@charset "utf-8";
#ageConfirmTop{
	@include props(pt,120);
	@include mq(md){
		@include props(pt,80,750);
	}
	.minContainer{
		text-align: center;

		.mainLogo{
			@include props(mb,120);
			img{
				margin: 0 auto;
				@include props(w,231);
			}

			@include mq(md){
				@include props(mb,120,750);

				img{
					@include props(w,233,750);
				}
			}
		}

		.mainTit{
			font-family: $YuMincho;
			@include fz_vw(40);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1;
			letter-spacing: 2.08px;
			@include props(mb,20);

			@include mq(md){
				@include fz_vw(62);
				@include props(mb,40,750);
			}
		}

		.subTit{
			font-family: $YuMincho;
			@include fz_vw(14);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.71;
			letter-spacing: 0.84px;

			@include mq(md){
				@include fz_vw(21);
			}
		}

		.read{
			font-family: $YuGothic;
			@include fz_vw(16);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: 0.32px;
			text-align: center;
			@include props(mt,40);
			@include props(mb,40);

			@include mq(md){
				@include fz_vw(28);
				@include props(mt,80,750);
				@include props(mb,80,750);
				@include props(pl,40,750);
				@include props(pr,40,750);
				letter-spacing: normal;
				text-align: left;
			}
		}

		.ask{
			font-family: $YuGothic;
			@include fz_vw(20);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.6;
			letter-spacing: 0.4px;

			@include mq(md){
				@include fz_vw(32);
			}
		}

		.BtnTemp{
            max-width: 400px;
            @include props(mt,60);
            @include props(mb,60);
            margin-right: auto;
            margin-left: auto;
  
            a{
              display: block;
              @include props(p,15);
			  background: #4d4d4d;
              transition: all .3s;

              &:hover{
                opacity: .8;
              }

			  p{
				display: inline;
				color: $basicWhiteColor;
				position: relative;
				font-family: $YuMincho;
				@include fz_vw(20);
				line-height: 2.4;
				text-align: center;
				text-decoration: none;
			  }
            }

              
            &.circleBtn{
              a{
                border-radius: 5px;
  
                p{
                  svg{
					position: absolute;
					@include props(r,-51);
					bottom: 25%;
                    @include props(w,41);
                    @include props(h,10);
                    transition: transform .5s;
                    transform: scale(1, 1);
                    transform-origin: left top;

                    @include mq(md){
						@include props(r,-51,750);
						@include props(w,41,750);
						@include props(h,10,750);
						@include props(ml,10,750);
                    }
                  }
                }

                &:hover{
                  p{
                    svg{
                      transform: scale(0, 1);
                      transform-origin: right top;
                    }
                  }
                }
              }
            }
  
            @include mq(md){
              width: 100%;
              max-width: inherit;
              @include props(mt,100,750);
              @include props(mb,100,750);
              @include props(pl,40,750);
              @include props(pr,40,750);
  
              a{
                @include props(p,30,750);
				p{
					@include fz_vw(32);
					line-height: 1.75;
				}
              }

              &.circleBtn{
                a{
                  border-radius: 1.33vw;
    
                  span{
                    &:after{
                      @include props(w,56,750);
                      @include props(ml,20,750);
                      min-width: 10.56%;
                    }
                  }
                }
              }
            }
          }
	}

	.intimateAnnounce{
		background: #F7F5F4;
		@include props(pt,60);
		@include props(pb,60);
		@include mq(md){
			@include props(pt,80,750);
			@include props(pb,80,750);
		}
		.animeLinkParent{
			max-width: 1406px;
			margin: 0 auto;
			padding: 0 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;

			@include mq(md){
				@include props(pl,40,750);
				@include props(pr,40,750);
			}
	
			.img{
				@include props(w,142);
				@include props(mr,80);

				@include mq(md){
					@include props(w,173,750);
					@include props(mr,0,750);
					@include props(mb,40,750);
				}
			}
	
			.txt{
				@include props(w,780);
				p{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 1.2px;
					text-align: left;
				}

				@include mq(md){
					width: 100%;
					max-width: 100%;

					p{
						@include fz_vw(28);
						letter-spacing: 2.1px;
						text-align: center;
					}
				}
			}
		}
	}
}

#footerAge{
	position: relative;
	background-color: #fff;
  
	.copyRightArea{
	  position: relative;
	  @include props(pt, 35);
	  @include props(pb, 35);
	  display: flex;
	  justify-content: center;
	  flex-wrap: wrap;
  
	  @include mq(md) {
		@include props(pt, 80, 750);
		@include props(pb, 50, 750);
	  }
	}
  
	.copyRight{
		width: 100%;
		display: block;
		font-family: $YuMincho;
		@include fz_vw(10);
		font-weight: 500;
		line-height: 1.2;
		letter-spacing: 1px;
		text-align: center;
		color: #4d4d4d;
		@include props(mt,30);
	
		@include mq(md) {
			@include fz_vw(20);
			letter-spacing: .6px;
			color: #707070;
		}
	}

	.snsLink{
		display: flex;
		width: 50%;
		justify-content: right;
		@include props(pr,55);
		li{
			@include props(w,45);
			@include props(ml,15);
			@include props(mr,15);
		}

		@include mq(md){
			width: 100%;
			justify-content: center;
			@include props(pr,0);
			@include props(mb,80,750);

			li{
				@include props(w,90,750);
				@include props(ml,20,750);
				@include props(mr,20,750);
			}
		}
	}
  
	.langBox{
	//   position: absolute;
	//   top: 0;
	//   bottom: 0;
	//   @include props(r, 20);
	//   margin: auto;
	  display: flex;
	  align-items: center;
	  width: 50%;
  
	  @include mq(md) {
		justify-content: center;
		width: 100%;
		@include props(mb, 60, 750);
	  }
  
	  span{
		font-family: $YuMincho;
		@include fz_vw(16);
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.48px;
		color: #666;
  
		@include mq(md) {
		  @include fz_vw(20);
		  letter-spacing: 0.3px;
		}
	  }
  
	  dl{
		position: relative;
		dt{
		  cursor: pointer;
		  position: relative;
		  font-family: $YuGothic;
		  @include fz_vw(14);
		  font-weight: 500;
		  line-height: 1;
		  letter-spacing: 1.05px;
		  color: #666;
		  @include props(pr, 30);
		  @include props(pl, 40);
  
		  @include mq(md) {
			@include fz_vw(20);
			letter-spacing: .75px;
			@include props(pr, 30, 750);
			@include props(pl, 40, 750);
		  }
  
		  &.open{
			&::after{
			  transform: rotate(-180deg);
			}
		  }
  
		  &::after{
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			background-image: url(/images/common/accordion_icon_01.svg);
			background-position: center right;
			background-size: contain;
			background-repeat: no-repeat;
			transition: .3s;
			backface-visibility: hidden;
			@include props(w, 12);
			@include props(h, 6);
  
			@include mq(md) {
			  @include props(w, 10, 750);
			  @include props(h, 5, 750);
			}
		  }
		}
  
		dd{
		  position: absolute;
		  bottom: 100%;
		  width: 100%;
		  display: none;
		  z-index: 90;
  
		  ul{
			background-color: #fff;
			@include props(pt, 6);
			@include props(pb, 16);
			@include props(pr, 10);
			@include props(pl, 20);
  
			@include mq(md) {
			  @include props(pt, 6, 750);
			  @include props(pb, 16, 750);
			  @include props(pr, 10, 750);
			  @include props(pl, 20, 750);
			}
		  }
  
		  li{
			font-family: $YuGothic;
			@include fz_vw(14);
			font-weight: 500;
			line-height: 1;
			background-color: #fff;
  
			@include mq(md) {
			  @include fz_vw(16);
			}
		  }
  
		  a{
			display: block;
			color: #707070;
			@include props(pt, 14);
			@include props(pb, 14);
  
			@include mq(md) {
			  @include props(pt, 14, 750);
			  @include props(pb, 14, 750);
			}
		  }
		}
	  }
	}
  }