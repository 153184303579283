@charset "utf-8";
/* ==============================================
トップページ
============================================== */
#indexTop{
  .indexArea{
    position: relative;
    z-index: 1;
    background-color: #f7f5f4;
    transition: 3s cubic-bezier(.08,.92,.35,1);
  }

  .secTit{
		font-family: $YuMincho;
		@include fz_vw(70);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 4.9px;
		text-align: center;
		@include props(pb,200);
	
		@include mq(md){
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 0.07em;
			text-align: center;
			@include props(pb,200,750);
		}
	}

  .gradation{
    position: relative;
    z-index: 1;
  }

  .mainVisual{
    position: relative;
    .slide{
      opacity: 0;
      transition: opacity .3s;

      &.slick-initialized {
        opacity: 1;
      }
    }
    .img{

      img{
        width: 100%;
      }
    }
    .mainVisualDots{
      position: absolute;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include props(b,50);
      @include mq(md){
        @include props(b,50, 750);
      }
      li{
        padding: 0 !important;
        @include props(w,30);
        @include props(h,4);
        background: #c3bfbf;
        margin: 0 5px;

        @include mq(md){
          @include props(w,30,750);
          @include props(h,4,750);
          @include props(ml,7,750);
          @include props(mr,7,750);
        }

        &:hover{
          cursor: pointer;
        }

        &.slick-active{
          background: #4d4d4d;
        }

        button{
          text-indent: -9999px;
        }
      }
    }
    .slide-arrow{
      position: absolute;
      top: 50%;
      z-index: 2;
      @include props(w,32);
      @include props(h,63);
      
      &.prev-arrow{
        @include props(l,100);
        transform: translate(0,-50%);
      }
      &.next-arrow{
        @include props(r,100);
        transform: translate(0,-50%);
      }
      @include mq(desk){
        &.prev-arrow{
          @include props(l,50);
        }
        &.next-arrow{
          @include props(r,50);
        }
      }
      @include mq(tab){
        &.prev-arrow{
          @include props(l,50);
        }
        &.next-arrow{
          @include props(r,50);
        }
      }

      @include mq(md){
        @include props(w,72,750);
        @include props(h,103,750);
        @include props(p,20,750);

        &.prev-arrow{
          @include props(l,20, 750);
        }
        &.next-arrow{
          @include props(r,20, 750);
        }
      }
    }
  }

  .aboutIroha{
    @include props(pt,240);
    @include props(pb,250);

    @include mq(md){
      @include props(pt,200,750);
      @include props(pb,140,750);
    }

    .mainTit{
      font-family: $YuMincho;
      @include fz_vw(60);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 3.12px;
      color: #666;
      @include props(mb,54);

      &:after{
        content: "きもちよさを、自分らしく。";
          font-family: $YuMincho;
          @include fz_vw(32);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 1.92px;
          display: block;
          @include props(mt,30);
      }

      @include mq(md){
        @include fz_vw(66);
        letter-spacing: 1.725px;
        text-align: center;
        @include props(mb,120,750);

        &:after{
          @include fz_vw(32);
          letter-spacing: .96px;
           @include props(mt,40,750);
        }
      }
    }

    .aboutIrohaDesc{
      position: relative;
      max-width: 1920px;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      @include mq(md){
        display: block;
        flex-direction: column-reverse;
      }
      .txt{
        max-width: 1406px;
        width: 100%;
        margin: 0 auto;
        @include props(pr,20);
        @include props(pl,20);
        @include mq(md){
          width: 100%;
          @include props(pl,0);
          @include props(pr,0);
        }

        .inner{
          width: 100%;
          max-width: 50%;

          @include mq(md){
            max-width: 100%;
          }

          p{
            font-family: $YuGothic;
            @include fz_vw(20);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.5;
            letter-spacing: 0.4px;
            text-align: left;
            display: inline-block;

            @include mq(md){
              @include fz_vw(32);
              line-height: 2.5;
              letter-spacing: 0.32px;
            }
          }

          .descTxt{
            @include mq(tab){
              @include props(pr,40);
            }
            @include mq(md){
              @include props(pl,80,750);
              @include props(pr,40,750);
              @include props(mb,45,750);
            }
            // &:nth-of-type(1){
            //   @include props(mb,110);
            //   @include props(pl,127);

            //   @include mq(md){
            //     @include props(mb,160,750);
            //     @include props(pl,40,750);
            //   }
            // }
            // &:nth-of-type(2){
            //   @include props(mb,130);
            //   @include mq(md){
            //     @include props(mb,160,750);
            //     @include props(pl,40,750);
            //   }
            // }
            // &:nth-of-type(3){
            //   text-align: right;
            //   @include props(pr,150);
            //   @include mq(md){
            //     @include props(pr,0,750);
            //     @include props(pl,40,750);
            //     @include props(mb,120,750);
            //     text-align: left;
            //   }
            // }
          }

          .btn01{
            @include props(mr,70);

            @include mq(md){
              @include props(mr,100,750)
            }

            a{
              justify-content: flex-end;
              @include props(pt,90);

              @include mq(md){
                @include props(pt, 115, 750);
              }

              &:before{
                content: "";
                @include props(l, 150);
              }
            }
          }
        }
      }

      .img{
        width: 50%;

        @include mq(md){
          width: 100%;
        }
        .videoBox{
          max-width: 50%;
          width: 100%;
          height: auto;
          position: absolute;
          right: 0;
          top: 0;

          @include mq(max){
            @include props(w,900);
          }
          @include mq(md){
            position: relative;
            max-width: 100%;
            width: 100%;
            box-shadow: none;
            @include props(mb,120, 750);
          }
          
          &:before{
            display: block;
            content: "";
            width: 100%;
            padding-top: 56.25%;
          }
          video{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
          }
        }

        img{
          max-width: 50%;
          position: absolute;
          right: 0;
          top: 0;
          //@include props(b,120);
          box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

          @include mq(max){
            @include props(w,900);
          }
          @include mq(md){
            position: static;
            max-width: 100%;
            width: 100%;
            box-shadow: none;
            @include props(mb,120, 750);
          }
        }
      }
    }
  }

  .products{
    @include props(pt, 200);
    @include props(pb, 200);

    @include mq(md){
      @include props(pt, 200,750);
      @include props(pb, 180,750);
    }

    .columnItemList{
      @include props(mb,240);

      .list{
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @include mq(md){
          flex-direction: column-reverse;
        }

        &:not(:last-of-type){
          @include props(mb,340);

          @include mq(md){
            @include props(mb,160,750);
          }
        }

        &:nth-of-type(even){
          flex-direction: row-reverse;

          .txt{
            text-align: right;
            &:before{
              right: inherit;
              left: 0;
            }

            .animeLinkEachsBtn{
              padding: 0 8.333% 0 0;
            }
          }

          @include mq(md){
            flex-direction: column-reverse;

            .txt{
              @include props(pl,0,750);
              @include props(pr,80,750);

              .animeLinkEachsBtn{
                @include props(pl,40,750);
                @include props(pr,40,750);
              }
            }

            .img{
              margin-left: auto;
              margin-right: 0;
            }
          }
        }

        .img{
          width: 62.5%;
          margin-bottom: -7.29166%;
          box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

          @include mq(md){
            @include props(w,710,750);
            margin-bottom: 0;
            margin-right: auto;
          }
        }

        .txt{
          width: 37.5%;
          padding: 7.8125% 0 4.166%;
          position: relative;
          @include mq(md){
            width: 100%;
            @include props(pt,100,750);
            @include props(pl,80,750);
            @include props(pr,0,750);
            @include props(pb,60,750);

            &:before{
              @include props(w,670,750);
              height: calc(100% + 6.66vw);
            }
          }
          
          &:before{
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            @include props(w,1200);
            background: $basicWhiteColor;
            z-index: -1;
          }

          .animeLinkEachsBtn{
            width: 86.1111%;
            display: inline-block;
            padding: 0 0 0 8.333%;
            text-align: center;

            @include mq(md){
              width: 100%;
              @include props(pl,40,750);
              @include props(pr,40,750);
              text-align: right;
            }

            .descTxt{
              font-family: $YuMincho;
              @include fz_vw(42);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: 2.1px;
              text-align: center;
              @include props(mb,14);

              @include mq(md){
                @include fz_vw(54);
                letter-spacing: 3.24px;
                @include props(mb,10,750);
              }
            }

            .descSubTxt{
              font-family: $YuGothic;
              @include fz_vw(20);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: 0.4px;
              text-align: center;
              display: block;
              @include mq(md){
                @include fz_vw(28);
                line-height: 2.14;
              }
            }

            .logo{
              margin: 0 auto;
              @include props(w,320);
              @include props(mb,72);
  
              @include mq(md){
                @include props(w,355,750);
                @include props(mb,70,750);
              }
            }

            p{
              font-family: $YuGothic;
              @include fz_vw(16);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: normal;
              text-align: left;
              @include props(mb,120);
              @include props(mt,60);
              width: 76.78%;
              margin-left: auto;
              margin-right: auto;
              @include mq(md){
                @include fz_vw(32);
                line-height: 2;
                width: 100%;
                @include props(mb,80,750);
                @include props(mt,80,750);
              }
            }

            .arrow{
              font-family: $YuMincho;
              @include fz_vw(18);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.22;
              letter-spacing: 1.08px;

              @include mq(md) {
                @include fz_vw(28);
              }

              &::after{
                content: "";
                display: inline-block;
                background-image: url(/images/index/products_arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                backface-visibility: hidden;
                @include props(ml, 10);
                width: 2.8em;
                height: .6em;
          
                @include mq(md) {
                  @include props(ml, 10, 750);
                  width: 1.8em;
                  height: .4em;
                }
              }
            }
          }
        }
      }
    }

    .irohaDiagnosis{
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      @include props(mt,140);
      background: $basicWhiteColor;
      text-align: center;

      @include mq(md){
        @include props(mt,140,750);
      }

      a{
        @include props(p,100);
        display: block;

        @include mq(md){
          @include props(pt,100,750);
          @include props(pb,100,750);
          @include props(pl,25,750);
          @include props(pr,25,750);
        }
      }

      h3{
        font-family: $YuMincho;
        @include fz_vw(36);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.73;
        letter-spacing: 3.6px;
        @include props(mb,40);

        @include mq(md){
          @include fz_vw(56);
					letter-spacing: 2.6px;
          @include props(mb,60,750);
        }
      }

      p{
        font-family: $YuGothic;
        @include fz_vw(18);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.36px;
        @include props(mb,30);

        @include mq(md){
          @include fz_vw(32);
					letter-spacing: normal;
          @include props(mb,60,750);
        }
      }

      .img{
        margin: 0 auto;
        width: 74.62%;

        img{
          margin: 0 auto;
        }
      }

      .btn{
        display: inline-block;
        @include props(mt,55);
        font-family: $YuGothic;
        @include fz_vw(20);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        border: solid 1px #bfbfbf;
        border-radius: 5px;
        @include props(w,400);
        @include props(pt,25);
        @include props(pb,25);
        @include props(pl,30);

        @include mq(md){
          @include fz_vw(32);
          @include props(w,590, 750);
          @include props(mt,80,750);
          @include props(pt,45,750);
          @include props(pb,45,750);
          @include props(pl,30,750);
        }

        &::after{
          content: "";
          display: inline-block;
          background-image: url(/images/common/blank_icon.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          backface-visibility: hidden;
          @include props(w,20);
          @include props(h,20);
          @include props(ml,30);
    
          @include mq(md) {
            width: 1em;
            height: 0.9em;
            @include props(ml, 30, 750);
          }
        }
      }
    }
  }

  .iroiroiroha{
    @include props(pt, 200);
    @include props(pb, 140);

    @include mq(md) {
      @include props(pt, 180, 750);
      @include props(pb, 180, 750);

      .minContainer{
        padding: 0;
      }
    }

    .iroiroirohaTit{
      margin: 0 auto;
      @include props(w, 460);
      @include props(mb, 170);

      @include mq(md) {
        @include props(w, 413, 750);
        @include props(mb, 215, 750);
      }
    }

    .iroiroirohaArticleTit{
      font-family: $YuMincho;
      @include fz_vw(42);
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 2.1px;
      color: #4d4d4d;
      border-bottom: solid 1px #4d4d4d;
      @include props(w, 922);
      @include props(pb, 10);
      @include props(mb, 70);

      @include mq(md) {
        @include fz_vw(48);
        line-height: 1;
        letter-spacing: 1.2px;
        margin: 0 auto;
        max-width: 89.3%;
        width: 100%;
        @include props(pb, 15, 750);
        @include props(mb, 70, 750);
      }
    }

    .featureTit{
      font-family: $YuMincho;
      @include fz_vw(42);
      font-weight: 500;
      line-height: 1.43;
      letter-spacing: 2.1px;
      color: #4d4d4d;
      border-bottom: solid 1px #4d4d4d;
      @include props(pb, 10);
      @include props(mb, 70);

      @include mq(md) {
        @include fz_vw(48);
        line-height: 1;
        letter-spacing: 1.2px;
        @include props(pb, 40, 750);
        @include props(mb, 55, 750);
      }
    }
    
    .category{
      display: flex;
      align-items: center;
      font-family: $YuGothic;
      @include fz_vw(16);
      font-weight: 500;
      line-height: 1;
      @include props(mb, 15);

      &::before{
        content: "";
        display: block;
        background-image: url(/images/common/iroiroiroha_icon_01.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @include props(w, 31);
        @include props(h, 26);
        @include props(mr, 5);
      }

      @include mq(md) {
        @include fz_vw(28);
        @include props(mb, 40, 750);
  
        &::before{
          @include props(w, 53, 750);
          @include props(h, 44, 750);
          @include props(mr, 8, 750);
        }
      }

      &.topics{
        color: #6456b7;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_01.svg);
        }
      }
      &.iroha_story{
        color: #4a6bb7;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_02.svg);
        }
      }
      &.interview{
        color: #63a534;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_03.svg);
        }
      }
      &.column{
        color: #aaa73f;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_04.svg);
        }
      }
      &.research{
        color: #c78246;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_05.svg);
        }
      }
      &.review{
        color: #bc5680;
        &::before{
          background-image: url(/images/common/iroiroiroha_icon_06.svg);
        }
      }
    }

    .flex{
      display: flex;
      @include props(mb, 40);

      @include mq(md) {
        display: block;
        @include props(mb, 60, 750);
      }
    }

    .newArticleCard{
      max-width: 50%;
      width: 100%;
      @include props(pr, 20);

      @include mq(md) {
        max-width: 100%;
        @include props(pr, 0);
        @include props(mb, 150);
      }

      a{
        display: block;
        @include hoverImg;
        position: relative;
        z-index: 0;
      }

      .img{
        position: relative;
        z-index: 2;
        box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
        overflow: hidden;
        @include props(w, 600);

        img{
          width: 100%;
        }

        @include mq(md) {
          max-width: 94.7%;
          width: 100%;
          box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
        }
      }
      .txt{
        position: relative;
        margin: 0 0 0 auto;
        z-index: 1;
        @include props(w, 600);
        @include props(pt, 70);
        @include props(pb, 110);
        @include props(pr, 40);
        @include props(pl, 50);

        @include mq(md) {
          max-width: 94.7%;
          width: 100%;
          @include props(pt, 60, 750);
          @include props(pb, 130, 750);
          @include props(pr, 40, 750);
          @include props(pl, 40, 750);
        }

        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: #fff;
          position: absolute;
          @include props(t, -60);
          left: 0;
          z-index: -1;

          @include mq(md) {
            @include props(t, -80, 750);
          }
        }
      }
      .newArticleCardTit{
        font-family: $YuGothic;
        @include fz_vw(24);
        font-weight: bold;
        line-height: 2;
        color: #4d4d4d;
        @include props(mb, 15);

        @include mq(md) {
          @include fz_vw(36);
          line-height: 1.44;
          @include props(mb, 40, 750);
        }
      }
      .newArticleCardTxt{
        font-family: $YuGothic;
        @include fz_vw(16);
        font-weight: 500;
        line-height: 2;
        color: #4d4d4d;
        @include props(pr, 20);
        @include props(mb, 40);

        @include mq(md) {
          @include fz_vw(28);
          line-height: 1.5;
          letter-spacing: .56px;
          @include props(pr, 0);
          @include props(mb, 40, 750);
        }
      }
      .newArticleCardData{
        display: block;
        font-family: $YuGothic;
        @include fz_vw(14);
        font-weight: 500;
        line-height: 1;
        color: #4d4d4d;

        @include mq(md) {
          @include fz_vw(28);
        }
      }
    }

    .featureBox{
      max-width: 50%;
      width: 100%;
      @include props(pl, 55);

      @include mq(md) {
        max-width: 100%;
        @include props(pl, 40, 750);
      }
    }

    .featureArticleCard{
      @include props(mb, 30);

      &:last-of-type{
        margin-bottom: 0;
      }

      a{
        display: flex;
        align-items: flex-start;
        @include hoverImg;
        @include props(pb, 30);
      }

      @include mq(md) {
        @include props(mb, 50, 750);

        a{   
          position: relative;
          z-index: 1;
          @include props(pt, 45, 750);
          @include props(pb, 40, 750);
          @include props(pr, 40, 750);

          &::after{
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #fff;
            @include props(w, 510, 750);
            height: 100%;
          }
        }
      }

      .img{
        flex-shrink: 0;
        box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .1);
        overflow: hidden;
        background-color: inherit;
        @include props(w, 284);
        @include props(mr, 40);

        img{
          width: 100%;
        }

        @include mq(md) {
          box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
          @include props(w, 300, 750);
          @include props(mr, 35, 750);
        }
      }

      .category{
        @include props(mb, 20);

        @include mq(md) {
          @include props(mb, 30, 750);
    
          &::before{
            @include props(w, 50, 750);
            @include props(h, 40, 750);
          }
        }
      }

      .featureArticleCardTit{
        font-family: $YuGothic;
        @include fz_vw(18);
        font-weight: bold;
        line-height: 1.78;
        color: #4d4d4d;
        @include props(mb, 20);

        @include mq(md) {
          @include fz_vw(28);
          line-height: 1.57;
          @include props(mb, 30, 750);
        }  
      }
      .featureArticleCardData{
        display: block;
        font-family: $YuGothic;
        @include fz_vw(14);
        font-weight: 500;
        line-height: 1;
        color: #4d4d4d;

        @include mq(md) {
          @include fz_vw(28);
        }  
      }
    }
  }

  .shop{
    @include props(pt, 200);
    @include props(pb, 140);

    @include mq(md){
      @include props(pt, 180,750);
      @include props(pb, 180,750);
    }

    .shopLinksWrap{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
      @include props(mb,40);

      @include mq(md){
        @include props(mb,140,750);
      }

			li{
				width: 30.38%;
				background: $basicWhiteColor;
				text-align: center;

				@include mq(md){
					width: 100%;
					@include props(mb,50,750);
          margin-left: 0;
          margin-right: 0;
				}

				a{
					display: block;
					width: 100%;
					height: 100%;
					padding: 14.45% 9.74% 9.74% 9.74%;
					position: relative;
          transition: opacity .3s;

          &:hover{
            opacity: .7;
          }
					
					&:after{
						content: "";
						background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						width: 12.11%;
						height: 7px;
						position: absolute;
						bottom: 9.74%;
						right: 9.74%;
					}

					p{
            font-family: $YuGothic;
						@include fz_vw(24);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						margin-bottom: 12.145%;

						&:before{
							content: "";
							width: 28.34%;
							@include props(h,70);
							display: block;
							margin: 0 auto 12.1%;
						}

						&:after{
							font-family: $YuMincho;
							@include fz_vw(18);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.86;
							letter-spacing: 0.28px;
							text-align: center;
							display: block;
						}
					}

					@include mq(md){
						@include props(pt,60,750);
						@include props(pl,40,750);
						@include props(pb,110,750);
						@include props(pr,40,750);

						&:after{
							@include props(w,53,750);
							@include props(h,10,750);
							@include props(r,40,750);
							@include props(b,40,750);
						}

						p{
							@include fz_vw(36);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
							@include props(mb,0,750);
              display: flex;
              align-items: center;
              position: relative;

							&:before{
								@include props(w,60,750);
								@include props(h,60,750);
                @include props(mr,55,750);
                @include props(ml,0,750);
                @include props(mb,-50,750);
							}

							&:after{
								@include fz_vw(22);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1;
								letter-spacing: 0.4px;
                position: absolute;
                @include props(b,-18,750);
                @include props(l,120,750);
                transform: translate(0,1em);
							}
						}
					}
				}

				&.cart{

					a{
						&:after{
							background: url(/images/common/blank_icon.svg) bottom center no-repeat;
							background-size: contain;
							@include props(w,16);
							@include props(h,14);
							min-width: 12px;
							min-height: 10px;

							@include mq(md){
								@include props(w,37,750);
								@include props(h,34,750);
							}
						}
					}
					p{
						&:before{
							background: url(/images/common/cart_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Official Online Store";
						}
					}
				}

				&.search{
          a{
            &:hover{
              &:after{
                // transform: scale(0, 1);
                // transform-origin: right top;
                animation: 1s arrow linear forwards;
              }
            }

            // &:after{
            //   transform: scale(1, 1);
            //   transform-origin: left top;
            //   transition: transform .5s;
            // }
          }
					p{
						&:before{
							background: url(/images/common/national_map.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Store Search";
						}
					}
				}
				&.store{
          a{
            &:hover{
              &:after{
                // transform: scale(0, 1);
                // transform-origin: right top;
                animation: 1s arrow linear forwards;
              }
            }

            // &:after{
            //   transform: scale(1, 1);
            //   transform-origin: left top;
            //   transition: transform .5s;
            // }
          }
					p{
						&:before{
							background: url(/images/common/store_bag.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Flagship Store";
						}
					}
				}
			}
		}
  }

  .pickUp{
    @include props(pt, 120);
    @include props(pb, 210);

    @include mq(md){
      @include props(pt, 100,750);
      @include props(pb, 200,750);
    }
    
    .minContainer{
      max-width: 1446px;

      @include mq(md){
        padding: 0;
      }
    }
    #pickUpSlide{
      position: relative;
      .slick-slide{
        //max-width: calc(100% - 9.37%);
        box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.16);
        line-height: 0;
        @include props(w,425);
        @include props(mr,23);
        @include props(ml,23);
        @include props(mb,25);

        @include mq(desk){
          box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
        }
        @include mq(tab){
          box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
        }
        @include mq(md){
          @include props(w,425, 750);
          @include props(mr,41, 750);
          @include props(ml,41, 750);
          @include props(mb,25, 750);
          box-shadow: 0.667vw 1.333vw 2.667vw 0 rgba(0, 0, 0, 0.16);
        }
      }
      li{
        //padding: 0 4.385%;

        // @include mq(md){
        //   @include props(pl,41,750);
        //   @include props(pr,41,750);
        // }

        img{
          width: 100%;
        }
      }

      .slide-arrow{
        position: absolute;
        @include props(t,212.5);
        z-index: 2;
        @include props(w,19);
        @include props(h,36.1);
        
        &.prev-arrow{
          left: 0%;
          transform: translate(-50%,-50%);
        }
        &.next-arrow{
          right: 0%;
          transform: translate(50%,-50%);
        }

        @include mq(max){
          &.prev-arrow{
            left: -130px;
          }
          &.next-arrow{
            right: -130px;
          }
        }

        @include mq(fixed){
          &.prev-arrow{
            left: -70px;
          }
          &.next-arrow{
            right: -70px;
          }
        }

        @include mq(md){
          @include props(t,212.5,750);
          @include props(w,19,750);
          @include props(h,36.1,750);

          &.prev-arrow{
            left: 13.33%;
            transform: translate(0%,-50%);
          }
          &.next-arrow{
            right: 13.33%;
            transform: translate(0%,-50%);
          }
        }
      }
    }

    .slide-dots{
      display: flex;
      justify-content: center;
      @include props(mt,95);
      @include mq(md){
        @include props(mt,95,750);
      }
      li{
        padding: 0 !important;
        @include props(w,30);
        @include props(h,4);
        background: #C3BFBF;
        margin: 0 5px;

        @include mq(md){
          @include props(w,30,750);
          @include props(h,4,750);
          @include props(ml,7,750);
          @include props(mr,7,750);
        }

        &:hover{
          cursor: pointer;
        }

        &.slick-active{
          background: #666666;
        }

        button{
          text-indent: -9999px;
        }
      }
    }
  }

  .newsSection{
    @include props(pt, 200);
    @include props(pb, 140);

    @include mq(md){
      @include props(pt, 180,750);
      @include props(pb, 180,750);
    }

		@include mq(md){
			.minContainer{
				padding: 0 !important;
			}
		}
    ul{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb,120);

			&:after{
				content: "";
				display: block;
				width: 31.039%;
			}
			
			@include mq(md){
				@include props(mb,50,750);
				position: relative;

				&:after{
					content: none;
				}

				.slick-slide{
					padding: 0 .5%;
				}

				.slide-arrow{
					position: absolute;
					@include props(t,145,750);
					z-index: 2;
					@include props(w,19,750);
					@include props(h,36.1,750);
					
					&.prev-arrow{
						left: 10%;
						transform: translate(-50%,-50%);
					}
					&.next-arrow{
						right: 10%;
						transform: translate(50%,-50%);
					}
				}
			}

			li{
				width: 31.039%;

				.img{
					img{
						width: 100%
					}
				}

				.txt{
					@include props(pt,40);

					@include mq(md){
						@include props(pt,80,750);

						.descTxt{
							@include fz_vw(28);
							@include props(pb,30,750);
						}

						time{
							@include fz_vw(28);
						}
					}

					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(pb,30);
					}

					time{
						font-family: $YuGothic;
						@include fz_vw(14);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.29;
						letter-spacing: normal;
						text-align: left;
					}
				}
			}
		}
  }

  .media{
    @include props(pt, 200);
    @include props(pb, 140);

    @include mq(md){
      @include props(pt, 180,750);
      @include props(pb, 180,750);
    }
    .mediaPostList{
			@include props(mb,200);
	
			@include mq(md){
				@include props(mb,120,750);
			}
			
			li{
				background: $basicWhiteColor;
				@include props(mb,70);
	
				@include mq(md){
					@include props(mb,80,750);
				}
	
				&:last-child{
					@include props(mb,0);
				}
	
				a{
					display:flex;
					@include props(pt,70);
					@include props(pb,70);
					@include props(pl,35);
					@include props(pr,80);
					position: relative;
          transition: opacity .3s;

          &:hover{
            opacity: .7;
          }
	
					@include mq(md){
						@include props(pt,35,750);
						@include props(pb,35,750);
						@include props(pl,40,750);
						@include props(pr,40,750);
					}
	
					.listInner{
						display: flex;
						flex-wrap: wrap;
						width: 100%;
            @include mq(md){
              flex-direction: column-reverse;
            }
	
						time{
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							width: 10.029%;
							padding-right: 1em;
	
							@include mq(md){
								@include fz_vw(28);
								@include props(w,170,750);
								line-height: 1.71;
							}
						}

						p{
							width: 70%;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
	
							@include mq(md){
								@include fz_vw(28);
								width: 100%;
								line-height: 1.71;
								letter-spacing: normal;
								@include props(mb,35,750);
							}
						}
					}
	
					img{
						position: absolute;
						@include props(r,80);
						@include props(b,20);
						@include props(w,148);
						@include props(h,191);
            transform: translateY(100px);
            opacity: 0;
            visibility:hidden;
            transition: opacity 1s .5s, transform 1s .5s;
	
						@include mq(md){
							position: inherit;
							@include props(w,148,750);
							@include props(h,190,750);
							@include props(r,0,750);
							@include props(b,0,750);
							@include props(t,-50,750);
							@include props(ml,50,750);
						}
					}
				}

        &.active{
          a{
            img{
              opacity: 1;
              visibility: visible;
              transform: none;
            }
          }
        }
			}
		}
  }

  .support{
    @include props(pt, 200);

    @include mq(md){
      @include props(pt, 180,750);
    }

    .secTit{
      @include props(pb, 140);
      @include mq(md){
        @include props(pb, 80,750);
      }
    }
    .supportRead{
      font-family: $YuGothic;
      @include fz_vw(16);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 1.6px;
      text-align: center;
      @include props(mb,150);

      @include mq(md){
        @include fz_vw(32);
        letter-spacing: normal;
        text-align: left;
        @include props(mb,100,750);
      }
    }
    .supportLinksWrap{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li{
				width: 30.38%;
				background: $basicWhiteColor;
				text-align: center;
        margin: 0 2.1961%;

				@include mq(md){
					width: 100%;
					@include props(mb,50,750);
          margin-left: 0;
          margin-right: 0;
				}

				a{
					display: block;
					width: 100%;
					height: 100%;
					padding: 14.45% 9.74% 9.74% 9.74%;
					position: relative;
          transition: opacity .3s;

          &:hover{
            opacity: .7;
          }
					
					&:after{
						content: "";
						background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						width: 12.11%;
						height: 7px;
						position: absolute;
						bottom: 9.74%;
						right: 9.74%;
					}

					p{
            font-family: $YuGothic;
						@include fz_vw(24);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						margin-bottom: 12.145%;

						&:before{
							content: "";
							width: 28.34%;
							@include props(h,70);
							display: block;
							margin: 0 auto 12.1%;
						}

						&:after{
							font-family: $YuMincho;
							@include fz_vw(18);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.7;
							letter-spacing: 0.28px;
							text-align: center;
							display: block;
						}
					}

					@include mq(md){
						@include props(pt,60,750);
						@include props(pl,40,750);
						@include props(pb,110,750);
						@include props(pr,40,750);

						&:after{
							@include props(w,53,750);
							@include props(h,10,750);
							@include props(r,40,750);
							@include props(b,40,750);
						}

						p{
							@include fz_vw(36);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.33;
              letter-spacing: normal;
							@include props(mb,0,750);
              display: flex;
              align-items: center;
              position: relative;

							&:before{
								@include props(w,60,750);
								@include props(h,60,750);
                @include props(mr,55,750);
                @include props(ml,0,750);
                @include props(mb,-50,750);
							}

							&:after{
								@include fz_vw(22);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1;
								letter-spacing: 0.4px;
                position: absolute;
                @include props(b,-18,750);
                @include props(l,120,750);
                transform: translate(0,1em);
							}
						}
					}
				}
				&.faq{
          a{
            &:hover{
              &:after{
                // transform: scale(0, 1);
                // transform-origin: right top;
                animation: 1s arrow linear forwards;
              }
            }

            // &:after{
            //   transform: scale(1, 1);
            //   transform-origin: left top;
            //   transition: transform .5s;
            // }
          }
					p{
						&:before{
							background: url(/images/common/faq_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "FAQ";
						}
					}
				}
				&.contact{
          a{
            &:hover{
              &:after{
                // transform: scale(0, 1);
                // transform-origin: right top;
                animation: 1s arrow linear forwards;
              }
            }

            // &:after{
            //   transform: scale(1, 1);
            //   transform-origin: left top;
            //   transition: transform .5s;
            // }
          }
					p{
						&:before{
							background: url(/images/common/contact_mail_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Contact Us";
						}
					}
				}
			}
		}
  }
}