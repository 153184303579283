@charset "utf-8";
/* ボタン装飾------------------------------ */
button {
  outline: none;
}

	.slide-arrow{
    &:hover{
      cursor: pointer;
    }
  }
  
.BtnTemp{
  @include props(w,400);
  @include props(mt,60);
  @include props(mb,60);
  margin-right: auto;
  margin-left: auto;

  @include mq(md){
    width: 100%;
    max-width: 100%;
  }

  &:not(.pdfBtn){
    a{
      border-width: 1px;
      border-style: solid;
      border-color: #fff;
    }
  }

  a{
    display: block;
    text-align: center;
    text-decoration: none;
    @include props(p,15);
    transition: all .3s;
    background: #4d4d4d;

    &:hover{
    opacity: .8;
    }

    p{
      font-family: $YuMincho;
      @include fz_vw(20);
      line-height: 1.7;
      color: $basicWhiteColor;
    }
  }

  &.circleBtnBlank{
    a{
    border-radius: 5px;

    p{
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
      @include props(w,22);
      @include props(h,21);
      @include props(ml,10);

      @include mq(md){
        @include props(w,24,750);
        @include props(h,22,750);
        @include props(ml,10,750);
      }
      }
    }
    }
  }

    
  &.circleBtn{
    a{
    border-radius: 5px;

    p{
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
      @include props(w,41);
      @include props(h,10);
      @include props(ml,10);
      transition: transform .5s;
      transform: scale(1, 1);
      transform-origin: left top;

      @include mq(md){
        @include props(w,41,750);
        @include props(h,10,750);
        @include props(ml,10,750);
      }
      }
    }

    &:hover{
      p{
        svg{
        // transform: scale(0, 1);
        // transform-origin: right top;
        animation: 1s arrow linear forwards;
        }
      }
      }
    }
  }

  &.pdfBtn{
    a{
    border-radius: 5px;
    background: #4d4d4d;
    color: $basicWhiteColor;

    p{
      display: flex;
      justify-content: center;
      align-items: center;
      &:after{
      content: "";
      display: inline-block;
      background-image: url(/images/news/news_post_pdf_icon.svg);
      background-repeat: no-repeat;
      background-position: center right;
      background-size: contain;
      @include props(w,33);
      height: 2.05em;
      @include props(ml,10);
      }
    }
    }
  }

  @include mq(md){
    width: 100%;
    max-width: inherit;
    @include props(mt,100,750);
    @include props(mb,100,750);

    a{
    line-height: 1.75;
    @include props(p,30,750);

    p{
      @include fz_vw(28);
    }
    }

    &.circleBtnBlank{
    a{
      border-radius: 1.33vw;
    }
    }

    &.circleBtn{
    a{
      border-radius: 1.33vw;
    }
    }
    &.pdfBtn{
    a{
      border-radius: 1.33vw;

      p{
      &:after{
        @include props(w,43,750);
        @include props(ml,20,750);
      }
      }
    }
    }
  }
}