@charset "utf-8";
/* ==============================================
水原 希子特設ページ
============================================== */
#mizuharaTop{

	.mainviewModal{
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100%;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10000;
		background-color: #fff;
		opacity: 0;
		visibility: hidden;
		transition: opacity 2s, visibility 2s;
		backface-visibility: hidden;

		&.isPlay{
			opacity: 1;
			visibility: visible;
		}

		@include mq(md){
			@include props(pt, 60, 750);
			@include props(pb, 60, 750);
		}

		.mainviewModalClose{
			position: absolute;
			cursor: pointer;
			z-index: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255,.6);
			// @include props(t, 76);
			// @include props(r, 40);
			// @include props(w, 81);
			// @include props(h, 58);
			// @include props(p, 10);

			p{
				// width: 100%;
				// height: 100%;
				position: absolute;
				@include props(t, 76);
				@include props(r, 40);
				@include props(w, 81);
				@include props(h, 58);
				@include props(p, 10);

				img{
					width: 100%;
					height: 100%;
					object-fit: contain;
				}

				@include mq(max){
					@include props(r, 100);
				}
	
				@include mq(minCon){
					@include props(t, 30);
					@include props(r, 40);
				}
	
				@include mq(md){
					@include props(t,40,750);
					@include props(r,40,750);
					@include props(w, 81, 750);
					@include props(h, 58, 750);
					@include props(p, 10, 750);
				}
			}
		}

		.mainviewModalVideoBox{
			max-width: 1320px;
			width: 100%;
			padding: 0 20px;
			margin: 0 auto;
			background-color: #fff;

			@include mq(desk){
				max-width: 1100px;
			}
			@include mq(tab){
				max-width: 1100px;
			}
		}
		.mainviewModalVideo {
			width: 100%;
			padding-top: 56.25%;
			position: relative;
			background-color: #fff;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	.mizuhara{
		position: relative;
		z-index: 1;
		background-color: rgba(253, 251, 249, .8);
		transition: 3s cubic-bezier(.08,.92,.35,1);
		@include props(pt, 120);
	
		@include mq(minCon){
			padding-top: 7.5vw;
		}

		@include mq(md){
			@include props(pt, 125, 750);
		}

		.btn{
			@include props(w, 247);

			@include mq(md){
				margin: 0 auto;
				@include props(w, 550, 750);
			}

			a{
				display: block;
				font-family: $YuMincho;
				@include fz_vw(14);
				font-weight: 500;
				line-height: 1;
				letter-spacing: 0.28px;
				text-align: center;
				color: #4d4d4d;
				border: solid 1px #ebccb7;
				border-radius: 3px;
				transition: all .3s;
				@include props(pt, 24);
				@include props(pb, 24);

				@include mq(md){
					@include fz_vw(28);
					letter-spacing: 0.56px;
					@include props(pt, 46, 750);
					@include props(pb, 46, 750);
				}

				&:hover{
					opacity: .8;
				}

				span{
					display: flex;
					align-items: center;
					justify-content: center;
					&:after{
						content: "";
						flex-shrink: 0;
						display: block;
						background-image: url(/images/common/blank_icon.svg);
						background-repeat: no-repeat;
						background-position: center right;
						background-size: contain;
						@include props(w, 14);
						@include props(h, 12);
						@include props(ml, 8);

						@include mq(md){
							@include props(w, 33, 750);
							@include props(h, 30, 750);
							@include props(ml, 20, 750);
						}
					}
				}
			}
		}

		.mainviewArea{
			cursor: pointer;
			transition: all .3s;

			&:hover{
				opacity: .9;
			}

			img{
				width: 100%;
			}
		}

		.mizuharaMainArea{
			@include props(pt, 160);
			@include props(pb, 100);

			@include mq(md){
				@include props(pt, 160, 750);
				@include props(pb, 80, 750);
			}

			.irohaMaiLink {
				max-width: 1040px;
				padding: 0 20px;
				margin-left: auto;
				margin-right: auto;
				@include props(mb, 160);
			  
				@include mq(md) {
				  @include props(pr, 40, 750);
				  @include props(pl, 40, 750);
				  @include props(mb, 160,750);
				}
			}

			.mizuharaMainAreaLead{
				font-family: $YuGothic;
				@include fz_vw(20);
				font-weight: 500;
				line-height: 3;
				letter-spacing: 0.6px;
				color: #4d4d4d;
				@include props(pb, 156);

				@include mq(md){
					@include fz_vw(32);
					font-weight: 500;
					line-height: 2.56;
					letter-spacing: .64px;
					@include props(pr, 80, 750);
					@include props(pl, 80, 750);
					@include props(pb, 160, 750);
				}
			}

			.interviewTxtBox{
				display: flex;
				align-items: center;
				justify-content: center;

				@include mq(md){
					display: block;
				}

				&:nth-of-type(2n){
					.imgBox{
						order: 2;
						@include props(pl, 50);

						.img{
							margin: 0 auto 0 0;
						}

						@include mq(md){
							@include props(pr, 0);
							@include props(pl, 40, 750);

							.img{
								margin: 0 0 0 auto;
							}
						}
					}
					.txtBox{
						order: 1;
						@include props(pr, 40);
						@include props(pl, 0);
						
						.interviewTit,
						.interviewTxt{
							margin-right: 0;
							margin-left: auto;
						}

						@include mq(md){
							@include props(pt, 80, 750);
							@include props(pr, 80, 750);
							@include props(pl, 80, 750);
						}
					}
				}

				.imgBox{
					max-width: 50%;
					width: 100%;
					flex-shrink: 0;
					@include props(pr, 40);

					.img{
						width: 100%;
						margin: 0 0 0 auto;
						box-shadow: 10px 10px 20px 0 #00000029;

						img{
							width: 100%;
						}
					}

					@include mq(md){
						max-width: 100%;
						@include props(pr, 40, 750);
	
						.img{
							box-shadow: 5px 5px 10px 0 #00000029;
							margin: 0 auto 0 0;
						}
					}
				}
				.txtBox{
					max-width: 50%;
					width: 100%;
					@include props(pl, 60);

					@include mq(md){
						max-width: 100%;
						@include props(pt, 80, 750);
						@include props(pr, 80, 750);
						@include props(pl, 80, 750);
					}
				}

				.interviewTit{
					font-family: $YuGothic;
					@include fz_vw(24);
					font-weight: bold;
					line-height: 2;
					color: #4d4d4d;
					@include props(mb, 40);

					@include mq(md){
						@include fz_vw(34);
						@include props(mb, 70, 750);
					}
				}
				.interviewTxt{
					font-family: $YuGothic;
					@include fz_vw(20);
					font-weight: 500;
					line-height: 2;
					color: #4d4d4d;

					@include mq(md){
						@include fz_vw(32);
					}
				}
			}
			.interviewTxtBox01{
				@include props(mb, 165);

				@include mq(md){
					@include props(mb, 160, 750);
				}

				.img{
					max-width: 100%;
				}
				.interviewTit,
				.interviewTxt{
					max-width: 60.45%;
					width: 100%;

					@include mq(desk){
						max-width: 79%;
					}
					@include mq(tab){
						max-width: 90%;
					}
					@include mq(md){
						max-width: 100%;
					}
				}
			}
			.interviewTxtBox02{
				@include props(mb, 84);

				@include mq(md){
					@include props(mb, 160, 750);
				}

				.img{
					max-width: 80.575%;

					@include mq(md){
						max-width: 100%;
					}
				}
				.interviewTit,
				.interviewTxt{
					max-width: 54%;
					width: 100%;

					@include mq(desk){
						max-width: 72%;
					}
					@include mq(tab){
						max-width: 90%;
					}
					@include mq(md){
						max-width: 100%;
					}
				}
			}
			.interviewTxtBox03{
				@include props(mb, 200);

				@include mq(md){
					@include props(mb, 160, 750);
				}

				.img{
					max-width: 69.784%;

					@include mq(md){
						max-width: 77.465%;
					}
				}
				.interviewTit,
				.interviewTxt{
					max-width: 55%;
					width: 100%;

					@include mq(desk){
						max-width: 72%;
					}
					@include mq(tab){
						max-width: 90%;
					}
					@include mq(md){
						max-width: 100%;
					}
				}
			}

			.flex{
				display: flex;
				align-items: flex-start;
				justify-content: space-between;

				@include mq(md){
					display: block;
				}
			}
			.boxLayout{
				background-color: #fff;
				@include props(p, 100);

				@include mq(md){
					@include props(pt, 160, 750);
					@include props(pb, 160, 750);
					@include props(pr, 80, 750);
					@include props(pl, 80, 750);
				}
			}
			.otherInfo{
				max-width: 50%;
				width: 100%;

				.inner{
					max-width: 588px;
					width: 100%;
					margin: 0 0 0 auto;
				}

				@include mq(desk){
					max-width: 53%;
					.inner{
						max-width: 100%;
					}
				}
				@include mq(tab){
					max-width: 53%;
					.inner{
						max-width: 100%;
					}
				}
				@include mq(md){
					max-width: 94.4%;
					.inner{
						max-width: 100%;
					}
				}

				.otherInfoTit{
					font-family: $YuMincho;
					@include fz_vw(32);
					font-weight: 500;
					line-height: 1.75;
					letter-spacing: 0.64px;
					color: #4d4d4d;
					text-align: center;
					@include props(mb, 50);

					@include mq(md){
						@include fz_vw(40);
						line-height: 2;
						letter-spacing: 0.4px;
						text-align: left;
						@include props(mb, 70, 750);

						.space{
							display: inline !important;
						}
					}
				}
				.otherInfoSubTit{
					font-family: $YuMincho;
					@include fz_vw(28);
					font-weight: 500;
					line-height: 1.86;
					letter-spacing: 0.56px;
					text-align: center;
					color: #4d4d4d;
					@include props(mb, 86);

					@include mq(md){
						@include fz_vw(36);
						line-height: 2;
						letter-spacing: 0.36px;
						@include props(mb, 106, 750);
					}
				}
				.otherInfoTxt{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					line-height: 2;
					color: #4d4d4d;
					@include props(pr, 13);
					@include props(pl, 13);
					@include props(mb, 86);

					@include mq(md){
						@include fz_vw(28);
						@include props(pr, 0);
						@include props(pl, 0);
						@include props(mb, 70, 750);
					}
				}

				.btnLayout{
					display: flex;
					justify-content: center;
					@include props(pr, 13);
					@include props(pl, 13);

					.btn{
						@include props(mr, 58);

						&:last-of-type{
							margin-right: 0;
						}
					}
					@include mq(md){
						display: block;
						@include props(pr, 0);
						@include props(pl, 0);

						.btn{
							@include props(mb, 80, 750);

							&:last-of-type{
								margin-bottom: 0;
							}
						}
					}
				}
			}
			.profile{
				max-width: 49.4792%;
				width: 100%;
				@include props(mt, 156);
				.inner{
					max-width: 76.667%;
					width: 100%;
				}

				@include mq(desk){
					max-width: 46.4792%;
					.inner{
						max-width: 100%;
					}
				}
				@include mq(tab){
					max-width: 46.4792%;
					.inner{
						max-width: 100%;
					}
				}
				@include mq(md){
					max-width: 94.4%;
					margin: 0 0 0 auto;
					@include props(mt, 160, 750);
					.inner{
						max-width: 100%;
					}
				}

				.profileTit{
					font-family: $YuGothic;
					@include fz_vw(32);
					font-weight: 500;
					line-height: 1.72;
					letter-spacing: 1.76px;
					@include props(mb, 50);

					@include mq(md){
						@include fz_vw(40);
						line-height: 1.38;
						letter-spacing: 1.1px;
						@include props(mb, 80, 750);
					}
				}
				.profileTxt{
					font-family: $YuGothic;
					@include fz_vw(18);
					font-weight: 500;
					line-height: 1.78;
					color: #4d4d4d;

					.mincho{
						font-family: $YuMincho;
					}

					a{
						color: #4d4d4d;
					}

					@include mq(md){
						@include fz_vw(30);
						line-height: 2;
					}
				}
			}
		}

		.ripples{
			display: none;
			background: #aaa;
			opacity: .1;
			width: 100vw;
			height: 100vh;
			position: fixed;
			top: 0;
			left: 0;
		}

		.linkBnrArea{
			display: flex;
			justify-content: center;
			@include props(mb, 50);

			.bnr{
				@include props(w, 600);
				@include props(mr, 30);
				@include props(ml, 30);
			}

			@include mq(md){
				display: block;
				@include props(mb, 40, 750);
	
				.bnr{
					margin: 0 auto;
					@include props(w, 600, 750);
					@include props(mb, 80, 750);

					&:last-of-type{
						margin-bottom: 0;
					}
				}
			}
		}

		.irohaMaiArea{
			position: relative;
			.irohaMaiMovieArea{
				.irohaMaiMovieAreaTit{
					font-family: $YuMincho;
					@include fz_vw(70);
					font-weight: 500;
					line-height: 0.79;
					letter-spacing: 3.85px;
					text-align: center;
					color: #666;
					@include props(pt,200);
					@include props(mb, 200);
	
					@include mq(md){
						line-height: 1.43;
						letter-spacing: 2.45px;
						@include props(pt,160,750);
						@include props(mb, 150, 750);
					}
				}
				.irohaMaiMovieBox{
					@include mq(md){
						padding: 0;
					}
				}
				.irohaMaiMovie{
					position: relative;
					@include props(mb, 160);
	
					&:hover{
						cursor: pointer;
						&::after{
							opacity: .3;
							visibility: visible;
						}
					}
	
					@include mq(md){
						@include props(mb, 160, 750);
					}
	
					&::after{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background-color: #fff;
						opacity: 0;
						visibility: hidden;
						transition: all .3s;
					}
	
					video{
						max-width: 100%;
						width: 100%;
					}
				}
			}

			ul{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,200,750);
				}
				li{
					position: relative;
					z-index: 1;
					@include props(pt,50);
	
					@include mq(md){
						@include props(pt,420,750);
						@include props(pb,80,750);
					}
	
					&:not(:last-child){
						@include props(mb,160);
	
						@include mq(md){
							@include props(mb,160,750);
						}
					}
					
	
					.mainTxt{
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						text-align: left;
						@include props(mb,50);
						@include props(pl,60);
						width: 58.565%;
	
						@include mq(md){
							@include fz_vw(40);
							width: 100%;
							@include props(mb,60,750);
							@include props(mt,60,750);
						}
					}
	
					.flex{
						display: flex;
						align-items: flex-start;
						flex-wrap: wrap;
	
						.innerTxt{
							padding-right: 5.49%;
							padding-left: 4.39%;
							width: 36.311%;
							text-align: right;
	
							@include mq(md){
								width: 100%;
							}
	
							.descTxt{
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2.3;
								letter-spacing: normal;
								text-align: left;
								@include props(mb,50);
	
								@include mq(md){
									@include fz_vw(28);
									@include props(mb,80,750);
									line-height: 1.7;
									letter-spacing: 1.12px;
								}
							}

							#audioBtn{
								display: block;
								margin: 0 auto;
								color: #4D4D4D;
								@include props(pt,13);
								@include props(pb,13);
								@include props(pl,27);
								@include props(pr,27);
								border: 1px solid #769DCD;
								border-radius: 3px;
								transition: opacity 1s .2s;
								&:hover{
									cursor: pointer;
									opacity: .7;
								}

								span{
									@include fz_vw(16);
									font-family: $YuMincho;

									&:before{
										content: "";
										background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
										background-size: contain;
										width: 1.2em;
										height: .9em;
										display: inline-block;
										padding-right: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,31,750);
									@include props(pb,31,750);
									span{
										@include fz_vw(28);
									}
								}
							}
						}
	
						.img{
							width: 63.689%;
							box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
							position: relative;
							padding-top: 40.263%;
							
	
							@include mq(md){
								position: absolute;
								top: 0;
								@include props(r,-40,750);
								@include props(w,710,750);
								padding-top: 63.2179%;
							}
	
							img{
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
							}
						}
					}
	
					&:nth-child(odd){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 500px;
								height: 75%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								left: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(l,-40,750);
								}
							}
						}
	
	
						.mainTxt{
							@include mq(md){
								@include props(pl,0,750);
								@include props(pr,40,750);
							}
						}
	
						.flex{
							.innerTxt{
								@include mq(md){
									@include props(pr,40,750);
									padding-left: 0;
								}
							}
						}
					}
					&:nth-child(3){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 560px;
								height: 85%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								left: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(l,-40,750);
								}
							}
						}
					}
					&:nth-child(even){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 500px;
								height: 75%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								right: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(r,-40,750);
								}
							}
						}
	
						.flex{
							flex-direction: row-reverse;
	
							.innerTxt{
								padding-left: 5.49%;
								padding-right: 4.39%;
								@include mq(md){
									@include props(pl,40,750);
									@include props(pr,0,750);
								}
							}
						}
	
						.mainTxt{
							margin-left: auto;
							margin-right: 0;
							@include mq(md){
								@include props(pl,40,750);
							}
						}
	
						.img{
							@include mq(md){
								@include props(l,-40,750);
								right: inherit;
							}
						}
					}
				}
			}

			.mizuharaComment{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,160,750);
				}
				.inner{
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					align-items: center;
					@include props(h,590);
					@include mq(md){
						background: $basicWhiteColor;
						height: inherit;
						max-height: inherit;
						@include props(pt,40,750);
						@include props(pr,35,750);
						@include props(pl,35,750);
					}

					.img{
						width: 50%;
						height: 100%;
						position: relative;
						@include mq(md){
							width: 100%;
						}

						&::after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background-color: #fff;
							opacity: 0;
							visibility: hidden;
							transition: all .3s;
						}

						&:hover{
							cursor: pointer;
							&::after{
								opacity: .3;
								visibility: visible;
							}
						}

						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.txt{
						width: 50%;
						background: $basicWhiteColor;
						height: 100%;
						@include props(pl,80);
						@include props(pr,80);
						display: flex;
						flex-direction: column;
						justify-content: center;

						.ttl{
							@include fz_vw(24);
							font-weight: bold;
							line-height: 2;
							letter-spacing: 0.04em;
							@include props(mb,70);
						}
						.read{
							@include fz_vw(20);
							line-height: 2.2;
							letter-spacing: 0.03em;
						}

						@include mq(md){
							width: 100%;
							@include props(pl,0,750);
							@include props(pr,0,750);
							@include props(pt,80,750);
							@include props(pb,80,750);

							.ttl{
								@include fz_vw(34);
								@include props(mb,80,750);
							}
							.read{
								@include fz_vw(32);
								letter-spacing: 0;
							}
						}
					}
				}
			}

			.postList{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,160,750);
				}
				.inner{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.postCard{
						width: 46.33%;
						
						&:nth-child(even){
							@include props(mt,170);
						}
						@include mq(md){
							width: 100%;
							&:nth-child(even){
								@include props(mt,160,750);
							}
						}

						.img,img{
							width: 100%;
						}

						.txt{
							background: $basicWhiteColor;
							@include props(pt,60);
							@include props(pb,40);
							@include props(pl,40);
							@include props(pr,40);

							@include mq(md){
								@include props(p,60,750);
							}

							.ttl{
								text-align: center;
								@include fz_vw(28);
								line-height: 2;;
								@include props(mb,60);
								font-family: $YuMincho;

								@include mq(md){
									@include fz_vw(36);
									@include props(mb,60,750);
								}
							}

							a{
								display: block;
								border: 1px solid #769DCD;
								@include props(w,247);
								text-align: center;
								@include props(pt,18);
								@include props(pb,18);
								margin: 0 auto;
								opacity: 1;
								transition: opacity 1s .2s;
								&:hover{
									opacity: .7;
								}

								span{
									font-family: $YuMincho;
									@include fz_vw(14);
									&:after{
										content: "";
										background: url(/images/common/blank_icon.svg) center center no-repeat;
										width: 0.9em;
										height: 0.8em;
										display: inline-block;
										background-size: contain;
										margin-left: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,35,750);
									@include props(pb,35,750);

									span{
										@include fz_vw(28);
									}
								}
							}
						}
					}
				}
			}

			.bnrBox{
				@include props(mb,300);
				img{
					margin: 0 auto;
					@include props(w,1040);
				}

				@include mq(md){
					@include props(mb,200,750);

					img{
						width: 100%;
						max-width: inherit;
					}
				}
			}

			.irohaMaiAreaTit{
				font-family: $YuMincho;
				@include fz_vw(70);
				font-weight: 500;
				line-height: 0.79;
				letter-spacing: 3.85px;
				text-align: center;
				color: #666;
				@include props(pt,200);
				@include props(mb, 200);

				@include mq(md){
					line-height: 1;
					letter-spacing: 2.1px;
					@include props(pt,160,750);
					@include props(mb, 160, 750);
				}
			}
		}
		
		.irohaPetitArea{
			position: relative;
			.irohaPetitMovieArea{
				.irohaPetitMovieAreaTit{
					font-family: $YuMincho;
					@include fz_vw(70);
					font-weight: 500;
					line-height: 0.79;
					letter-spacing: 3.85px;
					text-align: center;
					color: #666;
					@include props(pt,200);
					@include props(mb, 200);
	
					@include mq(md){
						line-height: 1.43;
						letter-spacing: 2.45px;
						@include props(pt,160,750);
						@include props(mb, 160, 750);
					}
				}
				.irohaPetitMovieBox{
					@include mq(md){
						padding: 0;
					}
				}
				.irohaPetitMovie{
					position: relative;
					@include props(mb, 160);
	
					// &:hover{
					// 	cursor: pointer;
					// 	&::after{
					// 		opacity: .3;
					// 		visibility: visible;
					// 	}
					// }
	
					@include mq(md){
						@include props(mb, 160, 750);
					}
	
					&::after{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background-color: #fff;
						opacity: 0;
						visibility: hidden;
						transition: all .3s;
					}
	
					img{
						max-width: 100%;
						width: 100%;
					}
				}
			}

			ul{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,200,750);
				}
				li{
					position: relative;
					z-index: 1;
					@include props(pt,50);
	
					@include mq(md){
						@include props(pt,420,750);
						@include props(pb,80,750);
					}
	
					&:not(:last-child){
						@include props(mb,160);
	
						@include mq(md){
							@include props(mb,160,750);
						}
					}
					
	
					.mainTxt{
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						text-align: left;
						@include props(mb,50);
						@include props(pl,60);
						width: 58.565%;
	
						@include mq(md){
							@include fz_vw(40);
							width: 100%;
							@include props(mb,60,750);
							@include props(mt,60,750);
						}
					}
	
					.flex{
						display: flex;
						align-items: flex-start;
						flex-wrap: wrap;
	
						.innerTxt{
							padding-right: 5.49%;
							padding-left: 4.39%;
							width: 36.311%;
							text-align: right;
	
							@include mq(md){
								width: 100%;
							}
	
							.descTxt{
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2.3;
								letter-spacing: normal;
								text-align: left;
								@include props(mb,50);
	
								@include mq(md){
									@include fz_vw(28);
									@include props(mb,80,750);
									line-height: 1.7;
									letter-spacing: 1.12px;
								}
							}

							#audioBtn{
								display: block;
								margin: 0 auto;
								color: #4D4D4D;
								@include props(pt,13);
								@include props(pb,13);
								@include props(pl,27);
								@include props(pr,27);
								border: 1px solid #769DCD;
								border-radius: 3px;
								transition: opacity 1s .2s;
								&:hover{
									cursor: pointer;
									opacity: .7;
								}

								span{
									@include fz_vw(16);
									font-family: $YuMincho;

									&:before{
										content: "";
										background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
										background-size: contain;
										width: 1.2em;
										height: .9em;
										display: inline-block;
										padding-right: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,31,750);
									@include props(pb,31,750);
									span{
										@include fz_vw(28);
									}
								}
							}
						}
	
						.img{
							width: 63.689%;
							box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
							position: relative;
							padding-top: 40.263%;
							
	
							@include mq(md){
								position: absolute;
								top: 0;
								@include props(r,-40,750);
								@include props(w,710,750);
								padding-top: 63.2179%;
							}
	
							img{
								width: 100%;
								height: 100%;
								position: absolute;
								top: 0;
							}
						}
					}
	
					&:nth-child(odd){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 500px;
								height: 75%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								left: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(l,-40,750);
								}
							}
						}
	
	
						.mainTxt{
							@include mq(md){
								@include props(pl,0,750);
								@include props(pr,40,750);
							}
						}
	
						.flex{
							.innerTxt{
								@include mq(md){
									@include props(pr,40,750);
									padding-left: 0;
								}
							}
						}
					}
					&:nth-child(3){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 560px;
								height: 85%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								left: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(l,-40,750);
								}
							}
						}
					}
					&:nth-child(even){
	
						.mainTxt{
							&:before{
								content: "";
								width: 58.565%;
								max-height: 500px;
								height: 75%;
								background: $basicWhiteColor;
								position: absolute;
								top: 0;
								right: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									top: inherit;
									bottom: 0;
									@include props(r,-40,750);
								}
							}
						}
	
						.flex{
							flex-direction: row-reverse;
	
							.innerTxt{
								padding-left: 5.49%;
								padding-right: 4.39%;
								@include mq(md){
									@include props(pl,40,750);
									@include props(pr,0,750);
								}
							}
						}
	
						.mainTxt{
							margin-left: auto;
							margin-right: 0;
							@include mq(md){
								@include props(pl,40,750);
							}
						}
	
						.img{
							@include mq(md){
								@include props(l,-40,750);
								right: inherit;
							}
						}
					}
				}
			}

			.mizuharaComment{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,160,750);
				}
				.inner{
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					align-items: center;
					@include props(h,590);
					@include mq(md){
						background: $basicWhiteColor;
						height: inherit;
						max-height: inherit;
						@include props(pt,40,750);
						@include props(pr,35,750);
						@include props(pl,35,750);
					}

					.img{
						width: 50%;
						height: 100%;
						position: relative;
						@include mq(md){
							width: 100%;
						}

						&::after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background-color: #fff;
							opacity: 0;
							visibility: hidden;
							transition: all .3s;
						}

						&:hover{
							cursor: pointer;
							&::after{
								opacity: .3;
								visibility: visible;
							}
						}

						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.txt{
						width: 50%;
						background: $basicWhiteColor;
						height: 100%;
						@include props(pl,90);
						@include props(pr,90);
						display: flex;
						flex-direction: column;
						justify-content: center;

						.ttl{
							@include fz_vw(24);
							font-weight: bold;
							line-height: 2;
							letter-spacing: 0.04em;
							@include props(mb,70);
						}
						.read{
							@include fz_vw(20);
							line-height: 2.2;
							letter-spacing: 0.03em;
						}

						@include mq(md){
							width: 100%;
							@include props(pl,0,750);
							@include props(pr,0,750);
							@include props(pt,80,750);
							@include props(pb,80,750);

							.ttl{
								@include fz_vw(34);
								@include props(mb,80,750);
							}
							.read{
								@include fz_vw(32);
								letter-spacing: 0;
							}
						}
					}
				}
			}

			.postList{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,200,750);
				}
				.inner{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.postCard{
						width: 46.33%;
						
						&:nth-child(even){
							@include props(mt,170);
						}
						@include mq(md){
							width: 100%;
							&:nth-child(even){
								@include props(mt,160,750);
							}
						}

						.img,img{
							width: 100%;
						}

						.txt{
							background: $basicWhiteColor;
							@include props(pt,60);
							@include props(pb,40);
							@include props(pl,40);
							@include props(pr,40);

							@include mq(md){
								@include props(p,60,750);
							}

							.ttl{
								text-align: center;
								@include fz_vw(28);
								line-height: 2;;
								@include props(mb,60);
								font-family: $YuMincho;

								@include mq(md){
									@include fz_vw(36);
									@include props(mb,60,750);
								}
							}

							a{
								display: block;
								border: 1px solid #769DCD;
								@include props(w,247);
								text-align: center;
								@include props(pt,18);
								@include props(pb,18);
								margin: 0 auto;
								opacity: 1;
								transition: opacity 1s .2s;
								&:hover{
									opacity: .7;
								}

								span{
									font-family: $YuMincho;
									@include fz_vw(14);
									&:after{
										content: "";
										background: url(/images/common/blank_icon.svg) center center no-repeat;
										width: 0.9em;
										height: 0.8em;
										display: inline-block;
										background-size: contain;
										margin-left: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,35,750);
									@include props(pb,35,750);

									span{
										@include fz_vw(28);
									}
								}
							}
						}
					}
				}
			}

			.bnrBox{
				@include props(mb,300);
				img{
					margin: 0 auto;
					@include props(w,1040);
				}

				@include mq(md){
					@include props(mb,200,750);

					img{
						width: 100%;
						max-width: inherit;
					}
				}
			}

			.irohaPetitAreaTit{
				font-family: $YuMincho;
				@include fz_vw(70);
				font-weight: 500;
				line-height: 0.79;
				letter-spacing: 3.85px;
				text-align: center;
				color: #666;
				@include props(pt,200);
				@include props(mb, 200);

				@include mq(md){
					line-height: 1;
					letter-spacing: 2.1px;
					@include props(pt,160,750);
					@include props(mb, 160, 750);
				}
			}
		}
		
		.irohaProductsArea{
			position: relative;
			.irohaProductsMovieArea{
				.irohaProductsMovieAreaTit{
					font-family: $YuMincho;
					@include fz_vw(70);
					font-weight: 500;
					line-height: 0.79;
					letter-spacing: 3.85px;
					text-align: center;
					color: #666;
					@include props(pt,200);
					@include props(mb, 200);
	
					@include mq(md){
						line-height: 1.43;
						letter-spacing: 2.45px;
						@include props(pt,160,750);
						@include props(mb, 160, 750);
					}
				}
				.irohaProductsMovieBox{
					@include mq(md){
						padding: 0;
					}
				}
				.irohaProductsMovie{
					position: relative;
					@include props(mb, 160);
	
					// &:hover{
					// 	cursor: pointer;
					// 	&::after{
					// 		opacity: .3;
					// 		visibility: visible;
					// 	}
					// }
	
					@include mq(md){
						@include props(mb, 160, 750);
					}
	
					&::after{
						content: "";
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						background-color: #fff;
						opacity: 0;
						visibility: hidden;
						transition: all .3s;
					}
	
					img{
						max-width: 100%;
						width: 100%;
					}
				}
			}

			ul{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,200,750);
				}
				li{
					position: relative;
					z-index: 1;
					@include props(pt,50);
	
					@include mq(md){
						padding-top: 66.99%;
						//@include props(pt,420,750);
						@include props(pb,80,750);
					}
	
					&:not(:last-child){
						@include props(mb,160);
	
						@include mq(md){
							@include props(mb,160,750);
						}
					}
					
	
					.mainTxt{
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						text-align: left;
						@include props(mb,40);
	
						@include mq(md){
							@include fz_vw(40);
							width: 100%;
							@include props(mb,60,750);
							@include props(mt,70,750);
						}
					}

					.new{
						display: inline-block;
						font-family: $YuGothic;
						@include fz_vw(14);
						font-weight: 500;
						text-align: center;
						border-radius: 25px;
						padding: 3px 25px 2px;
						border: solid 1px #F58D7E;
						color: #F58D7E;
						@include props(mb, 8);
	
						@include mq(md){
							@include fz_vw(26);
							line-height: 1;
							@include props(pt, 10, 750);
							@include props(pb, 8, 750);
							@include props(pl, 35, 750);
							@include props(pr, 35, 750);
							@include props(mb, 15, 750);
						}
					}

					.link{
						font-family: $YuMincho;
						@include fz_vw(16);
						font-weight: 500;
						line-height: 1;
						letter-spacing: 0.96px;
						text-align: right;
						color: #4d4d4d;
		
						@include mq(md){
							@include fz_vw(28);
							letter-spacing: 0.28px;
						}
		
						a{
							color: #4d4d4d;
						}
						.arrow{
							display: inline-block;
		
							&::after{
								content: "";
								display: inline-block;
								background-image: url(/images/index/products_arrow.svg);
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
								backface-visibility: hidden;
								@include props(ml, 10);
								width: 40px;
								height: 10px;
					
								@include mq(tab){
									width: 30px;
									height: 8px;
								}
								@include mq(md) {
									width: 30px;
									height: 8px;
									@include props(ml, 30, 750);
								}
							}
						}
					}
	
					.flex{
						display: flex;
						//align-items: flex-end;
						flex-wrap: wrap;
	
						.innerTxt{
							position: relative;
							padding-right: 5.49%;
							padding-left: 4.39%;
							width: 36.311%;
							text-align: right;
							@include props(pt,120);
	
							@include mq(md){
								position: static;
								width: 100%;
								@include props(pt,0);
							}
	
							.descTxt{
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2.3;
								letter-spacing: normal;
								text-align: left;
								@include props(mb,50);
	
								@include mq(md){
									@include fz_vw(28);
									@include props(mb,80,750);
									line-height: 1.7;
									letter-spacing: 1.12px;
								}
							}

							#audioBtn{
								display: block;
								margin: 0 auto;
								color: #4D4D4D;
								@include props(pt,13);
								@include props(pb,13);
								@include props(pl,27);
								@include props(pr,27);
								border: 1px solid #769DCD;
								border-radius: 3px;
								transition: opacity 1s .2s;
								&:hover{
									cursor: pointer;
									opacity: .7;
								}

								span{
									@include fz_vw(16);
									font-family: $YuMincho;

									&:before{
										content: "";
										background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
										background-size: contain;
										width: 1.2em;
										height: .9em;
										display: inline-block;
										padding-right: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,31,750);
									@include props(pb,31,750);
									span{
										@include fz_vw(28);
									}
								}
							}
						}
	
						.imgBox{
							width: 63.689%;
							position: relative;
							//padding-top: 40.263%;
							
							.img{
								box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
							}
	
							@include mq(md){
								position: absolute;
								top: 0;
								@include props(r,-40,750);
								@include props(w,710,750);
								padding-top: 66.99%;//63.2179%
							
								.img{
									box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
								}
							}
	
							img{
								width: 100%;
								// height: 100%;
								// position: absolute;
								// top: 0;
								// object-fit: cover;
							}
						}
					}
	
					&:nth-child(even){
	
						.innerTxt{
							&:before{
								content: "";
								width: 158.565%;
								max-height: 190%;
								height: 100%;
								background: $basicWhiteColor;
								position: absolute;
								@include props(t,60);
								left: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									//@include props(t,340,750);
									//bottom: 0;
									top: inherit;
									bottom: 0;
									@include props(l,-40,750);
								}
							}
						}
	
						.flex{
							.innerTxt{
								@include mq(md){
									@include props(pr,40,750);
									padding-left: 0;
								}
							}
						}
					}
					&:nth-child(odd){
	
						.innerTxt{
							&:before{
								content: "";
								width: 158.565%;
								max-height: 190%;
								height: 100%;
								background: $basicWhiteColor;
								position: absolute;
								@include props(t,60);
								right: 0;
								z-index: -1;
							}
		
							@include mq(md){
								&:before{
									content: "";
									@include props(w,710,750);
									height: 71.38%;
									max-height: inherit;
									//@include props(t,340,750);
									//bottom: 0;
									top: inherit;
									bottom: 0;
									@include props(r,-40,750);
								}
							}
						}
	
						.flex{
							flex-direction: row-reverse;
	
							.innerTxt{
								padding-left: 5.49%;
								padding-right: 4.39%;
								@include mq(md){
									@include props(pl,40,750);
									@include props(pr,0,750);
								}
							}
						}
	
						.imgBox{
							@include mq(md){
								@include props(l,-40,750);
								right: inherit;
							}
						}
					}
				}
			}

			.mizuharaComment{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,160,750);
				}
				.inner{
					display: flex;
					justify-content: center;
					flex-wrap: wrap;
					align-items: center;
					@include props(h,590);
					@include mq(md){
						background: $basicWhiteColor;
						height: inherit;
						max-height: inherit;
						@include props(pt,40,750);
						@include props(pr,35,750);
						@include props(pl,35,750);
					}

					.img{
						width: 50%;
						height: 100%;
						position: relative;
						@include mq(md){
							width: 100%;
						}

						&::after{
							content: "";
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background-color: #fff;
							opacity: 0;
							visibility: hidden;
							transition: all .3s;
						}

						&:hover{
							cursor: pointer;
							&::after{
								opacity: .3;
								visibility: visible;
							}
						}

						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.txt{
						width: 50%;
						background: $basicWhiteColor;
						height: 100%;
						@include props(pl,90);
						@include props(pr,90);
						display: flex;
						flex-direction: column;
						justify-content: center;

						.ttl{
							@include fz_vw(24);
							font-weight: bold;
							line-height: 2;
							letter-spacing: 0.04em;
							@include props(mb,70);
						}
						.read{
							@include fz_vw(20);
							line-height: 2.2;
							letter-spacing: 0.03em;
						}

						@include mq(md){
							width: 100%;
							@include props(pl,0,750);
							@include props(pr,0,750);
							@include props(pt,80,750);
							@include props(pb,80,750);

							.ttl{
								@include fz_vw(34);
								@include props(mb,80,750);
							}
							.read{
								@include fz_vw(32);
								letter-spacing: 0;
							}
						}
					}
				}
			}

			.postList{
				@include props(mb,200);
				@include mq(md){
					@include props(mb,200,750);
				}
				.inner{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.postCard{
						width: 46.33%;
						
						&:nth-child(even){
							@include props(mt,170);
						}
						@include mq(md){
							width: 100%;
							&:nth-child(even){
								@include props(mt,160,750);
							}
						}

						.img,img{
							width: 100%;
						}

						.txt{
							background: $basicWhiteColor;
							@include props(pt,60);
							@include props(pb,40);
							@include props(pl,40);
							@include props(pr,40);

							@include mq(md){
								@include props(p,60,750);
							}

							.ttl{
								text-align: center;
								@include fz_vw(28);
								line-height: 2;;
								@include props(mb,60);
								font-family: $YuMincho;

								@include mq(md){
									@include fz_vw(36);
									@include props(mb,60,750);
								}
							}

							a{
								display: block;
								border: 1px solid #769DCD;
								@include props(w,247);
								text-align: center;
								@include props(pt,18);
								@include props(pb,18);
								margin: 0 auto;
								opacity: 1;
								transition: opacity 1s .2s;
								&:hover{
									opacity: .7;
								}

								span{
									font-family: $YuMincho;
									@include fz_vw(14);
									&:after{
										content: "";
										background: url(/images/common/blank_icon.svg) center center no-repeat;
										width: 0.9em;
										height: 0.8em;
										display: inline-block;
										background-size: contain;
										margin-left: .7em;
									}
								}

								@include mq(md){
									@include props(w,550,750);
									@include props(pt,35,750);
									@include props(pb,35,750);

									span{
										@include fz_vw(28);
									}
								}
							}
						}
					}
				}
			}

			.bnrBox{
				@include props(mb,300);
				img{
					margin: 0 auto;
					@include props(w,1040);
				}

				@include mq(md){
					@include props(mb,200,750);

					img{
						width: 100%;
						max-width: inherit;
					}
				}
			}

			.irohaProductsAreaTit{
				font-family: $YuMincho;
				@include fz_vw(70);
				font-weight: 500;
				line-height: 0.79;
				letter-spacing: 3.85px;
				text-align: center;
				color: #666;
				@include props(pt,200);
				@include props(mb, 200);

				@include mq(md){
					line-height: 1;
					letter-spacing: 2.1px;
					@include props(pt,160,750);
					@include props(mb, 160, 750);
				}
			}
		}
	
		.campaign{
			@include props(mb,260);
			margin-left: auto;
			margin-right: auto;
			max-width: 1406px;
			padding: 0 20px;
			@include props(pt,200);
			
			@include mq(md){
				padding: 0;
				@include props(mb,140,750);
			}

			.campaignTit{
				font-family: $YuMincho;
				@include fz_vw(70);
				font-weight: 500;
				line-height: 0.79;
				letter-spacing: 3.85px;
				text-align: center;
				color: #666;
				@include props(mb, 180);

				@include mq(md){
					letter-spacing: 1.925px;
					@include props(mb, 330, 750);
				}
			}
			.campaignBox{
				position: relative;
				display: flex;
				align-items: center;
				background-color: #fff;
				background-image: url(/images/kiko_mizuhara/deco_02.svg);
				background-size: 52.19%;
				background-repeat: no-repeat;
				background-position: left 8% bottom 32%;
				@include props(pt, 110);
				@include props(pb, 120);
				@include props(pr, 110);
				@include props(pl, 50);

				@include mq(md){
					display: block;
					max-width: 94.7%;
					width: 100%;
					background-image: url(/images/kiko_mizuhara/deco_03.svg);
					background-size: 91%;
					background-position: top 26% center;
					@include props(pt, 338, 750);
					@include props(pb, 170, 750);
					@include props(pr, 80, 750);
					@include props(pl, 80, 750);
				}
			}
			.deco{
				position: absolute;
				@include props(t, -132);
				@include props(l, 55);
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: $YuMincho;
				@include fz_vw(24);
				font-weight: 500;
				line-height: 1.67;
				letter-spacing: 0.72px;
				text-align: center;
				color: #2c2c2c;
				@include props(w, 376);
				@include props(h, 371);

				@include mq(md) {
					@include props(t, -180, 750);
					@include props(l, 40, 750);
					@include fz_vw(34);
					line-height: 2;
					letter-spacing: 0.51px;
					@include props(w, 452, 750);
					@include props(h, 447, 750);
				}

				&::after{
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-image: url(/images/kiko_mizuhara/campaign_deco.svg);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					animation: rotateAnim 35s linear infinite;
				}

				@keyframes rotateAnim {
					0% {
							transform: rotate(0deg)
					}
					50% {
							transform: rotate(180deg)
					}
					100% {
							transform: rotate(360deg)
					}
				}
			}
			.txtBox{
				position: relative;
				z-index: 1;
				@include props(pt, 135);
				@include props(pr, 160);
				@include props(pl, 100);

				@include mq(md){
					@include props(pt, 0);
					@include props(pr, 0);
					@include props(pl, 0);
				}
			}
			.campaignBoxTit{
				font-family: $YuMincho;
				@include fz_vw(32);
				font-weight: 600;
				line-height: 1.8;
				letter-spacing: 0.64px;
				text-align: center;
				color: #769DCD;
				@include props(mb, 40);

				@include mq(tab){
					letter-spacing: 0.32px;
					@include fz_vw(28);
				}

				@include mq(md){
					@include fz_vw(38);
					letter-spacing: 0.2px;
					@include props(mb, 120, 750);
				}
			}
			.campaignBoxTxt{
				font-family: $YuGothic;
				@include fz_vw(18);
				font-weight: 500;
				line-height: 2.22;
				color: #4d4d4d;
				@include props(mb, 50);

				@include mq(md){
					@include fz_vw(30);
					line-height: 2;
					@include props(mb, 80, 750);
				}
			}
			.link{
				font-family: $YuMincho;
				@include fz_vw(16);
				font-weight: 500;
				line-height: 1;
				letter-spacing: 0.96px;
				text-align: right;
				color: #4d4d4d;

				@include mq(md){
					@include fz_vw(28);
					letter-spacing: 0.28px;
				}

				a{
					color: #4d4d4d;
				}
				.arrow{
					display: inline-block;

					&::after{
						content: "";
						display: inline-block;
						background-image: url(/images/index/products_arrow.svg);
						background-position: center;
						background-repeat: no-repeat;
						background-size: contain;
						backface-visibility: hidden;
						@include props(ml, 10);
						width: 40px;
						height: 10px;
			
						@include mq(tab){
							width: 30px;
							height: 8px;
						}
						@include mq(md) {
							width: 30px;
							height: 8px;
							@include props(ml, 30, 750);
						}
					}
				}
			}
			.imgBox{
				max-width: 41.807%;
				width: 100%;
				flex-shrink: 0;
				text-align: right;

				@include mq(md) {
					max-width: 100%;
					@include props(mb, 160, 750);

					img{
						width: 100%;
					}
				}

				span{
					@include fz_vw(14);
					display: inline-block;
					margin-top: .5em;

					@include mq(md){
						@include fz_vw(20);
					}
				}
			}
			.attention{
				font-family: $YuGothic;
				@include fz_vw(14);
				font-weight: 500;
				line-height: 1;
				text-align: right;
				color: #4d4d4d;
				@include props(mt, 20);

				@include mq(md) {
					@include fz_vw(20);
					@include props(mt, 20, 750);
				}
			}
		}

		.irohaCon{
			@include mq(md){
				padding: 0;
			}
		}

		.irohaArea{
			@include props(pt, 200);

			@include mq(md){
				@include props(pt, 80, 750);
			}

			.irohaAreaTit{
				font-family: $YuMincho;
				@include fz_vw(70);
				font-weight: 500;
				line-height: 0.79;
				letter-spacing: 3.85px;
				text-align: center;
				color: #666;
				@include props(mb, 250);

				@include mq(md){
					letter-spacing: 1.925px;
					@include props(mb, 260, 750);
				}
			}

			.loveMyColor{
				position: relative;
				background-color: rgba(255, 255, 255,.9);
				@include props(pt, 100);
				@include props(pb, 100);
				@include props(pr, 160);
				@include props(pl, 230);
				@include props(mb, 200);

				@include mq(md){
					max-width: 94.7%;
					width: 100%;
					margin: 0 0 0 auto;
					@include props(pt, 710, 750);
					@include props(pb, 200, 750);
					@include props(pr, 40, 750);
					@include props(pl, 40, 750);
					@include props(mb, 160, 750);
				}

				.imgBox01{
					position: absolute;
					@include props(w, 733);
					@include props(t, -50);
					right: calc(100% - 33%);
					box-shadow: 10px 10px 20px 0 #0000001a;

					@include mq(desk){
						@include props(l, -40);
					}
					@include mq(tab){
						@include props(l, -40);
					}
					@include mq(md){
						@include props(w, 710, 750);
						@include props(t, -100, 750);
						@include props(l, -40, 750);
						box-shadow: 5px 5px 10px 0 #0000001a;
					}
				}
				.txtBox{
					@include props(pl, 390);

					@include mq(desk){
						@include props(pl, 520);
					}
					@include mq(tab){
						@include props(pl, 420);
					}
					@include mq(md){
						@include props(pr, 40, 750);
						@include props(pl, 40, 750);
					}
				}
				.imgBox02{
					@include props(mt, 100);

					@include mq(md) {
						@include props(mt, 160, 750);
					}
				}
				.loveMyColorTit{
					font-family: $YuMincho;
					@include fz_vw(54);
					font-weight: 500;
					line-height: 1.26;
					letter-spacing: 3.24px;
					text-align: center;
					color: #666;
					@include props(mb, 32);

					@include mq(md){
						line-height: 1.26;
						letter-spacing: 1.405px;
						@include props(mb, 60, 750);
					}
				}
				.loveMyColorLead{
					font-family: $YuMincho;
					@include fz_vw(24);
					font-weight: 500;
					line-height: 2;
					letter-spacing: 0.48px;
					text-align: center;
					color: #4d4d4d;
					@include props(mb, 90);

					@include mq(md){
						@include fz_vw(32);
						line-height: .94;
						letter-spacing: .96px;
						@include props(mb, 110, 750);
					}
				}
				.loveMyColorTxt{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					line-height: 2;
					color: #4d4d4d;
					@include props(mb, 90);

					@include mq(md){
						@include fz_vw(28);
						@include props(mb, 80, 750);
					}
				}
				.link{
					font-family: $YuMincho;
					@include fz_vw(16);
					font-weight: 500;
					line-height: 1;
					letter-spacing: 0.96px;
					text-align: right;
					color: #4d4d4d;
					@include props(mb, 40);

					@include mq(md){
						@include fz_vw(28);
						letter-spacing: 0.28px;
						@include props(mb, 80, 750);
					}

					a{
						color: #4d4d4d;
					}
					
					.arrow{
						display: inline-block;

						&::after{
							content: "";
							display: inline-block;
							background-image: url(/images/index/products_arrow.svg);
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
							backface-visibility: hidden;
							@include props(ml, 10);
							width: 40px;
							height: 10px;

							@include mq(tab){
								width: 30px;
								height: 8px;
							}
							@include mq(md) {
								width: 30px;
								height: 8px;
								@include props(ml, 30, 750);
							}
						}
					}
				}
			}
		}
	}
}