@charset "utf-8";
#contact{
  background-image:linear-gradient(to bottom,rgba(241, 251, 252,1)120px,rgba(245, 253, 254,.6)850px,rgba(245, 253, 254,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(241, 251, 252,1)7.5vw,rgba(245, 253, 254,.6)52.63vw,rgba(245, 253, 254,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(241, 251, 252,1)16.66vw,rgba(245, 253, 254,.6)106vw,rgba(245, 253, 254,.8) 126.6vw );
	}

  .contact {
    @include props(pt,120);
    @include mq(md){
      @include props(pt,125,750);
    }

    .secTit{
      font-family: $YuMincho;
      @include fz_vw(70);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: 4.9px;
      text-align: center;
      @include props(pt,110);
      @include props(pb,110);
    
      @include mq(md){
        @include fz_vw(70);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: 0.07em;
        text-align: center;
        @include props(pt,150,750);
        @include props(pb,150,750);
      }
    }

    .secSubTit{
			font-family: $YuMincho;
			@include fz_vw(24);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: 0.48px;
			text-align: center;
			@include props(mb,50);
	
			@include mq(md){
				@include fz_vw(36);
				font-weight: 600;
				line-height: normal;
				letter-spacing: 3.6px;
				@include props(mb,60,750);
			}
		}

    .secRead {
      font-family: $YuGothic;
      @include fz_vw(18);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: center;

      @include mq(md){
				@include fz_vw(28);
        line-height: 2;
			}
    }

    .faqListTab{
      display: flex;
      justify-content: flex-start;//space-between
      @include props(mt,120);

      @include mq(md){
        @include props(mt,120,750);
      }

      li{
        cursor: pointer;
        max-width: calc(100% / 3.1);
        width: 100%;
        background-color: #e8e8e8;
        @include props(pt,30);
        @include props(pb,30);
        @include props(h,120);
        margin-right: 1.6%;

        @include mq(md){
          @include props(pt,20, 750);
          @include props(pb,20, 750);
          @include props(h,94, 750);
        }

        &:last-of-type{
          margin-right: 0;
        }

        &.active{
          background-color: #fff;
        }

        &.iroha-intimate-care{
          &::after{
            background-image: url(/images/common/iic_logo.svg);
          }
        }
        &.iroha-healthcare{
          display: none;
          &::after{
            background-image: url(/images/common/ihc_logo.svg);
          }
        }

        &::after{
          content: "";
          display: block;
          background-image: url(/images/common/iroha_logo.svg);
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          width: 100%;
          height: 100%;
        }
      }
    }

    .faqListBox{
      display: none;

      &.active{
        display: block;
      }
      &:nth-of-type(3){
        display: none;
      }
    }
    .faqList{
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      //padding: 3.6603% 2.1961%;
      background: $basicWhiteColor;
      @include props(pt,80);
      @include props(pb,40);
      @include props(pr,32);
      @include props(pl,32);
      @include mq(md){
        @include props(p,40,750);
        justify-content: space-between;
      }

      li{
        width: 13.825%;
        @include props(mh,260);
        margin-left: 1.33193%;
        margin-right: 1.33193%;
        margin-bottom: 2.4%;
        position: relative;
        //padding-top: 19.5%;
        // @include props(w,180);
        // @include props(pt,242);
        // @include props(ml,18);
        // @include props(mr,18);
        // @include props(mb,40);


        @include mq(md){
          @include props(w,282,750);
          @include props(mh,89, 750);
          margin-left: 0;
          margin-right: 0;
          @include props(mb,12,750);
          //padding-top: 15.08%;
        }

        .img{
          // position: absolute;
          // top: 0;
          width: 100%;
          height: 100%;

          a{
            width: 100%;
            height: 100%;
            display: block;

            img{
              @include mq(md){
                width: 100%;
                height: 100%;
                
              }
            }
          }
        }
        .logoTxt{
          font-family: $YuMincho;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          color: #4d4d4d;
          @include props(pt,10);
        }
      }
    }
    &Form {
      @include props(mt, 200);
      @include props(mb, 180);
      @include mq(md){
        @include props(mt, 0);
        @include props(mb, 180,750);
      }
      .secTit {
        @include props(pb,60);

        @include mq(md){
          @include props(pb,160,750);
        }
      }

      // &News {
      //   font-size: 1.4rem;
      //   line-height: 1.5;
      //   color: #cc0011;
      //   @include props(mb, 40);
      //   @include mq(md) {
      //     @include props(mb, 40, 750);
      //   }
      // }
      &Type {
        @include props(pt,40);
        @include props(pb,40);
        @include props(pl,120);
        @include props(pr,120);
        @include props(mt,120);
        @include props(mb,120);
        background-color: $basicWhiteColor;

        @include mq(md){
          @include props(pt,80,750);
          @include props(pb,80,750);
          @include props(pl,40,750);
          @include props(pr,40,750);
           @include props(mb,80,750);
        }

        &Label {
          @include fz_vw(18);
          font-family: $YuGothic;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          display: block;
          @include props(mb,50);
          @include mq(md) {
            @include fz_vw(30);
            @include props(mb,80,750);
          }
          span {
            @include fz_vw(14);
            font-size: 1.4rem;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            display: inline-block;
            color: $basicWhiteColor;
            background: #4d4d4d;
            padding: 0 0.7em;
            border-radius: 1em;
            margin-left: 1em;

            @include mq(md){
              @include fz_vw(20);
            }
          }
        }
        &Btn {
          label {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            position: relative;
            font-family: $YuGothic;
            &:not(:last-of-type) {
              @include props(mb,20);
            }
            @include mq(md){
              &:not(:last-of-type) {
                @include props(mb,60,750);
              }
            }
            span:not([class]) {
              position: relative;
              font-family: $YuGothic;
              @include fz_vw(16);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              cursor: pointer;
              @include props(pl,36);
              &::before {
                content: '';
                position: absolute;
                @include props(t,2);
                @include props(l,0);
                @include props(w,20);
                @include props(h,20);
                border: 1px solid #adadad;
                background: #fff;
                border-radius: 50%;
              }
              &::after {
                content: '';
                position: absolute;
                @include props(t,6);
                @include props(l,4);
                @include props(w,14);
                @include props(h,14);
                transform: scale(.5);
                border-radius: 50%;
              }

              @include mq(md){
                @include fz_vw(28);
                @include props(pl,50,750);
                &::before {
                  content: '';
                  position: absolute;
                  @include props(t,12,750);
                  @include props(l,0);
                  @include props(w,30,750);
                  @include props(h,30,750);
                  border: 1px solid #adadad;
                  background: #fff;
                  border-radius: 50%;
                }
                &::after {
                  content: '';
                  position: absolute;
                  @include props(t,18,750);
                  @include props(l,6,750);
                  @include props(w,22,750);
                  @include props(h,22,750);
                  transform: scale(.5);
                  border-radius: 50%;
                }
              }
            }

            h3.radioTit{
              position: relative;
              font-family: $YuGothic;
              @include fz_vw(16);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              cursor: pointer;
              padding-left: 36px;
              &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto 0;
                width: 20px;
                height: 20px;
                border: 1px solid #adadad;
                background: #fff;
                border-radius: 50%;

                @include mq(tab){
                  width: 18px;
                  height: 18px;
                }

                @include mq(md){
                  margin: 0;
                  @include props(w,30,750);
                  @include props(h,30,750);
                  @include props(t,12,750);
                }
              }
              &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 4px;
                margin: auto 0;
                width: 14px;
                height: 14px;
                transform: scale(.5);
                border-radius: 50%;

                @include mq(tab){
                  width: 12px;
                  height: 12px;
                }

                @include mq(md){
                  margin: 0;
                  @include props(w,22,750);
                  @include props(h,22,750);
                  @include props(t,18,750);
                  @include props(l,6,750);
                }
              }

              @include mq(md){
                @include fz_vw(28);
                @include props(pl,50,750);
                // &::before {
                //   content: '';
                //   position: absolute;
                //   @include props(t,12,750);
                //   @include props(l,0);
                //   @include props(w,30,750);
                //   @include props(h,30,750);
                //   border: 1px solid #adadad;
                //   background: #fff;
                //   border-radius: 50%;
                // }
                // &::after {
                //   content: '';
                //   position: absolute;
                //   @include props(t,18,750);
                //   @include props(l,6,750);
                //   @include props(w,22,750);
                //   @include props(h,22,750);
                //   transform: scale(.5);
                //   border-radius: 50%;
                // }
              }
            }
            // .attention {
            //   flex-basis: 100%;
            //   padding-left: 140px;
            //   @include mq(md) {
            //     padding-left: 30px;
            //   }
            // }
          }
          input[type="radio"],
          input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            width: 20px;
            padding: 9px;
            margin-top: 2px;
            &:checked {
              &+span {
                &::after {
                  transition: .3s;
                  transform: scale(1);
                  background: #4d4d4d;
                }
              }
              &+h3.radioTit{
                &::after {
                  transition: .3s;
                  transform: scale(1);
                  background: #4d4d4d;
                }
              }
            }
          }
          input[type="text"] {
            max-width: 400px;
            flex: 1;
            flex-basis: 100%;
            display: inline-block;
            margin-left: 30px;
            @include mq(md) {
              margin-top: 10px;
            }
          }
        }
      }
      .basic,
      .split,
      .business,
      .proposal{
        display: none;
        &.isShow {
          display: block;
        }
      }
      #online {
        @include props(mb, 120);
        @include mq(md) {
          @include props(mb, 120, 750);
          @include props(pl,40,750);
          @include props(pr,40,750);
        }

        .onlineTxt {
          font-family: $YuGothic;
          @include fz_vw(16);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          text-align: center;

          @include mq(md){
            @include fz_vw(28);
            text-align: left;
          }
        }

        .BtnTemp{
				@include props(w,500);
					@include props(mt,80);
					@include props(mb,60);
					margin-right: auto;
					margin-left: auto;
		  
					a{
            font-family: $YuMincho;
					  display: block;
					  @include fz_vw(18);
					  line-height: 2.4;
					  text-align: center;
					  text-decoration: none;
					  @include props(p,17);
            background-color: #4d4d4d;
            color: $basicWhiteColor;

            @include mq(tab){
              @include props(p,12);
              @include fz_vw(12);
            }
					}
		  
					&.circleBtnBlank{
					  a{
						border-radius: 5px;
		  
						span{
						  display: flex;
						  justify-content: center;
						  align-items: center;
						  &:after{
							content: "";
							display: inline-block;
							background-image: url(/images/contents/single/blank_icon.svg);
							background-repeat: no-repeat;
							background-position: center right;
							background-size: contain;
							@include props(w,16);
							@include props(h,14);
							@include props(ml,10);
							min-width: 6.1%;

              @include mq(tab){
                width: 10px;
                height: 10px;
                margin-left: 5px;
              }
						  }
						}
					  }
					}
		  
					@include mq(md){
					  width: 100%;
					  max-width: inherit;
					  @include props(mt,100,750);
					  @include props(mb,100,750);
		  
					  a{
						@include fz_vw(32);
            line-height: 1.5;
						@include props(p,15,750);
					  }
			
					  &.circleBtnBlank{
						a{
						  border-radius: 1.33vw;
			
						  span{
                position: relative;
							&:after{
							  @include props(w,32,750);
							  // @include props(h,30,750);
							  @include props(h,25,750);
                @include props(ml,40,750);
                position: absolute;
                right: 0;
                transform: translate(-50%,0);
							}
						  }
						}
					  }
					}
				}
      }

      &Field {
        display: none;
        opacity: 0;
        &.isShow {
          display: block;
          opacity: 1;
        }
        @include props(mt, 80);
        @include mq(md) {
          @include props(mt, 120, 750);
        }
        input, textarea {
          font-family: $YuGothic;
          @include fz_vw(16);
          line-height: 1.5;
          @include placeholder(#bbb);
          @include props(pt,17);
          @include props(pb,17);
          @include props(pl,30);
          @include props(pr,12);
          border: solid 1px #b7b7b7;
          background-color: #fff;
          width: 100%;
          border-radius: 4px;
          @include mq(md) {
            @include props(pt,30,750);
            @include props(pb,30,750);
            @include props(pl,20,750);
            @include props(pr,20,750);
            @include fz_vw(28);
          }
          &.error {
            background-color: #ffefef;
          }
        }
        textarea {
          font-family: $YuGothic;
          min-height: 270px;
          min-width: 100%;
          max-width: 100%;
          @include mq(md) {
            min-height: 115px;
          }
        }
        &>div {
          &>dl {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 30px;
            @include props(mb, 60);
            @include mq(md) {
              display: block;
              padding: 0 0 40px;
              @include props(mb, 80, 750);
              // border-bottom: solid 1px #e0e0e0;
            }
            &>dt {
              @include fz_vw(18);
              font-weight: bold;
              line-height: 2;
              flex-basis: 30%;
              max-width: 30%;
              @include props(pt,18);
              padding-right: 40px;
              @include mq(md) {
                @include fz_vw(28);
                padding: 0;
                max-width: none;
              }

              label{
                font-weight: bold;
              }
              span:not([class]) {
                @include fz_vw(14);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                display: inline-block;
                color: $basicWhiteColor;
                background: #4d4d4d;
                padding: 0 0.7em;
                border-radius: 1em;
                margin-left: 1em;
    
                @include mq(md){
                  @include fz_vw(20);
                }
              }
              h3.radioTit{
                @include fz_vw(14);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                display: inline-block;
                color: $basicWhiteColor;
                background: #4d4d4d;
                padding: 0 0.7em;
                border-radius: 1em;
                margin-left: 1em;
    
                @include mq(md){
                  @include fz_vw(20);
                }
              }
            }
            &>dd {
              flex-basis: 70%;
              max-width: 70%;
              // @include props(pl, 40);
              @include mq(md) {
                max-width: none;
                border: none;
                padding: 0;
              }
              @include mq(pc) {
                &:not(:last-of-type) {
                  padding-bottom: 40px;
                }
                &:first-of-type {
                  padding-top: 5px;
                }
                &:last-of-type {
                  padding-bottom: 5px;
                }
              }
            }
          }
        }
        dt {
          @include mq(md) {
            margin-bottom: 10px;
          }
          &.top {
            padding-top: .5em;
          }
          &:not(:last-of-type) {
            @include mq(pc) {
              @include props(mb, 40);
            }
          }
        }
        dd {
          &:not(:last-of-type) {
            @include mq(md) {
              margin-bottom: 30px;
            }
          }
          dl {
            flex-wrap: wrap;
            dt {
              // width: 94px;
              width: 25%;
              @include fz_vw(16);
              line-height: 1.5;
              font-weight: bold;
              label{
                font-family: $YuGothic;
                font-weight: bold;
                @include fz_vw(16);
                @include mq(md){
                  @include fz_vw(28);
                }
              }
            }
            dd {
              // flex-basis: calc(100% - 94px);
              width: 75%;
              &:not(:last-of-type) {
                @include props(mb, 40);
              }
              textarea {
                font-family: $YuGothic;
                min-height: 170px;
                @include mq(md) {
                  min-height: 115px;
                }
              }
            }
          }
          .flex {
            display: flex;
            input {
              flex: 1;
            }
          }
          .flex_pc {
            display: flex;
            @include mq(md) {
              padding-top: 10px;
            }

            dt{
              @include mq(md){
                flex-basis: 28%;
              }

              label{
                padding-right: 1em;
              }

              span:not([class]) {
                @include fz_vw(14);
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                display: inline-block;
                color: $basicWhiteColor;
                background: #4d4d4d;
                padding: 0 0.7em;
                border-radius: 1em;
                margin-right: 1em;
    
                @include mq(md){
                  @include fz_vw(20);
                }
              }
            }
            .center {
              @include props(pt,18);
              @include mq(md){
                padding-top: 0;
                flex-basis: 100%;
              }
            }

            dd{
              @include mq(md){
                flex-basis: 100%;
              }
            }
            input {
              flex: 1;
            }
          }
          .attention {
            font-family: $YuGothic;
            @include fz_vw(14);
            line-height: 2;
            color: #E64B4B;
            @include props(mt, 20);
            word-break: break-all;
            @include mq(md) {
              @include fz_vw(24);
              margin-top: 10px;
            }
            // &.mail {
            //   &::before {
            //     content: " ";
            //     width: 1em;
            //     height: 1em;
            //     display: inline-block;
            //     background: url(/images/contact/icon_attention.svg) center/contain no-repeat;
            //     position: relative;
            //     top: 2px;
            //   }
            // }

            a{
              color: #E64B4B;
              text-decoration: underline
            }
          }
          .errorTxt {
            font-family: $YuGothic;
            @include fz_vw(14);
            line-height: 2.29;
            color: #e64b4b;
            @include props(mt, 10);
            @include mq(md) {
              @include fz_vw(24);
              margin-top: 5px;
            }
          }
        }
        &Select {
          position: relative;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 25px;
            margin: auto;
            width: 10px;
            height: 10px;
            border-bottom: 1px solid #808080;
            border-right: 1px solid #808080;
            transform: rotate(45deg);
            transform-origin: right center;
            @include mq(md) {
              width: 7px;
              height: 7px;
              right: 20px;
            }
          }
          select {
            padding: 18px 15px;
            font-size: 1.6rem;
            line-height: 1.5;
            color: #000;
            border: solid 1px #b7b7b7;
            background-color: #fff;
          }
        }
        &Note {
          @include fz_vw(18);
          font-weight: bold;
          line-height: 1.5;
          color: #555;
          @include props(mb, 40);
          @include props(mt, 60);
          @include props(pt,40);
          width: 100%;
          border-top: 1px solid #E5E5E5;
          @include mq(md) {
            @include fz_vw(28);
            @include props(mb,40,750);
            @include props(mt, 60,750);
            @include props(pt,40,750);
          }
        }
        &NoteList {
          @include props(pl,120);
          @include props(pr,120);
          @include mq(md){
            @include props(pl,40,750);
            @include props(pr,40,750);
          }
          li {
            @include props(mb,50);
            font-family: $YuGothic;
            @include fz_vw(16);
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;

            &:last-child{
              @include props(mb,0);
            }

            @include mq(md){
              @include fz_vw(28);
              @include props(mb,80,750);
            }
          }
          span {
            display: block;
            font-family: $YuGothic;
            @include fz_vw(14);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            a{
              color: #4d4d4d;
              text-decoration: underline;
            }
            @include mq(md){
              @include fz_vw(24);
            }

            &.newformDesc{
              margin-bottom: 1em;
            }

            &.attention{
              color: #E64B4B;

              a{
                color: #E64B4B;
                text-decoration: underline;
              }
            }
          }
        }
        .radioArea,
        .checkboxArea {
          label {
            font-family: $YuGothic;
            flex-wrap: wrap;
            position: relative;
            &:not(:last-of-type) {
              @include props(mb,20,750);
            }
            &.txtIn{
              width: 100%;
              margin-right: 0 !important;
            }
            span:not([class]) {
              position: relative;
              @include fz_vw(16);
              line-height: 1.5;
              color: #333;
              cursor: pointer;
              padding-left: 33px;
              &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                width: 20px;
                height: 20px;
                margin: auto 0;
                border: 1px solid #adadad;
                background: #fff;

                @include mq(tab){
                  width: 18px;
                  height: 18px;
                }

                @include mq(md){
                  width: 18px;
                  height: 18px;
                }
              }
              &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                left: 4px;
                width: 14px;
                height: 14px;
                transform: scale(.5);
                @include mq(tab){
                  width: 12px;
                  height: 12px;
                }

                @include mq(md){
                  width: 14px;
                  height: 14px;
                  left: 3px;
                }
              }

              @include mq(md){
                @include fz_vw(28);
                // @include props(pl,70,750);
                width: 100%;
                // &::before {
                //   @include props(t,0);
                //   @include props(l,0);
                //   @include props(w,36,750);
                //   @include props(h,36,750);
                // }
                // &::after {
                //   @include props(t,6,750);
                //   @include props(l,6,750);
                //   @include props(w,28,750);
                //   @include props(h,28,750);
                // }
              }
            }
            h3.radioTit{
              position: relative;
              @include fz_vw(16);
              line-height: 1.5;
              color: #333;
              cursor: pointer;
              @include props(pl,30);
              &::before {
                content: '';
                position: absolute;
                @include props(t,2);
                @include props(l,0);
                @include props(w,18);
                @include props(h,18);
                border: 1px solid #adadad;
                background: #fff;
              }
              &::after {
                content: '';
                position: absolute;
                @include props(t,5);
                @include props(l,3);
                @include props(w,14);
                @include props(h,14);
                transform: scale(.5);
              }

              @include mq(md){
                @include fz_vw(28);
                @include props(pl,70,750);
                width: 100%;
                &::before {
                  @include props(t,0);
                  @include props(l,0);
                  @include props(w,36,750);
                  @include props(h,36,750);
                }
                &::after {
                  @include props(t,6,750);
                  @include props(l,6,750);
                  @include props(w,32,750);
                  @include props(h,32,750);
                }
              }
            }
            .attention {
              font-family: $YuGothic;
              flex-basis: 100%;
              padding-left: 140px;
              @include mq(md) {
                padding-left: 30px;
              }
            }
          }
          input[type="radio"],
          input[type="checkbox"] {
            opacity: 0;
            position: absolute;
            width: 20px;
            padding: 9px;
            margin-top: 2px;
            &:checked {
              &+span {
                &::after {
                  transition: .3s;
                  transform: scale(1);
                  background: #333;
                }
              }
            }
          }
          input[type="text"] {
            max-width: 400px;
            flex: 1;
            // flex-basis: 100%;
            display: inline-block;
            margin-left: 30px;
            @include mq(md) {
              margin-top: 10px;
            }
            &.spMax{
              @include mq(md){
                margin-left: 0;
                width: 100%;
                max-width: 100%;
              }
            }
          }
        }
        .radioArea {
          @include props(pt,20);
          @include props(pb,20);
          
          @include mq(md) {
            @include props(pt,0);
            @include props(pb,0);
          }

          &.error {
            background-color: #ffe3e3;
          }
          label {
            font-family: $YuGothic;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          
            @include mq(md) {
              &:not(:last-of-type) {
                @include props(mb,30,750);
              }
            }
          }
          span:not([class]) {
            &::before {
              border-radius: 50%;
            }
            &::after {
              border-radius: 50%;
            }
          }
          h3.radioTit{
            &::before {
              border-radius: 50%;
            }
            &::after {
              border-radius: 50%;
            }
          }
        }
        .checkboxArea {
          margin-left: 20px;
          
          @include mq(md) {
            margin-left: 15px;
          }
          label {
            font-family: $YuGothic;
            display: inline-flex;
            height: 100%;
            align-items: center;
          }
          span:not([class]) {
            &::before {
              border-radius: 3px;
            }
            &::after {
              border-radius: 3px;
            }
          }

          h3.radioTit{
            &::before {
              border-radius: 5px;
            }
            &::after {
              border-radius: 5px;
            }
          }

          &.checkboxArea02{
            @include props(p,20);
            margin-left: 0;
            display: flex;
            justify-content: left;
            flex-wrap: wrap;

            label{
              &:not(:last-of-type){
                 margin-right: 13%;
              }

              &:last-of-type{
                width: 100%;
              }
            }

            &.error{
              background: #ffe3e3;
            }
          }
        }

        .privacyPolicyBox{
          text-align: center;
          @include props(mb, 100);

          .privacyPolicy {
            display: inline-block;
            
            .checkboxArea{
              &.error{
                padding: 15px 0;
                background: #ffefef;
              }
            }
            @include mq(md) {
              @include props(mb, 140, 750);
            }
            &Txt {
              @include fz_vw(14);
              line-height: 2;
              color: #555;
              @include props(mb, 30);
              @include mq(md) {
                @include fz_vw(24);
                text-align: left;
                @include props(mb, 40, 750);
              }
              a {
                text-decoration: underline;
              }
            }
            .checkboxArea {
              margin-left: 0;
              span:not([class]) {
                @include fz_vw(14);
                line-height: 1.75;
                color: #4d4d4d;

                @include mq(md){
                  @include fz_vw(24);
                }
              }

              h3.radioTit{
                @include fz_vw(14);
                line-height: 1.75;
                color: #4d4d4d;

                @include mq(md){
                  @include fz_vw(24);
                }
              }
            }
            .errorTxt {
              font-family: $YuGothic;
              @include fz_vw(14);
              line-height: 2.29;
              color: #ff0028;
              @include props(mt, 10);
              @include mq(md) {
                margin-top: 5px;
              }
            }
          }
        }

        #basic{
          dl{
            background: $basicWhiteColor;
            @include props(p,120);
            @include props(mt,120);

            @include mq(md){
              @include props(pt,120,750);
              @include props(pb,120,750);
              @include props(pr,40,750);
              @include props(pl,40,750);
              @include props(mt,120,750);
            }
            
          }
        }

        #business{
          background: $basicWhiteColor;
          @include props(p,120);
          @include props(mt,120);
          @include props(mb,120);

          @include mq(md){
            @include props(pt,120,750);
            @include props(pb,120,750);
            @include props(pr,40,750);
            @include props(pl,40,750);
            @include props(mt,120,750);
            @include props(mb,120,750);
          }

          dl{
            padding: 0;
          }

          .attachedDocumentBox{
            &.error{
              label{
                background-color: #ffdbdb;
              }
            }
    
            label{
              position: relative;
              display: block;
              width: 100%;
              @include fz_vw(16);
              font-weight: normal;
              line-height: 1.5;
              color: #afb3b7;
              border-radius: 4px;
              border: solid 1px #b7b7b7;
              background-color: #fff;
              @include props(pt, 17);
              @include props(pb, 19);
              @include props(pr, 180);
              @include props(pl, 12);
    
              @include mq(md) {
                @include fz_vw(28);
                border-radius: 2px;
                @include props(pt, 30, 750);
                @include props(pb, 30, 750);
                @include props(pr, 280, 750);
                @include props(pl, 12, 750);
              }
            }
            input[type="file"]{
              display: none;
            }
            input[type="button"]{
              cursor: pointer;
              position: absolute;
              top: 0;
              right: 0;
              display: block;
              width: 100%;
              @include fz_vw(16);
              font-weight: normal;
              line-height: 1.5;
              border-radius: 0;
              border: none;
              background-color: #ddd;
              @include placeholder(#444);
              @include props(w, 180);
              height: 100%;
              padding: 0px;
              color: $basicColor;
      
              &.error{
                background-color: #ffdbdb;
              }
      
              @include mq(md) {
                @include fz_vw(28);
                border-radius: 2px;
                @include props(w, 280, 750);
              }
            }
            .attachedDocumentTxt{
              max-width: 100%;
              text-overflow: ellipsis;
              white-space-collapse: preserve;
              text-wrap: nowrap;
              overflow: hidden;
    
              &.active{
                color: #444;
              }
            }
          }
        }
      }
      &Note {
        // font-size: 1.4rem;
        // line-height: 2.29;
        // color: #555;
        // @include props(mt, 60);
        // @include props(mb, 120);
        // @include mq(md) {
        //   line-height: 1.68;
        //   @include props(mt, 100, 750);
        //   @include props(mb, 120, 750);
        // }
        font-family: $YuGothic;
        @include fz_vw(14);
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: left;
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;
        @include mq(md){
          @include fz_vw(24);
          @include props(pl,40,750);
          @include props(pr,40,750);
        }
      }
      .boxBtn_black {
        background: #4d4d4d;
        color: $basicWhiteColor;
        display: block;
        margin: 0 auto;
        @include props(w,400);
        @include props(p,16);
        @include props(mb,120);
        border-radius: 5px;

        @include mq(md){
          @include props(p,30,750);
          @include props(w,590,750);
          @include props(mb,80,750);
        }

        &:hover{
          cursor: pointer;

          span{
            &:after{
              animation: 1s arrow linear forwards;
            }
          }
        }

        span{
          font-family: $YuMincho;
          @include fz_vw(18);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.67;
          letter-spacing: 0.36px;
          position: relative;

          @include mq(md){
            @include fz_vw(28);

            // &:after{
            //   @include props(w,71,750);
            //   @include props(h,16,750);
            //   @include props(r,-20,750);
            // }
          }

          &:after{
            content: "";
            background: url(/images/common/arrow_next_white.svg) bottom center no-repeat;
            width: 2.55em;
            height: 1.55em;
            position: absolute;
            bottom: 0%;
            right: -2.77em;
            background-size: contain;
            // transform: translate(100%,-50%);
            // transition: all .3s;
            // transition: transform .5s;
            // transform: translate(100%,-50%)scale(1, 1);
            // transform-origin: left top;
          }
        }
        &:disabled {
          opacity: .5;
        }

        &:hover{
          span{
            &:after{
              animation: 1s arrow linear forwards;
            }
          }
        }
      }
    }
    &Confirm {
      @include props(mb, 80);
      @include mq(md) {
        @include props(mb, 140, 750);
      }
      &Tit {
        font-size: 2.6rem;
        font-weight: 500;
        line-height: 1.46;
        color: #555;
        @include props(mb, 20);
        @include mq(md) {
          @include props(mb, 40, 750);
        }
      }
      &Txt {
        font-size: 1.6rem;
        line-height: 2;
        color: #555;
        @include props(mb, 30);
        @include mq(md) {
          @include props(mb, 100, 750);
        }
      }
      &Area {
        background-color: $basicWhiteColor;
        @include props(p,120);
        @include props(mb, 100);
        @include mq(md) {
          @include props(pt,120,750);
          @include props(pb,120,750);
          @include props(pl,40,750);
          @include props(pr,40,750);
          @include props(mb, 140, 750);
        }
        .secRead{
          @include props(mb,120);
          @include mq(md){
            @include props(mb,120,750);
          }
        }
        dl {
          display: flex;
          flex-wrap: wrap;
          @include fz_vw(16);
          line-height: 1.5;
          // @include props(mb, 60);
          @include mq(md) {
            display: block;
            @include fz_vw(28);
            @include props(mb, 60, 750);

            .small{
              border-bottom: 1px solid #E5E5E5;
              @include props(mb,80,750);
              @include props(pb,20,750);
            }
          }
          // &:not(:last-child) {
          //   border-bottom: solid 1px #e0e0e0;
          //   @include props(pb, 60);
          //   @include props(mb, 60);
          //   @include mq(md) {
          //     @include props(pb, 80, 750);
          //     @include props(mb, 80, 750);
          //   }
          // }
          dt {
            color: #000;
            font-weight: 500;
            @include mq(pc) {
              width: 30%;
              // &.small {
              //   width: 256px;
              //   &+dd {
              //     width: calc(100% - 256px);
              //   }
              // }
              &:not(:last-child) {
                @include props(mb, 60);
              }
            }
            &:not(.small){
              @include mq(md) {
                @include props(mb, 40, 750);
              }
            }
          }
          dd {
            color: #555;
            @include mq(pc) {
              width: 70%;
            }
            &:not(:last-child) {
              @include props(mb, 60);
              @include mq(md) {
                @include props(mb, 80, 750);
              }
            }
          }
        }
        .flex {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0px !important;
          @include mq(md) {
            margin-bottom: 0px !important;
            @include props(pl,40,750);

            dt{
              width: 100%;
            }

            dd{
              width: 100%;
              &:not(:last-of-type){
                margin-bottom: 10.6666vw !important;
              }
            }
          }
          @include mq(pc) {
            dt {
              width: 25%;
              font-weight: normal;
            }
            dd {
              width: 75%;
            }
          }
        }
      }
      &Btn {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 860px;
        margin: auto;

        @include props(pl,40,750);
        @include props(pr,40,750);
        &Back {
          flex-basis: 46.5%;
          background: $basicWhiteColor;
          text-align: center;
          border-radius: 5px;
          @include mq(md) {
            flex-basis: 100%;
            @include props(mb,80,750);
          }
          a {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right-color: #fff;
            @include props(h,80);
            @include mq(md) {
              flex-basis: 100%;
              @include props(h,120,750);
            }
            span{
              font-family: $YuMincho;
              @include fz_vw(18);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.67;
              letter-spacing: 0.36px;
              position: relative;
    
              @include mq(md){
                @include fz_vw(28);
    
                &:before{
                  @include props(w,33,750);
                  @include props(h,32,750);
                  @include props(l,-30,750);
                }
              }
    
              &:before{
                content: "";
                background: url(/images/common/contact_back_icon.svg) center center no-repeat;
                @include props(w,19);
                @include props(h,17);
                position: absolute;
                top: 50%;
                @include props(l,-15);
                background-size: contain;
                transform: translate(-100%,-50%);
                transition: all .3s;
              }
            }
            // @include mq(md) {
            //   display: flex;
            //   flex-direction: row-reverse;
            // }
            // &::after {
            //   left: 25px;
            //   @include mq(md) {
            //     position: relative;
            //     left: inherit;
            //   }
            // }
          }
        }
        &Submit {
          flex-basis: 46.5%;
          background: #4d4d4d;
          border-radius: 5px;

          @include mq(md) {
            flex-basis: 100%;
          }
          button {
            width: 100%;
            color: $basicWhiteColor;
            @include props(h,80);
            @include mq(md) {
              flex-basis: 100%;
              @include props(h,120,750);
            }
            &:hover{
              cursor: pointer;

              span{
                &::after{
                  animation: 1s arrow linear forwards;
                }
              }
            }

            span{
              font-family: $YuMincho;
              @include fz_vw(18);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.67;
              letter-spacing: 0.36px;
              position: relative;
    
              @include mq(md){
                @include fz_vw(28);
              }
    
              &:after{
                content: "";
                background: url(/images/common/arrow_next_white.svg) center center no-repeat;
                position: absolute;
                background-size: contain;
                width: 2.55em;
                height: 1.55em;
                bottom: -.3em;
                right: -2.77em;
              }
            }
          }
        }
      }
    }
    &Complete {
      text-align: center;
      @include props(mb, 120);
      @include mq(md) {
        @include props(mb, 120, 750);
        @include props(pr, 40, 750);
        @include props(pl, 40, 750);
      }

      .secRead {
        line-height: 2;
        @include mq(md) {
          text-align: left;
        }
      }
    }

      .btnLayout{
        text-align: center;
        .boxBtn_back {
          background: #4d4d4d;
          color: $basicWhiteColor;
          display: block;
          margin: 0 auto;
          @include props(w,400);
          @include props(p,20);
          @include props(mb,350);
          border-radius: 5px;

          @include mq(md){
            @include props(p,30,750);
            @include props(w,590,750);
            @include props(mb,200,750);
          }

          &:hover{
            cursor: pointer;

            span{
              &:after{
                animation: 1s arrow linear forwards;
              }
            }
          }

          span{
            font-family: $YuMincho;
            @include fz_vw(18);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.67;
            letter-spacing: 0.36px;
            position: relative;

            @include mq(md){
              @include fz_vw(28);
            }

            &:after{
              content: "";
              background: url(/images/common/arrow_next_white.svg) center center no-repeat;
              width: 2.55em;
              height: 1.55em;
              position: absolute;
              bottom: -.3em;
              right: -3.05em;
              background-size: contain;
            }
          }
        }
      }
  }

  .detailModalContent {
    width: 100%;
    height: 100%;
    margin: 0;
    min-height: 100%;
    background: $basicWhiteColor;
    position: fixed;
    display: none;
    z-index: 100000;
    display: none;
  
    .close {
        position: absolute;
        @include props(t,70);
        @include props(r,100);
        z-index: 100;
        opacity: 1;
        @include mq(md) {
          @include props(t,40,750);
          @include props(r,40,750);
        }
  
        a{
          display: block;
          text-indent: -9999px;
          @include props(w,60);
          @include props(h,60);
          background: url(/images/common/modal_close_btn.svg) no-repeat;
          background-size: contain;
  
          @include mq(md){
            @include props(w,48,750);
            @include props(h,48,750);
            background-size: contain;
          }
        }
      }
  
      .modalInner{
        overflow-y: auto;
        position: relative;
        margin: 0 auto;
        text-align: center;
        @include props(pt,240);
        @include props(pb,240);
        height: 100vh;
        background: $basicWhiteColor;
  
        @include mq(md){
          @include props(pt,240,750);
          @include props(pb,240,750);
        }

        .minContainer{
          max-width: 1110px;
          padding: 0 20px;
          margin: auto;

          @include mq(md){
            @include props(pl,80,750);
            @include props(pr,80,750);
          }

          .modalTit{
            font-family: $YuMincho;
            @include fz_vw(24);
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 2.4px;
            text-align: center;
            @include props(mb,40);

            @include mq(md){
              @include fz_vw(54);
              @include props(mb,80,750);
              line-height: 1.78;
              letter-spacing: 5.4px;
            }
          }

          .modalSubTit{
            font-family: $YuGothic;
            @include fz_vw(16);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            text-align: center;
            @include props(mb,120);
            @include mq(md){
              @include fz_vw(28);
              @include props(mb,80,750);
            }
          }

          .modalDateTit{
            font-family: $YuGothic;
            @include fz_vw(20);
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.4;
            letter-spacing: 0.4px;
            text-align: left;
            @include props(mb,40);

            @include mq(md){
              @include fz_vw(32);
              @include props(mb,40,750);
            }
          }

          .modalDateList{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            border-top: 1px solid #E5E5E5;
            dt{
              flex-basis: 33.79%;
              font-family: $YuGothic;
              @include fz_vw(18);
              font-weight: 600;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.22;
              letter-spacing: 0.9px;
              text-align: left;
              border-bottom: 1px solid #E5E5E5;
              @include props(pb,30);
              @include props(pt,30);

              @include mq(md){
                @include fz_vw(30);
                @include props(pt,40,750);
                @include props(pb,0,750);
                border-bottom: none;
                flex-basis: 100%;
              }
            }

            dd{
              flex-basis: 66.21%;
              font-family: $YuGothic;
              @include fz_vw(18);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.22;
              letter-spacing: 0.9px;
              text-align: left;
              border-bottom: 1px solid #E5E5E5;
              @include props(pb,30);
              @include props(pt,30);
              @include clearfix;

              @include mq(md){
                @include fz_vw(30);
                @include props(pt,40,750);
                @include props(pb,40,750);
                flex-basis: 100%;
              }

              img {
                @include props(mt,50);
                @include props(mb,50);
            
                &.alignleft{
                  display: inline;
                  float: left;
                  margin: 0 auto;
                  @include props(mr,20);
                }
            
                &.aligncenter{
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                }
            
                &.alignright{
                  display: inline;
                  float: right;
                  margin: 0 auto;
                  @include props(ml,20);
                }
            
                @include mq(md){
                  @include props(mt,30,750);
                  @include props(mb,30,750);
            
                  &.alignleft{
                  @include props(mr,30,750);
                  }
            
                  &.alignright{
                  @include props(ml,30,750);
                  }
                }
              }
            }
          }
        }
      }
    }
  
}