@charset "utf-8";

/* =====================================
ヘッダー
===================================== */

#header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(/images/common/header_back.png);
  background-repeat: repeat-x;
  background-position: top;
  background-size: auto 100%;
  transition: background-color .5s;

  &.changeColor{
    background-image: none;
    background-color: rgba($color: #fff, $alpha: .7);
  }

  &.changeHeight {
    background-color: #fff;

    .headerContents{
      @include props(h, 80);

      @include mq(md) {
        @include props(h, 80, 750);
      }
    }

    .headerLogo{
      @include props(w, 106);

      @include mq(md) {
        @include props(w, 106, 750);
      }
    }

    .pcGlobalNav{
      @include mq(pc) {
        @include props(pl, 50);

        ul{
          .pcGlobalNavList{
            @include props(mr, 50);
    
            a{
              @include fz_vw(16);
              letter-spacing: 0.8px;
            }
          }
        }
      }
    }
  }

  .headerContents{
    position: relative;
    display: flex;
    align-items: center;
    transition: .5s;
    @include props(pr, 40);
    @include props(pl, 40);
    @include props(h, 120);

    @include mq(md) {
      @include props(pr, 40, 750);
      @include props(pl, 40, 750);
      @include props(h, 125, 750);
    }
  }

  .headerLogo{
    @include props(w, 155);
    flex-shrink: 0;
    transition: .5s;

    @include mq(md) {
      @include props(w, 155, 750);
    }

    a{
      display: block;
      max-width: 100%;
      width: 100%;
    }

    img{
      width: 100%;
    }
  }

  .navBtn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    @include props(pr, 40, 750);
    @include props(w, 100, 750);
    @include props(h, 28, 750);
    cursor: pointer;

    span {
      display: inline-block;
      position: absolute;
      @include props(r, 40, 750);
      @include props(w, 60, 750);
      height: 1px;
      background-color: #4d4d4d;
      transition: transform .5s ease-in-out , opacity .5s ease-in-out;
      &:nth-of-type(1) {
        @include props(w, 50, 750);
        @include props(t, 28, 750);
      }
      &:nth-of-type(2) {
        top: 49%;
        @include props(w, 30, 750);
      }
      &:nth-of-type(3) {
        @include props(b, 28, 750);
      }
    }
    &.isOpen {
      span {
        &:nth-of-type(1),
        &:nth-of-type(3) {
          top: 50%;
          background-color: #222;
        }
        &:nth-of-type(1) {
          @include props(w, 60, 750);
          transform: rotate(-150deg);
          height: 2px;
          z-index: 1;

          &::before{
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: -1px;
            left: 0;
            z-index: -1;
          }
          &::after{
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: 2px;
            left: 0;
            z-index: -1;
          }
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: rotate(150deg);
        }
      }
    }
  }

  .navArea{
    @include mq(md) {
      position: fixed;
      top: 0;
      right: 0;
      max-width: 69.3%;
      width: 100%;
      height: 100%;
      z-index: 998;visibility: hidden;
      -webkit-overflow-scrolling: touch;
      transition: visibility .7s .8s;

      &.isShow {
        visibility: visible;
        transition: visibility .7s;
        &::after {
          transition: transform .7s;
          transform: translateX(0);
        }
        .content {
          transition: opacity .8s .8s, transform .7s .8s;
          opacity: 1;
          transform: translateY(0);
        }
      }
  
      &::after{
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: -5px 0 20px 0 #0000000d;
        background-image: linear-gradient(to top, #ffffffcb 74%, #ffffff80);
        backdrop-filter: blur(5px);
        z-index: -1;
        transform: translateX(100%);
        transition: transform .7s .9s;
      }
      
      .content{
        overflow-y: scroll;
        width: 100%;
        height: 100%;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity .8s, transform .7s;
        @include props(pt, 160, 750);
        @include props(pb, 130, 750);
        @include props(pr, 40, 750);
        @include props(pl, 110, 750);
      }
    }
  }

  .pcGlobalNav{
    transition: .5s;
    @include props(pl, 80);

    @include mq(md) {
      @include props(pl, 0);
    }

    ul{
      display: flex;
      align-items: center;

      @include mq(md) {
        display: block;
      }

      .pcGlobalNavList{
        @include props(mr, 60);

        a{
          display: block;
          font-family: $YuMincho;
          @include fz_vw(18);
          font-weight: 800;
          line-height: 1;
          letter-spacing: 0.9px;
          color: #666;
          transition: opacity .3s;

          &:hover{
            opacity: .7;
          }
        }

        @include mq(md) {
          @include props(mb, 80, 750);
          @include props(mr, 0);
  
          a{
            @include fz_vw(40);
            font-weight: 500;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .externalLink{
    position: absolute;
    display: flex;
    align-items: center;
    @include props(t, 20);
    @include props(r, 40);

    @include mq(tab) {
      @include props(t, 0);
      align-items: baseline;
    }

    @include mq(md) {
      position: static;
      flex-wrap: wrap;
      @include props(mt, 120, 750);
    }

    .externalLinkBtn{
      flex-shrink: 0;
      @include props(ml, 20);

      @include mq(md) {
        @include props(ml, 0);
      }

      &.store{
        @include props(mr, 10);

        @include mq(md) {
          max-width: 100%;
          width: 100%;
          @include props(mb, 80, 750);
          @include props(mr, 0);
        }

        a{
          font-family: $YuMincho;
          @include fz_vw(14);
          line-height: 1;
          letter-spacing: 0.28px;
          color: #666;

          &::before{
            content: "";
            display: inline-block;
            vertical-align: baseline;
            background-image: url(/images/common/cart_icon_01.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include props(w, 18);
            @include props(h, 16);
            @include props(mr, 10);
          }

          @include mq(md) {
            @include fz_vw(34);
            letter-spacing: 0.65px;
  
            &::before{
              @include props(w, 38, 750);
              @include props(h, 35, 750);
              @include props(mr, 15, 750);
            }
          }
        }
      }
      &.twitter{
        @include props(w, 19);

        @include mq(md) {
          @include props(w, 45, 750);
          @include props(mr, 80, 750);

          img{
            width: 100%;
          }
        }
      }
      &.instagram{
        @include props(w, 18);

        @include mq(md) {
          @include props(w, 45, 750);

          img{
            width: 100%;
          }
        }
      }
    }
  }

  #productsSubNav{
    display: none;
  }
}