@charset "UTF-8";
/* ================================================================================
値の定義
================================================================================ */
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Zen+Antique&family=Zen+Maru+Gothic&display=swap");
/* ================================================================================
importファイル
================================================================================ */
/* ================================================================================
VW設定
================================================================================ */
/*PC設定*/
/*desk設定*/
/*tab設定*/
/*SP設定*/
/*フォントサイズ*/
/*余白計算*/
/*余白計算（一括指定）*/
/*Placeholder*/
/* EXTEND------------------------------------- */
/* ===================================
リセット
=================================== */
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  font: inherit;
  background: transparent;
  vertical-align: baseline;
}

html {
  text-size-adjust: 100%;
}

body {
  *font: x-small;
  color: #555;
  font-size: 62.5%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  body {
    font-size: 100%;
  }
}

html,
body {
  height: 100%;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: '';
  content: none;
}

q:before, q:after {
  content: '';
  content: none;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

fieldset {
  margin: 0 2px;
  border: 1px solid #999;
  padding: .35em .625em .75em;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  outline: none;
  background: transparent;
  text-decoration: none;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:link {
  color: #4d4d4d;
  outline: none;
}

a:visited {
  color: #4d4d4d;
  outline: none;
}

a:hover {
  text-decoration: none;
  outline: none;
}

a:focus {
  text-decoration: none;
}

ins {
  color: #000;
  background: #ff9;
  text-decoration: none;
}

mark {
  color: #000;
  background-color: #ff9;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted inherit;
  cursor: help;
}

table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

table th,
table td {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

hr {
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  display: block;
  overflow: visible;
  border-top: 1px solid #999;
}

img {
  max-width: 100%;
  display: block;
  font-size: 0;
  line-height: 0;
  height: auto;
  vertical-align: bottom;
}

input,
select {
  vertical-align: middle;
}

button,
select {
  text-transform: none;
}

select {
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

select::-ms-expand {
  display: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
  background: initial;
}

input[type='button'],
input[type='submit'],
input[type='search'],
input[type='reset'],
button {
  -webkit-appearance: none;
}

label {
  font-weight: normal;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

input {
  padding: 0;
}

input::-ms-clear, input::-ms-reveal {
  visibility: hidden;
}

[type='checkbox'],
[type='radio'] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: #ccc;
  opacity: 1;
}

:-moz-placeholder {
  color: #ccc;
}

::-moz-placeholder {
  color: #ccc;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #ccc;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy='true'] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  margin: 0;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

input,
textarea,
select {
  font-size: inherit;
  font-weight: inherit;
}

[hidden][aria-hidden='false'] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

[hidden][aria-hidden='false']:focus {
  clip: auto;
}

strong {
  font-weight: bold;
}

/* =========================================
印刷時の定義
========================================== */
@media print {
  section {
    display: block;
    page-break-before: always;
  }
  pre,
  code {
    page-break-after: always;
  }
  @page {
    margin-top: .4cm;
    margin: .5cm;
  }
  p,
  a,
  code,
  pre {
    word-wrap: break-word;
  }
  pre a[href]:after {
    content: "";
  }
  pre abbr[title]:after {
    content: "";
  }
  pre .ir a:after,
  pre a[href^='javascript:']:after,
  pre a[href^='#']:after {
    content: '';
  }
  .noPrint {
    display: none !important;
  }
  body {
    width: 1280px;
    font-size: 12pt;
    transform: scale(0.8);
    transform-origin: 0 0;
    -webkit-print-color-adjust: exact;
  }
  body .continer {
    padding-top: 0;
  }
}

/* ======================================================================
基本設定
===================================================================== */
* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */
#responsibleCheck {
  width: 1px;
  height: 0;
}

@media screen and (max-width: 767px) {
  #responsibleCheck {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */
@media screen and (max-width: 767px) {
  .pcOnly {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .spOnly {
    display: block !important;
  }
}

html {
  font-size: 62.5%;
  visibility: hidden;
}

html.wf-active, html.loading-delay {
  visibility: visible;
}

body {
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
  overflow-x: hidden;
}

body.fixedBody {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
}

body.fixedBody #header.scrollDown .headerContents .navBtnBox {
  background-color: rgba(0, 0, 0, 0);
}

/* ハイトカラー消去
------------------------------------------------------------- */
.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;
}

main.fixHeader {
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  main.fixHeader {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  main.fixHeader {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  main.fixHeader {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  main.fixHeader {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  main.fixHeader {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  main.fixHeader {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  main.fixHeader {
    padding-top: 9.15081vw;
  }
}

main article section {
  display: block;
  width: 100%;
  position: relative;
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .maxContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .maxContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .maxContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .maxContainer {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .maxContainer {
    padding-left: 2.92826vw;
  }
}

.container {
  max-width: 1520px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .container {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .container {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .container {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .container {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding-left: 2.92826vw;
  }
}

.minContainer {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .minContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .minContainer {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .minContainer {
    padding-left: 2.92826vw;
  }
}

a {
  color: #4d4d4d;
}

/* 共通デザイン
------------------------------------------------------------- */
.external::after {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  background-image: url(/images/common/blank_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.75vw;
  max-width: 12px;
  height: 0.75vw;
  max-height: 12px;
  margin-left: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  .external::after {
    margin-left: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .external::after {
    margin-left: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .external::after {
    margin-left: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  .external::after {
    width: 4.8vw;
    max-width: 36px;
    height: 4.13333vw;
    max-height: 31px;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .external::after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .external::after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .external::after {
    margin-left: 1.46413vw;
  }
}

.external.white::after {
  background-image: url(/images/common/blank_icon_white.svg);
  width: 1.125vw;
  max-width: 18px;
  height: 1vw;
  max-height: 16px;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  .external.white::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .external.white::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .external.white::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .external.white::after {
    width: 4.8vw;
    max-width: 36px;
    height: 4.13333vw;
    max-height: 31px;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .external.white::after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .external.white::after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .external.white::after {
    margin-left: 1.46413vw;
  }
}

.external.white.small::after {
  width: 0.9375vw;
  max-width: 15px;
  height: 0.875vw;
  max-height: 14px;
  margin-left: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  .external.white.small::after {
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .external.white.small::after {
    margin-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .external.white.small::after {
    margin-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .external.white.small::after {
    width: 4vw;
    max-width: 30px;
    height: 3.46667vw;
    max-height: 26px;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .external.white.small::after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .external.white.small::after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .external.white.small::after {
    margin-left: 1.46413vw;
  }
}

.btn01 {
  background-color: inherit;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(247, 245, 244, 0);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
  width: 40.625vw;
  max-width: 650px;
}

@media screen and (max-width: 767px) {
  .btn01 {
    width: 73.33333vw;
    max-width: 550px;
  }
}

.btn01 a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  z-index: 2;
  background-color: inherit;
  padding-top: 7.5vw;
  padding-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  .btn01 a {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  .btn01 a {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 4.39239vw;
  }
}

.btn01 a span {
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: 500;
  line-height: 1.7;
  letter-spacing: 1.2px;
  color: #4d4d4d;
  position: relative;
}

@media screen and (min-width: 1920px) {
  .btn01 a span {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span {
    font-size: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a span {
    font-size: 4vw;
  }
}

.btn01 a span::before {
  content: "";
  display: block;
  position: absolute;
  top: -50%;
  left: -1.875vw;
  transform: scale(0);
  z-index: -1;
  background-image: url(/images/common/btn_circles02.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: screen;
  backface-visibility: hidden;
  opacity: 1;
  transition-delay: .2s;
  backface-visibility: hidden;
  width: 8.75vw;
  max-width: 140px;
  height: 8.75vw;
  max-height: 140px;
}

@media screen and (min-width: 1600px) {
  .btn01 a span::before {
    left: -30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::before {
    left: -1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::before {
    left: -2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a span::before {
    left: -2.66667vw;
    width: 18.66667vw;
    max-width: 140px;
    height: 18.66667vw;
    max-height: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a span::before {
    left: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::before {
    left: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::before {
    left: -1.46413vw;
  }
}

.btn01 a span::after {
  content: "";
  display: block;
  position: absolute;
  top: -4.375vw;
  right: -2.5vw;
  transform: scale(0);
  z-index: -1;
  background-image: url(/images/common/btn_circles03.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: screen;
  opacity: 1;
  backface-visibility: hidden;
  transition-delay: .3s;
  backface-visibility: hidden;
  width: 5.625vw;
  max-width: 90px;
  height: 5.625vw;
  max-height: 90px;
}

@media screen and (min-width: 1600px) {
  .btn01 a span::after {
    top: -70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::after {
    top: -4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::after {
    top: -5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  .btn01 a span::after {
    right: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::after {
    right: -2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::after {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a span::after {
    top: -9.33333vw;
    right: -5.33333vw;
    width: 10.66667vw;
    max-width: 80px;
    height: 10.66667vw;
    max-height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a span::after {
    top: -70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::after {
    top: -4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::after {
    top: -5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a span::after {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a span::after {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a span::after {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a {
    font-size: 3.6rem;
    font-size: 1.875vw;
    letter-spacing: .72px;
    padding-top: 15.33333vw;
    padding-bottom: 8vw;
    padding-left: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .btn01 a {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .btn01 a {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a {
    padding-top: 115px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-top: 7.56579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-top: 8.41874vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a {
    padding-left: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a {
    padding-left: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a {
    padding-left: 10.98097vw;
  }
}

.btn01 a:hover {
  cursor: pointer;
}

.btn01 a:hover::before {
  animation: 1s circle1 linear forwards !important;
}

.btn01 a:hover::after {
  animation: 1s arrow linear forwards;
}

.btn01 a:hover span::before {
  animation: 1s circle1 linear forwards !important;
  animation-delay: .2s !important;
}

.btn01 a:hover span::after {
  animation: 1s circle1 linear forwards !important;
  animation-delay: .4s !important;
}

.btn01 a::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 1.25vw;
  left: 0vw;
  z-index: -1;
  background-image: url(/images/common/btn_circles01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  mix-blend-mode: screen;
  opacity: 1;
  backface-visibility: hidden;
  backface-visibility: hidden;
  width: 11.4375vw;
  max-width: 183px;
  height: 11.4375vw;
  max-height: 183px;
  transform: scale(0);
}

@media screen and (min-width: 1600px) {
  .btn01 a::before {
    bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::before {
    bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::before {
    bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  .btn01 a::before {
    left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::before {
    left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::before {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a::before {
    bottom: 2.66667vw;
    left: 5.33333vw;
    width: 24.4vw;
    max-width: 183px;
    height: 24.4vw;
    max-height: 183px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a::before {
    bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::before {
    bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::before {
    bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a::before {
    left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::before {
    left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::before {
    left: 2.92826vw;
  }
}

.btn01 a::after {
  content: "";
  display: block;
  background-image: url(/images/common/btn_arrow_01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform 1s;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 10.25vw;
  max-width: 164px;
  height: 1.0625vw;
  max-height: 17px;
}

@media screen and (min-width: 1600px) {
  .btn01 a::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .btn01 a::after {
    margin-left: 1.33333vw;
    width: 21.86667vw;
    max-width: 164px;
    height: 2.26667vw;
    max-height: 17px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .btn01 a::after {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .btn01 a::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .btn01 a::after {
    margin-left: 0.73206vw;
  }
}

.btn01.circleIn a::before {
  animation: 1s circle2 linear none;
  animation-delay: .5s;
}

.btn01.circleIn a span::before {
  animation: 1s circle2 linear none;
  animation-delay: .7s;
}

.btn01.circleIn a span::after {
  animation: 1s circle2 linear none;
  animation-delay: .9s;
}

@keyframes arrow {
  0% {
    transform: scale(1, 1);
    transform-origin: right top;
  }
  50% {
    transform: scale(0, 1);
    transform-origin: right top;
  }
  51% {
    transform: scale(0, 1);
    transform-origin: left top;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}

@keyframes back {
  0% {
    transform: scale(1, 1);
    transform-origin: left top;
  }
  50% {
    transform: scale(0, 1);
    transform-origin: left top;
  }
  51% {
    transform: scale(0, 1);
    transform-origin: right top;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: right top;
  }
}

@keyframes circle1 {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes circle2 {
  0% {
    transform: scale(0.3);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes topArrow {
  0% {
    transform: scale(1, 1);
    transform-origin: right top;
  }
  50% {
    transform: scale(1, 0);
    transform-origin: right top;
  }
  51% {
    transform: scale(1, 0);
    transform-origin: right bottom;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: right bottom;
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
.pageTopBox {
  position: relative;
  z-index: 1;
  text-align: right;
  margin-top: 18.75vw;
}

@media screen and (min-width: 1600px) {
  .pageTopBox {
    margin-top: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    margin-top: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    margin-top: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox {
    margin-top: 14.64129vw;
  }
}

.pageTopBox .pageTop {
  cursor: pointer;
  display: inline-block;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.9px;
  text-align: center;
  color: #666;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 1920px) {
  .pageTopBox .pageTop {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 2.4rem;
    font-size: 1.25vw;
    letter-spacing: 0.6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .pageTopBox .pageTop {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .pageTopBox .pageTop {
    font-size: 3.2vw;
  }
}

.pageTopBox .pageTop:hover::before {
  animation: 1s topArrow linear forwards;
}

.pageTopBox .pageTop::before {
  content: "";
  display: block;
  background-image: url(/images/common/page_top_icon.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 1.875vw;
  width: 0.6875vw;
  max-width: 11px;
  height: 5vw;
  max-height: 80px;
  margin-bottom: 0.375vw;
}

@media screen and (min-width: 1600px) {
  .pageTopBox .pageTop::before {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-left: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 2.66667vw;
    margin-left: 6.66667vw;
    width: 1.33333vw;
    max-width: 10px;
    height: 10.66667vw;
    max-height: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .pageTopBox .pageTop::before {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .pageTopBox .pageTop::before {
    margin-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .pageTopBox .pageTop::before {
    margin-left: 3.66032vw;
  }
}

/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 2.5vw;
  padding-bottom: 1.25vw;
  clear: both;
}

@media screen and (min-width: 1600px) {
  .breadcrumb {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb {
    padding-top: 4vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb {
    padding-bottom: 3.66032vw;
  }
}

.breadcrumb ol {
  position: relative;
  width: 100%;
  word-break: break-all;
  text-align: left;
  line-height: 1;
  color: #acabab;
  margin: 0 auto;
}

.breadcrumb ol::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  .breadcrumb ol {
    max-width: initial;
  }
}

.breadcrumb ol > li {
  display: inline;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1920px) {
  .breadcrumb ol > li {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .breadcrumb ol > li {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.22;
  letter-spacing: 0.9px;
  color: #666;
}

@media screen and (min-width: 1920px) {
  .breadcrumb ol > li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .breadcrumb ol > li a {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li a {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li a {
    font-size: 2.66667vw;
  }
}

.breadcrumb ol > li:first-child a {
  text-decoration: none;
}

.breadcrumb ol > li + li::before {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  padding-right: 0.625vw;
  padding-left: 0.5vw;
  color: #444444;
  content: "＞";
}

@media screen and (min-width: 1920px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
    font-size: 1.04167vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .breadcrumb ol > li + li::before {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .breadcrumb ol > li + li::before {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .breadcrumb ol > li + li::before {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .breadcrumb ol > li + li::before {
    padding-left: 0.73206vw;
  }
}

/* 404ページ
------------------------------------------------------------- */
#notFoundTop .notFoundArea {
  background-color: #f5f5f5;
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundArea {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundArea {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea {
    padding-top: 9.15081vw;
  }
}

#notFoundTop .notFoundArea .notFound {
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFound {
    padding-top: 2.92826vw;
  }
}

#notFoundTop .notFoundArea .logo {
  max-width: 27.819%;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .logo {
    width: 50.66667vw;
    max-width: 380px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .logo {
    margin-bottom: 2.92826vw;
  }
}

#notFoundTop .notFoundArea .notFoundTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 2.8px;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    line-height: 1.71;
    letter-spacing: .51px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTit {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTit {
    margin-bottom: 5.85652vw;
  }
}

#notFoundTop .notFoundArea .notFoundTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 2.4vw;
  }
}

#notFoundTop .notFoundArea .notFoundTxt a {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #notFoundTop .notFoundArea .notFoundTxt {
    font-size: 3.73333vw;
  }
}

/* =====================================
ヘッダー
===================================== */
#header {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url(/images/common/header_back.png);
  background-repeat: repeat-x;
  background-position: top;
  background-size: auto 100%;
  transition: background-color .5s;
}

#header.changeColor {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.7);
}

#header.changeHeight {
  background-color: #fff;
}

#header.changeHeight .headerContents {
  height: 5vw;
  max-height: 80px;
}

@media screen and (max-width: 767px) {
  #header.changeHeight .headerContents {
    height: 10.66667vw;
    max-height: 80px;
  }
}

#header.changeHeight .headerLogo {
  width: 6.625vw;
  max-width: 106px;
}

@media screen and (max-width: 767px) {
  #header.changeHeight .headerLogo {
    width: 14.13333vw;
    max-width: 106px;
  }
}

@media screen and (min-width: 768px) {
  #header.changeHeight .pcGlobalNav {
    padding-left: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #header.changeHeight .pcGlobalNav {
    padding-left: 50px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #header.changeHeight .pcGlobalNav {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #header.changeHeight .pcGlobalNav {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 50px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.6rem;
    font-size: 0.83333vw;
    letter-spacing: 0.8px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1920px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  #header.changeHeight .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.13333vw;
  }
}

#header .headerContents {
  position: relative;
  display: flex;
  align-items: center;
  transition: .5s;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  height: 7.5vw;
  max-height: 120px;
}

@media screen and (min-width: 1600px) {
  #header .headerContents {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .headerContents {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #header .headerContents {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    height: 16.66667vw;
    max-height: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .headerContents {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .headerContents {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .headerContents {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .headerContents {
    padding-left: 2.92826vw;
  }
}

#header .headerLogo {
  width: 9.6875vw;
  max-width: 155px;
  flex-shrink: 0;
  transition: .5s;
}

@media screen and (max-width: 767px) {
  #header .headerLogo {
    width: 20.66667vw;
    max-width: 155px;
  }
}

#header .headerLogo a {
  display: block;
  max-width: 100%;
  width: 100%;
}

#header .headerLogo img {
  width: 100%;
}

#header .navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 999;
  padding-right: 5.33333vw;
  width: 13.33333vw;
  max-width: 100px;
  height: 3.73333vw;
  max-height: 28px;
  cursor: pointer;
}

@media screen and (min-width: 1600px) {
  #header .navBtn {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn {
    padding-right: 2.92826vw;
  }
}

#header .navBtn span {
  display: inline-block;
  position: absolute;
  right: 5.33333vw;
  width: 8vw;
  max-width: 60px;
  height: 1px;
  background-color: #4d4d4d;
  transition: transform .5s ease-in-out , opacity .5s ease-in-out;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span {
    right: 2.92826vw;
  }
}

#header .navBtn span:nth-of-type(1) {
  width: 6.66667vw;
  max-width: 50px;
  top: 3.73333vw;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span:nth-of-type(1) {
    top: 28px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(1) {
    top: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(1) {
    top: 2.04978vw;
  }
}

#header .navBtn span:nth-of-type(2) {
  top: 49%;
  width: 4vw;
  max-width: 30px;
}

#header .navBtn span:nth-of-type(3) {
  bottom: 3.73333vw;
}

@media screen and (min-width: 1600px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 28px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .navBtn span:nth-of-type(3) {
    bottom: 2.04978vw;
  }
}

#header .navBtn.isOpen span:nth-of-type(1), #header .navBtn.isOpen span:nth-of-type(3) {
  top: 50%;
  background-color: #222;
}

#header .navBtn.isOpen span:nth-of-type(1) {
  width: 8vw;
  max-width: 60px;
  transform: rotate(-150deg);
  height: 2px;
  z-index: 1;
}

#header .navBtn.isOpen span:nth-of-type(1)::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: 0;
  z-index: -1;
}

#header .navBtn.isOpen span:nth-of-type(1)::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 0;
  z-index: -1;
}

#header .navBtn.isOpen span:nth-of-type(2) {
  opacity: 0;
}

#header .navBtn.isOpen span:nth-of-type(3) {
  transform: rotate(150deg);
}

@media screen and (max-width: 767px) {
  #header .navArea {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 69.3%;
    width: 100%;
    height: 100%;
    z-index: 998;
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    transition: visibility .7s .8s;
  }
  #header .navArea.isShow {
    visibility: visible;
    transition: visibility .7s;
  }
  #header .navArea.isShow::after {
    transition: transform .7s;
    transform: translateX(0);
  }
  #header .navArea.isShow .content {
    transition: opacity .8s .8s, transform .7s .8s;
    opacity: 1;
    transform: translateY(0);
  }
  #header .navArea::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: -5px 0 20px 0 #0000000d;
    background-image: linear-gradient(to top, #ffffffcb 74%, #ffffff80);
    backdrop-filter: blur(5px);
    z-index: -1;
    transform: translateX(100%);
    transition: transform .7s .9s;
  }
  #header .navArea .content {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity .8s, transform .7s;
    padding-top: 21.33333vw;
    padding-bottom: 17.33333vw;
    padding-right: 5.33333vw;
    padding-left: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .navArea .content {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .navArea .content {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-bottom: 8.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-bottom: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .navArea .content {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .navArea .content {
    padding-left: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .navArea .content {
    padding-left: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .navArea .content {
    padding-left: 8.05271vw;
  }
}

#header .pcGlobalNav {
  transition: .5s;
  padding-left: 5vw;
}

@media screen and (min-width: 1600px) {
  #header .pcGlobalNav {
    padding-left: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav {
    padding-left: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .pcGlobalNav {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav {
    padding-left: 0vw;
  }
}

#header .pcGlobalNav ul {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul {
    display: block;
  }
}

#header .pcGlobalNav ul .pcGlobalNavList {
  margin-right: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 4.39239vw;
  }
}

#header .pcGlobalNav ul .pcGlobalNavList a {
  display: block;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.9px;
  color: #666;
  transition: opacity .3s;
}

@media screen and (min-width: 1920px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.4vw;
  }
}

#header .pcGlobalNav ul .pcGlobalNavList a:hover {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 10.66667vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 4rem;
    font-size: 2.08333vw;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 5.33333vw;
  }
}

#header .externalLink {
  position: absolute;
  display: flex;
  align-items: center;
  top: 1.25vw;
  right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #header .externalLink {
    top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink {
    top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink {
    top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #header .externalLink {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink {
    top: 0vw;
    align-items: baseline;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #header .externalLink {
    top: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink {
    top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink {
    position: static;
    flex-wrap: wrap;
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink {
    margin-top: 8.78477vw;
  }
}

#header .externalLink .externalLinkBtn {
  flex-shrink: 0;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn {
    margin-left: 0vw;
  }
}

#header .externalLink .externalLinkBtn.store {
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store {
    max-width: 100%;
    width: 100%;
    margin-bottom: 10.66667vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store {
    margin-right: 0vw;
  }
}

#header .externalLink .externalLinkBtn.store a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1;
  letter-spacing: 0.28px;
  color: #666;
}

@media screen and (min-width: 1920px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 1.86667vw;
  }
}

#header .externalLink .externalLinkBtn.store a::before {
  content: "";
  display: inline-block;
  vertical-align: baseline;
  background-image: url(/images/common/cart_icon_01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.125vw;
  max-width: 18px;
  height: 1vw;
  max-height: 16px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    letter-spacing: 0.65px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.store a::before {
    width: 5.06667vw;
    max-width: 38px;
    height: 4.66667vw;
    max-height: 35px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.store a::before {
    margin-right: 1.0981vw;
  }
}

#header .externalLink .externalLinkBtn.twitter {
  width: 1.1875vw;
  max-width: 19px;
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.twitter {
    width: 6vw;
    max-width: 45px;
    margin-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #header .externalLink .externalLinkBtn.twitter {
    margin-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #header .externalLink .externalLinkBtn.twitter {
    margin-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #header .externalLink .externalLinkBtn.twitter {
    margin-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.twitter img {
    width: 100%;
  }
}

#header .externalLink .externalLinkBtn.instagram {
  width: 1.125vw;
  max-width: 18px;
}

@media screen and (max-width: 767px) {
  #header .externalLink .externalLinkBtn.instagram {
    width: 6vw;
    max-width: 45px;
  }
  #header .externalLink .externalLinkBtn.instagram img {
    width: 100%;
  }
}

#header #productsSubNav {
  display: none;
}

/* =====================================================
フッター
===================================================== */
#footer {
  position: relative;
  background-color: #fff;
}

#footer .footerLinkArea {
  background-color: #666;
  padding-top: 8.125vw;
  padding-bottom: 5.625vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-top: 130px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-top: 8.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-top: 9.51684vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-bottom: 6.875vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea {
    padding-top: 13.33333vw;
    padding-bottom: 12vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea {
    padding-left: 2.92826vw;
  }
}

#footer .footerLinkArea a {
  transition: opacity .3s;
}

#footer .footerLinkArea a:hover {
  opacity: .7;
}

#footer .footerLinkArea .footerNavList {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList {
    display: block;
  }
}

#footer .footerLinkArea .footerNavList .box {
  flex-shrink: 0;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .box {
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .box {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .box {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .box {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .box:last-of-type {
    margin-bottom: 0;
  }
}

#footer .footerLinkArea .footerNavList .footerNavTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1.4px;
  color: #fff;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 2.19619vw;
  }
}

#footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
  margin-top: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 7.32064vw;
  }
}

#footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 4.39239vw;
  }
}

#footer .footerLinkArea .footerNavList .footerNavTit a {
  color: #fff;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    position: relative;
    font-size: 4rem;
    font-size: 2.08333vw;
    letter-spacing: 1px;
    margin-bottom: 1.875vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:nth-of-type(3) {
    margin-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit:not(:first-of-type) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd {
    cursor: pointer;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd.isOpen::after {
    transform: rotate(-180deg);
  }
  #footer .footerLinkArea .footerNavList .footerNavTit.aCd::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background-image: url(/images/common/accordion_icon_white.svg);
    background-position: center right;
    background-size: contain;
    background-repeat: no-repeat;
    transition: .6s;
    backface-visibility: hidden;
    width: 7.86667vw;
    max-width: 59px;
    height: 4.13333vw;
    max-height: 31px;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .accordionCon {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-top: 1.33333vw;
    padding-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNavCon {
    padding-left: 3.66032vw;
  }
}

#footer .footerLinkArea .footerNavList .footerNav {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.36px;
  color: #fff;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-bottom: 1.46413vw;
  }
}

#footer .footerLinkArea .footerNavList .footerNav a {
  color: #fff;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: .8px;
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .footerNavList .footerNav {
    margin-top: 3.66032vw;
  }
}

#footer .footerLinkArea .otherLinkArea {
  display: flex;
  align-items: flex-end;
  margin-top: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea {
    position: relative;
    flex-flow: column;
    padding-bottom: 13.33333vw;
    margin-top: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea {
    margin-top: 6.58858vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList {
    margin-bottom: 6.58858vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .box {
  display: flex;
  align-items: center;
  border-right: solid 1px #fff;
  padding-right: 0.9375vw;
  margin-right: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    max-width: calc(100% / 3);
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    padding-right: 0vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box {
    margin-right: 0vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .box:last-of-type {
  border-right: none;
}

#footer .footerLinkArea .otherLinkArea .snsList .box:nth-of-type(3) .logo {
  width: 7.8125vw;
  max-width: 125px;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box:nth-of-type(3) .logo {
    width: 20.93333vw;
    max-width: 157px;
    margin-top: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box:nth-of-type(3) .logo {
    margin-top: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box:nth-of-type(3) .logo {
    margin-top: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .box:nth-of-type(3) .logo {
    margin-top: 0.58565vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .logo {
  width: 5.8125vw;
  max-width: 93px;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-right: 1.46413vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .logo img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin: 0 auto;
    width: 20.93333vw;
    max-width: 157px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .logo {
    margin-bottom: 2.92826vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .snsLogo {
  display: flex;
  align-items: center;
}

#footer .footerLinkArea .otherLinkArea .snsList .sns {
  width: 1.5625vw;
  max-width: 25px;
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 1.0981vw;
  }
}

#footer .footerLinkArea .otherLinkArea .snsList .sns img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    width: 6.66667vw;
    max-width: 50px;
    margin-right: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns {
    margin-right: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .snsList .sns:last-of-type {
    margin-right: 0;
  }
}

#footer .footerLinkArea .otherLinkArea .storeLink {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.4px;
  color: #fff;
  margin-bottom: 2.9375vw;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 47px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 3.09211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 3.4407vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 4rem;
    font-size: 2.08333vw;
    letter-spacing: 1px;
    margin-left: 0vw;
    margin-bottom: 15.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 115px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 7.56579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink {
    margin-bottom: 8.41874vw;
  }
}

#footer .footerLinkArea .otherLinkArea .storeLink a {
  position: relative;
  color: #fff;
  padding-left: 4.0625vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 4.27632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 4.75842vw;
  }
}

#footer .footerLinkArea .otherLinkArea .storeLink a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  background-image: url(/images/common/cart_icon_02.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.8125vw;
  max-width: 45px;
  height: 2.8125vw;
  max-height: 45px;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a {
    padding-left: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a::before {
    width: 12vw;
    max-width: 90px;
    height: 12vw;
    max-height: 90px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a::before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .storeLink a::before {
    margin-right: 1.46413vw;
  }
}

#footer .footerLinkArea .otherLinkArea .externalLinkList {
  display: flex;
  align-items: center;
  margin-bottom: 2.1875vw;
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    display: block;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList {
    margin-bottom: 8.05271vw;
  }
}

#footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 800;
  line-height: 1;
  letter-spacing: 0.48px;
  color: #fff;
  margin-right: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 3.66032vw;
  }
}

#footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 3rem;
    font-size: 1.5625vw;
    font-weight: 600;
    letter-spacing: 0.45px;
    margin-right: 0vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink:last-of-type {
    margin-bottom: 0;
  }
}

#footer .footerLinkArea .otherLinkArea .externalLinkList .externalLink a {
  color: #fff;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .rightBox {
    order: 2;
    width: 100%;
  }
}

#footer .footerLinkArea .otherLinkArea .leftBox {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox {
    order: 1;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoBox {
    padding-left: 2.92826vw;
  }
}

#footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.7px;
  color: #fff;
  margin-bottom: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 2.4rem;
    font-size: 1.25vw;
    font-feature-settings: "palt" 1;
    letter-spacing: 0.24px;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoTxt {
    margin-bottom: 2.19619vw;
  }
}

#footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
  font-size: 1rem;
  font-size: 0.52083vw;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.5px;
  color: #fff;
  width: 27.1875vw;
  max-width: 435px;
}

@media screen and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 1.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    max-width: 100%;
    width: 100%;
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 1.2;
    letter-spacing: 0.2px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .footerLinkArea .otherLinkArea .leftBox .isoDepTxt {
    font-size: 2.66667vw;
  }
}

#footer .copyRightArea {
  position: relative;
  padding-top: 2.1875vw;
  padding-bottom: 2.1875vw;
}

@media screen and (min-width: 1600px) {
  #footer .copyRightArea {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRightArea {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRightArea {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .copyRightArea {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRightArea {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRightArea {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRightArea {
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .copyRightArea {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRightArea {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRightArea {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .copyRightArea {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRightArea {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRightArea {
    padding-bottom: 3.66032vw;
  }
}

#footer .copyRight {
  display: block;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1rem;
  font-size: 0.52083vw;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #footer .copyRight {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    font-size: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    font-size: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    font-size: 1.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .copyRight {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: .6px;
    color: #707070;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .copyRight {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .copyRight {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .copyRight {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .copyRight {
    font-size: 2.66667vw;
  }
}

#footer .langBox {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.25vw;
  margin: auto;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1600px) {
  #footer .langBox {
    right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox {
    right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox {
    right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox {
    position: static;
    justify-content: center;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox {
    margin-bottom: 4.39239vw;
  }
}

#footer .langBox span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
  color: #666;
}

@media screen and (min-width: 1920px) {
  #footer .langBox span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox span {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox span {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.3px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .langBox span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox span {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .langBox span {
    font-size: 2.66667vw;
  }
}

#footer .langBox dl {
  position: relative;
}

#footer .langBox dl dt {
  cursor: pointer;
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.05px;
  color: #666;
  padding-right: 1.875vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #footer .langBox dl dt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dt {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dt {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: .75px;
    padding-right: 4vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .langBox dl dt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .langBox dl dt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dt {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dt {
    padding-left: 2.92826vw;
  }
}

#footer .langBox dl dt.open::after {
  transform: rotate(-180deg);
}

#footer .langBox dl dt::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-image: url(/images/common/accordion_icon_01.svg);
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .3s;
  backface-visibility: hidden;
  width: 0.75vw;
  max-width: 12px;
  height: 0.375vw;
  max-height: 6px;
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dt::after {
    width: 1.33333vw;
    max-width: 10px;
    height: 0.66667vw;
    max-height: 5px;
  }
}

#footer .langBox dl dd {
  position: absolute;
  bottom: 100%;
  width: 100%;
  display: none;
  z-index: 90;
}

#footer .langBox dl dd ul {
  background-color: #fff;
  padding-top: 0.375vw;
  padding-bottom: 1vw;
  padding-right: 0.625vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-top: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-top: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-top: 0.43924vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dd ul {
    padding-top: 0.8vw;
    padding-bottom: 2.13333vw;
    padding-right: 1.33333vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-top: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-top: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-top: 0.43924vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd ul {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd ul {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd ul {
    padding-left: 1.46413vw;
  }
}

#footer .langBox dl dd li {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  background-color: #fff;
}

@media screen and (min-width: 1920px) {
  #footer .langBox dl dd li {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd li {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd li {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dd li {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dd li {
    font-size: 1.6rem;
    font-size: 0.83333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footer .langBox dl dd li {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd li {
    font-size: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footer .langBox dl dd li {
    font-size: 2.13333vw;
  }
}

#footer .langBox dl dd a {
  display: block;
  color: #707070;
  padding-top: 0.875vw;
  padding-bottom: 0.875vw;
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd a {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd a {
    padding-top: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd a {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #footer .langBox dl dd a {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd a {
    padding-bottom: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd a {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footer .langBox dl dd a {
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd a {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd a {
    padding-top: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd a {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footer .langBox dl dd a {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footer .langBox dl dd a {
    padding-bottom: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footer .langBox dl dd a {
    padding-bottom: 1.02489vw;
  }
}

/* 見出し装飾--------------------------- */
/* アニメーション関連---------------------- */
.fadeIn {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: opacity .7s .2s, transform .7s .2s;
  backface-visibility: hidden;
}

.fadeIn.active {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn.active .fadeIn_second {
  opacity: 1;
  visibility: visible;
}

.fadeIn.active .fadeIn_third {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.fadeIn .fadeIn_second {
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s 1s;
}

.fadeIn .fadeIn_third {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity .8s .7s, transform .8s .7s;
}

.fadeIn2 {
  opacity: 0;
  visibility: hidden;
  transition: opacity .7s .2s;
  backface-visibility: hidden;
}

.fadeIn2.active {
  opacity: 1;
  visibility: visible;
}

.fadeIn2.active .fadeIn2_second {
  opacity: 1;
  visibility: visible;
}

.fadeIn2.active .fadeIn2_third {
  opacity: 1;
  visibility: visible;
}

.fadeIn2 .fadeIn2_second {
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s 1s;
}

.fadeIn2 .fadeIn2_third {
  opacity: 0;
  visibility: hidden;
  transition: opacity .8s .7s;
}

.arrow:after {
  transition: transform .5s;
  transform: scale(1, 1);
  transform-origin: left top;
}

.animeLinkEachsBtn.animeLinkEachBtn:hover .arrow::after, .animeLinkEachsBtn.animeLinkEachBtn.isActive .arrow::after {
  animation: 1s arrow linear forwards;
}

.animeLink:hover .arrow svg {
  animation: 1s arrow linear forwards;
}

.animeLink:hover .arrow:after {
  animation: 1s arrow linear forwards;
}

.animeLink {
  cursor: pointer;
}

.animeLink,
.animeLinkEach {
  display: block;
}

.animeLinkImg,
.animeLinkEachImg {
  display: block;
  transition: .8s;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

.animeLinkImg img,
.animeLinkImg .bgImg,
.animeLinkEachImg img,
.animeLinkEachImg .bgImg {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.animeLinkImg:hover img,
.animeLinkImg:hover .bgImg, .animeLinkImg.isActive img,
.animeLinkImg.isActive .bgImg,
.animeLinkEachImg:hover img,
.animeLinkEachImg:hover .bgImg,
.animeLinkEachImg.isActive img,
.animeLinkEachImg.isActive .bgImg {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

.animeLinkImg.notHover.isActive,
.animeLinkEachImg.notHover.isActive {
  opacity: .7;
}

.animeLinkBtn,
.animeLinkEachBtn {
  display: block;
}

.megaMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity .8s .6s;
  height: 15.5vw;
  max-height: 248px;
  max-height: none;
}

@media screen and (max-width: 767px) {
  .megaMenu {
    display: none;
  }
}

.megaMenu .megaMenuTit {
  opacity: 0;
}

.megaMenu .megaMenuLink {
  opacity: 0;
}

.megaMenu.isShow {
  visibility: visible;
  opacity: 1;
  transition: all .8s;
}

.megaMenu.isShow .megaMenuTit {
  transition: opacity .8s .5s;
  opacity: 1;
}

.megaMenu.isShow .megaMenuLink {
  opacity: 1;
  transition: opacity .8s .9s;
}

.megaMenu.isShow .megaMenuLink::after {
  opacity: 1;
  transform: translateX(100%);
  transition: opacity .8s, transform .8s .5s;
}

.megaMenu.isShow.isFast .megaMenuTit {
  transition: opacity .8s;
}

.megaMenu.isShow.isFast .megaMenuLink {
  transition: opacity .8s .5s;
}

.megaMenu.isShow.isFast .megaMenuLink::after {
  transition: opacity .8s, transform .8s;
}

.megaMenuBg {
  width: 100%;
  height: 0;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  transition: height .7s;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .megaMenuBg {
    display: none;
  }
}

.megaMenuBg.isShow {
  height: 15.5vw;
  max-height: 248px;
  max-height: none;
  border-bottom: solid 1px #e8e8e8;
}

@keyframes arrowMove {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  40%, 60% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}

/* ボタン装飾------------------------------ */
button {
  outline: none;
}

.slide-arrow:hover {
  cursor: pointer;
}

.BtnTemp {
  width: 25vw;
  max-width: 400px;
  margin-top: 3.75vw;
  margin-bottom: 3.75vw;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 1600px) {
  .BtnTemp {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp {
    margin-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp {
    margin-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  .BtnTemp {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp {
    width: 100%;
    max-width: 100%;
  }
}

.BtnTemp:not(.pdfBtn) a {
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
}

.BtnTemp a {
  display: block;
  text-align: center;
  text-decoration: none;
  padding: 0.9375vw;
  transition: all .3s;
  background: #4d4d4d;
}

@media screen and (min-width: 1600px) {
  .BtnTemp a {
    padding: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp a {
    padding: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp a {
    padding: 1.0981vw;
  }
}

.BtnTemp a:hover {
  opacity: .8;
}

.BtnTemp a p {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 1.7;
  color: #fff;
}

@media screen and (min-width: 1920px) {
  .BtnTemp a p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp a p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp a p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp a p {
    font-size: 2.66667vw;
  }
}

.BtnTemp.circleBtnBlank a {
  border-radius: 5px;
}

.BtnTemp.circleBtnBlank a p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnTemp.circleBtnBlank a p svg {
  width: 1.375vw;
  max-width: 22px;
  height: 1.3125vw;
  max-height: 21px;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp.circleBtnBlank a p svg {
    width: 3.2vw;
    max-width: 24px;
    height: 2.93333vw;
    max-height: 22px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.circleBtnBlank a p svg {
    margin-left: 0.73206vw;
  }
}

.BtnTemp.circleBtn a {
  border-radius: 5px;
}

.BtnTemp.circleBtn a p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnTemp.circleBtn a p svg {
  width: 2.5625vw;
  max-width: 41px;
  height: 0.625vw;
  max-height: 10px;
  margin-left: 0.625vw;
  transition: transform .5s;
  transform: scale(1, 1);
  transform-origin: left top;
}

@media screen and (min-width: 1600px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp.circleBtn a p svg {
    width: 5.46667vw;
    max-width: 41px;
    height: 1.33333vw;
    max-height: 10px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.circleBtn a p svg {
    margin-left: 0.73206vw;
  }
}

.BtnTemp.circleBtn a:hover p svg {
  animation: 1s arrow linear forwards;
}

.BtnTemp.pdfBtn a {
  border-radius: 5px;
  background: #4d4d4d;
  color: #fff;
}

.BtnTemp.pdfBtn a p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BtnTemp.pdfBtn a p:after {
  content: "";
  display: inline-block;
  background-image: url(/images/news/news_post_pdf_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 2.0625vw;
  max-width: 33px;
  height: 2.05em;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp {
    width: 100%;
    max-width: inherit;
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp a {
    line-height: 1.75;
    padding: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp a {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp a {
    padding: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp a {
    padding: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp a p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .BtnTemp a p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp a p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp a p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .BtnTemp a p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .BtnTemp.circleBtnBlank a {
    border-radius: 1.33vw;
  }
  .BtnTemp.circleBtn a {
    border-radius: 1.33vw;
  }
  .BtnTemp.pdfBtn a {
    border-radius: 1.33vw;
  }
  .BtnTemp.pdfBtn a p:after {
    width: 5.73333vw;
    max-width: 43px;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .BtnTemp.pdfBtn a p:after {
    margin-left: 1.46413vw;
  }
}

.columnLayout {
  display: flex;
  justify-content: space-between;
}

.columnLayout .col1 {
  flex-basis: 100%;
}

.columnLayout .col2 {
  flex-basis: 50%;
}

.columnLayout .col3 {
  flex-basis: 33%;
}

.columnLayout .col4 {
  flex-basis: 25%;
}

.columnLayout .col5 {
  flex-basis: 20%;
}

.columnLayout .col6 {
  flex-basis: 16%;
}

.columnLayout .col7 {
  flex-basis: 14%;
}

.columnLayout .col8 {
  flex-basis: 12%;
}

#bgWave {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  pointer-events: none !important;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

@-moz-document url-prefix() {
  #bgWave {
    opacity: .8;
  }
}

@supports (not (-webkit-hyphens: none)) and (not (-moz-appearance: none)) {
  #bgWave {
    opacity: .8;
  }
}

#subNav {
  max-width: 100%;
  width: 100%;
  background-color: #4d4d4d;
  height: 3.75vw;
  max-height: 60px;
  position: fixed;
  z-index: 10;
  top: 7.5vw;
  left: 0;
  transition: all .4s;
}

@media screen and (min-width: 1600px) {
  #subNav {
    top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav {
    top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav {
    top: 8.78477vw;
  }
}

#subNav.notTop {
  top: 5vw;
}

@media screen and (min-width: 1600px) {
  #subNav.notTop {
    top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav.notTop {
    top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav.notTop {
    top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #subNav {
    top: 7.5vw;
  }
  #subNav.notTop {
    top: 5vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav {
    top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav {
    top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav {
    top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav {
    top: 9.15081vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav.notTop {
    top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav.notTop {
    top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav.notTop {
    top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav.notTop {
    top: 5.85652vw;
  }
}

#subNav dl {
  position: relative;
  height: 100%;
}

#subNav dl dd {
  height: 100%;
}

@media screen and (max-width: 767px) {
  #subNav {
    height: 10.66667vw;
    max-height: 80px;
  }
  #subNav dl dd {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 998;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  #subNav .accordionBtn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 26.66667vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .accordionBtn {
    padding-right: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .accordionBtn {
    padding-right: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .accordionBtn {
    padding-right: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .accordionBtn {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .accordionBtn {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .accordionBtn {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .accordionBtn.isOpen::after {
    transform: rotate(-180deg);
  }
  #subNav .accordionBtn::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6.66667vw;
    margin: auto;
    background-image: url(/images/common/suvnav_accordion_arrow_white.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform .5s;
    backface-visibility: hidden;
    width: 6vw;
    max-width: 45px;
    height: 3.06667vw;
    max-height: 23px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .accordionBtn::after {
    right: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .accordionBtn::after {
    right: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .accordionBtn::after {
    right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .accordionBtn p {
    font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    color: #fff;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #subNav .accordionBtn p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .accordionBtn p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .accordionBtn p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #subNav .accordionBtn p {
    font-size: 4.26667vw;
  }
}

#subNav .flex {
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #subNav .flex {
    display: block;
    background-color: rgba(77, 77, 77, 0.9);
    padding: 0;
    padding-top: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex {
    padding-top: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex {
    padding-top: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .flex .subNavList {
    flex-wrap: wrap;
    padding-left: 25.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 28.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList {
    padding-left: 190px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList {
    padding-left: 12.5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList {
    padding-left: 13.90922vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList {
    padding-bottom: 215px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList {
    padding-bottom: 14.14474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList {
    padding-bottom: 15.73939vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .flex .subNavList .list {
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
    margin-right: 0vw;
    margin-left: 0vw;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .flex .subNavList .list a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #subNav .flex .subNavList .list a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list a {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #subNav .flex .subNavList .list a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .flex .subNavList .list.current a {
    color: #ADADAD;
  }
}

#subNav .flex .subNavList {
  display: flex;
  width: 100%;
  justify-content: center;
}

#subNav .flex .subNavList .list {
  padding-left: 0.4375vw;
  padding-right: 0.4375vw;
  margin-right: 1.125vw;
  margin-left: 1.125vw;
}

@media screen and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    padding-left: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    padding-left: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    padding-left: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    padding-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    padding-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    padding-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    margin-right: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    margin-right: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    margin-right: 1.31772vw;
  }
}

@media screen and (min-width: 1600px) {
  #subNav .flex .subNavList .list {
    margin-left: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list {
    margin-left: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list {
    margin-left: 1.31772vw;
  }
}

#subNav .flex .subNavList .list a {
  color: #fff;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.72px;
}

@media screen and (min-width: 1920px) {
  #subNav .flex .subNavList .list a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #subNav .flex .subNavList .list a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #subNav .flex .subNavList .list a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #subNav .flex .subNavList .list a {
    font-size: 2.4vw;
  }
}

#subNav .flex .subNavList .list.blank a:after {
  content: "";
  background: url(/images/common/blank_icon_white.svg) center center no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  display: inline-block;
  margin-left: .55em;
}

#loading {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  background: #ffffff;
  opacity: 1;
  z-index: 100000;
}

#loading #percent {
  position: relative;
  max-width: 181px;
}

#loading #percent #percent-text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  text-indent: -9999px;
  transition: transform .6s;
}

#loading .loadingTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.12px;
  text-align: center;
  color: #adadad;
  margin-top: 2.1875vw;
}

@media screen and (min-width: 1920px) {
  #loading .loadingTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #loading .loadingTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #loading .loadingTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #loading .loadingTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #loading .loadingTxt {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #loading .loadingTxt {
    margin-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #loading .loadingTxt {
    margin-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #loading .loadingTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-top: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #loading .loadingTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #loading .loadingTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #loading .loadingTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #loading .loadingTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #loading .loadingTxt {
    margin-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #loading .loadingTxt {
    margin-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #loading .loadingTxt {
    margin-top: 2.56223vw;
  }
}

/************************************
トップページ
***********************************/
/* ==============================================
トップページ
============================================== */
#indexTop .indexArea {
  position: relative;
  z-index: 1;
  background-color: #f7f5f4;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

#indexTop .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .secTit {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .secTit {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .secTit {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .secTit {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .secTit {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .secTit {
    padding-bottom: 14.64129vw;
  }
}

#indexTop .gradation {
  position: relative;
  z-index: 1;
}

#indexTop .mainVisual {
  position: relative;
}

#indexTop .mainVisual .slide {
  opacity: 0;
  transition: opacity .3s;
}

#indexTop .mainVisual .slide.slick-initialized {
  opacity: 1;
}

#indexTop .mainVisual .img img {
  width: 100%;
}

#indexTop .mainVisual .mainVisualDots {
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .mainVisualDots {
    bottom: 3.66032vw;
  }
}

#indexTop .mainVisual .mainVisualDots li {
  padding: 0 !important;
  width: 1.875vw;
  max-width: 30px;
  height: 0.25vw;
  max-height: 4px;
  background: #c3bfbf;
  margin: 0 5px;
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisual .mainVisualDots li {
    width: 4vw;
    max-width: 30px;
    height: 0.53333vw;
    max-height: 4px;
    margin-left: 0.93333vw;
    margin-right: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-left: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-right: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-right: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .mainVisualDots li {
    margin-right: 0.51245vw;
  }
}

#indexTop .mainVisual .mainVisualDots li:hover {
  cursor: pointer;
}

#indexTop .mainVisual .mainVisualDots li.slick-active {
  background: #4d4d4d;
}

#indexTop .mainVisual .mainVisualDots li button {
  text-indent: -9999px;
}

#indexTop .mainVisual .slide-arrow {
  position: absolute;
  top: 50%;
  z-index: 2;
  width: 2vw;
  max-width: 32px;
  height: 3.9375vw;
  max-height: 63px;
}

#indexTop .mainVisual .slide-arrow.prev-arrow {
  left: 6.25vw;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 7.32064vw;
  }
}

#indexTop .mainVisual .slide-arrow.next-arrow {
  right: 6.25vw;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 7.32064vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.125vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.28947vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.66032vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.125vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.28947vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisual .slide-arrow {
    width: 9.6vw;
    max-width: 72px;
    height: 13.73333vw;
    max-height: 103px;
    padding: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow {
    padding: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow {
    padding: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.prev-arrow {
    left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .mainVisual .slide-arrow.next-arrow {
    right: 1.46413vw;
  }
}

#indexTop .aboutIroha {
  padding-top: 15vw;
  padding-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha {
    padding-top: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha {
    padding-top: 15.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha {
    padding-top: 17.56955vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha {
    padding-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha {
    padding-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha {
    padding-top: 26.66667vw;
    padding-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha {
    padding-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha {
    padding-bottom: 10.2489vw;
  }
}

#indexTop .aboutIroha .mainTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 6rem;
  font-size: 3.125vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 3.12px;
  color: #666;
  margin-bottom: 3.375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 8vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 3.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 3.95315vw;
  }
}

#indexTop .aboutIroha .mainTit:after {
  content: "きもちよさを、自分らしく。";
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.92px;
  display: block;
  margin-top: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 6.6rem;
    font-size: 3.4375vw;
    letter-spacing: 1.725px;
    text-align: center;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 6.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 4.34211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 4.83163vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit {
    font-size: 8.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: .96px;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutIroha .mainTit:after {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .mainTit:after {
    margin-top: 2.92826vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc {
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc {
    display: block;
    flex-direction: column-reverse;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt {
  max-width: 1406px;
  width: 100%;
  margin: 0 auto;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 0vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt .inner {
  width: 100%;
  max-width: 50%;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner {
    max-width: 100%;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5;
  letter-spacing: 0.4px;
  text-align: left;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.5;
    letter-spacing: 0.32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 2.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-left: 10.66667vw;
    padding-right: 5.33333vw;
    margin-bottom: 6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    margin-bottom: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt {
    margin-bottom: 3.29429vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
  margin-right: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 7.32064vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
  justify-content: flex-end;
  padding-top: 5.625vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 15.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 115px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 7.56579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
    padding-top: 8.41874vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
  content: "";
  left: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 10.98097vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .img {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img {
    width: 100%;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
  max-width: 50%;
  width: 100%;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
}

@media screen and (min-width: 1920px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    width: 56.25vw;
    max-width: 900px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    position: relative;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-bottom: 8.78477vw;
  }
}

#indexTop .aboutIroha .aboutIrohaDesc .img .videoBox:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

#indexTop .aboutIroha .aboutIrohaDesc .img .videoBox video {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#indexTop .aboutIroha .aboutIrohaDesc .img img {
  max-width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1920px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img img {
    width: 56.25vw;
    max-width: 900px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img img {
    position: static;
    max-width: 100%;
    width: 100%;
    box-shadow: none;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img img {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img img {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .aboutIroha .aboutIrohaDesc .img img {
    margin-bottom: 8.78477vw;
  }
}

#indexTop .products {
  padding-top: 12.5vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products {
    padding-top: 26.66667vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products {
    padding-bottom: 13.17716vw;
  }
}

#indexTop .products .columnItemList {
  margin-bottom: 15vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList {
    margin-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList {
    margin-bottom: 15.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList {
    margin-bottom: 17.56955vw;
  }
}

#indexTop .products .columnItemList .list {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list {
    flex-direction: column-reverse;
  }
}

#indexTop .products .columnItemList .list:not(:last-of-type) {
  margin-bottom: 21.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 340px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 22.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 24.89019vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:not(:last-of-type) {
    margin-bottom: 11.71303vw;
  }
}

#indexTop .products .columnItemList .list:nth-of-type(even) {
  flex-direction: row-reverse;
}

#indexTop .products .columnItemList .list:nth-of-type(even) .txt {
  text-align: right;
}

#indexTop .products .columnItemList .list:nth-of-type(even) .txt:before {
  right: inherit;
  left: 0;
}

#indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
  padding: 0 8.333% 0 0;
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) {
    flex-direction: column-reverse;
  }
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-left: 0vw;
    padding-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list:nth-of-type(even) .img {
    margin-left: auto;
    margin-right: 0;
  }
}

#indexTop .products .columnItemList .list .img {
  width: 62.5%;
  margin-bottom: -7.29166%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-bottom: 0;
    margin-right: auto;
  }
}

#indexTop .products .columnItemList .list .txt {
  width: 37.5%;
  padding: 7.8125% 0 4.166%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt {
    width: 100%;
    padding-top: 13.33333vw;
    padding-left: 10.66667vw;
    padding-right: 0vw;
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt:before {
    width: 89.33333vw;
    max-width: 670px;
    height: calc(100% + 6.66vw);
  }
}

#indexTop .products .columnItemList .list .txt:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 75vw;
  max-width: 1200px;
  background: #fff;
  z-index: -1;
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
  width: 86.1111%;
  display: inline-block;
  padding: 0 0 0 8.333%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    text-align: right;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn {
    padding-right: 2.92826vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.2rem;
  font-size: 2.1875vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 2.1px;
  text-align: center;
  margin-bottom: 0.875vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 4.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 2.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 5.6vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    letter-spacing: 3.24px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 0.73206vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2.14;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 3.73333vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
  margin: 0 auto;
  width: 20vw;
  max-width: 320px;
  margin-bottom: 4.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 4.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 5.27086vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    width: 47.33333vw;
    max-width: 355px;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .logo {
    margin-bottom: 5.12445vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 7.5vw;
  margin-top: 3.75vw;
  width: 76.78%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2;
    width: 100%;
    margin-bottom: 10.66667vw;
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn p {
    margin-top: 5.85652vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 1.08px;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow {
    font-size: 3.73333vw;
  }
}

#indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/index/products_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 2.8em;
  height: .6em;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 1.33333vw;
    width: 1.8em;
    height: .4em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .columnItemList .list .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.73206vw;
  }
}

#indexTop .products .irohaDiagnosis {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8.75vw;
  background: #fff;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis {
    margin-top: 10.2489vw;
  }
}

#indexTop .products .irohaDiagnosis a {
  padding: 6.25vw;
  display: block;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis a {
    padding: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis a {
    padding: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis a {
    padding: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis a {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
    padding-left: 3.33333vw;
    padding-right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis a {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis a {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis a {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis a {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis a {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis a {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis a {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis a {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis a {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis a {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis a {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis a {
    padding-right: 1.83016vw;
  }
}

#indexTop .products .irohaDiagnosis h3 {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.6rem;
  font-size: 1.875vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: 3.6px;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 3.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 2.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 4.8vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 5.6rem;
    font-size: 2.91667vw;
    letter-spacing: 2.6px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 5.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 3.68421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis h3 {
    font-size: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis h3 {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .products .irohaDiagnosis p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.36px;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: normal;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis p {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .products .irohaDiagnosis .img {
  margin: 0 auto;
  width: 74.62%;
}

#indexTop .products .irohaDiagnosis .img img {
  margin: 0 auto;
}

#indexTop .products .irohaDiagnosis .btn {
  display: inline-block;
  margin-top: 3.4375vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  border: solid 1px #bfbfbf;
  border-radius: 5px;
  width: 25vw;
  max-width: 400px;
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
  padding-left: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 4.02635vw;
  }
}

@media screen and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    width: 78.66667vw;
    max-width: 590px;
    margin-top: 10.66667vw;
    padding-top: 6vw;
    padding-bottom: 6vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis .btn {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn {
    padding-left: 2.19619vw;
  }
}

#indexTop .products .irohaDiagnosis .btn::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/blank_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  width: 1.25vw;
  max-width: 20px;
  height: 1.25vw;
  max-height: 20px;
  margin-left: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    width: 1em;
    height: 0.9em;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .products .irohaDiagnosis .btn::after {
    margin-left: 2.19619vw;
  }
}

#indexTop .iroiroiroha {
  padding-top: 12.5vw;
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha {
    padding-top: 24vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha {
    padding-top: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha {
    padding-top: 13.17716vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha {
    padding-bottom: 13.17716vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .minContainer {
    padding: 0;
  }
}

#indexTop .iroiroiroha .iroiroirohaTit {
  margin: 0 auto;
  width: 28.75vw;
  max-width: 460px;
  margin-bottom: 10.625vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 12.4451vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    width: 55.06667vw;
    max-width: 413px;
    margin-bottom: 28.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 215px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 14.14474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaTit {
    margin-bottom: 15.73939vw;
  }
}

#indexTop .iroiroiroha .iroiroirohaArticleTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.2rem;
  font-size: 2.1875vw;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 2.1px;
  color: #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  width: 57.625vw;
  max-width: 922px;
  padding-bottom: 0.625vw;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 4.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 2.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 5.6vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    line-height: 1;
    letter-spacing: 1.2px;
    margin: 0 auto;
    max-width: 89.3%;
    width: 100%;
    padding-bottom: 2vw;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .iroiroirohaArticleTit {
    margin-bottom: 5.12445vw;
  }
}

#indexTop .iroiroiroha .featureTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.2rem;
  font-size: 2.1875vw;
  font-weight: 500;
  line-height: 1.43;
  letter-spacing: 2.1px;
  color: #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  padding-bottom: 0.625vw;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 4.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 2.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 5.6vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    line-height: 1;
    letter-spacing: 1.2px;
    padding-bottom: 5.33333vw;
    margin-bottom: 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .featureTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureTit {
    margin-bottom: 4.02635vw;
  }
}

#indexTop .iroiroiroha .category {
  display: flex;
  align-items: center;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .category {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .category {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 1.0981vw;
  }
}

#indexTop .iroiroiroha .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.625vw;
  max-height: 26px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .category::before {
    width: 7.06667vw;
    max-width: 53px;
    height: 5.86667vw;
    max-height: 44px;
    margin-right: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .category::before {
    margin-right: 0.58565vw;
  }
}

#indexTop .iroiroiroha .category.topics {
  color: #6456b7;
}

#indexTop .iroiroiroha .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#indexTop .iroiroiroha .category.iroha_story {
  color: #4a6bb7;
}

#indexTop .iroiroiroha .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#indexTop .iroiroiroha .category.interview {
  color: #63a534;
}

#indexTop .iroiroiroha .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#indexTop .iroiroiroha .category.column {
  color: #aaa73f;
}

#indexTop .iroiroiroha .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#indexTop .iroiroiroha .category.research {
  color: #c78246;
}

#indexTop .iroiroiroha .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#indexTop .iroiroiroha .category.review {
  color: #bc5680;
}

#indexTop .iroiroiroha .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#indexTop .iroiroiroha .flex {
  display: flex;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .flex {
    display: block;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .flex {
    margin-bottom: 4.39239vw;
  }
}

#indexTop .iroiroiroha .newArticleCard {
  max-width: 50%;
  width: 100%;
  padding-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard {
    max-width: 100%;
    padding-right: 0vw;
    margin-bottom: 9.375vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard {
    margin-bottom: 10.98097vw;
  }
}

#indexTop .iroiroiroha .newArticleCard a {
  display: block;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
  position: relative;
  z-index: 0;
}

#indexTop .iroiroiroha .newArticleCard a img,
#indexTop .iroiroiroha .newArticleCard a .bgImg {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#indexTop .iroiroiroha .newArticleCard a:hover img,
#indexTop .iroiroiroha .newArticleCard a:hover .bgImg, #indexTop .iroiroiroha .newArticleCard a.isActive img,
#indexTop .iroiroiroha .newArticleCard a.isActive .bgImg {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

#indexTop .iroiroiroha .newArticleCard .img {
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  width: 37.5vw;
  max-width: 600px;
}

#indexTop .iroiroiroha .newArticleCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .img {
    max-width: 94.7%;
    width: 100%;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

#indexTop .iroiroiroha .newArticleCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  width: 37.5vw;
  max-width: 600px;
  padding-top: 4.375vw;
  padding-bottom: 6.875vw;
  padding-right: 2.5vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    max-width: 94.7%;
    width: 100%;
    padding-top: 8vw;
    padding-bottom: 17.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 8.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-bottom: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt {
    padding-left: 2.92826vw;
  }
}

#indexTop .iroiroiroha .newArticleCard .txt::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: -3.75vw;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .txt::after {
    top: -5.85652vw;
  }
}

#indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTit {
    margin-bottom: 2.92826vw;
  }
}

#indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  padding-right: 1.25vw;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.5;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#indexTop .iroiroiroha .newArticleCard .newArticleCardData {
  display: block;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .newArticleCard .newArticleCardData {
    font-size: 3.73333vw;
  }
}

#indexTop .iroiroiroha .featureBox {
  max-width: 50%;
  width: 100%;
  padding-left: 3.4375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 4.02635vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureBox {
    max-width: 100%;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureBox {
    padding-left: 2.92826vw;
  }
}

#indexTop .iroiroiroha .featureArticleCard {
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 2.19619vw;
  }
}

#indexTop .iroiroiroha .featureArticleCard:last-of-type {
  margin-bottom: 0;
}

#indexTop .iroiroiroha .featureArticleCard a {
  display: flex;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
  padding-bottom: 1.875vw;
}

#indexTop .iroiroiroha .featureArticleCard a img,
#indexTop .iroiroiroha .featureArticleCard a .bgImg {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#indexTop .iroiroiroha .featureArticleCard a:hover img,
#indexTop .iroiroiroha .featureArticleCard a:hover .bgImg, #indexTop .iroiroiroha .featureArticleCard a.isActive img,
#indexTop .iroiroiroha .featureArticleCard a.isActive .bgImg {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    position: relative;
    z-index: 1;
    padding-top: 6vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-top: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard a::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #fff;
    width: 68vw;
    max-width: 510px;
    height: 100%;
  }
}

#indexTop .iroiroiroha .featureArticleCard .img {
  flex-shrink: 0;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: inherit;
  width: 17.75vw;
  max-width: 284px;
  margin-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 2.92826vw;
  }
}

#indexTop .iroiroiroha .featureArticleCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
    width: 40vw;
    max-width: 300px;
    margin-right: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .img {
    margin-right: 2.56223vw;
  }
}

#indexTop .iroiroiroha .featureArticleCard .category {
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .category {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .category::before {
    width: 6.66667vw;
    max-width: 50px;
    height: 5.33333vw;
    max-height: 40px;
  }
}

#indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1.78;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardTit {
    margin-bottom: 2.19619vw;
  }
}

#indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
  display: block;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .iroiroiroha .featureArticleCard .featureArticleCardData {
    font-size: 3.73333vw;
  }
}

#indexTop .shop {
  padding-top: 12.5vw;
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .shop {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .shop {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop {
    padding-top: 24vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop {
    padding-top: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop {
    padding-top: 13.17716vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop {
    padding-bottom: 13.17716vw;
  }
}

#indexTop .shop .shopLinksWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap {
    margin-bottom: 10.2489vw;
  }
}

#indexTop .shop .shopLinksWrap li {
  width: 30.38%;
  background: #fff;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li {
    width: 100%;
    margin-bottom: 6.66667vw;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li {
    margin-bottom: 3.66032vw;
  }
}

#indexTop .shop .shopLinksWrap li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 14.45% 9.74% 9.74% 9.74%;
  position: relative;
  transition: opacity .3s;
}

#indexTop .shop .shopLinksWrap li a:hover {
  opacity: .7;
}

#indexTop .shop .shopLinksWrap li a:after {
  content: "";
  background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 12.11%;
  height: 7px;
  position: absolute;
  bottom: 9.74%;
  right: 9.74%;
}

#indexTop .shop .shopLinksWrap li a p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  margin-bottom: 12.145%;
}

@media screen and (min-width: 1920px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 3.2vw;
  }
}

#indexTop .shop .shopLinksWrap li a p:before {
  content: "";
  width: 28.34%;
  height: 4.375vw;
  max-height: 70px;
  display: block;
  margin: 0 auto 12.1%;
}

#indexTop .shop .shopLinksWrap li a p:after {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.86;
  letter-spacing: 0.28px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-top: 8vw;
    padding-left: 5.33333vw;
    padding-bottom: 14.66667vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a:after {
    width: 7.06667vw;
    max-width: 53px;
    height: 1.33333vw;
    max-height: 10px;
    right: 5.33333vw;
    bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a:after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a:after {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a:after {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a:after {
    bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a:after {
    bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a:after {
    bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 0vw;
    display: flex;
    align-items: center;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
    margin-right: 7.33333vw;
    margin-left: 0vw;
    margin-bottom: -6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-right: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-right: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-right: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-bottom: -50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-bottom: -3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:before {
    margin-bottom: -3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 2.2rem;
    font-size: 1.14583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    position: absolute;
    bottom: -2.4vw;
    left: 16vw;
    transform: translate(0, 1em);
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 1.44737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    bottom: -18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    bottom: -1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    bottom: -1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    left: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    left: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .shop .shopLinksWrap li a p:after {
    left: 8.78477vw;
  }
}

#indexTop .shop .shopLinksWrap li.cart a:after {
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 1vw;
  max-width: 16px;
  height: 0.875vw;
  max-height: 14px;
  min-width: 12px;
  min-height: 10px;
}

@media screen and (max-width: 767px) {
  #indexTop .shop .shopLinksWrap li.cart a:after {
    width: 4.93333vw;
    max-width: 37px;
    height: 4.53333vw;
    max-height: 34px;
  }
}

#indexTop .shop .shopLinksWrap li.cart p:before {
  background: url(/images/common/cart_icon.svg) center center no-repeat;
  background-size: contain;
}

#indexTop .shop .shopLinksWrap li.cart p:after {
  content: "Official Online Store";
}

#indexTop .shop .shopLinksWrap li.search a:hover:after {
  animation: 1s arrow linear forwards;
}

#indexTop .shop .shopLinksWrap li.search p:before {
  background: url(/images/common/national_map.svg) center center no-repeat;
  background-size: contain;
}

#indexTop .shop .shopLinksWrap li.search p:after {
  content: "Store Search";
}

#indexTop .shop .shopLinksWrap li.store a:hover:after {
  animation: 1s arrow linear forwards;
}

#indexTop .shop .shopLinksWrap li.store p:before {
  background: url(/images/common/store_bag.svg) center center no-repeat;
  background-size: contain;
}

#indexTop .shop .shopLinksWrap li.store p:after {
  content: "Flagship Store";
}

#indexTop .pickUp {
  padding-top: 7.5vw;
  padding-bottom: 13.125vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp {
    padding-bottom: 210px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp {
    padding-bottom: 13.81579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp {
    padding-bottom: 15.37335vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp {
    padding-top: 13.33333vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp {
    padding-bottom: 14.64129vw;
  }
}

#indexTop .pickUp .minContainer {
  max-width: 1446px;
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp .minContainer {
    padding: 0;
  }
}

#indexTop .pickUp #pickUpSlide {
  position: relative;
}

#indexTop .pickUp #pickUpSlide .slick-slide {
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.16);
  line-height: 0;
  width: 26.5625vw;
  max-width: 425px;
  margin-right: 1.4375vw;
  margin-left: 1.4375vw;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 1.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 1.68375vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 1.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 1.68375vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    width: 56.66667vw;
    max-width: 425px;
    margin-right: 5.46667vw;
    margin-left: 5.46667vw;
    margin-bottom: 3.33333vw;
    box-shadow: 0.667vw 1.333vw 2.667vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 2.69737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-right: 3.00146vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 2.69737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-left: 3.00146vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slick-slide {
    margin-bottom: 1.83016vw;
  }
}

#indexTop .pickUp #pickUpSlide li img {
  width: 100%;
}

#indexTop .pickUp #pickUpSlide .slide-arrow {
  position: absolute;
  top: 13.28125vw;
  z-index: 2;
  width: 1.1875vw;
  max-width: 19px;
  height: 2.25625vw;
  max-height: 36.1px;
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 212.5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 13.98026vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 15.55637vw;
  }
}

#indexTop .pickUp #pickUpSlide .slide-arrow.prev-arrow {
  left: 0%;
  transform: translate(-50%, -50%);
}

#indexTop .pickUp #pickUpSlide .slide-arrow.next-arrow {
  right: 0%;
  transform: translate(50%, -50%);
}

@media screen and (min-width: 1920px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow.prev-arrow {
    left: -130px;
  }
  #indexTop .pickUp #pickUpSlide .slide-arrow.next-arrow {
    right: -130px;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow.prev-arrow {
    left: -70px;
  }
  #indexTop .pickUp #pickUpSlide .slide-arrow.next-arrow {
    right: -70px;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 28.33333vw;
    width: 2.53333vw;
    max-width: 19px;
    height: 4.81333vw;
    max-height: 36.1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 212.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 13.98026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow {
    top: 15.55637vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp #pickUpSlide .slide-arrow.prev-arrow {
    left: 13.33%;
    transform: translate(0%, -50%);
  }
  #indexTop .pickUp #pickUpSlide .slide-arrow.next-arrow {
    right: 13.33%;
    transform: translate(0%, -50%);
  }
}

#indexTop .pickUp .slide-dots {
  display: flex;
  justify-content: center;
  margin-top: 5.9375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 6.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 12.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 6.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp .slide-dots {
    margin-top: 6.95461vw;
  }
}

#indexTop .pickUp .slide-dots li {
  padding: 0 !important;
  width: 1.875vw;
  max-width: 30px;
  height: 0.25vw;
  max-height: 4px;
  background: #C3BFBF;
  margin: 0 5px;
}

@media screen and (max-width: 767px) {
  #indexTop .pickUp .slide-dots li {
    width: 4vw;
    max-width: 30px;
    height: 0.53333vw;
    max-height: 4px;
    margin-left: 0.93333vw;
    margin-right: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp .slide-dots li {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp .slide-dots li {
    margin-left: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp .slide-dots li {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .pickUp .slide-dots li {
    margin-right: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .pickUp .slide-dots li {
    margin-right: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .pickUp .slide-dots li {
    margin-right: 0.51245vw;
  }
}

#indexTop .pickUp .slide-dots li:hover {
  cursor: pointer;
}

#indexTop .pickUp .slide-dots li.slick-active {
  background: #666666;
}

#indexTop .pickUp .slide-dots li button {
  text-indent: -9999px;
}

#indexTop .newsSection {
  padding-top: 12.5vw;
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .newsSection {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .newsSection {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection {
    padding-top: 24vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection {
    padding-top: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection {
    padding-top: 13.17716vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection {
    padding-bottom: 13.17716vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection .minContainer {
    padding: 0 !important;
  }
}

#indexTop .newsSection ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .newsSection ul {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul {
    margin-bottom: 8.78477vw;
  }
}

#indexTop .newsSection ul:after {
  content: "";
  display: block;
  width: 31.039%;
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul {
    margin-bottom: 6.66667vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection ul {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul:after {
    content: none;
  }
  #indexTop .newsSection ul .slick-slide {
    padding: 0 .5%;
  }
  #indexTop .newsSection ul .slide-arrow {
    position: absolute;
    top: 19.33333vw;
    z-index: 2;
    width: 2.53333vw;
    max-width: 19px;
    height: 4.81333vw;
    max-height: 36.1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection ul .slide-arrow {
    top: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul .slide-arrow {
    top: 9.53947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul .slide-arrow {
    top: 10.61493vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul .slide-arrow.prev-arrow {
    left: 10%;
    transform: translate(-50%, -50%);
  }
  #indexTop .newsSection ul .slide-arrow.next-arrow {
    right: 10%;
    transform: translate(50%, -50%);
  }
}

#indexTop .newsSection ul li {
  width: 31.039%;
}

#indexTop .newsSection ul li .img img {
  width: 100%;
}

#indexTop .newsSection ul li .txt {
  padding-top: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 3.73333vw;
  }
}

#indexTop .newsSection ul li .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul li .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt .descTxt {
    padding-bottom: 2.19619vw;
  }
}

#indexTop .newsSection ul li .txt time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .newsSection ul li .txt time {
    font-size: 1.86667vw;
  }
}

#indexTop .media {
  padding-top: 12.5vw;
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .media {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .media {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media {
    padding-top: 24vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media {
    padding-top: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media {
    padding-top: 13.17716vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media {
    padding-bottom: 13.17716vw;
  }
}

#indexTop .media .mediaPostList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList {
    margin-bottom: 8.78477vw;
  }
}

#indexTop .media .mediaPostList li {
  background: #fff;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li {
    margin-bottom: 5.85652vw;
  }
}

#indexTop .media .mediaPostList li:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

#indexTop .media .mediaPostList li a {
  display: flex;
  padding-top: 4.375vw;
  padding-bottom: 4.375vw;
  padding-left: 2.1875vw;
  padding-right: 5vw;
  position: relative;
  transition: opacity .3s;
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 5.85652vw;
  }
}

#indexTop .media .mediaPostList li a:hover {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a {
    padding-right: 2.92826vw;
  }
}

#indexTop .media .mediaPostList li a .listInner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner {
    flex-direction: column-reverse;
  }
}

#indexTop .media .mediaPostList li a .listInner time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 10.029%;
  padding-right: 1em;
}

@media screen and (min-width: 1920px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 22.66667vw;
    max-width: 170px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner time {
    font-size: 3.73333vw;
  }
}

#indexTop .media .mediaPostList li a .listInner p {
  width: 70%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
    line-height: 1.71;
    letter-spacing: normal;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .media .mediaPostList li a .listInner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a .listInner p {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a .listInner p {
    margin-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a .listInner p {
    margin-bottom: 2.56223vw;
  }
}

#indexTop .media .mediaPostList li a img {
  position: absolute;
  right: 5vw;
  bottom: 1.25vw;
  width: 9.25vw;
  max-width: 148px;
  height: 11.9375vw;
  max-height: 191px;
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s .5s, transform 1s .5s;
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    right: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .media .mediaPostList li a img {
    position: inherit;
    width: 19.73333vw;
    max-width: 148px;
    height: 25.33333vw;
    max-height: 190px;
    right: 0vw;
    bottom: 0vw;
    top: -6.66667vw;
    margin-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    top: -50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    top: -3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    top: -3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .media .mediaPostList li a img {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .media .mediaPostList li a img {
    margin-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .media .mediaPostList li a img {
    margin-left: 3.66032vw;
  }
}

#indexTop .media .mediaPostList li.active a img {
  opacity: 1;
  visibility: visible;
  transform: none;
}

#indexTop .support {
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .support {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support {
    padding-top: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support {
    padding-top: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support {
    padding-top: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support {
    padding-top: 13.17716vw;
  }
}

#indexTop .support .secTit {
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #indexTop .support .secTit {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .secTit {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .secTit {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .secTit {
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .secTit {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .secTit {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .secTit {
    padding-bottom: 5.85652vw;
  }
}

#indexTop .support .supportRead {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.6px;
  text-align: center;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1920px) {
  #indexTop .support .supportRead {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportRead {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportRead {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportRead {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #indexTop .support .supportRead {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportRead {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportRead {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportRead {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: normal;
    text-align: left;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .support .supportRead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportRead {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportRead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .support .supportRead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportRead {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportRead {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportRead {
    margin-bottom: 7.32064vw;
  }
}

#indexTop .support .supportLinksWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#indexTop .support .supportLinksWrap li {
  width: 30.38%;
  background: #fff;
  text-align: center;
  margin: 0 2.1961%;
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li {
    width: 100%;
    margin-bottom: 6.66667vw;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li {
    margin-bottom: 3.66032vw;
  }
}

#indexTop .support .supportLinksWrap li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 14.45% 9.74% 9.74% 9.74%;
  position: relative;
  transition: opacity .3s;
}

#indexTop .support .supportLinksWrap li a:hover {
  opacity: .7;
}

#indexTop .support .supportLinksWrap li a:after {
  content: "";
  background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 12.11%;
  height: 7px;
  position: absolute;
  bottom: 9.74%;
  right: 9.74%;
}

#indexTop .support .supportLinksWrap li a p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  margin-bottom: 12.145%;
}

@media screen and (min-width: 1920px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 3.2vw;
  }
}

#indexTop .support .supportLinksWrap li a p:before {
  content: "";
  width: 28.34%;
  height: 4.375vw;
  max-height: 70px;
  display: block;
  margin: 0 auto 12.1%;
}

#indexTop .support .supportLinksWrap li a p:after {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.28px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a {
    padding-top: 8vw;
    padding-left: 5.33333vw;
    padding-bottom: 14.66667vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a {
    padding-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a:after {
    width: 7.06667vw;
    max-width: 53px;
    height: 1.33333vw;
    max-height: 10px;
    right: 5.33333vw;
    bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a:after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a:after {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a:after {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a:after {
    bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a:after {
    bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a:after {
    bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    margin-bottom: 0vw;
    display: flex;
    align-items: center;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p:before {
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
    margin-right: 7.33333vw;
    margin-left: 0vw;
    margin-bottom: -6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-right: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-right: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-right: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-bottom: -50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-bottom: -3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:before {
    margin-bottom: -3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 2.2rem;
    font-size: 1.14583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.4px;
    position: absolute;
    bottom: -2.4vw;
    left: 16vw;
    transform: translate(0, 1em);
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 1.44737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #indexTop .support .supportLinksWrap li a p:after {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p:after {
    bottom: -18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:after {
    bottom: -1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:after {
    bottom: -1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #indexTop .support .supportLinksWrap li a p:after {
    left: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #indexTop .support .supportLinksWrap li a p:after {
    left: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #indexTop .support .supportLinksWrap li a p:after {
    left: 8.78477vw;
  }
}

#indexTop .support .supportLinksWrap li.faq a:hover:after {
  animation: 1s arrow linear forwards;
}

#indexTop .support .supportLinksWrap li.faq p:before {
  background: url(/images/common/faq_icon.svg) center center no-repeat;
  background-size: contain;
}

#indexTop .support .supportLinksWrap li.faq p:after {
  content: "FAQ";
}

#indexTop .support .supportLinksWrap li.contact a:hover:after {
  animation: 1s arrow linear forwards;
}

#indexTop .support .supportLinksWrap li.contact p:before {
  background: url(/images/common/contact_mail_icon.svg) center center no-repeat;
  background-size: contain;
}

#indexTop .support .supportLinksWrap li.contact p:after {
  content: "Contact Us";
}

/* ==============================================
トップページ
============================================== */
.contents {
  background-color: #fcecdd;
}

.contents #header {
  background-image: none;
  background-color: rgba(255, 255, 255, 0.7);
  transition: top .5s;
}

.contents #header.fixed-hide {
  top: -7.5vw;
}

@media screen and (min-width: 1600px) {
  .contents #header.fixed-hide {
    top: -120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents #header.fixed-hide {
    top: -7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents #header.fixed-hide {
    top: -8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .contents #header.fixed-hide {
    top: -7.5vw;
  }
}

@media screen and (max-width: 767px) {
  .contents #header.fixed-hide {
    top: -16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents #header.fixed-hide {
    top: -125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents #header.fixed-hide {
    top: -8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents #header.fixed-hide {
    top: -9.15081vw;
  }
}

.contents #header.changeStyle {
  background-color: #fff;
}

.contents #header.changeStyle .headerContents {
  height: 5vw;
  max-height: 80px;
}

@media screen and (max-width: 767px) {
  .contents #header.changeStyle .headerContents {
    height: 10.66667vw;
    max-height: 80px;
  }
}

.contents #header.changeStyle .headerLogo {
  width: 6.625vw;
  max-width: 106px;
}

@media screen and (max-width: 767px) {
  .contents #header.changeStyle .headerLogo {
    width: 14.13333vw;
    max-width: 106px;
  }
}

@media screen and (min-width: 768px) {
  .contents #header.changeStyle .pcGlobalNav {
    padding-left: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  .contents #header.changeStyle .pcGlobalNav {
    padding-left: 50px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents #header.changeStyle .pcGlobalNav {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  .contents #header.changeStyle .pcGlobalNav {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 768px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.125vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 50px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList {
    margin-right: 3.66032vw;
  }
}

@media screen and (min-width: 768px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.6rem;
    font-size: 0.83333vw;
    letter-spacing: 0.8px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1920px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 1.1713vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 767px) {
  .contents #header.changeStyle .pcGlobalNav ul .pcGlobalNavList a {
    font-size: 2.13333vw;
  }
}

.contents .ui-widget.ui-widget-content {
  display: block;
  width: 11.25vw;
  max-width: 180px;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  border: 1px solid #333;
  background: #333;
  color: #fff;
  border-radius: 6px;
  transform: translateZ(1px);
  padding-top: 0.3125vw;
  padding-bottom: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 0.36603vw;
  }
}

.contents .ui-widget.ui-widget-content::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.contents .ui-widget.ui-widget-content.disp-top::after {
  bottom: -0.9375vw;
  left: 0.625vw;
  border-top-width: 0.9375vw;
  border-right-width: 0.9375vw;
  border-left-width: 0.9375vw;
  border-color: #333 transparent transparent transparent;
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 1.0981vw;
  }
}

.contents .ui-widget.ui-widget-content.disp-btm::after {
  top: -0.9375vw;
  left: 0.625vw;
  border-bottom-width: 0.9375vw;
  border-right-width: 0.9375vw;
  border-left-width: 0.9375vw;
  border-color: transparent transparent #333 transparent;
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content {
    width: 48vw;
    max-width: 360px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-top: 0.8vw;
    padding-bottom: 0.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    padding-top: 0.43924vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content {
    padding-bottom: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -3.33333vw;
    left: 2.66667vw;
    border-top-width: 3.33333vw;
    border-right-width: 3.33333vw;
    border-left-width: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    bottom: -1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-top-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-right-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-top::after {
    border-left-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -3.33333vw;
    left: 2.66667vw;
    border-bottom-width: 3.33333vw;
    border-right-width: 3.33333vw;
    border-left-width: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    top: -1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-bottom-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-right-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-widget.ui-widget-content.disp-btm::after {
    border-left-width: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-widget.ui-widget-content .ui-menu-item:nth-of-type(n+4) {
    display: none;
  }
}

.contents .ui-menu .ui-menu-item-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contents .ui-menu .ui-menu-item-wrapper {
  display: block;
  cursor: pointer;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-right: 0.75vw;
  padding-left: 0.75vw;
}

@media screen and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    padding-right: 3.2vw;
    padding-left: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-right: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .contents .ui-menu .ui-menu-item-wrapper {
    padding-left: 1.75695vw;
  }
}

.contents .ui-state-active,
.contents .ui-widget-content .ui-state-active,
.contents .ui-widget-header .ui-state-active,
.contents a.ui-button:active,
.contents .ui-button:active,
.contents .ui-button.ui-state-active:hover {
  border: none;
  background: inherit;
  color: #fff;
  margin: 0;
  opacity: .7;
}

#contentsTop {
  background-image: linear-gradient(to bottom, #fce9d8 120px, rgba(255, 250, 246, 0.6) 850px, rgba(255, 250, 246, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #contentsTop {
    background-image: linear-gradient(to bottom, #fce9d8 7.5vw, rgba(255, 250, 246, 0.6) 52.63vw, rgba(255, 250, 246, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #contentsTop {
    background-image: linear-gradient(to bottom, #fce9d8 16.66vw, rgba(255, 250, 246, 0.6) 106vw, rgba(255, 250, 246, 0.8) 126.6vw);
  }
}

#contentsTop .contentsSubNav {
  position: fixed;
  top: 7.5vw;
  left: 0;
  z-index: 999;
  max-width: 100%;
  width: 100%;
  background-color: #fff;
  height: 3.75vw;
  max-height: 60px;
  transition: top .5s;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav {
    top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav {
    top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav {
    top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #contentsTop .contentsSubNav {
    top: 7.5vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav {
    top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav {
    top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav {
    top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav {
    top: 9.15081vw;
  }
}

#contentsTop .contentsSubNav.scrollDown {
  top: 0vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav.scrollDown {
    top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav.scrollDown {
    top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav.scrollDown {
    top: 0vw;
  }
}

#contentsTop .contentsSubNav.minTop {
  top: 5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav.minTop {
    top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav.minTop {
    top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav.minTop {
    top: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #contentsTop .contentsSubNav.minTop {
    top: 5vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav.minTop {
    top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav.minTop {
    top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav.minTop {
    top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav.minTop {
    top: 5.85652vw;
  }
}

#contentsTop .contentsSubNav dl {
  position: relative;
  height: 100%;
}

#contentsTop .contentsSubNav dl dd {
  height: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav {
    height: 10.66667vw;
    max-height: 80px;
  }
  #contentsTop .contentsSubNav dl dd {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 998;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  #contentsTop .contentsSubNav .accordionBtn {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 26.66667vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-right: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-right: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-right: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .accordionBtn {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .accordionBtn.isOpen::after {
    transform: rotate(-180deg);
  }
  #contentsTop .contentsSubNav .accordionBtn::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6.66667vw;
    margin: auto;
    background-image: url(/images/common/suvnav_accordion_arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: transform .5s;
    backface-visibility: hidden;
    width: 6vw;
    max-width: 45px;
    height: 3.06667vw;
    max-height: 23px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .accordionBtn::after {
    right: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .accordionBtn::after {
    right: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .accordionBtn::after {
    right: 3.66032vw;
  }
}

#contentsTop .contentsSubNav .flex {
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .flex {
    display: block;
    background-color: #fff;
    padding: 0;
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .flex {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .flex {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .flex {
    padding-top: 4.39239vw;
  }
}

#contentsTop .contentsSubNav .logo {
  width: 9.25vw;
  max-width: 148px;
  margin-right: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 1.875vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .logo {
    margin-right: 2.19619vw;
  }
}

#contentsTop .contentsSubNav .contentsSubNavList {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    display: block;
    padding-right: 12vw;
    padding-left: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-right: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-right: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-right: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-left: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-left: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList {
    padding-left: 6.58858vw;
  }
}

#contentsTop .contentsSubNav .contentsSubNavList .list {
  flex-shrink: 0;
  margin-right: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 1.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-bottom: 8vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list {
    margin-right: 0vw;
  }
}

#contentsTop .contentsSubNav .contentsSubNavList .list a {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  color: #6456b7;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 2.4vw;
  }
}

#contentsTop .contentsSubNav .contentsSubNavList .list a::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.875vw;
  max-width: 30px;
  height: 1.875vw;
  max-height: 30px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: .64px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    width: 7.2vw;
    max-width: 54px;
    height: 6.13333vw;
    max-height: 46px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .contentsSubNavList .list a::before {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsSubNav .contentsSubNavList .list.topics a {
  color: #6456b7;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.topics a::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsSubNav .contentsSubNavList .list.iroha_story a {
  color: #4a6bb7;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.iroha_story a::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsSubNav .contentsSubNavList .list.interview a {
  color: #63a534;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.interview a::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsSubNav .contentsSubNavList .list.column a {
  color: #aaa73f;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.column a::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsSubNav .contentsSubNavList .list.research a {
  color: #c78246;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.research a::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsSubNav .contentsSubNavList .list.review a {
  color: #bc5680;
}

#contentsTop .contentsSubNav .contentsSubNavList .list.review a::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsSubNav .searchArea {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea {
    display: block;
    height: auto;
    margin: auto;
  }
}

#contentsTop .contentsSubNav .searchArea .searchAreaBack {
  cursor: pointer;
  background-image: url(/images/common/search_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.625vw;
  max-width: 26px;
  height: 1.625vw;
  max-height: 26px;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .searchAreaBack {
    display: none;
  }
}

#contentsTop .contentsSubNav .searchArea .formBox {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f2f2f2;
  width: 19.375vw;
  max-width: 310px;
  padding-top: 0.625vw;
  padding-bottom: 0.625vw;
  padding-right: 0.625vw;
  padding-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 0.73206vw;
  }
}

#contentsTop .contentsSubNav .searchArea .formBox .box {
  display: flex;
  align-items: center;
}

#contentsTop .contentsSubNav .searchArea .formBox .bg {
  flex-shrink: 0;
  background-image: url(/images/common/search_icon.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.4375vw;
  max-width: 23px;
  height: 1.4375vw;
  max-height: 23px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsSubNav .searchArea .formBox input {
  display: block;
}

#contentsTop .contentsSubNav .searchArea .formBox input[type="submit"] {
  cursor: pointer;
  opacity: 0;
  max-width: 100%;
}

#contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
  max-width: 100%;
  width: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  font-size: 0.625vw;
  font-weight: 500;
  line-height: 1.25;
  color: #666;
  border: none;
  border-radius: 0;
  background-color: #fff;
  padding: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 1.6vw;
  }
}

#contentsTop .contentsSubNav .searchArea .formBox input[type="text"]::placeholder {
  color: #adadad;
}

#contentsTop .contentsSubNav .searchArea .formBox input[type="text"]:-ms-input-placeholder {
  color: #adadad;
}

#contentsTop .contentsSubNav .searchArea .formBox input[type="text"]::-ms-input-placeholder {
  color: #adadad;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    position: static;
    max-width: 100%;
    width: 100%;
    padding-top: 2vw;
    padding-bottom: 2vw;
    padding-right: 4vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    width: 6.93333vw;
    max-width: 52px;
    height: 6.93333vw;
    max-height: 52px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox .bg {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    max-width: 100%;
    width: 100%;
    font-size: 2.9rem;
    font-size: 1.51042vw;
    padding: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 2.9rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 1.90789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 2.12299vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    font-size: 3.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSubNav .searchArea .formBox input[type="text"] {
    padding: 1.46413vw;
  }
}

#contentsTop .contentsArea {
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
  padding-top: 11.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea {
    padding-top: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea {
    padding-top: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea {
    padding-top: 13.17716vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea {
    padding-top: 11.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea {
    padding-top: 11.33vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea {
    padding-top: 15.00732vw;
  }
}

#contentsTop .contentsArea .gradation {
  position: relative;
  z-index: 1;
}

#contentsTop .contentsArea .contentsWrap {
  position: relative;
  z-index: 1;
}

#contentsTop .contentsArea .contentsWrap .flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .contentsWrap .flex {
    display: block;
    padding: 0;
  }
}

#contentsTop .contentsArea .contentsWrap .articlesColumn {
  max-width: 70.718%;
  width: 100%;
  padding-right: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    max-width: 100%;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .contentsWrap .articlesColumn {
    padding-right: 0vw;
  }
}

#contentsTop .contentsArea .contentsWrap .sideColumn {
  max-width: 27.453%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .contentsWrap .sideColumn {
    max-width: 100%;
  }
}

#contentsTop .contentsArea .sideColumn {
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.3) 82%, rgba(255, 255, 255, 0) 100%);
  padding-top: 4.875vw;
  padding-bottom: 3.75vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 78px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 5.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 5.7101vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn {
    background-color: inherit;
    background-image: none;
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn {
    padding-left: 0vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact {
  border: 1px solid #C78246;
  background: #fff;
  border-radius: 5px;
  margin-bottom: 3.125vw;
  padding-bottom: 1.25vw;
  padding-right: 1.5625vw;
  padding-left: 1.5625vw;
  padding-top: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-right: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-top: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    width: 89.33333vw;
    max-width: 670px;
    margin-top: 16vw;
    margin-bottom: 0vw;
    padding: 5.33333vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact {
    padding: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact .read {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: normal;
  margin-bottom: 1.875vw;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .read {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact .contactBtn {
  width: 70.67%;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn {
    width: 100%;
    height: 16vw;
    max-height: 120px;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
  display: flex;
  justify-content: center;
  align-items: baseline;
  background: #C78246;
  width: 100%;
  height: 100%;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-weight: bold;
  transition: opacity .3s;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-top: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
    margin-left: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
    margin-left: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
    margin-left: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
    margin-left: 2.34261vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
  content: "";
  background: url(/images/contents/side_arrow.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.8em;
  height: 1em;
  display: inline-block;
  margin-left: 6px;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:after {
    background: url(/images/contents/side_arrow.svg) center center no-repeat;
  }
}

#contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:hover {
  opacity: .7;
}

#contentsTop .contentsArea .sideColumn .sideContact .contactBtn a:hover:after {
  animation: 1s arrow linear forwards;
}

#contentsTop .contentsArea .sideColumn .tagList {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    justify-content: center;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-top: 16vw;
    padding-bottom: 10.66667vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsArea .sideColumn .tagList .tag {
  margin-right: 0.3125vw;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 1.33333vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .tagList .tag a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #808080;
  background-color: #fff;
  border-radius: 100px;
  padding-top: 0.625vw;
  padding-bottom: 0.625vw;
  padding-right: 0.9375vw;
  padding-left: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 4vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .tagList .tag a {
    padding-left: 2.19619vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking {
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-bottom: 21.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking.topics .sideRankingCard .img .num::after {
  border-color: #6456b7 transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking.iroha_story .sideRankingCard .img .num::after {
  border-color: #4a6bb7 transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking.interview .sideRankingCard .img .num::after {
  border-color: #63a534 transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking.column .sideRankingCard .img .num::after {
  border-color: #aaa73f transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking.research .sideRankingCard .img .num::after {
  border-color: #c78246 transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking.review .sideRankingCard .img .num::after {
  border-color: #bc5680 transparent transparent transparent;
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 1;
  letter-spacing: 1.54px;
  color: #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  padding-bottom: 0.625vw;
  margin-bottom: 2.8125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    letter-spacing: 1.2px;
    border-bottom: solid 1px #4d4d4d;
    padding-bottom: 2.66667vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingTit {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard:last-of-type {
  margin-bottom: 0;
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
  display: flex;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a img,
#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a .bgImg {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a:hover img,
#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a:hover .bgImg, #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a.isActive img,
#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a.isActive .bgImg {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    position: relative;
    z-index: 1;
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard a::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #fff;
    height: 100%;
    width: 68vw;
    max-width: 510px;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 8.75vw;
  max-width: 140px;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  z-index: 1;
  width: 2.125vw;
  max-width: 34px;
  height: 3.375vw;
  max-height: 54px;
  padding-top: 0.3125vw;
  padding-left: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 0.36603vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  top: 0;
  height: 0;
  border-style: solid;
  border-width: 54px 34px 0 0;
  border-top-width: 3.375vw;
  border-right-width: 2.125vw;
  border-color: #f79902 transparent transparent transparent;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 54px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 3.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 3.95315vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 34px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 2.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 2.48902vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    width: 40vw;
    max-width: 300px;
    margin-right: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img {
    margin-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 7.6vw;
    max-width: 57px;
    height: 12.13333vw;
    max-height: 91px;
    padding-top: 1.33333vw;
    padding-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 12.13333vw;
    border-right-width: 7.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 91px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 5.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-top-width: 6.66179vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 57px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 3.75vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .img .num::after {
    border-right-width: 4.17277vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  line-height: 1.63;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: bold;
    line-height: 1.57;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideRanking .sideRankingCard .sideRankingCardTit {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 1;
  letter-spacing: 1.54px;
  color: #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  padding-bottom: 0.625vw;
  margin-bottom: 2.8125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    letter-spacing: 1.2px;
    border-bottom: solid 1px #4d4d4d;
    padding-bottom: 2.66667vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureTit {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard:last-of-type {
  margin-bottom: 0;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
  display: flex;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a img,
#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a .bgImg {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a:hover img,
#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a:hover .bgImg, #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a.isActive img,
#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a.isActive .bgImg {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    position: relative;
    z-index: 1;
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard a::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background-color: #fff;
    height: 100%;
    width: 68vw;
    max-width: 510px;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
  flex-shrink: 0;
  overflow: hidden;
  width: 8.75vw;
  max-width: 140px;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    width: 40vw;
    max-width: 300px;
    margin-right: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .img {
    margin-right: 2.56223vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  line-height: 1.63;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: bold;
    line-height: 1.57;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .sideFeatureCardTit {
    padding-right: 2.92826vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 0.36603vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.3125vw;
  max-height: 21px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    width: 7.06667vw;
    max-width: 53px;
    height: 5.86667vw;
    max-height: 44px;
    margin-right: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category::before {
    margin-right: 0.58565vw;
  }
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.topics {
  color: #6456b7;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.interview {
  color: #63a534;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.column {
  color: #aaa73f;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.research {
  color: #c78246;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.review {
  color: #bc5680;
}

#contentsTop .contentsArea .sideColumn .sideFeature .sideFeatureCard .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsArea .newsPaging {
  display: flex;
  align-items: center;
  justify-content: center;
}

#contentsTop .contentsArea .newsPaging.topics .page a.active {
  background-color: #6456b7;
}

#contentsTop .contentsArea .newsPaging.iroha_story .page a.active {
  background-color: #4a6bb7;
}

#contentsTop .contentsArea .newsPaging.interview .page a.active {
  background-color: #63a534;
}

#contentsTop .contentsArea .newsPaging.column .page a.active {
  background-color: #aaa73f;
}

#contentsTop .contentsArea .newsPaging.research .page a.active {
  background-color: #c78246;
}

#contentsTop .contentsArea .newsPaging.review .page a.active {
  background-color: #bc5680;
}

#contentsTop .contentsArea .newsPaging .page {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsArea .newsPaging .page a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #4d4d4d;
  width: 2.25vw;
  max-width: 36px;
  height: 2.25vw;
  max-height: 36px;
  margin-right: 0.4375vw;
  margin-left: 0.4375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 0.51245vw;
  }
}

#contentsTop .contentsArea .newsPaging .page a.active {
  font-weight: bold;
  color: #fff;
  background-color: #4d4d4d;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .page {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .page a {
    width: 7.6vw;
    max-width: 57px;
    height: 7.6vw;
    max-height: 57px;
    margin-right: 0.66667vw;
    margin-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .page a {
    margin-left: 0.36603vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow a {
  color: #4d4d4d;
}

#contentsTop .contentsArea .newsPaging .pageArrow img {
  display: inline-block;
  vertical-align: baseline;
  backface-visibility: hidden;
  width: 1.9375vw;
  max-width: 31px;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow img {
    width: 6.4vw;
    max-width: 48px;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow.back {
  margin-right: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 2.19619vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow.back a:hover img {
  animation: 1s back linear forwards;
}

#contentsTop .contentsArea .newsPaging .pageArrow.back img {
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.back img {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow.next {
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 0.73206vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow.next img {
  margin-left: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 1.0981vw;
  }
}

#contentsTop .contentsArea .newsPaging .pageArrow.next a:hover img {
  animation: 1s arrow linear forwards;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next {
    margin-left: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next img {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next::before {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next::before {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next::before {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArea .newsPaging .pageArrow.next::before {
    margin-right: 2.19619vw;
  }
}

#contentsTop .contentsArea.contentsArticles {
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

#contentsTop .contentsIndex .mainviewArea {
  position: relative;
  z-index: 1;
}

#contentsTop .contentsIndex .mainviewArea .logo {
  margin: 0 auto;
  width: 23.625vw;
  max-width: 378px;
  margin-bottom: 7.5vw;
  margin-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    width: 50.4vw;
    max-width: 378px;
    margin-bottom: 21.33333vw;
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .logo {
    margin-top: 11.71303vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea {
  max-width: 1406px;
  position: relative;
  opacity: 0;
  transition: opacity .3s;
  margin: 0 auto;
}

#contentsTop .contentsIndex .mainviewArea .slideArea.slick-initialized {
  opacity: 1;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-slide {
  overflow-x: hidden;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slide {
  padding: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 1.46413vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slide img {
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide img {
    box-shadow: none;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea a {
  display: block;
}

#contentsTop .contentsIndex .mainviewArea .slideArea img {
  width: 100%;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow {
  cursor: pointer;
  position: absolute;
  top: 39.5%;
  width: 2vw;
  max-width: 32px;
  z-index: 1;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.prev-arrow {
  right: 3.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.prev-arrow {
    right: 53px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.prev-arrow {
    right: 3.48684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.prev-arrow {
    right: 3.87994vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.next-arrow {
  left: 3.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.next-arrow {
    left: 53px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.next-arrow {
    left: 3.48684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow.next-arrow {
    left: 3.87994vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slide-arrow {
    display: none !important;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots {
    margin-top: 3.66032vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
  background-color: #c3bfbf;
  width: 1.875vw;
  max-width: 30px;
  height: 0.25vw;
  max-height: 4px;
  margin-right: 0.3125vw;
  margin-left: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    width: 4vw;
    max-width: 30px;
    height: 0.53333vw;
    max-height: 4px;
    margin-right: 0.66667vw;
    margin-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li {
    margin-left: 0.36603vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li.slick-active {
  background-color: #666;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li.slick-active button {
  background-color: #666;
}

#contentsTop .contentsIndex .mainviewArea .slideArea .slick-dots li button {
  background-color: #c3bfbf;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

#contentsTop .contentsIndex .mainviewArea .headTagArea {
  margin-top: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea {
    margin-top: 11.71303vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-right: 2.5vw;
    padding-left: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #808080;
  margin-right: 0.3125vw;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
  display: block;
  border-radius: 100px;
  background-color: #fff;
  color: #808080;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.6vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 4vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .mainviewArea .headTagArea .tagList .tag a {
    padding-left: 2.19619vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea {
  padding-top: 10.75vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 172px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 11.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 12.59151vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 21.33333vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .minContainer {
    padding: 0;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  line-height: 1;
  letter-spacing: 2.8px;
  text-align: center;
  color: #666;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    letter-spacing: 1.4px;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesTit {
    margin-bottom: 11.71303vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .category {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 1.83016vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.625vw;
  max-height: 26px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    width: 7.2vw;
    max-width: 54px;
    height: 6.4vw;
    max-height: 48px;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .category::before {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .category.topics {
  color: #6456b7;
}

#contentsTop .contentsIndex .newArticlesArea .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsIndex .newArticlesArea .category.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsIndex .newArticlesArea .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsIndex .newArticlesArea .category.interview {
  color: #63a534;
}

#contentsTop .contentsIndex .newArticlesArea .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsIndex .newArticlesArea .category.column {
  color: #aaa73f;
}

#contentsTop .contentsIndex .newArticlesArea .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsIndex .newArticlesArea .category.research {
  color: #c78246;
}

#contentsTop .contentsIndex .newArticlesArea .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsIndex .newArticlesArea .category.review {
  color: #bc5680;
}

#contentsTop .contentsIndex .newArticlesArea .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 10.98097vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    display: block;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard {
    margin-bottom: 14.64129vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n)::after {
  left: 0;
  right: auto;
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .img {
  order: 2;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .img {
    margin: 0 0 0 auto;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt {
  order: 1;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt {
    margin: 0;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
  padding-right: 2.5vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
  padding-right: 2.5vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard:nth-of-type(2n) .txt .tagList {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
  content: "";
  display: block;
  width: 53.125vw;
  max-width: 850px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 3.125vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    max-width: 94.7%;
    width: 100%;
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard a {
  display: block;
  position: relative;
  z-index: 0;
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .img {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-width: 59.298%;
  width: 100%;
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .img {
    max-width: 94.7%;
    width: 100%;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  max-width: 40.702%;
  width: 100%;
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
  padding-top: 3.125vw;
  padding-bottom: 2.1875vw;
  padding-right: 3.125vw;
  padding-left: 3.125vw;
  margin-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt {
    max-width: 94.7%;
    width: 100%;
  }
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 8vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .newArticlesCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0.625vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
    padding-bottom: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-bottom: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList {
    padding-bottom: 2.04978vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.3125vw;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 0.36603vw;
  }
}

#contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag {
    margin-bottom: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .newArticlesArea .newArticlesCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsIndex .featureArea {
  padding-top: 12.5vw;
  padding-bottom: 11.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 190px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 12.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 13.90922vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 21.33333vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .minContainer {
    padding: 0;
  }
}

#contentsTop .contentsIndex .featureArea .featureTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  line-height: 1;
  letter-spacing: 2.8px;
  text-align: center;
  color: #666;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    letter-spacing: 1.4px;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureTit {
    margin-bottom: 11.71303vw;
  }
}

#contentsTop .contentsIndex .featureArea .category {
  display: flex;
  align-items: center;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 1.83016vw;
  }
}

#contentsTop .contentsIndex .featureArea .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.625vw;
  max-height: 26px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    width: 7.2vw;
    max-width: 54px;
    height: 6.4vw;
    max-height: 48px;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .category::before {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsIndex .featureArea .category.topics {
  color: #6456b7;
}

#contentsTop .contentsIndex .featureArea .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsIndex .featureArea .category.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsIndex .featureArea .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsIndex .featureArea .category.interview {
  color: #63a534;
}

#contentsTop .contentsIndex .featureArea .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsIndex .featureArea .category.column {
  color: #aaa73f;
}

#contentsTop .contentsIndex .featureArea .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsIndex .featureArea .category.research {
  color: #c78246;
}

#contentsTop .contentsIndex .featureArea .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsIndex .featureArea .category.review {
  color: #bc5680;
}

#contentsTop .contentsIndex .featureArea .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsIndex .featureArea .featureCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 10.98097vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    display: block;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard {
    margin-bottom: 14.64129vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n)::after {
  left: 0;
  right: auto;
}

#contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .img {
  order: 2;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .img {
    margin: 0 0 0 auto;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt {
  order: 1;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt {
    margin: 0;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
  padding-right: 2.5vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
  padding-right: 2.5vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard:nth-of-type(2n) .txt .tagList {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard::after {
  content: "";
  display: block;
  width: 53.125vw;
  max-width: 850px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 3.125vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    max-width: 94.7%;
    width: 100%;
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard a {
  display: block;
  position: relative;
  z-index: 0;
}

#contentsTop .contentsIndex .featureArea .featureCard .img {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-width: 59.298%;
  width: 100%;
}

#contentsTop .contentsIndex .featureArea .featureCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .img {
    max-width: 94.7%;
    width: 100%;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  max-width: 40.702%;
  width: 100%;
}

#contentsTop .contentsIndex .featureArea .featureCard .txt > a {
  padding-top: 3.125vw;
  padding-bottom: 2.1875vw;
  padding-right: 3.125vw;
  padding-left: 3.125vw;
  margin-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt {
    max-width: 94.7%;
    width: 100%;
  }
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 8vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .featureCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0.625vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
    padding-bottom: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-bottom: 48px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-bottom: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList {
    padding-bottom: 3.51391vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.3125vw;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 0.36603vw;
  }
}

#contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag {
    margin-bottom: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .featureArea .featureCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsIndex .rankingArea {
  padding-top: 12.5vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 21.33333vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .minContainer {
    padding: 0;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  line-height: 1;
  letter-spacing: 2.8px;
  text-align: center;
  color: #666;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    letter-spacing: 1.4px;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingTit {
    margin-bottom: 11.71303vw;
  }
}

#contentsTop .contentsIndex .rankingArea .category {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 1.83016vw;
  }
}

#contentsTop .contentsIndex .rankingArea .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.625vw;
  max-height: 26px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    width: 7.06667vw;
    max-width: 53px;
    height: 5.86667vw;
    max-height: 44px;
    margin-right: 1.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .category::before {
    margin-right: 0.58565vw;
  }
}

#contentsTop .contentsIndex .rankingArea .category.topics {
  color: #6456b7;
}

#contentsTop .contentsIndex .rankingArea .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsIndex .rankingArea .category.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsIndex .rankingArea .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsIndex .rankingArea .category.interview {
  color: #63a534;
}

#contentsTop .contentsIndex .rankingArea .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsIndex .rankingArea .category.column {
  color: #aaa73f;
}

#contentsTop .contentsIndex .rankingArea .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsIndex .rankingArea .category.research {
  color: #c78246;
}

#contentsTop .contentsIndex .rankingArea .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsIndex .rankingArea .category.review {
  color: #bc5680;
}

#contentsTop .contentsIndex .rankingArea .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsIndex .rankingArea .flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#contentsTop .contentsIndex .rankingArea .rankingCard {
  position: relative;
  display: flex;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 10.98097vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
  display: block;
  max-width: 48.683%;
  margin-bottom: 16vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    max-width: 100%;
    display: flex;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
  top: 15.625vw;
  left: 0;
  max-width: 96.242%;
  width: 100%;
  height: calc(100% - 15.625vw);
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 18.30161vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    height: calc(100% - 250px);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    height: calc(100% - 16.44737vw);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    height: calc(100% - 18.30161vw);
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    width: 68vw;
    max-width: 510px;
    top: 0vw;
    height: 100%;
    left: inherit;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type)::after {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .category {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .category {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img {
  max-width: 96.241%;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img {
    margin-top: 5.33333vw;
    max-width: 73.3%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 8vw;
    max-width: 60px;
    height: 12.8vw;
    max-height: 96px;
    padding-top: 1.6vw;
    padding-left: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-top: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-top: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-left: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num {
    padding-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-top-width: 12.8vw;
    border-right-width: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-top-width: 96px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-top-width: 6.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-top-width: 7.02782vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-right-width: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-right-width: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .img .num::after {
    border-right-width: 4.39239vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt {
  position: relative;
  max-width: 100%;
  z-index: 1;
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
  padding-bottom: 1.875vw;
  padding-right: 4.6875vw;
  margin-top: 0vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt {
    max-width: 84%;
  }
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 4.66667vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    padding-left: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 1.78;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .rankingCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
  padding-right: 4.6875vw;
  padding-bottom: 2.1875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 0vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList .tag {
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList .tag {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList .tag {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:not(:first-of-type) .tagList .tag {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 768px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:last-of-type, #contentsTop .contentsIndex .rankingArea .rankingCard:nth-last-of-type(2) {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    display: block;
    margin-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    display: flex;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard:last-of-type {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard::after {
  content: "";
  display: block;
  width: 53.125vw;
  max-width: 850px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 3.125vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    width: 68vw;
    max-width: 510px;
    top: 0vw;
    height: 100%;
    left: inherit;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard::after {
    top: 0vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard a {
  display: block;
  position: relative;
  z-index: 0;
}

#contentsTop .contentsIndex .rankingArea .rankingCard .img {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-width: 59.298%;
  width: 100%;
}

#contentsTop .contentsIndex .rankingArea .rankingCard .img img {
  width: 100%;
}

#contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  z-index: 1;
  width: 4.8125vw;
  max-width: 77px;
  height: 7.625vw;
  max-height: 122px;
  padding-top: 0.625vw;
  padding-left: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 1.0981vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  top: 0;
  height: 0;
  border-style: solid;
  border-width: 122px 77px 0 0;
  border-top-width: 7.625vw;
  border-right-width: 4.8125vw;
  border-color: #f79902 transparent transparent transparent;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 122px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 8.02632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 8.93119vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 77px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 5.06579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 5.6369vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img {
    width: 40vw;
    max-width: 300px;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
    margin: 0 0 0 auto;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 7.6vw;
    max-width: 57px;
    height: 12.13333vw;
    max-height: 91px;
    padding-top: 1.6vw;
    padding-left: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-top: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num {
    padding-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 12.13333vw;
    border-right-width: 7.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 91px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 5.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-top-width: 6.66179vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 57px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 3.75vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .img .num::after {
    border-right-width: 4.17277vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  max-width: 40.702%;
  width: 100%;
}

#contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
  padding-top: 3.125vw;
  padding-bottom: 2.1875vw;
  padding-right: 3.125vw;
  padding-left: 3.125vw;
  margin-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt {
    max-width: 94.7%;
    width: 100%;
    margin: 0;
  }
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 4.66667vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    padding-left: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.5;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .rankingCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0.625vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
    padding-bottom: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-bottom: 28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-bottom: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList {
    padding-bottom: 2.04978vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.3125vw;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 0.36603vw;
  }
}

#contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag {
    margin-bottom: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .rankingArea .rankingCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsIndex .keywordsArea {
  padding-top: 12.5vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 16vw;
    padding-bottom: 13.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsIndex .keywordsArea .keywordsTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  line-height: 1;
  letter-spacing: 1.92px;
  text-align: center;
  color: #666;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    letter-spacing: .96px;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .keywordsTit {
    margin-bottom: 8.78477vw;
  }
}

#contentsTop .contentsIndex .keywordsArea .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

#contentsTop .contentsIndex .keywordsArea .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.625vw;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsIndex .keywordsArea .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  background-color: #fff;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .keywordsArea .tagList .tag a {
    padding-left: 1.83016vw;
  }
}

#contentsTop .contentsIndex .aboutContents {
  padding: 0 20px;
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents {
    padding: 0;
  }
}

#contentsTop .contentsIndex .aboutContents .box {
  max-width: 1000px;
  background-color: #fff;
  margin: 0 auto;
  padding-top: 5.3125vw;
  padding-bottom: 5.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 85px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 5.59211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 6.22255vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 85px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 5.59211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 6.22255vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 16vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .box {
    padding-bottom: 8.78477vw;
  }
}

#contentsTop .contentsIndex .aboutContents .aboutContentsTit {
  width: 17.8125vw;
  max-width: 285px;
  margin: 0 auto;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    width: 38vw;
    max-width: 285px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTit {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 2.67;
  text-align: center;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsIndex .aboutContents .aboutContentsTxt {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsArchive .contentsArchiveMainview {
  background-color: #c7c57c;
  padding-top: 2.8125vw;
  padding-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 3.29429vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 6vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview {
    padding-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArchive .contentsArchiveMainview.topics {
  background-color: #6456b7;
}

#contentsTop .contentsArchive .contentsArchiveMainview.iroha_story {
  background-color: #4a6bb7;
}

#contentsTop .contentsArchive .contentsArchiveMainview.interview {
  background-color: #63a534;
}

#contentsTop .contentsArchive .contentsArchiveMainview.column {
  background-color: #c7c57c;
}

#contentsTop .contentsArchive .contentsArchiveMainview.research {
  background-color: #c78246;
}

#contentsTop .contentsArchive .contentsArchiveMainview.review {
  background-color: #bc5680;
}

#contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 5.2rem;
  font-size: 2.70833vw;
  line-height: 1.2;
  letter-spacing: 7.8px;
  text-align: center;
  color: #fff;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
    font-size: 5.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
    font-size: 3.42105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
    font-size: 3.80673vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
    font-size: 6.93333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewTit {
    letter-spacing: 3.9px;
  }
}

#contentsTop .contentsArchive .contentsArchiveMainview.childCategory .mainviewTit {
  font-size: 4rem;
  font-size: 2.08333vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .contentsArchiveMainview.childCategory .mainviewTit {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview.childCategory .mainviewTit {
    font-size: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview.childCategory .mainviewTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview.childCategory .mainviewTit {
    font-size: 5.33333vw;
  }
}

#contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 1.8;
  letter-spacing: 2px;
  text-align: center;
  color: #fff;
  margin-top: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 2;
    letter-spacing: 1.2px;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .contentsArchiveMainview .mainviewLead {
    margin-top: 0.73206vw;
  }
}

#contentsTop .contentsArchive .articlesColumn {
  padding-top: 4.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 78px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 5.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 5.7101vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 16vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn {
    padding-bottom: 14.64129vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox {
  position: relative;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
  text-align: center;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 1.85;
  letter-spacing: 1px;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn span:after {
  content: "";
  width: .7em;
  height: .7em;
  background: url(/images/contents/modal_arrow.svg) center center no-repeat;
  background-size: contain;
  display: inline-block;
  margin-left: 0.9em;
  transition: transform .3s;
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn:hover {
  cursor: pointer;
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn.isOpen span:after {
  transform: rotate(180deg);
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    position: absolute;
    bottom: 4.375vw;
    left: 50%;
    transform: translate(-50%, 0);
    width: 78.66667vw;
    max-width: 590px;
    border-bottom: 1px solid #E5E5E5;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox #toggleBtn {
    bottom: 5.12445vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
  padding: 1.55279% 3.10558%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-bottom: 18.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-top: 5.33333vw;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList:before, #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList::after {
    content: none;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList:before {
  content: "";
  display: block;
  width: 23.129%;
  order: 1;
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList:after {
  content: "";
  display: block;
  width: 23.129%;
  order: 1;
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList li {
  width: 23.129%;
  margin: 1.645% 0;
  border: 4px solid #fff;
  transition: display 1s;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList li {
    width: 36.66667vw;
    max-width: 275px;
  }
}

#contentsTop .contentsArchive .articlesColumn .cateNavListBox .cateNavList li.current {
  border: 4px solid #D6A1B7;
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
  max-width: 79.384%;
  width: 100%;
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  line-height: 1.15;
  letter-spacing: 2.88px;
  color: #4d4d4d;
  border-bottom: solid 1px #4d4d4d;
  padding-bottom: 0.625vw;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    max-width: 100%;
    font-size: 7rem;
    font-size: 3.64583vw;
    letter-spacing: 1.4px;
    text-align: center;
    color: #666;
    border: none;
    padding-bottom: 0vw;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveListTit {
    margin-bottom: 11.71303vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: inherit;
  justify-content: space-between;
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 14.64129vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
  max-width: 48.354%;
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    max-width: 100%;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n)::after {
    left: auto;
    right: 0;
  }
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n) .img {
    margin: 0;
  }
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n) .txt {
    margin: 0 0 0 auto;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
  content: "";
  display: block;
  max-width: 94.506%;
  width: 100%;
  height: calc(100% - 10.3125vw);
  background-color: #fff;
  position: absolute;
  top: 10.3125vw;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 165px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 10.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 12.07906vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 165px);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 10.85526vw);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 12.07906vw);
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .img {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
  max-width: 94.506%;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .img {
    max-width: 94.7%;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt {
  max-width: 94.506%;
  margin: 0;
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
  padding-top: 2.1875vw;
  padding-bottom: 1.25vw;
  padding-right: 0.875vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 3.66032vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt::after {
  display: none;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt {
    max-width: 94.7%;
  }
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 6.66667vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  letter-spacing: 1.26px;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    letter-spacing: normal;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 4.39239vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
  padding-right: 0.75vw;
  padding-left: 3.125vw;
  padding-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.19619vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
  padding-right: 0.9375vw;
  padding-left: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:last-of-type {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:last-of-type {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:last-of-type {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard:last-of-type {
    margin-bottom: 8.78477vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard a {
  display: block;
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard::after {
    content: "";
    display: block;
    max-width: 94.7%;
    width: 100%;
    height: 72%;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: -1;
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .img {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-width: 79.384%;
  width: 100%;
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .img {
    max-width: 94.7%;
    width: 100%;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  max-width: 82.891%;
  width: 100%;
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
  padding-top: 1.875vw;
  padding-bottom: 1.25vw;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt::after {
  content: "";
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: -4.6875vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt::after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt {
    max-width: 94.7%;
    width: 100%;
  }
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 8vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt .underLine {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  padding-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
    padding-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 5.12445vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.4375vw;
  margin-bottom: 0.4375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.51245vw;
  }
}

#contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn {
  padding-top: 4.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 78px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 5.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 5.7101vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 21.33333vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn {
    padding-bottom: 14.64129vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  line-height: 1.5;
  color: #4d4d4d;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 8.78477vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 1.0981vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  margin-right: 0.625vw;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    display: block;
    font-size: 4.8rem;
    font-size: 2.5vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .searchWord {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-right: 3.33333vw;
    margin-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsSearchArchiveTit .num {
    margin-left: 1.83016vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .flex {
  display: flex;
  flex-wrap: wrap;
  align-items: inherit;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .flex {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .flex {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .flex {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .flex {
    margin-bottom: 14.64129vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .category {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .category::before {
  content: "";
  display: block;
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.9375vw;
  max-width: 31px;
  height: 1.625vw;
  max-height: 26px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    width: 7.2vw;
    max-width: 54px;
    height: 6.4vw;
    max-height: 48px;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .category::before {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .category.topics {
  color: #6456b7;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .category.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .category.interview {
  color: #63a534;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .category.column {
  color: #aaa73f;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .category.research {
  color: #c78246;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .category.review {
  color: #bc5680;
}

#contentsTop .contentsSearchArchive .articlesColumn .category.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard {
    margin-bottom: 7.32064vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
  max-width: 48.354%;
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    max-width: 100%;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n)::after {
    left: 0;
    right: auto;
  }
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n) .img {
    margin: 0 0 0 auto;
  }
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard):nth-of-type(2n) .txt {
    margin: 0;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
  content: "";
  display: block;
  max-width: 94.506%;
  width: 100%;
  height: calc(100% - 10.3125vw);
  background-color: #fff;
  position: absolute;
  top: 10.3125vw;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 165px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 10.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 12.07906vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 165px);
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 10.85526vw);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    height: calc(100% - 12.07906vw);
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    left: auto;
    right: 0;
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard)::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .img {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
  max-width: 94.506%;
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .img {
    max-width: 94.7%;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.16);
    margin: 0;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt {
  max-width: 94.506%;
  margin: 0;
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
  padding-top: 2.1875vw;
  padding-bottom: 1.25vw;
  padding-right: 0.875vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 3.66032vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt::after {
  display: none;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt {
    max-width: 94.7%;
    margin: 0 0 0 auto;
  }
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 6.66667vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  letter-spacing: 1.26px;
  margin-bottom: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    letter-spacing: normal;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .contentsArchiveCardTit {
    margin-bottom: 4.39239vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
  padding-right: 0.75vw;
  padding-left: 3.125vw;
  padding-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.19619vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
  padding-right: 0.9375vw;
  padding-left: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard:not(.firstCard) .tagList .tag a {
    padding-left: 1.83016vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard a {
  display: block;
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard::after {
    content: "";
    display: block;
    max-width: 94.7%;
    width: 100%;
    height: 72%;
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: -1;
    top: 46.66667vw;
    height: calc(100% - 46.66667vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard::after {
    top: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard::after {
    top: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard::after {
    top: 25.62225vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .img {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.16);
  overflow: hidden;
  max-width: 79.384%;
  width: 100%;
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .img {
    max-width: 94.7%;
    width: 100%;
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.1);
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt {
  position: relative;
  margin: 0 0 0 auto;
  z-index: 1;
  max-width: 82.891%;
  width: 100%;
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
  padding-top: 1.875vw;
  padding-bottom: 1.25vw;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.92826vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt::after {
  content: "";
  display: block;
  max-width: 100%;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: -4.6875vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt::after {
    top: -5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt::after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt {
    max-width: 94.7%;
    width: 100%;
  }
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 8vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .txt > a {
    margin-top: 0vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTit {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt .underLine {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
    letter-spacing: .56px;
    padding-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardTxt {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
  display: block;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .contentsArchiveCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
  padding-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 2.66667vw;
    padding-left: 5.33333vw;
    padding-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList {
    padding-bottom: 5.12445vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.4375vw;
  margin-bottom: 0.4375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.51245vw;
  }
}

#contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.8rem;
    font-size: 0.9375vw;
    margin-right: 1.33333vw;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag {
    margin-bottom: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSearchArchive .articlesColumn .contentsArchiveCard .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .sideColumn {
    display: none !important;
  }
}

#contentsTop .contentsSingle .contentsWrap {
  padding-top: 4.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 78px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 5.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 5.7101vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap {
    padding-top: 4.02635vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .sideColumn {
  padding-top: 0px !important;
  order: 2;
  max-width: 27.453% !important;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .sideColumn {
    max-width: 25.1004% !important;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .sideColumn {
    order: inherit;
    max-width: 100% !important;
  }
}

#contentsTop .contentsSingle .contentsWrap .minContainer {
  flex-wrap: wrap;
  max-width: 1406px;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .minContainer {
    justify-content: center !important;
    max-width: 1534px;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn {
  max-width: 70.718%;
  width: 100%;
  padding: 3.125vw;
  background: #fff;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    max-width: 64.6586345%;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    width: 89.33333vw;
    max-width: 670px;
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn {
    padding-right: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  line-height: 1.5;
  margin-bottom: 1.875vw;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    margin-bottom: 7.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleListTit {
    margin-bottom: 4.02635vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleHead .contentsSingleData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
  padding: 3.464%;
  position: relative;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-top: 5.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 6.66667vw;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox {
    padding-bottom: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .img {
  width: 16.974%;
  position: absolute;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .img img {
  margin: 0;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .img {
    position: inherit;
    width: 20vw;
    max-width: 150px;
    height: 20vw;
    max-height: 150px;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
  width: 78.03%;
  margin-left: auto;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    width: 42.66667vw;
    max-width: 320px;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.2;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .name {
    margin-bottom: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1.83;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .title .teacherTit {
    font-size: 3.2vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc {
  width: 78.03%;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc {
    width: 100%;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc {
    margin-top: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc p, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc span, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .teacherBox .desc a {
  text-decoration: underline;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
  margin-top: 2.5vw;
  margin-bottom: 5vw;
  display: none;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 2vw;
    margin-bottom: 5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 2vw;
    margin-bottom: 5vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 2vw;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container {
    margin-bottom: 6.58858vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: bold;
  line-height: 1.71;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 4rem;
    font-size: 2.08333vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_title {
    margin-bottom: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
  padding-left: 1.25vw;
  position: relative;
  list-style: none;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li:before {
  content: "・";
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  margin-bottom: 1vw;
  display: inline-block;
  color: #4D4D4D;
  font-size: 1.8rem;
  font-size: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 1.1713vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li:before {
    top: 0.6em;
  }
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 3rem;
    font-size: 1.5625vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent #toc_container .toc_list li a {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent.tocActive #toc_container {
  display: block;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent.tocActive.tocMinimum #toc_container .toc_list li ul {
  display: none;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  margin-bottom: 3.75vw;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: bold;
  line-height: 2;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 1.1713vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.5;
    margin-bottom: 5.33333vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h2 {
    padding-bottom: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
  margin-top: 1.25vw;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
  display: inline;
  float: left;
  margin: 0 auto;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
  display: inline;
  float: right;
  margin: 0 auto;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 2.13333vw;
    margin-bottom: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img {
    margin-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignleft {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent img.alignright {
    margin-left: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  text-decoration: underline;
  color: #4D4D4D;
  word-break: break-all;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent p span {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.5;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h3 {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 1.77;
  font-weight: bold;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 1.86;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent h4 {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table {
  border: 1px solid #808080;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table thead,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tbody {
  border: inherit;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  border: inherit;
  word-break: break-all;
  padding: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr a,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td a,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  text-decoration: underline;
  color: #4D4D4D;
  word-break: break-all;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr span,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td span,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th span {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .4px;
    padding: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table tr,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table td,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent table th {
    padding: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]),
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) {
    padding-right: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
  list-style: inherit;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  word-break: break-all;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li a,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  text-decoration: underline;
  color: #4D4D4D;
  word-break: break-all;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li span,
#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li span {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ul:not([class]) li,
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent div:not(#toc_container) > ol:not([class]) li {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
  display: inline;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  word-break: break-all;
  padding: 2px;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker.red {
  background-color: #FFC0CB;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker.yellow {
  background-color: #fff6b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker.blue {
  background-color: #ADD8E6;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .boldMarker {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
  display: inline;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  word-break: break-all;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker.red {
  background: linear-gradient(transparent 70%, #FFC0CB 0);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker.yellow {
  background: linear-gradient(transparent 70%, #fff6b7 0);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker.blue {
  background: linear-gradient(transparent 70%, #ADD8E6 0);
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: .4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .marker {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .movie_wrap {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .movie_wrap iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .twitter-tweet {
  margin: 0 auto !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
  margin-top: 1.5625vw;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    flex-wrap: wrap;
    padding-top: 5.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox {
    width: 100%;
    max-width: inherit;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox {
    width: 100%;
    margin: 0 auto;
  }
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.55;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 8vw;
    right: 50%;
    transform: translate(50%, 50%);
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
    letter-spacing: .4px;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    text-align: right;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 8vw;
    right: 50%;
    transform: translate(50%, 50%);
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
    letter-spacing: .4px;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    text-align: right;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    padding-right: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
  position: relative;
  display: flex;
  padding-top: 2.0625vw;
  padding-left: 2.5vw;
  padding-right: 3.125vw;
  padding-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 33px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 2.17105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-top: 2.41581vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a {
    padding-bottom: 4.39239vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox {
  max-width: 320px;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox img {
  margin: 0vw;
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox img {
    margin: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox img {
    margin: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .imgBox img {
    margin: 0vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox {
  margin-left: 2.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox {
    margin-left: 42px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox {
    margin-left: 2.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox {
    margin-left: 3.07467vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  line-height: 2;
  color: #4D4D4D;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .txtBox .relatedArticleTit {
    font-size: 2.66667vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
  position: absolute;
  bottom: 1.875vw;
  right: 1.5625vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  text-align: center;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  line-height: 1.5;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    right: 1.83016vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .more:after {
  content: "";
  display: inline-block;
  background-image: url(/images/contents/next_arrow.svg);
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  width: 2em;
  height: 0.88em;
  margin-left: .5em;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
  position: absolute;
  bottom: 1.875vw;
  right: 1.5625vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  text-align: center;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    right: 1.83016vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank {
    font-size: 2.4vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a .blank:after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/blank_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: .8em;
  height: .8em;
  padding-left: .5em;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a:hover .imgBox img {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .relatedArticle a:hover .more:after {
  animation: 1s arrow linear forwards;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  padding-left: 1.875vw;
  padding-right: 1.875vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
    padding-left: 3.33333vw;
    padding-right: 3.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .textBgColor {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  padding-left: 1.875vw;
  padding-right: 1.875vw;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
  content: "";
  position: absolute;
  top: 3.125vw;
  left: 3.125vw;
  background: url(/images/contents/single/quotes_icon.svg) center center no-repeat;
  background-size: contain;
  width: 2.25vw;
  max-width: 36px;
  height: 1.875vw;
  max-height: 30px;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
  content: "";
  position: absolute;
  bottom: 3.125vw;
  right: 3.125vw;
  background: url(/images/contents/single/quotes_icon.svg) center center no-repeat;
  background-size: contain;
  width: 2.25vw;
  max-width: 36px;
  height: 1.875vw;
  max-height: 30px;
  transform: rotate(180deg);
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  width: 37.6875vw;
  max-width: 603px;
  margin-top: 1.25vw;
  margin-bottom: 1.25vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
    padding-left: 3.33333vw;
    padding-right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    width: 4.8vw;
    max-width: 36px;
    height: 4vw;
    max-height: 30px;
    top: 6.66667vw;
    left: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:before {
    left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    width: 4.8vw;
    max-width: 36px;
    height: 4vw;
    max-height: 30px;
    bottom: 6.66667vw;
    right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox:after {
    right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-top: 6.66667vw;
    margin-bottom: 6.66667vw;
    width: 68.53333vw;
    max-width: 514px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .quotesbox p {
    margin-bottom: 3.66032vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 5vw;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
  margin: 6px;
  border: 1px solid #808080;
  border-radius: 15px;
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
  display: block;
  padding: 7px 20px;
  line-height: 1;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  color: #808080;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    justify-content: left;
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-top: 2.66667vw;
    margin-right: 1.6vw;
    margin-bottom: 2.66667vw;
    margin-left: 0vw;
    border-radius: 26px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-right: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    padding-right: 2.66667vw;
    padding-left: 2.66667vw;
    font-size: 1.8rem;
    font-size: 0.9375vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .tagList .tag a {
    font-size: 2.4vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap {
    margin-bottom: 10.2489vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn {
  display: flex;
  justify-content: center;
  position: relative;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
  width: 50%;
  padding: 2.1875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a {
    padding-bottom: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
  display: inline-block;
  margin-bottom: 1.875vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 3rem;
    font-size: 1.5625vw;
    margin-bottom: 0vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a span {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 2;
  font-weight: bold;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a p {
    font-size: 2.13333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
  margin: 0 auto 0 0;
  text-align: end;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-left: 0vw;
    padding-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn {
    padding-right: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span {
  text-align: end;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
  content: "";
  display: inline-block;
  background-image: url(/images/contents/single/back_article_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 4.625vw;
  max-width: 74px;
  height: 0.625vw;
  max-height: 10px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    background-image: url(/images/contents/single/back_article_icon_sp.svg);
    width: 9.6vw;
    max-width: 72px;
    height: 2.26667vw;
    max-height: 17px;
    margin-right: 0.625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn span:before {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.backArticleBtn:hover span:before {
  animation: 1s back linear forwards;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-right: 0vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn {
    padding-left: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
  content: "";
  display: inline-block;
  background-image: url(/images/contents/single/next_article_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 4.625vw;
  max-width: 74px;
  height: 0.625vw;
  max-height: 10px;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    background-image: url(/images/contents/single/next_article_icon_sp.svg);
    width: 9.6vw;
    max-width: 72px;
    height: 2.26667vw;
    max-height: 17px;
    margin-left: 0.625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn span:after {
    margin-left: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .articleBtnWrap .articleBtnColumn a.nextArticleBtn:hover span:after {
  animation: 1s arrow linear forwards;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap {
  text-align: end;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack {
    margin-bottom: 2.92826vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
  content: "";
  display: inline-block;
  background: url(/images/contents/single/category_back_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.1875vw;
  max-width: 19px;
  height: 1.0625vw;
  max-height: 17px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    width: 4.4vw;
    max-width: 33px;
    height: 4vw;
    max-height: 30px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .categoryBack a:before {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a {
  font-family: "fot-tsukuardgothic-std", sans-serif;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
  content: "";
  display: inline-block;
  background: url(/images/contents/single/contents_back_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.1875vw;
  max-width: 19px;
  height: 1.0625vw;
  max-height: 17px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    width: 5.2vw;
    max-width: 39px;
    height: 3.6vw;
    max-height: 27px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap .contentsBack a:before {
    margin-right: 1.46413vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
  color: #4D4D4D;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .pageBackWrap a {
    font-size: 4vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_ava_box .w_b_ava_effect {
  margin-left: auto;
  margin-right: auto;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_box img {
  margin: 0;
  padding: 0;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_box .w_b_space {
  height: 0px !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  margin-top: 0.75vw;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 2.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_name {
    margin-top: 1.31772vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  line-height: 2;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_bal {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_shadow_L, #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_shadow_R {
  box-shadow: none !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_talk_L:before {
  transform: rotate(-135deg) !important;
  left: -6px !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_talk_L:after {
  transform: rotate(-135deg) !important;
  left: -5px !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_talk_R:before {
  transform: rotate(135deg) !important;
  right: -6px !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_talk_R:after {
  transform: rotate(135deg);
  right: -5px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .w_b_talk {
  border-radius: 5px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
  display: flex;
  justify-content: space-between;
  max-width: 274px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4.6875vw;
  margin-top: 5vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-bottom: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-bottom: 5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout {
    margin-top: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout .shereLayoutItem {
  overflow: hidden;
  height: 20px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout .shereLayoutItem:nth-child(1) {
  width: 80px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout .shereLayoutItem:nth-child(2) {
  width: 88px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout .shereLayoutItem:nth-child(3) {
  width: 79px;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn .singleContent .shereLayout .shereLayoutItem img {
  margin: 0;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .contentsSingleListTit {
  color: #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .teacherBox {
  border: 1px solid #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .toc_title {
  color: #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .toc_list {
  background-color: rgba(100, 86, 183, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics h2 {
  border-top: 1px solid #6456b7;
  border-bottom: 1px solid #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics h3 {
  border-bottom: 1px solid #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .articleBtnWrap {
  border-top: 1px solid #6456b7;
  border-bottom: 1px solid #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .articleBtnWrap .articleBtnColumn:before {
  background: #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .relatedArticle {
  border: 1px solid #6456b7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.topics .w_b_talk_R {
  border: 1px solid #6456b7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .w_b_talk_L:before {
  border-top-color: #6456b7 !important;
  border-right-color: #6456b7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.topics .w_b_talk_R:before {
  border-top-color: #6456b7 !important;
  border-left-color: #6456b7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .contentsSingleListTit {
  color: #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .teacherBox {
  border: 1px solid #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .toc_title {
  color: #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .toc_list {
  background-color: rgba(74, 107, 183, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story h2 {
  border-top: 1px solid #4a6bb7;
  border-bottom: 1px solid #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story h3 {
  border-bottom: 1px solid #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .articleBtnWrap {
  border-top: 1px solid #4a6bb7;
  border-bottom: 1px solid #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .articleBtnWrap .articleBtnColumn:before {
  background: #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .relatedArticle {
  border: 1px solid #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .w_b_talk_R {
  border: 1px solid #4a6bb7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .w_b_talk_L:before {
  border-top-color: #4a6bb7 !important;
  border-right-color: #4a6bb7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.iroha_story .w_b_talk_R:before {
  border-top-color: #4a6bb7 !important;
  border-left-color: #4a6bb7 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .contentsSingleListTit {
  color: #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .teacherBox {
  border: 1px solid #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .toc_title {
  color: #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .toc_list {
  background-color: rgba(99, 165, 52, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview h2 {
  border-top: 1px solid #63a534;
  border-bottom: 1px solid #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview h3 {
  border-bottom: 1px solid #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .articleBtnWrap {
  border-top: 1px solid #63a534;
  border-bottom: 1px solid #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .articleBtnWrap .articleBtnColumn:before {
  background: #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .relatedArticle {
  border: 1px solid #63a534;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.interview .w_b_talk_R {
  border: 1px solid #63a534 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .w_b_talk_L:before {
  border-top-color: #63a534 !important;
  border-right-color: #63a534 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.interview .w_b_talk_R:before {
  border-top-color: #63a534 !important;
  border-left-color: #63a534 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .contentsSingleListTit {
  color: #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .teacherBox {
  border: 1px solid #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .toc_title {
  color: #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .toc_list {
  background-color: rgba(170, 167, 63, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column h2 {
  border-top: 1px solid #AAA73F;
  border-bottom: 1px solid #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column h3 {
  border-bottom: 1px solid #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .articleBtnWrap {
  border-top: 1px solid #AAA73F;
  border-bottom: 1px solid #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .articleBtnWrap .articleBtnColumn:before {
  background: #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .relatedArticle {
  border: 1px solid #AAA73F;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.column .w_b_talk_R {
  border: 1px solid #AAA73F !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .w_b_talk_L:before {
  border-top-color: #AAA73F !important;
  border-right-color: #AAA73F !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.column .w_b_talk_R:before {
  border-top-color: #AAA73F !important;
  border-left-color: #AAA73F !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .contentsSingleListTit {
  color: #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .teacherBox {
  border: 1px solid #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .toc_title {
  color: #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .toc_list {
  background-color: rgba(199, 130, 70, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research h2 {
  border-top: 1px solid #c78246;
  border-bottom: 1px solid #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research h3 {
  border-bottom: 1px solid #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .articleBtnWrap {
  border-top: 1px solid #c78246;
  border-bottom: 1px solid #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .articleBtnWrap .articleBtnColumn:before {
  background: #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .relatedArticle {
  border: 1px solid #c78246;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.research .w_b_talk_R {
  border: 1px solid #c78246 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .w_b_talk_L:before {
  border-top-color: #c78246 !important;
  border-right-color: #c78246 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.research .w_b_talk_R:before {
  border-top-color: #c78246 !important;
  border-left-color: #c78246 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .contentsSingleListTit {
  color: #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .teacherBox {
  border: 1px solid #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .toc_title {
  color: #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .toc_list {
  background-color: rgba(188, 86, 128, 0.1);
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review h2 {
  border-top: 1px solid #bc5680;
  border-bottom: 1px solid #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review h3 {
  border-bottom: 1px solid #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .articleBtnWrap {
  border-top: 1px solid #bc5680;
  border-bottom: 1px solid #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .articleBtnWrap .articleBtnColumn:before {
  background: #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .relatedArticle {
  border: 1px solid #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .w_b_talk_L, #contentsTop .contentsSingle .contentsWrap .singleColumn.review .w_b_talk_R {
  border: 1px solid #bc5680 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .w_b_talk_L:before {
  border-top-color: #bc5680 !important;
  border-right-color: #bc5680 !important;
}

#contentsTop .contentsSingle .contentsWrap .singleColumn.review .w_b_talk_R:before {
  border-top-color: #bc5680 !important;
  border-left-color: #bc5680 !important;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea {
  max-width: 1534px;
  margin: 0 auto;
  padding-top: 12.5vw;
  order: 3;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    order: inherit;
    padding-top: 34.66667vw;
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 260px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 17.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea {
    padding-top: 19.03367vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  line-height: 1;
  letter-spacing: 1.92px;
  text-align: center;
  color: #666;
  margin-bottom: 8.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 132px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 8.68421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 9.66325vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    letter-spacing: .96px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedTit {
    margin-bottom: 5.85652vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList {
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  flex-wrap: wrap;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList:after {
  content: "";
  display: block;
  width: 30.12%;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList:after {
    display: none;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
  position: relative;
  width: 30.12%;
  margin-bottom: 10.66667vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 2), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(3):nth-of-type(3n + 1) {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 2), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(3):nth-of-type(3n + 1) {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 2), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(3):nth-of-type(3n + 1) {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 1), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(2):nth-of-type(3n + 2), #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:nth-last-of-type(3):nth-of-type(3n + 1) {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    width: 100%;
    max-width: inherit;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10.66667vw;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:last-of-type {
    margin-bottom: 0vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:after {
  content: "";
  display: block;
  max-width: 94.506%;
  width: 100%;
  height: 62.691%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard:after {
    height: 100%;
    width: 68vw;
    max-width: 510px;
    left: inherit;
    right: -20px;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .img {
  max-width: 94.506%;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .img {
    width: 40vw;
    max-width: 300px;
    margin-right: 4.66667vw;
    box-shadow: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .img {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .img {
    margin-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .img {
    margin-right: 2.56223vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt {
  position: relative;
  max-width: 94.506%;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt {
    width: 44.66667vw;
    max-width: 335px;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
  padding-top: 2.1875vw;
  padding-bottom: 1.25vw;
  padding-right: 0.875vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-right: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-right: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-right: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt > a {
    padding: 0vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
  display: flex;
  align-items: center;
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    width: 6.13333vw;
    max-width: 46px;
    height: 6.4vw;
    max-height: 48px;
    margin-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 1.875vw;
  max-width: 30px;
  height: 1.875vw;
  max-height: 30px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate:before {
    margin-right: 0.36603vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.topics {
  color: #6456B7;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.topics::before {
  background-image: url(/images/common/iroiroiroha_icon_01.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.iroha_story {
  color: #4a6bb7;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.iroha_story::before {
  background-image: url(/images/common/iroiroiroha_icon_02.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.interview {
  color: #63a534;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.interview::before {
  background-image: url(/images/common/iroiroiroha_icon_03.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.column {
  color: #aaa73f;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.column::before {
  background-image: url(/images/common/iroiroiroha_icon_04.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.research {
  color: #c78246;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.research::before {
  background-image: url(/images/common/iroiroiroha_icon_05.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.review {
  color: #bc5680;
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .listCate.review::before {
  background-image: url(/images/common/iroiroiroha_icon_06.svg);
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  letter-spacing: 1.26px;
  margin-bottom: 0.9375vw;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.44;
    letter-spacing: normal;
    margin-bottom: 3.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 27px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 1.77632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardTit {
    margin-bottom: 1.97657vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .contentsArchiveCardData {
    font-size: 3.73333vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
  padding-right: 0.75vw;
  padding-left: 3.125vw;
  padding-bottom: 1.875vw;
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-right: 0.87848vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList {
    padding-bottom: 2.19619vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
  font-family: "fot-tsukuardgothic-std", sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  color: #808080;
  margin-right: 0.4375vw;
  margin-bottom: 0.4375vw;
}

@media screen and (min-width: 1920px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag {
    margin-bottom: 0.51245vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
  display: block;
  color: #808080;
  border-radius: 100px;
  border: solid 1px #adadad;
  padding-top: 0.4375vw;
  padding-bottom: 0.4375vw;
  padding-right: 0.625vw;
  padding-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-top: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-top: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-top: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-bottom: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-bottom: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-bottom: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard .txt .tagList .tag a {
    padding-left: 0.73206vw;
  }
}

#contentsTop .contentsSingle .contentsWrap .recommendedArea .recommendedList .contentsArchiveCard a {
  display: block;
  position: relative;
  z-index: 0;
}

/* ==============================================
products共通パーツ
============================================== */
.productsArea {
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to bottom, #f5f0ee 120px, rgba(245, 240, 238, 0.1) 850px, rgba(245, 240, 238, 0.6) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  .productsArea {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea {
    padding-top: 8.78477vw;
  }
}

.productsArea .twoColumnOnly {
  display: none;
}

@media screen and (max-width: 767px) {
  .productsArea {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea {
    padding-top: 9.15081vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .twoColumnOnly {
    display: block !important;
  }
  .productsArea .BtnTemp {
    width: 78.66667vw;
    max-width: 590px;
    margin-top: 0vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .BtnTemp {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .BtnTemp {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .BtnTemp {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .BtnTemp {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .BtnTemp {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .BtnTemp {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .BtnTemp p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .BtnTemp p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .BtnTemp p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .BtnTemp p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .BtnTemp p {
    font-size: 4.26667vw;
  }
}

.productsArea.iic_brand {
  background-image: linear-gradient(to bottom, #edf8ed 120px, rgba(237, 248, 237, 0.1) 850px, rgba(237, 248, 237, 0.6) 1000px);
}

.productsArea.ih_brand {
  background-image: linear-gradient(to bottom, #ffeae2 120px, rgba(255, 234, 226, 0.1) 850px, rgba(255, 234, 226, 0.6) 1000px);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .productsArea {
    background-image: linear-gradient(to bottom, #f5f0ee 7.5vw, rgba(245, 240, 238, 0.6) 52.63vw, rgba(245, 240, 238, 0.8) 62.5vw);
  }
  .productsArea.iic_brand {
    background-image: linear-gradient(to bottom, #edf8ed 7.5vw, rgba(237, 248, 237, 0.6) 52.63vw, rgba(237, 248, 237, 0.8) 62.5vw);
  }
  .productsArea.ih_brand {
    background-image: linear-gradient(to bottom, #ffeae2 7.5vw, rgba(255, 234, 226, 0.1) 52.63vw, rgba(255, 234, 226, 0.6) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  .productsArea {
    background-image: linear-gradient(to bottom, #f5f0ee 16.66vw, rgba(245, 240, 238, 0.1) 106vw, rgba(245, 240, 238, 0.6) 126.6vw);
  }
  .productsArea.iic_brand {
    background-image: linear-gradient(to bottom, #edf8ed 16.66vw, rgba(237, 248, 237, 0.1) 106vw, rgba(237, 248, 237, 0.6) 126.6vw);
  }
  .productsArea.iic_brand .twoColumnOnly {
    display: none !important;
  }
  .productsArea.ih_brand {
    background-image: linear-gradient(to bottom, #ffeae2 16.66vw, rgba(255, 234, 226, 0.1) 106vw, rgba(255, 234, 226, 0.6) 126.6vw);
  }
  .productsArea.ih_brand .twoColumnOnly {
    display: none !important;
  }
}

.productsArea .productsCateArea {
  padding-top: 6.875vw;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateArea {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea {
    padding-top: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea {
    padding-top: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea {
    padding-top: 10.98097vw;
  }
}

.productsArea .productsCateArea .productsCateAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  margin-bottom: 6.875vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaTit {
    margin-bottom: 10.98097vw;
  }
}

.productsArea .productsCateArea .productsCateAreaList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList {
    display: block;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem {
  width: 42.541%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-right: 1px;
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem {
    width: 100%;
    border-right: none;
    margin-right: 0;
    margin-bottom: 0.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem {
    margin-bottom: 3px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem {
    margin-bottom: 0.19737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem {
    margin-bottom: 0.21962vw;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .img {
    box-shadow: none;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt {
  text-align: center;
  flex-grow: 1;
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
  padding-top: 2.375vw;
  padding-bottom: 2.5vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 38px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 2.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 2.78184vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 2.92826vw;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  padding-bottom: 1.25vw;
  line-height: 1.187;
  letter-spacing: 0.64px;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 1.46413vw;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.32px;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 2.13333vw;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn img {
  margin: 0 auto;
  width: 15.125vw;
  max-width: 242px;
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 6.66667vw;
    padding-bottom: 6.66667vw;
    padding-left: 1.33333vw;
    padding-right: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .mainTxt {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn .subTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem .txt .animeLinkEachsBtn img {
    max-width: 100%;
    width: 100%;
  }
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child {
  margin-right: 0;
}

.productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
  font-size: 2.8rem;
  font-size: 1.45833vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:last-child .txt .animeLinkEachsBtn .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateArea .productsCateAreaList .productsCateAreaListItem:nth-child(even) {
    border-right: none;
  }
}

.productsArea .productsList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList {
    margin-bottom: 7.32064vw;
  }
}

.productsArea .productsList:after {
  content: "";
  display: block;
  width: 30.89%;
}

@media screen and (max-width: 767px) {
  .productsArea .productsList {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList:after {
    content: "";
    display: none;
  }
  .productsArea .productsList.iic_brand .productsListItem {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-top: 4.53333vw;
    padding-bottom: 4vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-top: 34px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-top: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-top: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    font-size: 4.4rem;
    font-size: 2.29167vw;
    line-height: 1.45;
    letter-spacing: .86px;
    margin-top: 6.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    font-size: 2.89474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    font-size: 5.86667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .descTxt {
    display: block !important;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag {
    flex-direction: row;
    padding-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-top: 1.06667vw;
    padding-bottom: 1.06667vw;
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
    margin-right: 3.33333vw;
    margin-bottom: 2.66667vw;
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-top: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.iic_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-top: 4.53333vw;
    padding-bottom: 4vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-top: 34px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-top: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-top: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    font-size: 4.4rem;
    font-size: 2.29167vw;
    line-height: 1.45;
    letter-spacing: .86px;
    margin-top: 6.66667vw;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    font-size: 2.89474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    font-size: 5.86667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .descTxt {
    display: block !important;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag {
    flex-direction: row;
    padding-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-top: 1.06667vw;
    padding-bottom: 1.06667vw;
    padding-right: 3.33333vw;
    padding-left: 3.33333vw;
    margin-right: 3.33333vw;
    margin-bottom: 2.66667vw;
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-top: 8px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-top: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-top: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-right: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList.ih_brand .productsListItem .itemTag .itemTagList:last-child {
    margin-bottom: 1.46413vw;
  }
}

.productsArea .productsList .productsListItem {
  width: 30.89%;
  padding: 30px 28px 25px 28px;
  background: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) {
  .productsArea .productsList .productsListItem {
    padding-top: 1.875vw;
    padding-bottom: 1.5625vw;
    padding-left: 1.75vw;
    padding-right: 1.75vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    padding-top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    padding-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    padding-left: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    padding-left: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    padding-left: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    padding-right: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    padding-right: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1405px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    padding-right: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem {
    width: 42.66667vw;
    max-width: 320px;
    margin-bottom: 5.33333vw;
    padding: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem {
    padding: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem {
    padding: 1.46413vw;
  }
}

.productsArea .productsList .productsListItem .txt {
  flex-grow: 1;
}

.productsArea .productsList .productsListItem .txt .animeLinkEachsBtn {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.productsArea .productsList .productsListItem .txt .logo {
  margin-bottom: 0.625vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .logo {
    margin-bottom: 0vw;
  }
}

.productsArea .productsList .productsListItem .txt .itemTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  line-height: 1.36;
  text-align: center;
  color: #4d4d4d;
  word-break: keep-all;
  margin-top: 2.1875vw;
  margin-bottom: 1.375vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 22px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 1.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 2.66667vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTit {
    margin-bottom: 1.46413vw;
  }
}

.productsArea .productsList .productsListItem .txt .descTxt {
  margin-bottom: 2vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  color: #4d4d4d;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 2.34261vw;
  }
}

@media screen and (min-width: 1920px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    display: none !important;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2.07;
    letter-spacing: 0.28px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .descTxt {
    margin-bottom: 2.92826vw;
  }
}

.productsArea .productsList .productsListItem .txt .itemTag {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 1.875vw;
  border-top: 1px solid #e5e5e5;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 2.19619vw;
  }
}

.productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
  padding-top: 0.125vw;
  padding-bottom: 0.125vw;
  padding-right: 0.9375vw;
  padding-left: 0.9375vw;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-right: 0.9375vw;
  margin-bottom: 0.9375vw;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  color: #808080;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 0.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 0.14641vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 0.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 0.14641vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 1920px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 2.13333vw;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 0.53333vw;
    padding-bottom: 0.53333vw;
    padding-right: 2.13333vw;
    padding-left: 2.13333vw;
    margin-right: 0vw;
    margin-bottom: 1.33333vw;
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 0.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-top: 0.29283vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 0.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-bottom: 0.29283vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-right: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    padding-left: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsList .productsListItem .txt .itemTag .itemTagList:last-child {
    margin-bottom: 0vw;
  }
}

.productsArea .productsDiagnosis {
  position: relative;
  z-index: 1;
  margin-bottom: 18.75vw;
  margin-bottom: 26.66667vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 21.96193vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis {
    margin-bottom: 14.64129vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5.124%;
  background: #fff;
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-top: 13.33333vw;
    padding-bottom: 13.33333vw;
    padding-left: 3.33333vw;
    padding-right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis {
    padding-right: 1.83016vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .txt {
  width: 50%;
  padding-right: 1.2234%;
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt {
    width: 100%;
    padding: 0;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 1.92px;
  text-align: center;
  margin-bottom: 3.4375vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 4.02635vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 5.6rem;
    font-size: 2.91667vw;
    line-height: 1;
    letter-spacing: 2.6px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 5.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 3.68421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    font-size: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.36px;
  margin-bottom: 3.4375vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 4.02635vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: normal;
    margin-bottom: 8vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .txt .descTxt {
    margin-bottom: 4.39239vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .img {
  width: 50%;
  padding-left: 1.2234%;
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .img {
    width: 74.62%;
    padding: 0;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .img {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .img {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .img {
    margin-bottom: 5.85652vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .btn {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  display: block;
  text-align: center;
  border: solid 1px #bfbfbf;
  border-radius: 5px;
  margin: 0 auto;
  width: 25vw;
  max-width: 400px;
  padding-top: 1.5625vw;
  padding-bottom: 1.5625vw;
  padding-left: 1.875vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 2.19619vw;
  }
}

.productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
  content: "";
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.375vw;
  max-width: 22px;
  height: 1.25vw;
  max-height: 20px;
  display: inline-block;
  margin-left: 1.875vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 3rem;
    font-size: 1.5625vw;
    width: 78.66667vw;
    max-width: 590px;
    padding-top: 6vw;
    padding-bottom: 6vw;
    padding-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    width: 1em;
    height: 0.9em;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsDiagnosis .irohaDiagnosis .btn:after {
    margin-left: 2.19619vw;
  }
}

.productsArea .productsCateNameWrap {
  position: relative;
  z-index: 1;
  padding-top: 8.75vw;
  padding-bottom: 4.375vw;
  margin-bottom: 18.75vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    padding-top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    padding-top: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    padding-top: 10.2489vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap {
    padding-top: 21.33333vw;
    padding-bottom: 28.66667vw;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 215px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 14.14474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    padding-bottom: 15.73939vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap {
    margin-bottom: 14.64129vw;
  }
}

.productsArea .productsCateNameWrap .txt {
  position: relative;
  height: 41.625vw;
  max-height: 666px;
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt {
    height: inherit;
    max-height: inherit;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt:before {
    width: 94.66667vw;
    max-width: 710px;
  }
}

.productsArea .productsCateNameWrap .txt:before {
  content: "";
  width: 57.29%;
  background: #fff;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.productsArea .productsCateNameWrap .txt .txtWrap {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 2.8125vw;
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    justify-content: start;
    padding-top: 21.33333vw;
    padding-bottom: 44.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 335px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 22.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .txtWrap {
    padding-bottom: 24.52416vw;
  }
}

.productsArea .productsCateNameWrap .txt .productsCateName {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 5.4rem;
  font-size: 2.8125vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: 2.7px;
  text-align: center;
  margin-bottom: 1.875vw;
  width: 428px;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 5.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 3.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 7.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    width: 39%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 5.33333vw;
    text-align: center;
    font-size: 7rem;
    font-size: 3.64583vw;
    line-height: 1.37;
    letter-spacing: 3.5px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateName {
    font-size: 9.33333vw;
  }
}

.productsArea .productsCateNameWrap .txt .productsCateSubName {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: 0.48px;
  text-align: center;
  margin-bottom: 5vw;
  width: 428px;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    width: 39%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 16vw;
    text-align: center;
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 2.03;
    letter-spacing: 0.6px;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateSubName {
    font-size: 4vw;
  }
}

.productsArea .productsCateNameWrap .txt .productsCateDesc {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.36px;
  text-align: left;
  width: 428px;
}

@media screen and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    width: 39%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 2;
    letter-spacing: 1.2px;
    text-align: left;
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .productsCateNameWrap .txt .productsCateDesc {
    font-size: 4vw;
  }
}

.productsArea .productsCateNameWrap .productsMain {
  position: absolute;
  right: 0;
  bottom: 0;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.16);
  width: 57.29%;
  height: 41.625vw;
  max-height: 666px;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .productsArea .productsCateNameWrap .productsMain {
    width: 94.66667vw;
    max-width: 710px;
    height: 57.33333vw;
    max-height: 430px;
  }
}

.productsArea .productsListWrap {
  position: relative;
  z-index: 1;
}

.productsArea .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  .productsArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  .productsArea .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .productsArea .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .productsArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .productsArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .productsArea .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .productsArea .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .productsArea .secTit {
    padding-bottom: 10.98097vw;
  }
}

/* ==============================================
products
============================================== */
#productsTop .followingBtn {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  display: none;
}

@media screen and (max-width: 767px) {
  #productsTop .followingBtn {
    width: 53.33333vw;
    max-width: 400px;
  }
  #productsTop .followingBtn a {
    padding: 4vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .followingBtn a {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a {
    padding: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a {
    padding: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .followingBtn a p {
    width: 100%;
    padding: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .followingBtn a p {
    padding: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p {
    padding: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p {
    padding: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .followingBtn a p span {
    font-size: 1.9rem;
    font-size: 0.98958vw;
    padding-right: 2.2em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsTop .followingBtn a p span {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p span {
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p span {
    font-size: 1.39092vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .followingBtn a p span {
    font-size: 2.53333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .followingBtn a p span:after {
    right: -1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .followingBtn a p span:after {
    right: -10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p span:after {
    right: -0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p span:after {
    right: -0.73206vw;
  }
}

#productsTop .followingBtn a {
  background: #4d4d4d;
  display: inline-block;
  padding: 30px;
}

#productsTop .followingBtn a p {
  text-align: center;
  display: inline-block;
  padding-top: 1.25vw;
  padding-bottom: 1.25vw;
  padding-left: 6.25vw;
  padding-right: 6.25vw;
  background: #fff;
  border-radius: 3px;
}

@media screen and (min-width: 1600px) {
  #productsTop .followingBtn a p {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p {
    padding-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsTop .followingBtn a p {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsTop .followingBtn a p {
    padding-left: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p {
    padding-left: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p {
    padding-left: 7.32064vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsTop .followingBtn a p {
    padding-right: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p {
    padding-right: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p {
    padding-right: 7.32064vw;
  }
}

#productsTop .followingBtn a p span {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 1.2px;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #productsTop .followingBtn a p span {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .followingBtn a p span {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .followingBtn a p span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .followingBtn a p span {
    font-size: 2.66667vw;
  }
}

#productsTop .followingBtn a p span:after {
  content: "";
  background: url(/images/products/index/products_search_arrow.svg) bottom center no-repeat;
  background-size: contain;
  width: 2.2em;
  height: 1.42em;
  position: absolute;
  bottom: -.2em;
  right: -2.5em;
}

#productsTop .followingBtn a:hover p span:after {
  animation: 1s arrow linear forwards;
}

#productsTop .gradation {
  position: relative;
  z-index: 1;
}

#productsTop .productsSearch {
  margin-top: 18.75vw;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1600px) {
  #productsTop .productsSearch {
    margin-top: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch {
    margin-top: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch {
    margin-top: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch {
    margin-top: 11.71303vw;
  }
}

#productsTop .productsSearch .productsSearchTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 1.92px;
  text-align: center;
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1920px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    line-height: 1.02;
    letter-spacing: 2.16px;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchTit {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchTit {
    margin-bottom: 11.71303vw;
  }
}

#productsTop .productsSearch .productsSearchList {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList {
    justify-content: space-between;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList:after {
    content: "";
    display: block;
    width: 28.4vw;
    max-width: 213px;
  }
}

#productsTop .productsSearch .productsSearchList li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 215px;
  margin-right: 15.2px;
  margin-bottom: 15.2px;
  background: #fff;
  padding-top: 1.875vw;
  padding-bottom: 1.875vw;
  text-align: center;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.32px;
  border-radius: 3px;
  transition: background .3s;
}

@media screen and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1920px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 2.13333vw;
  }
}

#productsTop .productsSearch .productsSearchList li.sort {
  background: #e8d7cf;
}

#productsTop .productsSearch .productsSearchList li:nth-child(6n) {
  margin-right: 0px;
}

@media screen and (min-width: 768px) and (max-width: 1405px) {
  #productsTop .productsSearch .productsSearchList li {
    width: 15.75%;
    margin-right: 1.098%;
    margin-bottom: 1.098%;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList li {
    width: 28.4vw;
    max-width: 213px;
    max-width: inherit;
    min-height: 12.66667vw;
    margin-right: 0vw;
    margin-bottom: 1.33333vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-size: 2.2rem;
    font-size: 1.14583vw;
    letter-spacing: 0.44px;
    line-height: 1.3;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    min-height: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    min-height: 6.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    min-height: 6.95461vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-top: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 1.44737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList li {
    font-size: 2.93333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTop .productsSearch .productsSearchList li:nth-child(5) {
    letter-spacing: -1.32px;
  }
}

#productsTop .productsSearch .productsSearchList li:hover {
  cursor: pointer;
  background: #e8d7cf;
}

#productsParentCategoryTop .childCategoryWrap {
  position: relative;
  z-index: 1;
  padding-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap {
    padding-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 18.30161vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
  flex-direction: row-reverse;
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
  content: "";
  position: absolute;
  top: 0;
  left: inherit;
  right: 0;
  z-index: -1;
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
  right: inherit;
  left: 0;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 10.66667vw;
    padding-right: 5.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    flex-direction: column;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    content: "";
    position: absolute;
    top: 0;
    left: inherit;
    right: -5.33333vw;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    padding-bottom: 75.33333vw;
    padding-left: 5.33333vw;
    padding-right: 0vw;
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 565px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 37.17105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 41.36164vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 5.6rem;
    font-size: 2.91667vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 2.16px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 5.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.68421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.09956vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 7.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
  padding-left: 5.625vw;
  padding-right: 5.625vw;
  padding-top: 5.625vw;
  padding-bottom: 3.5vw;
  width: 40.70%;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 3.68421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 4.09956vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.4rem;
  font-size: 2.29167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.76px;
  text-align: center;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 5.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.83016vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.36px;
  text-align: left;
  margin-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 8.05271vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.96px;
  display: block;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.13333vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.875vw;
  max-width: 30px;
  height: 0.625vw;
  max-height: 10px;
  display: inline-block;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    background: url(/images/products/self_pleasure/arrow_next_sp.svg) bottom center no-repeat;
    background-size: contain;
    width: 7.06667vw;
    max-width: 53px;
    height: 1.46667vw;
    max-height: 11px;
    margin-left: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.1713vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
  width: 59.297%;
  top: 3.125vw;
  right: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    top: 3.66032vw;
  }
}

#productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    position: absolute;
    width: 89.33333vw;
    max-width: 670px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    right: -5.33333vw;
    bottom: 24.66667vw;
    top: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: 185px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: 12.17105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: 13.54319vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent {
  padding-bottom: 21.25vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 340px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 22.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 24.89019vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent {
    padding-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem {
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem {
    flex-direction: column-reverse;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
  margin-bottom: 21.25vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 340px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 22.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 24.89019vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:not(:last-of-type) {
    margin-bottom: 11.71303vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) {
  flex-direction: row-reverse;
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
  text-align: right;
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt:before {
  right: inherit;
  left: 0;
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
  padding: 0 8.333% 0 0;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) {
    flex-direction: column-reverse;
  }
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-left: 0vw;
    padding-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 10.66667vw;
    padding-right: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .txt .animeLinkEachsBtn {
    padding-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem:nth-of-type(even) .img {
    margin-left: auto;
    margin-right: 0;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .img {
  width: 62.5%;
  margin-bottom: -7.29166%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-bottom: 0;
    margin-right: auto;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
  width: 37.5%;
  padding: 7.8125% 0 4.166%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    width: 100%;
    padding-top: 13.33333vw;
    padding-left: 10.66667vw;
    padding-right: 0vw;
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt:before {
    width: 89.33333vw;
    max-width: 670px;
    height: calc(100% + 6.66vw);
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt:before {
  content: "";
  position: absolute;
  right: 0;
  left: auto;
  top: 0;
  height: 100%;
  width: 75vw;
  max-width: 1200px;
  background: #fff;
  z-index: -1;
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
  width: 86.1111%;
  display: inline-block;
  padding: 0 0 0 8.333%;
  text-align: center;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    width: 100%;
    padding-left: 6.66667vw;
    padding-right: 10.66667vw;
    text-align: right;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn {
    padding-right: 5.85652vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.2rem;
  font-size: 2.1875vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 2.1px;
  text-align: center;
  margin-bottom: 0.875vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 4.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 2.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 5.6vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    letter-spacing: 3.24px;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .mainTxt {
    margin-bottom: 0.73206vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.4px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2.14;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descSubTxt {
    font-size: 3.73333vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 7.5vw;
  margin-top: 3.75vw;
  width: 76.78%;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2;
    width: 100%;
    margin-bottom: 10.66667vw;
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .descTxt {
    margin-top: 5.85652vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 1.08px;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow {
    font-size: 3.73333vw;
  }
}

#productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/index/products_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 2.8em;
  height: .6em;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 1.33333vw;
    width: 1.8em;
    height: .4em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .childCategoryWrap.parent .childParentListItem .txt .animeLinkEachsBtn .arrow::after {
    margin-left: 0.73206vw;
  }
}

#productsParentCategoryTop .productsCateArea {
  padding-top: 0 !important;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsCateArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsCateArea .mainTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .productsCateArea .mainTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsCateArea .mainTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsCateArea .mainTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .productsCateArea .mainTxt {
    font-size: 3.73333vw;
  }
}

#productsParentCategoryTop .productsArea {
  position: relative;
  z-index: 1;
  background-color: #f7f5f4;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

#productsParentCategoryTop .productsArea.iic_brand {
  background-color: #f8fdf8;
}

#productsParentCategoryTop .productsArea.iic_brand .fv {
  margin-bottom: 10.9375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 175px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 11.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 12.81113vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .fv {
    margin-bottom: 10.98097vw;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 4.16px;
  text-align: center;
  color: #666;
  margin-bottom: 9.6875vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 155px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 10.19737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 11.347vw;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit:after {
  display: none;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 6rem;
    font-size: 3.125vw;
    font-feature-settings: "palt" 1;
    letter-spacing: 3.12px;
    margin-bottom: 17.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    font-size: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 8.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .mainTit {
    margin-bottom: 9.51684vw;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc {
  align-items: center;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-feature-settings: "palt" 1;
    letter-spacing: normal;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 4.6875vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 75px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 5.49048vw;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img {
    width: 100%;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
  width: 100%;
  height: auto;
  position: relative;
  margin-right: auto;
  margin-left: -4.6875vw;
  margin-top: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    max-width: 900px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    width: 100%;
    margin-top: 0vw;
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .videoBox:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .video-js {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

#productsParentCategoryTop .productsArea.iic_brand .aboutIroha .aboutIrohaDesc .img .video-js .vjs-poster {
  background-color: inherit;
  background-size: cover;
}

#productsParentCategoryTop .productsArea.ih_brand {
  background-color: #ffeae2;
}

#productsParentCategoryTop .productsArea.ih_brand .fv {
  margin-bottom: 10.9375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 175px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 11.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 12.81113vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .fv {
    margin-bottom: 10.98097vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
  padding-bottom: 11.25vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 13.17716vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha {
    padding-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 4.16px;
  text-align: center;
  color: #666;
  margin-bottom: 6.5625vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 105px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 6.90789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 7.68668vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit:after {
  display: none;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 6rem;
    font-size: 3.125vw;
    font-feature-settings: "palt" 1;
    letter-spacing: 3.12px;
    margin-bottom: 17.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    font-size: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 8.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .mainTit {
    margin-bottom: 9.51684vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc {
  display: block;
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt {
  width: 100%;
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner {
  max-width: 100%;
  margin-right: auto;
  padding-left: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner p {
  text-align: center;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-feature-settings: "palt" 1;
    letter-spacing: normal;
    text-align: left;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
  text-align: center;
  padding-left: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
  text-align: center;
}

#productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
  text-align: center;
  padding-right: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .productsArea.ih_brand .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 0vw;
  }
}

#productsParentCategoryTop .aboutIroha {
  padding-top: 6.25vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 18.66667vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha {
    padding-top: 10.2489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha {
    padding-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .aboutIroha .fvLogo {
  margin: 0 auto;
  width: 19.125vw;
  max-width: 306px;
  margin-bottom: 5.9375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 6.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 6.95461vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    width: 40.8vw;
    max-width: 306px;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .fvLogo {
    margin-bottom: 10.2489vw;
  }
}

#productsParentCategoryTop .aboutIroha .fv {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .aboutIroha .fv img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .fv {
    margin-bottom: 10.98097vw;
  }
}

#productsParentCategoryTop .aboutIroha .mainTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 8rem;
  font-size: 4.16667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 5.2px;
  text-align: center;
  color: #666;
  margin-bottom: 10.3125vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 10.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 165px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 10.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 12.07906vw;
  }
}

#productsParentCategoryTop .aboutIroha .mainTit:after {
  content: "きもちよさを、自分らしく。";
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.92px;
  text-align: center;
  display: block;
  margin-top: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 6.6rem;
    font-size: 3.4375vw;
    letter-spacing: 1.715px;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 6.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 4.34211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 4.83163vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    font-size: 8.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .mainTit:after {
    margin-top: 2.92826vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc {
    flex-direction: column;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt {
    padding-right: 2.92826vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
  width: 100%;
  max-width: 860px;
  margin-left: auto;
  margin-right: 0;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.6px;
  text-align: left;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    display: block;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.5;
    letter-spacing: 0.32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner p {
    font-size: 4.26667vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
  margin-bottom: 4.375vw;
  padding-left: 7.9375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 127px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 8.35526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 9.29722vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 10.66667vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 10.66667vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
  text-align: right;
  padding-right: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0vw;
    padding-left: 0vw;
    margin-bottom: 20vw;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .descTxt:nth-of-type(3) {
    margin-bottom: 10.98097vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
  margin-left: auto;
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 {
    margin-right: 2.92826vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a {
  justify-content: flex-end;
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
  content: "";
  left: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .txt .inner .btn01 a:before {
    left: 10.98097vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img {
    width: 100%;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
  width: 100%;
  height: auto;
  position: relative;
  margin-right: auto;
  margin-left: -4.6875vw;
  margin-top: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: -5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 10.2489vw;
  }
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    max-width: 900px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    width: 100%;
    margin-top: 0vw;
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox {
    margin-left: 0vw;
  }
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .videoBox:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .video-js {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

#productsParentCategoryTop .aboutIroha .aboutIrohaDesc .img .video-js .vjs-poster {
  background-color: inherit;
  background-size: cover;
}

#productsParentCategoryTop .relatedInfo {
  padding-top: 3.125vw;
  padding-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 0vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo {
    padding-bottom: 14.64129vw;
  }
}

#productsParentCategoryTop .relatedInfo .minContainer {
  max-width: 1456px;
}

#productsParentCategoryTop .relatedInfo .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 4.9px;
  text-align: center;
  color: #666666;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    line-height: 1.29;
    letter-spacing: 2.45px;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .mainTxt {
    margin-bottom: 10.98097vw;
  }
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide {
  position: relative;
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-track {
  display: flex;
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
  height: auto;
  background-color: #fff;
  box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.16);
  line-height: 0;
  width: 26.375vw;
  max-width: 422px;
  margin-right: 1.5625vw;
  margin-left: 1.5625vw;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    width: 56.66667vw;
    max-width: 425px;
    margin-right: 5.46667vw;
    margin-left: 5.46667vw;
    margin-bottom: 3.33333vw;
    box-shadow: 0.667vw 1.333vw 2.667vw 0 rgba(0, 0, 0, 0.16);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 2.69737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-right: 3.00146vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 2.69737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-left: 3.00146vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slick-slide {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li {
    position: relative;
    z-index: 1;
    margin-bottom: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li {
    margin-bottom: 42px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li {
    margin-bottom: 2.76316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li {
    margin-bottom: 3.07467vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li:last-of-type {
    margin-bottom: 0;
  }
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li::after {
    content: "";
    display: block;
    height: 100%;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 65.33333vw;
    max-width: 490px;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    display: flex;
    align-items: center;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .img {
    flex-shrink: 0;
    width: 40vw;
    max-width: 300px;
    margin-right: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .img {
    margin-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .img {
    margin-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .img {
    margin-right: 2.56223vw;
  }
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide li img {
  width: 100%;
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1.78;
  color: #4d4d4d;
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  padding-right: 1.875vw;
  padding-left: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
    padding-right: 5.33333vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide li .txt {
    padding-left: 0vw;
  }
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
  position: absolute;
  top: 13.28125vw;
  z-index: 2;
  width: 1.1875vw;
  max-width: 19px;
  height: 2.25625vw;
  max-height: 36.1px;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 212.5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 13.98026vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 15.55637vw;
  }
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.prev-arrow {
  left: 0%;
  transform: translate(-50%, -50%);
}

#productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.next-arrow {
  right: 0%;
  transform: translate(50%, -50%);
}

@media screen and (min-width: 1920px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.prev-arrow {
    left: -92px;
  }
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.next-arrow {
    right: -92px;
  }
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.prev-arrow {
    left: -70px;
  }
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.next-arrow {
    right: -70px;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 28.33333vw;
    width: 2.53333vw;
    max-width: 19px;
    height: 4.81333vw;
    max-height: 36.1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 212.5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 13.98026vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow {
    top: 15.55637vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.prev-arrow {
    left: 13.33%;
    transform: translate(0%, -50%);
  }
  #productsParentCategoryTop .relatedInfo #relatedInfoSlide .slide-arrow.next-arrow {
    right: 13.33%;
    transform: translate(0%, -50%);
  }
}

#productsParentCategoryTop .relatedInfo .slide-dots {
  display: flex;
  justify-content: center;
  margin-top: 5.9375vw;
}

@media screen and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 95px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 6.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 6.95461vw;
  }
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 12.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 95px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 6.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .slide-dots {
    margin-top: 6.95461vw;
  }
}

#productsParentCategoryTop .relatedInfo .slide-dots li {
  padding: 0 !important;
  width: 1.875vw;
  max-width: 30px;
  height: 0.25vw;
  max-height: 4px;
  background: #C3BFBF;
  margin: 0 5px;
}

@media screen and (max-width: 767px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    width: 4vw;
    max-width: 30px;
    height: 0.53333vw;
    max-height: 4px;
    margin-left: 0.93333vw;
    margin-right: 0.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-left: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-left: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-left: 0.51245vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-right: 7px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-right: 0.46053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsParentCategoryTop .relatedInfo .slide-dots li {
    margin-right: 0.51245vw;
  }
}

#productsParentCategoryTop .relatedInfo .slide-dots li:hover {
  cursor: pointer;
}

#productsParentCategoryTop .relatedInfo .slide-dots li.slick-active {
  background: #666666;
}

#productsParentCategoryTop .relatedInfo .slide-dots li button {
  text-indent: -9999px;
}

#productsTouchTop .productsCateArea {
  padding-top: 0 !important;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsTouchTop .productsCateArea {
    margin-bottom: 14.64129vw;
  }
}

/* ==============================================
products記事ページ
============================================== */
#productsSingleTop {
  background-image: linear-gradient(to bottom, #dee5e5 120px, rgba(240, 246, 246, 0.6) 850px, rgba(240, 246, 246, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #productsSingleTop {
    background-image: linear-gradient(to bottom, #dee5e5 7.5vw, rgba(240, 246, 246, 0.6) 52.63vw, rgba(240, 246, 246, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop {
    background-image: linear-gradient(to bottom, #dee5e5 16.66vw, rgba(240, 246, 246, 0.6) 106vw, rgba(240, 246, 246, 0.8) 126.6vw);
  }
  #productsSingleTop .twoColumnOnly {
    display: none !important;
  }
}

#productsSingleTop .productsArea {
  position: relative;
  z-index: 1;
  background-image: none;
  padding-top: 11.25vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsArea {
    padding-top: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsArea {
    padding-top: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsArea {
    padding-top: 13.17716vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #productsSingleTop .productsArea {
    padding-top: 11.25vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsArea {
    padding-top: 15.00732vw;
  }
}

#productsSingleTop .productsSingleConcept {
  position: relative;
  z-index: 1;
  margin-top: 7.1875vw;
  margin-bottom: 7.1875vw;
}

#productsSingleTop .productsSingleConcept::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 115px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 7.56579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 8.41874vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 115px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 7.56579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 8.41874vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 17.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 130px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 8.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept {
    margin-top: 9.51684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept {
    margin-bottom: 11.71303vw;
  }
}

#productsSingleTop .productsSingleConcept h1 {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 5rem;
  font-size: 2.60417vw;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleConcept h1 {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h1 {
    font-size: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h1 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h1 {
    font-size: 6.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h1 {
    margin-bottom: 8.78477vw;
  }
}

#productsSingleTop .productsSingleConcept h1 img {
  object-fit: cover;
  margin-left: auto;
  margin-right: auto;
  width: 45.625vw;
  max-width: 730px;
  height: 17.75vw;
  max-height: 284px;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h1 img {
    width: 100%;
    max-width: 100%;
  }
}

#productsSingleTop .productsSingleConcept h2 {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.4rem;
  font-size: 2.29167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: 3.08px;
  text-align: center;
  margin-bottom: 8.75vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 4.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 2.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 5.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 5rem;
    font-size: 2.60417vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 3.5px;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 5rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept h2 {
    font-size: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept h2 {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read {
    padding-right: 2.92826vw;
  }
}

#productsSingleTop .productsSingleConcept .read p {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.6px;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.56;
    letter-spacing: 1.28px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p {
    font-size: 4.26667vw;
  }
}

#productsSingleTop .productsSingleConcept .read p img {
  margin-top: 3.125vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 3.66032vw;
  }
}

#productsSingleTop .productsSingleConcept .read p img.alignleft {
  display: inline;
  float: left;
  margin: 0 auto;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 1.46413vw;
  }
}

#productsSingleTop .productsSingleConcept .read p img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#productsSingleTop .productsSingleConcept .read p img.alignright {
  display: inline;
  float: right;
  margin: 0 auto;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img.alignleft {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleConcept .read p img.alignright {
    margin-left: 2.19619vw;
  }
}

#productsSingleTop .childCategoryWrap {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap {
    padding-bottom: 14.64129vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 18.30161vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
  flex-direction: row-reverse;
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
  content: "";
  position: absolute;
  top: 0;
  left: inherit;
  right: 0;
  z-index: -1;
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
  right: inherit;
  left: 0;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: inherit !important;
    padding-right: 5.33333vw;
    margin-bottom: 21.33333vw;
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    flex-direction: column;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    content: "";
    position: absolute;
    top: 0;
    left: inherit;
    right: -5.33333vw;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .txt:before {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    margin-left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    margin-left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    min-height: auto;
    padding-left: 5.33333vw;
    padding-right: 0vw;
    padding-top: 21.33333vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    height: calc(100% - 27vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
    font-size: 2.5vw;
    text-align: center;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 2.16px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
  padding-left: 5.625vw;
  padding-top: 5.625vw;
  padding-right: 4.125vw;
  padding-bottom: 5vw;
  min-height: 30.625vw;
  width: 40.70%;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 66px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 4.34211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 4.83163vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    min-height: 490px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    min-height: 32.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt {
    min-height: 35.87116vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  text-align: left;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.76px;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.83016vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  letter-spacing: normal;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.36px;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.96px;
  display: block;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.875vw;
  max-width: 30px;
  height: 0.625vw;
  max-height: 10px;
  display: inline-block;
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    background: url(/images/products/self_pleasure/arrow_next_sp.svg) bottom center no-repeat;
    background-size: contain;
    width: 7.06667vw;
    max-width: 53px;
    height: 1.46667vw;
    max-height: 11px;
    margin-left: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.1713vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
  position: absolute;
  top: inherit;
  width: 59.297%;
  bottom: -3.125vw;
  right: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    bottom: -3.66032vw;
  }
}

#productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    position: relative;
    width: 89.33333vw;
    max-width: 670px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    right: -5.33333vw;
    bottom: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .childCategoryList .childCategoryListItem .img {
    right: -2.92826vw;
  }
}

#productsSingleTop .childCategoryWrap .productsMainSlide {
  width: 59.297%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 21.875vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 23.02632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 25.62225vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 0vw;
    margin-bottom: 10.66667vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .productsMainSlideInner img {
    padding-right: 2.92826vw;
  }
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
  position: absolute;
  z-index: 1;
  top: 14.75vw;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 236px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 15.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 17.27672vw;
  }
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow:hover {
  cursor: pointer;
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.prev-arrow {
  left: -7.73%;
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.prev-arrow.slick-disabled {
  display: none !important;
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.next-arrow {
  right: -7.73%;
}

#productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.next-arrow.slick-disabled {
  display: none !important;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 22vw;
    width: 2.88vw;
    max-width: 21.6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 10.85526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow {
    top: 12.07906vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.prev-arrow {
    left: 0;
  }
  #productsSingleTop .childCategoryWrap .productsMainSlide .slide-arrow.next-arrow {
    right: 0;
  }
}

#productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  background: #fff;
  margin-top: 5.952%;
  padding: 4.166% 5.35%;
}

#productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    margin-top: 5.33333vw;
    padding: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox {
    padding: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsMainSlide .txtBox p {
    font-size: 3.73333vw;
  }
}

#productsSingleTop .childCategoryWrap .productsSubSlide {
  padding: 1.46%;
  background: #fff;
}

#productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide img {
  border: 4px solid #FFF;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-left: 1.2vw;
    margin-right: 1.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-left: 9px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-left: 0.59211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-left: 0.65886vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-right: 9px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-right: 0.59211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide {
    margin-right: 0.65886vw;
  }
}

#productsSingleTop .childCategoryWrap .productsSubSlide .slick-slide.slick-current img {
  border: 4px solid #98CC98;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide:before {
    content: "";
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    height: 100%;
    background: #fff;
    z-index: -1;
  }
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 2.85333vw;
    max-width: 21.4px;
  }
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.prev-arrow {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.prev-arrow {
    left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.prev-arrow {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.prev-arrow {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.prev-arrow.slick-disabled {
    display: none !important;
  }
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.next-arrow {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.next-arrow {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.next-arrow {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.next-arrow {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .childCategoryWrap .productsSubSlide .slide-arrow.next-arrow.slick-disabled {
    display: none !important;
  }
}

#productsSingleTop .productsSingleLinks {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks {
    padding-bottom: 10.98097vw;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap {
    justify-content: space-between;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
  width: 22.54%;
  background: #fff;
  text-align: center;
  margin: 0 1.6105%;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li:nth-child(1) {
  margin-left: 0;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li:nth-child(4) {
  margin-right: 0;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li:nth-child(n + 5) {
  margin-top: 2.92%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    width: 41.33333vw;
    max-width: 310px;
    margin-bottom: 6.66667vw;
    margin-top: 0vw;
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li {
    margin-right: 0vw;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 13.02% 9.74% 9.74%;
  position: relative;
  transition: opacity .3s;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a:hover {
  opacity: .7;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a:after {
  content: "";
  background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 12.11%;
  height: 7px;
  position: absolute;
  bottom: 9.74%;
  right: 9.74%;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.89;
  letter-spacing: normal;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 1.83016vw;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:before {
  content: "";
  width: 28.34%;
  height: 4.375vw;
  max-height: 70px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:before {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:before {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:before {
    margin-bottom: 1.83016vw;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.28px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-top: 6vw;
    padding-left: 2.66667vw;
    padding-bottom: 2.66667vw;
    padding-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-top: 45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-top: 2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a:after {
    width: 7.06667vw;
    max-width: 53px;
    height: 1.33333vw;
    max-height: 10px;
  }
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 2.4rem;
    font-size: 1.25vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:before {
    width: 11.06667vw;
    max-width: 83px;
    height: 11.06667vw;
    max-height: 83px;
  }
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 2rem;
    font-size: 1.04167vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.4px;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    margin-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li a p:after {
    margin-top: 0.73206vw;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.cart a:after {
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 1vw;
  max-width: 16px;
  height: 0.875vw;
  max-height: 14px;
  min-width: 12px;
  min-height: 10px;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.cart a:after {
    width: 3.06667vw;
    max-width: 23px;
    height: 2.8vw;
    max-height: 21px;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.cart p:before {
  background: url(/images/common/cart_icon.svg) center center no-repeat;
  background-size: contain;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.cart p:after {
  content: "Official Online Store";
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.faq p:before {
  background: url(/images/common/faq_icon.svg) center center no-repeat;
  background-size: contain;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.faq p:after {
  content: "FAQ";
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.review p:before {
  background: url(/images/common/review_icon.svg) center center no-repeat;
  background-size: contain;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.review p:after {
  content: "Review";
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.download a:after {
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 1vw;
  max-width: 16px;
  height: 0.875vw;
  max-height: 14px;
  min-width: 12px;
  min-height: 10px;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.download a:after {
    width: 3.06667vw;
    max-width: 23px;
    height: 2.8vw;
    max-height: 21px;
  }
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.download p:before {
  background: url(/images/common/download_icon.svg) center center no-repeat;
  background-size: contain;
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li.download p:after {
  content: "Download Manual";
}

#productsSingleTop .productsSingleLinks .productsSingleLinksWrap li:not(.cart):not(.download) a:hover:after {
  animation: 1s arrow linear forwards;
}

#productsSingleTop .productsSingleMovie {
  position: relative;
  z-index: 1;
  text-align: center;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 14.64129vw;
  }
}

#productsSingleTop .productsSingleMovie .secTit {
  max-width: 1406px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

#productsSingleTop .productsSingleMovie .videoBox {
  width: 56.25vw;
  max-width: 900px;
  height: auto;
  position: relative;
  margin: 0 auto;
}

#productsSingleTop .productsSingleMovie .videoBox:before {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  padding-top: 56.25%;
}

#productsSingleTop .productsSingleMovie .videoBox .video-js {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#productsSingleTop .productsSingleMovie .videoBox .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

#productsSingleTop .productsSingleMovie .videoBox .video-js .vjs-poster {
  background-color: inherit;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleMovie {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleMovie .secTit {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleMovie .videoBox {
    width: 100%;
    max-width: 100%;
  }
}

#productsSingleTop .productsSingleHowto {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto {
    padding-bottom: 14.64129vw;
  }
}

#productsSingleTop .productsSingleHowto .towColumns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 7.32064vw;
  }
}

#productsSingleTop .productsSingleHowto .towColumns img {
  width: 47.9%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .towColumns {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .towColumns img {
    width: 100%;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .towColumns img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .towColumns img {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .towColumns img {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleHowto .threeColumns {
  background: #fff;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 3.6603% 4.026%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-top: 5.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 10vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-bottom: 4.93421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns {
    padding-bottom: 5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns:after {
    width: 100%;
  }
}

#productsSingleTop .productsSingleHowto .threeColumns:after {
  content: "";
  display: block;
  width: 30.4136%;
}

#productsSingleTop .productsSingleHowto .threeColumns .contentsWrap {
  width: 30.4136%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap {
    width: 100%;
    margin-bottom: 5.33333vw;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap img {
    width: 27.46667vw;
    max-width: 206px;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap img {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleHowto .threeColumns .contentsWrap img {
  margin-bottom: 10.47%;
}

#productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    text-align: left;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleHowto .threeColumns .contentsWrap p {
    padding-left: 2.92826vw;
  }
}

#productsSingleTop .productsSingleLineup {
  position: relative;
  z-index: 1;
  padding-bottom: 9.1875vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 147px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 9.67105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 10.76135vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup {
    padding-bottom: 14.64129vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.center {
  justify-content: center;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList {
  margin-right: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList {
    margin-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList {
    margin-right: 3.66032vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList:last-of-type {
  margin-right: 0;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.center:after {
  display: none;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn {
  justify-content: center;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
  margin-right: 1.5625vw;
  margin-left: 1.5625vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-right: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-left: 1.83016vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn:after {
  display: none;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.center {
    justify-content: space-between;
  }
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.center .productsLineupList {
    margin-right: 0;
  }
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn {
    justify-content: space-between;
  }
  #productsSingleTop .productsSingleLineup .productsLineupListWrap.twoColumn .productsLineupList {
    margin-right: 0;
    margin-left: 0;
  }
  #productsSingleTop .productsSingleLineup .productsLineupListWrap:after {
    width: 100%;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap:after {
  content: "";
  display: block;
  width: 30.89%;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
  width: 30.89%;
  background: #fff;
  padding: 2.928% 3.367%;
  margin-bottom: 6.25vw;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    width: 100%;
    padding-top: 5.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 8vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList:last-child {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide {
  position: relative;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide img {
  width: 100%;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow {
  width: 3.636%;
  position: absolute;
  top: 50%;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.prev-arrow {
  left: -8%;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.next-arrow {
  right: -8%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow {
    width: 2.85333vw;
    max-width: 21.4px;
  }
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.prev-arrow {
    left: -2.66667vw;
    transform: translate(-50%, 0);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.prev-arrow {
    left: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.prev-arrow {
    left: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.prev-arrow {
    left: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.next-arrow {
    right: -2.66667vw;
    transform: translate(50%, 0);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.next-arrow {
    right: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.next-arrow {
    right: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .imgSlide .slide-arrow.next-arrow {
    right: -1.46413vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
  padding-top: 12.12%;
  position: relative;
  padding-bottom: 6.875vw;
  flex-grow: 1;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-top: 10.66667vw;
    padding-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt {
    padding-bottom: 13.17716vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 1.12px;
  text-align: center;
  margin-bottom: 6.06%;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 4.2rem;
    font-size: 2.1875vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 1.68px;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 2.76316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsName {
    margin-bottom: 2.19619vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.36px;
  text-align: center;
  display: block;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: 0.56px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsSubName {
    font-size: 3.73333vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  margin: 12.12% auto;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-top: 10.66667vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsDesc {
    margin-bottom: 4.39239vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0.9375vw;
  margin-top: 12.12%;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    margin-top: 8vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .productsPrice {
    margin-bottom: 4.39239vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
  border-bottom: 1px solid #E5E5E5;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 1.08px;
  text-align: right;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.14;
    letter-spacing: 1.68px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn {
    font-size: 3.73333vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn:hover {
  cursor: pointer;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn.open:after {
  content: "";
  display: inline-block;
  width: .7em;
  height: .7em;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  transform: translate(0, -25%) rotate(45deg);
  margin-left: .8em;
  transition: all .3s ease-out;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .toggleBtn:after {
  content: "";
  display: inline-block;
  width: .7em;
  height: .7em;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  transform: translate(0, 25%) rotate(225deg);
  margin-left: .8em;
  transition: all .3s ease-out;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
  display: none;
  border-bottom: 1px solid #e5e5e5;
  padding: 7.27% 0;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-top: 5.33333vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents {
    padding-bottom: 3.66032vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  width: 50%;
  padding-right: 3.03%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  word-break: break-all;
  margin-bottom: 6.06%;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dt {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  width: 50%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  word-break: break-all;
  margin-bottom: 6.06%;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .slideContents dd {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4d4d4d;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  color: #fff;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.36px;
  border-radius: 3px;
  padding: 7.78% 10px;
  margin-top: 12.12%;
  width: 100%;
  position: absolute;
  bottom: 0;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 2.4vw;
  }
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn:before {
  content: "";
  display: block;
  background: url(/images/common/online_btn_cart.svg) center center no-repeat;
  background-size: contain;
  width: 1.4em;
  height: 1.1em;
  margin-right: 0.6em;
}

#productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn:after {
  content: "";
  display: block;
  background: url(/images/common/online_btn_blank.svg) center center no-repeat;
  background-size: contain;
  width: 0.9em;
  height: 0.8em;
  margin-left: 0.6em;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleLineup .productsLineupListWrap .productsLineupList .txt .onlineLinkBtn {
    margin-top: 4.39239vw;
  }
}

#productsSingleTop .productsSingleSpec {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .openTxt {
    text-align: center;
  }
  #productsSingleTop .productsSingleSpec .openTxt #openBtn {
    font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.56px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .openTxt #openBtn {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .openTxt #openBtn {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .openTxt #openBtn {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .openTxt #openBtn {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .openTxt #openBtn:hover {
    cursor: pointer;
  }
  #productsSingleTop .productsSingleSpec .openTxt #openBtn:after {
    content: "";
    width: .8em;
    height: .8em;
    border-bottom: 1px solid #4D4D4D;
    border-right: 1px solid #4D4D4D;
    display: inline-block;
    transform: rotate(45deg);
    margin-left: 0.5em;
    transition: all .3s;
  }
  #productsSingleTop .productsSingleSpec .openTxt #openBtn.isOpen:after {
    content: "";
    width: .8em;
    height: .8em;
    border-bottom: 1px solid #4D4D4D;
    border-right: 1px solid #4D4D4D;
    display: inline-block;
    transform: translate(0, 25%) rotate(-135deg);
    margin-left: 0.5em;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
  background: #fff;
  padding: 3.66% 14.27%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-top: 3.33333vw;
    padding-bottom: 3.33333vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-top: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-top: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-bottom: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc {
    padding-left: 0vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    margin-top: 4vw;
    padding-top: 7.33333vw;
    padding-bottom: 7.33333vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    margin-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-top: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-top: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-top: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-bottom: 55px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-bottom: 3.61842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-bottom: 4.02635vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList:before {
    content: "";
    width: 100%;
    height: 1px;
    background: #E5E5E5;
    position: absolute;
    top: 0;
    left: 0;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
  width: 13.33%;
  text-align: end;
  word-break: break-all;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    width: 27%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.56px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dt:last-of-type {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
  width: 85.2%;
  text-align: left;
  word-break: break-all;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    width: 70%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.56px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd p:not(:last-of-type) {
  margin-bottom: 1em;
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
  width: 100%;
  text-align: left;
  margin-bottom: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    width: 100%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 0.56px;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dt {
    margin-bottom: 1.46413vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
  width: 100%;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    width: 100%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: 1px;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate dd {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate:last-of-type dd {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate:last-of-type dd {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate:last-of-type dd {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd .subDate:last-of-type dd {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleSpec .productsSingleSpecDesc .mainDateList dd:last-of-type {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleInformation {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation {
    padding-bottom: 11.71303vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    position: relative;
    display: block;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:last-child {
    margin-bottom: 0vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img {
  width: 54.61%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img {
    width: 100%;
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .img {
    left: -2.92826vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
  width: 45.38%;
  margin-top: 2.8125vw;
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    width: 100%;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt {
    margin-top: 0vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
  display: block;
  padding: 12.9% 12.9% 10.54% 10.17%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-top: 8vw;
    padding-right: 0vw;
    padding-bottom: 10.66667vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a {
    padding-left: 2.92826vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 51.875vw;
  max-width: 830px;
  height: 100%;
  background: #fff;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    width: 94.66667vw;
    max-width: 710px;
    top: -10.66667vw;
    right: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    height: calc(100% + 10.66vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    top: -80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    top: -5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    top: -5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a:before {
    right: -2.92826vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .mainTxt {
    margin-bottom: 2.92826vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.32px;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: cover;
  display: inline-block;
  width: 2.5em;
  height: 0.65em;
  margin-left: .5em;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .arrow:after {
    width: 3em;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.32px;
  text-align: right;
  display: flex;
  justify-content: end;
  align-items: center;
}

@media screen and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 2.13333vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  background-size: contain;
  display: inline-block;
  width: .8em;
  height: .8em;
  margin-left: .5em;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem .txt a .blank {
    font-size: 3.73333vw;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .img {
    width: 100%;
    width: 94.66667vw;
    max-width: 710px;
    left: 0;
  }
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
  display: block;
  padding: 12.9% 10.17% 10.54% 12.9%;
  position: relative;
}

#productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
  content: "";
  position: absolute;
  top: 0;
  right: inherit;
  left: 0;
  width: 51.875vw;
  max-width: 830px;
  height: 100%;
  background: #fff;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-top: 10.66667vw;
    padding-left: 0vw;
    padding-bottom: 10.66667vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    width: 94.66667vw;
    max-width: 710px;
    top: -10.66667vw;
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    height: calc(100% + 10.66vw);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    top: -80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    top: -5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    top: -5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #productsSingleTop .productsSingleInformation .relatedInformationList .relatedInformationListItem:nth-child(even) .txt a:before {
    left: -2.92826vw;
  }
}

/* ==============================================
shop共通パーツ
============================================== */
.shopArea {
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
  padding-top: 11.5625vw;
}

@media screen and (min-width: 1600px) {
  .shopArea {
    padding-top: 185px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea {
    padding-top: 12.17105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea {
    padding-top: 13.54319vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea {
    padding-top: 15.00732vw;
  }
}

.shopArea .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  .shopArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .secTit {
    padding-bottom: 10.98097vw;
  }
}

.shopArea .storeSearchWrap {
  position: relative;
  z-index: 1;
}

.shopArea .storeSearchWrap .secTit {
  padding-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .secTit {
    padding-bottom: 10.98097vw;
  }
}

.shopArea .storeSearchWrap .storeSearchDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.29;
    margin-bottom: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .storeSearchDesc {
    margin-bottom: 6.58858vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox {
  background: #fff;
  padding-top: 9.375vw;
  padding-bottom: 9.375vw;
  padding-left: 5.625vw;
  padding-right: 5.625vw;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 10.98097vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox {
    padding-right: 2.92826vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch {
    margin-bottom: 2.92826vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect {
  flex-grow: 1;
  position: relative;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect {
    width: 100%;
    max-width: 100%;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
  width: 100%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 1.25em 2.25em 1.25em 1.25em;
  outline: none;
  border-radius: 3px;
  border: 1px solid #adadad;
  appearance: none;
  background: none;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 1.6em 2em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect select {
    font-size: 3.73333vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
  content: "";
  width: 1vw;
  max-width: 16px;
  height: 1vw;
  max-height: 16px;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  position: absolute;
  right: 1.25vw;
  top: 50%;
  transform: translate(0, -75%) rotate(45deg);
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    width: 3.2vw;
    max-width: 24px;
    height: 3.2vw;
    max-height: 24px;
    right: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox .buySearchBoxSelect:after {
    right: 3.66032vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox.buySearchBoxArea {
  width: 26.3069%;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox.buySearchBoxArea {
    width: 100%;
    max-width: 100%;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox.buySearchBoxStore {
  width: 28.1618%;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .selectBox.buySearchBoxStore {
    width: 100%;
    max-width: 100%;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter {
  width: 34.064%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter {
    width: 100%;
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
  flex-grow: 1;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 1.25em;
  border: 1px solid #adadad;
  border-radius: 3px;
  outline: none;
  width: 79%;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    width: 77%;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    width: 100%;
    max-width: 100%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 1.6em 2em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch .buySearchBoxFilter .buySearchBoxFilterInput {
    font-size: 3.73333vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearch label {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 1em;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 6.66667vw;
    margin-top: 6.66667vw;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label[for="area"] {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label[for="area"] {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label[for="area"] {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearch label[for="area"] {
    margin-top: 0vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn {
  width: 400px;
  border-radius: 3px;
  background: #4d4d4d;
}

.shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn:disabled {
  background: #adadad;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn {
    width: 100%;
    max-width: 100%;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #fff;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  line-height: 1;
  text-align: center;
  padding: 1.5em 1em;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span {
    font-size: 4.26667vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .buySearchBoxBtn span:before {
  content: "";
  background: url(/images/shop/index/shop_search_icon.svg) center center no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  margin-right: .8em;
}

.shopArea .storeSearchWrap .buySearchBox .warningBox {
  margin-top: 3.125vw;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 4.39239vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .warningBox:last-of-type {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 6.66667vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox {
    margin-bottom: 7.32064vw;
  }
}

.shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.66;
  letter-spacing: 0.32px;
  margin-bottom: .5em;
}

@media screen and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    text-align: left;
    font-size: 2.4rem;
    font-size: 1.25vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.48px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .shopArea .storeSearchWrap .buySearchBox .warningBox .warningNote {
    font-size: 3.2vw;
  }
}

/* ==============================================
shopTopパーツ
============================================== */
#shopTop {
  background-image: linear-gradient(to bottom, #f1fbfc 120px, rgba(245, 253, 254, 0.6) 850px, rgba(245, 253, 254, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #shopTop {
    background-image: linear-gradient(to bottom, #f1fbfc 7.5vw, rgba(245, 253, 254, 0.6) 52.63vw, rgba(245, 253, 254, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #shopTop {
    background-image: linear-gradient(to bottom, #f1fbfc 16.66vw, rgba(245, 253, 254, 0.6) 106vw, rgba(245, 253, 254, 0.8) 126.6vw);
  }
}

#shopTop .shopMainWrap {
  position: relative;
  z-index: 1;
  padding-bottom: 15vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap {
    padding-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap {
    padding-bottom: 15.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap {
    padding-bottom: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap {
    padding-bottom: 0vw;
  }
}

#shopTop .shopMainWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 14.64129vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    padding-top: 10.66667vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    height: 110%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.92px;
    text-align: center;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
  padding-left: 3.125vw;
  padding-right: 2.5vw;
  padding-top: 4.375vw;
  padding-bottom: 4.375vw;
  width: 34.114%;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.12445vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.64px;
  text-align: left;
  margin-bottom: 1.625vw;
}

@media screen and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 26px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.71053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.90337vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  text-align: left;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.96px;
  display: block;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.13333vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  background-size: contain;
  width: .8em;
  height: .7em;
  display: inline-block;
  margin-left: .5em;
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 4vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
  position: absolute;
  width: 65.885%;
  top: 3.125vw;
  right: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    top: 3.66032vw;
  }
}

#shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    position: relative;
    width: 89.33333vw;
    max-width: 670px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    top: 0;
    bottom: 0;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .shopMainWrap .childCategoryList .childCategoryListItem .img {
    right: -2.92826vw;
  }
}

#shopTop .otherStoreWrap {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap {
    padding-bottom: 14.64129vw;
  }
}

#shopTop .otherStoreWrap .secTit {
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .secTit {
    letter-spacing: 1.45px;
    padding-bottom: 20vw;
    font-size: 4.8rem;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .secTit {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .otherStoreWrap .secTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .secTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .secTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .otherStoreWrap .secTit {
    font-size: 6.4vw;
  }
}

#shopTop .otherStoreWrap .otherStore {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2.4%;
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore {
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore {
    margin-bottom: 0.73206vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 47.585%;
  margin-bottom: 4.86%;
  background: #fff;
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList {
    width: 100%;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList {
    margin-bottom: 3.66032vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList a {
  height: 100%;
  transition: opacity .3s;
}

#shopTop .otherStoreWrap .otherStore .otherStoreList a:hover {
  opacity: .7;
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .storeLogo {
  overflow: hidden;
  height: 11.5625vw;
  max-height: 185px;
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .storeLogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeLogo {
    height: 25.33333vw;
    max-height: 190px;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 10px solid #f2fcfd;
  border-top-width: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink {
    border-top-width: 0.73206vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
  position: relative;
  flex-grow: 1;
  border-right: 10px solid #f2fcfd;
  border-right-width: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 0.73206vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5625vw;
  margin: auto;
  width: 0.625vw;
  max-width: 10px;
  height: 0.625vw;
  max-height: 10px;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  display: inline-block;
  transform: translate(0, -50%) rotate(-45deg);
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 1.83016vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:last-of-type {
  border-right: none;
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
  margin-right: 0.375vw;
  margin-left: 0.375vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0.43924vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link {
    border-right-width: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 2.66667vw;
    width: 2.66667vw;
    max-width: 20px;
    height: 2.66667vw;
    max-height: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link::after {
    right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0vw;
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link:first-of-type:last-of-type a img {
    margin-left: 0vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
  display: flex;
  justify-content: center;
  padding-top: 0.9375vw;
  padding-bottom: 0.9375vw;
  padding-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 0.73206vw;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a img {
  width: 7.625vw;
  max-width: 122px;
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    padding-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .irohaLink .link a img {
    width: 20vw;
    max-width: 150px;
  }
}

#shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: center;
  padding: 6.66% 0;
  border-top: 5px solid #f2fcfd;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 1920px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 2.4rem;
    font-size: 1.25vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.48px;
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    border-top: 0.66vw solid #f2fcfd;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap .otherStore .otherStoreList .storeName {
    padding-bottom: 0.73206vw;
  }
}

#shopTop .otherStoreWrap .otherStore #hideBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#shopTop .otherStoreWrap .otherStore #hideBox:before {
  display: block;
  content: "";
  order: 1;
  width: 21.96%;
}

#shopTop .otherStoreWrap .otherStore #hideBox:after {
  display: block;
  content: "";
  width: 21.96%;
}

#shopTop .otherStoreWrap #toggleBox {
  text-align: center;
  border-top: 1px solid #e5e5e5;
  padding-top: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .otherStoreWrap #toggleBox {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap #toggleBox {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap #toggleBox {
    padding-top: 2.19619vw;
  }
}

#shopTop .otherStoreWrap #toggleBox #shopToggleBtn {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 2.8px;
}

@media screen and (min-width: 1920px) {
  #shopTop .otherStoreWrap #toggleBox #shopToggleBtn {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .otherStoreWrap #toggleBox #shopToggleBtn {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .otherStoreWrap #toggleBox #shopToggleBtn {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .otherStoreWrap #toggleBox #shopToggleBtn {
    font-size: 3.73333vw;
  }
}

#shopTop .otherStoreWrap #toggleBox #shopToggleBtn:hover {
  cursor: pointer;
}

#shopTop .otherStoreWrap #toggleBox #shopToggleBtn:after {
  content: "";
  width: .7em;
  height: .7em;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  display: inline-block;
  transform: translate(0, -25%) rotate(45deg);
  margin-left: 1em;
  transition: all .3s;
}

#shopTop .otherStoreWrap #toggleBox #shopToggleBtn.isOpen:after {
  transform: translate(0, 25%) rotate(-135deg);
}

#shopTop .inStoreWrap {
  position: relative;
  z-index: 1;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap {
    padding-bottom: 14.64129vw;
  }
}

#shopTop .inStoreWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  flex-direction: row-reverse;
  align-items: flex-start;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 14.64129vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 10.66667vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    margin-top: 57.33333vw;
    padding-top: 10.66667vw;
    padding-right: 0vw;
    padding-left: 5.33333vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    margin-top: 430px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    margin-top: 28.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    margin-top: 31.47877vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt:before {
    width: 94.66667vw;
    max-width: 710px;
    top: inherit;
    bottom: 0;
    height: 110%;
    left: 0;
  }
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    text-align: left;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
  padding-left: 3.125vw;
  padding-right: 4.375vw;
  padding-top: 8.75vw;
  padding-bottom: 6.25vw;
  width: 40.70%;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 10.2489vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 7.32064vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;
  right: 0;
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  margin-bottom: 1.875vw;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 0.56px;
  text-align: left;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.73333vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  margin-bottom: 3.75vw;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.13333vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.96px;
  display: block;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.13333vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.875vw;
  max-width: 30px;
  height: 0.625vw;
  max-height: 10px;
  display: inline-block;
  margin-left: 0.625vw;
  margin-bottom: .2em;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    width: 1.8em;
    height: 0.7em;
    margin-left: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.1713vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
  width: 59.297%;
  right: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  top: -3.125vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: -3.66032vw;
  }
}

#shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
    margin-bottom: 0vw;
    top: 0vw;
    width: 94.66667vw;
    max-width: 710px;
    left: -5.33333vw;
    position: absolute;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .childCategoryList .childCategoryListItem .img {
    left: -2.92826vw;
  }
}

#shopTop .inStoreWrap .secSubTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 1.92px;
  text-align: center;
  padding-top: 12.5vw;
  padding-bottom: 6.25vw;
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 4.8rem;
    font-size: 2.5vw;
    padding-top: 26.66667vw;
    padding-bottom: 20.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .secSubTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 154px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 10.13158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .secSubTit {
    padding-bottom: 11.27379vw;
  }
}

#shopTop .inStoreWrap .recommendedList {
  display: flex;
  justify-content: space-between;
  align-items: inherit;
  flex-wrap: wrap;
}

#shopTop .inStoreWrap .recommendedList:after {
  content: "";
  display: block;
  width: 48.6%;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList:after {
    display: none;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard {
  position: relative;
  width: 48.6%;
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    width: 100%;
    max-width: inherit;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 21.33333vw;
    padding-bottom: 10.66667vw;
  }
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(-n+4) {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:last-of-type {
    margin-bottom: 0vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard:after {
  content: "";
  display: block;
  max-width: 96.38%;
  width: 100%;
  height: 62.691%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:after {
    height: 65.35%;
    width: 94.66667vw;
    max-width: 710px;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:after {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:after {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:after {
    left: -2.92826vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .img {
  max-width: 96.38%;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 0 auto;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .img {
    margin-right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .img {
    margin-right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .img {
    margin-right: -2.92826vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
  position: relative;
  max-width: 96.38%;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    width: 100%;
    max-width: 100%;
    padding-top: 10.66667vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt {
    padding-right: 2.92826vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
  padding-top: 2.1875vw;
  padding-bottom: 3.5vw;
  padding-right: 2.875vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-bottom: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-bottom: 3.68421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-bottom: 4.09956vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-right: 46px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-right: 3.02632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-right: 3.3675vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt > a {
    padding: 0vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardLinkBox {
  display: flex;
  flex-direction: column;
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  letter-spacing: 1.26px;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.44;
    letter-spacing: normal;
    margin-bottom: 8vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardTit {
    margin-bottom: 4.39239vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: normal;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .txt .otherStoreCardDesc {
    margin-bottom: 7.32064vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
  position: absolute;
  bottom: 2.5vw;
  right: 5vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 5.85652vw;
  }
}

@media screen and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 2.13333vw;
  }
}

#shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  background-size: contain;
  width: 0.85em;
  height: 0.76em;
  margin-left: 0.5em;
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    bottom: 10.66667vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard .otherStoreCardDescBlank {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even):after {
    right: -5.33333vw;
    left: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even):after {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even):after {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even):after {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .img {
    right: inherit;
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .img {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .img {
    margin-left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .img {
    margin-left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .otherStoreCardDescBlank {
    right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .otherStoreCardDescBlank {
    right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .otherStoreCardDescBlank {
    right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #shopTop .inStoreWrap .recommendedList .otherStoreCard:nth-of-type(even) .otherStoreCardDescBlank {
    right: 1.46413vw;
  }
}

/* ==============================================
storeSearchパーツ
============================================== */
#storeSearchTop {
  background-image: linear-gradient(to bottom, #f1fbfc 120px, rgba(245, 253, 254, 0.6) 850px, rgba(245, 253, 254, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #storeSearchTop {
    background-image: linear-gradient(to bottom, #f1fbfc 7.5vw, rgba(245, 253, 254, 0.6) 52.63vw, rgba(245, 253, 254, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop {
    background-image: linear-gradient(to bottom, #f1fbfc 16.66vw, rgba(245, 253, 254, 0.6) 106vw, rgba(245, 253, 254, 0.8) 126.6vw);
  }
}

#storeSearchTop .buySearchResultBox {
  position: relative;
  z-index: 1;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox {
    margin-bottom: 14.64129vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 1.6;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultTit {
    margin-bottom: 5.85652vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 1.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 2.93333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea:before {
  content: "「";
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea:after {
  content: "」";
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchArea {
    font-size: 4.26667vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 1.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 2.93333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter:before {
  content: "「";
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter:after {
  content: "」";
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchFilter {
    font-size: 4.26667vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 1.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 2.93333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop:before {
  content: "「";
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop:after {
  content: "」";
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .searchShop {
    font-size: 4.26667vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: normal;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    margin-bottom: 7.32064vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
  font-size: 2.2rem;
  font-size: 1.14583vw;
  font-weight: bold;
  margin-left: .5em;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 2.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 1.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 1.61054vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 2.93333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
  content: "件";
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  margin-left: .5em;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 1.6;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 3.4rem;
    font-size: 1.77083vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    content: "件";
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 1.6;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultHead .buySearchResultHeadNum .searchNum:after {
    font-size: 4vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    border-top: 1px solid #707070;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType {
    margin-bottom: 0vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  margin-right: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    margin-right: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dt {
    margin-right: 4.39239vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  margin-right: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1.33;
    width: 50%;
    margin-right: 0vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:nth-last-child(-n+2) {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:nth-last-child(-n+2) {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:nth-last-child(-n+2) {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:nth-last-child(-n+2) {
    margin-bottom: 0vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:last-child {
  margin-right: 0vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:last-child {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:last-child {
    margin-right: 0vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:before {
  content: "";
  width: 2.15em;
  height: 2.15em;
  margin-right: 5px;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:before {
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li:before {
    margin-right: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.certified:before {
  background: url(/images/shop/store_search/search_cretified_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.drug:before {
  background: url(/images/shop/store_search/search_drug_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.variety:before {
  background: url(/images/shop/store_search/search_variety_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.salon:before {
  background: url(/images/shop/store_search/search_salon_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.adult:before {
  background: url(/images/shop/store_search/search_adult_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .storeType dd ul li.clinic:before {
  background: url(/images/shop/store_search/search_clinic_icon.svg) center center no-repeat;
  background-size: contain;
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070;
    padding-top: 5.33333vw;
    padding-bottom: 2.66667vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType {
    margin-bottom: 0vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.36px;
  margin-right: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    margin-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    margin-right: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dt {
    margin-right: 4.39239vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul {
    display: block;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  margin-right: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1.33;
    width: 100%;
    margin-right: 0vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li {
    margin-bottom: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:last-child {
  margin-right: 0vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:last-child {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:last-child {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:last-child {
    margin-right: 0vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:before {
  content: "";
  width: 5.625vw;
  max-width: 90px;
  height: 2.1875vw;
  max-height: 35px;
  margin-right: 10px;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:before {
    width: 24.4vw;
    max-width: 183px;
    height: 8vw;
    max-height: 60px;
    margin-right: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:before {
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:before {
    margin-right: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li:before {
    margin-right: 1.0981vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.iroha:before {
  background: url(/images/shop/store_search/iroha_logo.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.iroha:before {
    background: url(/images/shop/store_search/iroha_logo_sp.svg) center center no-repeat;
    background-size: contain;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.iic:before {
  background: url(/images/shop/store_search/iic_logo.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.iic:before {
    background: url(/images/shop/store_search/iic_logo_sp.svg) center center no-repeat;
    background-size: contain;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.ihc:before {
  background: url(/images/shop/store_search/ihc_logo.svg) center center no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .brandType dd ul li.ihc:before {
    background: url(/images/shop/store_search/ihc_logo_sp.svg) center center no-repeat;
    background-size: contain;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
  margin-top: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList {
    margin-top: 5.85652vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead {
  background: #fff;
  width: 100%;
  margin-bottom: 1.25vw;
  display: block;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead {
    margin-bottom: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
  display: flex;
  width: 100%;
  padding: 1.928% 3.66% 1.928% 1.4641%;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr {
    padding-left: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
  text-align: center;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1.6px;
  margin: auto;
  padding-right: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr td {
    padding-right: 0.73206vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .storeName {
  width: 31.611%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .address {
  width: 23.209%;
  padding-right: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .address {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .address {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .address {
    padding-right: 3.66032vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .handling {
  width: 11.538%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .brand {
  width: 16%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .tel {
  width: 11.448%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList thead tr .map {
  width: 6.349%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody {
  width: 100%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
  display: flex;
  align-items: center;
  padding: 1.464% 3.66% 1.464% 1.464%;
  background: #fff;
  padding-right: 1.25vw;
  padding-left: 1.25vw;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-right: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr:last-of-type {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr:last-of-type {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr:last-of-type {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding: 5.33333vw;
    margin-bottom: 10.66667vw;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    padding: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr {
    margin-bottom: 5.85652vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  padding-right: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    padding-right: 0.73206vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td:last-child {
  padding-right: 0;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr td {
    font-size: 3.73333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName {
  width: 31.611%;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-right: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName {
    padding-right: 2.19619vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-shrink: 0;
  width: 4.875vw;
  max-width: 78px;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 1.46413vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img {
  width: 2.125vw;
  max-width: 34px;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img:nth-of-type(n + 3) {
  margin-top: 12.5%;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName {
    width: 100%;
    flex-wrap: wrap;
    order: 1;
  }
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    max-width: 100%;
    width: 100%;
    justify-content: left;
    margin-right: 0vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img {
    width: 8vw;
    max-width: 60px;
    height: 8vw;
    max-height: 60px;
    margin-bottom: 0;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img {
    margin-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img:nth-of-type(n + 3) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img:nth-of-type(n + 3) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img:nth-of-type(n + 3) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeIcon img:nth-of-type(n + 3) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .storeName .storeNameField {
    width: 100%;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
  width: 23.209%;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    width: 100%;
    order: 2;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .address {
    margin-top: 2.92826vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling {
  width: 11.538%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
  display: block;
  min-width: calc(50% - 5px);
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  text-align: left;
  margin-bottom: 0.125vw;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 0.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 0.14641vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
  content: "";
  display: inline-block;
  vertical-align: text-top;
  border-radius: 100%;
  width: 1vw;
  max-width: 16px;
  height: 1vw;
  max-height: 16px;
  margin-right: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 0.36603vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.appliances::before {
  background: #ffda9f;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.physiology::before {
  background: #f7d2da;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.health::before {
  background: #99cfdf;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.beauty::before {
  background: rgba(229, 227, 87, 0.3);
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.adult::before {
  background: #ccccf5;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.bodyCare::before {
  background: #b6e6ab;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span.femtech::before {
  background: #fab0b0;
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling {
    width: 100%;
    justify-content: left;
    margin-bottom: 4vw;
    order: 4;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
    font-size: 2.4rem;
    font-size: 1.25vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 2.8px;
    text-align: left;
    width: 100%;
    margin-bottom: 2.66667vw;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling p {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    min-width: auto;
    width: auto;
    margin-right: 4vw;
    margin-top: 0.66667vw;
    margin-bottom: 0.66667vw;
    line-height: 1.66;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 1.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-top: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-top: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span {
    margin-bottom: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    width: 3.73333vw;
    max-width: 28px;
    height: 3.73333vw;
    max-height: 28px;
    margin-right: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .handling span::before {
    margin-right: 0.36603vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand {
  width: 16%;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex {
  display: flex;
  flex-wrap: wrap;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
  width: 5.625vw;
  max-width: 90px;
  margin-right: 0.3125vw;
  margin-bottom: 0.3125vw;
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand {
    order: 4;
    width: 100%;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    width: 24.4vw;
    max-width: 183px;
    margin-right: 1.6vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img:last-of-type {
    margin-right: 0;
  }
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .brand .flex .img img {
    width: 100%;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel {
  width: 11.448%;
  text-align: center;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
  color: #4d4d4d;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel {
    width: 76.5%;
    text-align: left;
    order: 3;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel {
    margin-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.86;
    letter-spacing: 1.4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .tel a {
    font-size: 3.73333vw;
  }
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map {
  width: 6.349%;
  text-align: center;
}

#storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  color: #4d4d4d;
  display: inline-block;
  width: 100%;
  border: 1px solid #adadad;
  border-radius: 7px;
  padding: 4px 0;
  width: 3.75vw;
  max-width: 60px;
}

@media screen and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map {
    width: 23.5%;
    order: 5;
    margin-left: auto;
  }
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 18.53333vw;
    max-width: 139px;
    padding-top: 2.13333vw;
    padding-bottom: 2.13333vw;
    line-height: 1;
    border-radius: 0.93vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-top: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-top: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-top: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #storeSearchTop .buySearchResultBox .buySearchResult .buySearchResultList tbody tr .map a {
    padding-bottom: 1.1713vw;
  }
}

/* ==============================================
iroha STOREパーツ
============================================== */
#irohaStoreTop {
  background-image: linear-gradient(to bottom, #f1fbfc 120px, rgba(245, 253, 254, 0.6) 850px, rgba(245, 253, 254, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #irohaStoreTop {
    background-image: linear-gradient(to bottom, #f1fbfc 7.5vw, rgba(245, 253, 254, 0.6) 52.63vw, rgba(245, 253, 254, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop {
    background-image: linear-gradient(to bottom, #f1fbfc 16.66vw, rgba(245, 253, 254, 0.6) 106vw, rgba(245, 253, 254, 0.8) 126.6vw);
  }
}

#irohaStoreTop .irohaStoreBOX {
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX {
    padding-bottom: 14.64129vw;
  }
}

#irohaStoreTop .irohaStoreBOX .mainVisual img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
  width: 100%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 3rem;
    font-size: 1.5625vw;
    letter-spacing: 1.2px;
    margin-top: 21.33333vw;
    margin-bottom: 21.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreDesc {
    padding-right: 2.92826vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg {
    margin-bottom: 11.71303vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div {
    width: 100%;
    max-width: 100%;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
  margin-top: 11.875vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 190px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 12.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 13.90922vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 0vw;
    margin-right: -10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-right: -80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-right: -5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) {
    margin-right: -5.85652vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) img {
  width: 37.5vw;
  max-width: 600px;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(1) img {
    width: 89.33333vw;
    max-width: 670px;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-left: -10.66667vw;
    margin-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-left: -80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-left: -5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-left: -5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) {
    margin-bottom: 11.71303vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) img {
  width: 37.5vw;
  max-width: 600px;
  margin-left: auto;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(2) img {
    width: 89.33333vw;
    max-width: 670px;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
  margin-left: auto;
  margin-top: -5.625vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: -90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: -5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: -6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: 0vw;
    margin-right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) {
    margin-right: -2.92826vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) img {
  width: 37.5vw;
  max-width: 600px;
  margin-right: auto;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreBOXImg div:nth-of-type(3) img {
    width: 89.33333vw;
    max-width: 670px;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-end;
  width: 100%;
  position: relative;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 10.66667vw;
    align-items: baseline;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column;
    padding-bottom: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    padding-top: 16vw;
    padding-right: 5.33333vw;
    padding-left: 0vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt:before {
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    height: 72.75%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
    font-size: 2.5vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.96px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    flex-basis: 26.7%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    width: 73.3%;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 3.73333vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
  padding-left: 3.125vw;
  padding-right: 2.5vw;
  padding-top: 7.5vw;
  padding-bottom: 5vw;
  width: 34.114%;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 1.875vw;
    padding-right: 1.875vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.64px;
  text-align: left;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
  flex-basis: 25%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dt {
    font-size: 2.4vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
  width: 75%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .txt dl dd {
    font-size: 2.4vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
  width: 65.885%;
  height: 31.25vw;
  max-height: 500px;
  bottom: -3.125vw;
  right: 0;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    bottom: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    bottom: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    bottom: -3.66032vw;
  }
}

#irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img iframe {
  margin: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    width: 94.66667vw;
    max-width: 710px;
    height: 73.33333vw;
    max-height: 550px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    bottom: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
  width: 30.38%;
  background: #fff;
  text-align: center;
  margin: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    width: 100%;
    max-width: 100%;
    margin-top: 0vw;
    margin-right: 0vw;
    margin-left: 0vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li:last-child {
    margin-bottom: 0vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 19.277% 9.15% 9.15%;
  position: relative;
  transition: opacity .3s;
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:hover {
  opacity: .7;
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:after {
  content: "";
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.34375vw;
  max-width: 21.5px;
  height: 1.2375vw;
  max-height: 19.8px;
  min-width: 12px;
  min-height: 10px;
  position: absolute;
  bottom: 9.15%;
  right: 9.15%;
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  margin-bottom: 17.831%;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 3.2vw;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p:before {
  content: "";
  width: 26.7%;
  height: 3.375vw;
  max-height: 54px;
  display: block;
  margin: 0 auto 12.1%;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-top: 9.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 9.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-top: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:after {
    width: 5.06667vw;
    max-width: 38px;
    height: 4.66667vw;
    max-height: 35px;
    right: 5.33333vw;
    bottom: 50%;
    transform: translate(0, 50%);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:after {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a:after {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    margin-bottom: 0vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li a p:before {
    width: 8.8vw;
    max-width: 66px;
    height: 7.06667vw;
    max-height: 53px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin-bottom: 0;
  }
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li.blog p:before {
  background: url(/images/shop/iroha_store/irohastore_blog_icon.svg) center center no-repeat;
  background-size: contain;
}

#irohaStoreTop .irohaStoreBOX .irohaStoreLinksWrap li.twitter p:before {
  background: url(/images/shop/iroha_store/irohastore_twitter_icon.svg) center center no-repeat;
  background-size: contain;
}

#irohaStoreTop .irohaStoreNewsBOX {
  position: relative;
  z-index: 1;
  padding-bottom: 8.75vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 140px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX {
    padding-bottom: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX .minContainer {
    padding: 0 !important;
  }
}

#irohaStoreTop .irohaStoreNewsBOX ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 8.78477vw;
  }
}

#irohaStoreTop .irohaStoreNewsBOX ul:after {
  content: "";
  display: block;
  width: 31.039%;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 6.66667vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul:after {
    content: none;
  }
  #irohaStoreTop .irohaStoreNewsBOX ul .slick-slide {
    padding: 0 .5%;
  }
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow {
    position: absolute;
    top: 19.33333vw;
    z-index: 2;
    width: 2.53333vw;
    max-width: 19px;
    height: 4.81333vw;
    max-height: 36.1px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow {
    top: 145px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow {
    top: 9.53947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow {
    top: 10.61493vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow.prev-arrow {
    left: 10%;
    transform: translate(-50%, -50%);
  }
  #irohaStoreTop .irohaStoreNewsBOX ul .slide-arrow.next-arrow {
    right: 10%;
    transform: translate(50%, -50%);
  }
}

#irohaStoreTop .irohaStoreNewsBOX ul li {
  width: 31.039%;
}

#irohaStoreTop .irohaStoreNewsBOX ul li .img img {
  width: 100%;
}

#irohaStoreTop .irohaStoreNewsBOX ul li .txt {
  padding-top: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 3.73333vw;
  }
}

#irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt .descTxt {
    padding-bottom: 2.19619vw;
  }
}

#irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreNewsBOX ul li .txt time {
    font-size: 1.86667vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox {
  position: relative;
  z-index: 1;
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList {
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList {
    margin-bottom: 2.92826vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
  background: #fff;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li {
    margin-bottom: 5.85652vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
  display: flex;
  padding-top: 4.375vw;
  padding-bottom: 4.375vw;
  padding-left: 2.1875vw;
  padding-right: 5vw;
  position: relative;
  transition: opacity .3s;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 5.85652vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a:hover {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a {
    padding-right: 2.92826vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner {
    flex-direction: column-reverse;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 10.029%;
  padding-right: 1em;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 22.66667vw;
    max-width: 170px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner time {
    font-size: 3.73333vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
  width: 70%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
    line-height: 1.71;
    letter-spacing: normal;
    margin-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    margin-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    margin-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a .listInner p {
    margin-bottom: 2.56223vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
  position: absolute;
  right: 5vw;
  bottom: 1.25vw;
  width: 9.25vw;
  max-width: 148px;
  height: 11.9375vw;
  max-height: 191px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    position: inherit;
    width: 19.73333vw;
    max-width: 148px;
    height: 25.33333vw;
    max-height: 190px;
    right: 0vw;
    bottom: 0vw;
    top: -6.66667vw;
    margin-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    top: -50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    top: -3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    top: -3.66032vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    margin-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #irohaStoreTop .irohaStoreMediaBox .mediaPostList li a img {
    margin-left: 3.66032vw;
  }
}

#irohaStoreTop .irohaStoreMediaBox .mediaPostList li.active a img {
  opacity: 1;
  visibility: visible;
  transform: none;
}

/* ==============================================
 NEWSパーツ
============================================== */
.newsArea {
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  .newsArea {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea {
    padding-top: 9.15081vw;
  }
}

.newsArea .postArchiveBox {
  position: relative;
  z-index: 1;
}

.newsArea .postArchiveBox .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secTit {
    padding-bottom: 8.78477vw;
  }
}

.newsArea .postArchiveBox .secSubTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: center;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.6px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .secSubTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .secSubTit {
    margin-bottom: 5.85652vw;
  }
}

.newsArea .postArchiveBox .selection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 2.5vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection {
    border-bottom: none;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection {
    margin-bottom: 8.78477vw;
  }
}

.newsArea .postArchiveBox .selection .categoryList {
  display: flex;
  justify-content: flex-start;
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList {
    width: 58%;
    border-left: 1px solid #444444;
    justify-content: center;
    position: relative;
  }
}

.newsArea .postArchiveBox .selection .categoryList dt a {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.2px;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  color: #666;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dt a {
    padding-right: 1.83016vw;
  }
}

.newsArea .postArchiveBox .selection .categoryList dt.current a {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 600;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dt.current a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dt.current a {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dt.current a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dt.current a {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dt.current {
    font-weight: 600;
    color: #4d4d4d;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn {
    width: 100%;
    text-align: center;
  }
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn:hover {
    cursor: pointer;
  }
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn:after {
    content: "";
    width: .7em;
    height: .7em;
    border-bottom: 1px solid #4d4d4d;
    border-right: 1px solid #4d4d4d;
    display: inline-block;
    margin-left: 9.33333vw;
    transform: translate(0, -25%) rotate(45deg);
    transition: all .3s;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn:after {
    margin-left: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn:after {
    margin-left: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn:after {
    margin-left: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList #cateToggleBtn.isOpen:after {
    transform: translate(0, 25%) rotate(-135deg);
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd {
    position: absolute;
    bottom: 0px;
    width: 100%;
    transform: translate(0, 100%);
    z-index: 2;
  }
}

.newsArea .postArchiveBox .selection .categoryList dd ul {
  display: flex;
}

.newsArea .postArchiveBox .selection .categoryList dd ul li a {
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 1.08px;
  color: #666;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-right: 1.83016vw;
  }
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 2.4vw;
  }
}

.newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 600;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul {
    flex-direction: column;
  }
  .newsArea .postArchiveBox .selection .categoryList dd ul li {
    text-align: center;
    border-bottom: 1px solid #FFFDFD;
  }
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    display: block;
    width: 100%;
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    background: #E5E5E5;
    color: #4d4d4d;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li a:hover {
    background: #ADADAD;
    color: #E5E5E5;
    cursor: pointer;
  }
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    background: #E5E5E5;
    color: #4d4d4d;
    font-weight: 500;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .categoryList dd ul li.current a:hover {
    background: #ADADAD;
    color: #E5E5E5;
  }
}

.newsArea .postArchiveBox .selection .archive {
  position: relative;
  padding-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 0vw;
    width: 42%;
    border-left: 1px solid #444444;
    border-right: 1px solid #444444;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive {
    padding-bottom: 0vw;
  }
}

.newsArea .postArchiveBox .selection .archive dt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 1.08px;
  color: #4d4d4d;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 1.83016vw;
  }
}

.newsArea .postArchiveBox .selection .archive dt:hover {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dt {
    text-align: center;
    padding-left: 2.66667vw;
    padding-right: 2.66667vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dt {
    font-size: 3.73333vw;
  }
}

.newsArea .postArchiveBox .selection .archive dt:after {
  content: "";
  width: .7em;
  height: .7em;
  border-bottom: 1px solid #4d4d4d;
  border-right: 1px solid #4d4d4d;
  display: inline-block;
  margin-left: 1.25em;
  transform: translate(0, -25%) rotate(45deg);
  transition: all .3s;
}

.newsArea .postArchiveBox .selection .archive.isOpen dt:after {
  transform: translate(0, 25%) rotate(-135deg);
}

.newsArea .postArchiveBox .selection .archive dd {
  display: none;
  position: absolute;
  width: 100%;
  background: #E5E5E5;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  z-index: 2;
  bottom: 0;
  transform: translate(0, 100%);
}

.newsArea .postArchiveBox .selection .archive dd ul li {
  border-bottom: 1px solid #FFFDFD;
}

.newsArea .postArchiveBox .selection .archive dd ul li a {
  display: block;
  width: 100%;
  padding-top: 0.625vw;
  padding-bottom: 0.625vw;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  color: #4d4d4d;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 2.4vw;
  }
}

.newsArea .postArchiveBox .selection .archive dd ul li a:hover {
  background: #ADADAD;
  color: #E5E5E5;
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 1.33333vw;
    padding-bottom: 1.33333vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .selection .archive dd ul li a {
    font-size: 3.73333vw;
  }
}

.newsArea .postArchiveBox .newsPaging {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsArea .postArchiveBox .newsPaging .page {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 2.13333vw;
  }
}

.newsArea .postArchiveBox .newsPaging .page a {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #4d4d4d;
  width: 2.25vw;
  max-width: 36px;
  height: 2.25vw;
  max-height: 36px;
  margin-right: 0.4375vw;
  margin-left: 0.4375vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 0.51245vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 7px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 0.46053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 0.51245vw;
  }
}

.newsArea .postArchiveBox .newsPaging .page a.active {
  font-weight: bold;
  color: #fff;
  background-color: #4d4d4d;
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .page {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    width: 7.6vw;
    max-width: 57px;
    height: 7.6vw;
    max-height: 57px;
    margin-right: 0.66667vw;
    margin-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-right: 0.36603vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .page a {
    margin-left: 0.36603vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 2.13333vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow form {
  display: inline-block;
}

.newsArea .postArchiveBox .newsPaging .pageArrow a {
  color: #4d4d4d;
}

.newsArea .postArchiveBox .newsPaging .pageArrow img {
  display: inline-block;
  vertical-align: baseline;
  transition: transform .3s;
  backface-visibility: hidden;
  width: 1.9375vw;
  max-width: 31px;
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow img {
    width: 6.4vw;
    max-width: 48px;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow.back {
  margin-right: 1.875vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 2.19619vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow.back a:hover img {
  animation: 1s back linear forwards;
}

.newsArea .postArchiveBox .newsPaging .pageArrow.back img {
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.back img {
    margin-right: 1.46413vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow.next {
  margin-left: 0.625vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 0.73206vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow.next img {
  margin-left: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 1.0981vw;
  }
}

.newsArea .postArchiveBox .newsPaging .pageArrow.next a:hover img {
  animation: 1s arrow linear forwards;
}

.newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
  content: "...";
  margin-right: 1.875vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 0.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 0.32895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next {
    margin-left: 0.36603vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next img {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postArchiveBox .newsPaging .pageArrow.next::before {
    margin-right: 2.19619vw;
  }
}

.newsArea .postSingleBox .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .secTit {
    padding-bottom: 10.98097vw;
  }
}

.newsArea .postSingleBox .articlePost {
  background: #fff;
  padding: 6.25vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    padding: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    padding: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    padding: 7.32064vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost {
    padding-top: 16vw;
    padding-bottom: 16vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost {
    margin-bottom: 8.78477vw;
  }
}

.newsArea .postSingleBox .articlePost .postTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 4.4rem;
    font-size: 2.29167vw;
    line-height: 1.64;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 2.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postTit {
    font-size: 5.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postTit {
    margin-bottom: 3.66032vw;
  }
}

.newsArea .postSingleBox .articlePost .postDate {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.14;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .postDate {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .postDate {
    margin-right: 2.19619vw;
  }
}

.newsArea .postSingleBox .articlePost .tag {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .tag {
    font-size: 3.73333vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner::after {
  display: table;
  clear: both;
  content: "";
}

.newsArea .postSingleBox .articlePost .articlePostInner h2 {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
  border-top: 1px solid #adadad;
  border-bottom: 1px solid #adadad;
  margin-bottom: 3.125vw;
  margin-top: 3.125vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.5;
    margin-bottom: 10.66667vw;
    margin-top: 10.66667vw;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h2 {
    padding-bottom: 2.92826vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner h3 {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  border-bottom: 1px solid #adadad;
  padding-bottom: 0.625vw;
  margin-bottom: 3.125vw;
  margin-top: 3.125vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 1.33;
    margin-bottom: 8vw;
    margin-top: 8vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner h3 {
    padding-bottom: 1.46413vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 2.13333vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner p a {
  text-decoration: underline;
  color: #4d4d4d;
  font-size: 1.6rem;
  font-size: 0.83333vw;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner p a {
    font-size: 3.73333vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner img {
  margin-top: 3.125vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 3.66032vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
  display: inline;
  float: left;
  margin: 0 auto;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 1.46413vw;
  }
}

.newsArea .postSingleBox .articlePost .articlePostInner img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
  display: inline;
  float: right;
  margin: 0 auto;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignleft {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .articlePost .articlePostInner img.alignright {
    margin-left: 2.19619vw;
  }
}

.newsArea .postSingleBox .pageLink {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 18.75vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 26.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink {
    padding-right: 2.92826vw;
  }
}

.newsArea .postSingleBox .pageLink li {
  padding-left: 3.75vw;
  padding-right: 3.75vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) {
    order: 1;
    width: 50%;
    border-bottom: 1px solid #adadad;
    border-top: 1px solid #adadad;
  }
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-right: 1em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li:nth-child(1) a {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li:nth-child(2) {
    order: 3;
    margin-left: auto;
    text-align: right;
    margin-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li:nth-child(2) {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li:nth-child(2) {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li:nth-child(2) {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) {
    order: 2;
    width: 50%;
    text-align: right;
    border-bottom: 1px solid #adadad;
    border-left: 1px solid #adadad;
    border-top: 1px solid #adadad;
  }
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    display: block;
    width: 100%;
    height: 100%;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-left: 1em;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li:nth-child(3) a {
    padding-bottom: 5.85652vw;
  }
}

.newsArea .postSingleBox .pageLink li a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.96px;
}

@media screen and (min-width: 1920px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a {
    font-size: 3.73333vw;
  }
}

.newsArea .postSingleBox .pageLink li a img {
  display: inline-block;
  vertical-align: baseline;
  backface-visibility: hidden;
  width: 1.375vw;
  max-width: 22px;
  margin-right: 0.75vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a img {
    width: 5.06667vw;
    max-width: 38px;
    margin-right: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img {
    margin-right: 1.1713vw;
  }
}

.newsArea .postSingleBox .pageLink li a img.arrowBack {
  width: 2.5625vw;
  max-width: 41px;
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a img.arrowBack {
    width: 10vw;
    max-width: 75px;
  }
}

.newsArea .postSingleBox .pageLink li a img.arrowNext {
  width: 2.5625vw;
  max-width: 41px;
  margin-right: 0vw;
  margin-left: 0.75vw;
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0vw;
  }
}

@media screen and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    width: 10vw;
    max-width: 75px;
    margin-left: 2.13333vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-left: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  .newsArea .postSingleBox .pageLink li a img.arrowNext {
    margin-right: 0vw;
  }
}

.newsArea .postSingleBox .pageLink li a:hover img.arrowBack {
  animation: 1s back linear forwards;
}

.newsArea .postSingleBox .pageLink li a:hover img.arrowNext {
  animation: 1s arrow linear forwards;
}

/* ==============================================
NEWSパーツ
============================================== */
#newsTop {
  background-image: linear-gradient(to bottom, #ddddec 120px, rgba(221, 221, 236, 0.1) 850px, rgba(245, 245, 250, 0.6) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #newsTop {
    background-image: linear-gradient(to bottom, #ddddec 7.5vw, rgba(221, 221, 236, 0.3) 52.63vw, rgba(245, 245, 250, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #newsTop {
    background-image: linear-gradient(to bottom, #ddddec 16.66vw, rgba(221, 221, 236, 0.3) 106vw, rgba(245, 245, 250, 0.8) 126.6vw);
  }
}

#newsTop .postArchiveBox .newsPostList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 8.78477vw;
  }
}

#newsTop .postArchiveBox .newsPostList:after {
  content: "";
  display: block;
  width: 31.039%;
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 16vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList:after {
    content: none;
  }
}

#newsTop .postArchiveBox .newsPostList li {
  width: 31.039%;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li {
    width: 100%;
    margin-bottom: 21.33333vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li:last-child {
    margin-bottom: 0vw;
  }
}

#newsTop .postArchiveBox .newsPostList li .img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#newsTop .postArchiveBox .newsPostList li .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .img {
    width: 40vw;
    max-width: 300px;
  }
}

#newsTop .postArchiveBox .newsPostList li .txt {
  padding-top: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 0vw;
    width: 50%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 3.73333vw;
  }
}

#newsTop .postArchiveBox .newsPostList li .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  padding-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .descTxt {
    padding-bottom: 2.19619vw;
  }
}

#newsTop .postArchiveBox .newsPostList li .txt time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt time {
    font-size: 1.86667vw;
  }
}

#newsTop .postArchiveBox .newsPostList li .txt .tag {
  width: 9.432%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  padding-left: 1em;
}

@media screen and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 20vw;
    max-width: 150px;
    line-height: 1.57;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .postArchiveBox .newsPostList li .txt .tag {
    font-size: 3.73333vw;
  }
}

#newsTop .postArchiveBox .archiveNext {
  max-width: 330px;
  width: 100%;
  margin-top: 7.5vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext {
    margin-top: 8.78477vw;
  }
}

#newsTop .postArchiveBox .archiveNext a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 1.08px;
}

@media screen and (min-width: 1920px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 3.04;
    letter-spacing: 1.68px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #newsTop .postArchiveBox .archiveNext a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #newsTop .postArchiveBox .archiveNext a img {
    width: 6.4vw;
    max-width: 48px;
    margin-left: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 26px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 1.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 1.90337vw;
  }
}

#newsTop .postArchiveBox .archiveNext a img {
  display: inline-block;
  vertical-align: baseline;
  backface-visibility: hidden;
  width: 1.9375vw;
  max-width: 31px;
  margin-left: 0.75vw;
}

@media screen and (min-width: 1600px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #newsTop .postArchiveBox .archiveNext a img {
    margin-left: 0.87848vw;
  }
}

#newsTop .postArchiveBox .archiveNext a:hover img {
  animation: 1s arrow linear forwards;
}

/* ==============================================
NEWS投稿パーツ
============================================== */
#newsPostTop {
  background-image: linear-gradient(to bottom, #ddddec 120px, rgba(221, 221, 236, 0.1) 850px, rgba(245, 245, 250, 0.6) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #newsPostTop {
    background-image: linear-gradient(to bottom, #ddddec 7.5vw, rgba(221, 221, 236, 0.3) 52.63vw, rgba(245, 245, 250, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #newsPostTop {
    background-image: linear-gradient(to bottom, #ddddec 16.66vw, rgba(221, 221, 236, 0.3) 106vw, rgba(245, 245, 250, 0.8) 126.6vw);
  }
}

/* ==============================================
MEDIAパーツ
============================================== */
#mediaTop {
  background-image: linear-gradient(to bottom, #fceed3 120px, rgba(252, 238, 211, 0.3) 850px, rgba(255, 249, 238, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #mediaTop {
    background-image: linear-gradient(to bottom, #fceed3 7.5vw, rgba(252, 238, 211, 0.3) 52.63vw, rgba(255, 249, 238, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #mediaTop {
    background-image: linear-gradient(to bottom, #fceed3 16.66vw, rgba(252, 238, 211, 0.3) 106vw, rgba(255, 249, 238, 0.8) 126.6vw);
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .selection {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .selection {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .selection {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .selection {
    margin-bottom: 5.85652vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList {
    margin-bottom: 8.78477vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li {
  background: #fff;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 5.12445vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li:first-child {
  margin-top: 4.375vw;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li:first-child {
    margin-top: 0vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li:last-child {
    margin-bottom: 0vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li a {
  display: flex;
  padding-top: 4.375vw;
  padding-bottom: 4.375vw;
  padding-left: 2.1875vw;
  padding-right: 5vw;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    align-items: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a {
    padding-right: 2.92826vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li a .listInner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

#mediaTop .postArchiveBox .mediaPostList li a .listInner time {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 8.792%;
  padding-right: 1em;
}

@media screen and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 22.66667vw;
    max-width: 170px;
    line-height: 1.71;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner time {
    font-size: 3.73333vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
  min-width: 9.432%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  padding-right: 1em;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    min-width: inherit;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 20vw;
    max-width: 150px;
    line-height: 1.57;
    flex-grow: 1;
    padding-right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner .tag {
    font-size: 3.73333vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
  width: 66.82%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a .listInner h3 {
    margin-top: 2.92826vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li a img {
  position: absolute;
  right: 5vw;
  bottom: 1.25vw;
  width: 9.25vw;
  max-width: 148px;
  height: 11.9375vw;
  max-height: 191px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: opacity 1s .5s, transform 1s .5s;
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    position: inherit;
    width: 19.73333vw;
    max-width: 148px;
    height: 25.33333vw;
    max-height: 190px;
    right: 0vw;
    bottom: 0vw;
    top: 0vw;
    margin-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    margin-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mediaTop .postArchiveBox .mediaPostList li a img {
    margin-left: 2.92826vw;
  }
}

#mediaTop .postArchiveBox .mediaPostList li.active a img {
  opacity: 1;
  visibility: visible;
  transform: none;
}

/* ==============================================
MEDIAパーツ
============================================== */
#mediaPostTop {
  background-image: linear-gradient(to bottom, #fceed3 120px, rgba(252, 238, 211, 0.3) 850px, rgba(255, 249, 238, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #mediaPostTop {
    background-image: linear-gradient(to bottom, #fceed3 7.5vw, rgba(252, 238, 211, 0.3) 52.63vw, rgba(255, 249, 238, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #mediaPostTop {
    background-image: linear-gradient(to bottom, #fceed3 16.66vw, rgba(252, 238, 211, 0.3) 106vw, rgba(255, 249, 238, 0.8) 126.6vw);
  }
}

#contact {
  background-image: linear-gradient(to bottom, #f1fbfc 120px, rgba(245, 253, 254, 0.6) 850px, rgba(245, 253, 254, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #contact {
    background-image: linear-gradient(to bottom, #f1fbfc 7.5vw, rgba(245, 253, 254, 0.6) 52.63vw, rgba(245, 253, 254, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #contact {
    background-image: linear-gradient(to bottom, #f1fbfc 16.66vw, rgba(245, 253, 254, 0.6) 106vw, rgba(245, 253, 254, 0.8) 126.6vw);
  }
}

#contact .contact {
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contact {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact {
    padding-top: 9.15081vw;
  }
}

#contact .contact .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  #contact .contact .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contact .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contact .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secTit {
    padding-bottom: 10.98097vw;
  }
}

#contact .contact .secSubTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #contact .contact .secSubTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secSubTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secSubTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secSubTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .secSubTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secSubTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secSubTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secSubTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.6px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contact .secSubTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secSubTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contact .secSubTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .secSubTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secSubTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secSubTit {
    margin-bottom: 4.39239vw;
  }
}

#contact .contact .secRead {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  #contact .contact .secRead {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secRead {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secRead {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secRead {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .secRead {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contact .secRead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .secRead {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .secRead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contact .secRead {
    font-size: 3.73333vw;
  }
}

#contact .contact .faqListTab {
  display: flex;
  justify-content: flex-start;
  margin-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqListTab {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .faqListTab {
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqListTab {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab {
    margin-top: 8.78477vw;
  }
}

#contact .contact .faqListTab li {
  cursor: pointer;
  max-width: calc(100% / 3.1);
  width: 100%;
  background-color: #e8e8e8;
  padding-top: 1.875vw;
  padding-bottom: 1.875vw;
  height: 7.5vw;
  max-height: 120px;
  margin-right: 1.6%;
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqListTab li {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab li {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab li {
    padding-top: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqListTab li {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab li {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab li {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .faqListTab li {
    padding-top: 2.66667vw;
    padding-bottom: 2.66667vw;
    height: 12.53333vw;
    max-height: 94px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqListTab li {
    padding-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab li {
    padding-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab li {
    padding-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqListTab li {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqListTab li {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqListTab li {
    padding-bottom: 1.46413vw;
  }
}

#contact .contact .faqListTab li:last-of-type {
  margin-right: 0;
}

#contact .contact .faqListTab li.active {
  background-color: #fff;
}

#contact .contact .faqListTab li.iroha-intimate-care::after {
  background-image: url(/images/common/iic_logo.svg);
}

#contact .contact .faqListTab li.iroha-healthcare {
  display: none;
}

#contact .contact .faqListTab li.iroha-healthcare::after {
  background-image: url(/images/common/ihc_logo.svg);
}

#contact .contact .faqListTab li::after {
  content: "";
  display: block;
  background-image: url(/images/common/iroha_logo.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

#contact .contact .faqListBox {
  display: none;
}

#contact .contact .faqListBox.active {
  display: block;
}

#contact .contact .faqListBox:nth-of-type(3) {
  display: none;
}

#contact .contact .faqList {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  background: #fff;
  padding-top: 5vw;
  padding-bottom: 2.5vw;
  padding-right: 2vw;
  padding-left: 2vw;
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList {
    padding-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList {
    padding-right: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList {
    padding-right: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList {
    padding-right: 2.34261vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList {
    padding-left: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList {
    padding-left: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList {
    padding-left: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .faqList {
    padding: 5.33333vw;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqList {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList {
    padding: 2.92826vw;
  }
}

#contact .contact .faqList li {
  width: 13.825%;
  min-height: 16.25vw;
  margin-left: 1.33193%;
  margin-right: 1.33193%;
  margin-bottom: 2.4%;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList li {
    min-height: 260px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList li {
    min-height: 17.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList li {
    min-height: 19.03367vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .faqList li {
    width: 37.6vw;
    max-width: 282px;
    min-height: 11.86667vw;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqList li {
    min-height: 89px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList li {
    min-height: 5.85526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList li {
    min-height: 6.51537vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .faqList li {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList li {
    margin-bottom: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList li {
    margin-bottom: 0.87848vw;
  }
}

#contact .contact .faqList li .img {
  width: 100%;
  height: 100%;
}

#contact .contact .faqList li .img a {
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (max-width: 767px) {
  #contact .contact .faqList li .img a img {
    width: 100%;
    height: 100%;
  }
}

#contact .contact .faqList li .logoTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  color: #4d4d4d;
  padding-top: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contact .contact .faqList li .logoTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList li .logoTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList li .logoTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .faqList li .logoTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .faqList li .logoTxt {
    padding-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .faqList li .logoTxt {
    padding-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .faqList li .logoTxt {
    padding-top: 0.73206vw;
  }
}

#contact .contactForm {
  margin-top: 12.5vw;
  margin-bottom: 11.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm {
    margin-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm {
    margin-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm {
    margin-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactForm {
    margin-bottom: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm {
    margin-bottom: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm {
    margin-bottom: 13.17716vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm {
    margin-top: 0vw;
    margin-bottom: 24vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm {
    margin-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm {
    margin-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm {
    margin-bottom: 13.17716vw;
  }
}

#contact .contactForm .secTit {
  padding-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm .secTit {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .secTit {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .secTit {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm .secTit {
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm .secTit {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .secTit {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .secTit {
    padding-bottom: 11.71303vw;
  }
}

#contact .contactFormType {
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
  background-color: #fff;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    padding-left: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-left: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-left: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    padding-right: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-right: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-right: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    margin-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormType {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormType {
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormType {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormType {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormType {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormType {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormType {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormType {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormType {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactFormTypeLabel {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  display: block;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormTypeLabel {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeLabel {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeLabel {
    font-size: 3rem;
    font-size: 1.5625vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormTypeLabel {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormTypeLabel {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactFormTypeLabel span {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-size: 1.4rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  display: inline-block;
  color: #fff;
  background: #4d4d4d;
  padding: 0 0.7em;
  border-radius: 1em;
  margin-left: 1em;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormTypeLabel span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeLabel span {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeLabel span {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormTypeLabel span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeLabel span {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeLabel span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormTypeLabel span {
    font-size: 2.66667vw;
  }
}

#contact .contactFormTypeBtn label {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
}

#contact .contactFormTypeBtn label:not(:last-of-type) {
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label:not(:last-of-type) {
    margin-bottom: 4.39239vw;
  }
}

#contact .contactFormTypeBtn label span:not([class]) {
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  cursor: pointer;
  padding-left: 2.25vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 36px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 2.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 2.63543vw;
  }
}

#contact .contactFormTypeBtn label span:not([class])::before {
  content: '';
  position: absolute;
  top: 0.125vw;
  left: 0vw;
  width: 1.25vw;
  max-width: 20px;
  height: 1.25vw;
  max-height: 20px;
  border: 1px solid #adadad;
  background: #fff;
  border-radius: 50%;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 0.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 0.14641vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0vw;
  }
}

#contact .contactFormTypeBtn label span:not([class])::after {
  content: '';
  position: absolute;
  top: 0.375vw;
  left: 0.25vw;
  width: 0.875vw;
  max-width: 14px;
  height: 0.875vw;
  max-height: 14px;
  transform: scale(0.5);
  border-radius: 50%;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 0.43924vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 4px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 0.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 0.29283vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class]) {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    content: '';
    position: absolute;
    top: 1.6vw;
    left: 0vw;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    border: 1px solid #adadad;
    background: #fff;
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    top: 0.87848vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::before {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    content: '';
    position: absolute;
    top: 2.4vw;
    left: 0.8vw;
    width: 2.93333vw;
    max-width: 22px;
    height: 2.93333vw;
    max-height: 22px;
    transform: scale(0.5);
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label span:not([class])::after {
    left: 0.43924vw;
  }
}

#contact .contactFormTypeBtn label h3.radioTit {
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  cursor: pointer;
  padding-left: 36px;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 2.13333vw;
  }
}

#contact .contactFormTypeBtn label h3.radioTit::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
  width: 20px;
  height: 20px;
  border: 1px solid #adadad;
  background: #fff;
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit::before {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label h3.radioTit::before {
    margin: 0;
    width: 4vw;
    max-width: 30px;
    height: 4vw;
    max-height: 30px;
    top: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label h3.radioTit::before {
    top: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit::before {
    top: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit::before {
    top: 0.87848vw;
  }
}

#contact .contactFormTypeBtn label h3.radioTit::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4px;
  margin: auto 0;
  width: 14px;
  height: 14px;
  transform: scale(0.5);
  border-radius: 50%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    margin: 0;
    width: 2.93333vw;
    max-width: 22px;
    height: 2.93333vw;
    max-height: 22px;
    top: 2.4vw;
    left: 0.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    top: 18px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    top: 1.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    left: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    left: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit::after {
    left: 0.43924vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-left: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    padding-left: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    padding-left: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormTypeBtn label h3.radioTit {
    padding-left: 3.66032vw;
  }
}

#contact .contactFormTypeBtn input[type="radio"],
#contact .contactFormTypeBtn input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 20px;
  padding: 9px;
  margin-top: 2px;
}

#contact .contactFormTypeBtn input[type="radio"]:checked + span::after,
#contact .contactFormTypeBtn input[type="checkbox"]:checked + span::after {
  transition: .3s;
  transform: scale(1);
  background: #4d4d4d;
}

#contact .contactFormTypeBtn input[type="radio"]:checked + h3.radioTit::after,
#contact .contactFormTypeBtn input[type="checkbox"]:checked + h3.radioTit::after {
  transition: .3s;
  transform: scale(1);
  background: #4d4d4d;
}

#contact .contactFormTypeBtn input[type="text"] {
  max-width: 400px;
  flex: 1;
  flex-basis: 100%;
  display: inline-block;
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  #contact .contactFormTypeBtn input[type="text"] {
    margin-top: 10px;
  }
}

#contact .contactForm .basic,
#contact .contactForm .split,
#contact .contactForm .business,
#contact .contactForm .proposal {
  display: none;
}

#contact .contactForm .basic.isShow,
#contact .contactForm .split.isShow,
#contact .contactForm .business.isShow,
#contact .contactForm .proposal.isShow {
  display: block;
}

#contact .contactForm #online {
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm #online {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online {
    margin-bottom: 16vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online {
    padding-right: 2.92826vw;
  }
}

#contact .contactForm #online .onlineTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
}

@media screen and (min-width: 1920px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactForm #online .onlineTxt {
    font-size: 3.73333vw;
  }
}

#contact .contactForm #online .BtnTemp {
  width: 31.25vw;
  max-width: 500px;
  margin-top: 5vw;
  margin-bottom: 3.75vw;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 4.39239vw;
  }
}

#contact .contactForm #online .BtnTemp a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  display: block;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  line-height: 2.4;
  text-align: center;
  text-decoration: none;
  padding: 1.0625vw;
  background-color: #4d4d4d;
  color: #fff;
}

@media screen and (min-width: 1920px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 1.24451vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 0.75vw;
    font-size: 1.2rem;
    font-size: 0.625vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 12px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1920px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 0.87848vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 1.6vw;
  }
}

#contact .contactForm #online .BtnTemp.circleBtnBlank a {
  border-radius: 5px;
}

#contact .contactForm #online .BtnTemp.circleBtnBlank a span {
  display: flex;
  justify-content: center;
  align-items: center;
}

#contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
  content: "";
  display: inline-block;
  background-image: url(/images/contents/single/blank_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 1vw;
  max-width: 16px;
  height: 0.875vw;
  max-height: 14px;
  margin-left: 0.625vw;
  min-width: 6.1%;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp {
    width: 100%;
    max-width: inherit;
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.5;
    padding: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp a {
    padding: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a {
    border-radius: 1.33vw;
  }
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span {
    position: relative;
  }
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    width: 4.26667vw;
    max-width: 32px;
    height: 3.33333vw;
    max-height: 25px;
    margin-left: 5.33333vw;
    position: absolute;
    right: 0;
    transform: translate(-50%, 0);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm #online .BtnTemp.circleBtnBlank a span:after {
    margin-left: 2.92826vw;
  }
}

#contact .contactFormField {
  display: none;
  opacity: 0;
  margin-top: 5vw;
}

#contact .contactFormField.isShow {
  display: block;
  opacity: 1;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField {
    margin-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField {
    margin-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField {
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField {
    margin-top: 8.78477vw;
  }
}

#contact .contactFormField input, #contact .contactFormField textarea {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  padding-top: 1.0625vw;
  padding-bottom: 1.0625vw;
  padding-left: 1.875vw;
  padding-right: 0.75vw;
  border: solid 1px #b7b7b7;
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 2.13333vw;
  }
}

#contact .contactFormField input::placeholder, #contact .contactFormField textarea::placeholder {
  color: #bbb;
}

#contact .contactFormField input:-ms-input-placeholder, #contact .contactFormField textarea:-ms-input-placeholder {
  color: #bbb;
}

#contact .contactFormField input::-ms-input-placeholder, #contact .contactFormField textarea::-ms-input-placeholder {
  color: #bbb;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 1.24451vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 1.24451vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-left: 2.66667vw;
    padding-right: 2.66667vw;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    padding-right: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField input, #contact .contactFormField textarea {
    font-size: 3.73333vw;
  }
}

#contact .contactFormField input.error, #contact .contactFormField textarea.error {
  background-color: #ffefef;
}

#contact .contactFormField textarea {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  min-height: 270px;
  min-width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField textarea {
    min-height: 115px;
  }
}

#contact .contactFormField > div > dl {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 30px;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl {
    display: block;
    padding: 0 0 40px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactFormField > div > dl > dt {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 2;
  flex-basis: 30%;
  max-width: 30%;
  padding-top: 1.125vw;
  padding-right: 40px;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField > div > dl > dt {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt {
    padding-top: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt {
    padding-top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 0;
    max-width: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt {
    font-size: 3.73333vw;
  }
}

#contact .contactFormField > div > dl > dt label {
  font-weight: bold;
}

#contact .contactFormField > div > dl > dt span:not([class]) {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  display: inline-block;
  color: #fff;
  background: #4d4d4d;
  padding: 0 0.7em;
  border-radius: 1em;
  margin-left: 1em;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt span:not([class]) {
    font-size: 2.66667vw;
  }
}

#contact .contactFormField > div > dl > dt h3.radioTit {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  display: inline-block;
  color: #fff;
  background: #4d4d4d;
  padding: 0 0.7em;
  border-radius: 1em;
  margin-left: 1em;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField > div > dl > dt h3.radioTit {
    font-size: 2.66667vw;
  }
}

#contact .contactFormField > div > dl > dd {
  flex-basis: 70%;
  max-width: 70%;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField > div > dl > dd {
    max-width: none;
    border: none;
    padding: 0;
  }
}

@media screen and (min-width: 768px) {
  #contact .contactFormField > div > dl > dd:not(:last-of-type) {
    padding-bottom: 40px;
  }
  #contact .contactFormField > div > dl > dd:first-of-type {
    padding-top: 5px;
  }
  #contact .contactFormField > div > dl > dd:last-of-type {
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dt {
    margin-bottom: 10px;
  }
}

#contact .contactFormField dt.top {
  padding-top: .5em;
}

@media screen and (min-width: 768px) {
  #contact .contactFormField dt:not(:last-of-type) {
    margin-bottom: 2.5vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #contact .contactFormField dt:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dt:not(:last-of-type) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dt:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

#contact .contactFormField dd dl {
  flex-wrap: wrap;
}

#contact .contactFormField dd dl dt {
  width: 25%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  font-weight: bold;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField dd dl dt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd dl dt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd dl dt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd dl dt {
    font-size: 2.13333vw;
  }
}

#contact .contactFormField dd dl dt label {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  font-size: 0.83333vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField dd dl dt label {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd dl dt label {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd dl dt label {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd dl dt label {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd dl dt label {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField dd dl dt label {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd dl dt label {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd dl dt label {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField dd dl dt label {
    font-size: 3.73333vw;
  }
}

#contact .contactFormField dd dl dd {
  width: 75%;
}

#contact .contactFormField dd dl dd:not(:last-of-type) {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField dd dl dd:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd dl dd:not(:last-of-type) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd dl dd:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

#contact .contactFormField dd dl dd textarea {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  min-height: 170px;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd dl dd textarea {
    min-height: 115px;
  }
}

#contact .contactFormField dd .flex {
  display: flex;
}

#contact .contactFormField dd .flex input {
  flex: 1;
}

#contact .contactFormField dd .flex_pc {
  display: flex;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc dt {
    flex-basis: 28%;
  }
}

#contact .contactFormField dd .flex_pc dt label {
  padding-right: 1em;
}

#contact .contactFormField dd .flex_pc dt span:not([class]) {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  display: inline-block;
  color: #fff;
  background: #4d4d4d;
  padding: 0 0.7em;
  border-radius: 1em;
  margin-right: 1em;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc dt span:not([class]) {
    font-size: 2.66667vw;
  }
}

#contact .contactFormField dd .flex_pc .center {
  padding-top: 1.125vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField dd .flex_pc .center {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .flex_pc .center {
    padding-top: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .flex_pc .center {
    padding-top: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc .center {
    padding-top: 0;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .flex_pc dd {
    flex-basis: 100%;
  }
}

#contact .contactFormField dd .flex_pc input {
  flex: 1;
}

#contact .contactFormField dd .attention {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 2;
  color: #E64B4B;
  margin-top: 1.25vw;
  word-break: break-all;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField dd .attention {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .attention {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .attention {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .attention {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField dd .attention {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .attention {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .attention {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .attention {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField dd .attention {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .attention {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .attention {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField dd .attention {
    font-size: 3.2vw;
  }
}

#contact .contactFormField dd .attention a {
  color: #E64B4B;
  text-decoration: underline;
}

#contact .contactFormField dd .errorTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 2.29;
  color: #e64b4b;
  margin-top: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField dd .errorTxt {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .errorTxt {
    margin-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .errorTxt {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField dd .errorTxt {
    font-size: 3.2vw;
  }
}

#contact .contactFormFieldSelect {
  position: relative;
}

#contact .contactFormFieldSelect::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  margin: auto;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #808080;
  border-right: 1px solid #808080;
  transform: rotate(45deg);
  transform-origin: right center;
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldSelect::after {
    width: 7px;
    height: 7px;
    right: 20px;
  }
}

#contact .contactFormFieldSelect select {
  padding: 18px 15px;
  font-size: 1.6rem;
  line-height: 1.5;
  color: #000;
  border: solid 1px #b7b7b7;
  background-color: #fff;
}

#contact .contactFormFieldNote {
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  line-height: 1.5;
  color: #555;
  margin-bottom: 2.5vw;
  margin-top: 3.75vw;
  padding-top: 2.5vw;
  width: 100%;
  border-top: 1px solid #E5E5E5;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormFieldNote {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNote {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    margin-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    margin-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNote {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 5.33333vw;
    margin-top: 8vw;
    padding-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormFieldNote {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormFieldNote {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    margin-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNote {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNote {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNote {
    padding-top: 2.92826vw;
  }
}

#contact .contactFormFieldNoteList {
  padding-left: 7.5vw;
  padding-right: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNoteList {
    padding-left: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList {
    padding-left: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList {
    padding-left: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNoteList {
    padding-right: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList {
    padding-right: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList {
    padding-right: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNoteList {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNoteList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNoteList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList {
    padding-right: 2.92826vw;
  }
}

#contact .contactFormFieldNoteList li {
  margin-bottom: 3.125vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1920px) {
  #contact .contactFormFieldNoteList li {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList li {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNoteList li {
    font-size: 2.13333vw;
  }
}

#contact .contactFormFieldNoteList li:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormFieldNoteList li:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList li:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNoteList li {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormFieldNoteList li {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList li {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList li {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormFieldNoteList li {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList li {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactFormFieldNoteList span {
  display: block;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormFieldNoteList span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNoteList span {
    font-size: 1.86667vw;
  }
}

#contact .contactFormFieldNoteList span a {
  color: #4d4d4d;
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  #contact .contactFormFieldNoteList span {
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormFieldNoteList span {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormFieldNoteList span {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormFieldNoteList span {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormFieldNoteList span {
    font-size: 3.2vw;
  }
}

#contact .contactFormFieldNoteList span.newformDesc {
  margin-bottom: 1em;
}

#contact .contactFormFieldNoteList span.attention {
  color: #E64B4B;
}

#contact .contactFormFieldNoteList span.attention a {
  color: #E64B4B;
  text-decoration: underline;
}

#contact .contactFormField .radioArea label,
#contact .contactFormField .checkboxArea label {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  flex-wrap: wrap;
  position: relative;
}

#contact .contactFormField .radioArea label:not(:last-of-type),
#contact .contactFormField .checkboxArea label:not(:last-of-type) {
  margin-bottom: 2.66667vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label:not(:last-of-type),
  #contact .contactFormField .checkboxArea label:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label:not(:last-of-type),
  #contact .contactFormField .checkboxArea label:not(:last-of-type) {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label:not(:last-of-type),
  #contact .contactFormField .checkboxArea label:not(:last-of-type) {
    margin-bottom: 1.46413vw;
  }
}

#contact .contactFormField .radioArea label.txtIn,
#contact .contactFormField .checkboxArea label.txtIn {
  width: 100%;
  margin-right: 0 !important;
}

#contact .contactFormField .radioArea label span:not([class]),
#contact .contactFormField .checkboxArea label span:not([class]) {
  position: relative;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  color: #333;
  cursor: pointer;
  padding-left: 33px;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 2.13333vw;
  }
}

#contact .contactFormField .radioArea label span:not([class])::before,
#contact .contactFormField .checkboxArea label span:not([class])::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 20px;
  margin: auto 0;
  border: 1px solid #adadad;
  background: #fff;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label span:not([class])::before,
  #contact .contactFormField .checkboxArea label span:not([class])::before {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label span:not([class])::before,
  #contact .contactFormField .checkboxArea label span:not([class])::before {
    width: 18px;
    height: 18px;
  }
}

#contact .contactFormField .radioArea label span:not([class])::after,
#contact .contactFormField .checkboxArea label span:not([class])::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 4px;
  width: 14px;
  height: 14px;
  transform: scale(0.5);
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label span:not([class])::after,
  #contact .contactFormField .checkboxArea label span:not([class])::after {
    width: 12px;
    height: 12px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label span:not([class])::after,
  #contact .contactFormField .checkboxArea label span:not([class])::after {
    width: 14px;
    height: 14px;
    left: 3px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField .radioArea label span:not([class]),
  #contact .contactFormField .checkboxArea label span:not([class]) {
    font-size: 3.73333vw;
  }
}

#contact .contactFormField .radioArea label h3.radioTit,
#contact .contactFormField .checkboxArea label h3.radioTit {
  position: relative;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
  color: #333;
  cursor: pointer;
  padding-left: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 2.19619vw;
  }
}

#contact .contactFormField .radioArea label h3.radioTit::before,
#contact .contactFormField .checkboxArea label h3.radioTit::before {
  content: '';
  position: absolute;
  top: 0.125vw;
  left: 0vw;
  width: 1.125vw;
  max-width: 18px;
  height: 1.125vw;
  max-height: 18px;
  border: 1px solid #adadad;
  background: #fff;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 2px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0.14641vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0vw;
  }
}

#contact .contactFormField .radioArea label h3.radioTit::after,
#contact .contactFormField .checkboxArea label h3.radioTit::after {
  content: '';
  position: absolute;
  top: 0.3125vw;
  left: 0.1875vw;
  width: 0.875vw;
  max-width: 14px;
  height: 0.875vw;
  max-height: 14px;
  transform: scale(0.5);
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 5px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 0.32895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 0.36603vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 3px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 0.19737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 0.21962vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-left: 9.33333vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit,
  #contact .contactFormField .checkboxArea label h3.radioTit {
    padding-left: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0vw;
    left: 0vw;
    width: 4.8vw;
    max-width: 36px;
    height: 4.8vw;
    max-height: 36px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::before,
  #contact .contactFormField .checkboxArea label h3.radioTit::before {
    left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 0.8vw;
    left: 0.8vw;
    width: 4.26667vw;
    max-width: 32px;
    height: 4.26667vw;
    max-height: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    top: 0.43924vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label h3.radioTit::after,
  #contact .contactFormField .checkboxArea label h3.radioTit::after {
    left: 0.43924vw;
  }
}

#contact .contactFormField .radioArea label .attention,
#contact .contactFormField .checkboxArea label .attention {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  flex-basis: 100%;
  padding-left: 140px;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label .attention,
  #contact .contactFormField .checkboxArea label .attention {
    padding-left: 30px;
  }
}

#contact .contactFormField .radioArea input[type="radio"],
#contact .contactFormField .radioArea input[type="checkbox"],
#contact .contactFormField .checkboxArea input[type="radio"],
#contact .contactFormField .checkboxArea input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  width: 20px;
  padding: 9px;
  margin-top: 2px;
}

#contact .contactFormField .radioArea input[type="radio"]:checked + span::after,
#contact .contactFormField .radioArea input[type="checkbox"]:checked + span::after,
#contact .contactFormField .checkboxArea input[type="radio"]:checked + span::after,
#contact .contactFormField .checkboxArea input[type="checkbox"]:checked + span::after {
  transition: .3s;
  transform: scale(1);
  background: #333;
}

#contact .contactFormField .radioArea input[type="text"],
#contact .contactFormField .checkboxArea input[type="text"] {
  max-width: 400px;
  flex: 1;
  display: inline-block;
  margin-left: 30px;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea input[type="text"],
  #contact .contactFormField .checkboxArea input[type="text"] {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea input[type="text"].spMax,
  #contact .contactFormField .checkboxArea input[type="text"].spMax {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
}

#contact .contactFormField .radioArea {
  padding-top: 1.25vw;
  padding-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea {
    padding-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea {
    padding-top: 0vw;
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea {
    padding-bottom: 0vw;
  }
}

#contact .contactFormField .radioArea.error {
  background-color: #ffe3e3;
}

#contact .contactFormField .radioArea label {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .radioArea label:not(:last-of-type) {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .radioArea label:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .radioArea label:not(:last-of-type) {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .radioArea label:not(:last-of-type) {
    margin-bottom: 2.19619vw;
  }
}

#contact .contactFormField .radioArea span:not([class])::before {
  border-radius: 50%;
}

#contact .contactFormField .radioArea span:not([class])::after {
  border-radius: 50%;
}

#contact .contactFormField .radioArea h3.radioTit::before {
  border-radius: 50%;
}

#contact .contactFormField .radioArea h3.radioTit::after {
  border-radius: 50%;
}

#contact .contactFormField .checkboxArea {
  margin-left: 20px;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .checkboxArea {
    margin-left: 15px;
  }
}

#contact .contactFormField .checkboxArea label {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  display: inline-flex;
  height: 100%;
  align-items: center;
}

#contact .contactFormField .checkboxArea span:not([class])::before {
  border-radius: 3px;
}

#contact .contactFormField .checkboxArea span:not([class])::after {
  border-radius: 3px;
}

#contact .contactFormField .checkboxArea h3.radioTit::before {
  border-radius: 5px;
}

#contact .contactFormField .checkboxArea h3.radioTit::after {
  border-radius: 5px;
}

#contact .contactFormField .checkboxArea.checkboxArea02 {
  padding: 1.25vw;
  margin-left: 0;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .checkboxArea.checkboxArea02 {
    padding: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .checkboxArea.checkboxArea02 {
    padding: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .checkboxArea.checkboxArea02 {
    padding: 1.46413vw;
  }
}

#contact .contactFormField .checkboxArea.checkboxArea02 label:not(:last-of-type) {
  margin-right: 13%;
}

#contact .contactFormField .checkboxArea.checkboxArea02 label:last-of-type {
  width: 100%;
}

#contact .contactFormField .checkboxArea.checkboxArea02.error {
  background: #ffe3e3;
}

#contact .contactFormField .privacyPolicyBox {
  text-align: center;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .privacyPolicyBox {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox {
    margin-bottom: 7.32064vw;
  }
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy {
  display: inline-block;
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea.error {
  padding: 15px 0;
  background: #ffefef;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy {
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy {
    margin-bottom: 10.2489vw;
  }
}

#contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 2;
  color: #555;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 2.4rem;
    font-size: 1.25vw;
    text-align: left;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicyTxt {
    margin-bottom: 2.92826vw;
  }
}

#contact .contactFormField .privacyPolicyBox .privacyPolicyTxt a {
  text-decoration: underline;
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea {
  margin-left: 0;
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1.75;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea span:not([class]) {
    font-size: 3.2vw;
  }
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 1.75;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 2.4rem;
    font-size: 1.25vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .checkboxArea h3.radioTit {
    font-size: 3.2vw;
  }
}

#contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  line-height: 2.29;
  color: #ff0028;
  margin-top: 0.625vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    margin-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    margin-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField .privacyPolicyBox .privacyPolicy .errorTxt {
    margin-top: 5px;
  }
}

#contact .contactFormField #basic dl {
  background: #fff;
  padding: 7.5vw;
  margin-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    padding: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    padding: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    padding: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #basic dl {
    padding-top: 16vw;
    padding-bottom: 16vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #basic dl {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #basic dl {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #basic dl {
    margin-top: 8.78477vw;
  }
}

#contact .contactFormField #business {
  background: #fff;
  padding: 7.5vw;
  margin-top: 7.5vw;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business {
    padding: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    padding: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    padding: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business {
    margin-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    margin-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    margin-top: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #business {
    padding-top: 16vw;
    padding-bottom: 16vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-top: 16vw;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business {
    margin-bottom: 8.78477vw;
  }
}

#contact .contactFormField #business dl {
  padding: 0;
}

#contact .contactFormField #business .attachedDocumentBox.error label {
  background-color: #ffdbdb;
}

#contact .contactFormField #business .attachedDocumentBox label {
  position: relative;
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: normal;
  line-height: 1.5;
  color: #afb3b7;
  border-radius: 4px;
  border: solid 1px #b7b7b7;
  background-color: #fff;
  padding-top: 1.0625vw;
  padding-bottom: 1.1875vw;
  padding-right: 11.25vw;
  padding-left: 0.75vw;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 1.24451vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 19px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 1.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 1.39092vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 13.17716vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 12px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 0.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 0.87848vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    border-radius: 2px;
    padding-top: 4vw;
    padding-bottom: 4vw;
    padding-right: 37.33333vw;
    padding-left: 1.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 280px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 18.42105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-right: 20.4978vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 0.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox label {
    padding-left: 0.87848vw;
  }
}

#contact .contactFormField #business .attachedDocumentBox input[type="file"] {
  display: none;
}

#contact .contactFormField #business .attachedDocumentBox input[type="button"] {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: normal;
  line-height: 1.5;
  border-radius: 0;
  border: none;
  background-color: #ddd;
  width: 11.25vw;
  max-width: 180px;
  height: 100%;
  padding: 0px;
  color: #555;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 2.13333vw;
  }
}

#contact .contactFormField #business .attachedDocumentBox input[type="button"]::placeholder {
  color: #444;
}

#contact .contactFormField #business .attachedDocumentBox input[type="button"]:-ms-input-placeholder {
  color: #444;
}

#contact .contactFormField #business .attachedDocumentBox input[type="button"]::-ms-input-placeholder {
  color: #444;
}

#contact .contactFormField #business .attachedDocumentBox input[type="button"].error {
  background-color: #ffdbdb;
}

@media screen and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    border-radius: 2px;
    width: 37.33333vw;
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormField #business .attachedDocumentBox input[type="button"] {
    font-size: 3.73333vw;
  }
}

#contact .contactFormField #business .attachedDocumentBox .attachedDocumentTxt {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space-collapse: preserve;
  text-wrap: nowrap;
  overflow: hidden;
}

#contact .contactFormField #business .attachedDocumentBox .attachedDocumentTxt.active {
  color: #444;
}

#contact .contactFormNote {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
}

@media screen and (min-width: 1920px) {
  #contact .contactFormNote {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormNote {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormNote {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormNote {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactFormNote {
    font-size: 2.4rem;
    font-size: 1.25vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactFormNote {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormNote {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormNote {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactFormNote {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormNote {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormNote {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormNote {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactFormNote {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactFormNote {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactFormNote {
    padding-right: 2.92826vw;
  }
}

#contact .contactForm .boxBtn_black {
  background: #4d4d4d;
  color: #fff;
  display: block;
  margin: 0 auto;
  width: 25vw;
  max-width: 400px;
  padding: 1vw;
  margin-bottom: 7.5vw;
  border-radius: 5px;
}

@media screen and (min-width: 1600px) {
  #contact .contactForm .boxBtn_black {
    padding: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black {
    padding: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black {
    padding: 1.1713vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm .boxBtn_black {
    padding: 4vw;
    width: 78.66667vw;
    max-width: 590px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm .boxBtn_black {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black {
    padding: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black {
    padding: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactForm .boxBtn_black:hover {
  cursor: pointer;
}

#contact .contactForm .boxBtn_black:hover span:after {
  animation: 1s arrow linear forwards;
}

#contact .contactForm .boxBtn_black span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.36px;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactForm .boxBtn_black span {
    font-size: 3.73333vw;
  }
}

#contact .contactForm .boxBtn_black span:after {
  content: "";
  background: url(/images/common/arrow_next_white.svg) bottom center no-repeat;
  width: 2.55em;
  height: 1.55em;
  position: absolute;
  bottom: 0%;
  right: -2.77em;
  background-size: contain;
}

#contact .contactForm .boxBtn_black:disabled {
  opacity: .5;
}

#contact .contactForm .boxBtn_black:hover span:after {
  animation: 1s arrow linear forwards;
}

#contact .contactConfirm {
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirm {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirm {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirm {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirm {
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirm {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirm {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirm {
    margin-bottom: 10.2489vw;
  }
}

#contact .contactConfirmTit {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 1.46;
  color: #555;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmTit {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmTit {
    margin-bottom: 2.92826vw;
  }
}

#contact .contactConfirmTxt {
  font-size: 1.6rem;
  line-height: 2;
  color: #555;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmTxt {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmTxt {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmTxt {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmTxt {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmTxt {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmTxt {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmTxt {
    margin-bottom: 7.32064vw;
  }
}

#contact .contactConfirmArea {
  background-color: #fff;
  padding: 7.5vw;
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmArea {
    padding: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    padding: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    padding: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmArea {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea {
    padding-top: 16vw;
    padding-bottom: 16vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea {
    padding-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea {
    margin-bottom: 10.2489vw;
  }
}

#contact .contactConfirmArea .secRead {
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea .secRead {
    margin-bottom: 8.78477vw;
  }
}

#contact .contactConfirmArea dl {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  line-height: 1.5;
}

@media screen and (min-width: 1920px) {
  #contact .contactConfirmArea dl {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea dl {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea dl {
    display: block;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactConfirmArea dl {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactConfirmArea dl {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea dl .small {
    border-bottom: 1px solid #E5E5E5;
    margin-bottom: 10.66667vw;
    padding-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl .small {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl .small {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl .small {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl .small {
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl .small {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl .small {
    padding-bottom: 1.46413vw;
  }
}

#contact .contactConfirmArea dl dt {
  color: #000;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  #contact .contactConfirmArea dl dt {
    width: 30%;
  }
  #contact .contactConfirmArea dl dt:not(:last-child) {
    margin-bottom: 3.75vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl dt:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl dt:not(:last-child) {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl dt:not(:last-child) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea dl dt:not(.small) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl dt:not(.small) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl dt:not(.small) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl dt:not(.small) {
    margin-bottom: 2.92826vw;
  }
}

#contact .contactConfirmArea dl dd {
  color: #555;
}

@media screen and (min-width: 768px) {
  #contact .contactConfirmArea dl dd {
    width: 70%;
  }
}

#contact .contactConfirmArea dl dd:not(:last-child) {
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea dl dd:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactConfirmArea .flex {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0px !important;
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea .flex {
    margin-bottom: 0px !important;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmArea .flex {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmArea .flex {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmArea .flex {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmArea .flex dt {
    width: 100%;
  }
  #contact .contactConfirmArea .flex dd {
    width: 100%;
  }
  #contact .contactConfirmArea .flex dd:not(:last-of-type) {
    margin-bottom: 10.6666vw !important;
  }
}

@media screen and (min-width: 768px) {
  #contact .contactConfirmArea .flex dt {
    width: 25%;
    font-weight: normal;
  }
  #contact .contactConfirmArea .flex dd {
    width: 75%;
  }
}

#contact .contactConfirmBtn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 860px;
  margin: auto;
  padding-left: 5.33333vw;
  padding-right: 5.33333vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmBtn {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtn {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtn {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmBtn {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtn {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtn {
    padding-right: 2.92826vw;
  }
}

#contact .contactConfirmBtnBack {
  flex-basis: 46.5%;
  background: #fff;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnBack {
    flex-basis: 100%;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmBtnBack {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnBack {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnBack {
    margin-bottom: 5.85652vw;
  }
}

#contact .contactConfirmBtnBack a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right-color: #fff;
  height: 5vw;
  max-height: 80px;
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnBack a {
    flex-basis: 100%;
    height: 16vw;
    max-height: 120px;
  }
}

#contact .contactConfirmBtnBack a span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.36px;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactConfirmBtnBack a span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnBack a span:before {
    width: 4.4vw;
    max-width: 33px;
    height: 4.26667vw;
    max-height: 32px;
    left: -4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -2.19619vw;
  }
}

#contact .contactConfirmBtnBack a span:before {
  content: "";
  background: url(/images/common/contact_back_icon.svg) center center no-repeat;
  width: 1.1875vw;
  max-width: 19px;
  height: 1.0625vw;
  max-height: 17px;
  position: absolute;
  top: 50%;
  left: -0.9375vw;
  background-size: contain;
  transform: translate(-100%, -50%);
  transition: all .3s;
}

@media screen and (min-width: 1600px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnBack a span:before {
    left: -1.0981vw;
  }
}

#contact .contactConfirmBtnSubmit {
  flex-basis: 46.5%;
  background: #4d4d4d;
  border-radius: 5px;
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnSubmit {
    flex-basis: 100%;
  }
}

#contact .contactConfirmBtnSubmit button {
  width: 100%;
  color: #fff;
  height: 5vw;
  max-height: 80px;
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnSubmit button {
    flex-basis: 100%;
    height: 16vw;
    max-height: 120px;
  }
}

#contact .contactConfirmBtnSubmit button:hover {
  cursor: pointer;
}

#contact .contactConfirmBtnSubmit button:hover span::after {
  animation: 1s arrow linear forwards;
}

#contact .contactConfirmBtnSubmit button span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.36px;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contactConfirmBtnSubmit button span {
    font-size: 3.73333vw;
  }
}

#contact .contactConfirmBtnSubmit button span:after {
  content: "";
  background: url(/images/common/arrow_next_white.svg) center center no-repeat;
  position: absolute;
  background-size: contain;
  width: 2.55em;
  height: 1.55em;
  bottom: -.3em;
  right: -2.77em;
}

#contact .contactComplete {
  text-align: center;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #contact .contactComplete {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactComplete {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactComplete {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contactComplete {
    margin-bottom: 16vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactComplete {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactComplete {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactComplete {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactComplete {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactComplete {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactComplete {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contactComplete {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contactComplete {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contactComplete {
    padding-left: 2.92826vw;
  }
}

#contact .contactComplete .secRead {
  line-height: 2;
}

@media screen and (max-width: 767px) {
  #contact .contactComplete .secRead {
    text-align: left;
  }
}

#contact .contact .btnLayout {
  text-align: center;
}

#contact .contact .btnLayout .boxBtn_back {
  background: #4d4d4d;
  color: #fff;
  display: block;
  margin: 0 auto;
  width: 25vw;
  max-width: 400px;
  padding: 1.25vw;
  margin-bottom: 21.875vw;
  border-radius: 5px;
}

@media screen and (min-width: 1600px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 350px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 23.02632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 25.62225vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 4vw;
    width: 78.66667vw;
    max-width: 590px;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back {
    padding: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back {
    margin-bottom: 14.64129vw;
  }
}

#contact .contact .btnLayout .boxBtn_back:hover {
  cursor: pointer;
}

#contact .contact .btnLayout .boxBtn_back:hover span:after {
  animation: 1s arrow linear forwards;
}

#contact .contact .btnLayout .boxBtn_back span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: 0.36px;
  position: relative;
}

@media screen and (min-width: 1920px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .contact .btnLayout .boxBtn_back span {
    font-size: 3.73333vw;
  }
}

#contact .contact .btnLayout .boxBtn_back span:after {
  content: "";
  background: url(/images/common/arrow_next_white.svg) center center no-repeat;
  width: 2.55em;
  height: 1.55em;
  position: absolute;
  bottom: -.3em;
  right: -3.05em;
  background-size: contain;
}

#contact .detailModalContent {
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  background: #fff;
  position: fixed;
  display: none;
  z-index: 100000;
  display: none;
}

#contact .detailModalContent .close {
  position: absolute;
  top: 4.375vw;
  right: 6.25vw;
  z-index: 100;
  opacity: 1;
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .close {
    top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .close {
    top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .close {
    top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .close {
    right: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .close {
    right: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .close {
    right: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .close {
    top: 5.33333vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .close {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .close {
    top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .close {
    top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .close {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .close {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .close {
    right: 2.92826vw;
  }
}

#contact .detailModalContent .close a {
  display: block;
  text-indent: -9999px;
  width: 3.75vw;
  max-width: 60px;
  height: 3.75vw;
  max-height: 60px;
  background: url(/images/common/modal_close_btn.svg) no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .close a {
    width: 6.4vw;
    max-width: 48px;
    height: 6.4vw;
    max-height: 48px;
    background-size: contain;
  }
}

#contact .detailModalContent .modalInner {
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-top: 15vw;
  padding-bottom: 15vw;
  height: 100vh;
  background: #fff;
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner {
    padding-top: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner {
    padding-top: 15.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner {
    padding-top: 17.56955vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 15.78947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 17.56955vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner {
    padding-top: 32vw;
    padding-bottom: 32vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner {
    padding-top: 240px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner {
    padding-top: 15.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner {
    padding-top: 17.56955vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 240px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 15.78947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner {
    padding-bottom: 17.56955vw;
  }
}

#contact .detailModalContent .modalInner .minContainer {
  max-width: 1110px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-left: 10.66667vw;
    padding-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer {
    padding-right: 5.85652vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2.4px;
  text-align: center;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    margin-bottom: 10.66667vw;
    line-height: 1.78;
    letter-spacing: 5.4px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 5.85652vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalSubTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalSubTit {
    margin-bottom: 5.85652vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: 0.4px;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateTit {
    margin-bottom: 2.92826vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 1px solid #E5E5E5;
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dt {
  flex-basis: 33.79%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.9px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 1.875vw;
  padding-top: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 3rem;
    font-size: 1.5625vw;
    padding-top: 5.33333vw;
    padding-bottom: 0vw;
    border-bottom: none;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dt {
    padding-bottom: 0vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd {
  flex-basis: 66.21%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.22;
  letter-spacing: 0.9px;
  text-align: left;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 1.875vw;
  padding-top: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 2.19619vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 3rem;
    font-size: 1.5625vw;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    flex-basis: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd {
    padding-bottom: 2.92826vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
  margin-top: 3.125vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 3.66032vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
  display: inline;
  float: left;
  margin: 0 auto;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 1.46413vw;
  }
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
  display: inline;
  float: right;
  margin: 0 auto;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignleft {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #contact .detailModalContent .modalInner .minContainer .modalDateList dd img.alignright {
    margin-left: 2.19619vw;
  }
}

#faqTop {
  background-image: linear-gradient(to bottom, #f1fbfc 120px, rgba(245, 253, 254, 0.6) 850px, rgba(245, 253, 254, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

#faqTop .faqArea {
  padding-top: 7.8125vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea {
    padding-top: 125px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea {
    padding-top: 8.22368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea {
    padding-top: 9.15081vw;
  }
}

#faqTop .faqArea .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secTit {
    padding-bottom: 10.98097vw;
  }
}

#faqTop .faqArea .secSubTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .secSubTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secSubTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secSubTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .secSubTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .secSubTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 3.6px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .secSubTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secSubTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .secSubTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .secSubTit {
    margin-bottom: 4.39239vw;
  }
}

#faqTop .faqArea .faqListTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .faqListTit {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqListTit {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqListTit {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqListTit {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqListTit {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqListTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqListTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqListTit {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .faqListTit {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqListTit {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqListTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .faqListTit {
    font-size: 3.73333vw;
  }
}

#faqTop .faqArea .allControlWrap {
  text-align: end;
  border-bottom: 1px solid #e5e5e5;
}

#faqTop .faqArea .allControlWrap #allControlBtn {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 1.2px;
  display: inline-block;
  padding-right: 5vw;
  position: relative;
  padding-bottom: 1.25vw;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-right: 10.66667vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn {
    padding-bottom: 2.19619vw;
  }
}

#faqTop .faqArea .allControlWrap #allControlBtn:after {
  content: "";
  width: .8em;
  height: .8em;
  border-top: 1px solid #4d4d4d;
  border-left: 1px solid #4d4d4d;
  position: absolute;
  top: .7em;
  right: 2.5vw;
  transform: translate(50%, -50%) rotate(225deg);
  transition: all .3s;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .allControlWrap #allControlBtn:after {
    right: 2.92826vw;
  }
}

#faqTop .faqArea .allControlWrap #allControlBtn.isOpen:after {
  transform: translate(50%, 0%) rotate(45deg);
}

#faqTop .faqArea .faqList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList {
    margin-bottom: 8.78477vw;
  }
}

#faqTop .faqArea .faqList li {
  border-bottom: 1px solid #e5e5e5;
}

#faqTop .faqArea .faqList li .question {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 5vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  position: relative;
  display: flex;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    padding-left: 5.33333vw;
    padding-right: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question {
    padding-right: 5.85652vw;
  }
}

#faqTop .faqArea .faqList li .question:before {
  flex-shrink: 0;
  content: "Q.";
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 3px;
  width: 2vw;
  max-width: 32px;
  margin-right: 1.0625vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 1.24451vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 4.2rem;
    font-size: 2.1875vw;
    width: 6.13333vw;
    max-width: 46px;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 2.76316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question:before {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:before {
    margin-right: 2.19619vw;
  }
}

#faqTop .faqArea .faqList li .question:after {
  content: "";
  width: .8em;
  height: .8em;
  border-top: 1px solid #4d4d4d;
  border-left: 1px solid #4d4d4d;
  position: absolute;
  top: 2.5vw;
  right: 2.5vw;
  transform: translate(50%, 75%) rotate(225deg);
  transition: all .3s;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 4vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:after {
    top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .question:after {
    right: 2.92826vw;
  }
}

#faqTop .faqArea .faqList li .question.isOpen:after {
  transform: translate(50%, 100%) rotate(45deg);
}

#faqTop .faqArea .faqList li .answerBox {
  display: none;
}

#faqTop .faqArea .faqList li .answer {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  position: relative;
  display: flex;
  background-color: #fff;
  padding-top: 2.5vw;
  padding-bottom: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer {
    padding-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    padding-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    padding-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    padding-right: 2.92826vw;
  }
}

#faqTop .faqArea .faqList li .answer::after {
  display: table;
  clear: both;
  content: "";
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer {
    padding: 2.92826vw;
  }
}

#faqTop .faqArea .faqList li .answer:before {
  flex-shrink: 0;
  content: "A.";
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3rem;
  font-size: 1.5625vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 3px;
  width: 2vw;
  max-width: 32px;
  margin-right: 1.0625vw;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 17px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 1.11842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 1.24451vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 4.2rem;
    font-size: 2.1875vw;
    width: 6.13333vw;
    max-width: 46px;
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 4.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 2.76316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 3.07467vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer:before {
    font-size: 5.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer:before {
    margin-right: 2.19619vw;
  }
}

#faqTop .faqArea .faqList li .answer img {
  margin-top: 3.125vw;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 3.66032vw;
  }
}

#faqTop .faqArea .faqList li .answer img.alignleft {
  display: inline;
  float: left;
  margin: 0 auto;
  margin-right: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 1.46413vw;
  }
}

#faqTop .faqArea .faqList li .answer img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#faqTop .faqArea .faqList li .answer img.alignright {
  display: inline;
  float: right;
  margin: 0 auto;
  margin-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 4vw;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img.alignleft {
    margin-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqList li .answer img.alignright {
    margin-left: 2.19619vw;
  }
}

#faqTop .faqArea .faqList li .answer a {
  color: #4d4d4d;
  text-decoration: underline;
}

#faqTop .faqArea .faqThumbnailList {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  padding: 3.6603% 2.1961% 1.83015%;
  background: #fff;
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqThumbnailList {
    padding: 5.33333vw;
    margin-bottom: 16vw;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqThumbnailList {
    padding: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqThumbnailList {
    padding: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqThumbnailList {
    padding: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqThumbnailList {
    margin-bottom: 8.78477vw;
  }
}

#faqTop .faqArea .faqThumbnailList li {
  width: 16.8453%;
  margin-left: 1.53193%;
  margin-right: 1.53193%;
  margin-bottom: 3.06386%;
  border: 4px solid #fff;
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .faqThumbnailList li {
    width: 37.6vw;
    max-width: 282px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .faqThumbnailList li {
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .faqThumbnailList li {
    margin-bottom: 0.85526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .faqThumbnailList li {
    margin-bottom: 0.95168vw;
  }
}

#faqTop .faqArea .faqThumbnailList li.current {
  border: 4px solid #D6A1B7;
}

#faqTop .faqArea .pageBackWrap {
  text-align: end;
}

#faqTop .faqArea .pageBackWrap .categoryBack {
  margin-bottom: 17.5vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 280px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 18.42105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 20.4978vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap .categoryBack {
    margin-bottom: 14.64129vw;
  }
}

#faqTop .faqArea .pageBackWrap .categoryBack a:before {
  content: "";
  display: inline-block;
  background: url(/images/contents/single/category_back_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.1875vw;
  max-width: 19px;
  height: 1.0625vw;
  max-height: 17px;
  margin-right: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    width: 4.4vw;
    max-width: 33px;
    height: 4vw;
    max-height: 30px;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap .categoryBack a:before {
    margin-right: 1.46413vw;
  }
}

#faqTop .faqArea .pageBackWrap a {
  color: #4D4D4D;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  position: relative;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #faqTop .faqArea .pageBackWrap a {
    font-size: 3.73333vw;
  }
}

#aboutTop {
  background-image: linear-gradient(to bottom, #e8dfe0 120px, rgba(248, 240, 241, 0.6) 850px, rgba(248, 240, 241, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #aboutTop {
    background-image: linear-gradient(to bottom, #e8dfe0 7.5vw, rgba(248, 240, 241, 0.6) 52.63vw, rgba(248, 240, 241, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #aboutTop {
    background-image: linear-gradient(to bottom, #e8dfe0 16.66vw, rgba(248, 240, 241, 0.6) 106vw, rgba(248, 240, 241, 0.8) 126.6vw);
  }
}

#aboutTop .aboutIrohaArea {
  padding-top: 11.5625vw;
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 185px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 12.17105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 13.54319vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutIrohaArea {
    padding-top: 15.00732vw;
  }
}

#aboutTop .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .secTit {
    padding-bottom: 10.98097vw;
  }
}

#aboutTop .aboutConceptWrap {
  padding-bottom: 12.5vw;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 92vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 690px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 45.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap {
    padding-bottom: 50.51245vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc {
    flex-direction: column-reverse;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt {
  padding-top: 2.8125vw;
  width: 50%;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 45px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 2.96053vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    padding-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt {
    padding-top: 8.78477vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
  width: 100%;
  max-width: 703px;
  margin-left: auto;
  padding-left: 1.25vw;
  padding-right: 0vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner {
    padding-right: 0vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 5.4rem;
  font-size: 2.8125vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.26;
  letter-spacing: 3.24px;
  text-align: center;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 5.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 3.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 7.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 5.12445vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
  content: "きもちよさを、自分らしく。";
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  display: block;
  margin-top: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    letter-spacing: 1.62px;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .conceptTit:after {
    margin-top: 1.46413vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.6px;
  text-align: left;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 2.66667vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
  text-align: end;
  padding-right: 4.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 66px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 4.34211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 4.83163vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
  text-align: end;
  padding-right: 2.375vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 38px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 2.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 2.78184vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(3) {
  text-align: end;
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 2.92826vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(4) {
  text-align: end;
  width: 133%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.5;
    letter-spacing: .32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    text-align: left;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(1) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    text-align: left;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(2) p {
    width: 100%;
  }
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(3) {
    text-align: left;
  }
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:not(:nth-of-type(4)) {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(4) {
    text-align: left;
    width: 100%;
  }
  #aboutTop .aboutConceptWrap .conceptDesc .txt .inner .descTxt:nth-of-type(4) p {
    width: 100%;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .img {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img {
    width: 100%;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
  width: 93.75%;
  height: auto;
  position: relative;
  margin-right: 0;
  margin-left: auto;
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    width: 100%;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img .videoBox {
    margin-bottom: 0vw;
  }
}

#aboutTop .aboutConceptWrap .conceptDesc .img .videoBox:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

#aboutTop .aboutConceptWrap .conceptDesc .img .videoBox video {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#aboutTop .aboutConceptWrap .conceptDesc .img img {
  margin-left: 16.875vw;
  width: 28.125vw;
  max-width: 450px;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 270px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 17.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 19.76574vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    position: absolute;
    bottom: 21.33333vw;
    right: 0;
    margin-left: 0vw;
    width: 63.06667vw;
    max-width: 473px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutConceptWrap .conceptDesc .img img {
    margin-left: 0vw;
  }
}

#aboutTop .aboutUsWrap {
  z-index: 1;
  padding-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap {
    padding-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap {
    padding-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap {
    padding-bottom: 18.30161vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList:not(:last-of-type) {
    margin-bottom: 11.71303vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    margin-bottom: 14.64129vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:before {
  content: "";
  background-color: #fff;
  width: 56.25vw;
  max-width: 900px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:last-child {
  margin-bottom: 0vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:last-child {
    margin-bottom: 0vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) {
  flex-direction: row-reverse;
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even):before {
  content: "";
  position: absolute;
  top: 0;
  left: inherit;
  right: 0;
  z-index: -1;
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
  right: inherit;
  left: 0;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: baseline;
    padding-bottom: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:before {
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    top: inherit;
    bottom: 0;
    height: 72.75%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    flex-direction: column;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even):before {
    content: "";
    position: absolute;
    top: 0;
    left: inherit;
    right: -5.33333vw;
    z-index: -1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even):before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even):before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even):before {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem:nth-child(even) .img {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    padding-top: 10.66667vw;
    padding-right: 5.33333vw;
    padding-left: 0vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  width: 39.53%;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 5.85652vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 2.92826vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.13333vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
  width: 59.297218%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  margin-top: 3.125vw;
  margin-bottom: -3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: -3.66032vw;
  }
}

#aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    bottom: 0;
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList {
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  margin-bottom: 12.5vw;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 10vw;
    padding-bottom: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 75px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 4.93421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-top: 5.49048vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList::before {
    width: 94.66667vw;
    max-width: 710px;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList::before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList::before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList::before {
    right: -2.92826vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList:before {
  content: "";
  width: 80.6734%;
  height: 100%;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

#aboutTop .aboutUsWrap .aboutUsList li {
  display: flex;
  flex-wrap: wrap;
}

#aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
  margin-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList li .img img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.5;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList li .txt .descTxt {
    font-size: 3.73333vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList .first .img {
  width: 59.297%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .img {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .img {
    margin-left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .img {
    margin-left: -2.92826vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList .first .txt {
  width: 40.703%;
  padding-left: 2.5vw;
  padding-right: 3.125vw;
  padding-top: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 0vw;
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .first .txt {
    padding-top: 5.85652vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList .remaining {
  width: 80.6734%;
  margin-left: auto;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    width: 100%;
    padding-left: 5.33333vw;
    padding-right: 0vw;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining {
    padding-right: 0vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
  width: 36.064%;
  padding-right: 2.5vw;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    width: 100%;
    padding-right: 0vw;
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt .mainTxt {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt .mainTxt {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt .mainTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .txt .mainTxt {
    margin-bottom: 2.92826vw;
  }
}

#aboutTop .aboutUsWrap .aboutUsList .remaining .img {
  width: 63.936%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutUsWrap .aboutUsList .remaining .img {
    width: 66.66667vw;
    max-width: 500px;
    margin: 0 auto;
  }
}

#aboutTop .anniversaryWrap {
  padding-top: 12.5vw;
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .anniversaryWrap {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .anniversaryWrap {
    padding-top: 26.66667vw;
    padding-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .anniversaryWrap {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap {
    padding-bottom: 14.64129vw;
  }
}

#aboutTop .anniversaryWrap a {
  position: relative;
}

#aboutTop .anniversaryWrap a .arrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.96px;
  text-align: center;
  position: absolute;
  bottom: 5.625vw;
  left: 0;
  width: 100%;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.71;
    letter-spacing: 1.68px;
    bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .anniversaryWrap a .arrow {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .anniversaryWrap a .arrow {
    bottom: 7.32064vw;
  }
}

#aboutTop .anniversaryWrap a .arrow svg {
  width: 1.9375vw;
  max-width: 31px;
  height: 0.75vw;
  max-height: 12px;
}

@media screen and (max-width: 767px) {
  #aboutTop .anniversaryWrap a .arrow svg {
    width: 5.6vw;
    max-width: 42px;
    height: 1.6vw;
    max-height: 12px;
  }
}

#aboutTop .aboutDescLinkWrap {
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 22.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 11.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap {
    padding-bottom: 12.4451vw;
  }
}

#aboutTop .aboutDescLinkWrap .secTit {
  font-size: 4.8rem;
  font-size: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 5.4rem;
    font-size: 2.8125vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .secTit {
    font-size: 7.2vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: baseline;
    padding-bottom: 0vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    width: 100%;
    max-height: 100%;
    padding-top: 10.66667vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.12px;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 5.12445vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
  padding-right: 3.125vw;
  width: 40.703%;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt {
    padding-right: 3.66032vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .mainTxt {
    margin-bottom: 3.66032vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .txt .descTxt {
    margin-bottom: 3.66032vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.96px;
  display: block;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.13333vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
  content: "";
  background: url(/images/about_iroha/next_arrow.svg) bottom center no-repeat;
  background-size: contain;
  width: 2.5625vw;
  max-width: 41px;
  height: 0.625vw;
  max-height: 10px;
  display: inline-block;
  margin-left: 0.625vw;
  margin-bottom: .2em;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
    font-size: 1.5625vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    width: 6.4vw;
    max-width: 48px;
    height: 1.46667vw;
    max-height: 11px;
    margin-left: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .arrow:after {
    margin-left: 1.1713vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
  width: 59.297%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img img {
  margin: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    width: 89.33333vw;
    max-width: 670px;
    margin-top: 0vw;
    margin-bottom: 0vw;
    bottom: 0;
    margin-left: auto;
    margin-right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList .childCategoryListItem .img {
    margin-right: -2.92826vw;
  }
}

#aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem {
  flex-direction: row-reverse;
}

#aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
  padding-right: 0vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 0vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem {
    flex-direction: column-reverse;
  }
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .img {
    margin-right: auto;
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .img {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .img {
    margin-left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutDescLinkWrap .childCategoryList:nth-of-type(even) .childCategoryListItem .img {
    margin-left: -2.92826vw;
  }
}

#aboutTop .aboutCardListWrap {
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap {
    padding-top: 14.64129vw;
  }
}

#aboutTop .aboutCardListWrap ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#aboutTop .aboutCardListWrap ul li {
  width: 23.7188%;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li {
    width: 48.3582%;
  }
  #aboutTop .aboutCardListWrap ul li:nth-child(-n+2) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li:nth-child(-n+2) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li:nth-child(-n+2) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li:nth-child(-n+2) {
    margin-bottom: 2.92826vw;
  }
}

#aboutTop .aboutCardListWrap ul li a {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
  height: 100%;
  padding-top: 3.125vw;
  padding-bottom: 3.125vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  transition: opacity .3s;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 1.83016vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 1.83016vw;
  }
}

#aboutTop .aboutCardListWrap ul li a:hover {
  opacity: .7;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 5.33333vw;
    padding-bottom: 5.33333vw;
    padding-left: 3.33333vw;
    padding-right: 3.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-left: 1.83016vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 25px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 1.64474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a {
    padding-right: 1.83016vw;
  }
}

#aboutTop .aboutCardListWrap ul li a .cardTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: 0.64px;
  text-align: center;
  margin-bottom: 1.5625vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    line-height: 1.6;
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardTit {
    margin-bottom: 0.73206vw;
  }
}

#aboutTop .aboutCardListWrap ul li a .cardRead {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 2.4rem;
    font-size: 1.25vw;
    margin-bottom: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a .cardRead {
    margin-bottom: 1.46413vw;
  }
}

#aboutTop .aboutCardListWrap ul li a span {
  margin-top: auto;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.32px;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutCardListWrap ul li a span {
    font-size: 3.73333vw;
  }
}

#aboutTop .aboutCardListWrap ul li a span.arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.8em;
  height: 0.7em;
  display: inline-block;
  margin-left: 0.5em;
}

#aboutTop .aboutCardListWrap ul li a span.blank:after {
  content: "";
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  margin-left: 0.5em;
}

#aboutTop .aboutProductsCatWrap {
  padding-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 28vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 210px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 13.81579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap {
    padding-bottom: 15.37335vw;
  }
}

#aboutTop .aboutProductsCatWrap .flex {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .flex {
    display: block;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
  max-width: 32.211%;
  width: 100%;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.1);
  margin-right: 1.4375vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 23px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 1.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 1.68375vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard:last-of-type {
  margin-right: 0;
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    max-width: 100%;
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
    margin-right: 0vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard {
    margin-bottom: 5.85652vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
  position: relative;
  display: block;
  background-color: #fff;
  height: 100%;
  padding-bottom: 7.375vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 118px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 7.76316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 8.63836vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 18.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 138px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 9.07895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard a {
    padding-bottom: 10.10249vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard img {
  width: 100%;
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
  margin: 0 auto;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .img {
    margin-bottom: 4.39239vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
  margin: 0 auto;
  width: 13.625vw;
  max-width: 218px;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    width: 29.06667vw;
    max-width: 218px;
    margin-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .logo {
    margin-bottom: 3.66032vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.32px;
  color: #4d4d4d;
  padding-right: 2.5vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.56px;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard .cardRead {
    padding-left: 2.92826vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
  position: absolute;
  margin-top: auto;
  text-align: end;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.32px;
  bottom: 3.125vw;
  right: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    bottom: 8vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutTop .aboutProductsCatWrap .aboutProductsCatCard span {
    right: 2.92826vw;
  }
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard span.arrow:after {
  content: "";
  background: url(/images/common/arrow_next.svg) bottom center no-repeat;
  background-size: contain;
  width: 1.8em;
  height: 0.7em;
  display: inline-block;
  margin-left: 0.5em;
}

#aboutTop .aboutProductsCatWrap .aboutProductsCatCard span.blank:after {
  content: "";
  background: url(/images/common/blank_icon.svg) bottom center no-repeat;
  background-size: contain;
  width: 0.8em;
  height: 0.8em;
  display: inline-block;
  margin-left: 0.5em;
}

#aboutCSRTop {
  background-image: linear-gradient(to bottom, #e8dfe0 120px, rgba(248, 240, 241, 0.6) 850px, rgba(248, 240, 241, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #aboutCSRTop {
    background-image: linear-gradient(to bottom, #e8dfe0 7.5vw, rgba(248, 240, 241, 0.6) 52.63vw, rgba(248, 240, 241, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop {
    background-image: linear-gradient(to bottom, #e8dfe0 16.66vw, rgba(248, 240, 241, 0.6) 106vw, rgba(248, 240, 241, 0.8) 126.6vw);
  }
}

#aboutCSRTop .aboutCSRArea {
  padding-top: 11.5625vw;
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 185px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 12.17105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 13.54319vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCSRArea {
    padding-top: 15.00732vw;
  }
}

#aboutCSRTop .aboutCsrWrap .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 6.875vw;
}

@media screen and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 8.05271vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .secTit {
    padding-bottom: 10.98097vw;
  }
}

#aboutCSRTop .aboutCsrWrap .readTxt {
  text-align: center;
}

#aboutCSRTop .aboutCsrWrap .readTxt p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
}

@media screen and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.56;
    letter-spacing: 1.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .readTxt p {
    font-size: 4.26667vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card {
    margin-top: 14.64129vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList {
  width: 47.584%;
  background: #fff;
  padding-top: 4.375vw;
  padding-bottom: 3.75vw;
  padding-left: 4.6875vw;
  padding-right: 4.6875vw;
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 5.49048vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 75px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 4.93421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 5.49048vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    width: 100%;
    padding-top: 8vw;
    padding-bottom: 8vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:not(:last-of-type) {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:not(:last-of-type) {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:not(:last-of-type) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:not(:last-of-type) {
    margin-bottom: 5.85652vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: 0.56px;
  text-align: left;
  margin-bottom: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.3;
    letter-spacing: 0.8px;
    margin-bottom: 10.66667vw;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardTit {
    margin-bottom: 5.85652vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.56;
    letter-spacing: 1.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList .cardDesc {
    font-size: 4.26667vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1) {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1) {
    flex-direction: column-reverse;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1):after {
  content: "";
  background: url(/images/about_iroha/csr/csr_ribbon_icon.svg) top center no-repeat;
  background-size: contain;
  width: 8.4%;
  height: 100%;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1):after {
    width: 100%;
    height: 24vw;
    max-height: 180px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1):after {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1):after {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1):after {
    margin-bottom: 5.85652vw;
  }
}

#aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1).active:after {
  opacity: 1;
  transform: none;
}

#aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1) .txt {
  width: 86.6%;
}

@media screen and (max-width: 767px) {
  #aboutCSRTop .aboutCsrWrap .card .cardList:nth-of-type(1) .txt {
    width: 100%;
  }
}

#aboutAwardTop {
  background-image: linear-gradient(to bottom, #e8dfe0 120px, rgba(248, 240, 241, 0.6) 850px, rgba(248, 240, 241, 0.8) 1000px);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #aboutAwardTop {
    background-image: linear-gradient(to bottom, #e8dfe0 7.5vw, rgba(248, 240, 241, 0.6) 52.63vw, rgba(248, 240, 241, 0.8) 62.5vw);
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop {
    background-image: linear-gradient(to bottom, #e8dfe0 16.66vw, rgba(248, 240, 241, 0.6) 106vw, rgba(248, 240, 241, 0.8) 126.6vw);
  }
}

#aboutAwardTop .aboutAwardArea {
  padding-top: 11.5625vw;
  position: relative;
  z-index: 1;
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 185px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 12.17105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 13.54319vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardArea {
    padding-top: 15.00732vw;
  }
}

#aboutAwardTop .aboutAwardWrap .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: 4.9px;
  text-align: center;
  padding-top: 6.875vw;
  padding-bottom: 10vw;
}

@media screen and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 7rem;
    font-size: 3.64583vw;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.07em;
    text-align: center;
    padding-top: 20vw;
    padding-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    font-size: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-top: 10.98097vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .secTit {
    padding-bottom: 10.98097vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 22.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 170px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 11.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:not(:last-child) {
    margin-bottom: 12.4451vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner {
  position: relative;
}

#aboutAwardTop .aboutAwardWrap .awardList .inner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 96.3396%;
  height: 100%;
  background: #fff;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner:before {
    bottom: 0;
    left: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    top: inherit;
    bottom: 0;
    height: 81.18%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner:before {
    left: -2.92826vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    flex-direction: column-reverse;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain {
    padding-bottom: 5.85652vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
  width: 41.4348%;
  padding: 3.66%;
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    width: 100%;
    padding-top: 10.66667vw;
    padding-left: 0vw;
    padding-right: 5.33333vw;
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt {
    padding-bottom: 0vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.5;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainTit {
    margin-bottom: 5.85652vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .txt .awardMainDesc {
    font-size: 3.73333vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
  width: 58.5652%;
  margin-top: -3.125vw;
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: -3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    width: 94.66667vw;
    max-width: 710px;
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img {
    margin-top: 0vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardMain .img img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
  width: 96.3396%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1.83% 3.66% 2.928% 3.66%;
  border-top: 1px solid #E5E5E5;
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    width: 100%;
    padding-top: 5.33333vw;
    padding-right: 5.33333vw;
    padding-bottom: 10.66667vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc {
    padding-left: 0vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img {
  width: 20.231%;
  padding-right: 3.79%;
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img {
    width: 100%;
    padding-right: 0;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc .img img {
    width: 39.33333vw;
    max-width: 295px;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
  width: 79.769%;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    width: 100%;
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .inner .awardDesc p {
    font-size: 2.66667vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
  text-align: right;
  width: 96.3396%;
  padding-right: 3.66%;
  margin-top: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    width: 100%;
    padding-right: 0;
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap {
    margin-top: 5.85652vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
  color: #4d4d4d;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.32px;
}

@media screen and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList .linkWrap a {
    font-size: 3.73333vw;
  }
}

#aboutAwardTop .aboutAwardWrap .awardList .linkWrap a:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  background-size: contain;
  width: .8em;
  height: .8em;
  display: inline-block;
  margin-left: .5em;
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner {
    position: relative;
  }
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner:before {
    bottom: 0;
    right: -5.33333vw;
    left: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner:before {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .img {
    margin-left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .img {
    margin-left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .img {
    margin-left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardMain .img {
    margin-left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-left: 5.33333vw;
    padding-right: 0vw;
    border-top: none;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #aboutAwardTop .aboutAwardWrap .awardList:nth-of-type(even) .inner .awardDesc:before {
    content: "";
    width: 84vw;
    max-width: 630px;
    height: 1px;
    background: #e5e5e5;
    position: absolute;
    top: 0;
    right: 0;
  }
}

#ageConfirmTop {
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop {
    padding-top: 5.85652vw;
  }
}

#ageConfirmTop .minContainer {
  text-align: center;
}

#ageConfirmTop .minContainer .mainLogo {
  margin-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 8.78477vw;
  }
}

#ageConfirmTop .minContainer .mainLogo img {
  margin: 0 auto;
  width: 14.4375vw;
  max-width: 231px;
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainLogo {
    margin-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .mainLogo img {
    width: 31.06667vw;
    max-width: 233px;
  }
}

#ageConfirmTop .minContainer .mainTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4rem;
  font-size: 2.08333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 2.08px;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 5.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 6.2rem;
    font-size: 3.22917vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 6.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 4.07895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 4.5388vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .minContainer .mainTit {
    font-size: 8.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .mainTit {
    margin-bottom: 2.92826vw;
  }
}

#ageConfirmTop .minContainer .subTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.71;
  letter-spacing: 0.84px;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 2.1rem;
    font-size: 1.09375vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 2.1rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 1.38158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 1.53734vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .minContainer .subTit {
    font-size: 2.8vw;
  }
}

#ageConfirmTop .minContainer .read {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: center;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .minContainer .read {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .read {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .read {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-top: 10.66667vw;
    margin-bottom: 10.66667vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
    letter-spacing: normal;
    text-align: left;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .minContainer .read {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .minContainer .read {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .read {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .read {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .read {
    padding-right: 2.92826vw;
  }
}

#ageConfirmTop .minContainer .ask {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.4px;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .minContainer .ask {
    font-size: 4.26667vw;
  }
}

#ageConfirmTop .minContainer .BtnTemp {
  max-width: 400px;
  margin-top: 3.75vw;
  margin-bottom: 3.75vw;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 4.39239vw;
  }
}

#ageConfirmTop .minContainer .BtnTemp a {
  display: block;
  padding: 0.9375vw;
  background: #4d4d4d;
  transition: all .3s;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 1.0981vw;
  }
}

#ageConfirmTop .minContainer .BtnTemp a:hover {
  opacity: .8;
}

#ageConfirmTop .minContainer .BtnTemp a p {
  display: inline;
  color: #fff;
  position: relative;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 2.4;
  text-align: center;
  text-decoration: none;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 2.66667vw;
  }
}

#ageConfirmTop .minContainer .BtnTemp.circleBtn a {
  border-radius: 5px;
}

#ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
  position: absolute;
  right: -3.1875vw;
  bottom: 25%;
  width: 2.5625vw;
  max-width: 41px;
  height: 0.625vw;
  max-height: 10px;
  transition: transform .5s;
  transform: scale(1, 1);
  transform-origin: left top;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -51px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -3.35526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -3.73353vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -6.8vw;
    width: 5.46667vw;
    max-width: 41px;
    height: 1.33333vw;
    max-height: 10px;
    margin-left: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -51px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -3.35526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    right: -3.73353vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a p svg {
    margin-left: 0.73206vw;
  }
}

#ageConfirmTop .minContainer .BtnTemp.circleBtn a:hover p svg {
  transform: scale(0, 1);
  transform-origin: right top;
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp {
    width: 100%;
    max-width: inherit;
    margin-top: 13.33333vw;
    margin-bottom: 13.33333vw;
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    margin-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp a {
    padding: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.75;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp a p {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a {
    border-radius: 1.33vw;
  }
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a span:after {
    width: 7.46667vw;
    max-width: 56px;
    margin-left: 2.66667vw;
    min-width: 10.56%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a span:after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a span:after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .minContainer .BtnTemp.circleBtn a span:after {
    margin-left: 1.46413vw;
  }
}

#ageConfirmTop .intimateAnnounce {
  background: #F7F5F4;
  padding-top: 3.75vw;
  padding-bottom: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce {
    padding-bottom: 5.85652vw;
  }
}

#ageConfirmTop .intimateAnnounce .animeLinkParent {
  max-width: 1406px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent {
    padding-right: 2.92826vw;
  }
}

#ageConfirmTop .intimateAnnounce .animeLinkParent .img {
  width: 8.875vw;
  max-width: 142px;
  margin-right: 5vw;
}

@media screen and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    width: 23.06667vw;
    max-width: 173px;
    margin-right: 0vw;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .img {
    margin-bottom: 2.92826vw;
  }
}

#ageConfirmTop .intimateAnnounce .animeLinkParent .txt {
  width: 48.75vw;
  max-width: 780px;
}

#ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 1.2px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt {
    width: 100%;
    max-width: 100%;
  }
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 2.1px;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #ageConfirmTop .intimateAnnounce .animeLinkParent .txt p {
    font-size: 3.73333vw;
  }
}

#footerAge {
  position: relative;
  background-color: #fff;
}

#footerAge .copyRightArea {
  position: relative;
  padding-top: 2.1875vw;
  padding-bottom: 2.1875vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media screen and (min-width: 1600px) {
  #footerAge .copyRightArea {
    padding-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRightArea {
    padding-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRightArea {
    padding-top: 2.56223vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .copyRightArea {
    padding-bottom: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRightArea {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRightArea {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .copyRightArea {
    padding-top: 10.66667vw;
    padding-bottom: 6.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .copyRightArea {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRightArea {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRightArea {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .copyRightArea {
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRightArea {
    padding-bottom: 3.28947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRightArea {
    padding-bottom: 3.66032vw;
  }
}

#footerAge .copyRight {
  width: 100%;
  display: block;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1rem;
  font-size: 0.52083vw;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 1px;
  text-align: center;
  color: #4d4d4d;
  margin-top: 1.875vw;
}

@media screen and (min-width: 1920px) {
  #footerAge .copyRight {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRight {
    font-size: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRight {
    font-size: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .copyRight {
    font-size: 1.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .copyRight {
    margin-top: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRight {
    margin-top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRight {
    margin-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .copyRight {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: .6px;
    color: #707070;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footerAge .copyRight {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .copyRight {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .copyRight {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footerAge .copyRight {
    font-size: 2.66667vw;
  }
}

#footerAge .snsLink {
  display: flex;
  width: 50%;
  justify-content: right;
  padding-right: 3.4375vw;
}

@media screen and (min-width: 1600px) {
  #footerAge .snsLink {
    padding-right: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink {
    padding-right: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink {
    padding-right: 4.02635vw;
  }
}

#footerAge .snsLink li {
  width: 2.8125vw;
  max-width: 45px;
  margin-left: 0.9375vw;
  margin-right: 0.9375vw;
}

@media screen and (min-width: 1600px) {
  #footerAge .snsLink li {
    margin-left: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink li {
    margin-left: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink li {
    margin-left: 1.0981vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .snsLink li {
    margin-right: 15px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink li {
    margin-right: 0.98684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink li {
    margin-right: 1.0981vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .snsLink {
    width: 100%;
    justify-content: center;
    padding-right: 0vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .snsLink {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .snsLink {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .snsLink li {
    width: 12vw;
    max-width: 90px;
    margin-left: 2.66667vw;
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .snsLink li {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink li {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink li {
    margin-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .snsLink li {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .snsLink li {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .snsLink li {
    margin-right: 1.46413vw;
  }
}

#footerAge .langBox {
  display: flex;
  align-items: center;
  width: 50%;
}

@media screen and (max-width: 767px) {
  #footerAge .langBox {
    justify-content: center;
    width: 100%;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox {
    margin-bottom: 4.39239vw;
  }
}

#footerAge .langBox span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.48px;
  color: #666;
}

@media screen and (min-width: 1920px) {
  #footerAge .langBox span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox span {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox span {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: 0.3px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footerAge .langBox span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox span {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footerAge .langBox span {
    font-size: 2.66667vw;
  }
}

#footerAge .langBox dl {
  position: relative;
}

#footerAge .langBox dl dt {
  cursor: pointer;
  position: relative;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1.05px;
  color: #666;
  padding-right: 1.875vw;
  padding-left: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #footerAge .langBox dl dt {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dt {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dt {
    padding-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    padding-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    padding-right: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dt {
    font-size: 2rem;
    font-size: 1.04167vw;
    letter-spacing: .75px;
    padding-right: 4vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footerAge .langBox dl dt {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footerAge .langBox dl dt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dt {
    padding-right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    padding-right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    padding-right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dt {
    padding-left: 2.92826vw;
  }
}

#footerAge .langBox dl dt.open::after {
  transform: rotate(-180deg);
}

#footerAge .langBox dl dt::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  background-image: url(/images/common/accordion_icon_01.svg);
  background-position: center right;
  background-size: contain;
  background-repeat: no-repeat;
  transition: .3s;
  backface-visibility: hidden;
  width: 0.75vw;
  max-width: 12px;
  height: 0.375vw;
  max-height: 6px;
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dt::after {
    width: 1.33333vw;
    max-width: 10px;
    height: 0.66667vw;
    max-height: 5px;
  }
}

#footerAge .langBox dl dd {
  position: absolute;
  bottom: 100%;
  width: 100%;
  display: none;
  z-index: 90;
}

#footerAge .langBox dl dd ul {
  background-color: #fff;
  padding-top: 0.375vw;
  padding-bottom: 1vw;
  padding-right: 0.625vw;
  padding-left: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-top: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-top: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-top: 0.43924vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dd ul {
    padding-top: 0.8vw;
    padding-bottom: 2.13333vw;
    padding-right: 1.33333vw;
    padding-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-top: 6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-top: 0.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-top: 0.43924vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-bottom: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd ul {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd ul {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd ul {
    padding-left: 1.46413vw;
  }
}

#footerAge .langBox dl dd li {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  background-color: #fff;
}

@media screen and (min-width: 1920px) {
  #footerAge .langBox dl dd li {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd li {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd li {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dd li {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dd li {
    font-size: 1.6rem;
    font-size: 0.83333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #footerAge .langBox dl dd li {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd li {
    font-size: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd li {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #footerAge .langBox dl dd li {
    font-size: 2.13333vw;
  }
}

#footerAge .langBox dl dd a {
  display: block;
  color: #707070;
  padding-top: 0.875vw;
  padding-bottom: 0.875vw;
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd a {
    padding-top: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd a {
    padding-top: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd a {
    padding-top: 1.02489vw;
  }
}

@media screen and (min-width: 1600px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #footerAge .langBox dl dd a {
    padding-top: 1.86667vw;
    padding-bottom: 1.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd a {
    padding-top: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd a {
    padding-top: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd a {
    padding-top: 1.02489vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 14px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 0.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #footerAge .langBox dl dd a {
    padding-bottom: 1.02489vw;
  }
}

#anniversaryTop .secTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 4.8rem;
  font-size: 2.5vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: 1.92px;
  text-align: center;
  color: #666;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .secTit {
    font-size: 4.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .secTit {
    font-size: 3.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .secTit {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .secTit {
    font-size: 6.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .secTit {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .secTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .secTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .secTit {
    font-size: 5.4rem;
    font-size: 2.8125vw;
    font-weight: 500;
    line-height: 1.78;
    letter-spacing: 0.04em;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .secTit {
    font-size: 5.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .secTit {
    font-size: 3.55263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .secTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .secTit {
    font-size: 7.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .secTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .secTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .secTit {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .gradation {
  background: url(/images/10th_anniversary/gradation_bg.png) center center repeat;
  background-size: cover;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: .8;
}

#anniversaryTop .flower {
  transition: opacity 2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: scale(0.8) translate3d(0, 0, 0);
  opacity: 0;
  position: absolute;
  mix-blend-mode: multiply !important;
}

#anniversaryTop .flower svg {
  width: 100%;
  height: 100%;
}

#anniversaryTop .flower.large {
  width: 9.6875vw;
  max-width: 155px;
  width: 9.4375vw;
  max-width: 151px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .flower.large {
    width: 16.66667vw;
    max-width: 125px;
    width: 16.26667vw;
    max-width: 122px;
  }
}

#anniversaryTop .flower.middle {
  width: 5.125vw;
  max-width: 82px;
  width: 5vw;
  max-width: 80px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .flower.middle {
    width: 10.93333vw;
    max-width: 82px;
    width: 10.66667vw;
    max-width: 80px;
  }
}

#anniversaryTop .flower.small {
  width: 3.8125vw;
  max-width: 61px;
  width: 3.75vw;
  max-width: 60px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .flower.small {
    width: 8.13333vw;
    max-width: 61px;
    width: 8vw;
    max-width: 60px;
  }
}

#anniversaryTop .flower:not(.fvFlower).flowerIn {
  transform: scale(1) translate3d(0, 0, 0);
  opacity: 1;
}

#anniversaryTop .itemAnime {
  position: absolute;
  opacity: 0;
}

#anniversaryTop .anniversaryMain .mainviewTit {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  max-width: 1760px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .mainviewTit {
    padding-left: 2.92826vw;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
  width: 100%;
  text-align: center;
  margin-top: 2.1875vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 10.66667vw;
    height: 26.98%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo {
    margin-top: 5.85652vw;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .mianLogo img {
  margin: 0 auto;
  width: 37.25vw;
  max-width: 596px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .mianLogo img {
    width: 88.93333vw;
    max-width: 667px;
    height: 100%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower1 {
  left: 11.918%;
  top: 41%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower1 {
    left: 55.46%;
    top: 25.07%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower2 {
  left: 6.104%;
  top: 27.87%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower2 {
    left: 51.06%;
    top: 17.11%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower3 {
  left: 23.837%;
  top: 37.59%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower3 {
    left: inherit;
    right: 10.66%;
    top: 80.65%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower4 {
  left: 8.3139%;
  top: 59.62%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower4 {
    left: 52.53%;
    top: 73.73%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower5 {
  left: 28.546%;
  top: 72.22%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower5 {
    left: 83.86%;
    top: 53.85%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower6 {
  right: 13.13%;
  top: 20.64%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower6 {
    right: 67.06%;
    top: 83.11%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower7 {
  right: 10.29%;
  top: 42.68%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .flower7 {
    right: inherit;
    left: 19.6%;
    top: 18.52%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .flower8 {
  right: 5.98%;
  top: 62.59%;
}

#anniversaryTop .anniversaryMain .mainviewTit .flower9 {
  right: 18.66%;
  top: 75.46%;
}

#anniversaryTop .anniversaryMain .mainviewTit .item1 {
  left: 25.58%;
  top: 20.64%;
  width: 11.8125vw;
  max-width: 189px;
  height: 11.8125vw;
  max-height: 189px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item1 {
    width: 20.4vw;
    max-width: 153px;
    height: 20.4vw;
    max-height: 153px;
    left: 29.2%;
    top: 14.3%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item2 {
  left: 15.87%;
  top: 27.77%;
  width: 4.75vw;
  max-width: 76px;
  height: 4.75vw;
  max-height: 76px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item2 {
    width: 10.13333vw;
    max-width: 76px;
    height: 10.13333vw;
    max-height: 76px;
    left: 28.4%;
    top: 30.73%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item3 {
  left: 3.023%;
  top: 40.46%;
  width: 6.75vw;
  max-width: 108px;
  height: 11.5625vw;
  max-height: 185px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item3 {
    width: 14.4vw;
    max-width: 108px;
    height: 24.66667vw;
    max-height: 185px;
    left: 8%;
    top: 26.93%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item4 {
  left: 20.93%;
  top: 52.87%;
  width: 6.5vw;
  max-width: 104px;
  height: 12.5vw;
  max-height: 200px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item4 {
    width: 13.86667vw;
    max-width: 104px;
    height: 26.66667vw;
    max-height: 200px;
    left: 32.93%;
    top: 73.73%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item5 {
  left: 13.895%;
  top: 67.87%;
  width: 5.4375vw;
  max-width: 87px;
  height: 7.3125vw;
  max-height: 117px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item5 {
    width: 11.6vw;
    max-width: 87px;
    height: 15.6vw;
    max-height: 117px;
    left: 10.66%;
    top: 71.42%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item6 {
  left: 20.34%;
  top: 79.53%;
  width: 5.625vw;
  max-width: 90px;
  height: 6.3125vw;
  max-height: 101px;
}

#anniversaryTop .anniversaryMain .mainviewTit .item7 {
  right: 26.51%;
  top: 26.29%;
  width: 3.9375vw;
  max-width: 63px;
  height: 7.625vw;
  max-height: 122px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item7 {
    width: 8.4vw;
    max-width: 63px;
    height: 16.26667vw;
    max-height: 122px;
    right: 18.99%;
    top: 15.99%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item8 {
  right: 2.79%;
  top: 28.33%;
  width: 8.4375vw;
  max-width: 135px;
  height: 8.4375vw;
  max-height: 135px;
}

#anniversaryTop .anniversaryMain .mainviewTit .item9 {
  right: 20.23%;
  top: 44.16%;
  width: 3.9375vw;
  max-width: 63px;
  height: 7.625vw;
  max-height: 122px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item9 {
    width: 11.86667vw;
    max-width: 89px;
    height: 16.13333vw;
    max-height: 121px;
    right: 10.66%;
    top: 30.20%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item10 {
  right: 2.79%;
  top: 52.5%;
  width: 4.5vw;
  max-width: 72px;
  height: 4.5vw;
  max-height: 72px;
}

#anniversaryTop .anniversaryMain .mainviewTit .item11 {
  right: 14.94%;
  top: 58.51%;
  width: 4.25vw;
  max-width: 68px;
  height: 4.875vw;
  max-height: 78px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item11 {
    width: 9.06667vw;
    max-width: 68px;
    height: 10.4vw;
    max-height: 78px;
    right: 10.26%;
    top: 71.13%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item12 {
  right: 25.69%;
  top: 66.20%;
  width: 5.6875vw;
  max-width: 91px;
  height: 7.125vw;
  max-height: 114px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .mainviewTit .item12 {
    width: 12.13333vw;
    max-width: 91px;
    height: 15.2vw;
    max-height: 114px;
    right: 24.13%;
    top: 79.98%;
  }
}

#anniversaryTop .anniversaryMain .mainviewTit .item13 {
  right: 5.98%;
  top: 67.87%;
  width: 15.3125vw;
  max-width: 245px;
  height: 15.3125vw;
  max-height: 245px;
}

#anniversaryTop .anniversaryMain .anniversaryDesc {
  position: relative;
}

#anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
  top: 6vw;
  left: 8.9375vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    top: 96px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    top: 6.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    top: 7.02782vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    left: 143px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    left: 9.40789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower10 {
    left: 10.46852vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
  top: 18.3125vw;
  left: 17.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    top: 293px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    top: 19.27632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    top: 21.44949vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    left: 280px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    left: 18.42105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower11 {
    left: 20.4978vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
  bottom: 6.9375vw;
  right: 7.125vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    bottom: 111px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    bottom: 7.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    bottom: 8.12592vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    right: 114px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    right: 7.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower12 {
    right: 8.34553vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
  bottom: -5vw;
  right: 11vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    bottom: -80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    bottom: -5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    bottom: -5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    right: 176px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    right: 11.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .flower13 {
    right: 12.88433vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .read {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: 0.6px;
  text-align: center;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 2.56;
    letter-spacing: 0.96px;
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .read {
    margin-bottom: 14.64129vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .pageLink {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink {
    justify-content: space-between;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li {
  width: 15.739%;
  background: #fff;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li {
    width: calc(100% / 3 - 1.06vw);
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 0vw;
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li:nth-child(2) {
    margin-right: 0vw;
  }
}

#anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 1.25vw;
  padding-bottom: 1.25vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.32px;
  text-align: center;
  color: #4d4d4d;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    padding-top: 4vw;
    padding-bottom: 4vw;
    line-height: 1.23;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 1.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .anniversaryMain .anniversaryDesc .pageLink li a {
    padding-bottom: 2.19619vw;
  }
}

#anniversaryTop .everyIroha {
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha {
    padding-top: 14.64129vw;
  }
}

#anniversaryTop .everyIroha .messageDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .messageDesc {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .messageDesc {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .messageDesc {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.88;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .messageDesc {
    font-size: 4.26667vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding: 0 40px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-right: 0vw;
    padding-left: 0vw;
    margin-bottom: 10.66667vw;
    padding-bottom: 10.66667vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer:before {
    content: "";
    width: 100%;
    height: 75.76%;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
  width: 42.16%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    width: 100%;
    margin-bottom: 10.66667vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery {
    padding-left: 2.92826vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
  position: absolute;
  z-index: 1;
  top: 17.1875vw;
  width: 1.375vw;
  max-width: 22px;
  height: 2.5625vw;
  max-height: 41px;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 275px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 18.09211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 20.13177vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 22vw;
    width: 2.93333vw;
    max-width: 22px;
    height: 5.46667vw;
    max-height: 41px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 10.85526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow {
    top: 12.07906vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
  left: -3.75vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: -60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: -3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: -4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: -10px;
    transform: translate(-100%, 0);
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: 14.66667vw;
    transform: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.prev-arrow {
    left: 8.05271vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
  right: -2.25vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: -36px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: -2.36842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: -2.63543vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: -10px;
    transform: translate(100%, 0);
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: 14.66667vw;
    transform: none;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .slick-arrow.next-arrow {
    right: 8.05271vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
  padding-right: 1.6875vw;
  overflow: hidden;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 1.97657vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    padding-bottom: 0vw;
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList {
    padding-top: 0vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .img {
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .img {
    width: 50.13333vw;
    max-width: 376px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .img {
    box-shadow: 0.55vw 0.55vw 1.25vw 0px rgba(0, 0, 0, 0.1);
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .img {
    box-shadow: 0.58vw 0.58vw 1.36vw 0px rgba(0, 0, 0, 0.1);
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
  margin-top: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    display: flex;
    align-items: flex-start;
    margin-top: 5.33333vw;
    margin-bottom: 9.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-bottom: 74px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-bottom: 4.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit {
    margin-bottom: 5.41728vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.54px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1;
    margin-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    margin-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    margin-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .name {
    margin-right: 2.92826vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
  margin-top: 0.625vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: 0.54px;
  text-align: left;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 0.73206vw;
  }
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.36;
    margin-top: 0vw;
    flex: 1;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .mainTit .career {
    margin-top: 0vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
  margin-top: 1.25vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.16px;
  text-align: left;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    margin-top: 1.46413vw;
  }
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.71;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .gallery .galleryList .message {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail {
  width: 50%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-list {
    width: 100% !important;
  }
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-track {
    transform: unset !important;
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-track:before {
    content: none !important;
  }
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-track:after {
    width: 26.3543%;
    height: 0;
  }
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide {
    width: 26.3543% !important;
    margin-bottom: 1.875vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide {
    margin-bottom: 30px;
  }
}

@media screen and (min-width: 768px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide {
    margin-bottom: 2.19619vw;
  }
}

@media screen and (min-width: 768px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    cursor: pointer;
    outline: none;
    width: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-left: 2.66667vw;
    padding-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-left: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList {
    padding-right: 1.46413vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList img {
  border: 4px solid rgba(189, 159, 219, 0);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList img {
    border: 0.534vw solid rgba(189, 159, 219, 0);
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1.33;
    letter-spacing: 0.72px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .name {
    font-size: 3.2vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.64;
  letter-spacing: 0.42px;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 2rem;
    font-size: 1.04167vw;
    line-height: 1.15;
    letter-spacing: 0.6px;
    margin-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    margin-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    margin-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide .thumbnailList .career {
    margin-top: 0.73206vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .mainSlideContainer .thumbnail .slick-slide.slick-current .thumbnailList img {
  border: 4px solid #bd9fdb;
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer {
  max-width: 1406px;
  padding: 0 10px;
  margin: auto;
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding: 0 30px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer {
    padding-left: 2.92826vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
  position: relative;
  position: relative;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-left: 2.13333vw;
    padding-right: 2.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-left: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-left: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-left: 1.1713vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-right: 16px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-right: 1.05263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide {
    padding-right: 1.1713vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow {
  position: absolute;
  top: 50%;
  width: 1.375vw;
  max-width: 22px;
  height: 2.5625vw;
  max-height: 41px;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow {
    width: 2.93333vw;
    max-width: 22px;
    height: 5.46667vw;
    max-height: 41px;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
  left: -3.75vw;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
    left: -60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
    left: -3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
    left: -4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
    left: -10px;
    transform: translate(-100%, -50%);
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.prev-arrow {
    left: 0px;
    transform: translate(0%, -50%);
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
  right: -3.75vw;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
    right: -60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
    right: -3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
    right: -4.39239vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
    right: -10px;
    transform: translate(100%, -50%);
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-arrow.next-arrow {
    right: 0px;
    transform: translate(0%, -50%);
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-track {
  display: flex;
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-slide {
  height: auto !important;
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .slick-slide > div {
  height: 100%;
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
  padding: 0 10px;
  height: 100%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-left: 3.2vw;
    padding-right: 3.2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-left: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-left: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-left: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-right: 24px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-right: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList {
    padding-right: 1.75695vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
  background: #fff;
  padding-right: 0.625vw;
  padding-left: 1.25vw;
  padding-top: 1.25vw;
  padding-bottom: 1.25vw;
  height: 100%;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-right: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-right: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-right: 0.73206vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-left: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-left: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-left: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    background: inherit;
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList {
    padding: 0vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
  display: flex;
  align-items: center;
  margin-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox {
    margin-bottom: 2.19619vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox img {
  width: 41.28%;
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
  margin-left: 0.625vw;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 1.33333vw;
    font-size: 2.4rem;
    font-size: 1.25vw;
    line-height: 1.33;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    margin-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 2.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 1.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .nameBox .name {
    font-size: 3.2vw;
  }
}

#anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: 0.16px;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.71;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .everyIroha .slideContainer .subSlideContainer .subSlide .subSlideList .innerList .message {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .AnniversaryProject {
  padding-top: 18.75vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject {
    padding-top: 14.64129vw;
  }
}

#anniversaryTop .AnniversaryProject .secTit {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 26.66667vw;
    font-size: 7rem;
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject .secTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject .secTit {
    font-size: 9.33333vw;
  }
}

#anniversaryTop .AnniversaryProject ul li {
  position: relative;
  z-index: 1;
  padding-top: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 56vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 420px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 27.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-top: 30.74671vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li {
    padding-bottom: 5.85652vw;
  }
}

#anniversaryTop .AnniversaryProject ul li:not(:last-child) {
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.4rem;
  font-size: 1.77083vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 3.75vw;
  padding-left: 3.75vw;
  width: 58.565%;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 3.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 2.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 4.53333vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    padding-left: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 4.8rem;
    font-size: 2.5vw;
    width: 100%;
    margin-bottom: 8vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 4.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 3.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 3.51391vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    font-size: 6.4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .mainTxt {
    margin-top: 4.39239vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#anniversaryTop .AnniversaryProject ul li .flex .innerTxt {
  padding-right: 6.5885%;
  padding-left: 4.39%;
  width: 31.918%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt {
    width: 100%;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.75vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
    line-height: 1.7;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.96px;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex .innerTxt .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/common/arrow_next.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: .5em;
  width: 1.875em;
  height: .6em;
}

#anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.96px;
  line-height: 1.5;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .innerTxt .external {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex .img {
  width: 68.08%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 40.263%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li .flex .img {
    position: absolute;
    top: 0;
    right: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    padding-top: 62.686%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flex .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flex .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flex .img {
    right: -2.92826vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flex .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
}

#anniversaryTop .AnniversaryProject ul li:nth-child(2) .img {
  padding-top: 38.799%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(2) .img {
    padding-top: 60.447%;
  }
}

#anniversaryTop .AnniversaryProject ul li:nth-child(3) .img {
  padding-top: 35.724%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(3) .img {
    padding-top: 55.671%;
  }
}

#anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 600px;
  height: 90%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 5.33333vw;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.92826vw;
  }
}

#anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 600px;
  height: 90%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt:before {
    right: -2.92826vw;
  }
}

#anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

#anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt {
  margin-left: auto;
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .mainTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li:nth-child(even) .img {
    left: -2.92826vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flower14 {
  right: 8.125vw;
  top: -10.625vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    right: 130px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    right: 8.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    right: 9.51684vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    top: -170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    top: -11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower14 {
    top: -12.4451vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flower15 {
  right: 14.25vw;
  top: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    right: 228px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    right: 15vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    right: 16.69107vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower15 {
    top: 1.46413vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flower16 {
  left: 5.625vw;
  top: -18.125vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    top: -290px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    top: -19.07895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower16 {
    top: -21.22987vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flower17 {
  left: 10.9375vw;
  top: -10.625vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    left: 175px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    left: 11.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    left: 12.81113vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    top: -170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    top: -11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower17 {
    top: -12.4451vw;
  }
}

#anniversaryTop .AnniversaryProject ul li .flower18 {
  right: 10.9375vw;
  bottom: -7.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    right: 175px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    right: 11.51316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    right: 12.81113vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    bottom: -120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    bottom: -7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .AnniversaryProject ul li .flower18 {
    bottom: -8.78477vw;
  }
}

#anniversaryTop .brandHistory {
  padding-top: 18.75vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory {
    padding-top: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory {
    padding-top: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory {
    padding-top: 21.96193vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory {
    padding-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory {
    padding-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory {
    padding-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .secTit {
    margin-bottom: 10.66667vw;
    font-size: 7rem;
    font-size: 3.64583vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .secTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .secTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .secTit {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .secTit {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .secTit {
    font-size: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .secTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .secTit {
    font-size: 9.33333vw;
  }
}

#anniversaryTop .brandHistory .messageDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 9.375vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.88;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .messageDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .messageDesc {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .brandHistory .historyGraph {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 24vw;
    position: relative;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph {
    margin-bottom: 13.17716vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper {
  transition: all 1s;
  width: 23.27%;
  z-index: 2;
  position: sticky;
  top: 0;
  mix-blend-mode: multiply;
  transform: translate3d(0px, 0px, 0px);
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper:before {
  content: "";
  background: url(/images/10th_anniversary/side_gradation.png) center left no-repeat;
  background-size: cover;
  width: 3.4375vw;
  max-width: 55px;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper {
    width: 100%;
    top: 10.66667vw;
    position: sticky;
    mix-blend-mode: inherit;
    transform: translate(0, 0) !important;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper {
    top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper {
    top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper {
    top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper:before {
    background: url(/images/10th_anniversary/side_gradation_sp.png) center left no-repeat;
    background-size: cover;
    width: 100vw;
    max-width: 750px;
    height: 30vw;
    max-height: 225px;
    position: absolute;
    z-index: 1;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper:before {
    left: -2.92826vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
  display: flex;
  flex-direction: column;
  padding-left: 1.875vw;
  padding-bottom: 3.75vw;
  padding-top: 3.75vw;
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 0vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 4vw;
    padding-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-top: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index {
    padding-bottom: 2.19619vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
  padding-top: 1.25vw;
  padding-bottom: 1.25vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 1.46413vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.1px;
  color: #ADADAD;
  transition: all .3s;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 2.66667vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
  font-size: 4rem;
  font-size: 2.08333vw;
  color: #808080;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 0vw;
    padding-bottom: 0vw;
    padding-right: 1.33333vw;
    padding-left: 1.33333vw;
    width: calc(100% / 5);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-right: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-right: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-right: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-left: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-left: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li {
    padding-left: 0.73206vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: 1.72;
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .indexWrapper .index li a.current {
    font-size: 4.26667vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList {
  width: 76.73%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    width: 100%;
    display: block;
    margin-top: 10.66667vw;
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList {
    margin-bottom: 0vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dt {
  width: 26.71%;
  padding-bottom: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    width: 100%;
    padding-bottom: 4vw;
    padding-top: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-bottom: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt {
    padding-top: 0.73206vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 9rem;
  font-size: 4.6875vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  text-align: right;
  margin-bottom: 1.25vw;
  color: #666;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 9rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 12vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 9rem;
    font-size: 4.6875vw;
    text-align: left;
    margin-bottom: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 9rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 5.92105vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 6.58858vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    font-size: 12vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .ad {
    margin-bottom: 2.19619vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dt .adDesc {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd {
  width: 69.274%;
  padding-bottom: 7.5vw;
  padding-top: 1.5625vw;
  border-left: 1px solid #ADADAD;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 1.83016vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    width: 100%;
    padding-bottom: 10.66667vw;
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd {
    padding-top: 4.39239vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li {
  padding-left: 16.38%;
  position: relative;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot {
  padding-left: 5.69%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li {
    padding-left: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li {
    padding-left: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li {
    padding-left: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li {
    padding-left: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 7.32064vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
  margin-bottom: 5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(:last-child) {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .notModal {
  display: flex;
  align-items: center;
  color: #4d4d4d;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .notModal .desc {
    flex: 1;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap {
  margin-right: 2.55%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap .img {
  position: relative;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap img {
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap {
    margin-right: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap {
    margin-right: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .imgWrap {
    margin-right: 1.46413vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a {
  display: flex;
  align-items: center;
  color: #4d4d4d;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a {
    flex-wrap: wrap;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
  width: 2.8125vw;
  max-width: 45px;
  height: 2.8125vw;
  max-height: 45px;
  border-radius: 50%;
  background: #fff;
  margin-left: 1.5625vw;
  position: relative;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 25px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 1.64474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 1.83016vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow:before {
  content: "";
  background: url(/images/10th_anniversary/arrow.svg) bottom right no-repeat;
  width: 90%;
  height: 0.6875vw;
  max-height: 11px;
  position: absolute;
  bottom: 45%;
  left: -30%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    width: 6vw;
    max-width: 45px;
    height: 6vw;
    max-height: 45px;
    margin-left: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 32px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow {
    margin-left: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .modelArrow:before {
    width: 6.8vw;
    max-width: 51px;
    height: 1.33333vw;
    max-height: 10px;
    left: -50%;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .img {
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  z-index: 1;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a .img img {
  transition: transform 2s ease-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a:hover .img img {
  transform: scale(1.02) rotate(0.001deg);
  backface-visibility: hidden;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li a:hover .modelArrow:before {
  animation: 1s arrow linear forwards;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .irohaHana {
  width: 4.95%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .irohaHana {
    width: 6.66667vw;
    max-width: 50px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .irohaHana .img {
  position: relative;
  padding-top: 100%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .irohaHana .img img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon {
  position: relative;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon:after {
  content: "";
  position: absolute;
  width: 7.8125vw;
  max-width: 125px;
  height: 5.3125vw;
  max-height: 85px;
  top: 0;
  right: 0;
  transform: translate(100%, -100%);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon:after {
    width: 29.46667vw;
    max-width: 221px;
    height: 18.13333vw;
    max-height: 136px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon1:after {
  background: url(/images/10th_anniversary/balloon/balloon01@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon2:after {
  background: url(/images/10th_anniversary/balloon/balloon02@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon3:after {
  background: url(/images/10th_anniversary/balloon/balloon03@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon4:after {
  background: url(/images/10th_anniversary/balloon/balloon04@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon5:after {
  background: url(/images/10th_anniversary/balloon/balloon05@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon6:after {
  background: url(/images/10th_anniversary/balloon/balloon06@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon7:after {
  background: url(/images/10th_anniversary/balloon/balloon07@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon8:after {
  background: url(/images/10th_anniversary/balloon/balloon08@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon9:after {
  background: url(/images/10th_anniversary/balloon/balloon09@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li .balloon10:after {
  background: url(/images/10th_anniversary/balloon/balloon10@2x.png) center center no-repeat;
  background-size: contain;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 2.4vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.largeCate .desc {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.middleCate .desc {
    font-size: 3.73333vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
  content: "";
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  line-height: 1.66;
  height: 1.66em;
  font-size: 3rem;
  font-size: 1.5625vw;
  position: absolute;
  left: 3.5vw;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 3rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 4vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 56px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 3.68421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 4.09956vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 4.4rem;
    font-size: 2.29167vw;
    left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 4.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 2.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 3.22108vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    font-size: 5.86667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li:not(.dot):before {
    left: 1.46413vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jan:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.feb:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.mar::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.apr:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.may:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jun:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jul:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.aug:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.sep:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.oct::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.nov::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dec::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.375vw;
  max-width: 6px;
  height: 0.375vw;
  max-height: 6px;
  background: #666;
  border-radius: 50%;
  margin: auto 0;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jan:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.feb:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.mar::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.apr:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.may:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jun:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jul:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.aug:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.sep:after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.oct::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.nov::after, #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dec::after {
    width: 1.33333vw;
    max-width: 10px;
    height: 1.33333vw;
    max-height: 10px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0.75vw;
  max-width: 12px;
  height: 0.75vw;
  max-height: 12px;
  background: #666;
  border-radius: 50%;
  margin: auto 0;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dot:before {
    width: 2.4vw;
    max-width: 18px;
    height: 2.4vw;
    max-height: 18px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jan:before {
  content: "01";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.feb:before {
  content: "02";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.mar:before {
  content: "03";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.apr:before {
  content: "04";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.may:before {
  content: "05";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jun:before {
  content: "06";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.jul:before {
  content: "07";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.aug:before {
  content: "08";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.sep:before {
  content: "09";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.oct:before {
  content: "10";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.nov:before {
  content: "11";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd ol li.dec:before {
  content: "12";
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
  margin-top: -1.25vw;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .imgWrap {
  width: 30.36%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .imgWrap {
    width: 29%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .imgWrap {
    width: 35.46667vw;
    max-width: 266px;
  }
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .imgWrap:after {
    right: -25%;
    top: -15%;
    transform: translate(0, -100%);
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .imgWrap .img {
  padding-top: 44.565%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .desc {
    width: 37.33333vw;
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) .modelArrow {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, 100%);
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: -20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: -1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: 13.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: 100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: 6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(1) {
    margin-top: 7.32064vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(2) .imgWrap {
  width: 23.59%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(2) .imgWrap {
    width: 21.86667vw;
    max-width: 164px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2013 li:nth-child(2) .imgWrap .img {
  padding-top: 36.36%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) .imgWrap {
  width: 16.5%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) .imgWrap:after {
  top: 30%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) .imgWrap {
    width: 17.06667vw;
    max-width: 128px;
  }
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) .imgWrap:after {
    top: 15%;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) .imgWrap .img {
  padding-top: 133%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(2) {
    margin-top: 14.64129vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(3) .imgWrap {
  width: 10.23%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(3) .imgWrap {
    width: 12.53333vw;
    max-width: 94px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2014 li:nth-child(3) .imgWrap .img {
  padding-top: 100%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(1) .imgWrap {
  width: 13.36%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(1) .imgWrap {
    width: 14.26667vw;
    max-width: 107px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(1) .imgWrap .img {
  padding-top: 65.43%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(2) .desc {
    letter-spacing: -0.08em;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(2) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .imgWrap {
  width: 37.62%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .imgWrap .img {
  padding-top: 27.19%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .imgWrap {
    width: 33.62%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .imgWrap {
    width: 30.13333vw;
    max-width: 226px;
  }
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .imgWrap .img {
    padding-top: 68.99%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .desc {
    width: 42.66667vw;
    max-width: 320px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2015 li:nth-child(3) .modelArrow {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(0, 100%);
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
  margin-top: -1.25vw;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) .imgWrap {
  width: 5.44%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) .imgWrap:after {
  top: 10%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) .imgWrap {
    width: 7.46667vw;
    max-width: 56px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) .imgWrap .img {
  padding-top: 296.96%;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: -20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: -1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2016 li:nth-child(1) {
    margin-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(1) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .imgWrap {
  width: 16.83%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .imgWrap:after {
  top: 30%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .imgWrap {
    width: 17.6vw;
    max-width: 132px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .imgWrap .img {
  padding-top: 129.41%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .modelArrow {
    position: absolute;
    bottom: -2.66667vw;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .modelArrow {
    bottom: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .modelArrow {
    bottom: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) .modelArrow {
    bottom: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) {
    margin-top: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) {
    margin-top: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) {
    margin-top: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(2) {
    margin-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(3) .desc {
    width: 54.4vw;
    max-width: 408px;
    letter-spacing: -0.03em;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(4) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(5) .imgWrap {
  width: 14.85%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(5) .imgWrap:after {
  top: 30%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(5) .imgWrap .img {
  padding-top: 80%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2017 li:nth-child(5) .imgWrap {
    width: 12.13333vw;
    max-width: 91px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(1) .imgWrap {
  width: 9.57%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(1) .imgWrap .img {
  padding-top: 91.37%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(1) .imgWrap {
    width: 10.13333vw;
    max-width: 76px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(1) .desc {
    width: 44vw;
    max-width: 330px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .imgWrap {
  width: 33.0%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .imgWrap:after {
  top: 20%;
  right: -20%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .imgWrap:after {
    top: -5%;
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .imgWrap {
    width: 36vw;
    max-width: 270px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .imgWrap .img {
  padding-top: 70%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .desc {
    width: 36.66667vw;
    max-width: 275px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .modelArrow {
    position: absolute;
    bottom: -5.33333vw;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .modelArrow {
    bottom: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .modelArrow {
    bottom: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) .modelArrow {
    bottom: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(2) {
    margin-top: 14.64129vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(3) .imgWrap {
  width: 8.91%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(3) .imgWrap:after {
  top: 30%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(3) .imgWrap:after {
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(3) .imgWrap {
    width: 7.2vw;
    max-width: 54px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(3) .imgWrap .img {
  padding-top: 133.33%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(4) .imgWrap {
  width: 11.71%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(4) .imgWrap {
    width: 9.46667vw;
    max-width: 71px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2018 li:nth-child(4) .imgWrap .img {
  padding-top: 76.05%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
  padding-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(1) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap {
  width: 33.33%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap:after {
  top: 30%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap:after {
    top: 0;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap .img {
  padding-top: 63.86%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap {
    width: 28.26667vw;
    max-width: 212px;
  }
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .imgWrap .img {
    padding-top: 79.26%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .desc {
    width: 44.4vw;
    max-width: 333px;
    letter-spacing: -0.08em;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .modelArrow {
    position: absolute;
    bottom: -2.66667vw;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .modelArrow {
    bottom: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .modelArrow {
    bottom: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) .modelArrow {
    bottom: -1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(2) {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(3) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(4) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(5) .imgWrap {
  width: 23.59%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(5) .imgWrap {
    width: 21.06667vw;
    max-width: 158px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(5) .imgWrap .img {
  padding-top: 36.36%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(6) .imgWrap {
  width: 6.93%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(6) .imgWrap {
    width: 6.8vw;
    max-width: 51px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(6) .imgWrap .img {
  padding-top: 142.85%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(6) .desc {
    width: 54.4vw;
    max-width: 408px;
    letter-spacing: -0.02em;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(7) .imgWrap {
  width: 5.28%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(7) .imgWrap {
    width: 5.2vw;
    max-width: 39px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(7) .imgWrap .img {
  padding-top: 187.5%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(8) .desc {
    letter-spacing: -0.05em;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(8) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) .imgWrap {
  width: 15.67%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) .imgWrap:after {
  top: 20%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) .imgWrap {
    width: 16vw;
    max-width: 120px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) .imgWrap .img {
  padding-top: 133.68%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) .desc {
    width: 44.93333vw;
    max-width: 337px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(9) {
    margin-top: 14.64129vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) .imgWrap {
  width: 33%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) .imgWrap {
    width: 35.6vw;
    max-width: 267px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) .imgWrap .img {
  padding-top: 70%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) .desc {
    width: 37.33333vw;
    max-width: 280px;
    letter-spacing: -.05em;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) .modelArrow {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2019 li:nth-child(10) {
    margin-top: 14.64129vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
  margin-top: -2.5vw;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) .imgWrap {
  width: 6.6%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) .imgWrap {
    width: 7.33333vw;
    max-width: 55px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) .imgWrap .img {
  padding-top: 310%;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: -2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(1) {
    margin-top: 10.2489vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(2) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(3) .imgWrap {
  width: 23.59%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(3) .imgWrap {
    width: 20.93333vw;
    max-width: 157px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(3) .imgWrap .img {
  padding-top: 36.36%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(4) .imgWrap {
  width: 4.62%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(4) .imgWrap .img {
  padding-top: 214.28%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(4) .imgWrap {
    width: 4.66667vw;
    max-width: 35px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(4) .desc {
    width: 45.2vw;
    max-width: 339px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(5) .imgWrap {
  width: 4.62%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(5) .imgWrap {
    width: 5.33333vw;
    max-width: 40px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2020 li:nth-child(5) .imgWrap .img {
  padding-top: 193.1%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(1) .imgWrap {
  width: 8.08%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(1) .imgWrap .img {
  padding-top: 100%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(1) .imgWrap {
    width: 7.6vw;
    max-width: 57px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
  margin-top: 6.25vw;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .imgWrap {
  width: 34.48%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .imgWrap:after {
  right: 20%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .imgWrap .img {
  padding-top: 30.14%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .imgWrap {
    width: 22.13333vw;
    max-width: 166px;
  }
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .imgWrap .img {
    padding-top: 97.03%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .desc {
    width: 50.8vw;
    max-width: 381px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .modelArrow {
    position: absolute;
    bottom: -2.66667vw;
    right: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .modelArrow {
    bottom: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .modelArrow {
    bottom: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) .modelArrow {
    bottom: -1.46413vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(2) {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(3) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(4) .imgWrap {
  width: 4.62%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(4) .imgWrap .img {
  padding-top: 185.18%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(4) .imgWrap {
    width: 5.33333vw;
    max-width: 40px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(4) .desc {
    width: 41.6vw;
    max-width: 312px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(5) .imgWrap {
  width: 34.48%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(5) .imgWrap .img {
  padding-top: 66.66%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(5) .imgWrap {
    width: 35.46667vw;
    max-width: 266px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(5) .desc {
    width: 37.33333vw;
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(5) .modelArrow {
    margin-left: auto;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(6) .imgWrap {
  width: 4.62%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(6) .imgWrap .img {
  padding-top: 185.18%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(6) .imgWrap {
    width: 5.33333vw;
    max-width: 40px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2021 li:nth-child(6) .desc {
    width: 45.2vw;
    max-width: 339px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(1) .imgWrap {
  width: 10.06%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(1) .imgWrap .img {
  padding-top: 81.96%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(1) .imgWrap {
    width: 11.73333vw;
    max-width: 88px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(2) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
  right: -400%;
  top: -10%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    top: -2.66667vw;
    right: inherit;
    left: 41.33333vw;
    transform: translate(0, -100%);
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    top: -20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    top: -1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    top: -1.46413vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    left: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    left: 20.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .imgWrap:after {
    left: 22.694vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(3) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
  margin-top: 6.25vw;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .imgWrap {
  width: 33%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .imgWrap .img {
  padding-top: 70%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .imgWrap:after {
  top: 10%;
  right: -10%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .imgWrap:after {
    top: -10%;
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .imgWrap {
    width: 36vw;
    max-width: 270px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .desc {
    flex: 1;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .modelArrow {
    position: absolute;
    right: 0;
    bottom: -6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .modelArrow {
    bottom: -45px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .modelArrow {
    bottom: -2.96053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(4) .modelArrow {
    bottom: -3.29429vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(5) .desc {
    width: 54.4vw;
    max-width: 408px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(6) .imgWrap {
  width: 10.23%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(6) .imgWrap .img {
  padding-top: 87.09%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(6) .imgWrap {
    width: 11.73333vw;
    max-width: 88px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(7) .imgWrap {
  width: 9.9%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(7) .imgWrap .img {
  padding-top: 101.66%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(7) .imgWrap {
    width: 21.86667vw;
    max-width: 164px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(8) .imgWrap {
  width: 39.76%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(8) .imgWrap .img {
  padding-top: 58.09%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(8) .imgWrap {
    width: 31.46667vw;
    max-width: 236px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2022 li:nth-child(8) .modelArrow {
    margin-left: auto;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
  margin-top: -2.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: -2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: -2.92826vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) .imgWrap {
  width: 17.65%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) .imgWrap .img {
  padding-top: 124.29%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) .imgWrap {
    width: 17.6vw;
    max-width: 132px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) .desc {
    width: 38vw;
    max-width: 285px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(1) {
    margin-top: 0vw;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap {
  width: 28.8%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap .img {
  padding-top: 59.5%;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap {
    width: 26.8%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap {
    width: 35.46667vw;
    max-width: 266px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap:after {
  top: 10%;
  right: -10%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .imgWrap:after {
    top: -10%;
    right: 0;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .desc {
    width: 37.33333vw;
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(2) .modelArrow {
    margin-left: auto;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .imgWrap {
  width: 33%;
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .imgWrap .img {
  padding-top: 49.5%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .imgWrap {
    width: 35.46667vw;
    max-width: 266px;
  }
}

#anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .imgWrap:after {
  top: 10%;
  right: -10%;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .imgWrap:after {
    top: 0;
    right: 50%;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .desc {
    width: 37.33333vw;
    max-width: 280px;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) {
    margin-top: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) {
    margin-top: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) {
    margin-top: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) {
    margin-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyGraph .historyList dd.sec2023 li:nth-child(3) .modelArrow {
    margin-left: auto;
  }
}

#anniversaryTop .brandHistory .historyFinish {
  margin: 0 auto;
  width: 35.8125vw;
  max-width: 573px;
  position: relative;
}

#anniversaryTop .brandHistory .historyFinish img {
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish {
    width: 100%;
    max-width: 100%;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower19 {
  left: -11.6875vw;
  bottom: -3.125vw;
  transition: opacity 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: -187px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: -12.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: -13.6896vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: 22vw;
    bottom: -27.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: 165px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: 10.85526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    left: 12.07906vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -205px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -13.48684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower19 {
    bottom: -15.00732vw;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower20 {
  left: 0.375vw;
  bottom: -6.25vw;
  transition: opacity 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 6px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 0.39474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 0.43924vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 10.13333vw;
    bottom: -10.93333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 76px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    left: 5.56369vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -82px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -5.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower20 {
    bottom: -6.00293vw;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower21 {
  left: -4.0625vw;
  top: -2.1875vw;
  transition: opacity 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: -65px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: -4.27632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: -4.75842vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: -35px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: -2.30263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: -2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: 46.66667vw;
    top: 35.6vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: 350px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: 23.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    left: 25.62225vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: 267px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: 17.56579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower21 {
    top: 19.54612vw;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower22 {
  right: -3.8125vw;
  top: -7.4375vw;
  transition: opacity 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: -61px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: -4.01316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: -4.46559vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -119px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -7.82895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -8.71157vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: 18.8vw;
    top: -21.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: 141px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: 9.27632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    right: 10.32211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -161px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -10.59211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower22 {
    top: -11.78624vw;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower23 {
  right: -8.4375vw;
  top: 3.125vw;
  transition: opacity 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: -135px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: -8.88158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: -9.88287vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: 4vw;
    top: -8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    right: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: -60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: -3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower23 {
    top: -4.39239vw;
  }
}

#anniversaryTop .brandHistory .historyFinish .flower24 {
  right: -3.8125vw;
  top: 9.625vw;
  transition: opacity 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: -61px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: -4.01316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: -4.46559vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: 154px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: 10.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: 11.27379vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: 41.33333vw;
    top: -14.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: 310px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: 20.39474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    right: 22.694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: -107px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: -7.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .brandHistory .historyFinish .flower24 {
    top: -7.83309vw;
  }
}

#anniversaryTop .detailModalContent {
  width: 100%;
  height: 100%;
  margin: 0;
  min-height: 100%;
  background: #fff;
  position: fixed;
  display: none;
  z-index: 100000;
  display: none;
}

#anniversaryTop .detailModalContent .close {
  position: absolute;
  top: 4.375vw;
  right: 6.25vw;
  z-index: 100;
  opacity: 1;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .close {
    top: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .close {
    top: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .close {
    top: 5.12445vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .close {
    right: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .close {
    right: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .close {
    right: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .close {
    top: 5.33333vw;
    right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .close {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .close {
    top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .close {
    top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .close {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .close {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .close {
    right: 2.92826vw;
  }
}

#anniversaryTop .detailModalContent .close a {
  display: block;
  text-indent: -9999px;
  width: 3.75vw;
  max-width: 60px;
  height: 3.75vw;
  max-height: 60px;
  background: url(/images/common/modal_close_btn.svg) no-repeat;
  background-size: contain;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .close a {
    width: 6.4vw;
    max-width: 48px;
    height: 6.4vw;
    max-height: 48px;
    background-size: contain;
  }
}

#anniversaryTop .detailModalContent .modalInner {
  overflow-y: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  padding-top: 9.375vw;
  padding-bottom: 9.375vw;
  height: 100vh;
  background: #fff;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 10.98097vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 150px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 9.86842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 30.66667vw;
    padding-bottom: 30.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 15.13158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-top: 16.83748vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 230px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 15.13158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner {
    padding-bottom: 16.83748vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer {
  max-width: 802px;
  padding: 0 20px;
  margin: auto;
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-left: 5.33333vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer {
    padding-right: 2.92826vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.6px;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    margin-bottom: 10.66667vw;
    line-height: 1.5;
    letter-spacing: 1.6px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalTit {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer img {
  margin-bottom: 3.125vw;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 10.66667vw;
    width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer img {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 5vw;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.71;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDesc {
    margin-bottom: 5.85652vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  margin-top: 2.5vw;
  text-align: right;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    text-align: left;
    font-size: 2.8rem;
    font-size: 1.45833vw;
    line-height: 1.71;
    margin-top: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .modalDescStamp {
    margin-top: 2.92826vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap {
  text-align: right;
}

#anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .linkWrap:not(:last-of-type) {
    margin-bottom: 2.92826vw;
  }
}

#anniversaryTop .detailModalContent .modalInner .minContainer .external {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.16px;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #anniversaryTop .detailModalContent .modalInner .minContainer .external {
    font-size: 3.73333vw;
  }
}

/* ==============================================
水原 希子特設ページ
============================================== */
#mizuharaTop .mainviewModal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s, visibility 2s;
  backface-visibility: hidden;
}

#mizuharaTop .mainviewModal.isPlay {
  opacity: 1;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mainviewModal {
    padding-top: 8vw;
    padding-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal {
    padding-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal {
    padding-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal {
    padding-top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal {
    padding-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal {
    padding-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal {
    padding-bottom: 4.39239vw;
  }
}

#mizuharaTop .mainviewModal .mainviewModalClose {
  position: absolute;
  cursor: pointer;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
}

#mizuharaTop .mainviewModal .mainviewModalClose p {
  position: absolute;
  top: 4.75vw;
  right: 2.5vw;
  width: 5.0625vw;
  max-width: 81px;
  height: 3.625vw;
  max-height: 58px;
  padding: 0.625vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 76px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 5.56369vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 0.73206vw;
  }
}

#mizuharaTop .mainviewModal .mainviewModalClose p img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 6.25vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 100px;
  }
}

@media screen and (min-width: 1920px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 6.57895vw;
  }
}

@media screen and (min-width: 1920px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 7.32064vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 1.875vw;
    right: 2.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 30px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 2.19619vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 5.33333vw;
    right: 5.33333vw;
    width: 10.8vw;
    max-width: 81px;
    height: 7.73333vw;
    max-height: 58px;
    padding: 1.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalClose p {
    padding: 0.73206vw;
  }
}

#mizuharaTop .mainviewModal .mainviewModalVideoBox {
  max-width: 1320px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  background-color: #fff;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mainviewModal .mainviewModalVideoBox {
    max-width: 1100px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mainviewModal .mainviewModalVideoBox {
    max-width: 1100px;
  }
}

#mizuharaTop .mainviewModal .mainviewModalVideo {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background-color: #fff;
}

#mizuharaTop .mainviewModal .mainviewModalVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#mizuharaTop .mizuhara {
  position: relative;
  z-index: 1;
  background-color: rgba(253, 251, 249, 0.8);
  transition: 3s cubic-bezier(0.08, 0.92, 0.35, 1);
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara {
    padding-top: 8.78477vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1520px) {
  #mizuharaTop .mizuhara {
    padding-top: 7.5vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara {
    padding-top: 16.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara {
    padding-top: 125px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara {
    padding-top: 8.22368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara {
    padding-top: 9.15081vw;
  }
}

#mizuharaTop .mizuhara .btn {
  width: 15.4375vw;
  max-width: 247px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .btn {
    margin: 0 auto;
    width: 73.33333vw;
    max-width: 550px;
  }
}

#mizuharaTop .mizuhara .btn a {
  display: block;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.28px;
  text-align: center;
  color: #4d4d4d;
  border: solid 1px #ebccb7;
  border-radius: 3px;
  transition: all .3s;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 24px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 1.75695vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 24px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.56px;
    padding-top: 6.13333vw;
    padding-bottom: 6.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .btn a {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 46px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 3.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    padding-top: 3.3675vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 46px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 3.02632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a {
    padding-bottom: 3.3675vw;
  }
}

#mizuharaTop .mizuhara .btn a:hover {
  opacity: .8;
}

#mizuharaTop .mizuhara .btn a span {
  display: flex;
  align-items: center;
  justify-content: center;
}

#mizuharaTop .mizuhara .btn a span:after {
  content: "";
  flex-shrink: 0;
  display: block;
  background-image: url(/images/common/blank_icon.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  width: 0.875vw;
  max-width: 14px;
  height: 0.75vw;
  max-height: 12px;
  margin-left: 0.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .btn a span:after {
    width: 4.4vw;
    max-width: 33px;
    height: 4vw;
    max-height: 30px;
    margin-left: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .btn a span:after {
    margin-left: 1.46413vw;
  }
}

#mizuharaTop .mizuhara .mainviewArea {
  cursor: pointer;
  transition: all .3s;
}

#mizuharaTop .mizuhara .mainviewArea:hover {
  opacity: .9;
}

#mizuharaTop .mizuhara .mainviewArea img {
  width: 100%;
}

#mizuharaTop .mizuhara .mizuharaMainArea {
  padding-top: 10vw;
  padding-bottom: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 11.71303vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 21.33333vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea {
    padding-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
  max-width: 1040px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .irohaMaiLink {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  line-height: 3;
  letter-spacing: 0.6px;
  color: #4d4d4d;
  padding-bottom: 9.75vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 2.66667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 156px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 10.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 11.4202vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    font-weight: 500;
    line-height: 2.56;
    letter-spacing: .64px;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
    padding-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-left: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .mizuharaMainAreaLead {
    padding-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox {
    display: block;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
  order: 2;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 3.66032vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox .img {
  margin: 0 auto 0 0;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-right: 0vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .imgBox .img {
    margin: 0 0 0 auto;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
  order: 1;
  padding-right: 2.5vw;
  padding-left: 0vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 0vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 0vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox .interviewTit,
#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox .interviewTxt {
  margin-right: 0;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-top: 10.66667vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox:nth-of-type(2n) .txtBox {
    padding-left: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
  max-width: 50%;
  width: 100%;
  flex-shrink: 0;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 2.92826vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox .img {
  width: 100%;
  margin: 0 0 0 auto;
  box-shadow: 10px 10px 20px 0 #00000029;
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox .img img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    max-width: 100%;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .imgBox .img {
    box-shadow: 5px 5px 10px 0 #00000029;
    margin: 0 auto 0 0;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
  max-width: 50%;
  width: 100%;
  padding-left: 3.75vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    max-width: 100%;
    padding-top: 10.66667vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .txtBox {
    padding-left: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTit {
    margin-bottom: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 2rem;
  font-size: 1.04167vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 3.2rem;
    font-size: 1.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox .interviewTxt {
    font-size: 4.26667vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
  margin-bottom: 10.3125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 165px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 10.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 12.07906vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .img {
  max-width: 100%;
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTit,
#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTxt {
  max-width: 60.45%;
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTxt {
    max-width: 79%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTxt {
    max-width: 90%;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox01 .interviewTxt {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
  margin-bottom: 5.25vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 84px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 5.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 6.14934vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .img {
  max-width: 80.575%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .img {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTit,
#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTxt {
  max-width: 54%;
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTxt {
    max-width: 72%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTxt {
    max-width: 90%;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox02 .interviewTxt {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .img {
  max-width: 69.784%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .img {
    max-width: 77.465%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTit,
#mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTxt {
  max-width: 55%;
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTxt {
    max-width: 72%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTxt {
    max-width: 90%;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTit,
  #mizuharaTop .mizuhara .mizuharaMainArea .interviewTxtBox03 .interviewTxt {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .flex {
    display: block;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
  background-color: #fff;
  padding: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-top: 21.33333vw;
    padding-bottom: 21.33333vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .boxLayout {
    padding-left: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo {
  max-width: 50%;
  width: 100%;
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .inner {
  max-width: 588px;
  width: 100%;
  margin: 0 0 0 auto;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo {
    max-width: 53%;
  }
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .inner {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo {
    max-width: 53%;
  }
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .inner {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo {
    max-width: 94.4%;
  }
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .inner {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: 0.64px;
  color: #4d4d4d;
  text-align: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 2;
    letter-spacing: 0.4px;
    text-align: left;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit {
    margin-bottom: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTit .space {
    display: inline !important;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  font-weight: 500;
  line-height: 1.86;
  letter-spacing: 0.56px;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 5.375vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 86px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 5.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 6.29575vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 3.6rem;
    font-size: 1.875vw;
    line-height: 2;
    letter-spacing: 0.36px;
    margin-bottom: 14.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 106px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 6.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoSubTit {
    margin-bottom: 7.75988vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  padding-right: 0.8125vw;
  padding-left: 0.8125vw;
  margin-bottom: 5.375vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 86px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 5.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 6.29575vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    padding-right: 0vw;
    padding-left: 0vw;
    margin-bottom: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .otherInfoTxt {
    margin-bottom: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
  display: flex;
  justify-content: center;
  padding-right: 0.8125vw;
  padding-left: 0.8125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 0.95168vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
  margin-right: 3.625vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-right: 58px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-right: 3.81579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-right: 4.24597vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    display: block;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .otherInfo .btnLayout .btn:last-of-type {
    margin-bottom: 0;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile {
  max-width: 49.4792%;
  width: 100%;
  margin-top: 9.75vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 156px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 10.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 11.4202vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile .inner {
  max-width: 76.667%;
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    max-width: 46.4792%;
  }
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .inner {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    max-width: 46.4792%;
  }
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .inner {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    max-width: 94.4%;
    margin: 0 0 0 auto;
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile {
    margin-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .inner {
    max-width: 100%;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  line-height: 1.72;
  letter-spacing: 1.76px;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 4rem;
    font-size: 2.08333vw;
    line-height: 1.38;
    letter-spacing: 1.1px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTit {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 1.78;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 2.4vw;
  }
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt .mincho {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

#mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt a {
  color: #4d4d4d;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 2;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .mizuharaMainArea .profile .profileTxt {
    font-size: 4vw;
  }
}

#mizuharaTop .mizuhara .ripples {
  display: none;
  background: #aaa;
  opacity: .1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

#mizuharaTop .mizuhara .linkBnrArea {
  display: flex;
  justify-content: center;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 3.66032vw;
  }
}

#mizuharaTop .mizuhara .linkBnrArea .bnr {
  width: 37.5vw;
  max-width: 600px;
  margin-right: 1.875vw;
  margin-left: 1.875vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-right: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-right: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-right: 2.19619vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-left: 30px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-left: 1.97368vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-left: 2.19619vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    display: block;
    margin-bottom: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .linkBnrArea {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin: 0 auto;
    width: 80vw;
    max-width: 600px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .linkBnrArea .bnr:last-of-type {
    margin-bottom: 0;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea {
  position: relative;
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    line-height: 1.43;
    letter-spacing: 2.45px;
    padding-top: 21.33333vw;
    margin-bottom: 20vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 150px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 9.86842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieAreaTit {
    margin-bottom: 10.98097vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovieBox {
    padding: 0;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
  position: relative;
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie:hover {
  cursor: pointer;
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie:hover::after {
  opacity: .3;
  visibility: visible;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiMovieArea .irohaMaiMovie video {
  max-width: 100%;
  width: 100%;
}

#mizuharaTop .mizuhara .irohaMaiArea ul {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul {
    margin-bottom: 14.64129vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li {
  position: relative;
  z-index: 1;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 56vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 420px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 27.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-top: 30.74671vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li {
    padding-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 3.125vw;
  padding-left: 3.75vw;
  width: 58.565%;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    padding-left: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    width: 100%;
    margin-bottom: 8vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .mainTxt {
    margin-top: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt {
  padding-right: 5.49%;
  padding-left: 4.39%;
  width: 36.311%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
    line-height: 1.7;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
  display: block;
  margin: 0 auto;
  color: #4D4D4D;
  padding-top: 0.8125vw;
  padding-bottom: 0.8125vw;
  padding-left: 1.6875vw;
  padding-right: 1.6875vw;
  border: 1px solid #769DCD;
  border-radius: 3px;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-left: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.97657vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-right: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.97657vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn:hover {
  cursor: pointer;
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.13333vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span:before {
  content: "";
  background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.2em;
  height: .9em;
  display: inline-block;
  padding-right: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.13333vw;
    padding-bottom: 4.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.2694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.2694vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .innerTxt #audioBtn span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img {
  width: 63.689%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 40.263%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img {
    position: absolute;
    top: 0;
    right: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    padding-top: 63.2179%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img {
    right: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li .flex .img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 500px;
  height: 75%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .mainTxt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 5.33333vw;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(3) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 560px;
  height: 85%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(3) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(3) .mainTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(3) .mainTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(3) .mainTxt:before {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 500px;
  height: 75%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt:before {
    right: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex {
  flex-direction: row-reverse;
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
  padding-left: 5.49%;
  padding-right: 4.39%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt {
  margin-left: auto;
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .mainTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea ul li:nth-child(even) .img {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 36.875vw;
  max-height: 590px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    background: #fff;
    height: inherit;
    max-height: inherit;
    padding-top: 5.33333vw;
    padding-right: 4.66667vw;
    padding-left: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner {
    padding-left: 2.56223vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img {
  width: 50%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img:hover {
  cursor: pointer;
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img:hover::after {
  opacity: .3;
  visibility: visible;
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
  width: 50%;
  background: #fff;
  height: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 5.85652vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 80px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 5.26316vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.04em;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 2.2;
  letter-spacing: 0.03em;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .mizuharaComment .inner .txt .read {
    font-size: 4.26667vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard {
  width: 46.33%;
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
  margin-top: 10.625vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 12.4451vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard {
    width: 100%;
  }
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .img, #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard img {
  width: 100%;
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
  background: #fff;
  padding-top: 3.75vw;
  padding-bottom: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt {
    padding: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
  text-align: center;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 2;
  margin-bottom: 3.75vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
  display: block;
  border: 1px solid #769DCD;
  width: 15.4375vw;
  max-width: 247px;
  text-align: center;
  padding-top: 1.125vw;
  padding-bottom: 1.125vw;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 1.31772vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a:hover {
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 1.86667vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  width: 0.9em;
  height: 0.8em;
  display: inline-block;
  background-size: contain;
  margin-left: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .postList .inner .postCard .txt a span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
  margin-bottom: 18.75vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 21.96193vw;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .bnrBox img {
  margin: 0 auto;
  width: 65vw;
  max-width: 1040px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .bnrBox img {
    width: 100%;
    max-width: inherit;
  }
}

#mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    line-height: 1;
    letter-spacing: 2.1px;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaMaiArea .irohaMaiAreaTit {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea {
  position: relative;
}

#mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    line-height: 1.43;
    letter-spacing: 2.45px;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieAreaTit {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovieBox {
    padding: 0;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
  position: relative;
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaPetitArea .irohaPetitMovieArea .irohaPetitMovie img {
  max-width: 100%;
  width: 100%;
}

#mizuharaTop .mizuhara .irohaPetitArea ul {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul {
    margin-bottom: 14.64129vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li {
  position: relative;
  z-index: 1;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 56vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 420px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 27.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-top: 30.74671vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li {
    padding-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 3.125vw;
  padding-left: 3.75vw;
  width: 58.565%;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    padding-left: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    padding-left: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    padding-left: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    width: 100%;
    margin-bottom: 8vw;
    margin-top: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-top: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .mainTxt {
    margin-top: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt {
  padding-right: 5.49%;
  padding-left: 4.39%;
  width: 36.311%;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
    line-height: 1.7;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
  display: block;
  margin: 0 auto;
  color: #4D4D4D;
  padding-top: 0.8125vw;
  padding-bottom: 0.8125vw;
  padding-left: 1.6875vw;
  padding-right: 1.6875vw;
  border: 1px solid #769DCD;
  border-radius: 3px;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-left: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.97657vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-right: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.97657vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn:hover {
  cursor: pointer;
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.13333vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span:before {
  content: "";
  background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.2em;
  height: .9em;
  display: inline-block;
  padding-right: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.13333vw;
    padding-bottom: 4.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.2694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.2694vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .innerTxt #audioBtn span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img {
  width: 63.689%;
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-top: 40.263%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img {
    position: absolute;
    top: 0;
    right: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    padding-top: 63.2179%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img {
    right: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li .flex .img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 500px;
  height: 75%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
    padding-right: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .mainTxt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 5.33333vw;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(3) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 560px;
  height: 85%;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(3) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(3) .mainTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(3) .mainTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(3) .mainTxt:before {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt:before {
  content: "";
  width: 58.565%;
  max-height: 500px;
  height: 75%;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt:before {
    right: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex {
  flex-direction: row-reverse;
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
  padding-left: 5.49%;
  padding-right: 4.39%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 0vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt {
  margin-left: auto;
  margin-right: 0;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt {
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .mainTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .img {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .img {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .img {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea ul li:nth-child(even) .img {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 36.875vw;
  max-height: 590px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    background: #fff;
    height: inherit;
    max-height: inherit;
    padding-top: 5.33333vw;
    padding-right: 4.66667vw;
    padding-left: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner {
    padding-left: 2.56223vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img {
  width: 50%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img:hover {
  cursor: pointer;
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img:hover::after {
  opacity: .3;
  visibility: visible;
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
  width: 50%;
  background: #fff;
  height: 100%;
  padding-left: 5.625vw;
  padding-right: 5.625vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 6.58858vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.04em;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 2.2;
  letter-spacing: 0.03em;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .mizuharaComment .inner .txt .read {
    font-size: 4.26667vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList {
    margin-bottom: 14.64129vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard {
  width: 46.33%;
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
  margin-top: 10.625vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 12.4451vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard {
    width: 100%;
  }
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .img, #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard img {
  width: 100%;
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
  background: #fff;
  padding-top: 3.75vw;
  padding-bottom: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt {
    padding: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
  text-align: center;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 2;
  margin-bottom: 3.75vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
  display: block;
  border: 1px solid #769DCD;
  width: 15.4375vw;
  max-width: 247px;
  text-align: center;
  padding-top: 1.125vw;
  padding-bottom: 1.125vw;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 1.31772vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a:hover {
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 1.86667vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  width: 0.9em;
  height: 0.8em;
  display: inline-block;
  background-size: contain;
  margin-left: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .postList .inner .postCard .txt a span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
  margin-bottom: 18.75vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 21.96193vw;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .bnrBox img {
  margin: 0 auto;
  width: 65vw;
  max-width: 1040px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .bnrBox img {
    width: 100%;
    max-width: inherit;
  }
}

#mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    line-height: 1;
    letter-spacing: 2.1px;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaPetitArea .irohaPetitAreaTit {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea {
  position: relative;
}

#mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    line-height: 1.43;
    letter-spacing: 2.45px;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieAreaTit {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovieBox {
    padding: 0;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
  position: relative;
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaProductsArea .irohaProductsMovieArea .irohaProductsMovie img {
  max-width: 100%;
  width: 100%;
}

#mizuharaTop .mizuhara .irohaProductsArea ul {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul {
    margin-bottom: 14.64129vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li {
  position: relative;
  z-index: 1;
  padding-top: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-top: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-top: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-top: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-top: 66.99%;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li {
    padding-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
  margin-bottom: 10vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:not(:last-child) {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 4rem;
    font-size: 2.08333vw;
    width: 100%;
    margin-bottom: 8vw;
    margin-top: 9.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    font-size: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-top: 70px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-top: 4.60526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .mainTxt {
    margin-top: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .new {
  display: inline-block;
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  text-align: center;
  border-radius: 25px;
  padding: 3px 25px 2px;
  border: solid 1px #F58D7E;
  color: #F58D7E;
  margin-bottom: 0.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 0.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 2.6rem;
    font-size: 1.35417vw;
    line-height: 1;
    padding-top: 1.33333vw;
    padding-bottom: 1.06667vw;
    padding-left: 4.66667vw;
    padding-right: 4.66667vw;
    margin-bottom: 2vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 1.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 1.90337vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    font-size: 3.46667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-top: 10px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-top: 0.65789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-top: 0.73206vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-bottom: 8px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-bottom: 0.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-bottom: 0.58565vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-left: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    padding-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 0.98684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .new {
    margin-bottom: 1.0981vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .link {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.96px;
  text-align: right;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .link a {
  color: #4d4d4d;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow {
  display: inline-block;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/index/products_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 40px;
  height: 10px;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    width: 30px;
    height: 8px;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    width: 30px;
    height: 8px;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .link .arrow::after {
    margin-left: 2.19619vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex {
  display: flex;
  flex-wrap: wrap;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
  position: relative;
  padding-right: 5.49%;
  padding-left: 4.39%;
  width: 36.311%;
  text-align: right;
  padding-top: 7.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 8.78477vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    position: static;
    width: 100%;
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt {
    padding-top: 0vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.3;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
    line-height: 1.7;
    letter-spacing: 1.12px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt .descTxt {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
  display: block;
  margin: 0 auto;
  color: #4D4D4D;
  padding-top: 0.8125vw;
  padding-bottom: 0.8125vw;
  padding-left: 1.6875vw;
  padding-right: 1.6875vw;
  border: 1px solid #769DCD;
  border-radius: 3px;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.85526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 0.95168vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-left: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-left: 1.97657vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-right: 27px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.77632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-right: 1.97657vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn:hover {
  cursor: pointer;
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.13333vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span:before {
  content: "";
  background: url(/images/kiko_mizuhara/audio_icon.svg) center center no-repeat;
  background-size: contain;
  width: 1.2em;
  height: .9em;
  display: inline-block;
  padding-right: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.13333vw;
    padding-bottom: 4.13333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-top: 2.2694vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 31px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.03947vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn {
    padding-bottom: 2.2694vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .innerTxt #audioBtn span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox {
  width: 63.689%;
  position: relative;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox .img {
  box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox {
    position: absolute;
    top: 0;
    right: -5.33333vw;
    width: 94.66667vw;
    max-width: 710px;
    padding-top: 66.99%;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox {
    right: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox .img {
    box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li .flex .imgBox img {
  width: 100%;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
  content: "";
  width: 158.565%;
  max-height: 190%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 3.75vw;
  left: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    left: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .innerTxt:before {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 5.33333vw;
    padding-left: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(even) .flex .innerTxt {
    padding-right: 2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
  content: "";
  width: 158.565%;
  max-height: 190%;
  height: 100%;
  background: #fff;
  position: absolute;
  top: 3.75vw;
  right: 0;
  z-index: -1;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    top: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    content: "";
    width: 94.66667vw;
    max-width: 710px;
    height: 71.38%;
    max-height: inherit;
    top: inherit;
    bottom: 0;
    right: -5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    right: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    right: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .innerTxt:before {
    right: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex {
  flex-direction: row-reverse;
}

#mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
  padding-left: 5.49%;
  padding-right: 4.39%;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-left: 5.33333vw;
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .flex .innerTxt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .imgBox {
    left: -5.33333vw;
    right: inherit;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .imgBox {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .imgBox {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea ul li:nth-child(odd) .imgBox {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  height: 36.875vw;
  max-height: 590px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    background: #fff;
    height: inherit;
    max-height: inherit;
    padding-top: 5.33333vw;
    padding-right: 4.66667vw;
    padding-left: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-top: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-top: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-top: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-right: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-right: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-right: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-left: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-left: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner {
    padding-left: 2.56223vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img {
  width: 50%;
  height: 100%;
  position: relative;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img:hover {
  cursor: pointer;
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img:hover::after {
  opacity: .3;
  visibility: visible;
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
  width: 50%;
  background: #fff;
  height: 100%;
  padding-left: 5.625vw;
  padding-right: 5.625vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 6.58858vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 6.58858vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.04em;
  margin-bottom: 4.375vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 70px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.12445vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
  font-size: 2rem;
  font-size: 1.04167vw;
  line-height: 2.2;
  letter-spacing: 0.03em;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    width: 100%;
    padding-left: 0vw;
    padding-right: 0vw;
    padding-top: 10.66667vw;
    padding-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-top: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt {
    padding-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
    font-size: 1.77083vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    font-size: 4.53333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .ttl {
    margin-bottom: 5.85652vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .mizuharaComment .inner .txt .read {
    font-size: 4.26667vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList {
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList {
    margin-bottom: 14.64129vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard {
  width: 46.33%;
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
  margin-top: 10.625vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 170px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 12.4451vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard {
    width: 100%;
  }
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard:nth-child(even) {
    margin-top: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .img, #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard img {
  width: 100%;
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
  background: #fff;
  padding-top: 3.75vw;
  padding-bottom: 2.5vw;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-top: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-top: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-top: 4.39239vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-left: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-left: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-left: 2.92826vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-right: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-right: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt {
    padding: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
  text-align: center;
  font-size: 2.8rem;
  font-size: 1.45833vw;
  line-height: 2;
  margin-bottom: 3.75vw;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 3.73333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
    font-size: 1.875vw;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 3.6rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 2.36842vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 2.63543vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    font-size: 4.8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt .ttl {
    margin-bottom: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
  display: block;
  border: 1px solid #769DCD;
  width: 15.4375vw;
  max-width: 247px;
  text-align: center;
  padding-top: 1.125vw;
  padding-bottom: 1.125vw;
  margin: 0 auto;
  opacity: 1;
  transition: opacity 1s .2s;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 1.31772vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 18px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 1.31772vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a:hover {
  opacity: .7;
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 1.86667vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span:after {
  content: "";
  background: url(/images/common/blank_icon.svg) center center no-repeat;
  width: 0.9em;
  height: 0.8em;
  display: inline-block;
  background-size: contain;
  margin-left: .7em;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    width: 73.33333vw;
    max-width: 550px;
    padding-top: 4.66667vw;
    padding-bottom: 4.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-top: 2.56223vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 2.30263vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a {
    padding-bottom: 2.56223vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .postList .inner .postCard .txt a span {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
  margin-bottom: 18.75vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 300px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 19.73684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 21.96193vw;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .bnrBox img {
  margin: 0 auto;
  width: 65vw;
  max-width: 1040px;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 26.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .bnrBox img {
    width: 100%;
    max-width: inherit;
  }
}

#mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  padding-top: 12.5vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 14.64129vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    line-height: 1;
    letter-spacing: 2.1px;
    padding-top: 21.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    padding-top: 11.71303vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaProductsArea .irohaProductsAreaTit {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .campaign {
  margin-bottom: 16.25vw;
  margin-left: auto;
  margin-right: auto;
  max-width: 1406px;
  padding: 0 20px;
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 260px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 17.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 19.03367vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign {
    padding: 0;
    margin-bottom: 18.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 140px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 9.21053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign {
    margin-bottom: 10.2489vw;
  }
}

#mizuharaTop .mizuhara .campaign .campaignTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  margin-bottom: 11.25vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 180px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 11.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 13.17716vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    letter-spacing: 1.925px;
    margin-bottom: 44vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 330px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 21.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignTit {
    margin-bottom: 24.15813vw;
  }
}

#mizuharaTop .mizuhara .campaign .campaignBox {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fff;
  background-image: url(/images/kiko_mizuhara/deco_02.svg);
  background-size: 52.19%;
  background-repeat: no-repeat;
  background-position: left 8% bottom 32%;
  padding-top: 6.875vw;
  padding-bottom: 7.5vw;
  padding-right: 6.875vw;
  padding-left: 3.125vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 120px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 7.89474vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 8.78477vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 110px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 7.23684vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 8.05271vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    display: block;
    max-width: 94.7%;
    width: 100%;
    background-image: url(/images/kiko_mizuhara/deco_03.svg);
    background-size: 91%;
    background-position: top 26% center;
    padding-top: 45.06667vw;
    padding-bottom: 22.66667vw;
    padding-right: 10.66667vw;
    padding-left: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 338px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 22.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-top: 24.74378vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 170px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 11.18421vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-bottom: 12.4451vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-right: 5.85652vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBox {
    padding-left: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .campaign .deco {
  position: absolute;
  top: -8.25vw;
  left: 3.4375vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: 0.72px;
  text-align: center;
  color: #2c2c2c;
  width: 23.5vw;
  max-width: 376px;
  height: 23.1875vw;
  max-height: 371px;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -132px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -8.68421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -9.66325vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 55px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 3.61842vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 4.02635vw;
  }
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 3.2vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -24vw;
    left: 5.33333vw;
    font-size: 3.4rem;
    font-size: 1.77083vw;
    line-height: 2;
    letter-spacing: 0.51px;
    width: 60.26667vw;
    max-width: 452px;
    height: 59.6vw;
    max-height: 447px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -180px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -11.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    top: -13.17716vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 3.4rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 2.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 2.48902vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .deco {
    font-size: 4.53333vw;
  }
}

#mizuharaTop .mizuhara .campaign .deco::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/kiko_mizuhara/campaign_deco.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotateAnim 35s linear infinite;
}

@keyframes rotateAnim {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#mizuharaTop .mizuhara .campaign .txtBox {
  position: relative;
  z-index: 1;
  padding-top: 8.4375vw;
  padding-right: 10vw;
  padding-left: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 135px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 8.88158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 9.88287vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 11.71303vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 0vw;
    padding-right: 0vw;
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-top: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-right: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 0px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 0vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .txtBox {
    padding-left: 0vw;
  }
}

#mizuharaTop .mizuhara .campaign .campaignBoxTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 3.2rem;
  font-size: 1.66667vw;
  font-weight: 600;
  line-height: 1.8;
  letter-spacing: 0.64px;
  text-align: center;
  color: #769DCD;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 3.2rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 4.26667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    letter-spacing: 0.32px;
    font-size: 2.8rem;
    font-size: 1.45833vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.8rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 1.84211vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.04978vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 3.8rem;
    font-size: 1.97917vw;
    letter-spacing: 0.2px;
    margin-bottom: 16vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 3.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 2.78184vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    font-size: 5.06667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 120px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 7.89474vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTit {
    margin-bottom: 8.78477vw;
  }
}

#mizuharaTop .mizuhara .campaign .campaignBoxTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.8rem;
  font-size: 0.9375vw;
  font-weight: 500;
  line-height: 2.22;
  color: #4d4d4d;
  margin-bottom: 3.125vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 1.8rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 1.18421vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 1.31772vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 3.66032vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 3rem;
    font-size: 1.5625vw;
    line-height: 2;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 3rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 2.19619vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    font-size: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .campaignBoxTxt {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .campaign .link {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.96px;
  text-align: right;
  color: #4d4d4d;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 2.13333vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.28px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .link {
    font-size: 3.73333vw;
  }
}

#mizuharaTop .mizuhara .campaign .link a {
  color: #4d4d4d;
}

#mizuharaTop .mizuhara .campaign .link .arrow {
  display: inline-block;
}

#mizuharaTop .mizuhara .campaign .link .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/index/products_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 40px;
  height: 10px;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    width: 30px;
    height: 8px;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    width: 30px;
    height: 8px;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .link .arrow::after {
    margin-left: 2.19619vw;
  }
}

#mizuharaTop .mizuhara .campaign .imgBox {
  max-width: 41.807%;
  width: 100%;
  flex-shrink: 0;
  text-align: right;
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .imgBox {
    max-width: 100%;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .imgBox {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .imgBox {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .imgBox {
    margin-bottom: 11.71303vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .imgBox img {
    width: 100%;
  }
}

#mizuharaTop .mizuhara .campaign .imgBox span {
  font-size: 1.4rem;
  font-size: 0.72917vw;
  display: inline-block;
  margin-top: .5em;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 1.86667vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 2rem;
    font-size: 1.04167vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .imgBox span {
    font-size: 2.66667vw;
  }
}

#mizuharaTop .mizuhara .campaign .attention {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.4rem;
  font-size: 0.72917vw;
  font-weight: 500;
  line-height: 1;
  text-align: right;
  color: #4d4d4d;
  margin-top: 1.25vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 0.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 1.02489vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 1.86667vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 1.31579vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 2rem;
    font-size: 1.04167vw;
    margin-top: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 1.46413vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .campaign .attention {
    font-size: 2.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 20px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 1.31579vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .campaign .attention {
    margin-top: 1.46413vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaCon {
    padding: 0;
  }
}

#mizuharaTop .mizuhara .irohaArea {
  padding-top: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea {
    padding-top: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 7rem;
  font-size: 3.64583vw;
  font-weight: 500;
  line-height: 0.79;
  letter-spacing: 3.85px;
  text-align: center;
  color: #666;
  margin-bottom: 15.625vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    font-size: 7rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    font-size: 4.60526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    font-size: 5.12445vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    font-size: 9.33333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 250px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 16.44737vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 18.30161vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    letter-spacing: 1.925px;
    margin-bottom: 34.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 260px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 17.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .irohaAreaTit {
    margin-bottom: 19.03367vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 6.25vw;
  padding-bottom: 6.25vw;
  padding-right: 10vw;
  padding-left: 14.375vw;
  margin-bottom: 12.5vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 7.32064vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 7.32064vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 160px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 10.52632vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 11.71303vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 230px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 15.13158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 16.83748vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 13.15789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    max-width: 94.7%;
    width: 100%;
    margin: 0 0 0 auto;
    padding-top: 94.66667vw;
    padding-bottom: 26.66667vw;
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
    margin-bottom: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 710px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 46.71053vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-top: 51.97657vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 13.15789vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-bottom: 14.64129vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    padding-left: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor {
    margin-bottom: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
  position: absolute;
  width: 45.8125vw;
  max-width: 733px;
  top: -3.125vw;
  right: calc(100% - 33%);
  box-shadow: 10px 10px 20px 0 #0000001a;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -50px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -3.28947vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -3.66032vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.5vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.63158vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.92826vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.5vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -40px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    width: 94.66667vw;
    max-width: 710px;
    top: -13.33333vw;
    left: -5.33333vw;
    box-shadow: 5px 5px 10px 0 #0000001a;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -100px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -6.57895vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    top: -7.32064vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox01 {
    left: -2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
  padding-left: 24.375vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 390px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 25.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 28.55051vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 32.5vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 520px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 34.21053vw;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 38.06735vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 26.25vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 420px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 27.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 30.74671vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-right: 5.33333vw;
    padding-left: 5.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-right: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-right: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-right: 2.92826vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 2.63158vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .txtBox {
    padding-left: 2.92826vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
  margin-top: 6.25vw;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 100px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 6.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 7.32064vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 21.33333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 160px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 10.52632vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .imgBox02 {
    margin-top: 11.71303vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 5.4rem;
  font-size: 2.8125vw;
  font-weight: 500;
  line-height: 1.26;
  letter-spacing: 3.24px;
  text-align: center;
  color: #666;
  margin-bottom: 2vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    font-size: 5.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    font-size: 3.55263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    font-size: 3.95315vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    font-size: 7.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 2.10526vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 2.34261vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    line-height: 1.26;
    letter-spacing: 1.405px;
    margin-bottom: 8vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 3.94737vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTit {
    margin-bottom: 4.39239vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 2.4rem;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.48px;
  text-align: center;
  color: #4d4d4d;
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 2.4rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 1.57895vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 1.75695vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 3.2vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 3.2rem;
    font-size: 1.66667vw;
    line-height: .94;
    letter-spacing: .96px;
    margin-bottom: 14.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 3.2rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 2.10526vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 2.34261vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    font-size: 4.26667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 110px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 7.23684vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorLead {
    margin-bottom: 8.05271vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
  font-family: "yu-gothic-pr6n", "游ゴシック", YuGothic, "Yu Gothic", "Noto Sans JP", "Hiragino Sans", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Verdana, sans-serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 2;
  color: #4d4d4d;
  margin-bottom: 5.625vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 5.92105vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 6.58858vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .loveMyColorTxt {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
  font-family: "yu-mincho-pr6n", "YuMincho", "游明朝", "Noto Serif JP", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
  font-size: 1.6rem;
  font-size: 0.83333vw;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.96px;
  text-align: right;
  color: #4d4d4d;
  margin-bottom: 2.5vw;
}

@media screen and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 1.05263vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 1.1713vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 2.13333vw;
  }
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 40px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 2.63158vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 2.92826vw;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 2.8rem;
    font-size: 1.45833vw;
    letter-spacing: 0.28px;
    margin-bottom: 10.66667vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1920px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 2.8rem;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 1.84211vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 2.04978vw;
  }
}

@media screen and (max-width: 767px) and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    font-size: 3.73333vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 5.26316vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link {
    margin-bottom: 5.85652vw;
  }
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .link a {
  color: #4d4d4d;
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow {
  display: inline-block;
}

#mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
  content: "";
  display: inline-block;
  background-image: url(/images/index/products_arrow.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  backface-visibility: hidden;
  margin-left: 0.625vw;
  width: 40px;
  height: 10px;
}

@media screen and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 0.65789vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 0.73206vw;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    width: 30px;
    height: 8px;
  }
}

@media screen and (max-width: 767px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    width: 30px;
    height: 8px;
    margin-left: 4vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 1600px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 30px;
  }
}

@media screen and (max-width: 767px) and (min-width: 1025px) and (max-width: 1440px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 1.97368vw;
  }
}

@media screen and (max-width: 767px) and (min-width: 768px) and (max-width: 1024px) {
  #mizuharaTop .mizuhara .irohaArea .loveMyColor .link .arrow::after {
    margin-left: 2.19619vw;
  }
}
