@charset "utf-8";
#faqTop{
	background-image:linear-gradient(to bottom,rgba(241, 251, 252,1)120px,rgba(245, 253, 254,.6)850px,rgba(245, 253, 254,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	.faqArea{
		@include props(pt,125);

		.secTit{
			font-family: $YuMincho;
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 4.9px;
			text-align: center;
			@include props(pt,110);
			@include props(pb,110);
		  
			@include mq(md){
				@include fz_vw(70);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: 0.07em;
				text-align: center;
				@include props(pt,150,750);
				@include props(pb,150,750);
			}
		}
	  
		.secSubTit{
			font-family: $YuMincho;
			@include fz_vw(24);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: 0.48px;
			text-align: center;
			@include props(mb,50);

			@include mq(md){
			  @include fz_vw(36);
			  font-weight: 600;
			  line-height: normal;
			  letter-spacing: 3.6px;
			  @include props(mb,60,750);
			}
		}

		.faqListTit {
			font-family: $YuGothic;
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.78;
			letter-spacing: normal;
			text-align: center;
			@include props(mb,120);
	  
			@include mq(md){
				@include fz_vw(28);
				line-height: 2;
			}
		}

		.allControlWrap{
			text-align: end;
			border-bottom: 1px solid #e5e5e5;
			#allControlBtn{
				font-family: $YuMincho;
				@include fz_vw(20);
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.6;
				letter-spacing: 1.2px;
				display: inline-block;
				@include props(pr,80);
				position: relative;
				@include props(pb,20);
				color: #4d4d4d;

				@include mq(md){
					@include fz_vw(28);
					@include props(pr,80,750);
					@include props(pb,30,750);
				}

				&:after{
					content: "";
					width: .8em;
					height: .8em;
					border-top: 1px solid #4d4d4d;;
					border-left: 1px solid #4d4d4d;
					position: absolute;
					top: .7em;
					@include props(r,40);
					transform: translate(50%,-50%)rotate(225deg);
					transition: all .3s;

					@include mq(md){
						@include props(r,40,750);
					}
				}

				&.isOpen{
					&:after{
						transform: translate(50%,0%)rotate(45deg);
					}
				}
			}
		}

		.faqList{
			@include props(mb,200);

			@include mq(md){
				@include props(mb,120,750);
			}
			li{
				border-bottom: 1px solid #e5e5e5;

				.question{
					font-family: $YuGothic;
					@include fz_vw(18);
					@include props(pt,40);
					@include props(pb,40);
					@include props(pl,40);
					@include props(pr,80);
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					position: relative;
					display: flex;

					@include mq(md){
						@include fz_vw(28);
						@include props(pt,40,750);
						@include props(pb,40,750);
						@include props(pl,40,750);
						@include props(pr,80,750);
					}

					&:before{
						flex-shrink: 0;
						content: "Q.";
						font-family: $YuGothic;
						@include fz_vw(30);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: 3px;
						@include props(w, 32);
						@include props(mr,17);

						@include mq(md){
							@include fz_vw(42);
							@include props(w, 46, 750);
							@include props(mr,30,750);
						}
					}

					&:after{
						content: "";
						width: .8em;
						height: .8em;
						border-top: 1px solid #4d4d4d;;
						border-left: 1px solid #4d4d4d;
						position: absolute;
						@include props(t,40);
						@include props(r,40);
						transform: translate(50%,75%)rotate(225deg);
						transition: all .3s;

						@include mq(md){
							@include props(t,30,750);
							@include props(r,40,750);
						}
					}

					&.isOpen{
						&:after{
							transform: translate(50%,100%)rotate(45deg);
						}
					}
				}

				.answerBox{
					display: none;
				}

				.answer{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					position: relative;
					display: flex;
					background-color:$basicWhiteColor;
					@include props(pt,40);
					@include props(pb,40);
					@include props(pl,40);
					@include props(pr,40);
					@include clearfix;

					@include mq(md){
						@include fz_vw(28);
						@include props(p,40,750);
					}

					&:before{
						flex-shrink: 0;
						content: "A.";
						font-family: $YuGothic;
						@include fz_vw(30);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: 3px;
						@include props(w, 32);
						@include props(mr,17);

						@include mq(md){
							@include fz_vw(42);
							@include props(w, 46, 750);
							@include props(mr,30,750);
						}
					}
	
					img {
						@include props(mt,50);
						@include props(mb,50);
				
						&.alignleft{
							display: inline;
							float: left;
							margin: 0 auto;
							@include props(mr,20);
						}
				
						&.aligncenter{
							display: block;
							margin-left: auto;
							margin-right: auto;
						}
				
						&.alignright{
							display: inline;
							float: right;
							margin: 0 auto;
							@include props(ml,20);
						}
				
						@include mq(md){
							@include props(mt,30,750);
							@include props(mb,30,750);
				
							&.alignleft{
							@include props(mr,30,750);
							}
				
							&.alignright{
							@include props(ml,30,750);
							}
						}
					}

					a{
						color: #4d4d4d;
						text-decoration: underline;
					}
				}
			}
		}

		.faqThumbnailList{
			display: flex;
			justify-content: left;
			flex-wrap: wrap;
			padding: 3.6603% 2.1961% 1.83015%;
			background: $basicWhiteColor;
			@include props(mb,120);
			@include mq(md){
			  @include props(p,40,750);
			  @include props(mb,120,750);
			  justify-content: space-between;
			}
	  
			li{
			  width: 16.8453%;
			  margin-left: 1.53193%;
			  margin-right: 1.53193%;
			  margin-bottom: 3.06386%;
			  border: 4px solid #fff;
	  
			  @include mq(md){
				@include props(w,282,750);
				margin-left: 0;
				margin-right: 0;
				@include props(mb,13,750);
			  }

			  &.current{
				border: 4px solid #D6A1B7;
			  }
			}
		}

		.pageBackWrap{
            text-align: end;
  
            .categoryBack{
              @include props(mb,280);
  
              @include mq(md){
                @include props(mb,200,750);
              }
              
              a{
                &:before{
                  content: "";
                  display: inline-block;
                  background: url(/images/contents/single/category_back_icon.svg) center center no-repeat;
                  background-size: contain;
                  @include props(w,19);
                  @include props(h,17);
                  @include props(mr,10);
  
                  @include mq(md){
                    @include props(w,33,750);
                    @include props(h,30,750);
                    @include props(mr,20,750);
                  }
                }
              }
            }

            a{
              color: #4D4D4D;
              @include fz_vw(18);
              position: relative;
			  font-family: $YuMincho;
  
              @include mq(md){
                @include fz_vw(28);
              }
            }
          }
	}
}