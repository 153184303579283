@charset "utf-8";
/* ==============================================
トップページ
============================================== */
.contents{
  background-color: #fcecdd;
  #header {
    // position: static;
    background-image: none;
    background-color: rgba($color: #fff, $alpha: .7);
    transition: top .5s;

    &.fixed-hide{
      @include props(t,-120);

      @include mq(minCon){
        top: -7.5vw;
      }
  
      @include mq(md){
        @include props(t,-125,750);
      }
    }

    &.changeStyle {
      background-color: #fff;
  
      .headerContents{
        @include props(h, 80);
  
        @include mq(md) {
          @include props(h, 80, 750);
        }
      }
  
      .headerLogo{
        @include props(w, 106);
  
        @include mq(md) {
          @include props(w, 106, 750);
        }
      }
  
      .pcGlobalNav{
        @include mq(pc) {
          @include props(pl, 50);
  
          ul{
            .pcGlobalNavList{
              @include props(mr, 50);
      
              a{
                @include fz_vw(16);
                letter-spacing: 0.8px;
              }
            }
          }
        }
      }
    }
  }

  //サジェスト
  .ui-widget.ui-widget-content{
    display: block;
    @include props(w, 180);
    @include fz_vw(14);
    border: 1px solid #333;
    background: #333;
    color: #fff;
    border-radius: 6px;
    transform:translateZ(1px);
    @include props(pt, 5);
    @include props(pb, 5);

    &::after{
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &.disp-top{
      &::after{
        @include props(b, -15);
        @include props(l, 10);
        @include props(btw, 15);
        @include props(brw, 15);
        @include props(blw, 15);
        border-color: #333 transparent transparent transparent;
      }
    }
    &.disp-btm{
      &::after{
        @include props(t, -15);
        @include props(l, 10);
        @include props(bbw, 15);
        @include props(brw, 15);
        @include props(blw, 15);
        border-color: transparent transparent #333 transparent;
      }
    }
  
    @include mq(md){
      @include props(w, 360, 750);
      @include fz_vw(28);
      @include props(pt, 6, 750);
      @include props(pb, 6, 750);

      &.disp-top{
        &::after{
          @include props(b, -25, 750);
          @include props(l, 20, 750);
          @include props(btw, 25, 750);
          @include props(brw, 25, 750);
          @include props(blw, 25, 750);
        }
      }
      &.disp-btm{
        &::after{
          @include props(t, -25, 750);
          @include props(l, 20, 750);
          @include props(bbw, 25, 750);
          @include props(brw, 25, 750);
          @include props(blw, 25, 750);
        }
      }

      .ui-menu-item{
        &:nth-of-type(n+4){
          display: none;
        }
      }
    }
  }
  .ui-menu .ui-menu-item-wrapper{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ui-menu .ui-menu-item-wrapper{
    display: block;
    cursor: pointer;
    @include props(pt, 8);
    @include props(pb, 8);
    @include props(pr, 12);
    @include props(pl, 12);
  
    @include mq(md){
      @include props(pt, 16, 750);
      @include props(pb, 16, 750);
      @include props(pr, 24, 750);
      @include props(pl, 24, 750);
    }
  }
  .ui-state-active, 
  .ui-widget-content .ui-state-active,
  .ui-widget-header .ui-state-active,
  a.ui-button:active,
  .ui-button:active,
  .ui-button.ui-state-active:hover {
    border: none;
    background: inherit;
    color: #fff;
    margin: 0;
    opacity: .7;
  }
}
#contentsTop{
  background-image:linear-gradient(to bottom,rgba(252, 233, 216,1)120px,rgba(255, 250, 246,.6)850px,rgba(255, 250, 246,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
	  background-image:linear-gradient(to bottom,rgba(252, 233, 216,1)7.5vw,rgba(255, 250, 246,.6)52.63vw,rgba(255, 250, 246,.8) 62.5vw );
	}

	@include mq(md){
	  background-image:linear-gradient(to bottom,rgba(252, 233, 216,1)16.66vw,rgba(255, 250, 246,.6)106vw,rgba(255, 250, 246,.8) 126.6vw );
	}

  //contents 共通部分
  .contentsSubNav{
    position: fixed;
    @include props(t,120);
    left: 0;
    z-index: 999;
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    @include props(h, 60);
    transition: top .5s;

    @include mq(minCon){
      top: 7.5vw;
    }

    @include mq(md){
      @include props(t,125,750);
    }

    &.scrollDown{
      @include props(t,0);
    }

    &.minTop{
      @include props(t,80);
      @include mq(minCon){
        top: 5vw;
      }
  
      @include mq(md){
        @include props(t,80,750);
      }
    }

    dl{
      position: relative;
      height: 100%;

      dd{
        height: 100%;
      }
    }

    @include mq(md) {
      @include props(h, 80, 750);

      dl{
        dd{
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          z-index: 998;
          max-width: 100%;
          width: 100%;
          height: auto;
        }
      }

      .accordionBtn{
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 100%;
        @include props(pr, 200, 750);
        @include props(pl, 40, 750);

        &.isOpen{
          &::after{
            transform: rotate(-180deg);
          }
        }

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          @include props(r, 50, 750);
          margin: auto;
          background-image: url(/images/common/suvnav_accordion_arrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition: transform .5s;
          backface-visibility: hidden;
          @include props(w, 45, 750);
          @include props(h, 23, 750);
        }
      }
    }

    .flex{
      display: flex;
      align-items: center;
      height: 100%;

      @include mq(md) {
        display: block;
        background-color: #fff;
        padding: 0;
        @include props(pt, 60, 750);
      }
    }

    .logo{
      @include props(w, 148);
      @include props(mr, 60);

      @include mq(tab) {
        @include props(mr, 30);
      }
    }

    .contentsSubNavList{
      display: flex;
      align-items: center;

      @include mq(md) {
        display: block;
        @include props(pr, 90, 750);
        @include props(pl, 90, 750);
      }

      .list{
        flex-shrink: 0;
        @include props(mr, 25);

        @include mq(tab) {
          @include props(mr, 20);
        }

        @include mq(md) {
          @include props(mb, 60, 750);
          @include props(mr, 0);
        }

        a{
          display: flex;
          align-items: center;
          font-family: $tsukushi;
          @include fz_vw(18);
          font-weight: 500;
          line-height: 1;
          color: #6456b7;

          &::before{
            content: "";
            display: block;
            background-image: url(/images/common/iroiroiroha_icon_01.svg);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            @include props(w, 30);
            @include props(h, 30);
            @include props(mr, 5);
          }

          @include mq(md) {
            @include fz_vw(32);
            letter-spacing: .64px;

            &::before{
              @include props(w, 54, 750);
              @include props(h, 46, 750);
              @include props(mr, 20, 750);
            }
          }
        }

        &.topics{
          a{
            color: #6456b7;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_01.svg);
            }
          }
        }
        &.iroha_story{
          a{
            color: #4a6bb7;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_02.svg);
            }
          }
        }
        &.interview{
          a{
            color: #63a534;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_03.svg);
            }
          }
        }
        &.column{
          a{
            color: #aaa73f;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_04.svg);
            }
          }
        }
        &.research{
          a{
            color: #c78246;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_05.svg);
            }
          }
        }
        &.review{
          a{
            color: #bc5680;
            &::before{
              background-image: url(/images/common/iroiroiroha_icon_06.svg);
            }
          }
        }
      }
    }

    .searchArea{
      position: relative;
      display: flex;
      align-items: center;
      height: 100%;
      margin: 0 0 0 auto;

      @include mq(md) {
        display: block;
        height: auto;
        margin: auto;
      }
      .searchAreaBack{
        cursor: pointer;
        background-image: url(/images/common/search_icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @include props(w, 26);
        @include props(h, 26);

        @include mq(md) {
          display: none;
        }
      }
      .formBox{
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #f2f2f2;
        @include props(w, 310);
        @include props(pt, 10);
        @include props(pb, 10);
        @include props(pr, 10);
        @include props(pl, 10);

        .box{
          display: flex;
          align-items: center;
        }

        .bg{
          flex-shrink: 0;
          background-image: url(/images/common/search_icon.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          @include props(w, 23);
          @include props(h, 23);
          @include props(mr, 10);
        }

        input{
          display: block;
          
          &[type="submit"]{
            cursor: pointer;
            opacity: 0;
            max-width: 100%;
          }

          &[type="text"]{
            max-width: 100%;
            width: 100%;
            font-family: $Montserrat;
            @include fz_vw(12);
            font-weight: 500;
            line-height: 1.25;
            color: #666;
            border: none;
            border-radius: 0;
            @include placeholder(#adadad);
            background-color: #fff;
            @include props(p, 10);
          }
        }

        @include mq(md) {
          position: static;
          max-width: 100%;
          width: 100%;
          @include props(pt, 15, 750);
          @include props(pb, 15, 750);
          @include props(pr, 30, 750);
          @include props(pl, 30, 750);

          .bg{
            @include props(w, 52, 750);
            @include props(h, 52, 750);
            @include props(mr, 20, 750);
          }

          input{
            &[type="text"]{
              max-width: 100%;
              width: 100%;
              @include fz_vw(29);
              @include props(p, 20, 750);
            }
          }
        }
      }
    }
  }
  .contentsArea{
    position: relative;
    z-index: 1;
    transition: 3s cubic-bezier(.08,.92,.35,1);
    @include props(pt, 180);

    @include mq(desk) {
      padding-top: 11.25vw;
    }

    @include mq(tab){
      padding-top: 11.33vw;
    }
    
    @include mq(md) {
      @include props(pt, 205, 750);
    }

    // &::after{
    //   content: "";
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   z-index: 0;
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   opacity: .8;
    //   background-image: url(/images/common/wave_back@2x.png);
    //   background-size: 100% auto;
    //   background-attachment: fixed;
    //   background-repeat: repeat;
    //   background-position: top center;
    //   mix-blend-mode: lighten;
    // }

    .gradation{
      position: relative;
      z-index: 1;
    }

    .contentsWrap{
      position: relative;
      z-index: 1;
      // @include props(pt, 180);

      // @include mq(md) {
      //   @include props(pt, 205, 750);
      // }
      // @include props(pb, 150);

      // @include mq(md) {
      //   @include props(pb, 100, 750);
      // }

      .flex{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include mq(md) {
          display: block;
          padding: 0;
        }
      }

      .articlesColumn{
        max-width: 70.718%;
        width: 100%;
        @include props(pr, 25);

        @include mq(md) {
          max-width: 100%;
          @include props(pr, 0);
        }
      }
      .sideColumn{
        max-width: 27.453%;
        width: 100%;

        @include mq(md) {
          max-width: 100%;
        }
      }
    }

    .sideColumn{
      background-image: linear-gradient(0deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0.3) 82%, rgba(255,255,255,0) 100%);
      @include props(pt, 78);
      @include props(pb, 60);
      @include props(pr, 20);
      @include props(pl, 20);

      @include mq(md) {
        background-color: inherit;
        background-image: none;
        @include props(pt, 0);
        @include props(pb, 0);
        @include props(pr, 0);
        @include props(pl, 0);
      }

      .sideContact{
        border: 1px solid #C78246;
        background: $basicWhiteColor;
        border-radius: 5px;
        @include props(mb,50);
        @include props(pb,20);
        @include props(pr,25);
        @include props(pl,25);
        @include props(pt,25);

        @include mq(md){
          @include props(w,670,750);
          @include props(mt,120,750);
          @include props(mb,0,750);
          @include props(p,40,750);
          margin-left: auto;
          margin-right: auto
        }

        .read{
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          letter-spacing: normal;
          @include props(mb,30);
          font-family: $tsukushi;
          font-weight: bold;

          @include mq(md){
            @include fz_vw(28);
            @include props(mb,40,750);
          }
        }

        .contactBtn{
          width: 70.67%;
          margin:0 0 0 auto;

          @include mq(md){
            width: 100%;
            @include props(h,120,750);
          }

          a{
            display:flex;
            justify-content: center;
            align-items: baseline;
            background: #C78246;
            width: 100%;
            height: 100%;
            @include props(pt,8);
            @include props(pb,8);
            text-align: center;
            color: $basicWhiteColor;
            border-radius: 5px;
            @include fz_vw(16);
            font-family: $tsukushi;
            font-weight: bold;
            transition: opacity .3s;

            @include mq(md){
              @include fz_vw(32);
              line-height: 1.75;
              align-items: center;

              &:after{
                @include props(ml,32,750);
              }
            }

            &:after{
              content: "";
              background: url(/images/contents/side_arrow.svg) bottom center no-repeat;
              background-size: contain;
              width: 1.8em;
              height: 1em;
              display:inline-block;
              margin-left: 6px;

              @include mq(md){
                background: url(/images/contents/side_arrow.svg) center center no-repeat;
              }
            }

            &:hover{
              opacity: .7;

              &:after{
                animation: 1s arrow linear forwards;
              }
            }
          }
        }
      }

      .tagList{
        display: flex;
        flex-wrap: wrap;
        @include props(mb, 30);

        @include mq(md) {
          justify-content: center;
          @include props(pl, 40, 750);
          @include props(pr, 40, 750);
          @include props(pt, 120, 750);
          @include props(pb, 80, 750);
          @include props(mb, 0);
        }

        .tag{
          @include props(mr, 5);
          @include props(mb, 20);

          @include mq(md) {
            @include props(mr, 10, 750);
            @include props(mb, 40, 750);
          }
          a{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            text-align: center;
            color: #808080;
            background-color: #fff;
            border-radius: 100px;
            @include props(pt, 10);
            @include props(pb, 10);
            @include props(pr, 15);
            @include props(pl, 15);

            @include mq(md) {
              @include fz_vw(18);
              @include props(pt, 10, 750);
              @include props(pb, 10, 750);
              @include props(pr, 30, 750);
              @include props(pl, 30, 750);
            }
          }
        }
      }

      .sideRanking{
        @include props(mb, 60);

        @include mq(md) {
          @include props(pb, 160, 750);
          @include props(pl, 40, 750);
          @include props(mb, 0);
        }

        &.topics{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #6456b7 transparent transparent transparent;
                }
              }
            }
          }
        }
        &.iroha_story{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #4a6bb7 transparent transparent transparent;
                }
              }
            }
          }
        }
        &.interview{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #63a534 transparent transparent transparent;
                }
              }
            }
          }
        }
        &.column{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #aaa73f transparent transparent transparent;
                }
              }
            }
          }
        }
        &.research{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #c78246 transparent transparent transparent;
                }
              }
            }
          }
        }
        &.review{
          .sideRankingCard{
            .img{
              .num{
                &::after{
                  border-color: #bc5680 transparent transparent transparent;
                }
              }
            }
          }
        }

        .sideRankingTit{
          font-family: $ZenMaruGothic;
          @include fz_vw(28);
          line-height: 1;
          letter-spacing: 1.54px;
          color: #4d4d4d;
          border-bottom: solid 1px #4d4d4d;
          @include props(pb, 10);
          @include props(mb, 45);

          @include mq(md) {
            @include fz_vw(48);
            letter-spacing: 1.2px;
            border-bottom: solid 1px #4d4d4d;
            @include props(pb, 20, 750);
            @include props(mb, 80, 750);
          }
        }
        .sideRankingCard{
          @include props(mb, 40);

          &:last-of-type{
            margin-bottom: 0;
          }

          a{
            display: flex;
            @include hoverImg;
          }

          @include mq(md) {
            @include props(mb, 80,750);

            a{
              position: relative;
              z-index: 1;
              @include props(pt, 50, 750);
              @include props(pb, 50, 750);

              &::after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                background-color: #fff;
                height: 100%;
                @include props(w, 510,750);
              }
            }
          }

          .img{
            position: relative;
            flex-shrink: 0;
            overflow: hidden;
            @include props(w, 140);
            @include props(mr, 20);

            .num{
              position: absolute;
              top: 0;
              left: 0;
              display: block;
              font-family: $tsukushi;
              @include fz_vw(18);
              font-weight: bold;
              line-height: 1;
              color: #fff;
              z-index: 1;
              @include props(w, 34);
              @include props(h, 54);
              @include props(pt, 5);
              @include props(pl, 5);
  
              &::after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: -1;
                top: 0;
                height: 0;
                border-style: solid;
                border-width: 54px 34px 0 0;
                @include props(btw, 54);
                @include props(brw, 34);
                border-color: #f79902 transparent transparent transparent;
              }
            }

            @include mq(md) {
              @include props(w, 300,750);
              @include props(mr, 35,750);
              
              .num{
                @include fz_vw(28);
                @include props(w, 57, 750);
                @include props(h, 91, 750);
                @include props(pt, 10, 750);
                @include props(pl, 10, 750);
    
                &::after{
                  @include props(btw, 91, 750);
                  @include props(brw, 57, 750);
                }
              }
            }
          }
          .sideRankingCardTit{
            font-family: $tsukushi;
            @include fz_vw(16);
            font-weight: bold;
            line-height: 1.63;
            color: #4d4d4d;

            @include mq(md) {
              @include fz_vw(28);
              font-weight: bold;
              line-height: 1.57;
              @include props(pr,40,750);
            }
          }
        }
      }

      .sideFeature{
        @include mq(md) {
          @include props(pl, 40, 750);
        }

        .sideFeatureTit{
          font-family: $ZenMaruGothic;
          @include fz_vw(28);
          line-height: 1;
          letter-spacing: 1.54px;
          color: #4d4d4d;
          border-bottom: solid 1px #4d4d4d;
          @include props(pb, 10);
          @include props(mb, 45);

          @include mq(md) {
            @include fz_vw(48);
            letter-spacing: 1.2px;
            border-bottom: solid 1px #4d4d4d;
            @include props(pb, 20, 750);
            @include props(mb, 80, 750);
          }
        }
        .sideFeatureCard{
          @include props(mb, 40);

          &:last-of-type{
            margin-bottom: 0;
          }

          a{
            display: flex;
            @include hoverImg;
          }

          @include mq(md) {
            @include props(mb, 80,750);

            a{
              position: relative;
              z-index: 1;
              @include props(pt, 50, 750);
              @include props(pb, 50, 750);

              &::after{
                content: "";
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                z-index: -1;
                background-color: #fff;
                height: 100%;
                @include props(w, 510,750);
              }
            }
          }

          .img{
            flex-shrink: 0;
            overflow: hidden;
            @include props(w, 140);
            @include props(mr, 20);

            @include mq(md) {
              @include props(w, 300,750);
              @include props(mr, 35,750);
            }
          }
          .sideFeatureCardTit{
            font-family: $tsukushi;
            @include fz_vw(16);
            font-weight: bold;
            line-height: 1.63;
            color: #4d4d4d;

            @include mq(md) {
              @include fz_vw(28);
              font-weight: bold;
              line-height: 1.57;
              @include props(pr,40,750);
            }
          }
          .category{
            display: flex;
            align-items: center;
            font-family: $tsukushi;
            @include fz_vw(16);
            font-weight: 500;
            line-height: 1;
            @include props(mb, 5);
      
            &::before{
              content: "";
              display: block;
              background-image: url(/images/common/iroiroiroha_icon_01.svg);
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center;
              @include props(w, 31);
              @include props(h, 21);
              @include props(mr, 5);
            }
      
            @include mq(md) {
              @include fz_vw(28);
              @include props(mb, 15, 750);
        
              &::before{
                @include props(w, 53, 750);
                @include props(h, 44, 750);
                @include props(mr, 8, 750);
              }
            }
      
            &.topics{
              color: #6456b7;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_01.svg);
              }
            }
            &.iroha_story{
              color: #4a6bb7;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_02.svg);
              }
            }
            &.interview{
              color: #63a534;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_03.svg);
              }
            }
            &.column{
              color: #aaa73f;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_04.svg);
              }
            }
            &.research{
              color: #c78246;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_05.svg);
              }
            }
            &.review{
              color: #bc5680;
              &::before{
                background-image: url(/images/common/iroiroiroha_icon_06.svg);
              }
            }
          }
        }
      }
    }

    .newsPaging{
      display: flex;
      align-items: center;
      justify-content: center;
  
      &.topics{
        .page{
          a{
            &.active{
              background-color: #6456b7;
            }
          }
        }
      }
      &.iroha_story{
        .page{
          a{
            &.active{
              background-color: #4a6bb7;
            }
          }
        }
      }
      &.interview{
        .page{
          a{
            &.active{
              background-color: #63a534;
            }
          }
        }
      }
      &.column{
        .page{
          a{
            &.active{
              background-color: #aaa73f;
            }
          }
        }
      }
      &.research{
        .page{
          a{
            &.active{
              background-color: #c78246;
            }
          }
        }
      }
      &.review{
        .page{
          a{
            &.active{
              background-color: #bc5680;
            }
          }
        }
      }

      .page{
        font-family: $YuMincho;
        @include fz_vw(16);
        font-weight: 500;
        line-height: 1;
        color: #4d4d4d;

        a{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          color: #4d4d4d;
          @include props(w, 36);
          @include props(h, 36);
          @include props(mr, 7);
          @include props(ml, 7);

          &.active{
            font-weight: bold;
            color: #fff;
            background-color: #4d4d4d;
          }
        }

        @include mq(md) {
          @include fz_vw(28);

          a{
            @include props(w, 57, 750);
            @include props(h, 57, 750);
            @include props(mr, 5, 750);
            @include props(ml, 5, 750);
          }
        }
      }
      .pageArrow{
        font-family: $YuMincho;
        @include fz_vw(16);
        font-weight: 500;
        line-height: 1;
        color: #4d4d4d;
        a{
          color: #4d4d4d;
        }
        img{
          display: inline-block;
          vertical-align: baseline;
          // transition: transform .3s;
          backface-visibility: hidden;
          @include props(w, 31);
        }

        @include mq(md) {
          @include fz_vw(28);
          img{
            @include props(w, 48, 750);
          }
        }

        &.back{
          @include props(mr, 30);
          a{
            &:hover{
              img{
                animation: 1s back linear forwards;
              }
            }
          }
          img{
            @include props(mr, 15);
          }

          @include mq(md) {
            @include props(mr, 30, 750);
            img{
              @include props(mr, 20, 750);
            }
          }
        }
        &.next{
          @include props(ml, 10);
          img{
            @include props(ml, 15);
          }
          a{
            &:hover{
              img{
                animation: 1s arrow linear forwards;
              }
            }
          }
          // &::before{
          //   content: "...";
          //   @include props(mr, 30);
          // }

          @include mq(md) {
            @include props(ml, 5, 750);
            img{
              @include props(ml, 20, 750);
            }
            &::before{
              @include props(mr, 30, 750);
            }
          }
        }
      }
    }

    &.contentsArticles{
      position: relative;
      z-index: 1;
      //background-image: linear-gradient(to bottom, #fcecdd, #FDF7F1 63%, #fff9f3);
      // background-color: #fff9f3;
      transition: 3s cubic-bezier(.08,.92,.35,1);

      // .contentsWrap{
      //   background-image: linear-gradient(to bottom, rgba(252,236,221,1) 0%, rgba(252,237,222,0.97) 8%, rgba(255,255,255,0) 16%);
        
      //   @include mq(md) {
      //     background-image: linear-gradient(to bottom,#fcecdd 0,rgba(252,237,222,.97) 1%,rgba(255,255,255,0) 4%);
      //   }
      // }
    }
  }

  .contentsIndex{
    // background-color: #fff9f3;

    .mainviewArea{
      position: relative;
      z-index: 1;
      // background-image: linear-gradient(to top, #fff9f3, #fceddef7 45%, #fcecdd);

      .logo{
        margin: 0 auto;
        @include props(w, 378);
        @include props(mb, 120);
        @include props(mt, 120);

        @include mq(md) {
          @include props(w, 378, 750);
          @include props(mb, 160, 750);
          @include props(mt, 160,750);
        }
      }

      .slideArea{
        max-width: 1406px;
        position: relative;
        opacity: 0;
        transition: opacity .3s;
        margin: 0 auto;
  
        &.slick-initialized {
          opacity: 1;
        }

        .slick-slide{
          overflow-x: hidden;
        }

        .slide{
          @include props(p, 20);

          img{
            box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          }
          
          @include mq(md) {
            @include props(p, 0);
  
            img{
              box-shadow: none;
            }
          }
        }

        a{
          display: block;
        }
  
        img{
          width: 100%;
        }

        .slide-arrow{
          cursor: pointer;
          position: absolute;
          top: 39.5%;
          @include props(w, 32);
          z-index: 1;

          &.prev-arrow{
            @include props(r, 53);
          }
          &.next-arrow{
            @include props(l, 53);
          }

          @include mq(md) {
            display: none !important;
          }
        }

        .slick-dots{
          display: flex;
          align-items: center;
          justify-content: center;
          @include props(mt, 15);
          
          @include mq(md) {
            @include props(mt, 50, 750);
          }

          li{
            background-color: #c3bfbf;
            @include props(w, 30);
            @include props(h, 4);
            @include props(mr, 5);
            @include props(ml, 5);
          
            @include mq(md) {
              @include props(w, 30, 750);
              @include props(h, 4, 750);
              @include props(mr, 5, 750);
              @include props(ml, 5, 750);
            }

            &.slick-active{
              background-color: #666;
              button{
                background-color: #666;
              }
            }

            button{
              background-color: #c3bfbf;
              font-size: 0;
              line-height: 0;
              display: block;
              width: 100%;
              height: 100%;
              cursor: pointer;
              color: transparent;
              border: 0;
              outline: none;
              background: transparent;
            }
          }
        }
      }

      .headTagArea{
        @include props(mt, 40);
          
        @include mq(md) {
          @include props(mt, 160, 750);
        }

        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          @include mq(md) {
            @include props(pr, 40);
            @include props(pl, 40);
          }

          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            text-align: center;
            color: #808080;
            @include props(mr, 5);
            @include props(mb, 20);

            a{
              display: block;
              border-radius: 100px;
              background-color: #fff;
              color: #808080;
              @include props(pt, 8);
              @include props(pb, 8);
              @include props(pr, 20);
              @include props(pl, 20);
            }
          
            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 12, 750);
              @include props(mb, 30, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 30, 750);
                @include props(pl, 30, 750);
              }
            }
          }
        }
      }
    }

    .newArticlesArea{
      @include props(pt, 172);
      @include props(pb, 200);

      @include mq(md) {
        @include props(pt, 160, 750);
        @include props(pb, 200, 750);

        .minContainer{
          padding: 0;
        }
      }

      .newArticlesTit{
        font-family: $tsukushi;
        @include fz_vw(70);
        line-height: 1;
        letter-spacing: 2.8px;
        text-align: center;
        color: #666;
        @include props(mb, 200);

        @include mq(md) {
          letter-spacing: 1.4px;
          @include props(mb, 160, 750);
        }
      }
    
      .category{
        display: flex;
        align-items: center;
        font-family: $tsukushi;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1;
        @include props(mb, 25);
  
        &::before{
          content: "";
          display: block;
          background-image: url(/images/common/iroiroiroha_icon_01.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include props(w, 31);
          @include props(h, 26);
          @include props(mr, 5);
        }
  
        @include mq(md) {
          @include fz_vw(28);
          @include props(mb, 40, 750);
    
          &::before{
            @include props(w, 54, 750);
            @include props(h, 48, 750);
            @include props(mr, 10, 750);
          }
        }
  
        &.topics{
          color: #6456b7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_01.svg);
          }
        }
        &.iroha_story{
          color: #4a6bb7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_02.svg);
          }
        }
        &.interview{
          color: #63a534;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_03.svg);
          }
        }
        &.column{
          color: #aaa73f;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_04.svg);
          }
        }
        &.research{
          color: #c78246;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_05.svg);
          }
        }
        &.review{
          color: #bc5680;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_06.svg);
          }
        }
      }

      .newArticlesCard{
        position: relative;
        display: flex;
        align-items: flex-start;
        @include props(mb, 150);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(md) {
          display: block;
          @include props(mb, 200, 750);
        }

        &:nth-of-type(2n){
          &::after{
            left: 0;
            right: auto;
          }
          .img{
            order: 2;

            @include mq(md) {
              margin: 0 0 0 auto;
            }
          }
          .txt{
            order: 1;

            @include mq(md) {
              margin: 0
            }

            &>a{
              @include props(pr, 40);
              @include props(pl, 50);

              @include mq(md) {
                @include props(pr, 40, 750);
                @include props(pl, 40, 750);
              }
            }
            .tagList{
              @include props(pr, 40);
              @include props(pl, 50);

              @include mq(md) {
                @include props(pr, 20, 750);
                @include props(pl, 40, 750);
              }
            }
          }
        }

        &::after{
          content: "";
          display: block;
          @include props(w, 850);
          height: 100%;
          background-color: #fff;
          position: absolute;
          @include props(t, 50);
          right: 0;
          z-index: -1;

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            @include props(t, 350, 750);
            height: calc(100% - 46.66667vw);
          }
        }

        a{
          display: block;
          position: relative;
          z-index: 0;
        }

        .img{
          flex-shrink: 0;
          position: relative;
          z-index: 2;
          box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          overflow: hidden;
          max-width: 59.298%;
          width: 100%;

          img{
            width: 100%;
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
          }
        }
        .txt{
          position: relative;
          margin: 0 0 0 auto;
          z-index: 1;
          max-width: 40.702%;
          width: 100%;

          &>a{
            @include props(pt, 50);
            @include props(pb, 35);
            @include props(pr, 50);
            @include props(pl, 50);
            @include props(mt, 50);
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;

            &>a{
              @include props(pt, 60, 750);
              @include props(pb, 40, 750);
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(mt, 0);
            }
          }
        }
        .newArticlesCardTit{
          font-family: $tsukushi;
          @include fz_vw(24);
          font-weight: bold;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          @include mq(md) {
            @include fz_vw(36);
            line-height: 1.44;
            @include props(mb, 40, 750);
          }
        }
        .newArticlesCardTxt{
          font-family: $tsukushi;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 15);

          @include mq(md) {
            @include fz_vw(28);
            line-height: 2;
            letter-spacing: .56px;
            @include props(pr, 0);
            @include props(mb, 40, 750);
          }
        }
        .newArticlesCardData{
          display: block;
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #4d4d4d;

          @include mq(md) {
            @include fz_vw(28);
          }
        }
        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include props(pr, 10);
          @include props(pl, 40);

          @include mq(md) {
            @include props(pr, 20, 750);
            @include props(pl, 40, 750);
            @include props(pb, 28, 750);
          }
          
          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            color: #808080;
            @include props(mr, 5);
            @include props(mb, 5);

            a{
              display: block;
              color: #808080;
              border-radius: 100px;
              border: solid 1px #adadad;
              @include props(pt, 7);
              @include props(pb, 7);
              @include props(pr, 20);
              @include props(pl, 20);
            }

            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 10, 750);
              @include props(mb, 12, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
              }
            }
          }
        }
      }
    }

    .featureArea{
      @include props(pt, 200);
      @include props(pb, 190);

      @include mq(md) {
        @include props(pt, 160, 750);
        @include props(pb, 200, 750);

        .minContainer{
          padding: 0;
        }
      }

      .featureTit{
        font-family: $ZenMaruGothic;
        @include fz_vw(70);
        line-height: 1;
        letter-spacing: 2.8px;
        text-align: center;
        color: #666;
        @include props(mb, 200);

        @include mq(md) {
          letter-spacing: 1.4px;
          @include props(mb, 160, 750);
        }
      }
    
      .category{
        display: flex;
        align-items: center;
        font-family: $YuGothic;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1;
        @include props(mb, 25);
  
        &::before{
          content: "";
          display: block;
          background-image: url(/images/common/iroiroiroha_icon_01.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include props(w, 31);
          @include props(h, 26);
          @include props(mr, 5);
        }
  
        @include mq(md) {
          @include fz_vw(28);
          @include props(mb, 40, 750);
    
          &::before{
            @include props(w, 54, 750);
            @include props(h, 48, 750);
            @include props(mr, 10, 750);
          }
        }
  
        &.topics{
          color: #6456b7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_01.svg);
          }
        }
        &.iroha_story{
          color: #4a6bb7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_02.svg);
          }
        }
        &.interview{
          color: #63a534;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_03.svg);
          }
        }
        &.column{
          color: #aaa73f;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_04.svg);
          }
        }
        &.research{
          color: #c78246;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_05.svg);
          }
        }
        &.review{
          color: #bc5680;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_06.svg);
          }
        }
      }

      .featureCard{
        position: relative;
        display: flex;
        align-items: flex-start;
        @include props(mb, 150);

        &:last-of-type{
          margin-bottom: 0;
        }

        @include mq(md) {
          display: block;
          @include props(mb, 200, 750);
        }

        &:nth-of-type(2n){
          &::after{
            left: 0;
            right: auto;
          }
          .img{
            order: 2;

            @include mq(md) {
              margin: 0 0 0 auto;
            }
          }
          .txt{
            order: 1;

            @include mq(md) {
              margin: 0
            }

            &>a{
              @include props(pr, 40);
              @include props(pl, 50);

              @include mq(md) {
                @include props(pr, 40, 750);
                @include props(pl, 40, 750);
              }
            }
            .tagList{
              @include props(pr, 40);
              @include props(pl, 50);

              @include mq(md) {
                @include props(pr, 20, 750);
                @include props(pl, 40, 750);
              }
            }
          }
        }

        &::after{
          content: "";
          display: block;
          @include props(w, 850);
          height: 100%;
          background-color: #fff;
          position: absolute;
          @include props(t, 50);
          right: 0;
          z-index: -1;

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            @include props(t, 350, 750);
            height: calc(100% - 46.66667vw);
          }
        }

        a{
          display: block;
          position: relative;
          z-index: 0;
        }

        .img{
          flex-shrink: 0;
          position: relative;
          z-index: 2;
          box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          overflow: hidden;
          max-width: 59.298%;
          width: 100%;

          img{
            width: 100%;
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
          }
        }
        .txt{
          position: relative;
          margin: 0 0 0 auto;
          z-index: 1;
          max-width: 40.702%;
          width: 100%;

          &>a{
            @include props(pt, 50);
            @include props(pb, 35);
            @include props(pr, 50);
            @include props(pl, 50);
            @include props(mt, 50);
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;

            &>a{
              @include props(pt, 60, 750);
              @include props(pb, 40, 750);
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(mt, 0);
            }
          }
        }
        .featureCardTit{
          font-family: $tsukushi;
          @include fz_vw(24);
          font-weight: bold;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          @include mq(md) {
            @include fz_vw(36);
            line-height: 1.44;
            @include props(mb, 40, 750);
          }
        }
        .featureCardTxt{
          font-family: $tsukushi;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 15);

          @include mq(md) {
            @include fz_vw(28);
            line-height: 2;
            letter-spacing: .56px;
            @include props(pr, 0);
            @include props(mb, 40, 750);
          }
        }
        .featureCardData{
          display: block;
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #4d4d4d;

          @include mq(md) {
            @include fz_vw(28);
          }
        }
        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include props(pr, 10);
          @include props(pl, 40);

          @include mq(md) {
            @include props(pr, 20, 750);
            @include props(pl, 40, 750);
            @include props(pb, 48, 750);
          }
          
          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            color: #808080;
            @include props(mr, 5);
            @include props(mb, 5);

            a{
              display: block;
              color: #808080;
              border-radius: 100px;
              border: solid 1px #adadad;
              @include props(pt, 7);
              @include props(pb, 7);
              @include props(pr, 20);
              @include props(pl, 20);
            }

            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 10, 750);
              @include props(mb, 12, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
              }
            }
          }
        }
      }
    }

    .rankingArea{
      @include props(pt, 200);
      @include props(pb, 200);

      @include mq(md) {
        @include props(pt, 160, 750);
        @include props(pb, 200, 750);

        .minContainer{
          padding: 0;
        }
      }

      .rankingTit{
        font-family: $ZenMaruGothic;
        @include fz_vw(70);
        line-height: 1;
        letter-spacing: 2.8px;
        text-align: center;
        color: #666;
        @include props(mb, 200);

        @include mq(md) {
          letter-spacing: 1.4px;
          @include props(mb, 160, 750);
        }
      }
    
      .category{
        display: flex;
        align-items: center;
        font-family: $tsukushi;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1;
        @include props(mb, 25);
  
        &::before{
          content: "";
          display: block;
          background-image: url(/images/common/iroiroiroha_icon_01.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include props(w, 31);
          @include props(h, 26);
          @include props(mr, 5);
        }
  
        @include mq(md) {
          @include fz_vw(28);
          @include props(mb, 40, 750);
    
          &::before{
            @include props(w, 53, 750);
            @include props(h, 44, 750);
            @include props(mr, 8, 750);
          }
        }
  
        &.topics{
          color: #6456b7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_01.svg);
          }
        }
        &.iroha_story{
          color: #4a6bb7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_02.svg);
          }
        }
        &.interview{
          color: #63a534;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_03.svg);
          }
        }
        &.column{
          color: #aaa73f;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_04.svg);
          }
        }
        &.research{
          color: #c78246;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_05.svg);
          }
        }
        &.review{
          color: #bc5680;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_06.svg);
          }
        }
      }

      .flex{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .rankingCard{
        position: relative;
        display: flex;
        align-items: flex-start;
        max-width: 100%;
        width: 100%;
        @include props(mb, 150);

        &:not(:first-of-type){
          display: block;
          max-width: 48.683%;
          @include props(mb,120,750);

          @include mq(md) {
            max-width: 100%;
            display: flex;
            @include props(mb, 80, 750);
          }

          &::after{
            @include props(t, 250);
            left: 0;
            max-width: 96.242%;
            width: 100%;
            height: calc(100% - 15.625vw);

            @include mq(fixed) {
              height: calc(100% - 250px);
            }
            @include mq(desk) {
              height: calc(100% - 16.44737vw);
            }
            @include mq(tab) {
              height: calc(100% - 18.30161vw);
            }

            @include mq(md) {
              @include props(w, 510, 750);
              @include props(t, 0, 750);
              // height: calc(100% - 34.66667vw);
              height: 100%;
              left: inherit;
              right: 0;
            }
          }
    
          .category{
            @include mq(md) {
              @include props(mb, 40, 750);
            }
          }

          .img{
            max-width: 96.241%;
            margin: 0 0 0 auto;

            @include mq(md) {
              @include props(mt,40,750);
              max-width: 73.3%;
              // margin: 0;

              .num{
                @include fz_vw(28);
                @include props(w, 60, 750);
                @include props(h, 96, 750);
                @include props(pt, 12, 750);
                @include props(pl, 12, 750);
    
                &::after{
                  @include props(btw, 96, 750);
                  @include props(brw, 60, 750);
                }
              }
            }
          }
          .txt{
            position: relative;
            max-width: 100%;
            z-index: 1;
  
            &>a{
              @include props(pb, 30);
              @include props(pr, 75);
              @include props(mt, 0);
            }

            @include mq(md) {
              max-width: 84%;
              // @include props(ml, 40, 750);
    
              &>a{
                @include props(pt, 40, 750);
                @include props(pb, 40, 750);
                @include props(pr, 40, 750);
                @include props(pl, 35, 750);
                @include props(mt, 0);
              }
            }
          }
          .rankingCardTit{
            @include fz_vw(18);
            line-height: 1.78;
            @include props(mb, 20);

            @include mq(md) {
              @include fz_vw(28);
              line-height: 1.57;
              @include props(mb, 40, 750);
            }
          }
          .tagList{
            @include props(pr, 75);
            @include props(pb, 35);

            @include mq(md) {
              @include props(pr, 0);
              @include props(pb, 35, 750);

              .tag{
                @include props(mb, 15, 750);
              }
            }
          }
        }

        &:last-of-type,
        &:nth-last-of-type(2){
          @include mq(pc){
            margin-bottom: 0 !important;
          }
        }

        @include mq(md) {
          display: block;
          @include props(mb, 80, 750);
          @include props(pl, 40, 750);
          display: flex;
        }

        &:last-of-type{
          @include mq(md){
            @include props(mb, 0, 750);
          }
        }

        &::after{
          content: "";
          display: block;
          @include props(w, 850);
          height: 100%;
          background-color: #fff;
          position: absolute;
          @include props(t, 50);
          right: 0;
          z-index: -1;

          @include mq(md) {
            @include props(w, 510, 750);
            @include props(t, 0, 750);
            // height: calc(100% - 34.66667vw);
            height: 100%;
            left: inherit;
            right: 0;
          }
        }

        a{
          display: block;
          position: relative;
          z-index: 0;
        }

        .img{
          flex-shrink: 0;
          position: relative;
          z-index: 2;
          box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          overflow: hidden;
          max-width: 59.298%;
          width: 100%;

          img{
            width: 100%;
          }

          .num{
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            font-family: $YuGothic;
            @include fz_vw(32);
            font-weight: bold;
            line-height: 1;
            color: #fff;
            z-index: 1;
            @include props(w, 77);
            @include props(h, 122);
            @include props(pt, 10);
            @include props(pl, 15);

            &::after{
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: -1;
              top: 0;
              height: 0;
              border-style: solid;
              border-width: 122px 77px 0 0;
              @include props(btw, 122);
              @include props(brw, 77);
              border-color: #f79902 transparent transparent transparent;
            }
          }

          @include mq(md) {
            // max-width: 94.7%;
            @include props(w,300,750);
            // width: 100%;
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
            margin: 0 0 0 auto;
            @include props(mt,40,750);

            .num{
              @include fz_vw(28);
              @include props(w, 57, 750);
              @include props(h, 91, 750);
              @include props(pt, 12, 750);
              @include props(pl, 12, 750);
  
              &::after{
                @include props(btw, 91, 750);
                @include props(brw, 57, 750);
              }
            }
          }
        }
        .txt{
          position: relative;
          margin: 0 0 0 auto;
          z-index: 1;
          max-width: 40.702%;
          width: 100%;

          &>a{
            @include props(pt, 50);
            @include props(pb, 35);
            @include props(pr, 50);
            @include props(pl, 50);
            @include props(mt, 50);
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            margin: 0;

            &>a{
              @include props(pt, 40, 750);
              @include props(pb, 40, 750);
              @include props(pr, 40, 750);
              @include props(pl, 35, 750);
              @include props(mt, 0);
            }
          }
        }
        .rankingCardTit{
          font-family: $tsukushi;
          @include fz_vw(24);
          font-weight: bold;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          @include mq(md) {
            @include fz_vw(28);
            line-height: 1.57;
            @include props(mb, 40, 750);
          }
        }
        .rankingCardTxt{
          font-family: $tsukushi;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 15);

          @include mq(md) {
            @include fz_vw(28);
            line-height: 1.5;
            letter-spacing: .56px;
            @include props(pr, 0);
            @include props(mb, 40, 750);
          }
        }
        .rankingCardData{
          display: block;
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #4d4d4d;

          @include mq(md) {
            @include fz_vw(28);
          }
        }
        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include props(pr, 10);
          @include props(pl, 40);

          @include mq(md) {
            @include props(pr, 20, 750);
            @include props(pl, 40, 750);
            @include props(pb, 28, 750);
          }
          
          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            color: #808080;
            @include props(mr, 5);
            @include props(mb, 5);

            a{
              display: block;
              color: #808080;
              border-radius: 100px;
              border: solid 1px #adadad;
              @include props(pt, 7);
              @include props(pb, 7);
              @include props(pr, 20);
              @include props(pl, 20);
            }

            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 10, 750);
              @include props(mb, 12, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
              }
            }
          }
        }
      }
    }

    .keywordsArea{
      @include props(pt, 200);
      @include props(pb, 200);

      @include mq(md) {
        @include props(pt, 120, 750);
        @include props(pb, 100, 750);
        @include props(pr, 20, 750);
        @include props(pl, 20, 750);
      }

      .keywordsTit{
        font-family: $ZenMaruGothic;
        @include fz_vw(48);
        line-height: 1;
        letter-spacing: 1.92px;
        text-align: center;
        color: #666;
        @include props(mb, 70);

        @include mq(md) {
          @include fz_vw(48);
          letter-spacing: .96px;
          @include props(mb, 120, 750);
        }
      }
      .tagList{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        
        .tag{
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #808080;
          @include props(mr, 10);
          @include props(mb, 40);

          a{
            display: block;
            color: #808080;
            border-radius: 100px;
            background-color: #fff;
            @include props(pt, 7);
            @include props(pb, 7);
            @include props(pr, 20);
            @include props(pl, 20);
          }

          @include mq(md) {
            @include fz_vw(18);
            @include props(mr, 10, 750);
            @include props(mb, 40, 750);
  
            a{
              @include props(pt, 10, 750);
              @include props(pb, 10, 750);
              @include props(pr, 25, 750);
              @include props(pl, 25, 750);
            }
          }
        }
      }
    }

    .aboutContents{
      padding: 0 20px;
      @include props(pt, 200);

      @include mq(md) {
        padding: 0;
      }

      .box{
        max-width: 1000px;
        background-color: #fff;
        margin: 0 auto;
        @include props(pt, 85);
        @include props(pb, 85);

        @include mq(md) {
          @include props(pt, 120, 750);
          @include props(pb, 120, 750);
        }
      }

      .aboutContentsTit{
        @include props(w, 285);
        margin: 0 auto;
        @include props(mb, 70);

        @include mq(md) {
          @include props(w, 285, 750);
          @include props(mb, 80, 750);
        }
      }

      .aboutContentsTxt{
        font-family: $tsukushi;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 2.67;
        text-align: center;
        color: #4d4d4d;

        @include mq(md) {
          @include fz_vw(28);
          font-weight: 500;
          line-height: 2;
        }
      }
    }
  }

  //カテゴリー一覧
  .contentsArchive{
    .contentsArchiveMainview{
      background-color: #c7c57c;
      @include props(pt, 45);
      @include props(pb, 40);

      @include mq(md) {
        @include props(pt, 45, 750);
        @include props(pb, 40, 750);
      }
  
      &.topics{
        background-color: #6456b7;
      }
      &.iroha_story{
        background-color: #4a6bb7;
      }
      &.interview{
        background-color: #63a534;
      }
      &.column{
        background-color: #c7c57c;
      }
      &.research{
        background-color: #c78246;
      }
      &.review{
        background-color: #bc5680;
      }

      .mainviewTit{
        font-family: $ZenMaruGothic;
        @include fz_vw(52);
        line-height: 1.2;
        letter-spacing: 7.8px;
        text-align: center;
        color: #fff;
      
        @include mq(md) {
          letter-spacing: 3.9px;
        }
      }

      &.childCategory{
        .mainviewTit{
          @include fz_vw(40);
        }
      }
      .mainviewLead{
        font-family: $ZenMaruGothic;
        @include fz_vw(20);
        line-height: 1.8;
        letter-spacing: 2px;
        text-align: center;
        color: #fff;
        @include props(mt, 20);
      
        @include mq(md) {
          @include fz_vw(24);
          line-height: 2;
          letter-spacing: 1.2px;
          @include props(mt, 10, 750);
        }
      }
    }
    .articlesColumn{
      @include props(pt, 78);
      @include mq(md) {
        @include props(pt, 120, 750);
        @include props(pb, 200, 750);
      }

      .cateNavListBox{
        position: relative;
        @include props(mb,120);

        @include mq(md){
          @include props(pl,40,750);
          @include props(pr,40,750);
          @include props(mb,0,750);
        }

        #toggleBtn{
          text-align: center;
          @include props(mb,20);
          span{
            font-family: $YuMincho;
            @include fz_vw(28);
            line-height: 1.85;
            letter-spacing: 1px;

            &:after{
              content: "";
              width: .7em;
              height: .7em;
              background: url(/images/contents/modal_arrow.svg) center center no-repeat;
              background-size: contain;
              display: inline-block;
              margin-left: 0.9em;
              transition: transform .3s;
            }
          }

          &:hover{
            cursor: pointer;
          }

          &.isOpen{
            span{
              &:after{
                transform: rotate(180deg);
              }
            }
          }

          @include mq(md){
            position: absolute;
            @include props(b,70);
            left: 50%;
            transform: translate(-50%,0);
            @include props(w,590,750);
            border-bottom: 1px solid #E5E5E5;
          }
        }
        
        .cateNavList{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          background: $basicWhiteColor;
          padding: 1.55279% 3.10558%;

          @include mq(md){
            @include props(pb,140,750);
            @include props(pl,40,750);
            @include props(pr,40,750);
            @include props(pt,40,750);
            margin-left: auto;
            margin-right: auto;

            &:before,&::after{
              content: none;
            }
          }

          &:before{
            content: "";
            display: block;
            width: 23.129%;
            order: 1;
          }

          &:after{
            content: "";
            display: block;
            width: 23.129%;
            order: 1;
          }

          li{
            width: 23.129%;
            margin: 1.645% 0;
            border: 4px solid #fff;
            transition: display 1s;

            @include mq(md){
              @include props(w,275,750);
            }

            &.current{
              border: 4px solid #D6A1B7;
            }
          }
        }
      }

      .contentsArchiveListTit{
        max-width: 79.384%;
        width: 100%;
        font-family: $ZenMaruGothic;
        @include fz_vw(48);
        line-height: 1.15;
        letter-spacing: 2.88px;
        color: #4d4d4d;
        border-bottom: solid 1px #4d4d4d;
        @include props(pb, 10);
        @include props(mb, 60);

        @include mq(md) {
          max-width: 100%;
          @include fz_vw(70);
          letter-spacing: 1.4px;
          text-align: center;
          color: #666;
          border: none;
          @include props(pb, 0);
          @include props(pt, 160,750);
          @include props(mb, 160, 750);
        }
      }

      .flex{
        display: flex;
        flex-wrap: wrap;
        align-items: inherit;
        justify-content: space-between;
      }

      .contentsArchiveCard{
        position: relative;
        max-width: 100%;
        width: 100%;
        @include props(mb, 40);

        @include mq(md) {
          @include props(mb, 200, 750);
        }

        &:not(.firstCard){
          max-width: 48.354%;
          @include props(mb, 90);

          @include mq(md) {
            max-width: 100%;
            @include props(mb, 160, 750);

            &:nth-of-type(2n){
              &::after{
                left: auto;
                right: 0;
              }
    
              .img{
                margin: 0;
              }
              .txt{
                margin: 0 0 0 auto;
              }
            }
          }

          &::after{
            content: "";
            display: block;
            max-width: 94.506%;
            width: 100%;
            height: calc(100% - 10.3125vw);
            background-color: #fff;
            position: absolute;
            @include props(t, 165);
            left: 0;
            z-index: -1;

            @include mq(fixed) {
              height: calc(100% - 165px);
            }
            @include mq(desk) {
              height: calc(100% - 10.85526vw);
            }
            @include mq(tab) {
              height: calc(100% - 12.07906vw);
            }

            @include mq(md) {
              @include props(t, 350, 750);
              height: calc(100% - 46.66667vw);
            }
          }

          .img{
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .16);
            max-width: 94.506%;
            margin: 0 0 0 auto;

            @include mq(md) {
              max-width: 94.7%;
              box-shadow: 2px 2px 5px 0 rgba($color: #000000, $alpha: .16);
            }
          }
          .txt{
            max-width: 94.506%;
            margin: 0;
  
            &>a{
              @include props(pt, 35);
              @include props(pb, 20);
              @include props(pr, 14);
              @include props(pl, 50);
            }
  
            &::after{
              display: none;
            }

            @include mq(md) {
              max-width: 94.7%;
  
              &>a{
                @include props(pt, 50, 750);
                @include props(pb, 40, 750);
                @include props(pr, 40, 750);
                @include props(pl, 40, 750);
              }
            }
          }
          .contentsArchiveCardTit{
            @include fz_vw(18);
            letter-spacing: 1.26px;
            @include props(mb, 15);

            @include mq(md) {
              @include fz_vw(36);
              line-height: 1.44;
              letter-spacing: normal;
              @include props(mb, 60, 750);
            }
          }
          .tagList{
            @include props(pr, 12);
            @include props(pl, 50);
            @include props(pb, 30);
          
            .tag{
              a{
                @include props(pr, 15);
                @include props(pl, 15);
              }
            }

            @include mq(md) {
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(pb, 40, 750);
            
              .tag{
                a{
                  @include props(pr, 25, 750);
                  @include props(pl, 25, 750);
                }
              }
            }
          }
        }

        &:last-of-type{
          @include mq(md){
            @include props(mb,120,750);
          }
        }

        a{
          display: block;
          position: relative;
          z-index: 0;
        }

        &::after{
          @include mq(md) {
            content: "";
            display: block;
            max-width: 94.7%;
            width: 100%;
            height: 72%;
            background-color: #fff;
            position: absolute;
            right: 0;
            z-index: -1;
            @include props(t, 350, 750);
            height: calc(100% - 46.66667vw);
          }
        }

        .img{
          flex-shrink: 0;
          position: relative;
          z-index: 2;
          box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          overflow: hidden;
          max-width: 79.384%;
          width: 100%;

          img{
            width: 100%;
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
          }
        }
        .txt{
          position: relative;
          margin: 0 0 0 auto;
          z-index: 1;
          max-width: 82.891%;
          width: 100%;

          &>a{
            @include props(pt, 30);
            @include props(pb, 20);
            @include props(pr, 40);
            @include props(pl, 40);
          }

          &::after{
            content: "";
            display: block;
            max-width: 100%;
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: absolute;
            @include props(t, -75);
            right: 0;
            z-index: -1;
  
            @include mq(md) {
              display: none;
            }
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;

            &>a{
              @include props(pt, 60, 750);
              @include props(pb, 40, 750);
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(mt, 0);
            }
          }
        }
        .contentsArchiveCardTit{
          font-family: $tsukushi;
          @include fz_vw(24);
          font-weight: bold;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          @include mq(md) {
            @include fz_vw(36);
            line-height: 1.44;
            @include props(mb, 40, 750);
          }
        }
        .contentsArchiveCardTxt{
          font-family: $tsukushi;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          .underLine{
            text-decoration: underline;
          }

          @include mq(md) {
            @include fz_vw(28);
            line-height: 2;
            letter-spacing: .56px;
            @include props(pr, 0);
            @include props(mb, 40, 750);
          }
        }
        .contentsArchiveCardData{
          display: block;
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #4d4d4d;

          @include mq(md) {
            @include fz_vw(28);
          }
        }
        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include props(pr, 40);
          @include props(pl, 40);
          @include props(pb, 100);

          @include mq(md) {
            @include props(pr, 20, 750);
            @include props(pl, 40, 750);
            @include props(pb, 70, 750);
          }
          
          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            color: #808080;
            @include props(mr, 7);
            @include props(mb, 7);

            a{
              display: block;
              color: #808080;
              border-radius: 100px;
              border: solid 1px #adadad;
              @include props(pt, 7);
              @include props(pb, 7);
              @include props(pr, 20);
              @include props(pl, 20);
            }

            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 10, 750);
              @include props(mb, 12, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
              }
            }
          }
        }
      }
    }
  }

  //検索結果一覧
  .contentsSearchArchive{
    .articlesColumn{
      @include props(pt, 78);
      @include mq(md) {
        @include props(pt, 160, 750);
        @include props(pb, 200, 750);
      }

      .contentsSearchArchiveTit{
        font-family: $tsukushi;
        @include fz_vw(24);
        line-height: 1.5;
        color: #4d4d4d;
        @include props(mb, 120);

        .searchWord{
          @include fz_vw(32);
          font-weight: bold;
          @include props(mr, 15);
        }

        .num{
          @include fz_vw(32);
          font-weight: bold;
          @include props(mr, 10);
          @include props(ml, 10);
        }

        @include mq(md) {
          @include fz_vw(32);
          line-height: 1.75;
          @include props(pr, 80, 750);
          @include props(pl, 80, 750);
          @include props(mb, 80, 750);
  
          .searchWord{
            display: block;
            @include fz_vw(48);
            @include props(mr, 0);
          }
  
          .num{
            @include fz_vw(40);
            @include props(mr, 25, 750);
            @include props(ml, 25, 750);
          }
        }
      }

      .flex{
        display: flex;
        flex-wrap: wrap;
        align-items: inherit;
        justify-content: space-between;

        @include mq(md) {
          @include props(mb, 200, 750);
        }
      }
    
      .category{
        display: flex;
        align-items: center;
        font-family: $tsukushi;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1;
        @include props(mb, 20);
  
        &::before{
          content: "";
          display: block;
          background-image: url(/images/common/iroiroiroha_icon_01.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          @include props(w, 31);
          @include props(h, 26);
          @include props(mr, 5);
        }
  
        @include mq(md) {
          @include fz_vw(28);
          @include props(mb, 40, 750);
    
          &::before{
            @include props(w, 54, 750);
            @include props(h, 48, 750);
            @include props(mr, 10, 750);
          }
        }
  
        &.topics{
          color: #6456b7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_01.svg);
          }
        }
        &.iroha_story{
          color: #4a6bb7;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_02.svg);
          }
        }
        &.interview{
          color: #63a534;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_03.svg);
          }
        }
        &.column{
          color: #aaa73f;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_04.svg);
          }
        }
        &.research{
          color: #c78246;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_05.svg);
          }
        }
        &.review{
          color: #bc5680;
          &::before{
            background-image: url(/images/common/iroiroiroha_icon_06.svg);
          }
        }
      }

      .contentsArchiveCard{
        position: relative;
        max-width: 100%;
        width: 100%;
        @include props(mb, 80);

        @include mq(md) {
          @include props(mb, 100, 750);
        }

        &:not(.firstCard){
          max-width: 48.354%;
          @include props(mb, 90);

          @include mq(md) {
            max-width: 100%;
            @include props(mb, 100, 750);

            &:nth-of-type(2n){
              &::after{
                left: 0;
                right: auto;
              }
    
              .img{
                margin: 0 0 0 auto;
              }
              .txt{
                margin: 0;
              }
            }
          }

          &::after{
            content: "";
            display: block;
            max-width: 94.506%;
            width: 100%;
            height: calc(100% - 10.3125vw);
            background-color: #fff;
            position: absolute;
            @include props(t, 165);
            left: 0;
            z-index: -1;

            @include mq(fixed) {
              height: calc(100% - 165px);
            }
            @include mq(desk) {
              height: calc(100% - 10.85526vw);
            }
            @include mq(tab) {
              height: calc(100% - 12.07906vw);
            }

            @include mq(md) {
              left: auto;
              right: 0;
              @include props(t, 350, 750);
              height: calc(100% - 46.66667vw);
            }
          }

          .img{
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .16);
            max-width: 94.506%;
            margin: 0 0 0 auto;

            @include mq(md) {
              max-width: 94.7%;
              box-shadow: 2px 2px 5px 0 rgba($color: #000000, $alpha: .16);
              margin: 0;
            }
          }
          .txt{
            max-width: 94.506%;
            margin: 0;
  
            &>a{
              @include props(pt, 35);
              @include props(pb, 20);
              @include props(pr, 14);
              @include props(pl, 50);
            }
  
            &::after{
              display: none;
            }

            @include mq(md) {
              max-width: 94.7%;
              margin: 0 0 0 auto;
  
              &>a{
                @include props(pt, 50, 750);
                @include props(pb, 40, 750);
                @include props(pr, 40, 750);
                @include props(pl, 40, 750);
              }
            }
          }
          .contentsArchiveCardTit{
            @include fz_vw(18);
            letter-spacing: 1.26px;
            @include props(mb, 15);

            @include mq(md) {
              @include fz_vw(36);
              line-height: 1.44;
              letter-spacing: normal;
              @include props(mb, 60, 750);
            }
          }
          .tagList{
            @include props(pr, 12);
            @include props(pl, 50);
            @include props(pb, 30);
          
            .tag{
              a{
                @include props(pr, 15);
                @include props(pl, 15);
              }
            }

            @include mq(md) {
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(pb, 40, 750);
            
              .tag{
                a{
                  @include props(pr, 25, 750);
                  @include props(pl, 25, 750);
                }
              }
            }
          }
        }

        a{
          display: block;
          position: relative;
          z-index: 0;
        }

        &::after{
          @include mq(md) {
            content: "";
            display: block;
            max-width: 94.7%;
            width: 100%;
            height: 72%;
            background-color: #fff;
            position: absolute;
            right: 0;
            z-index: -1;
            @include props(t, 350, 750);
            height: calc(100% - 46.66667vw);
          }
        }

        .img{
          flex-shrink: 0;
          position: relative;
          z-index: 2;
          box-shadow: 10px 10px 20px 0 rgba($color: #000000, $alpha: .16);
          overflow: hidden;
          max-width: 79.384%;
          width: 100%;

          img{
            width: 100%;
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;
            box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .1);
          }
        }
        .txt{
          position: relative;
          margin: 0 0 0 auto;
          z-index: 1;
          max-width: 82.891%;
          width: 100%;

          &>a{
            @include props(pt, 30);
            @include props(pb, 20);
            @include props(pr, 40);
            @include props(pl, 40);
          }

          &::after{
            content: "";
            display: block;
            max-width: 100%;
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: absolute;
            @include props(t, -75);
            right: 0;
            z-index: -1;
  
            @include mq(md) {
              display: none;
            }
          }

          @include mq(md) {
            max-width: 94.7%;
            width: 100%;

            &>a{
              @include props(pt, 60, 750);
              @include props(pb, 40, 750);
              @include props(pr, 40, 750);
              @include props(pl, 40, 750);
              @include props(mt, 0);
            }
          }
        }
        .contentsArchiveCardTit{
          font-family: $tsukushi;
          @include fz_vw(24);
          font-weight: bold;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          @include mq(md) {
            @include fz_vw(36);
            line-height: 1.44;
            @include props(mb, 40, 750);
          }
        }
        .contentsArchiveCardTxt{
          font-family: $tsukushi;
          @include fz_vw(16);
          font-weight: 500;
          line-height: 2;
          color: #4d4d4d;
          @include props(mb, 20);

          .underLine{
            text-decoration: underline;
          }

          @include mq(md) {
            @include fz_vw(28);
            line-height: 2;
            letter-spacing: .56px;
            @include props(pr, 0);
            @include props(mb, 40, 750);
          }
        }
        .contentsArchiveCardData{
          display: block;
          font-family: $tsukushi;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          color: #4d4d4d;

          @include mq(md) {
            @include fz_vw(28);
          }
        }
        .tagList{
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @include props(pr, 40);
          @include props(pl, 40);
          @include props(pb, 100);

          @include mq(md) {
            @include props(pr, 20, 750);
            @include props(pl, 40, 750);
            @include props(pb, 70, 750);
          }
          
          .tag{
            font-family: $tsukushi;
            @include fz_vw(14);
            font-weight: 500;
            line-height: 1;
            color: #808080;
            @include props(mr, 7);
            @include props(mb, 7);

            a{
              display: block;
              color: #808080;
              border-radius: 100px;
              border: solid 1px #adadad;
              @include props(pt, 7);
              @include props(pb, 7);
              @include props(pr, 20);
              @include props(pl, 20);
            }

            @include mq(md) {
              @include fz_vw(18);
              @include props(mr, 10, 750);
              @include props(mb, 12, 750);
  
              a{
                @include props(pt, 10, 750);
                @include props(pb, 10, 750);
                @include props(pr, 20, 750);
                @include props(pl, 20, 750);
              }
            }
          }
        }
      }
    }
  }

  //記事詳細
  .contentsSingle{

    @include mq(md){
      .sideColumn{
        display: none !important;
      }
    }
    .contentsWrap{
      @include props(pt, 78);
      @include mq(md){
        @include props(pt, 55,750);
      }
  
      .sideColumn{
        padding-top: 0px !important;
        order: 2;
        max-width: 27.453% !important;
  
        @include mq(fixed){
          max-width: 25.1004% !important;
        }
        
        @include mq(md){
          order: inherit;
          max-width: 100% !important;
        }
      }
  
      .minContainer{
        flex-wrap: wrap;
        max-width: 1406px;
  
        @include mq(fixed){
          justify-content: center !important;
          max-width: 1534px;
        }
      }
  
      .singleColumn{
        @include mq(fixed){
          max-width: 64.6586345%;
          margin-right: 20px;
        }
  
        max-width: 70.718%;
        width: 100%;
        @include props(p, 50);
        background: $basicWhiteColor;
    
        @include mq(md) {
          @include props(w,670,750);
          @include props(pt, 100,750);
          @include props(pb, 100,750);
          @include props(pl, 40,750);
          @include props(pr, 40,750);
          margin: 0 auto;
        }
  
        .singleHead{
          @include props(mb,40);
          @include mq(md){
            @include props(mb,80,750);
          }
          .contentsSingleListTit{
            font-family: $tsukushi;
            @include fz_vw(32);
            line-height: 1.5;
            @include props(mb, 30);
            font-weight: bold;
        
            @include mq(md) {
              @include fz_vw(48);
              @include props(mb, 55,750);
            }
          }
  
          .contentsSingleData{
            font-family: $tsukushi;
            @include fz_vw(14);
  
            @include mq(md) {
              @include fz_vw(28);
            }
          }
        }
  
        .singleContent{
          .teacherBox{
            padding: 3.464%;
            position: relative;
            border-radius: 5px;
            @include mq(md){
              @include props(pt,40,750);
              @include props(pl,40,750);
              @include props(pr,40,750);
              @include props(pb,50,750);
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              justify-content: space-between;
            }

            .img{
              width: 16.974%;
              position: absolute;
              img{
                margin: 0
              }

              @include mq(md){
                position: inherit;
                @include props(w,150,750);
                @include props(h,150,750);
              }
            }

            .title{
              width: 78.03%;
              margin-left: auto;
              @include props(mb,20);

              @include mq(md){
                @include props(w,320,750);
                @include props(mb,0,750);
              }

              .name{
                  @include fz_vw(20);
                  font-family: $tsukushi;
                  font-weight: bold;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.5;
                  @include props(mb,20);

                  @include mq(md){
                    @include fz_vw(40);
                    line-height: 1.2;
                    @include props(mb,30,750);
                  }
              }

              .teacherTit{
                  @include fz_vw(16);
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2;
                  letter-spacing: normal;

                  @include mq(md){
                    @include fz_vw(24);
                    line-height: 1.83;
                  }
              }
            }

            .desc{
              width: 78.03%;
              margin-left: auto;

              @include mq(md){
                width: 100%;
                @include props(mt,40,750);
              }

              p,span,a{
                  font-family: $tsukushi;
                  @include fz_vw(16);
                  font-weight: normal;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 2;
                  letter-spacing: normal;
                  color: #4d4d4d;

                  @include mq(md){
                    @include fz_vw(28);
                  }
              }

              a{
                text-decoration: underline
              }
            }
          }
          
          #toc_container{
            @include props(mt, 40);
            @include props(mb, 80);
            display: none;

            @include mq(desk){
              margin-top: 2vw;
              margin-bottom: 5vw;
            }
            
            @include mq(tab){
              margin-top: 2vw;
              margin-bottom: 5vw;
            }

            @include mq(md){
              @include props(mt,15,750);
              @include props(mb,90,750);
            }
            
            .toc_title{
              @include fz_vw(28);
              font-weight: bold;
              line-height: 1.71;
              @include props(mb,20);
  
              @include mq(md){
                @include fz_vw(40);
                @include props(mb,50,750);
              }
            }
  
            .toc_list{
              @include props(pt,40);
              @include props(pb,40);
              @include props(pl,50);
              @include props(pr,50);
  
              li{
                @include props(pl,20);
                position: relative;
                list-style: none;
                &:before{
                  content: "・";
                  font-size: 1em;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
                a{
                  font-family: $tsukushi;
                  text-decoration: underline;
                  text-decoration-thickness: 1px;
                  @include props(mb,16);
                  display: inline-block;
                  color: #4D4D4D;
                  @include fz_vw(18);
                }
              }
  
              @include mq(md){
                @include props(pt,100,750);
                @include props(pb,100,750);
                @include props(pl,40,750);
                @include props(pr,40,750);
  
                li{
                  @include props(pl,40,750);
                  &:before{
                    top: 0.6em;
                  }
  
                  a{
                    @include fz_vw(30);
                    @include props(mb,20,750);
                  }
                }
              }
            }
          }

          &.tocActive{
            #toc_container{
              display: block;
            }
            &.tocMinimum{
              #toc_container{
                .toc_list li ul{
                  display: none;
                }
              }
            }
          }
  
          h2{
            font-family: $tsukushi;
            @include props(mb,60);
            @include fz_vw(28);
            font-weight: bold;
            line-height: 2;
            @include props(pt,16);
            @include props(pb,16);
  
            @include mq(md){
              @include fz_vw(40);
              line-height: 1.5;
              @include props(mb,40,750);
              @include props(pt,30,750);
              @include props(pb,30,750);
            }
          }
  
          img{
            @include props(mt,20);
            @include props(mb,20);
  
            &.alignleft{
              display: inline;
              float: left;
              margin: 0 auto;
              @include props(mr,20);
            }
  
            &.aligncenter{
              display: block;
              margin-left: auto;
              margin-right: auto;
            }
  
            &.alignright{
              display: inline;
              float: right;
              margin: 0 auto;
              @include props(ml,20);
            }
  
            @include mq(md){
              @include props(mt,16,750);
              @include props(mb,16,750);
  
              &.alignleft{
                @include props(mr,30,750);
              }
    
              &.alignright{
                @include props(ml,30,750);
              }
            }
          }
  
          p{
            font-family: $tsukushi;
            @include fz_vw(16);
            line-height: 2;
  
            a{
              font-family: $tsukushi;
              text-decoration: underline;
              color: #4D4D4D;
              word-break: break-all;
            }
  
            @include mq(md){
              @include fz_vw(28);
              letter-spacing: .4px;
            }

            span{
              font-family: $tsukushi;
            }
          }
  
          h3{
            font-family: $tsukushi;
            @include fz_vw(24);
            font-weight: bold;
            line-height: 2;
            @include props(mb,60);
  
            @include mq(md){
              @include fz_vw(36);
              line-height: 1.5;
              @include props(mb,80,750);
            }
          }
  
          h4{
            font-family: $tsukushi;
            @include fz_vw(18);
            font-weight: 1.77;
            font-weight: bold;
            @include props(mb,20);
  
            @include mq(md){
              @include fz_vw(30);
              line-height: 1.86;
              @include props(mb,40,750);
            }
          }

          table{
            border: 1px solid #808080;

            thead,
            tbody{
              border: inherit;
            }
            tr,
            td,
            th{
              font-family: $tsukushi;
              @include fz_vw(16);
              line-height: 2;
              border: inherit;
              word-break: break-all;
              @include props(p,10);
    
              a{
                font-family: $tsukushi;
                text-decoration: underline;
                color: #4D4D4D;
                word-break: break-all;
              }

              span{
                font-family: $tsukushi;
              }
    
              @include mq(md){
                @include fz_vw(28);
                letter-spacing: .4px;
                @include props(p,10, 750);
              }
            }
          }

          div:not(#toc_container){
            & > ul:not([class]),
            & > ol:not([class]){
              @include props(pr, 20);
              @include props(pl, 20);
  
              @include mq(md){
                @include props(pl, 40,750);
                @include props(pr, 40,750);
              }
  
              li{
                list-style:inherit;
                font-family: $tsukushi;
                @include fz_vw(16);
                line-height: 2;
                word-break: break-all;
      
                a{
                  font-family: $tsukushi;
                  text-decoration: underline;
                  color: #4D4D4D;
                  word-break: break-all;
                }
  
                span{
                  font-family: $tsukushi;
                }
      
                @include mq(md){
                  @include fz_vw(28);
                  letter-spacing: .4px;
                }
              }
            }
          }

          .boldMarker{
            display: inline;
            font-family: $tsukushi;
            @include fz_vw(16);
            line-height: 2;
            word-break: break-all;
            padding: 2px;

            &.red{
              background-color: #FFC0CB;
            }
            &.yellow{
              background-color: #fff6b7;
            }
            &.blue{
              background-color: #ADD8E6;
            }
  
            @include mq(md){
              @include fz_vw(28);
              letter-spacing: .4px;
            }
          }

          .marker{
            display: inline;
            font-family: $tsukushi;
            @include fz_vw(16);
            line-height: 2;
            word-break: break-all;

            &.red{
              background: linear-gradient(transparent 70%,#FFC0CB 0);
            }
            &.yellow{
              background: linear-gradient(transparent 70%,#fff6b7 0);
            }
            &.blue{
              background: linear-gradient(transparent 70%,#ADD8E6 0);
            }
  
            @include mq(md){
              @include fz_vw(28);
              letter-spacing: .4px;
            }
          }

            // 動画スタイル
            .movie_wrap {
              position: relative;
              height: 0;
              padding-bottom: 56.25%;

              iframe {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0;
              }
          }

          .twitter-tweet{
            margin: 0 auto !important;
          }
  
          .relatedArticle{
            @include props(mt,25);
            @include props(mb,25);
  
            @include mq(md){
              @include props(mt,100,750);
              @include props(mb,100,750);
  
              a{
                flex-wrap: wrap;
                @include props(pt,40,750);
                @include props(pl,40,750);
                @include props(pr,40,750);
                @include props(pb,120,750);
  
  
                .imgBox{
                  width: 100%;
                  max-width: inherit;
                  @include props(mb,30,750);
                }
  
                .txtBox{
                  width: 100%;
                  margin: 0 auto;
  
                  .relatedArticleTit{
                    @include fz_vw(32);
                    line-height: 1.55;
                  }
                }
  
                .more{
                  @include props(b,60,750);
                  right: 50%;
                  transform: translate(50%,50%);
                  @include fz_vw(28);
                  width: 100%;
                  letter-spacing: .4px;
                  @include props(pl,40,750);
                  @include props(pr,40,750);
                  text-align: right;
  
                }

                .blank{
                  @include props(b,60,750);
                  right: 50%;
                  transform: translate(50%,50%);
                  @include fz_vw(28);
                  width: 100%;
                  letter-spacing: .4px;
                  @include props(pl,40,750);
                  @include props(pr,40,750);
                  text-align: right;
                }
              }
            }
  
            a{
              position: relative;
              display: flex;
              @include props(pt,33);
              @include props(pl,40);
              @include props(pr,50);
              @include props(pb,60);
  
              .imgBox{
                max-width: 320px;
                position: relative;
                overflow: hidden;
                backface-visibility: hidden;
                z-index: 1;
  
                img{
                  @include props(m,0);
                  transition: transform 2s ease-out;
                  -webkit-backface-visibility: hidden;
                  backface-visibility: hidden;
                }
              }
  
              .txtBox{
                @include props(ml,42);
  
                .relatedArticleTit{
                  @include fz_vw(20);
                  font-weight: bold;
                  line-height: 2;
                  color: #4D4D4D;
                }
              }
  
              .more{
                position: absolute;
                @include props(b,30);
                @include props(r,25);
                @include fz_vw(16);
                text-align: center;
                font-family: $YuMincho;
                line-height: 1.5;
  
  
                &:after{
                  content: "";
                  display: inline-block;
                  background-image: url(/images/contents/next_arrow.svg);
                  background-repeat: no-repeat;
                  background-position: bottom right;
                  background-size: contain;
                  width: 2em;
                  height: 0.88em;
                  margin-left: .5em;
                }
              }

              .blank{
                position: absolute;
                @include props(b,30);
                @include props(r,25);
                @include fz_vw(18);
                text-align: center;
                font-family: $YuMincho;

                &:after{
                  content: "";
                  display: inline-block;
                  background-image: url(/images/common/blank_icon.svg);
                  background-repeat: no-repeat;
                  background-position: center right;
                  background-size: contain;
                  width: .8em;
                  height: .8em;
                  padding-left: .5em;
                }
              }

              &:hover{
                .imgBox{
                  img{
                    transform: scale(1.02) rotate(0.001deg);
                    backface-visibility: hidden;
                  }
                }
                .more{
                  &:after{
                    animation: 1s arrow linear forwards;
                  }
                }
              }
            }
          }
  
          // .BtnTemp{
          //   max-width: 400px;
          //   @include props(mt,60);
          //   @include props(mb,60);
          //   margin-right: auto;
          //   margin-left: auto;
  
          //   a{
          //     display: block;
          //     @include fz_vw(20);
          //     line-height: 2.4;
          //     text-align: center;
          //     text-decoration: none;
          //     @include props(p,20);
          //     border-width: 1px;
          //     border-style: solid;
          //     border-color: #fff;
          //     transition: all .3s;

          //     &:hover{
          //       opacity: .8;
          //     }
          //   }
  
          //   &.circleBtnBlank{
          //     a{
          //       border-radius: 5px;
  
          //       p{
          //         display: flex;
          //         justify-content: center;
          //         align-items: center;
          //         svg{
          //           @include props(w,22);
          //           @include props(h,21);
          //           @include props(ml,10);

          //           @include mq(md){
          //             @include props(w,24,750);
          //             @include props(h,22,750);
          //             @include props(ml,10,750);
          //           }
          //         }
          //       }
          //     }
          //   }

              
          //   &.circleBtn{
          //     a{
          //       border-radius: 5px;
  
          //       p{
          //         display: flex;
          //         justify-content: center;
          //         align-items: center;
          //         svg{
          //           @include props(w,41);
          //           @include props(h,10);
          //           @include props(ml,10);
          //           transition: transform .5s;
          //           transform: scale(1, 1);
          //           transform-origin: left top;

          //           @include mq(md){
          //             @include props(w,41,750);
          //             @include props(h,10,750);
          //             @include props(ml,10,750);
          //           }
          //         }
          //       }

          //       &:hover{
          //         p{
          //           svg{
          //             transform: scale(0, 1);
          //             transform-origin: right top;
          //           }
          //         }
          //       }
          //     }
          //   }
  
          //   @include mq(md){
          //     width: 100%;
          //     max-width: inherit;
          //     @include props(mt,100,750);
          //     @include props(mb,100,750);
  
          //     a{
          //       @include fz_vw(32);
          //       line-height: 1.75;
          //       @include props(p,30,750);
          //     }
    
          //     &.circleBtnBlank{
          //       a{
          //         border-radius: 1.33vw;
    
          //         p{
          //           &:after{
          //             @include props(w,32,750);
          //             // @include props(h,30,750);
          //             @include props(ml,20,750);
          //           }
          //         }
          //       }
          //     }

          //     &.circleBtn{
          //       a{
          //         border-radius: 1.33vw;
    
          //         span{
          //           &:after{
          //             @include props(w,56,750);
          //             @include props(ml,20,750);
          //             min-width: 10.56%;
          //           }
          //         }
          //       }
          //     }
          //   }
          // }
  
          .textBgColor{
            font-family: $tsukushi;
            @include props(pt,50);
            @include props(pb,50);
            @include props(pl,30);
            @include props(pr,30);
            @include fz_vw(16);
  
            @include mq(md){
              @include props(pt,50,750);
              @include props(pb,50,750);
              @include props(pl,25,750);
              @include props(pr,25,750);
              @include fz_vw(28);
            }
          }
  
          .quotesbox{
            @include props(pt,50);
            @include props(pb,50);
            @include props(pl,30);
            @include props(pr,30);
            position: relative;
            &:before{
              content: "";
              position: absolute;
              @include props(t,50);
              @include props(l,50);
              background:url(/images/contents/single/quotes_icon.svg) center center no-repeat;
              background-size: contain;
              @include props(w,36);
              @include props(h,30);
            }
  
            &:after{
              content: "";
              position: absolute;
              @include props(b,50);
              @include props(r,50);
              background:url(/images/contents/single/quotes_icon.svg) center center no-repeat;
              background-size: contain;
              @include props(w,36);
              @include props(h,30);
              transform: rotate(180deg);
            }
  
            p{
              @include fz_vw(16);
              @include props(w,603);
              @include props(mt,20);
              @include props(mb,20);
              margin-left: auto;
              margin-right: auto;
            }
  
            @include mq(md){
              @include props(pt,50,750);
              @include props(pb,50,750);
              @include props(pl,25,750);
              @include props(pr,25,750);
  
              &:before{
                @include props(w,36,750);
                @include props(h,30,750);
                @include props(t,50,750);
                @include props(l,25,750);
              }
  
              &:after{
                @include props(w,36,750);
                @include props(h,30,750);
                @include props(b,50,750);
                @include props(r,25,750);
              }
  
              p{
                @include fz_vw(28);
                @include props(mt,50,750);
                @include props(mb,50,750);
                @include props(w,514,750);
              }
            }
          }
  
          .tagList{
            display:flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @include props(mt,80);
            @include props(mb,80);
  
            .tag{
              margin: 6px;
              border: 1px solid #808080;
              border-radius: 15px;
              font-family: $tsukushi;
  
              a{
                display: block;
                padding: 7px 20px;
                line-height: 1;
                @include fz_vw(14);
                color: #808080;
              }
            }
  
            @include mq(md){
              justify-content: left;
              @include props(mt,60,750);
              @include props(mb,60,750);
  
              .tag{
                @include props(mt,20,750);
                @include props(mr,12,750);
                @include props(mb,20,750);
                @include props(ml,0,750);
                border-radius: 26px;
    
                a{
                  @include props(pt,10,750);
                  @include props(pb,10,750);
                  @include props(pr,20,750);
                  @include props(pl,20,750);
                  @include fz_vw(18);
                }
              }
            }
          }
  
          .articleBtnWrap{
            @include props(mb,90);
  
            @include mq(md){
              @include props(mb,140,750);
            }
  
            .articleBtnColumn{
              display: flex;
              justify-content: center;
              position: relative;
  
              &:before{
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
              }
  
              a{
                width: 50%;
                @include props(p,35);
                @include mq(md){
                  @include props(pt,80,750);
                  @include props(pb,80,750);
                }
  
                span{
                  display: inline-block;
                  @include props(mb,30);
                  @include fz_vw(18);
                  font-family: $YuMincho;
  
                  @include mq(md){
                    @include fz_vw(30);
                    @include props(mb,0,750);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                  }
                }
  
                p{
                  @include fz_vw(16);
                  line-height: 2;
                  font-weight: bold;
                  text-align: left;
                }
  
                &.backArticleBtn{
                  margin: 0 auto 0 0;
                  text-align: end;
  
                  @include mq(md){
                    @include props(pl,0,750);
                    @include props(pr,30,750);
                  }
  
                  span{
                    text-align: end;
  
                    &:before{
                      content: "";
                      display: inline-block;
                      background-image: url(/images/contents/single/back_article_icon.svg);
                      background-repeat: no-repeat;
                      background-position: center right;
                      background-size: contain;
                      @include props(w,74);
                      @include props(h,10);
                      @include props(mr,10);
  
                      @include mq(md){
                        background-image: url(/images/contents/single/back_article_icon_sp.svg);
                        @include props(w,72,750);
                        @include props(h,17,750);
                        @include props(mr,10);
                      }
                    }
                  }

                  &:hover{
                    span{
                      &:before{
                        animation: 1s back linear forwards;
                      }
                    }
                  }
                }
  
                &.nextArticleBtn{
                  margin: 0 0 0 auto;
  
                  @include mq(md){
                    @include props(pr,0,750);
                    @include props(pl,30,750);
                  }
  
                  span{
                    
                    &:after{
                      content: "";
                      display: inline-block;
                      background-image: url(/images/contents/single/next_article_icon.svg);
                      background-repeat: no-repeat;
                      background-position: center right;
                      background-size: contain;
                      @include props(w,74);
                      @include props(h,10);
                      @include props(ml,10);
  
                      @include mq(md){
                        background-image: url(/images/contents/single/next_article_icon_sp.svg);
                        @include props(w,72,750);
                        @include props(h,17,750);
                        @include props(ml,10);
                      }
                    }
                  }

                  &:hover{
                    span{
                      &:after{
                        animation: 1s arrow linear forwards;
                      }
                    }
                  }
                }
              }
            }
          }
  
          .pageBackWrap{
            text-align: end;
  
            .categoryBack{
              @include props(mb,30);
  
              @include mq(md){
                @include props(mb,40,750);
              }
              
              a{
                font-family: $tsukushi;
                &:before{
                  content: "";
                  display: inline-block;
                  background: url(/images/contents/single/category_back_icon.svg) center center no-repeat;
                  background-size: contain;
                  @include props(w,19);
                  @include props(h,17);
                  @include props(mr,10);
  
                  @include mq(md){
                    @include props(w,33,750);
                    @include props(h,30,750);
                    @include props(mr,20,750);
                  }
                }
              }
            }
  
            .contentsBack{
              a{
                font-family: $tsukushi;
                &:before{
                  content: "";
                  display: inline-block;
                  background: url(/images/contents/single/contents_back_icon.svg) center center no-repeat;
                  background-size: contain;
                  @include props(w,19);
                  @include props(h,17);
                  @include props(mr,10);
  
                  @include mq(md){
                    @include props(w,39,750);
                    @include props(h,27,750);
                    @include props(mr,20,750);
                  }
                }
              }
            }
  
            a{
              color: #4D4D4D;
              @include fz_vw(18);
              position: relative;
  
              @include mq(md){
                @include fz_vw(30);
              }
            }
          }

          .w_b_ava_box{

            .w_b_ava_effect{
              margin-left: auto;
              margin-right: auto;
            }
          }

          .w_b_box{
            img{
              margin: 0;
              padding: 0;
            }

            .w_b_space{
              height: 0px !important
            }
          }

          .w_b_name{
            @include fz_vw(14);
            font-family: $tsukushi;
            @include props(mt,12);
            letter-spacing: -0.04em;
            @include mq(md){
              @include fz_vw(24);
              @include props(mt,18,750);
            }
          }
  
          .w_b_bal{
            @include fz_vw(16);
            font-family: $tsukushi;
            line-height: 2;

            @include mq(md){
              @include fz_vw(28);
            }
          }
  
          .w_b_shadow_L,.w_b_shadow_R{
            box-shadow: none !important;
          }

          .w_b_talk_L:before{
            transform: rotate(-135deg) !important;
            left: -6px !important;
          }

          .w_b_talk_L:after{
            transform: rotate(-135deg) !important;
            left: -5px !important;
          }

          .w_b_talk_R:before{
            transform: rotate(135deg) !important;
            right: -6px !important;
          }

          .w_b_talk_R:after{
            transform: rotate(135deg);
            right: -5px;
          }

  
          .w_b_talk{
            border-radius: 5px;
          }
  
          .shereLayout{
            display: flex;
            justify-content: space-between;
            max-width: 274px;
            margin-left: auto;
            margin-right: auto;
            @include props(mb,75);
            @include props(mt,80);
  
            .shereLayoutItem{
              overflow: hidden;
              height: 20px;
  
              &:nth-child(1){
                width: 80px;
              }
  
              &:nth-child(2){
                width: 88px;
              }
  
              &:nth-child(3){
                width: 79px;
              }
  
              img{
                margin: 0;
              }
            }
          }
        }
  
        &.topics{
          .contentsSingleListTit{
            color: #6456b7;
          }

          .teacherBox{
            border: 1px solid #6456b7;
          }
  
          .toc_title{
            color: #6456b7;
          }
  
          .toc_list{
            background-color: rgba(100,86,183,.1);
          }
  
          h2{
            border-top: 1px solid #6456b7;
            border-bottom: 1px solid #6456b7;
          }
  
          h3{
            border-bottom: 1px solid #6456b7;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #6456b7;
            border-bottom: 1px solid #6456b7;
  
            .articleBtnColumn{
              &:before{
                background: #6456b7;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #6456b7;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #6456b7 !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #6456b7 !important;
            border-right-color: #6456b7 !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #6456b7 !important;
            border-left-color: #6456b7 !important;
          }
        }
  
        &.iroha_story{
          .contentsSingleListTit{
            color: #4a6bb7;
          }

          .teacherBox{
            border: 1px solid #4a6bb7;
          }
  
          .toc_title{
            color: #4a6bb7;
          }
  
          .toc_list{
            background-color: rgba(74,107,183,.1);
          }
  
          h2{
            border-top: 1px solid #4a6bb7;
            border-bottom: 1px solid #4a6bb7;
          }
  
          h3{
            border-bottom: 1px solid #4a6bb7;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #4a6bb7;
            border-bottom: 1px solid #4a6bb7;
  
            .articleBtnColumn{
              &:before{
                background: #4a6bb7;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #4a6bb7;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #4a6bb7 !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #4a6bb7 !important;
            border-right-color: #4a6bb7 !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #4a6bb7 !important;
            border-left-color: #4a6bb7 !important;
          }
        }
  
        &.interview{
          .contentsSingleListTit{
            color: #63a534;
          }

          .teacherBox{
            border: 1px solid #63a534;
          }
  
          .toc_title{
            color: #63a534;
          }
  
          .toc_list{
            background-color: rgba(99,165,52,.1);
          }
  
          h2{
            border-top: 1px solid #63a534;
            border-bottom: 1px solid #63a534;
          }
  
          h3{
            border-bottom: 1px solid #63a534;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #63a534;
            border-bottom: 1px solid #63a534;
  
            .articleBtnColumn{
              &:before{
                background: #63a534;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #63a534;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #63a534 !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #63a534 !important;
            border-right-color: #63a534 !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #63a534 !important;
            border-left-color: #63a534 !important;
          }
        }
  
        &.column{
          .contentsSingleListTit{
            color: #AAA73F;
          }

          .teacherBox{
            border: 1px solid #AAA73F;
          }
  
          .toc_title{
            color: #AAA73F;
          }
  
          .toc_list{
            background-color: rgba(170,167,63,.1);
          }
  
          h2{
            border-top: 1px solid #AAA73F;
            border-bottom: 1px solid #AAA73F;
          }
  
          h3{
            border-bottom: 1px solid #AAA73F;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #AAA73F;
            border-bottom: 1px solid #AAA73F;
  
            .articleBtnColumn{
              &:before{
                background: #AAA73F;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #AAA73F;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #AAA73F !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #AAA73F !important;
            border-right-color: #AAA73F !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #AAA73F !important;
            border-left-color: #AAA73F !important;
          }
        }
  
        &.research{
          .contentsSingleListTit{
            color: #c78246;
          }

          .teacherBox{
            border: 1px solid #c78246;
          }
  
          .toc_title{
            color: #c78246;
          }
  
          .toc_list{
            background-color: rgba(199,130,70,.1);
          }
  
          h2{
            border-top: 1px solid #c78246;
            border-bottom: 1px solid #c78246;
          }
  
          h3{
            border-bottom: 1px solid #c78246;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #c78246;
            border-bottom: 1px solid #c78246;
  
            .articleBtnColumn{
              &:before{
                background: #c78246;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #c78246;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #c78246 !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #c78246 !important;
            border-right-color: #c78246 !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #c78246 !important;
            border-left-color: #c78246 !important;
          }
        }
  
        &.review{
          .contentsSingleListTit{
            color: #bc5680;
          }

          .teacherBox{
            border: 1px solid #bc5680;
          }
  
          .toc_title{
            color: #bc5680;
          }
  
          .toc_list{
            background-color: rgba(188,86,128,.1);
          }
  
          h2{
            border-top: 1px solid #bc5680;
            border-bottom: 1px solid #bc5680;
          }
  
          h3{
            border-bottom: 1px solid #bc5680;
          }
  
          .articleBtnWrap{
            border-top: 1px solid #bc5680;
            border-bottom: 1px solid #bc5680;
  
            .articleBtnColumn{
              &:before{
                background: #bc5680;
              }
            }
          }
  
          .relatedArticle{
            border: 1px solid #bc5680;
          }
  
          .w_b_talk_L,.w_b_talk_R{
            border: 1px solid #bc5680 !important;
          }
  
          .w_b_talk_L:before{
            border-top-color: #bc5680 !important;
            border-right-color: #bc5680 !important;
          }
  
          .w_b_talk_R:before{
            border-top-color: #bc5680 !important;
            border-left-color: #bc5680 !important;
          }
        }
  
      }
  
      .recommendedArea{
        max-width: 1534px;
        margin: 0 auto;
        @include props(pt,200);
        order: 3;
  
        @include mq(md){
          order: inherit;
          @include props(pt,260,750);
          padding-left: 20px;
          padding-right: 20px;
        }
  
        .recommendedTit{
          font-family: $ZenMaruGothic;
          @include fz_vw(48);
          line-height: 1;
          letter-spacing: 1.92px;
          text-align: center;
          color: #666;
          @include props(mb, 132);
      
          @include mq(md) {
            @include fz_vw(48);
            letter-spacing: .96px;
            @include props(mb, 80, 750);
          }
        }
  
        .recommendedList{
          display: flex;
          justify-content: space-between;
          align-items: inherit;
          flex-wrap: wrap;
  
          &:after{
            content: "";
            display: block;
            width: 30.12%;
            @include mq(md){
              display: none;
            }
          }
          
          .contentsArchiveCard{
            position: relative;
            width: 30.12%;
            @include props(mb,80,750);

            @include mq(pc){
              &:nth-last-of-type(1),&:nth-last-of-type(2):nth-of-type(3n + 1),&:nth-last-of-type(2):nth-of-type(3n + 2),&:nth-last-of-type(3):nth-of-type(3n + 1){
                @include props(mb,0);
              }
            }
  
            @include mq(md){
              width: 100%;
              max-width: inherit;
              display:flex;
              align-items: flex-start;
              @include props(mb,80,750);
              @include props(pt,40,750);
              @include props(pb,40,750);
  
              &:last-of-type{
                @include props(mb,0,750);
              }
            }
  
            &:after{
              content: "";
              display: block;
              max-width: 94.506%;
              width: 100%;
              height: 62.691%;
              background-color: #fff;
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: -1;
  
              @include mq(md){
                height: 100%;
                @include props(w,510,750);
                left: inherit;
                right: -20px;
              }
            }
  
            .img{
              max-width: 94.506%;
              box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .16);
                margin: 0 0 0 auto;
  
              @include mq(md){
                @include props(w,300,750);
                @include props(mr,35,750);
                box-shadow: none;
              }
            }
  
            .txt{
              position: relative;
              max-width: 94.506%;
              z-index: 1;
  
              @include mq(md){
                @include props(w,335,750);
              }
  
              &>a{
                @include props(pt, 35);
                @include props(pb, 20);
                @include props(pr, 14);
                @include props(pl, 50);
  
                @include mq(md){
                  @include props(p, 0,750);
                }
                }
  
                .listCate{
                display: flex;
                align-items: center;
                font-family: $tsukushi;
                @include fz_vw(18);
                font-weight: 500;
                line-height: 1;
                @include props(mb, 20);
  
                @include mq(md){
                  @include fz_vw(28);
                  @include props(mb, 25,750);
  
                  &:before{
                    @include props(w, 46,750);
                    @include props(h, 48,750);
                    @include props(mr, 10,750);
                  }
                }
  
                &:before{
                  content: "";
                  display: block;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center;
                  @include props(w, 30);
                  @include props(h, 30);
                  @include props(mr, 5);
                }
  
                &.topics{
                  color: #6456B7;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_01.svg);
                  }
                }
  
                &.iroha_story{
                  color: #4a6bb7;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_02.svg);
                  }
                  }
                  &.interview{
                  color: #63a534;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_03.svg);
                  }
                  }
                  &.column{
                  color: #aaa73f;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_04.svg);
                  }
                  }
                  &.research{
                  color: #c78246;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_05.svg);
                  }
                  }
                  &.review{
                  color: #bc5680;
                  &::before{
                    background-image: url(/images/common/iroiroiroha_icon_06.svg);
                  }
                  }
                }
  
                .contentsArchiveCardTit{
                  font-family: $tsukushi;
                  @include fz_vw(18);
                  letter-spacing: 1.26px;
                  @include props(mb, 15);
                  font-weight: bold;
            
                  @include mq(md) {
                    @include fz_vw(28);
                    line-height: 1.44;
                    letter-spacing: normal;
                    @include props(mb, 27, 750);
                  }
                }
  
                .contentsArchiveCardData{
                  font-family: $tsukushi;
                  @include fz_vw(14);
    
                  @include mq(md){
                    @include fz_vw(28);
                  }
                }
  
                .tagList{
                  @include props(pr, 12);
                  @include props(pl, 50);
                  @include props(pb, 30);
                  display: flex;
                  flex-wrap: wrap;
                  
                  .tag{
                    font-family: $tsukushi;
                    @include fz_vw(14);
                    font-weight: 500;
                    line-height: 1;
                    color: #808080;
                    @include props(mr, 7);
                    @include props(mb, 7);
                    a{
                    display: block;
                    color: #808080;
                    border-radius: 100px;
                    border: solid 1px #adadad;
                    @include props(pt, 7);
                    @include props(pb, 7);
                    @include props(pr, 10);
                    @include props(pl, 10);
                    }
                  }
                }
            }
  
            a{
              display: block;
              position: relative;
              z-index: 0;
              }
          }
        }
      }
    }
  }
}