@charset "utf-8";
/* アニメーション関連---------------------- */
.fadeIn {
  $fadeIn: &;
  opacity: 0;
  visibility:hidden;
  transform: translateY(100px);
  transition: opacity .7s .2s, transform .7s .2s;//transition: opacity 1s .2s, transform 1s .2s;
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: none;
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
  #{$fadeIn}_second {
    opacity: 0;
    visibility:hidden;
    transition: opacity .8s 1s;
  }
  #{$fadeIn}_third {
    opacity: 0;
    visibility:hidden;
    transform: translateY(30px);
    transition: opacity .8s .7s, transform .8s .7s;
  }
}

.fadeIn2 {
  $fadeIn: &;
  opacity: 0;
  visibility:hidden;
  transition: opacity .7s .2s;//transition: opacity 1s .2s;
  backface-visibility: hidden;
  &.active {
    opacity: 1;
    visibility: visible;
    #{$fadeIn}_second {
      opacity: 1;
      visibility: visible;
    }
    #{$fadeIn}_third {
      opacity: 1;
      visibility: visible;
    }
  }
  #{$fadeIn}_second {
    opacity: 0;
    visibility:hidden;
    transition: opacity .8s 1s;
  }
  #{$fadeIn}_third {
    opacity: 0;
    visibility:hidden;
    transition: opacity .8s .7s;
  }
}

.arrow{
  &:after{
    transition: transform .5s;
    transform: scale(1, 1);
    transform-origin: left top;
  }
}

.animeLinkEachsBtn.animeLinkEachBtn{
  &:hover,
  &.isActive{
    .arrow{
    &::after{
      animation: 1s arrow linear forwards;
    }
    }
  }
}

.animeLink:hover{
  .arrow{
      svg{
        animation: 1s arrow linear forwards;
      }

      &:after{
        animation: 1s arrow linear forwards;
      }
    }
  }

.animeLink {
  cursor: pointer;
}
.animeLink,
.animeLinkEach {
  display: block;
  &Img {
    display: block;
    transition: .8s;
    @include hoverImg;
    &.notHover {
      &.isActive {
        opacity: .7;
      }
    }
  }
  &Btn {
    display: block;
  }
}
.megaMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: opacity .8s .6s;
  @include props(h, 248);
  max-height: none;
  @include mq(md) {
    display: none;
  }
  .megaMenuTit {
    opacity: 0;
  }
  .megaMenuLink {
    opacity: 0;
  }
  &.isShow {
    visibility: visible;
    opacity: 1;
    transition: all .8s;
    .megaMenuTit {
      transition: opacity .8s .5s;
      opacity: 1;
    }
    .megaMenuLink {
      opacity: 1;
      transition: opacity .8s .9s;
      &::after {
        opacity: 1;
        transform: translateX(100%);
        transition: opacity .8s, transform .8s .5s;
      }
    }
    &.isFast {
      .megaMenuTit {
        transition: opacity .8s;
      }
      .megaMenuLink {
        transition: opacity .8s .5s;
        &::after {
          transition: opacity .8s, transform .8s;
        }
      }
    }
  }
  &Bg {
    width: 100%;
    height: 0;
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    transition: height .7s;
    z-index: -1;
    @include mq(md) {
      display: none;
    }
    &.isShow {
      @include props(h, 248);
      max-height: none;
      border-bottom: solid 1px #e8e8e8;
    }
  }
}
@keyframes arrowMove {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  40%, 60% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(10px);
  }
}