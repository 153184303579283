@charset "utf-8";
/* =====================================================
フッター
===================================================== */
#footer{
  position: relative;
  background-color: #fff;
  .footerLinkArea{
    background-color: #666;
    @include props(pt, 130);
    @include props(pb, 90);

    @include mq(tab) {
      @include props(pb, 110);
    }

    @include mq(md) {
      @include props(pt, 100, 750);
      @include props(pb, 90, 750);
      @include props(pr, 40, 750);
      @include props(pl, 40, 750);
    }

    a{
      transition: opacity .3s;

      &:hover{
        opacity: .7;
      }
    }

    .footerNavList{
      display: flex;
      justify-content: space-between;

      @include mq(md) {
        display: block;
      }

      .box{
        flex-shrink: 0;

        @include mq(md) {
          @include props(mb, 90, 750);

          &:last-of-type{
            margin-bottom: 0;
          }
        }
      }

      .footerNavTit{
        font-family: $YuMincho;
        @include fz_vw(28);
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: 1.4px;
        color: #fff;
        @include props(mb, 30);

        &:nth-of-type(3){
          @include props(mt, 100);
        }

        &:not(:first-of-type){
          @include props(mb, 60);
        }

        a{
          color: #fff;
        }

        @include mq(md) {
          position: relative;
          @include fz_vw(40);
          letter-spacing: 1px;
          @include props(mb, 30);
  
          &:nth-of-type(3){
            @include props(mt, 90, 750);
          }
  
          &:not(:first-of-type){
            @include props(mb, 90, 750);
          }

          &.aCd{
            cursor: pointer;
            @include props(mb, 0);

            &.isOpen{
              &::after{
                transform: rotate(-180deg);
              }
            }

            &::after{
              content: "";
              display: block;
              position: absolute;
              top: 0;
              right: 0;
              background-image: url(/images/common/accordion_icon_white.svg);
              background-position: center right;
              background-size: contain;
              background-repeat: no-repeat;
              transition: .6s;
              backface-visibility: hidden;
              @include props(w, 59, 750);
              @include props(h, 31, 750);
            }
          }
        }
      }

      .accordionCon{
        @include mq(md) {
          display: none;
        }
      }

      .footerNavCon{
        @include mq(md) {
          @include props(pt, 10, 750);
          @include props(pl, 50, 750);
        }
      }

      .footerNav{
        font-family: $YuGothic;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.36px;
        color: #fff;
        @include props(mb, 20);

        a{
          color: #fff;
        }
        @include mq(md) {
          @include fz_vw(32);
          letter-spacing: .8px;
          @include props(mt, 50, 750);
        }
      }
    }

    .otherLinkArea{
      display: flex;
      align-items: flex-end;
      @include props(mt, 20);

      @include mq(md) {
        position: relative;
        flex-flow: column;
        @include props(pb, 100, 750);
        @include props(mt, 90, 750);
      }

      .snsList{
        display: flex;
        align-items: center;

        @include mq(md) {
          width: 100%;
          justify-content: flex-start;
          //justify-content: center;
          @include props(mb, 90, 750);
        }

        .box{
          display: flex;
          align-items: center;
          border-right: solid 1px #fff;
          @include props(pr, 15);
          @include props(mr, 30);

          @include mq(md) {
            max-width: calc(100% / 3);
            width: 100%;
            flex-direction: column;
            justify-content: space-between;
            align-self: stretch;
            @include props(pr, 0);
            @include props(mr, 0);
          }

          &:last-of-type{
            border-right: none;
          }

          &:nth-of-type(3){
            .logo{
              @include props(w, 125);
            }

            @include mq(md) {
              .logo{
                @include props(w, 157, 750);
                @include props(mt, 8, 750);
              }
            }
          }
        }

        .logo{
          @include props(w, 93);
          @include props(mr, 20);

          img{
            width: 100%;
          }

          @include mq(md) {
            margin: 0 auto;
            @include props(w, 157, 750);
            @include props(mb, 40, 750);
          }
        }
        .snsLogo{
          display: flex;
          align-items: center;
        }
        .sns{
          @include props(w, 25);
          @include props(mr, 15);

          img{
            width: 100%;
          }

          @include mq(md) {
            @include props(w, 50, 750);
            @include props(mr, 32, 750);

            &:last-of-type{
              margin-right: 0;
            }
          }
        }
      }

      .storeLink{
        font-family: $YuMincho;
        @include fz_vw(28);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.4px;
        color: #fff;
        @include props(mb, 47);
        //@include props(ml, 60);

        @include mq(md) {
          @include fz_vw(40);
          letter-spacing: 1px;
          @include props(ml, 0);
          @include props(mb, 115, 750);
        }

        a{
          position: relative;
          color: #fff;
          @include props(pl, 65);

          &::before{
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            display: block;
            background-image: url(/images/common/cart_icon_02.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            @include props(w, 45);
            @include props(h, 45);
          }

          @include mq(md) {
            @include props(pl, 110, 750);
  
            &::before{
              @include props(w, 90, 750);
              @include props(h, 90, 750);
              @include props(mr, 20, 750);
            }
          }
        }
      }

      .externalLinkList{
        display: flex;
        align-items: center;
        @include props(mb, 35);

        @include mq(md) {
          display: block;
          @include props(mb, 110, 750);
        }

        .externalLink{
          font-family: $YuMincho;
          @include fz_vw(16);
          font-weight: 800;
          line-height: 1;
          letter-spacing: 0.48px;
          color: #fff;
          @include props(mr, 50);

          &:last-of-type{
            margin-right: 0;
          }

          @include mq(md) {
            @include fz_vw(30);
            font-weight: 600;
            letter-spacing: 0.45px;
            @include props(mr, 0);
            @include props(mb, 60, 750);

            &:last-of-type{
              margin-bottom: 0;
            }
          }

          a{
            color: #fff;
          }
        }
      }

      .rightBox{
        @include mq(md) {
          order: 2;
          width: 100%;
        }
      }

      .leftBox{
        margin: 0 0 0 auto;

        @include mq(md) {
          order: 1;
          max-width: 100%;
          width: 100%;
          margin: 0;
        }

        .isoBox{
          @include mq(md) {
            position: absolute;
            left: 0;
            bottom: 0;
            @include props(pr, 40, 750);
            @include props(pl, 40, 750);
          }
        }
        .isoTxt{
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          letter-spacing: 0.7px;
          color: #fff;
          @include props(mb, 10);

          @include mq(md) {
            @include fz_vw(24);
            font-feature-settings: "palt" 1;
            letter-spacing: 0.24px;
            @include props(mb, 30, 750);
          }
        }
        .isoDepTxt{
          @include fz_vw(10);
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: 0.5px;
          color: #fff;
          @include props(w, 435);

          @include mq(md) {
            max-width: 100%;
            width: 100%;
            @include fz_vw(20);
            line-height: 1.2;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }

  .copyRightArea{
    position: relative;
    @include props(pt, 35);
    @include props(pb, 35);

    @include mq(md) {
      @include props(pt, 50, 750);
      @include props(pb, 50, 750);
    }
  }

  .copyRight{
    display: block;
    font-family: $YuMincho;
    @include fz_vw(10);
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 1px;
    text-align: center;
    color: #4d4d4d;

    @include mq(md) {
      @include fz_vw(20);
      letter-spacing: .6px;
      color: #707070;
    }
  }

  .langBox{
    position: absolute;
    top: 0;
    bottom: 0;
    @include props(r, 20);
    margin: auto;
    display: flex;
    align-items: center;

    @include mq(md) {
      position: static;
      justify-content: center;
      @include props(mb, 60, 750);
    }

    span{
      font-family: $YuMincho;
      @include fz_vw(16);
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.48px;
      color: #666;

      @include mq(md) {
        @include fz_vw(20);
        letter-spacing: 0.3px;
      }
    }

    dl{
      position: relative;
      dt{
        cursor: pointer;
        position: relative;
        font-family: $YuGothic;
        @include fz_vw(14);
        font-weight: 500;
        line-height: 1;
        letter-spacing: 1.05px;
        color: #666;
        @include props(pr, 30);
        @include props(pl, 40);

        @include mq(md) {
          @include fz_vw(20);
          letter-spacing: .75px;
          @include props(pr, 30, 750);
          @include props(pl, 40, 750);
        }

        &.open{
          &::after{
            transform: rotate(-180deg);
          }
        }

        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          background-image: url(/images/common/accordion_icon_01.svg);
          background-position: center right;
          background-size: contain;
          background-repeat: no-repeat;
          transition: .3s;
          backface-visibility: hidden;
          @include props(w, 12);
          @include props(h, 6);

          @include mq(md) {
            @include props(w, 10, 750);
            @include props(h, 5, 750);
          }
        }
      }

      dd{
        position: absolute;
        bottom: 100%;
        width: 100%;
        display: none;
        z-index: 90;

        ul{
          background-color: #fff;
          @include props(pt, 6);
          @include props(pb, 16);
          @include props(pr, 10);
          @include props(pl, 20);

          @include mq(md) {
            @include props(pt, 6, 750);
            @include props(pb, 16, 750);
            @include props(pr, 10, 750);
            @include props(pl, 20, 750);
          }
        }

        li{
          font-family: $YuGothic;
          @include fz_vw(14);
          font-weight: 500;
          line-height: 1;
          background-color: #fff;

          @include mq(md) {
            @include fz_vw(16);
          }
        }

        a{
          display: block;
          color: #707070;
          @include props(pt, 14);
          @include props(pb, 14);

          @include mq(md) {
            @include props(pt, 14, 750);
            @include props(pb, 14, 750);
          }
        }
      }
    }
  }
}