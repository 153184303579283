@charset "utf-8";
/* ================================================================================
値の定義
================================================================================ */

// フォントサイズ
$mainRemFontSize: 62.5%; // =10pxに相当
// 基本フォントサイズ(SP)
$mainFontSpSize: 100%;
// 基本横幅
$basicWidth: 1020px;

// 基本カラー
$basicColor: #555;
$basicWhiteColor: #fff;
$keyColor: #c00;
$borderColor: #999;
$thinBorderColor: #ccc;
$bgColor: #f0f0f0;
$mainColor: #98c043;

// 基本フォント
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,500,700&display=swap&subset=japanese");
$base-font: "yu-gothic-pr6n" , "游ゴシック", YuGothic, 'Yu Gothic',"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;500;700&display=swap');
$noto-serif: 'Noto Serif JP', serif;

$YuGothic: "yu-gothic-pr6n" , "游ゴシック", YuGothic, 'Yu Gothic', 'Noto Sans JP', 'Hiragino Sans', 'ヒラギノ角ゴシック', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Verdana, sans-serif;
//$YuGothic: "游ゴシック", "游ゴシック Medium", "Yu Gothic Medium", 'Noto Sans JP', 'Hiragino Sans', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, Verdana, sans-serif;
$YuMincho: "yu-mincho-pr6n" , 'YuMincho', '游明朝','Noto Serif JP', "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", Verdana, serif;
$tsukushi:"fot-tsukuardgothic-std", sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Zen+Antique&family=Zen+Maru+Gothic&display=swap');
$ZenAntique: 'Zen Antique', serif;
$Montserrat: 'Montserrat', sans-serif;
$ZenMaruGothic: 'Zen Maru Gothic', sans-serif;

$yakuhan:YakuHanJP_Noto,
"Noto Sans JP",
"ヒラギノ角ゴ Pro W3",
"Hiragino Kaku Gothic ProN",
"ヒラギノ角ゴシック",
"Hiragino Kaku Gothic Pro",
"メイリオ",
Meiryo,
Verdana,
sans-serif;

// ブレークポイント定義
$breakpoints: ("mdMin": "screen and (max-width : 374px)",
  "md": "screen and (max-width : 767px)",
  "tab": "screen and (min-width : 768px) and (max-width : 1024px)",
  "desk": "screen and (min-width : 1025px) and (max-width : 1440px)",
  "pc": "screen and (min-width : 768px)",
  "minCon": "screen and (min-width : 768px) and  (max-width : 1520px)",
  "min": "screen and (min-width : 768px) and  (max-width : 1405px)",
  "fixed": "screen and (min-width : 1600px)",
  "max": "screen and (min-width : 1920px)",
) !default;

$properties: ('p': 'padding',
  'pt': 'padding-top',
  'pl': 'padding-left',
  'pb': 'padding-bottom',
  'pr': 'padding-right',
  'm': 'margin',
  'mt': 'margin-top',
  'ml': 'margin-left',
  'mb': 'margin-bottom',
  'mr': 'margin-right',
  'w': 'width',
  'mw': 'min-width',
  'h': 'height',
  'mh': 'min-height',
  't': 'top',
  'l': 'left',
  'b': 'bottom',
  'r': 'right',
  'br': 'border-radius',
  'btlr': 'border-top-left-radius',
  'btrr' : 'border-top-right-radius',
  'bblr' : 'border-bottom-left-radius',
  'bbrr' : 'border-bottom-right-radius',
  'btw': 'border-top-width',
  'bbw' : 'border-bottom-width',
  'brw' : 'border-right-width',
  'blw' : 'border-left-width',
) !default;

/* ================================================================================
importファイル
================================================================================ */

// @mixin定義
@import "foundation/mixin";

// @extend定義
@import "foundation/extend";

// リセット
@import "foundation/reset";

// 印刷
@import "foundation/print";

// 基本 
@import "layout/bace";

// ヘッダー
@import "layout/header";

// フッター
@import "layout/footer";

// コンポーネント（共通パーツ）
@import "component/title";
@import "component/animation";
@import "component/button";
@import "component/common";

/************************************
トップページ
***********************************/

// トップページ
@import "page/index";

// iro iro iroha
@import "page/contents";

// iro iro iroha　記事ページ
//@import "page/single";

// products
@import "page/products";

// shop
@import "page/shop";

// news
@import "page/news";

// contact
@import "page/contact";

// faq
@import "page/faq";

// about
@import "page/about";

// age
@import "page/age";

// anniversary
@import "page/anniversary";

// 水原 希子特設ページ
@import "page/mizuhara";