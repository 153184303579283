@charset "utf-8";

/* ==============================================
shop共通パーツ
============================================== */
.shopArea {
	position: relative;
	z-index: 1;
	// background-color: #f1fbfc;
	// background-image: linear-gradient(to bottom, #f1fbfc, #F5FDFE 850px, #F5FDFE);
	transition: 3s cubic-bezier(.08, .92, .35, 1);
	@include props(pt, 185);

	@include mq(md) {
		@include props(pt, 205, 750);
	}

	.secTit {
		font-family: $YuMincho;
		@include fz_vw(70);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 4.9px;
		text-align: center;
		@include props(pt, 110);
		@include props(pb, 110);

		@include mq(md) {
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 0.07em;
			text-align: center;
			@include props(pt, 150, 750);
			@include props(pb, 150, 750);
		}
	}

	.storeSearchWrap {
		position: relative;
		z-index: 1;

		.secTit {
			@include props(pb, 70);

			@include mq(md) {
				@include props(pb, 150, 750);
			}
		}

		.storeSearchDesc {
			font-family: $YuGothic;
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			text-align: center;
			@include props(mb, 120);

			@include mq(md) {
				@include fz_vw(28);
				line-height: 1.29;
				@include props(mb, 90, 750);
			}
		}

		.buySearchBox {
			background: $basicWhiteColor;
			@include props(pt, 150);
			@include props(pb, 150);
			@include props(pl, 90);
			@include props(pr, 90);
			text-align: center;

			@include mq(md) {
				@include props(pt, 80, 750);
				@include props(pb, 80, 750);
				@include props(pl, 40, 750);
				@include props(pr, 40, 750);
			}

			.buySearch {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;
				@include props(mb, 60);

				@include mq(md) {
					@include props(mb, 40, 750);
				}

				.selectBox {
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.buySearchBoxSelect {
						flex-grow: 1;
						position: relative;

						@include mq(md) {
							width: 100%;
							max-width: 100%;
						}

						select {
							width: 100%;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: normal;
							letter-spacing: normal;
							text-align: left;
							padding: 1.25em 2.25em 1.25em 1.25em;
							outline: none;
							border-radius: 3px;
							border: 1px solid #adadad;
							appearance: none;
							background: none;
							color: #4d4d4d;

							@include mq(md) {
								@include fz_vw(28);
								padding: 1.6em 2em;
							}
						}

						&:after {
							content: "";
							@include props(w, 16);
							@include props(h, 16);
							border-bottom: 1px solid #4d4d4d;
							border-right: 1px solid #4d4d4d;
							position: absolute;
							@include props(r, 20);
							top: 50%;
							transform: translate(0, -75%)rotate(45deg);

							@include mq(md) {
								@include props(w, 24, 750);
								@include props(h, 24, 750);
								@include props(r, 50, 750);
							}
						}
					}

					&.buySearchBoxArea {
						width: 26.3069%;

						@include mq(md) {
							width: 100%;
							max-width: 100%;
						}
					}

					&.buySearchBoxStore {
						width: 28.1618%;

						@include mq(md) {
							width: 100%;
							max-width: 100%;
						}
					}
				}

				.buySearchBoxFilter {
					width: 34.064%;
					display: flex;
					align-items: center;

					@include mq(md) {
						width: 100%;
						max-width: 100%;
						flex-wrap: wrap;
					}

					.buySearchBoxFilterInput {
						flex-grow: 1;
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 300;
						font-stretch: normal;
						font-style: normal;
						line-height: normal;
						letter-spacing: normal;
						text-align: left;
						padding: 1.25em;
						border: 1px solid #adadad;
						border-radius: 3px;
						outline: none;
						width: 79%;

						@include mq(minCon) {
							width: 77%;
						}

						@include mq(md) {
							width: 100%;
							max-width: 100%;
							@include fz_vw(28);
							padding: 1.6em 2em;
						}
					}
				}

				label {
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: normal;
					letter-spacing: normal;
					margin-right: 1em;

					@include mq(md) {
						@include fz_vw(28);
						@include props(mb, 50, 750);
						@include props(mt, 50, 750);
						margin-right: 0;

						&[for="area"] {
							@include props(mt, 0, 750);
						}
					}
				}
			}

			.buySearchBoxBtn {
				width: 400px;
				border-radius: 3px;
				background: #4d4d4d;

				&:disabled {
					background: #adadad;
				}

				@include mq(md) {
					width: 100%;
					max-width: 100%;
				}

				span {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					color: $basicWhiteColor;
					font-family: $YuMincho;
					@include fz_vw(20);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					letter-spacing: 1.2px;
					line-height: 1;
					text-align: center;
					padding: 1.5em 1em;

					@include mq(md) {
						@include fz_vw(32);
					}

					&:before {
						content: "";
						background: url(/images/shop/index/shop_search_icon.svg) center center no-repeat;
						background-size: contain;
						width: .8em;
						height: .8em;
						margin-right: .8em;
					}
				}
			}

			.warningBox {
				@include props(mt, 50);
				@include props(mb, 60);

				&:last-of-type{
					margin-bottom: 0;
				}

				@include mq(md) {
					@include props(mt, 50, 750);
					@include props(mb, 100, 750);
				}

				.warningNote {
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.66;
					letter-spacing: 0.32px;
					margin-bottom: .5em;

					@include mq(md) {
						text-align: left;
						@include fz_vw(24);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						letter-spacing: 0.48px;
					}
				}
			}
		}
	}
}

/* ==============================================
shopTopパーツ
============================================== */
#shopTop {
	background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)120px, rgba(245, 253, 254, .6)850px, rgba(245, 253, 254, .8) 1000px);
	transition: 3s cubic-bezier(.08, .92, .35, 1);

	@include mq(minCon) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)7.5vw, rgba(245, 253, 254, .6)52.63vw, rgba(245, 253, 254, .8) 62.5vw);
	}

	@include mq(md) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)16.66vw, rgba(245, 253, 254, .6)106vw, rgba(245, 253, 254, .8) 126.6vw);
	}

	.shopMainWrap {
		position: relative;
		z-index: 1;
		@include props(pb, 240);

		@include mq(md) {
			@include props(pb, 0, 750);
		}

		.childCategoryList {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.childCategoryListItem {
				display: flex;
				align-items: flex-end;
				width: 100%;
				position: relative;
				@include props(mb, 200);


				&:last-child {
					@include props(mb, 0);
				}

				@include mq(md) {
					flex-wrap: wrap;
					flex-direction: column;
					@include props(pb, 0, 750);
					@include props(pr, 0, 750);

					.txt {
						width: 100%;
						max-height: 100%;
						@include props(pt, 80, 750);
						@include props(pr, 40, 750);
						@include props(pl, 40, 750);
						@include props(pb, 80, 750);

						&:before {
							@include props(l, -40, 750);
							@include props(w, 710, 750);
							height: 110%;
						}

						.mainTxt {
							@include fz_vw(48);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.92px;
							text-align: center;
							@include props(mb, 120, 750);
						}

						.descTxt {
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
							@include props(mb, 80, 750);
						}
					}
				}

				.txt {
					@include props(pl, 50);
					@include props(pr, 40);
					@include props(pt, 70);
					@include props(pb, 70);
					width: 34.114%;
					position: relative;

					&:before {
						content: "";
						background-color: $basicWhiteColor;
						@include props(w, 900);
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
					}

					.mainTxt {
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.64px;
						text-align: left;
						@include props(mb, 26);
					}

					.descTxt {
						font-family: $YuGothic;
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						text-align: left;
						@include props(mb, 80);
					}
				}

				.arrow {
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: 0.96px;
					display: block;
					text-align: end;
					font-family: $YuMincho;

					&:after {
						content: "";
						background: url(/images/common/blank_icon.svg) center center no-repeat;
						background-size: contain;
						width: .8em;
						height: .7em;
						display: inline-block;
						margin-left: .5em;
					}

					@include mq(md) {
						@include fz_vw(30);
					}
				}

				.img {
					position: absolute;
					width: 65.885%;
					@include props(t, 50);
					right: 0;
					box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);

					img {
						margin: 0;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					@include mq(md) {
						position: relative;
						@include props(w, 670, 750);
						@include props(mt, 0, 750);
						@include props(mb, 0, 750);
						top: 0;
						bottom: 0;
						@include props(r, -40, 750)
					}
				}
			}
		}
	}

	.otherStoreWrap {
		position: relative;
		z-index: 1;
		@include props(pb, 200);

		@include mq(md) {
			@include props(pb, 200, 750);
		}

		.secTit {
			@include props(pb, 110);

			@include mq(md) {
				letter-spacing: 1.45px;
				@include props(pb, 150, 750);
				@include fz_vw(48);
			}
		}

		.otherStore {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 2.4%;

			@include mq(md) {
				@include props(mb, 10, 750);
			}

			.otherStoreList {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 47.585%;
				margin-bottom: 4.86%;
				background: $basicWhiteColor;

				@include mq(md) {
					width: 100%;
					@include props(mb, 50, 750);
				}

				a {
					height: 100%;
					transition: opacity .3s;

					&:hover {
						opacity: .7;
					}
				}

				.storeLogo{
					overflow: hidden;
					@include props(h, 185);

					img{
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: center;
					}

					@include mq(md) {
						@include props(h, 190, 750);
					}
				}

				.irohaLink{
					width: 100%;
					display: flex;
					justify-content: space-between;
					border-top: 10px solid #f2fcfd;
					@include props(btw, 10);

					@include mq(md) {
						@include props(btw, 10, 750);
					}

					.link{
						position: relative;
						flex-grow: 1;
						border-right: 10px solid #f2fcfd;
						@include props(brw, 10);
	
						&::after{
							content: "";
							position: absolute;
							top: 50%;
							@include props(r, 25);
							margin: auto;
							@include props(w, 10);
							@include props(h, 10);
							border-bottom: 1px solid #4d4d4d;
							border-right: 1px solid #4d4d4d;
							display: inline-block;
							transform: translate(0, -50%) rotate(-45deg);
						}
						&:last-of-type{
							border-right: none;
						}
						&:first-of-type:last-of-type{
							a{
								img{
									@include props(mr, 6);
									@include props(ml, 6);
								}
							}
						}

						@include mq(md) {
							@include props(brw, 10, 750);
	
							&::after{
								@include props(r, 20, 750);
								@include props(w, 20, 750);
								@include props(h, 20, 750);
							}
							&:first-of-type:last-of-type{
								a{
									img{
										@include props(mr, 0);
										@include props(ml, 0);
									}
								}
							}
						}

						a{
							display: flex;
							justify-content: center;
							@include props(pt, 15);
							@include props(pb, 15);
							@include props(pr, 10);

							img{
								@include props(w, 122);
							}

							@include mq(md) {
								@include props(pt, 20, 750);
								@include props(pb, 20, 750);
								@include props(pr, 30, 750);
	
								img{
									@include props(w, 150, 750);
								}
							}
						}
					}
				}

				.storeName {
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.32px;
					text-align: center;
					padding: 6.66% 0;
					border-top: 5px solid #f2fcfd;
					flex-grow: 1;
					display: flex;
					justify-content: center;
					align-items: center;

					@include mq(md) {
						@include fz_vw(24);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.33;
						letter-spacing: 0.48px;
						@include props(pt, 10, 750);
						@include props(pb, 10, 750);
						border-top: 0.66vw solid #f2fcfd;
					}
				}
			}

			#hideBox {
				width: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				&:before {
					display: block;
					content: "";
					order: 1;
					width: 21.96%;
				}

				&:after {
					display: block;
					content: "";
					width: 21.96%;
				}
			}
		}

		#toggleBox {
			text-align: center;
			border-top: 1px solid #e5e5e5;
			@include props(pt, 30);

			#shopToggleBtn {
				font-family: $YuMincho;
				@include fz_vw(28);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.86;
				letter-spacing: 2.8px;

				&:hover {
					cursor: pointer;
				}

				&:after {
					content: "";
					width: .7em;
					height: .7em;
					border-bottom: 1px solid #4d4d4d;
					border-right: 1px solid #4d4d4d;
					display: inline-block;
					transform: translate(0, -25%)rotate(45deg);
					margin-left: 1em;
					transition: all .3s;
				}

				&.isOpen {
					&:after {
						transform: translate(0, 25%)rotate(-135deg);
					}
				}
			}
		}
	}

	.inStoreWrap {
		position: relative;
		z-index: 1;
		@include props(pb, 200);

		@include mq(md) {
			@include props(pb, 200, 750);
		}

		.childCategoryList {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.childCategoryListItem {
				display: flex;
				align-items: flex-end;
				width: 100%;
				position: relative;
				flex-direction: row-reverse;
				align-items: flex-start;
				@include props(mb, 200);



				&:last-child {
					@include props(mb, 0);
				}

				// &:nth-child(even){
				// 	flex-direction: row-reverse;

				// 	&:before{
				// 		content: "";
				// 		position: absolute;
				// 		top: 0;
				// 		left: inherit;
				// 		right: 0;
				// 		z-index: -1;
				// 	}

				// 	.img{
				// 		right: inherit;
				// 		left: 0;
				// 	}
				// }

				@include mq(md) {
					flex-wrap: wrap;
					flex-direction: column;
					@include props(pb, 80, 750);
					@include props(pr, 0, 750);

					// &:nth-child(even){
					// 	flex-direction: column;
					// 	@include props(pl,40,750);


					// 	&:before{
					// 		content: "";
					// 		position: absolute;
					// 		top: 0;
					// 		left: inherit;
					// 		@include props(r,-40,750);
					// 		z-index: -1;
					// 	}

					// 	.img{
					// 		@include props(l,-40,750);
					// 		right: inherit;
					// 	}
					// }

					.txt {
						width: 100%;
						max-height: 100%;
						@include props(mt, 430, 750);
						@include props(pt, 80, 750);
						@include props(pr, 0, 750);
						@include props(pl, 40, 750);
						@include props(pb, 80, 750);

						&:before {
							@include props(w, 710, 750);
							top: inherit;
							bottom: 0;
							height: 110%;
							left: 0;
						}

						.mainTxt {
							@include fz_vw(36);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.44;
							letter-spacing: normal;
							text-align: center;
							@include props(mb, 60, 750);
						}

						.descTxt {
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
							text-align: left;
							@include props(mb, 80, 750);
						}
					}
				}

				.txt {
					@include props(pl, 50);
					@include props(pr, 70);
					@include props(pt, 140);
					@include props(pb, 100);
					width: 40.70%;
					position: relative;

					&:before {
						content: "";
						background-color: $basicWhiteColor;
						@include props(w, 900);
						height: 100%;
						position: absolute;
						top: 0;
						z-index: -1;
						right: 0;
					}

					.mainTxt {
						font-family: $YuMincho;
						@include props(mb, 30);
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.86;
						letter-spacing: 0.56px;
						text-align: left;
					}

					.descTxt {
						font-family: $YuGothic;
						@include props(mb, 60);
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
					}
				}

				.arrow {
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: 0.96px;
					display: block;
					text-align: end;
					font-family: $YuMincho;

					&:after {
						content: "";
						background: url(/images/common/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						@include props(w, 30);
						@include props(h, 10);
						display: inline-block;
						@include props(ml, 10);
						margin-bottom: .2em
					}

					@include mq(md) {
						@include fz_vw(30);

						&:after {
							width: 1.8em;
							height: 0.7em;
							@include props(ml, 16, 750);
						}
					}
				}

				.img {
					width: 59.297%;
					right: 0;
					box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
					@include props(t, -50);

					img {
						margin: 0;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}

					@include mq(md) {
						@include props(mt, 0, 750);
						@include props(mb, 0, 750);
						@include props(t, 0);
						@include props(w, 710, 750);
						@include props(l, -40, 750);
						position: absolute;
					}
				}
			}
		}

		.secSubTit {
			font-family: $YuMincho;
			@include fz_vw(48);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.15;
			letter-spacing: 1.92px;
			text-align: center;
			@include props(pt, 200);
			@include props(pb, 100);

			@include mq(md) {
				@include fz_vw(48);
				@include props(pt, 200, 750);
				@include props(pb, 154, 750);
			}
		}

		.recommendedList {
			display: flex;
			justify-content: space-between;
			align-items: inherit;
			flex-wrap: wrap;

			&:after {
				content: "";
				display: block;
				width: 48.6%;

				@include mq(md) {
					display: none;
				}
			}

			.otherStoreCard {
				position: relative;
				width: 48.6%;

				&:nth-of-type(-n+4) {
					@include props(mb, 70);
				}

				@include mq(md) {
					&:nth-of-type(-n+4) {
						@include props(mb, 160, 750);
					}

					width: 100%;
					max-width: inherit;
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;
					@include props(mb, 160, 750);
					@include props(pb, 80, 750);

					&:last-of-type {
						@include props(mb, 0, 750);
					}
				}

				&:after {
					content: "";
					display: block;
					max-width: 96.38%;
					width: 100%;
					height: 62.691%;
					background-color: #fff;
					position: absolute;
					bottom: 0;
					left: 0;
					z-index: -1;

					@include mq(md) {
						height: 65.35%;
						@include props(w, 710, 750);
						@include props(l, -40, 750);
					}
				}

				.img {
					max-width: 96.38%;
					box-shadow: 5px 5px 10px 0 rgba($color: #000000, $alpha: .16);
					margin: 0 0 0 auto;

					@include mq(md) {
						@include props(w, 710, 750);
						@include props(mr, -40, 750);
					}
				}

				.txt {
					position: relative;
					max-width: 96.38%;
					z-index: 1;

					@include mq(md) {
						width: 100%;
						max-width: 100%;
						@include props(pt, 80, 750);
						@include props(pr, 40, 750);
					}

					&>a {
						@include props(pt, 35);
						@include props(pb, 56);
						@include props(pr, 46);
						@include props(pl, 50);

						@include mq(md) {
							@include props(p, 0, 750);
						}
					}

					.otherStoreCardLinkBox {
						display: flex;
						flex-direction: column;
					}

					.otherStoreCardTit {
						font-family: $YuMincho;
						@include fz_vw(28);
						letter-spacing: 1.26px;
						@include props(mb, 30);

						@include mq(md) {
							@include fz_vw(36);
							line-height: 1.44;
							letter-spacing: normal;
							@include props(mb, 60, 750);
							text-align: center;
						}
					}

					.otherStoreCardDesc {
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;
						@include props(mb, 40);

						@include mq(md) {
							@include fz_vw(28);
							letter-spacing: normal;
							@include props(mb, 100, 750);
						}
					}
				}

				.otherStoreCardDescBlank {
					position: absolute;
					@include props(b, 40);
					@include props(r, 80);
					font-family: $YuMincho;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.25;
					letter-spacing: 0.32px;
					display: flex;
					justify-content: flex-end;
					align-items: center;

					&:after {
						content: "";
						background: url(/images/common/blank_icon.svg) center center no-repeat;
						background-size: contain;
						width: 0.85em;
						height: 0.76em;
						margin-left: 0.5em;
					}

					@include mq(md) {
						@include fz_vw(28);
						@include props(b, 80, 750);
						@include props(r, 40, 750);
					}
				}


				@include mq(md) {
					&:nth-of-type(even) {
						&:after {
							@include props(r, -40, 750);
							left: inherit;
						}

						.img {
							right: inherit;
							@include props(ml, -40, 750);
						}

						.txt {
							@include props(pl, 40, 750);
							@include props(pr, 0, 750);
						}

						.otherStoreCardDescBlank {
							@include props(r, 20, 750);
						}
					}
				}

			}
		}
	}
}

/* ==============================================
storeSearchパーツ
============================================== */
#storeSearchTop {
	background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)120px, rgba(245, 253, 254, .6)850px, rgba(245, 253, 254, .8) 1000px);
	transition: 3s cubic-bezier(.08, .92, .35, 1);

	@include mq(minCon) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)7.5vw, rgba(245, 253, 254, .6)52.63vw, rgba(245, 253, 254, .8) 62.5vw);
	}

	@include mq(md) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)16.66vw, rgba(245, 253, 254, .6)106vw, rgba(245, 253, 254, .8) 126.6vw);
	}

	.buySearchResultBox {
		position: relative;
		z-index: 1;
		@include props(mb, 200);

		@include mq(md) {
			@include props(mb, 200, 750);
		}

		.buySearchResult {
			.buySearchResultHead {
				.buySearchResultTit {
					font-family: $YuGothic;
					@include fz_vw(18);
					font-size: 18px;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.67;
					letter-spacing: normal;
					text-align: left;
					@include props(mb, 40);

					@include mq(md) {
						@include fz_vw(30);
						line-height: 1.6;
						@include props(mb, 80, 750);
					}
				}

				.searchArea {
					@include fz_vw(22);
					font-weight: bold;

					&:before {
						content: "「";
					}

					&:after {
						content: "」";
					}

					@include mq(md) {
						@include fz_vw(32);
						line-height: 2;
					}
				}

				.searchFilter {
					@include fz_vw(22);
					font-weight: bold;

					&:before {
						content: "「";
					}

					&:after {
						content: "」";
					}

					@include mq(md) {
						@include fz_vw(32);
						line-height: 2;
					}
				}

				.searchShop {
					@include fz_vw(22);
					font-weight: bold;

					&:before {
						content: "「";
					}

					&:after {
						content: "」";
					}

					@include mq(md) {
						@include fz_vw(32);
						line-height: 2;
					}
				}

				.buySearchResultHeadNum {
					font-family: $YuGothic;
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.67;
					letter-spacing: normal;
					@include props(mb, 40);

					@include mq(md) {
						@include props(mb, 100, 750);
					}

					.searchNum {
						@include fz_vw(22);
						font-weight: bold;
						margin-left: .5em;

						&:after {
							content: "件";
							@include fz_vw(18);
							font-weight: 500;
							margin-left: .5em
						}
					}

					@include mq(md) {
						@include fz_vw(30);
						line-height: 1.6;

						.searchNum {
							@include fz_vw(34);

							&:after {
								content: "件";
								@include fz_vw(30);
								line-height: 1.6;
							}
						}
					}
				}
			}

			.storeType {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				@include props(mb, 40);

				@include mq(md) {
					border-top: 1px solid #707070;
					@include props(pt, 40, 750);
					@include props(pb, 40, 750);
					@include props(mb, 0);
				}

				dt {
					font-family: $YuGothic;
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: 0.36px;
					@include props(mr, 60);
				}

				dd {
					ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;

						li {
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.32px;
							display: flex;
							align-items: center;
							@include props(mr, 30);

							@include mq(md) {
								@include fz_vw(24);
								line-height: 1.33;
								width: 50%;
								@include props(mr, 0, 750);
								@include props(mb, 20, 750);

								&:nth-last-child(-n+2) {
									@include props(mb, 0, 750);
								}
							}

							&:last-child {
								@include props(mr, 0);
							}

							&:before {
								content: "";
								width: 2.15em;
								height: 2.15em;
								margin-right: 5px;

								@include mq(md) {
									@include props(w, 60, 750);
									@include props(h, 60, 750);
									@include props(mr, 20, 750);
								}
							}

							&.certified {
								&:before {
									background: url(/images/shop/store_search/search_cretified_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}

							&.drug {
								&:before {
									background: url(/images/shop/store_search/search_drug_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}

							&.variety {
								&:before {
									background: url(/images/shop/store_search/search_variety_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}

							&.salon {
								&:before {
									background: url(/images/shop/store_search/search_salon_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}

							&.adult {
								&:before {
									background: url(/images/shop/store_search/search_adult_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}

							&.clinic {
								&:before {
									background: url(/images/shop/store_search/search_clinic_icon.svg) center center no-repeat;
									background-size: contain;
								}
							}
						}
					}
				}
			}

			.brandType {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				@include props(mb, 40);

				@include mq(md) {
					border-top: 1px solid #707070;
					border-bottom: 1px solid #707070;
					@include props(pt, 40, 750);
					@include props(pb, 20, 750);
					@include props(mb, 0);
				}

				dt {
					font-family: $YuGothic;
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: 0.36px;
					@include props(mr, 60);
				}

				dd {
					ul {
						display: flex;
						flex-wrap: wrap;
						justify-content: flex-start;
						align-items: center;

						@include mq(md) {
							display: block;
						}

						li {
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.32px;
							display: flex;
							align-items: center;
							@include props(mr, 30);

							@include mq(md) {
								@include fz_vw(24);
								line-height: 1.33;
								width: 100%;
								@include props(mr, 0, 750);
								@include props(mb, 20, 750);
							}

							&:last-child {
								@include props(mr, 0);
							}

							&:before {
								content: "";
								@include props(w, 90);
								@include props(h, 35);
								margin-right: 10px;

								@include mq(md) {
									@include props(w, 183, 750);
									@include props(h, 60, 750);
									@include props(mr, 15, 750);
								}
							}

							&.iroha {
								&:before {
									background: url(/images/shop/store_search/iroha_logo.svg) center center no-repeat;
									background-size: contain;

									@include mq(md) {
										background: url(/images/shop/store_search/iroha_logo_sp.svg) center center no-repeat;
										background-size: contain;
									}
								}
							}

							&.iic {
								&:before {
									background: url(/images/shop/store_search/iic_logo.svg) center center no-repeat;
									background-size: contain;

									@include mq(md) {
										background: url(/images/shop/store_search/iic_logo_sp.svg) center center no-repeat;
										background-size: contain;
									}
								}
							}

							&.ihc {
								&:before {
									background: url(/images/shop/store_search/ihc_logo.svg) center center no-repeat;
									background-size: contain;

									@include mq(md) {
										background: url(/images/shop/store_search/ihc_logo_sp.svg) center center no-repeat;
										background-size: contain;
									}
								}
							}
						}
					}
				}
			}

			.buySearchResultList {
				@include props(mt, 20);

				@include mq(md) {
					@include props(mt, 80, 750);
				}

				thead {
					background: $basicWhiteColor;
					width: 100%;
					@include props(mb, 20);
					display: block;

					tr {
						display: flex;
						width: 100%;
						padding: 1.928% 3.66% 1.928% 1.4641%;
						@include props(pr, 20);
						@include props(pl, 20);

						td {
							text-align: center;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.25;
							letter-spacing: 1.6px;
							margin: auto;
							@include props(pr, 10);
							//padding-right: 2em;
						}

						.storeName {
							width: 31.611%;
						}

						.address {
							width: 23.209%;
							@include props(pr, 50);
						}

						.handling {
							width: 11.538%;
						}

						.brand {
							width: 16%;
						}

						.tel {
							width: 11.448%;
						}

						.map {
							width: 6.349%;
						}
					}
				}

				tbody {
					width: 100%;

					tr {
						display: flex;
						align-items: center;
						padding: 1.464% 3.66% 1.464% 1.464%;
						background: $basicWhiteColor;
						@include props(pr, 20);
						@include props(pl, 20);
						@include props(mb, 20);

						&:last-of-type {
							@include props(mb, 0);
						}

						@include mq(md) {
							@include props(p, 40, 750);
							@include props(mb, 80, 750);
							flex-wrap: wrap;
						}

						td {
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.5;
							letter-spacing: 0.8px;
							@include props(pr, 10);
							//padding-right: 2em;

							&:last-child {
								padding-right: 0;
							}

							@include mq(md) {
								@include fz_vw(28);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2;
								padding-right: 0;
							}
						}

						.storeName {
							width: 31.611%;
							display: flex;
							align-items: center;
							font-weight: bold;
							@include props(pr,30);

							.storeIcon {
								display: flex;
								justify-content: space-between;
								flex-wrap: wrap;
								flex-shrink: 0;
								@include props(w, 78);
								@include props(mr, 20);

								img {
									@include props(w, 34);

									&:nth-of-type(n + 3) {
										margin-top: 12.5%;
									}
								}
							}

							@include mq(md) {
								width: 100%;
								flex-wrap: wrap;
								order: 1;

								.storeIcon {
									max-width: 100%;
									width: 100%;
									justify-content: left;
									@include props(mr, 0);
									@include props(mb, 30, 750);

									img {
										@include props(w, 60, 750);
										@include props(h, 60, 750);
										margin-bottom: 0;
										@include props(mr, 20, 750);

										&:nth-of-type(n + 3) {
											@include props(mt, 0, 750);
										}
									}
								}

								.storeNameField {
									width: 100%;
								}
							}
						}

						.address {
							width: 23.209%;
							@include props(pr, 40);

							@include mq(md) {
								width: 100%;
								order: 2;
								@include props(mt, 40, 750);
							}
						}

						.handling {
							width: 11.538%;
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							justify-content: space-between;

							span {
								display: block;
								min-width: calc(50% - 5px);
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: 500;
								text-align: left;
								@include props(mb, 2);
								//border-radius: 25px;
								//padding: 3px;
								//margin: 5px 0;

								&::before{
									content: "";
									display: inline-block;
									vertical-align: text-top;
									border-radius: 100%;
									@include props(w, 16);
									@include props(h, 16);
									@include props(mr, 5);
								}

								&.appliances {
									&::before{
										background: rgb(255, 218, 159);
									}
								}

								&.physiology {
									&::before{
										background: rgb(247, 210, 218);
									}
								}

								&.health {
									&::before{
										background: rgb(153, 207, 223);
									}
								}

								&.beauty {
									&::before{
										background: rgba(229, 227, 87, .3);
									}
								}

								&.adult {
									&::before{
										background: rgb(204, 204, 245);
									}
								}

								&.bodyCare {
									&::before{
										background: rgb(182, 230, 171);
									}
								}

								&.femtech {
									&::before{
										background: rgb(250, 176, 176);
									}
								}
							}

							@include mq(md) {
								width: 100%;
								justify-content: left;
								@include props(mb, 30, 750);
								order: 4;

								p {
									font-family: $YuGothic;
									@include fz_vw(24);
									font-weight: 500;
									font-stretch: normal;
									font-style: normal;
									line-height: 1.71;
									letter-spacing: 2.8px;
									text-align: left;
									width: 100%;
									@include props(mb, 20, 750);
									@include props(mt, 40, 750);
								}

								span {
									@include fz_vw(26);
									min-width: auto;
									width: auto;
									@include props(mr, 30, 750);
									@include props(mt, 5, 750);
									@include props(mb, 5, 750);
									line-height: 1.66;

									&::before{
										@include props(w, 28, 750);
										@include props(h, 28, 750);
										@include props(mr, 5, 750);
									}
								}
							}
						}

						.brand {
							width: 16%;

							.flex{
								display: flex;
								flex-wrap: wrap;

								.img{
									@include props(w, 90);
									@include props(mr, 5);
									@include props(mb, 5);
								}
							}

							@include mq(md) {
								order: 4;
								width: 100%;
								@include props(mb, 40, 750);

								.flex{
									.img{
										@include props(w, 183, 750);
										@include props(mr, 12, 750);
										@include props(mb, 0);

										&:last-of-type{
											margin-right: 0;
										}

										img{
											width: 100%;
										}
									}
								}
							}
						}

						.tel {
							width: 11.448%;
							text-align: center;

							a {
								color: #4d4d4d;
								@include fz_vw(16);
								font-weight: 500;
							}


							@include mq(md) {
								width: 76.5%;
								text-align: left;
								order: 3;
								@include props(mt, 10, 750);

								a {
									@include fz_vw(28);
									font-weight: 500;
									font-stretch: normal;
									font-style: normal;
									line-height: 0.86;
									letter-spacing: 1.4px;
								}
							}
						}

						.map {
							width: 6.349%;
							text-align: center;

							a {
								@include fz_vw(16);
								font-weight: 500;
								color: #4d4d4d;
								display: inline-block;
								width: 100%;
								border: 1px solid #adadad;
								border-radius: 7px;
								padding: 4px 0;
								@include props(w, 60);
							}

							@include mq(md) {
								width: 23.5%;
								order: 5;
								margin-left: auto;

								a {
									@include fz_vw(28);
									@include props(w, 139, 750);
									@include props(pt, 16, 750);
									@include props(pb, 16, 750);
									line-height: 1;
									border-radius: 0.93vw;
								}
							}
						}
					}
				}
			}
		}
	}
}


/* ==============================================
iroha STOREパーツ
============================================== */

#irohaStoreTop {
	background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)120px, rgba(245, 253, 254, .6)850px, rgba(245, 253, 254, .8) 1000px);
	transition: 3s cubic-bezier(.08, .92, .35, 1);

	@include mq(minCon) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)7.5vw, rgba(245, 253, 254, .6)52.63vw, rgba(245, 253, 254, .8) 62.5vw);
	}

	@include mq(md) {
		background-image: linear-gradient(to bottom, rgba(241, 251, 252, 1)16.66vw, rgba(245, 253, 254, .6)106vw, rgba(245, 253, 254, .8) 126.6vw);
	}

	.irohaStoreBOX {
		@include props(pb, 200);

		@include mq(md) {
			@include props(pb, 200, 750)
		}

		.mainVisual {
			img {
				box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);
			}
		}

		.irohaStoreDesc {
			font-family: $YuGothic;
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			text-align: left;
			@include props(mt, 120);
			@include props(mb, 120);
			width: 100%;
			max-width: 700px;
			margin-left: auto;
			margin-right: auto;

			@include mq(md) {
				@include fz_vw(30);
				letter-spacing: 1.2px;
				@include props(mt, 160, 750);
				@include props(mb, 160, 750);
				@include props(pl, 40, 750);
				@include props(pr, 40, 750);
			}
		}

		.irohaStoreBOXImg {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			@include props(mb, 200);

			@include mq(md) {
				@include props(mb, 160, 750);
			}

			div {
				width: 50%;

				@include mq(md) {
					width: 100%;
					max-width: 100%;
				}

				&:nth-of-type(1) {
					@include props(mt, 190);

					@include mq(md) {
						@include props(mt, 0, 750);
						@include props(mr, -80, 750);
					}

					img {
						@include props(w, 600);
						box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);

						@include mq(md) {
							@include props(w, 670, 750);
						}
					}
				}

				&:nth-of-type(2) {
					@include mq(md) {
						@include props(ml, -80, 750);
						@include props(mt, 160, 750);
						@include props(mb, 160, 750);
					}

					img {
						@include props(w, 600);
						margin-left: auto;
						box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);

						@include mq(md) {
							@include props(w, 670, 750);
						}
					}
				}

				&:nth-of-type(3) {
					margin-left: auto;
					@include props(mt, -90);

					@include mq(md) {
						@include props(mt, 0, 750);
						@include props(mr, -40, 750);
					}

					img {
						@include props(w, 600);
						margin-right: auto;
						box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);

						@include mq(md) {
							@include props(w, 670, 750);
						}
					}
				}
			}
		}

		.childCategoryList {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.childCategoryListItem {
				display: flex;
				align-items: flex-end;
				width: 100%;
				position: relative;
				@include props(mb, 200);

				@include mq(md) {
					@include props(mb, 80, 750);
					align-items: baseline;
				}

				@include mq(md) {
					flex-wrap: wrap;
					flex-direction: column;
					@include props(pb, 0, 750);
					@include props(pr, 0, 750);

					.txt {
						width: 100%;
						max-height: 100%;
						@include props(pt, 120, 750);
						@include props(pr, 40, 750);
						@include props(pl, 0, 750);
						@include props(pb, 80, 750);

						&:before {
							@include props(l, -40, 750);
							@include props(w, 710, 750);
							height: 72.75%;
						}

						.mainTxt {
							@include fz_vw(48);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.33;
							letter-spacing: 0.96px;
							@include props(mb, 80, 750);
						}

						dl {
							dt {
								flex-basis: 26.7%;
								@include fz_vw(28);
							}

							dd {
								width: 73.3%;
								@include fz_vw(28);
							}
						}
					}
				}

				.txt {
					@include props(pl, 50);
					@include props(pr, 40);
					@include props(pt, 120);
					@include props(pb, 80);
					width: 34.114%;

					@include mq(tab) {
						@include props(pl, 30);
						@include props(pr, 30);

						.mainTxt {
							@include fz_vw(30);
						}

					}

					&:before {
						content: "";
						background-color: $basicWhiteColor;
						@include props(w, 900);
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
					}

					.mainTxt {
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.64px;
						text-align: left;
						@include props(mb, 60);
					}

					dl {
						display: flex;
						flex-wrap: wrap;
						justify-content: left;

						dt {
							flex-basis: 25%;
							font-family: $YuGothic;
							@include fz_vw(18);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: right;
						}

						dd {
							width: 75%;
							font-family: $YuGothic;
							@include fz_vw(18);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
						}
					}
				}

				.img {
					width: 65.885%;
					@include props(h, 500);
					@include props(b, -50);
					right: 0;
					box-shadow: 10px 10px 30px 0px rgba(0, 0, 0, 0.1);

					iframe {
						margin: 0;
						width: 100%;
						height: 100%;
					}

					@include mq(md) {
						@include props(w, 710, 750);
						@include props(h, 550, 750);
						@include props(mt, 0, 750);
						@include props(mb, 0, 750);
						bottom: 0;
					}
				}
			}
		}

		.irohaStoreLinksWrap {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li {
				width: 30.38%;
				background: $basicWhiteColor;
				text-align: center;
				@include props(m, 30);

				@include mq(md) {
					width: 100%;
					max-width: 100%;
					@include props(mt, 0, 750);
					@include props(mr, 0, 750);
					@include props(ml, 0, 750);
					@include props(mb, 80, 750);

					&:last-child {
						@include props(mb, 0, 750);
					}
				}

				a {
					display: block;
					width: 100%;
					height: 100%;
					padding: 19.277% 9.15% 9.15%;
					position: relative;
					transition: opacity .3s;

					&:hover {
						opacity: .7;
					}

					&:after {
						content: "";
						background: url(/images/common/blank_icon.svg) bottom center no-repeat;
						background-size: contain;
						@include props(w, 21.5);
						@include props(h, 19.8);
						min-width: 12px;
						min-height: 10px;
						position: absolute;
						bottom: 9.15%;
						right: 9.15%;
					}

					p {
						font-family: $YuMincho;
						@include fz_vw(24);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						margin-bottom: 17.831%;

						&:before {
							content: "";
							width: 26.7%;
							@include props(h, 54);
							display: block;
							margin: 0 auto 12.1%;
						}
					}

					@include mq(md) {
						@include props(pt, 70, 750);
						@include props(pl, 40, 750);
						@include props(pb, 70, 750);
						@include props(pr, 40, 750);

						&:after {
							@include props(w, 38, 750);
							@include props(h, 35, 750);
							@include props(r, 40, 750);
							bottom: 50%;
							transform: translate(0, 50%);
						}

						p {
							@include fz_vw(36);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.42;
							letter-spacing: normal;
							@include props(mb, 0, 750);
							position: relative;

							&:before {
								@include props(w, 66, 750);
								@include props(h, 53, 750);
								position: absolute;
								top: 50%;
								left: 0;
								transform: translate(0, -50%);
								margin-bottom: 0;
							}
						}
					}
				}

				&.blog {
					p {
						&:before {
							background: url(/images/shop/iroha_store/irohastore_blog_icon.svg) center center no-repeat;
							background-size: contain;
						}
					}
				}

				&.twitter {
					p {
						&:before {
							background: url(/images/shop/iroha_store/irohastore_twitter_icon.svg) center center no-repeat;
							background-size: contain;
						}
					}
				}
			}
		}

	}

	.irohaStoreNewsBOX {
		position: relative;
		z-index: 1;
		@include props(pb, 140);

		@include mq(md) {
			@include props(pb, 140, 750);

			.minContainer {
				padding: 0 !important;
			}
		}

		ul {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb, 120);

			&:after {
				content: "";
				display: block;
				width: 31.039%;
			}

			@include mq(md) {
				@include props(mb, 50, 750);
				position: relative;

				&:after {
					content: none;
				}

				.slick-slide {
					padding: 0 .5%;
				}

				.slide-arrow {
					position: absolute;
					@include props(t, 145, 750);
					z-index: 2;
					@include props(w, 19, 750);
					@include props(h, 36.1, 750);

					&.prev-arrow {
						left: 10%;
						transform: translate(-50%, -50%);
					}

					&.next-arrow {
						right: 10%;
						transform: translate(50%, -50%);
					}
				}
			}

			li {
				width: 31.039%;

				.img {
					img {
						width: 100%
					}
				}

				.txt {
					@include props(pt, 40);

					@include mq(md) {
						@include props(pt, 80, 750);

						.descTxt {
							@include fz_vw(28);
							@include props(pb, 30, 750);
						}

						time {
							@include fz_vw(28);
						}
					}

					.descTxt {
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(pb, 30);
					}

					time {
						font-family: $YuGothic;
						@include fz_vw(14);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.29;
						letter-spacing: normal;
						text-align: left;
					}
				}
			}
		}
	}

	.irohaStoreMediaBox {
		position: relative;
		z-index: 1;

		.mediaPostList {
			@include props(mb, 120);

			@include mq(md) {
				@include props(mb, 40, 750);
			}

			li {
				background: $basicWhiteColor;
				@include props(mb, 70);

				@include mq(md) {
					@include props(mb, 80, 750);
				}

				&:last-child {
					@include props(mb, 0);
				}

				a {
					display: flex;
					@include props(pt, 70);
					@include props(pb, 70);
					@include props(pl, 35);
					@include props(pr, 80);
					position: relative;
					transition: opacity .3s;

					&:hover {
						opacity: .7;
					}

					@include mq(md) {
						@include props(pt, 35, 750);
						@include props(pb, 35, 750);
						@include props(pl, 40, 750);
						@include props(pr, 40, 750);
					}

					.listInner {
						display: flex;
						flex-wrap: wrap;
						width: 100%;

						@include mq(md) {
							flex-direction: column-reverse;
						}

						time {
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							width: 10.029%;
							padding-right: 1em;

							@include mq(md) {
								@include fz_vw(28);
								@include props(w, 170, 750);
								line-height: 1.71;
							}
						}

						p {
							width: 70%;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;

							@include mq(md) {
								@include fz_vw(28);
								width: 100%;
								line-height: 1.71;
								letter-spacing: normal;
								@include props(mb, 35, 750);
							}
						}
					}

					img {
						position: absolute;
						@include props(r, 80);
						@include props(b, 20);
						@include props(w, 148);
						@include props(h, 191);
						opacity: 0;
						visibility: hidden;
						transform: translateY(100px);
						transition: opacity 1s .5s, transform 1s .5s;

						@include mq(md) {
							position: inherit;
							@include props(w, 148, 750);
							@include props(h, 190, 750);
							@include props(r, 0, 750);
							@include props(b, 0, 750);
							@include props(t, -50, 750);
							@include props(ml, 50, 750);
						}
					}
				}

				&.active {
					a {
						img {
							opacity: 1;
							visibility: visible;
							transform: none;
						}
					}
				}
			}
		}
	}
}