@charset "utf-8";
#aboutTop{
	background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)120px,rgba(248, 240, 241,.6)850px,rgba(248, 240, 241,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)7.5vw,rgba(248, 240, 241,.6)52.63vw,rgba(248, 240, 241,.8) 62.5vw );
	}

	@include mq(md){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)16.66vw,rgba(248, 240, 241,.6)106vw,rgba(248, 240, 241,.8) 126.6vw );
	}

	.aboutIrohaArea{
		@include props(pt,185);
		position: relative;
		z-index: 1;
		transition: 3s cubic-bezier(.08,.92,.35,1);

		@include mq(md){
			@include props(pt,205,750);
		}
	}

	.secTit{
		font-family: $YuMincho;
		@include fz_vw(70);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 4.9px;
		text-align: center;
		@include props(pt,110);
		@include props(pb,110);
	
		@include mq(md){
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 0.07em;
			text-align: center;
			@include props(pt,150,750);
			@include props(pb,150,750);
		}
	}

	.aboutConceptWrap{
		@include props(pb,200);
		position: relative;

		@include mq(md){
			@include props(pb,690,750);
		}
		.conceptDesc{
			display: flex;
			flex-wrap: wrap;

			@include mq(md){
				flex-direction: column-reverse;
			}

			.txt{
				@include props(pt,45);
				width: 50%;
				@include mq(md){
					width: 100%;
					@include props(pl,40,750);
					@include props(pr,40,750);
					@include props(pt,120,750);
				}

				.inner{
					width: 100%;
					max-width:703px;
					margin-left: auto;
					@include props(pl,20);
					@include props(pr,0);

					@include mq(md){
						@include props(pl,0,750);
						@include props(pr,0,750);
					}

					.conceptTit{
						font-family: $YuMincho;
						@include fz_vw(54);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.26;
						letter-spacing: 3.24px;
						text-align: center;
						@include props(mb,70);

						&:after{
							content: "きもちよさを、自分らしく。";
							font-family: $YuMincho;
							@include fz_vw(24);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.48px;
							display: block;
							@include props(mt,20);
						}

						@include mq(md){
							@include fz_vw(54);
							letter-spacing: 1.62px;
							@include props(mb,120,750);

							&:after{
								@include fz_vw(24);
								@include props(mt,20,750);
							}
						}
					}

					.descTxt{
						p{
							font-family: $YuGothic;
							@include fz_vw(20);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 3;
							letter-spacing: 0.6px;
							text-align: left;
							display: inline-block;
						}

						&:nth-of-type(1){
							text-align: end;
							@include props(pr,66);
						}

						&:nth-of-type(2){
							text-align: end;
							@include props(pr,38);
						}

						&:nth-of-type(3){
							text-align: end;
						}

						&:not(:nth-of-type(4)){
							@include props(mb,40);
						}
						&:nth-of-type(4){
							text-align: end;
							width: 133%;
						}

						@include mq(md){
							p{
								@include fz_vw(32);
								line-height: 2.5;
								letter-spacing: .32px;
							}


							&:nth-of-type(1){
								text-align: left;
								@include props(pr,0);
							}
							&:nth-of-type(2){
								text-align: left;
								@include props(pr,0);
								p{
									width: 100%;
								}
							}
							&:nth-of-type(3){
								text-align: left;
							}

							&:not(:nth-of-type(4)){
								@include props(mb,120,750);
							}
							&:nth-of-type(4){
								text-align: left;
								width: 100%;
								p{
									width: 100%;
								}
							}
						}
					}
				}
			}

			.img{
				width: 50%;

				@include mq(md){
					width: 100%;
				}

				.videoBox{
					width: 93.75%;
					height: auto;
					position: relative;
					margin-right: 0;
					margin-left: auto;
					@include props(mb,160);
					@include mq(md){
					  width: 100%;
					  @include props(mb,0,750);
					}
					
					&:before{
					  display: block;
					  content: "";
					  width: 100%;
					  padding-top: 56.25%;
					}
					video{
					  display: block;
					  width: 100%;
					  height: 100%;
					  position: absolute;
					  top: 0;
					  left: 0;
					  right: 0;
					  bottom: 0;
					  object-fit: cover;
					  box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
					}
				  }

				img{
					@include props(ml,270);
					@include props(w,450);
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

					@include mq(md){
						position: absolute;
						@include props(b,160,750);
						right: 0;
						@include props(ml,0,750);
						@include props(w,473,750);
					}
				}
			}
		}
	}

	.aboutUsWrap{
		z-index: 1;
		@include props(pb,250);

		.childCategoryList{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			&:not(:last-of-type){
				@include props(mb,250);
			}

			@include mq(md){
				&:not(:last-of-type){
					@include props(mb,160,750);
				}
			}
			
	
			.childCategoryListItem{
				display:flex;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				position: relative;
				@include props(mb,200);
	
				&:before{
					content: "";
					background-color: $basicWhiteColor;
					@include props(w,900);
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
				}
				
				&:last-child{
					@include props(mb,0);
				}
	
				&:nth-child(even){
					flex-direction: row-reverse;
	
					&:before{
						content: "";
						position: absolute;
						top: 0;
						left: inherit;
						right: 0;
						z-index: -1;
					}
	
					.img{
						right: inherit;
						left: 0;
					}
				}
	
				@include mq(md){
					flex-wrap: wrap;
					flex-direction: column-reverse;
					align-items: baseline;
					@include props(pb,0,750);
					@include props(pr,0,750);
	
					&:before{
						@include props(l,-40,750);
						@include props(w,710,750);
						top: inherit;
						bottom: 0;
						height: 72.75%;
					}
	
					&:nth-child(even){
						flex-direction: column;
						@include props(pl,40,750);
	
		
						&:before{
							content: "";
							position: absolute;
							top: 0;
							left: inherit;
							@include props(r,-40,750);
							z-index: -1;
						}
	
						.img{
							@include props(l,-40,750);
							right: inherit;
						}
					}
	
					.txt{
						width: 100%;
						max-height: 100%;
						@include props(pt,80,750);
						@include props(pr,40,750);
						@include props(pl,0,750);
						@include props(pb,80,750);
	
						.mainTxt{
							@include fz_vw(40);
							font-stretch: normal;
							font-style: normal;
							line-height: 1.5;
							letter-spacing: normal;
							@include props(mb,80,750);
						}
	
						.descTxt{
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
						}
					}
				}
	
				.txt{
					@include props(pl,40);
					@include props(pr,40);
					@include props(pt,80);
					@include props(pb,80);
					width: 39.53%;
	
					.mainTxt{
						font-family: $YuGothic;
						@include fz_vw(24);
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(mb,40);
					}
	
					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;
					}
				}
	
				.img{
					width: 59.297218%;
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
					// transform: translate(0,50px);
					@include props(mt,50);
					@include props(mb,-50);
	
					img{
						margin: 0;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
	
					@include mq(md){
						@include props(w,710,750);
						@include props(mt,0,750);
						@include props(mb,0,750);
						bottom: 0;
						transform: translate(0,0);
					}
				}
			}
		}

		.aboutUsList{
			@include props(pt,50);
			@include props(pb,50);
			@include props(mb,200);
			position: relative;

			@include mq(md){
				@include props(pt,75,750);
				@include props(pb,160,750);
				@include props(mb,160,750);

				&::before{
					@include props(w,710,750);
					@include props(r,-40,750);
				}
			}

			&:before{
				content: "";
				width: 80.6734%;
				height: 100%;
				background: $basicWhiteColor;
				position: absolute;
				right: 0;
				top: 0;
				z-index: -1;
			}
			li{
				display: flex;
				flex-wrap: wrap;
				&:not(:last-child){
					@include props(mb,100);
				}

				@include mq(md){
					&:not(:last-child){
						@include props(mb,80,750);
					}
				}

				.img{
					img{
						box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
					}
				}

				.txt{
					.mainTxt{
						font-family: $YuGothic;
						@include fz_vw(24);
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(mb,50);

						@include mq(md){
							@include fz_vw(40);
							line-height: 1.5;
							@include props(mb,80,750);
						}
					}

					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;

						@include mq(md){
							@include fz_vw(28);
						}
					}
				}
			}

			.first{
				.img{
					width: 59.297%;
					@include mq(md){
						@include props(w,710,750);
						@include props(ml,-40,750);
					}
				}

				.txt{
					width: 40.703%;
					@include props(pl,40);
					@include props(pr,50);
					@include props(pt,30);

					@include mq(md){
						width: 100%;
						@include props(pl,40,750);
						@include props(pr,0,750);
						@include props(pt,80,750);
					}
				}
			}

			.remaining{
				width: 80.6734%;
				margin-left: auto;
				@include props(pl,50);
				@include props(pr,50);

				@include mq(md){
					width: 100%;
					@include props(pl,40,750);
					@include props(pr,0,750);
					flex-direction: column-reverse;
				}

				.txt{
					width: 36.064%;
					@include props(pr,40);
					@include props(pt,50);

					@include mq(md){
						width: 100%;
						@include props(pr,0,750);
						@include props(pt,80,750);

						.mainTxt{
							@include props(mb,40,750);
						}
					}
				}

				.img{
					width: 63.936%;
					@include mq(md){
						@include props(w,500,750);
						margin: 0 auto;
					}
				}
			}
		}
	}

	.anniversaryWrap{
		@include props(pt,200);
		@include props(pb,200);

		@include mq(md){
			@include props(pt,200,750);
			@include props(pb,200,750);
		}

		a{
			position: relative;

			.arrow{
				font-family: $YuMincho;
				@include fz_vw(16);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 3;
				letter-spacing: 0.96px;
				text-align: center;
				position: absolute;
				@include props(b,90);
				left: 0;
				width: 100%;
				color: #4d4d4d;

				@include mq(md){
					@include fz_vw(28);
					line-height: 1.71;
					letter-spacing: 1.68px;
					@include props(b,100,750);
				}

				svg{
					@include props(w,31);
					@include props(h,12);

					@include mq(md){
						@include props(w,42,750);
						@include props(h,12,750);
					}
				}
			}
		}
	}

	.aboutDescLinkWrap{
		@include props(pb,200);

		@include mq(md){
			@include props(pb,170,750);
		}

		.secTit{
			@include fz_vw(48);

			@include mq(md){
				@include fz_vw(54);
			}
		}
		.childCategoryList{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			
	
			.childCategoryListItem{
				display:flex;
				align-items: flex-start;
				justify-content: space-between;
				width: 100%;
				position: relative;
	
				@include mq(md){
					flex-wrap: wrap;
					flex-direction: column-reverse;
					align-items: baseline;
					@include props(pb,0,750);
					@include props(pr,0,750);
	
					.txt{
						width: 100%;
						max-height: 100%;
						@include props(pt,80,750);
						@include props(pr,40,750);
						@include props(pl,40,750);
						@include props(pb,0,750);
	
						.mainTxt{
							@include fz_vw(40);
							font-stretch: normal;
							font-style: normal;
							line-height: 1.5;
							letter-spacing: normal;
							@include props(mb,80,750);
						}
	
						.descTxt{
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
							@include props(mb,70,750);
						}
					}
				}
	
				.txt{
					@include props(pr,50);
					width: 40.703%;
	
					.mainTxt{
						font-family: $YuGothic;
						@include fz_vw(24);
						font-weight: bold;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(mb,50);
					}
	
					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;
						@include props(mb,50);
					}
				}

				.arrow{
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: 0.96px;
					display: block;
					text-align: end;
					font-family: $YuMincho;
		
					&:after{
						content: "";
						background: url(/images/about_iroha/next_arrow.svg) bottom center no-repeat;
						background-size: contain;
						@include props(w,41);
						@include props(h,10);
						display: inline-block;
						@include props(ml,10);
						margin-bottom: .2em;
					}
	
					@include mq(md){
						@include fz_vw(30);
		
						&:after{
							@include props(w,48,750);
							@include props(h,11,750);
							@include props(ml,16,750);
						}
					}
				}
	
				.img{
					width: 59.297%;
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
	
					img{
						margin: 0;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
	
					@include mq(md){
						@include props(w,670,750);
						@include props(mt,0,750);
						@include props(mb,0,750);
						bottom: 0;
						margin-left: auto;
						@include props(mr,-40,750);
					}
				}
			}

			&:nth-of-type(even){

				.childCategoryListItem{
					flex-direction: row-reverse;

					.txt{
						@include props(pr,0);
						@include props(pl,50);
					}

					@include mq(md){
						flex-direction: column-reverse;

						.txt{
							@include props(pr,40,750);
							@include props(pl,40,750);
						}

						.img{
							margin-right: auto;
							@include props(ml,-40,750);
						}
					}
				}
			}
		}
	}

	.aboutCardListWrap{
		@include props(pt,200);
		@include mq(md){
			@include props(pt,200,750);
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			li{
				width: 23.7188%;

				@include mq(md){
					width: 48.3582%;
					&:nth-child(-n+2){
						@include props(mb,40,750);
					}
				}

				a{
					display: flex;
					flex-direction: column;
					background: $basicWhiteColor;
					width: 100%;
					height: 100%;
					@include props(pt,50);
					@include props(pb,50);
					@include props(pl,25);
					@include props(pr,25);
					transition: opacity .3s;

					&:hover{
					  opacity: .7;
					}

					@include mq(md){
						@include props(pt,40,750);
						@include props(pb,40,750);
						@include props(pl,25,750);
						@include props(pr,25,750);
					}
					
					.cardTit{
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.7;
						letter-spacing: 0.64px;
						text-align: center;
						@include props(mb,25);

						@include mq(md){
							line-height: 1.6;
							@include fz_vw(32);
							@include props(mb,10,750);
						}
					}

					.cardRead{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;
						@include props(mb,60);

						@include mq(md){
							@include fz_vw(24);
							@include props(mb,20,750);
						}
					}

					span{
						margin-top: auto;
						text-align: end;
						font-family: $YuMincho;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: 0.32px;

						@include mq(md){
							@include fz_vw(28);
						}

						&.arrow{
							&:after{
								content: "";
								background: url(/images/common/arrow_next.svg) bottom center no-repeat;
								background-size: contain;
								width: 1.8em;
								height: 0.7em;
								display: inline-block;
								margin-left: 0.5em;
							}
						}
						&.blank{
							&:after{
								content: "";
								background: url(/images/common/blank_icon.svg) bottom center no-repeat;
								background-size: contain;
								width: 0.8em;
								height: 0.8em;
								display: inline-block;
								margin-left: 0.5em;
							}
						}
					}
				}
			}
		}
	}

	.aboutProductsCatWrap{
		@include props(pb,200);
		@include mq(md){
			@include props(pb,210,750);
		}
		.flex{
			display: flex;
			justify-content: center;

			@include mq(md){
				display: block;
			}
		}
		.aboutProductsCatCard{
			max-width: 32.211%;
			width: 100%;
			box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.1);
			@include props(mr,23);
			&:last-of-type{
				margin-right: 0;
				margin-bottom: 0;
			}
			@include mq(md){
				max-width: 100%;
				box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);
				@include props(mr,0);
				@include props(mb,80,750);
			}
			
			a{
				position: relative;
				display: block;
				background-color: #fff;
				height: 100%;
				@include props(pb,118);

				@include mq(md){
					@include props(pb,138, 750);
				}
			}
			img{
				width: 100%;
			}
			.img{
				margin: 0 auto;
				@include props(mb,40);

				@include mq(md){
					@include props(mb,60, 750);
				}
			}
			.logo{
				margin: 0 auto;
				@include props(w,218);
				@include props(mb,40);

				@include mq(md){
					@include props(w,218, 750);
					@include props(mb,50, 750);
				}
			}
			.cardRead{
				font-family: $YuGothic;
				@include fz_vw(16);
				font-weight: 500;
				line-height: 2;
				letter-spacing: 0.32px;
				color: #4d4d4d;
				@include props(pr,40);
				@include props(pl,40);

				@include mq(md){
					@include fz_vw(28);
					letter-spacing: 0.56px;
					@include props(pr,40, 750);
					@include props(pl,40, 750);
				}
			}
			span{
				position: absolute;
				margin-top: auto;
				text-align: end;
				font-family: $YuMincho;
				@include fz_vw(16);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1;
				letter-spacing: 0.32px;
				@include props(b,50);
				@include props(r,40);

				@include mq(md){
					@include fz_vw(28);
					@include props(b,60, 750);
					@include props(r,40, 750);
				}

				&.arrow{
					&:after{
						content: "";
						background: url(/images/common/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						width: 1.8em;
						height: 0.7em;
						display: inline-block;
						margin-left: 0.5em;
					}
				}
				&.blank{
					&:after{
						content: "";
						background: url(/images/common/blank_icon.svg) bottom center no-repeat;
						background-size: contain;
						width: 0.8em;
						height: 0.8em;
						display: inline-block;
						margin-left: 0.5em;
					}
				}
			}
		}
	}
}

#aboutCSRTop{
	background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)120px,rgba(248, 240, 241,.6)850px,rgba(248, 240, 241,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)7.5vw,rgba(248, 240, 241,.6)52.63vw,rgba(248, 240, 241,.8) 62.5vw );
	}

	@include mq(md){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)16.66vw,rgba(248, 240, 241,.6)106vw,rgba(248, 240, 241,.8) 126.6vw );
	}

	.aboutCSRArea{
		@include props(pt,185);
		position: relative;
		z-index: 1;
		transition: 3s cubic-bezier(.08,.92,.35,1);

		@include mq(md){
			@include props(pt,205,750);
		}
	}

	.aboutCsrWrap{
		.secTit{
			font-family: $YuMincho;
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 4.9px;
			text-align: center;
			@include props(pt,110);
			@include props(pb,110);
		
			@include mq(md){
				@include fz_vw(70);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: 0.07em;
				text-align: center;
				@include props(pt,150,750);
				@include props(pb,150,750);
			}
		}
	
		.readTxt{
			text-align: center;
	
			p{
				font-family: $YuGothic;
				@include fz_vw(18);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2;
				letter-spacing: normal;
				text-align: left;
				display: inline-block;
	
				@include mq(md){
					@include fz_vw(32);
					line-height: 2.56;
					letter-spacing: 1.28px;
				}
			}
		}
	
		.card{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mt,100);
	
			@include mq(md){
				@include props(mt,200,750);
			}
	
			.cardList{
				width: 47.584%;
				background: $basicWhiteColor;
				@include props(pt,70);
				@include props(pb,60);
				@include props(pl,75);
				@include props(pr,75);
	
				@include mq(md){
					width: 100%;
					@include props(pt,60,750);
					@include props(pb,60,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
					&:not(:last-of-type){
						@include props(mb,80,750);
					}
				}
	
				.cardTit{
					font-family: $YuMincho;
					@include fz_vw(28);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.86;
					letter-spacing: 0.56px;
					text-align: left;
					@include props(mb,30);
	
					@include mq(md){
						@include fz_vw(40);
						line-height: 1.3;
						letter-spacing: 0.8px;
						@include props(mb,80,750);
						text-align: center;
					}
				}
	
				.cardDesc{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.32px;
					text-align: left;
	
					@include mq(md){
						@include fz_vw(32);
						line-height: 2.56;
						letter-spacing: 1.28px;
					}
				}
	
				&:nth-of-type(1){
					display: flex;
					justify-content: space-between;
	
					@include mq(md){
						flex-direction: column-reverse;
					}
					&:after{
						content: "";
						background: url(/images/about_iroha/csr/csr_ribbon_icon.svg) top center no-repeat;
						background-size: contain;
						width: 8.4%;
						height: 100%;
						display: inline-block;
	
						@include mq(md){
							width: 100%;
							@include props(h,180,750);
							@include props(mb,80,750);
						}
					}

					&.active:after{
						opacity: 1;
						transform: none;
					}
	
					.txt{
						width: 86.6%;
						@include mq(md){
							width: 100%;
						}
					}
				}
			}
		}
	}

}

#aboutAwardTop{
	background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)120px,rgba(248, 240, 241,.6)850px,rgba(248, 240, 241,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)7.5vw,rgba(248, 240, 241,.6)52.63vw,rgba(248, 240, 241,.8) 62.5vw );
	}

	@include mq(md){
	  background-image:linear-gradient(to bottom,rgba(232, 223, 224,1)16.66vw,rgba(248, 240, 241,.6)106vw,rgba(248, 240, 241,.8) 126.6vw );
	}

	.aboutAwardArea{
		@include props(pt,185);
		position: relative;
		z-index: 1;
		transition: 3s cubic-bezier(.08,.92,.35,1);

		@include mq(md){
			@include props(pt,205,750);
		}
	}

	.aboutAwardWrap{
		.secTit{
			font-family: $YuMincho;
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 4.9px;
			text-align: center;
			@include props(pt,110);
			@include props(pb,160);
		
			@include mq(md){
				@include fz_vw(70);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: 0.07em;
				text-align: center;
				@include props(pt,150,750);
				@include props(pb,150,750);
			}
		}

		.awardList{
			&:not(:last-child){
				@include props(mb,250);
			}

			@include mq(md){
				&:not(:last-child){
					@include props(mb,170,750);
				}
			}
			.inner{
				position: relative;
				&:before{
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 96.3396%;
					height: 100%;
					background: $basicWhiteColor;
					z-index: -1;
				}

				@include mq(md){
					&:before{
						bottom: 0;
						@include props(l,-40,750);
						@include props(w,710,750);
						top: inherit;
						bottom: 0;
						height: 81.18%;
					}
				}
				.awardMain{
					display: flex;
					flex-wrap: wrap;
					@include props(pb,50);

					@include mq(md){
						flex-direction: column-reverse;
						@include props(pb,80,750);
					}

					.txt{
						width: 41.4348%;
						padding: 3.66%;

						@include mq(md){
							width: 100%;
							@include props(pt,80,750);
							@include props(pl,0,750);
							@include props(pr,40,750);
							@include props(pb,0,750);
						}

						.awardMainTit{
							font-family: $YuGothic;
							@include fz_vw(24);
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							@include props(mb,40);
							@include mq(md){
								@include fz_vw(40);
								line-height: 1.5;
								@include props(mb,80,750);
							}
						}

						.awardMainDesc{
							font-family: $YuGothic;
							@include fz_vw(18);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							@include mq(md){
								@include fz_vw(28);
							}
						}
					}

					.img{
						width: 58.5652%;
						@include props(mt,-50);
						@include mq(md){
							@include props(w,710,750);
							@include props(mt,0);
						}

						img{
							box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
						}
					}
				}

				.awardDesc{
					width: 96.3396%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					flex-wrap: wrap;
					padding: 1.83% 3.66% 2.928% 3.66%;
					border-top: 1px solid #E5E5E5;

					@include mq(md){
						width: 100%;
						@include props(pt,40,750);
						@include props(pr,40,750);
						@include props(pb,80,750);
						@include props(pl,0,750);
					}

					.img{
						width: 20.231%;
						padding-right: 3.79%;

						@include mq(md){
							width: 100%;
							padding-right: 0;
							@include props(mb,40,750);

							img{
								@include props(w,295,750);
							}
						}
					}

					p{
						width: 79.769%;
						font-family: $YuGothic;
						@include fz_vw(14);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;

						@include mq(md){
							width: 100%;
							@include fz_vw(20);
						}
					}
				}
			}

			.linkWrap{
				text-align: right;
				width: 96.3396%;
				padding-right: 3.66%;
				@include props(mt,40);

				@include mq(md){
					width: 100%;
					padding-right: 0;
					@include props(mt,80,750);
				}
				
				a{
					color: #4d4d4d;
					font-family: $YuMincho;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.25;
					letter-spacing: 0.32px;

					@include mq(md){
						@include fz_vw(28);
					}

					&:after{
						content: "";
						background: url(/images/common/blank_icon.svg) center center no-repeat;
						background-size: contain;
						width: .8em;
						height: .8em;
						display: inline-block;
						margin-left: .5em;
					}
				}
			}

			@include mq(md){
				&:nth-of-type(even){
					.inner{
						position: relative;
						&:before{
							bottom: 0;
							@include props(r,-40,750);
							left: inherit;
						}

						.awardMain{
							.txt{
								@include props(pl,40,750);
								@include props(pr,0,750);
							}

							.img{
								@include props(ml,-40,750);
							}
						}

						.awardDesc{
							@include props(pl,40,750);
							@include props(pr,0,750);
							border-top: none;
							position: relative;
							&:before{
								content: "";
								@include props(w,630,750);
								height: 1px;
								background: #e5e5e5;
								position: absolute;
								top: 0;
								right: 0;
							}
						}
					}
				}
			}
		}
	}

}