@charset "utf-8";

/* ======================================================================
基本設定
===================================================================== */

* {
  box-sizing: border-box;
}

/* レスポンシブチェック
------------------------------------------------------------- */

#responsibleCheck {
  width: 1px;
  height: 0;
  @include mq(md) {
    width: 2px;
  }
}

/* PC/SPで要素を出しわけ
------------------------------------------------------------- */

.pcOnly {
  @include mq(md) {
    display: none !important;
  }
}

.spOnly {
  display: none !important;
  @include mq(md) {
    display: block !important;
  }
}

html {
  font-size: $mainRemFontSize;
  visibility: hidden;

  &.wf-active,
  &.loading-delay{
    visibility: visible;
  }
}

body {
  position: relative;
  font-family: $base-font;
  font-size: 1.6rem;
  font-weight: normal;
  color: #555;
  overflow-x: hidden;

  &.fixedBody {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;

    #header {
      &.scrollDown{
        .headerContents{
          .navBtnBox{
            background-color: rgba(0, 0, 0, 0);
          }
        }
      }
    }
  }
}

/* ハイトカラー消去
------------------------------------------------------------- */

.offTouch {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

main {
  position: relative;
  display: block;
  width: 100%;

  &.fixHeader{
    @include props(pt, 120);

    @include mq(md) {
      @include props(pt, 125, 750);
    }
  }

  article {
    section {
      display: block;
      width: 100%;
      position: relative;
    }
  }
}

.maxContainer {
  max-width: 1600px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.container {
  max-width: 1520px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

.minContainer {
  max-width: 1406px;
  padding: 0 20px;
  margin: auto;

  @include mq(md) {
    @include props(pr, 40, 750);
    @include props(pl, 40, 750);
  }
}

a {
  color: #4d4d4d;
}
/* 共通デザイン
------------------------------------------------------------- */
.external{
  &::after{
    content: "";
    display: inline-block;
    vertical-align: baseline;
    background-image: url(/images/common/blank_icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    @include props(w, 12);
    @include props(h, 12);
    @include props(ml, 5);

    @include mq(md) {
      @include props(w, 36, 750);
      @include props(h, 31, 750);
      @include props(ml, 20, 750);
    }
  }

  &.white{
    &::after{
      background-image: url(/images/common/blank_icon_white.svg);
      @include props(w, 18);
      @include props(h, 16);
      @include props(ml, 10);

      @include mq(md) {
        @include props(w, 36, 750);
        @include props(h, 31, 750);
        @include props(ml, 20, 750);
      }
    }

    &.small{
      &::after{
        @include props(w, 15);
        @include props(h, 14);
        @include props(ml, 15);

        @include mq(md) {
          @include props(w, 30, 750);
          @include props(h, 26, 750);
          @include props(ml, 20, 750);
        }
      }
    }
  }
}

.btn01{
  background-color: inherit;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(247, 245, 244,0);
  transition: 3s cubic-bezier(.08,.92,.35,1);
  @include props(w, 650);

  @include mq(md) {
    @include props(w, 550, 750);
  }

  a{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $YuMincho;
    z-index: 2;
    background-color: inherit;
    @include props(pt, 120);
    @include props(pb, 60);
    span{
      @include fz_vw(30);
      font-weight: 500;
      line-height: 1.7;
      letter-spacing: 1.2px;
      color: #4d4d4d;
      position: relative;

      &::before{
        content: "";
        display: block;
        position: absolute;
        top: -50%;
        @include props(l, -30);
        transform: scale(0);
        z-index: -1;
        background-image: url(/images/common/btn_circles02.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: screen;
        backface-visibility: hidden;
        opacity: 1;
        // transition: transform .7s ease-in-out ,opacity 2s ease-in-out;
        transition-delay: .2s;
        backface-visibility: hidden;
        @include props(w, 140);
        @include props(h, 140);
        // transition: transform 4s ,opacity 4s;
  
        @include mq(md) {
          @include props(l, -20, 750);
          @include props(w, 140, 750);
          @include props(h, 140, 750);
        }
      }

      &::after{
        content: "";
        display: block;
        position: absolute;
        @include props(t, -70);
        @include props(r, -40);
        transform: scale(0);
        z-index: -1;
        background-image: url(/images/common/btn_circles03.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: screen;
        opacity: 1;
        backface-visibility: hidden;
        // transition: transform .7s ease-in-out ,opacity 2s ease-in-out;
        transition-delay: .3s;
        backface-visibility: hidden;
        @include props(w, 90);
        @include props(h, 90);
        // transition: transform 4s ,opacity 4s;
  
        @include mq(md) {
          @include props(t, -70,750);
          @include props(r, -40,750);
          @include props(w, 80, 750);
          @include props(h, 80, 750);
        }
      }
    }

    @include mq(md) {
      @include fz_vw(36);
      letter-spacing: .72px;
      @include props(pt, 115, 750);
      @include props(pb, 60, 750);
      @include props(pl, 150, 750);
    }

    &:hover{
      cursor: pointer;
      &::before{
        animation: 1s circle1 linear forwards !important;
      }
      &::after{
        animation: 1s arrow linear forwards;
      }

      span{
        &::before{
          animation: 1s circle1 linear forwards !important;
          animation-delay: .2s !important;
        }

        &::after{
          animation: 1s circle1 linear forwards !important;
          animation-delay: .4s !important;
        }
      }
    }

    &::before{
      content: "";
      display: block;
      position: absolute;
      @include props(b, 20);
      @include props(l, 0);
      z-index: -1;
      background-image: url(/images/common/btn_circles01.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      mix-blend-mode: screen;
      opacity: 1;
      backface-visibility: hidden;
      backface-visibility: hidden;
      @include props(w, 183);
      @include props(h, 183);
      transform: scale(0);
      // transition: transform 4s ,opacity 4s;

      @include mq(md) {
        @include props(b, 20, 750);
        @include props(l, 40, 750);
        @include props(w, 183, 750);
        @include props(h, 183, 750);
      }
    }

    &::after{
      content: "";
      display: block;
      background-image: url(/images/common/btn_arrow_01.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 1s;
      backface-visibility: hidden;
      @include props(ml, 10);
      @include props(w, 164);
      @include props(h, 17);
      // transform: scale(1, 1);
      // transform-origin: left top;

      @include mq(md) {
        @include props(ml, 10, 750);
        @include props(w, 164, 750);
        @include props(h, 17, 750);
      }
    }
  }

  &.circleIn{
    a{
      &::before{
        animation: 1s circle2 linear none;
        animation-delay: .5s;
      }

      span{
        &::before{
          animation: 1s circle2 linear none;
          animation-delay: .7s;
        }

        &::after{
          animation: 1s circle2 linear none;
          animation-delay: .9s;
        }
      }
    }
  }
}

@keyframes arrow{
  0%{
    transform: scale(1, 1);
    transform-origin: right top;
  }
  50%{
    transform: scale(0, 1);
    transform-origin: right top;
  }
  51%{
    transform: scale(0, 1);
    transform-origin: left top;
  }
  100%{
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
@keyframes back{
  0%{
    transform: scale(1, 1);
    transform-origin: left top;
  }
  50%{
    transform: scale(0, 1);
    transform-origin: left top;
  }
  51%{
    transform: scale(0, 1);
    transform-origin: right top;
  }
  100%{
    transform: scale(1, 1);
    transform-origin: right top;
  }
}
@keyframes circle1{
  0%{
    transform: scale(0.3);
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  100%{
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes circle2{
  0%{
    transform: scale(0.3);
    opacity: 1;
  }
  50%{
    opacity: 1;
  }
  100%{
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes topArrow{
  0%{
    transform: scale(1, 1);
    transform-origin: right top;
  }
  50%{
    transform: scale(1, 0);
    transform-origin: right top;
  }
  51%{
    transform: scale(1, 0);
    transform-origin: right bottom;
  }
  100%{
    transform: scale(1, 1);
    transform-origin: right bottom;
  }
}

/* アンカーリンクデザイン
------------------------------------------------------------- */
.pageTopBox{
  position: relative;
  z-index: 1;
  text-align: right;
  @include props(mt,300);

  @include mq(md){
    @include props(mt,200,750);
  }

  .pageTop{
    cursor: pointer;
    display: inline-block;
    font-family: $YuMincho;
    @include fz_vw(18);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.9px;
    text-align: center;
    color: #666;
    margin: 0 0 0 auto;

    @include mq(md) {
      @include fz_vw(24);
      letter-spacing: 0.6px;
    }

    &:hover{
      &::before{
        animation: 1s topArrow linear forwards;
      }
    }

    &::before{
      content: "";
      display: block;
      background-image: url(/images/common/page_top_icon.svg);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain;
      // transition: .5s;
      backface-visibility: hidden;
      @include props(ml, 30);
      @include props(w, 11);
      @include props(h, 80);
      @include props(mb,6);

      @include mq(md) {
        @include props(mb, 20, 750);
        @include props(ml, 50, 750);
        @include props(w, 10, 750);
        @include props(h, 80, 750);
      }
    }
  }
}

/* 汎用CSS
------------------------------------------------------------- */
/* ページ送り
------------------------------------------------------------- */
/* SNSリンク集
------------------------------------------------------------- */
/* パン屑リスト
------------------------------------------------------------- */
.breadcrumb {
  position: relative;
  z-index: 1;
  width: 100%;
  @include props(pt, 40);
  @include props(pb, 20);
  clear: both;

  @include mq(md) {
    @include props(pt, 30, 750);
    @include props(pb, 50, 750);
  }
  ol {
    @include clearfix;
    position: relative;
    width: 100%;
    word-break: break-all;
    text-align: left;
    line-height: 1;
    color: #acabab;
    margin: 0 auto;
    @include mq(md) {
      max-width: initial;
    }
    &>li {
      display: inline;
      font-family: $YuMincho;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1.22;
      letter-spacing: 0.9px;
      color: #666;
  
      @include mq(md) {
        @include fz_vw(20);
        letter-spacing: 0.5px;
      }

      a {
        font-family: $YuMincho;
        @include fz_vw(18);
        font-weight: 500;
        line-height: 1.22;
        letter-spacing: 0.9px;
        color: #666;

        @include mq(md) {
          @include fz_vw(20);
          letter-spacing: 0.5px;
        }
      }
      &:first-child {
        a {
          text-decoration: none;
        }
      }
      &+li::before {
        @include fz_vw(16);
        @include props(pr, 10);
        @include props(pl, 8);
        color: #444444;
        content: "＞";

        @include mq(md) {
          @include fz_vw(20);
          @include props(pr, 10, 750);
          @include props(pl, 10, 750);
        }
      }
    }
  }
}
/* 404ページ
------------------------------------------------------------- */
#notFoundTop{
  .notFoundArea{
    background-color: #f5f5f5;
    @include props(pt,120);
    @include mq(md){
      @include props(pt,125,750);
    }

    .notFound{
      @include props(pt,50);

      @include mq(md){
        @include props(pt,40,750);
      }
    }

    .logo{
      max-width: 27.819%;
      width: 100%;
      margin: 0 auto;
      @include props(mb,50);

      @include mq(md){
        @include props(w,380,750);
        @include props(mb,40,750);
      }
    }

    .notFoundTit{
      font-family: $YuMincho;
      @include fz_vw(28);
      font-weight: 500;
      line-height: 1.57;
      letter-spacing: 2.8px;
      text-align: center;
      color: #4d4d4d;
      @include props(mb,50);

      @include mq(md){
        @include fz_vw(34);
        line-height: 1.71;
        letter-spacing: .51px;
        @include props(mb,80,750);
      }
    }

    .notFoundTxt{
      font-family: $YuGothic;
      @include fz_vw(18);
      font-weight: 500;
      line-height: 1.78;
      letter-spacing: normal;
      text-align: center;
      color: #4d4d4d;

      a{
        text-decoration: underline;
      }

      @include mq(md){
        @include fz_vw(28);
        line-height: 2;
        text-align: left;
      }
    }
  }
}