@charset "utf-8";

/* ==============================================
 NEWSパーツ
============================================== */
.newsArea{
	@include props(pt,120);
	@include mq(md){
		@include props(pt,125,750);
	}
	.postArchiveBox{
		position: relative;
		z-index: 1;
		// @include props(pb,140);
	
		// @include mq(md){
		// 	@include props(pb,140,750);
		// }
	
		.secTit{
			font-family: $YuMincho;
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 4.9px;
			text-align: center;
			@include props(pt,110);
			@include props(pb,110);
		
			@include mq(md){
				@include fz_vw(70);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: 0.07em;
				text-align: center;
				@include props(pt,150,750);
				@include props(pb,120,750);
			}
		}
	
		.secSubTit{
			font-family: $YuMincho;
			@include fz_vw(24);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: 0.48px;
			text-align: center;
			@include props(mb,100);
	
			@include mq(md){
				@include fz_vw(36);
				font-weight: 600;
				line-height: normal;
				letter-spacing: 3.6px;
				@include props(mb,80,750);
				
			}
		}
	
		.selection{
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid #e5e5e5;
			@include props(mb,40);
			font-family: $YuMincho;
			@include mq(md){
				border-bottom: none;
				@include props(pr,40,750);
				@include props(pl,40,750);
				@include props(mb,120,750);
			}
	
			.categoryList{
				display: flex;
				justify-content: flex-start;
	
				@include mq(md){
					width: 58%;
					border-left: 1px solid #444444;
					justify-content: center;
					position: relative
				}
	
				dt{
					a{
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						letter-spacing: 1.2px;
						@include props(pl,25);
						@include props(pr,25);
						color: #666;
					}
	
					&.current{
						a{
							@include fz_vw(20);
							font-weight: 600;
							color:#4d4d4d;
						}
					}
	
					@include mq(md){
						@include fz_vw(28);
						font-weight: 500;
	
						&.current{
							font-weight: 600;
							color:#4d4d4d;
						}
					}
				}
	
				@include mq(md){
					#cateToggleBtn{
						width: 100%;
						text-align: center;
						&:hover{
							cursor: pointer;
						}
						&:after{
							content: "";
							width: .7em;
							height: .7em;
							border-bottom: 1px solid #4d4d4d;
							border-right: 1px solid #4d4d4d;
							display: inline-block;
							@include props(ml,70,750);
							transform: translate(0,-25%)rotate(45deg);
							transition: all .3s;
						}
	
						&.isOpen{
							&:after{
								transform: translate(0,25%)rotate(-135deg);
							}
						}
					}
				}

				dd{
					@include mq(md){
						position: absolute;
						bottom: 0px;
						width: 100%;
						transform: translate(0, 100%);
						z-index: 2;
					}
					ul{
						display: flex;
						li{
							a{
								@include props(pl,25);
								@include props(pr,25);
								@include fz_vw(18);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.78;
								letter-spacing: 1.08px;
								color: #666;
							}
		
							&.current{
								a{
									@include fz_vw(20);
									font-weight: 600;
									color:#4d4d4d;
								}
							}
						}

						@include mq(md){
							flex-direction: column;
							li{
								text-align: center;
								border-bottom: 1px solid #FFFDFD;
								a{
									display: block;
									width: 100%;
									@include props(pt,10,750);
									@include props(pb,10,750);
									@include fz_vw(28);
									background: #E5E5E5;
									color: #4d4d4d;

									&:hover{
										background: #ADADAD;
										color: #E5E5E5;
										cursor: pointer
									}
								}

								&.current{
									a{
										@include fz_vw(28);
										background: #E5E5E5;
										color: #4d4d4d;
										font-weight: 500;

										&:hover{
											background: #ADADAD;
											color: #E5E5E5;
										}
									}
							}
							}
						}
					}
				}
			}
	
			.archive{
				position: relative;
				@include props(pb,20);
	
				@include mq(md){
					@include props(pb,0,750);
					width: 42%;
					border-left: 1px solid #444444;
					border-right: 1px solid #444444;
				}
				dt{
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: 1.08px;
					color: #4d4d4d;
					@include props(pl,25);
					@include props(pr,25);
					&:hover{
						cursor: pointer;
					}
	
					@include mq(md){
						text-align: center;
						@include props(pl,20,750);
						@include props(pr,20,750);
						@include fz_vw(28);
						letter-spacing: 0.28px;
					}
	
					&:after{
						content: "";
						width: .7em;
						height: .7em;
						border-bottom: 1px solid #4d4d4d;
						border-right: 1px solid #4d4d4d;
						display: inline-block;
						margin-left: 1.25em;
						transform: translate(0,-25%)rotate(45deg);
						transition: all .3s;
					}
				}

				&.isOpen{
					dt{
						&:after{
							transform: translate(0,25%)rotate(-135deg);
						}
					}
				}
				dd{
					display: none;
					position: absolute;
					width: 100%;
					background: #E5E5E5;
					font-family: $YuMincho;
					z-index: 2;
					bottom: 0;
					transform: translate(0,100%);

					ul{
						li{
							a{
								display: block;
								width: 100%;
								@include props(pt,10);
								@include props(pb,10);
								@include fz_vw(18);
								color: #4d4d4d;
								text-align: center;

								&:hover{
									background: #ADADAD;
									color: #E5E5E5;
								}
								
							}
							border-bottom: 1px solid #FFFDFD;
						}
					}

					@include mq(md){

						ul{
							li{
								a{
									@include props(pt,10,750);
									@include props(pb,10,750);
									@include fz_vw(28);
								}
							}
						}
					}
				}
			}
		}
	
		.newsPaging{
			display: flex;
			align-items: center;
			justify-content: center;
	  
			.page{
			  font-family: $YuMincho;
			  @include fz_vw(16);
			  font-weight: 500;
			  line-height: 1;
			  color: #4d4d4d;
	  
			  a{
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				color: #4d4d4d;
				@include props(w, 36);
				@include props(h, 36);
				@include props(mr, 7);
				@include props(ml, 7);
	  
				&.active{
				  font-weight: bold;
				  color: #fff;
				  background-color: #4d4d4d;
				}
			  }
	  
			  @include mq(md) {
				@include fz_vw(28);
	  
				a{
				  @include props(w, 57, 750);
				  @include props(h, 57, 750);
				  @include props(mr, 5, 750);
				  @include props(ml, 5, 750);
				}
			  }
			}
			.pageArrow{
			  font-family: $YuMincho;
			  @include fz_vw(16);
			  font-weight: 500;
			  line-height: 1;
			  color: #4d4d4d;
				form{
					display: inline-block;
				}
			  a{
				color: #4d4d4d;
			  }
			  img{
				display: inline-block;
				vertical-align: baseline;
				transition: transform .3s;
				backface-visibility: hidden;
				@include props(w, 31);
			  }
	  
			  @include mq(md) {
				@include fz_vw(28);
				img{
				  @include props(w, 48, 750);
				}
			  }
	  
			  &.back{
				@include props(mr, 30);
				a{
				  &:hover{
					img{
						// transform: scale(0, 1);
						// transform-origin: left top;
						animation: 1s back linear forwards;
					}
				  }
				}
				img{
				  @include props(mr, 15);
				//   transition: transform .5s;
				//   transform: scale(1, 1);
				//   transform-origin: right top;
				}
	  
				@include mq(md) {
				  @include props(mr, 30, 750);
				  img{
					@include props(mr, 20, 750);
				  }
				}
			  }
			  &.next{
				@include props(ml, 10);
				img{
				  @include props(ml, 15);
				//   transition: transform .5s;
				//   transform: scale(1, 1);
				//   transform-origin: left top;
				}
				a{
				  &:hover{
					img{
						// transform: scale(0, 1);
						// transform-origin: right top;
						animation: 1s arrow linear forwards;
					}
				  }
				}
				&::before{
				  content: "...";
				  @include props(mr, 30);
				}
	  
				@include mq(md) {
				  @include props(ml, 5, 750);
				  img{
					@include props(ml, 20, 750);
				  }
				  &::before{
					@include props(mr, 30, 750);
				  }
				}
			  }
			}
		  }
	}
	
	.postSingleBox{
		.secTit{
			font-family: $YuMincho;
			@include fz_vw(70);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: 4.9px;
			text-align: center;
			@include props(pt,110);
			@include props(pb,110);
		
			@include mq(md){
				@include fz_vw(70);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.43;
				letter-spacing: 0.07em;
				text-align: center;
				@include props(pt,150,750);
				@include props(pb,150,750);
			}
		}
	
		.articlePost{
			background: $basicWhiteColor;
			@include props(p,100);
			@include props(mb,200);
	
			@include mq(md){
				@include props(pt,120,750);
				@include props(pb,120,750);
				@include props(pl,40,750);
				@include props(pr,40,750);
				@include props(mb,120,750);
			}
	
			.postTit{
				font-family: $YuGothic;
				@include fz_vw(32);
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: normal;
				text-align: left;
				@include props(mb,50);
	
				@include mq(md){
					@include fz_vw(44);
					line-height: 1.64;
					@include props(mb,50,750);
				}
			}
	
			.postDate{
				font-family: $YuGothic;
				@include fz_vw(14);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2;
				letter-spacing: normal;
				text-align: left;
				display: inline-block;
				@include props(mr,15);
				@include mq(md){
					@include fz_vw(28);
					line-height: 1.14;
					@include props(mr,30,750);
				}
			}
	
			.tag{
				font-family: $YuGothic;
				@include fz_vw(16);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2;
				letter-spacing: normal;
				text-align: left;
				display: inline-block;
				@include mq(md){
					@include fz_vw(28);
					line-height: 1.57;
				}
			}
	
			.articlePostInner {
				@include clearfix;
				h2{
					font-family: $YuGothic;
					@include fz_vw(28);
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.29;
					letter-spacing: normal;
					text-align: left;
					border-top: 1px solid #adadad;
					border-bottom: 1px solid #adadad;
					@include props(mb,50);
					@include props(mt,50);
	
					@include mq(md){
						@include fz_vw(40);
						line-height: 1.5;
						@include props(mb,80,750);
						@include props(mt,80,750);
						@include props(pt,40,750);
						@include props(pb,40,750);
					}
				}
	
				h3{
					font-family: $YuGothic;
					@include fz_vw(24);
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					text-align: left;
					border-bottom: 1px solid #adadad;
					@include props(pb,10);
					@include props(mb,50);
					@include props(mt,50);
	
					@include mq(md){
						@include fz_vw(36);
						line-height: 1.33;
						@include props(mb,60,750);
						@include props(mt,60,750);
						@include props(pb,20,750);
					}
				}
	
				p {
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					text-align: left;

					a{
						text-decoration: underline;
						color: #4d4d4d;
						@include fz_vw(16);
					}
	
					@include mq(md){
						@include fz_vw(28);
	
						a{
							@include fz_vw(28);
						}
					}
				}
	
				img {
					@include props(mt,50);
					@include props(mb,50);
		  
					&.alignleft{
					  display: inline;
					  float: left;
					  margin: 0 auto;
					  @include props(mr,20);
					}
		  
					&.aligncenter{
					  display: block;
					  margin-left: auto;
					  margin-right: auto;
					}
		  
					&.alignright{
					  display: inline;
					  float: right;
					  margin: 0 auto;
					  @include props(ml,20);
					}
		  
					@include mq(md){
					  @include props(mt,30,750);
					  @include props(mb,30,750);
		  
					  &.alignleft{
						@include props(mr,30,750);
					  }
			
					  &.alignright{
						@include props(ml,30,750);
					  }
					}
				}
			}
		}
	
		.pageLink{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			@include props(mb,300);
	
			@include mq(md){
				@include props(mb,200,750);
				@include props(pl,40,750);
				@include props(pr,40,750);
			}
	
	
			li{
				@include props(pl,60);
				@include props(pr,60);
				@include mq(md){
					@include props(pl,0,750);
					@include props(pr,0,750);
					&:nth-child(1){
						order: 1;
						width: 50%;
						border-bottom: 1px solid #adadad;
						border-top: 1px solid #adadad;
	
						a{
							display: block;
							width: 100%;
							height: 100%;
							@include props(pt,80,750);
							@include props(pb,80,750);
							padding-right: 1em;
						}
					}
					&:nth-child(2){
						order: 3;
						margin-left: auto;
						text-align: right;
						@include props(mt,100,750);
					}
					&:nth-child(3){
						order: 2;
						width: 50%;
						text-align: right;
						border-bottom: 1px solid #adadad;
						border-left: 1px solid #adadad;
						border-top: 1px solid #adadad;
						a{
							display: block;
							width: 100%;
							height: 100%;
							@include props(pt,80,750);
							@include props(pb,80,750);
							padding-left: 1em;
						}
					}
				}
				a{
					font-family: $YuMincho;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 3;
					letter-spacing: 0.96px;
	
					@include mq(md){
						@include fz_vw(28);
					}
					img{
						display: inline-block;
						vertical-align: baseline;
						backface-visibility: hidden;
						@include props(w, 22);
						@include props(mr,12);
	
						@include mq(md){
							@include props(w, 38,750);
							@include props(mr,16,750);
						}
	
	
						&.arrowBack{
							@include props(w, 41);
							// transition: transform .5s;
							// transform: scale(1, 1);
							// transform-origin: right top;
	
							@include mq(md){
								@include props(w, 75,750);
							}
						}
						&.arrowNext{
							@include props(w, 41);
							@include props(mr,0);
							@include props(ml,12);
							// transition: transform .5s;
							// transform: scale(1, 1);
							// transform-origin: left top;
	
							@include mq(md){
								@include props(w, 75,750);
								@include props(ml,16,750);
								@include props(mr,0,750);
							}
						}
					}
	
					&:hover{
						img{
							&.arrowBack{
								// transform: scale(0, 1);
								// transform-origin: left top;
								animation: 1s back linear forwards;
							}
							&.arrowNext{
								// transform: scale(0, 1);
								// transform-origin: right top;
								animation: 1s arrow linear forwards;
							}
						}
					}
				}
			}
		}
	}
	
}

/* ==============================================
NEWSパーツ
============================================== */

#newsTop{
	background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)120px,rgba(221, 221, 236,.1)850px,rgba(245, 245, 250,.6) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)7.5vw,rgba(221, 221, 236,.3)52.63vw,rgba(245, 245, 250,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)16.66vw,rgba(221, 221, 236,.3)106vw,rgba(245, 245, 250,.8) 126.6vw );
	}

	.postArchiveBox{
		.newsPostList{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb,120);

			&:after{
				content: "";
				display: block;
				width: 31.039%;
			}
			
			@include mq(md){
				@include props(mb,120,750);
				position: relative;

				&:after{
					content: none;
				}
			}

			li{
				width: 31.039%;
				@include props(mb,80);

				@include mq(md){
					width: 100%;
					@include props(mb,160,750);
					display: flex;
					justify-content: space-between;
					align-items: flex-start;

					&:last-child{
						@include props(mb,0,750);
					}
				}

				.img{
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
					img{
						width: 100%
					}

					@include mq(md){
						@include props(w,300,750);
					}
				}

				.txt{
					@include props(pt,40);

					@include mq(md){
						@include props(pt,0,750);
						width: 50%;

						.descTxt{
							@include fz_vw(28);
							@include props(pb,40,750);
						}

						time{
							@include fz_vw(28);
						}
					}

					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						@include props(pb,30);
					}

					time{
						font-family: $YuGothic;
						@include fz_vw(14);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.29;
						letter-spacing: normal;
						text-align: left;
					}

					.tag{
						width: 9.432%;
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;
						padding-left: 1em;
						// display: inline-block;

						@include mq(md){
							@include fz_vw(28);
							@include props(w,150,750);
							line-height: 1.57;
						}
					}
				}
			}

		}

		.archiveNext{
			max-width: 330px;
			width: 100%;
			@include props(mt,120);
			margin-left: auto;
			margin-right: auto;
			text-align: center;

			@include mq(md){
				@include props(mt,120,750);
			}

			a{
				font-family: $YuMincho;
				@include fz_vw(18);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2.67;
				letter-spacing: 1.08px;

				@include mq(md){
					@include fz_vw(28);
					line-height: 3.04;
					letter-spacing: 1.68px;

					img{
						@include props(w, 48,750);
						@include props(ml,26,750);
					}
				}

				img{
					display: inline-block;
					vertical-align: baseline;
					backface-visibility: hidden;
					@include props(w, 31);
					@include props(ml,12);
					// transition: transform .5s;
					// transform: scale(1, 1);
					// transform-origin: left top;
				}

				&:hover{
					img{
						// transform: scale(0, 1);
						// transform-origin: right top;
						animation: 1s arrow linear forwards;
					}
				}
			}
		}
	}
}

/* ==============================================
NEWS投稿パーツ
============================================== */

#newsPostTop{
	background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)120px,rgba(221, 221, 236,.1)850px,rgba(245, 245, 250,.6) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)7.5vw,rgba(221, 221, 236,.3)52.63vw,rgba(245, 245, 250,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(221, 221, 236,1)16.66vw,rgba(221, 221, 236,.3)106vw,rgba(245, 245, 250,.8) 126.6vw );
	}
}

/* ==============================================
MEDIAパーツ
============================================== */

#mediaTop{
	background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)120px,rgba(252, 238, 211,.3)850px,rgba(255, 249, 238,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)7.5vw,rgba(252, 238, 211,.3)52.63vw,rgba(255, 249, 238,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)16.66vw,rgba(252, 238, 211,.3)106vw,rgba(255, 249, 238,.8) 126.6vw );
	}

	.postArchiveBox{
		.selection{
			@include mq(md){
				@include props(mb,80,750);
			}
		}
		.mediaPostList{
			@include props(mb,200);
	
			@include mq(md){
				@include props(mb,120,750);
			}
			
			li{
				background: $basicWhiteColor;
				@include props(mb,70);

				&:first-child{
					@include props(mt,70);
				}
	
				@include mq(md){
					@include props(mb,80,750);
					&:first-child{
						@include props(mt,0,750);
					}
				}
	
				&:last-child{
					@include props(mb,0);
				}
	
				a{
					display:flex;
					@include props(pt,70);
					@include props(pb,70);
					@include props(pl,35);
					@include props(pr,80);
					position: relative;
	
					@include mq(md){
						@include props(pt,80,750);
						@include props(pb,80,750);
						@include props(pl,40,750);
						@include props(pr,40,750);
						align-items: center;
					}
	
					.listInner{
						display: flex;
						flex-wrap: wrap;
						width: 100%;
	
						time{
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							width: 8.792%;
							padding-right: 1em;
	
							@include mq(md){
								@include fz_vw(28);
								@include props(w,170,750);
								line-height: 1.71;
							}
						}
	
						.tag{
							min-width: 9.432%;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							padding-right: 1em;
							display: inline-block;
	
							@include mq(md){
								min-width: inherit;
								@include fz_vw(28);
								@include props(w,150,750);
								line-height: 1.57;
								flex-grow: 1;
								padding-right: 0;
							}
						}
	
						h3{
							width: 66.82%;
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
	
							@include mq(md){
								@include fz_vw(28);
								width: 100%;
								line-height: 2;
								letter-spacing: 1.12px;
								@include props(mt,40,750);
							}
						}
					}
	
					img{
						position: absolute;
						@include props(r,80);
						@include props(b,20);
						@include props(w,148);
						@include props(h,191);
						opacity: 0;
						visibility:hidden;
						transform: translateY(100px);
						transition: opacity 1s .5s, transform 1s .5s;
	
						@include mq(md){
							position: inherit;
							@include props(w,148,750);
							@include props(h,190,750);
							@include props(r,0,750);
							@include props(b,0,750);
							@include props(t,0,750);
							@include props(ml,40,750);
						}
					}
				}

				&.active{
					a{
						img{
							opacity: 1;
							visibility: visible;
							transform: none;
						}
					}
				}
			}
		}
	}

}

/* ==============================================
MEDIAパーツ
============================================== */

#mediaPostTop{
	background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)120px,rgba(252, 238, 211,.3)850px,rgba(255, 249, 238,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)7.5vw,rgba(252, 238, 211,.3)52.63vw,rgba(255, 249, 238,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(252, 238, 211,1)16.66vw,rgba(252, 238, 211,.3)106vw,rgba(255, 249, 238,.8) 126.6vw );
	}
}