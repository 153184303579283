@charset "utf-8";

.columnLayout {
	display: flex;
	justify-content: space-between;
	@for $i from 1 through 8 {
		$width: percentage(1 / $i);
		.col#{$i} {
			flex-basis: floor($width);
		}
	}
}

#bgWave{
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 1;
    display: block;
    pointer-events: none !important;
	width: 100vw;
	height: 100vh;
	object-fit: cover;
	@-moz-document url-prefix(){
		opacity: .8;
	}
	@supports (not (-webkit-hyphens: none)) and (not (-moz-appearance: none)){
		opacity: .8;
	}
  }

#subNav{
	max-width: 100%;
	width: 100%;
	background-color: #4d4d4d;
	@include props(h, 60);
	position: fixed;
	z-index: 10;
	@include props(t,120);
	left: 0;
	transition: all .4s;

	&.notTop{
		@include props(t,80);
	}

	@include mq(minCon){
		top: 7.5vw;

		&.notTop{
			top: 5vw;
		}
	}

	@include mq(md){
		@include props(t,125,750);

		&.notTop{
			@include props(t,80,750);
		}
	}

	dl{
		position: relative;
		height: 100%;

		dd{
		height: 100%;
		}
	}

	@include mq(md) {
		@include props(h, 80, 750);

		dl{
		dd{
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			z-index: 998;
			max-width: 100%;
			width: 100%;
			height: auto;
		}
		}

		.accordionBtn{
		position: relative;
		cursor: pointer;
		display: flex;
		align-items: center;
		height: 100%;
		@include props(pr, 200, 750);
		@include props(pl, 40, 750);

		&.isOpen{
			&::after{
			transform: rotate(-180deg);
			}
		}

		&::after{
			content: "";
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			@include props(r, 50, 750);
			margin: auto;
			background-image: url(/images/common/suvnav_accordion_arrow_white.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
			transition: transform .5s;
			backface-visibility: hidden;
			@include props(w, 45, 750);
			@include props(h, 23, 750);
		}

		p{
			font-family: $YuMincho;
			@include fz_vw(32);
			color: $basicWhiteColor;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.25;
			letter-spacing: 1.28px;
		}
		}
	}

	.flex{
		display: flex;
		align-items: center;
		height: 100%;

		@include mq(md) {
		display: block;
		background-color: rgba(77,77,77,.9);
		padding: 0;
		@include props(pt, 90, 750);
			.subNavList{
				flex-wrap: wrap;
				@include props(pl,190,750);
				@include props(pr,40,750);
				@include props(pb,215,750);

				.list{
					width: 100%;
					@include props(pl,0,750);
					@include props(pr,0,750);
					@include props(mr,0,750);
					@include props(ml,0,750);
					@include props(mb,70,750);

					a{
						@include fz_vw(32);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.25;
						letter-spacing: 1.28px;
					}

					&.current{
						a{
							color: #ADADAD;
						}
					}
				}
			}
		}

		.subNavList{
			display: flex;
			width: 100%;
			justify-content: center;

			.list{
				@include props(pl,7);
				@include props(pr,7);
				@include props(mr,18);
				@include props(ml,18);
				a{
					color: $basicWhiteColor;
					font-family: $YuMincho;
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.78;
					letter-spacing: 0.72px;
				}

				&.blank{
					a{
						&:after{
							content: "";
							background: url(/images/common/blank_icon_white.svg) center center no-repeat;
							background-size: contain;
							width: .8em;
							height: .8em;
							display: inline-block;
							margin-left: .55em;
						}
					}
				}
			}
		}
	}
}

#loading {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: auto;
	min-width: 100%;
	height: auto;
	min-height: 100%;
	background: #ffffff;
	opacity: 1;
	z-index: 100000;

	#percent {
		position: relative;
		max-width: 181px;

		#percent-text{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, .9);
			text-indent: -9999px;
			transition: transform .6s;
		}
	}
	.loadingTxt {
		font-family: $YuMincho;
		@include fz_vw(16);
		font-weight: 500;
		line-height: 1;
		letter-spacing: 1.12px;
		text-align: center;
		color: #adadad;
		@include props(mt,35);

		@include mq(md) {
			@include fz_vw(32);
			@include props(mt,35, 750);
		}
	}
}