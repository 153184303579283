@charset "utf-8";

/* ==============================================
products共通パーツ
============================================== */
.productsArea{
	position: relative;
	z-index: 1;
	background-image:linear-gradient(to bottom,rgba(245, 240, 238,1)120px,rgba(245, 240, 238,.1)850px,rgba(245, 240, 238,.6) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);
	@include props(pt,120);

	.twoColumnOnly{
		display: none;
	}

	@include mq(md){
		@include props(pt,125,750);

		.twoColumnOnly{
			display: block !important;
		}

		.BtnTemp{
			@include props(w,590,750);
			@include props(mt,0,750);
			@include props(mb,0,750);

			p{
				@include fz_vw(32);
			}
		}
	}

	&.iic_brand{
		background-image:linear-gradient(to bottom,rgba(237, 248, 237,1)120px,rgba(237, 248, 237,.1)850px,rgba(237, 248, 237,.6) 1000px );
	}
	&.ih_brand{
		background-image:linear-gradient(to bottom,rgba(255, 234, 226,1)120px,rgba(255, 234, 226,.1)850px,rgba(255, 234, 226,.6) 1000px );
	}

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(245, 240, 238,1)7.5vw,rgba(245, 240, 238,.6)52.63vw,rgba(245, 240, 238,.8) 62.5vw );

		&.iic_brand{
			background-image:linear-gradient(to bottom,rgba(237, 248, 237,1)7.5vw,rgba(237, 248, 237,.6)52.63vw,rgba(237, 248, 237,.8) 62.5vw );
		}
		&.ih_brand{
			background-image:linear-gradient(to bottom,rgba(255, 234, 226,1)7.5vw,rgba(255, 234, 226,.1)52.63vw,rgba(255, 234, 226,.6) 62.5vw );
		}
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(245, 240, 238,1)16.66vw,rgba(245, 240, 238,.1)106vw,rgba(245, 240, 238,.6) 126.6vw );

		&.iic_brand{
			background-image:linear-gradient(to bottom,rgba(237, 248, 237,1)16.66vw,rgba(237, 248, 237,.1)106vw,rgba(237, 248, 237,.6) 126.6vw );

			.twoColumnOnly{
				display: none !important;
			}
		}
		&.ih_brand{
			background-image:linear-gradient(to bottom,rgba(255, 234, 226,1)16.66vw,rgba(255, 234, 226,.1)106vw,rgba(255, 234, 226,.6) 126.6vw );

			.twoColumnOnly{
				display: none !important;
			}
		}
	}

.productsCateArea{
	@include props(pt,110);
	position: relative;
	z-index: 1;

	@include mq(md){
		@include props(pt,150,750);
	}

	.productsCateAreaTit{
		font-family: $YuMincho;
		@include fz_vw(70);
		@include props(mb,110);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 4.9px;
		text-align: center;

		@include mq(md){
			@include fz_vw(70);
			@include props(mb,150,750);
		}
	}

	.productsCateAreaList{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		//justify-content: space-between;

		@include mq(md){
			display: block;
		}

		.productsCateAreaListItem{
			width: 42.541%;
			//width: calc(100% / 3 - 1px);
			background-color: $basicWhiteColor;
			display: flex;
			flex-direction: column;
			margin-right: 1px;

			@include mq(md){
				width: 100%;
				border-right: none;
				margin-right: 0;
				@include props(mb,3, 750);
			}

			.img{
				box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

				@include mq(md){
					box-shadow: none;
				}
			}


			.txt{
				text-align: center;
				flex-grow: 1;

				.animeLinkEachsBtn{
					@include props(pt,38);
					@include props(pb,40);
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.mainTxt{
						font-family: $YuMincho;
						@include fz_vw(32);
						font-weight: 500;
						@include props(pb,20);
						line-height: 1.187;
						letter-spacing: 0.64px;
					}
		
					.subTxt{
						font-family: $YuMincho;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: 0.32px;
					}

					img{
						margin: 0 auto;
						@include props(w,242);
					}

					@include mq(md){
						@include props(pt,50,750);
						@include props(pb,50,750);
						@include props(pl,10,750);
						@include props(pr,10,750);

						.mainTxt{
							@include fz_vw(32);
							@include props(pb,20,750);
						}

						.subTxt{
							@include fz_vw(20);
						}

						img{
							max-width: 100%;
							width: 100%;
						}
					}
				}
			}

			&:last-child{
				margin-right: 0;
				.txt{
					.animeLinkEachsBtn{
						.mainTxt{
							@include fz_vw(28);

							@include mq(md){
								@include fz_vw(32);
							}
						}
					}
				}
			}

			@include mq(md){
				&:nth-child(even){
					border-right: none;
				}
			}
		}
	}
}

.productsList{
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;
	@include props(mb,100);

	&:after{
		content: "";
		display: block;
		width: 30.89%;
	}

	@include mq(md){
		@include props(mb,160,750);

		&:after{
			content: "";
			display: none;
		}

		&.iic_brand{
			.productsListItem{
				max-width: 100%;
				width: 100%;

				@include mq(md){
					@include props(pt,34,750);
					@include props(pb,30,750);
					@include props(pr,40,750);
					@include props(pl,40,750);
				}

				.itemTit{
	
					@include mq(md){
						@include fz_vw(44);
						line-height: 1.45;
						letter-spacing: .86px;
						@include props(mt,50, 750);
						@include props(mb,50, 750);
					}
				}
	
				.descTxt{
					@include mq(md){
						display: block !important;
					}
				}

				.itemTag{
					@include mq(md){
						flex-direction: row;
						@include props(pt,40,750);
						.itemTagList{
							@include props(pt,8,750);
							@include props(pb,8,750);
							@include props(pr,25,750);
							@include props(pl,25,750);
							@include props(mr,25,750);
							@include props(mb,20,750);
							@include fz_vw(24);
	
							&:last-child{
								@include props(mb,20,750);
							}
						}
					}
				}
			}
		}

		&.ih_brand{
			.productsListItem{
				max-width: 100%;
				width: 100%;

				@include mq(md){
					@include props(pt,34,750);
					@include props(pb,30,750);
					@include props(pr,40,750);
					@include props(pl,40,750);
				}

				.itemTit{
	
					@include mq(md){
						@include fz_vw(44);
						line-height: 1.45;
						letter-spacing: .86px;
						@include props(mt,50, 750);
						@include props(mb,50, 750);
					}
				}
	
				.descTxt{
					@include mq(md){
						display: block !important;
					}
				}

				.itemTag{
					@include mq(md){
						flex-direction: row;
						@include props(pt,40,750);
						.itemTagList{
							@include props(pt,8,750);
							@include props(pb,8,750);
							@include props(pr,25,750);
							@include props(pl,25,750);
							@include props(mr,25,750);
							@include props(mb,20,750);
							@include fz_vw(24);
	
							&:last-child{
								@include props(mb,20,750);
							}
						}
					}
				}
			}
		}
	}

	.productsListItem{
		width: 30.89%;
		padding: 30px 28px 25px 28px;
		background: $basicWhiteColor;
		display: flex;
		flex-direction: column;
		@include props(mb,100);
		@include mq(min){
			@include props(pt,30);
			@include props(pb,25);
			@include props(pl,28);
			@include props(pr,28);
		}

		@include mq(md){
			@include props(w,320,750);
			@include props(mb,40,750);
			@include props(p,20,750);
		}

		.txt{
			flex-grow: 1;

			.animeLinkEachsBtn{
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			.logo{
				@include props(mb,10);

				@include mq(md){
					@include props(mb,0,750);
				}
			}

			.itemTit{
				font-family: $YuMincho;
				@include fz_vw(28);
				font-weight: 500;
				line-height: 1.36;
				text-align: center;
				color: #4d4d4d;
				word-break: keep-all;
				@include props(mt,35);
				@include props(mb,22);

				@include mq(md){
					@include props(mt,20, 750);
					@include props(mb,20, 750);
				}
			}

			.descTxt{
				@include props(mb,32);
				@include fz_vw(16);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2;
				letter-spacing: 0.32px;
				text-align: left;
				color: #4d4d4d;

				@include mq(md){
					display: none !important;
					@include fz_vw(28);
					line-height: 2.07;
					letter-spacing: 0.28px;
					@include props(mb,40, 750);
				}
			}

			.itemTag{
				display: flex;
				justify-content: center;
				flex-wrap: wrap;
				@include props(pt,30);
				border-top: 1px solid #e5e5e5;

				.itemTagList{
					@include props(pt,2);
					@include props(pb,2);
					@include props(pr,15);
					@include props(pl,15);
					border: 1px solid #e5e5e5;
					border-radius: 5px;
					@include props(mr,15);
					@include props(mb,15);
					@include fz_vw(14);
					color: #808080;
				}

				@include mq(md){
					@include props(pt,16,750);
					flex-direction: column;
					align-items: center;
					.itemTagList{
						@include props(pt,4,750);
						@include props(pb,4,750);
						@include props(pr,16,750);
						@include props(pl,16,750);
						@include props(mr,0,750);
						@include props(mb,10,750);
						@include fz_vw(20);

						&:last-child{
							@include props(mb,0,750);
						}
					}
				}
			}
		}
	}
}

.productsDiagnosis{
	position: relative;
	z-index: 1;
	@include props(mb,300);

	@include props(mb,200,750);

	.irohaDiagnosis{
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		padding: 5.124%;
		background: $basicWhiteColor;

		@include mq(md){
			@include props(pt,100,750);
			@include props(pb,100,750);
			@include props(pl,25,750);
			@include props(pr,25,750);
		}

		.txt{
			width: 50%;
			padding-right: 1.2234%;

			@include mq(md){
				width: 100%;
				padding: 0;
			}

			.mainTxt{
				font-family: $YuMincho;
				@include fz_vw(48);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.15;
				letter-spacing: 1.92px;
				text-align: center;
				@include props(mb,55);

				@include mq(md){
          @include fz_vw(56);
					line-height: 1;
					letter-spacing: 2.6px;
          @include props(mb,60,750);
				}
			}

			.descTxt{
				font-family: $YuGothic;
				@include fz_vw(18);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 2;
				letter-spacing: 0.36px;
				@include props(mb,55);

				@include mq(md){
          @include fz_vw(32);
					letter-spacing: normal;
          @include props(mb,60,750);
					text-align: center;
				}
			}
		}

		.img{
			width: 50%;
			padding-left: 1.2234%;

			@include mq(md){
        width: 74.62%;
				padding:0;
				@include props(mb,80,750);
			}
		}
		.btn{
			font-family: $YuGothic;
			@include fz_vw(20);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.6;
			letter-spacing: normal;
			display: block;
			text-align: center;
			border: solid 1px #bfbfbf;
			border-radius: 5px;
			margin: 0 auto;
			@include props(w,400);
			@include props(pt,25);
			@include props(pb,25);
			@include props(pl,30);

			&:after{
				content: "";
				background: url(/images/common/blank_icon.svg) bottom center no-repeat;
				background-size: contain;
				@include props(w,22);
				@include props(h,20);
				display: inline-block;
				@include props(ml,30);
			}

			@include mq(md){
				@include fz_vw(30);
				@include props(w,590, 750);
				@include props(pt,45,750);
				@include props(pb,45,750);
				@include props(pl,30,750);

				&:after{
					width: 1em;
					height: 0.9em;
					@include props(ml, 30, 750);
				}
			}
		}
	}
  }

  .productsCateNameWrap{
	position: relative;
	z-index: 1;
	@include props(pt,140);
	@include props(pb,70);
	@include props(mb,300);

	@include mq(md){
		@include props(pt,160,750);
		@include props(pb,215,750);
		@include props(mb,200,750);
	}



	.txt{
		position: relative;
		@include props(h,666);

		@include mq(md){
			height: inherit;
			max-height: inherit;
			@include props(pr, 40, 750);
			@include props(pl, 40, 750);

			&:before{
				@include props(w,710,750);
			}
		}
		

		&:before{
			content: "";
			width: 57.29%;
			background: $basicWhiteColor;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: -1;
		}

		.txtWrap{
			max-width: 1406px;
			padding: 0 20px;
			margin: auto;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			@include props(pb,45);

			@include mq(md) {
				@include props(pr, 40, 750);
				@include props(pl, 40, 750);
				justify-content: start;
				@include props(pt,160,750);
				@include props(pb,335,750);
			}
		}

		.productsCateName{
			font-family: $YuMincho;
			@include fz_vw(54);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.41;
			letter-spacing: 2.7px;
			text-align: center;
			@include props(mb,30);
			width: 428px;

			@include mq(minCon){
				width: 39%;
				max-width: 100%;
			}

			@include mq(md){
				@include props(mb,40,750);
				text-align: center;
				@include fz_vw(70);
				line-height: 1.37;
				  letter-spacing: 3.5px;
				width: 100%;
				max-width: 100%;
			}

		}

		.productsCateSubName{
			@include fz_vw(24);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.08;
			letter-spacing: 0.48px;
			text-align: center;
			@include props(mb,80);
			width: 428px;

			@include mq(minCon){
				width: 39%;
				max-width: 100%;
			}

			@include mq(md){
				@include props(mb,120,750);
				text-align: center;
				@include fz_vw(30);
				line-height: 2.03;
				  letter-spacing: 0.6px;
				width: 100%;
				max-width: 100%;
			}
		}

		.productsCateDesc{
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2.22;
			letter-spacing: 0.36px;
			text-align: left;
			width: 428px;

			@include mq(minCon){
				width: 39%;
				max-width: 100%;
			}

			@include mq(md){
				@include fz_vw(30);
				line-height: 2;
				letter-spacing: 1.2px;
				text-align: left;
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.productsMain{
		position: absolute;
		right: 0;
		bottom: 0;
		box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.16);
		width: 57.29%;
		@include props(h,666);
		object-fit: cover;

		@include mq(md){
			@include props(w,710,750);
			@include props(h,430,750);
		}
	}
}

// .childCategoryWrap{
// 	position: relative;
// 	z-index: 1;
// 	@include props(pb,250);

// 	@include mq(md){
// 		@include props(pb,200,750);
// 	}

// 	.childCategoryList{
// 		display: flex;
// 		flex-direction: column;
// 		flex-wrap: wrap;

// 		.childCategoryListItem{
// 			display:flex;
// 			align-items: flex-start;
// 			width: 100%;
// 			position: relative;
// 			@include props(mb,250);
			
// 			&:last-child{
// 				@include props(mb,0);
// 			}

// 			&:nth-child(even){
// 				flex-direction: row-reverse;

// 				.txt{
// 					&:before{
// 						content: "";
// 						position: absolute;
// 						top: 0;
// 						left: inherit;
// 						right: 0;
// 						z-index: -1;
// 					}
// 				}

// 				.img{
// 					right: inherit;
// 					left: 0;
// 				}
// 			}

// 			@include mq(md){
// 				flex-wrap: wrap;
// 				flex-direction: column;
// 				align-items: flex-end;
// 				@include props(pb,80,750);
// 				@include props(pr,40,750);
// 				@include props(mb,160,750);

// 				&:nth-child(even){
// 					flex-direction: column;
// 					@include props(pl,40,750);

// 					.txt{
// 						&:before{
// 							content: "";
// 							position: absolute;
// 							top: 0;
// 							left: inherit;
// 							@include props(r,-40,750);
// 							z-index: -1;
// 						}
// 					}

// 					.img{
// 						@include props(l,-40,750);
// 						right: inherit;
// 					}
// 				}

// 				.txt{
// 					width: 100%;
// 					max-height: 100%;
// 					@include props(pb,565,750);
// 					@include props(pl,40,750);
// 					@include props(pr,0,750);
// 					@include props(pt,80,750);
// 					&:before{
// 						@include props(l,-40,750);
// 						@include props(w,710,750);
// 					}

// 					.mainTxt{
// 						@include fz_vw(56);
// 						font-weight: 500;
// 						font-stretch: normal;
// 						font-style: normal;
// 						line-height: 2;
// 						letter-spacing: 2.16px;
// 						@include props(mb,80,750);
// 					}

// 					.descTxt{
// 						@include fz_vw(28);
// 						font-weight: 500;
// 						font-stretch: normal;
// 						font-style: normal;
// 						line-height: 2;
// 						letter-spacing: 1.12px;
// 						@include props(mb,0,750);
// 					}
// 				}
// 			}

// 			.txt{
// 				@include props(pl,90);
// 				@include props(pr,90);
// 				@include props(pt,90);
// 				@include props(pb,56);
// 				width: 40.70%;

// 				&:before{
// 					content: "";
// 					background-color: $basicWhiteColor;
// 					@include props(w,900);
// 					height: 100%;
// 					position: absolute;
// 					top: 0;
// 					left: 0;
// 					z-index: -1;
// 				}

// 				.mainTxt{
// 					font-family: $YuMincho;
// 					@include fz_vw(44);
// 					font-weight: 500;
// 					font-stretch: normal;
// 					font-style: normal;
// 					line-height: 2;
// 					letter-spacing: 1.76px;
// 					text-align: center;
// 					@include props(mb,25);
// 				}

// 				.descTxt{
// 					font-family: $YuGothic;
// 					@include fz_vw(18);
// 					font-weight: 500;
// 					font-stretch: normal;
// 					font-style: normal;
// 					line-height: 2.22;
// 					letter-spacing: 0.36px;
// 					text-align: left;
// 					@include props(mb,110);
// 				}
// 			}

// 			.arrow{
// 				@include fz_vw(16);
// 				font-weight: 500;
// 				font-stretch: normal;
// 				font-style: normal;
// 				line-height: 1;
// 				letter-spacing: 0.96px;
// 				display: block;
// 				text-align: end;
// 				font-family: $YuMincho;
	
// 				&:after{
// 					content: "";
// 					background: url(/images/common/arrow_next.svg) bottom center no-repeat;
// 					background-size: contain;
// 					@include props(w,30);
// 					@include props(h,10);
// 					display: inline-block;
// 					@include props(ml,10);
// 				}

// 				@include mq(md){
// 					@include fz_vw(30);
	
// 					&:after{
// 						background: url(/images/products/self_pleasure/arrow_next_sp.svg) bottom center no-repeat;
// 						background-size: contain;
// 						@include props(w,53,750);
// 						@include props(h,11,750);
// 						@include props(ml,16,750);
// 					}
// 				}
// 			}

// 			.img{
// 				width: 59.297%;
// 				@include props(t,50);
// 				right: 0;
// 				box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

// 				img{
// 					margin: 0;
// 					object-fit: cover;
// 					width: 100%;
// 					height: 100%;
// 				}

// 				@include mq(md){
// 					position: absolute;
// 					@include props(w,670,750);
// 					@include props(mt,0,750);
// 					@include props(mb,0,750);
// 					@include props(r,-40,750);
// 					@include props(b,185,750);
// 					top: inherit;
// 				}
// 			}
// 		}
// 	}
// }

.productsListWrap{
	position: relative;
	z-index: 1;
}

.secTit{
	font-family: $YuMincho;
	@include fz_vw(70);
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: 4.9px;
	text-align: center;
	@include props(pt,110);
	@include props(pb,110);

	@include mq(md){
		@include fz_vw(70);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: 0.07em;
		text-align: center;
		@include props(pt,150,750);
		@include props(pb,150,750);
	}
}
}

/* ==============================================
products
============================================== */
#productsTop{
	.followingBtn{
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 2;
		display: none;

		@include mq(md){
			@include props(w,400,750);
			a{
				@include props(p,30,750);
				width: 100%;

				p{
					width: 100%;
					@include props(p,20,750);

					span{
						@include fz_vw(19);

						padding-right: 2.2em;

						&:after{
							@include props(r,-10,750);
						}
					}
				}
			}
		}

		a{
			background: #4d4d4d;
			display: inline-block;
			padding: 30px;

			p{
				text-align: center;
				display: inline-block;
				@include props(pt,20);
				@include props(pb,20);
				@include props(pl,100);
				@include props(pr,100);
				background: $basicWhiteColor;
				border-radius: 3px;

				
				span{
					@include fz_vw(20);
					font-family: $YuMincho;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.4;
					letter-spacing: 1.2px;
					position: relative;
					&:after{
						content: "";
						background: url(/images/products/index/products_search_arrow.svg) bottom center no-repeat;
						background-size: contain;
						width: 2.2em;
						height: 1.42em;
						position: absolute;
						bottom: -.2em;
						right: -2.5em;
					}
				}
			}
			&:hover{
				p{
					span{
						&:after{
							animation: 1s arrow linear forwards;
						}
					}
				}
			}
		}
	}

  .gradation{
    position: relative;
    z-index: 1;
  }

  .productsSearch{
	@include props(mt,300);
	position: relative;
	z-index: 1;

	@include mq(md){
		@include props(mt,160,750);
	}

	.productsSearchTit{
		font-family: $YuMincho;
		@include fz_vw(48);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.15;
		letter-spacing: 1.92px;
		text-align: center;
		@include props(mb,90);

		@include mq(md){
			@include fz_vw(54);
			line-height: 1.02;
			letter-spacing: 2.16px;
			@include props(mb,160,750);
		}
	}

	.productsSearchList{
		display: flex;
		flex-wrap: wrap;
		justify-content: left;
		@include props(mb,100);

		@include mq(md){
			justify-content: space-between;
			@include props(mb,200,750);

			&:after{
				content: "";
				display: block;
				@include props(w,213,750);
			}
		}

		li{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 215px;
			margin-right: 15.2px;
			margin-bottom: 15.2px;
			background: $basicWhiteColor;
			@include props(pt,30);
			@include props(pb,30);
			text-align: center;
			font-family: $YuGothic;
			@include fz_vw(16);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: 0.32px;
			border-radius: 3px;
			transition: background .3s;

			&.sort{
				background: #e8d7cf;
			}

			&:nth-child(6n){
				margin-right: 0px;
			}

			@include mq(min){
				width: 15.75%;
				margin-right: 1.098%;
				margin-bottom: 1.098%;
			}

			@include mq(md){
				@include props(w,213,750);
				max-width: inherit;
				@include props(mh,95,750);
				@include props(mr,0,750);
				@include props(mb,10,750);
				@include props(pt,15,750);
				@include props(pb,15,750);
				@include fz_vw(22);
				letter-spacing: 0.44px;
				line-height: 1.3;

				&:nth-child(5){
					letter-spacing: -1.32px;
				}
			}

			&:hover{
				cursor: pointer;
				background: #e8d7cf;
			}
		}
	}
  }
}

#productsParentCategoryTop{

	.childCategoryWrap{
		position: relative;
		z-index: 1;
		@include props(pb,250);
	
		@include mq(md){
			@include props(pb,200,750);
		}
	
		.childCategoryList{
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
	
			.childCategoryListItem{
				display:flex;
				align-items: flex-end;
				width: 100%;
				position: relative;
				@include props(mb,250);
				
				&:last-child{
					@include props(mb,0);
				}
	
				&:nth-child(even){
					flex-direction: row-reverse;
	
					.txt{
						&:before{
							content: "";
							position: absolute;
							top: 0;
							left: inherit;
							right: 0;
							z-index: -1;
						}
					}
	
					.img{
						right: inherit;
						left: 0;
					}
				}
	
				@include mq(md){
					flex-wrap: wrap;
					flex-direction: column;
					align-items: flex-end;
					@include props(pb,80,750);
					@include props(pr,40,750);
					@include props(mb,160,750);
	
					&:nth-child(even){
						flex-direction: column;
						@include props(pl,40,750);
	
						.txt{
							&:before{
								content: "";
								position: absolute;
								top: 0;
								left: inherit;
								@include props(r,-40,750);
								z-index: -1;
							}
						}
	
						.img{
							@include props(l,-40,750);
							right: inherit;
						}
					}
	
					.txt{
						width: 100%;
						max-height: 100%;
						@include props(pb,565,750);
						@include props(pl,40,750);
						@include props(pr,0,750);
						@include props(pt,80,750);
						&:before{
							@include props(l,-40,750);
							@include props(w,710,750);
						}
	
						.mainTxt{
							@include fz_vw(56);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 2.16px;
							@include props(mb,80,750);
						}
	
						.descTxt{
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
							@include props(mb,0,750);
						}
					}
				}
	
				.txt{
					@include props(pl,90);
					@include props(pr,90);
					@include props(pt,90);
					@include props(pb,56);
					width: 40.70%;
	
					&:before{
						content: "";
						background-color: $basicWhiteColor;
						@include props(w,900);
						height: 100%;
						position: absolute;
						top: 0;
						left: 0;
						z-index: -1;
					}
	
					.mainTxt{
						font-family: $YuMincho;
						@include fz_vw(44);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 1.76px;
						text-align: center;
						@include props(mb,25);
					}
	
					.descTxt{
						font-family: $YuGothic;
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.22;
						letter-spacing: 0.36px;
						text-align: left;
						@include props(mb,110);
					}
				}
	
				.arrow{
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1;
					letter-spacing: 0.96px;
					display: block;
					text-align: end;
					font-family: $YuMincho;
		
					&:after{
						content: "";
						background: url(/images/common/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						@include props(w,30);
						@include props(h,10);
						display: inline-block;
						@include props(ml,10);
					}
	
					@include mq(md){
						@include fz_vw(30);
		
						&:after{
							background: url(/images/products/self_pleasure/arrow_next_sp.svg) bottom center no-repeat;
							background-size: contain;
							@include props(w,53,750);
							@include props(h,11,750);
							@include props(ml,16,750);
						}
					}
				}
	
				.img{
					width: 59.297%;
					@include props(t,50);
					right: 0;
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
	
					img{
						margin: 0;
						object-fit: cover;
						width: 100%;
						height: 100%;
					}
	
					@include mq(md){
						position: absolute;
						@include props(w,670,750);
						@include props(mt,0,750);
						@include props(mb,0,750);
						@include props(r,-40,750);
						@include props(b,185,750);
						top: inherit;
					}
				}
			}
		}

		&.parent{
			@include props(pb,340);
	
			@include mq(md){
				@include props(pb,200,750);
			}

			.childParentListItem{
				max-width: 100%;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @include mq(md){
          flex-direction: column-reverse;
        }

        &:not(:last-of-type){
          @include props(mb,340);

          @include mq(md){
            @include props(mb,160,750);
          }
        }

        &:nth-of-type(even){
          flex-direction: row-reverse;

          .txt{
            text-align: right;
            &:before{
              right: inherit;
              left: 0;
            }

            .animeLinkEachsBtn{
              padding: 0 8.333% 0 0;
            }
          }

          @include mq(md){
            flex-direction: column-reverse;

            .txt{
              @include props(pl,0,750);
              @include props(pr,80,750);

              .animeLinkEachsBtn{
                @include props(pl,80,750);
                @include props(pr,50,750);
              }
            }

            .img{
              margin-left: auto;
              margin-right: 0;
            }
          }
        }

        .img{
          width: 62.5%;
          margin-bottom: -7.29166%;
          box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

          @include mq(md){
            @include props(w,710,750);
            margin-bottom: 0;
            margin-right: auto;
          }
        }

        .txt{
          width: 37.5%;
          padding: 7.8125% 0 4.166%;
          position: relative;
					
          @include mq(md){
            width: 100%;
            @include props(pt,100,750);
            @include props(pl,80,750);
            @include props(pr,0,750);
            @include props(pb,60,750);

            &:before{
              @include props(w,670,750);
              height: calc(100% + 6.66vw);
            }
          }
          
          &:before{
            content: "";
            position: absolute;
            right: 0;
						left: auto;
            top: 0;
            height: 100%;
            @include props(w,1200);
            background: $basicWhiteColor;
            z-index: -1;
          }

          .animeLinkEachsBtn{
            width: 86.1111%;
            display: inline-block;
            padding: 0 0 0 8.333%;
            text-align: center;

            @include mq(md){
              width: 100%;
              @include props(pl,50,750);
              @include props(pr,80,750);
              text-align: right;
            }

            .mainTxt{
              font-family: $YuMincho;
              @include fz_vw(42);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.43;
              letter-spacing: 2.1px;
              text-align: center;
              @include props(mb,14);

              @include mq(md){
                @include fz_vw(54);
                letter-spacing: 3.24px;
                @include props(mb,10,750);
              }
            }

            .descSubTxt{
              font-family: $YuGothic;
              @include fz_vw(20);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: 0.4px;
              text-align: center;
              display: block;
              @include mq(md){
                @include fz_vw(28);
                line-height: 2.14;
              }
            }

            .descTxt{
              font-family: $YuGothic;
              @include fz_vw(16);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2;
              letter-spacing: normal;
              text-align: left;
              @include props(mb,120);
              @include props(mt,60);
              width: 76.78%;
              margin-left: auto;
              margin-right: auto;
              @include mq(md){
                @include fz_vw(32);
                line-height: 2;
                width: 100%;
                @include props(mb,80,750);
                @include props(mt,80,750);
              }
            }

            .arrow{
              font-family: $YuMincho;
              @include fz_vw(18);
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 2.22;
              letter-spacing: 1.08px;

              @include mq(md) {
                @include fz_vw(28);
              }

              &::after{
                content: "";
                display: inline-block;
                background-image: url(/images/index/products_arrow.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                backface-visibility: hidden;
                @include props(ml, 10);
                width: 2.8em;
                height: .6em;
          
                @include mq(md) {
                  @include props(ml, 10, 750);
                  width: 1.8em;
                  height: .4em;
                }
              }
            }
          }
        }
      }
		}
	}

	.productsCateArea{
		padding-top: 0 !important;
		@include props(mb,200);

		@include mq(md){
			@include props(mb,200,750);

			.mainTxt{
				@include fz_vw(28);
			}
		}
	}

	.productsArea{
		position: relative;
		z-index: 1;
		background-color: #f7f5f4;
		transition: 3s cubic-bezier(.08,.92,.35,1);

		&.iic_brand{
			background-color: #f8fdf8;

			.fv{
				@include props(mb,175);
		
				@include mq(md){
					@include props(mb,150,750);
				}
			}

			.aboutIroha{
				.mainTit{
					font-family: $YuMincho;
					@include fz_vw(70);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.63;
					letter-spacing: 4.16px;
					text-align: center;
					color: #666;
					@include props(mb,155);
		
					&:after{
						display: none;
					}
		
					@include mq(md){
						@include fz_vw(60);
						font-feature-settings: "palt" 1;
						letter-spacing: 3.12px;
						@include props(mb,130,750);
					}
				}

				.aboutIrohaDesc{
					align-items: center;
					.txt{
						.inner{
							p{
								@include mq(md){
									font-feature-settings: "palt" 1;
									letter-spacing: normal;
								}
							}

							.descTxt{
								&:nth-of-type(3){
									@include mq(tab){
										@include props(pr,75);
									}
								}
							}
						}
					}
		
					.img{
						width: 50%;
		
						@include mq(md){
							width: 100%;
						}
						.videoBox{
							width: 100%;
							height: auto;
							position: relative;
							margin-right: auto;
							@include props(ml,-75);
							@include props(mt,0);

							@include mq(max){
								max-width: 900px;
							}
							@include mq(tab){
								@include props(ml,0);
							}
							@include mq(md){
								width: 100%;
								@include props(mt,0);
								@include props(ml,0,750);
							}
							
							&:before{
								display: block;
								content: "";
								width: 100%;
								padding-top: 56.25%;
							}
							// video{
							// 	display: block;
							// 	width: 100%;
							// 	height: 100%;
							// 	position: absolute;
							// 	top: 0;
							// 	left: 0;
							// 	right: 0;
							// 	bottom: 0;
							// 	object-fit: cover;
							// 	box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
							// }
						}

						.video-js{
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							object-fit: cover;

							.vjs-big-play-button{
								top: 50%;
								left: 50%;
								transform: translate(-50%,-50%);
								opacity: 0;
							}

							.vjs-poster{
								background-color: inherit;
								background-size: cover;
							}
						}
					}
				}
			}
		}

		&.ih_brand{
			background-color: #ffeae2;

			.fv{
				@include props(mb,175);
		
				@include mq(md){
					@include props(mb,150,750);
				}
			}

			.aboutIroha{
				@include props(pb,180);

				@include mq(md){
					@include props(pb,200,750);
				}

				.mainTit{
					font-family: $YuMincho;
					@include fz_vw(70);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.63;
					letter-spacing: 4.16px;
					text-align: center;
					color: #666;
					@include props(mb,105);
		
					&:after{
						display: none;
					}
		
					@include mq(md){
						@include fz_vw(60);
						font-feature-settings: "palt" 1;
						letter-spacing: 3.12px;
						@include props(mb,130,750);
					}
				}

				.aboutIrohaDesc{
					display: block;
					.txt{
						width: 100%;
						.inner{
							max-width: 100%;
							margin-right: auto;
							@include props(pl,0);

							p{
								text-align: center;
								@include mq(md){
									font-feature-settings: "palt" 1;
									letter-spacing: normal;
									text-align: left;
								}
							}

							.descTxt{
								&:nth-of-type(1){
									text-align: center;
									@include props(pl,0);
								}
								&:nth-of-type(2){
									text-align: center;
								}
								&:nth-of-type(3){
									text-align: center;
									@include props(pr,0);
		
									@include mq(md){
										@include props(mb,0);
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
	.aboutIroha{
    @include props(pt,100);
    @include props(pb,200);

    @include mq(md){
      @include props(pt,140,750);
      @include props(pb,200,750);
    }

		.fvLogo{
			margin: 0 auto;
			@include props(w,306);
			@include props(mb,95);
	
			@include mq(md){
				@include props(w,306,750);
				@include props(mb,140,750);
			}
		}

		.fv{
			@include props(mb,200);

			img{
				width: 100%;
			}
	
			@include mq(md){
				@include props(mb,150,750);
			}
		}

    .mainTit{
      font-family: $YuMincho;
      @include fz_vw(80);
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: 5.2px;
      text-align: center;
      color: #666;
      @include props(mb,165);

      &:after{
        content: "きもちよさを、自分らしく。";
          font-family: $YuMincho;
          @include fz_vw(32);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: 1.92px;
          text-align: center;
          display: block;
          @include props(mt,70);
      }

      @include mq(md){
        @include fz_vw(66);
        letter-spacing: 1.715px;
        @include props(mb,150,750);

        &:after{
          @include fz_vw(32);
           @include props(mt,40,750);
        }
      }
    }

    .aboutIrohaDesc{
      display: flex;
      flex-wrap: wrap;
      @include mq(md){
        flex-direction: column;
      }
      .txt{
        width: 50%;
        @include mq(md){
          width: 100%;
          @include props(pl,40,750);
          @include props(pr,40,750);
        }

        .inner{
          width: 100%;
          max-width: 860px;
          margin-left: auto;
          margin-right: 0;
          @include props(pl,50);

          @include mq(md){
            @include props(pl,0,750);
          }

          p{
            font-family: $YuGothic;
            @include fz_vw(20);
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 3;
            letter-spacing: 0.6px;
            text-align: left;
            display: inline-block;

            @include mq(md){
							display: block;
              @include fz_vw(32);
              line-height: 2.5;
              letter-spacing: 0.32px;
            }
          }

          .descTxt{
            &:nth-of-type(1){
              @include props(mb,70);
              @include props(pl,127);

              @include mq(md){
                @include props(mb,80,750);
                @include props(pl,0);
              }
            }
            &:nth-of-type(2){
              @include props(mb,70);
              @include mq(md){
                @include props(mb,80,750);
                @include props(pl,0);
              }
            }
            &:nth-of-type(3){
              text-align: right;
              @include props(pr,150);
              @include mq(md){
                @include props(pr,0,750);
                @include props(pl,0);
                @include props(mb,150,750);
                text-align: left;
              }
            }
          }

          .btn01{
            margin-left: auto;
            margin-right: 0;
            @include mq(md){
              @include props(mr,40,750)
            }

            a{
              justify-content: flex-end;

              &:before{
                content: "";
                @include props(l, 150);
              }
            }
          }
        }
      }

      .img{
        width: 50%;

        @include mq(md){
          width: 100%;
        }
        .videoBox{
					width: 100%;
          height: auto;
          position: relative;
          margin-right: auto;
          @include props(ml,-75);
          @include props(mt,140);
		
					@include mq(max){
						max-width: 900px;
					}
          @include mq(tab){
						@include props(ml,0);
          }
          @include mq(md){
            width: 100%;
            @include props(mt,0);
            @include props(ml,0,750);
          }
          
          &:before{
            display: block;
            content: "";
            width: 100%;
						padding-top: 56.25%;
          }
          // video{
          //   display: block;
          //   width: 100%;
          //   height: 100%;
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          //   right: 0;
          //   bottom: 0;
          //   object-fit: cover;
          //   box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
          // }
        }

				.video-js{
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					object-fit: cover;

					.vjs-big-play-button{
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						opacity: 0;
					}

					.vjs-poster{
						background-color: inherit;
						background-size: cover;
					}
				}
      }
    }
  }

	// .productsCateAreaList{
	// 	.productsCateAreaListItem{
	// 		.img{
	// 			padding-top: 65.72%;
	// 			position: relative;

	// 			a{
	// 				position: absolute;
	// 				top: 0;
	// 				height: 100%;
	// 				width: 100%;
					
	// 				img{
	// 					height: 100%;
	// 					width: 100%;
	// 					object-fit: cover;
	// 					object-position: center;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	.relatedInfo{
    @include props(pt,50);
    @include props(pb,250);

    @include mq(md){
      @include props(pt,0);
      @include props(pb,200,750);
    }

		.minContainer{
			max-width: 1456px;
		}

		.mainTxt{
      font-family: $YuMincho;
      @include fz_vw(70);
			font-weight: 500;
			line-height: 1;
			letter-spacing: 4.9px;
			text-align: center;
			color: #666666;
			@include props(mb,150);

			@include mq(md){
				line-height: 1.29;
				letter-spacing: 2.45px;
				@include props(mb,150, 750);
			}
		}

    #relatedInfoSlide{
      position: relative;
			.slick-track{
				display: flex;
			}
      .slick-slide{
				height: auto;
				background-color: #fff;
        box-shadow: 5px 10px 20px 0 rgba(0, 0, 0, 0.16);
        line-height: 0;
        @include props(w,422);
        @include props(mr,25);
        @include props(ml,25);
        @include props(mb,25);

        @include mq(desk){
          box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
        }
        @include mq(tab){
          box-shadow: 0.26vw 0.521vw 1.042vw 0 rgba(0, 0, 0, 0.16);
        }
        @include mq(md){
          @include props(w,425, 750);
          @include props(mr,41, 750);
          @include props(ml,41, 750);
          @include props(mb,25, 750);
          box-shadow: 0.667vw 1.333vw 2.667vw 0 rgba(0, 0, 0, 0.16);
        }
      }
      li{
				@include mq(md){
					position: relative;
					z-index: 1;
					@include props(mb,42, 750);

					&:last-of-type{
						margin-bottom: 0;
					}

					&::after{
						content: "";
						display: block;
						height: 100%;
						background-color: #fff;
						position: absolute;
						bottom: 0;
						right: 0;
						z-index: -1;
						@include props(w,490, 750);
					}
				}
				a{
					@include mq(md){
						display: flex;
						align-items: center;
						@include props(pt,40, 750);
						@include props(pb,40, 750);
					}
				}
				.img{
					@include mq(md){
						flex-shrink: 0;
						@include props(w,300, 750);
						@include props(mr,35, 750);
					}
				}
        img{
          width: 100%;
        }
				.txt{
					@include fz_vw(18);
					font-weight: bold;
					line-height: 1.78;
					color: #4d4d4d;
					@include props(pt,40);
					@include props(pb,40);
					@include props(pr,30);
					@include props(pl,30);

					@include mq(md){
						@include fz_vw(28);
						line-height: 1.57;
						@include props(pt,14, 750);
						@include props(pb,14, 750);
						@include props(pr,40, 750);
						@include props(pl,0);
					}
				}
      }

      .slide-arrow{
        position: absolute;
        @include props(t,212.5);
        z-index: 2;
        @include props(w,19);
        @include props(h,36.1);
        
        &.prev-arrow{
          left: 0%;
          transform: translate(-50%,-50%);
        }
        &.next-arrow{
          right: 0%;
          transform: translate(50%,-50%);
        }

        @include mq(max){
          &.prev-arrow{
            left: -92px;
          }
          &.next-arrow{
            right: -92px;
          }
        }

        @include mq(fixed){
          &.prev-arrow{
            left: -70px;
          }
          &.next-arrow{
            right: -70px;
          }
        }

        @include mq(md){
          @include props(t,212.5,750);
          @include props(w,19,750);
          @include props(h,36.1,750);

          &.prev-arrow{
            left: 13.33%;
            transform: translate(0%,-50%);
          }
          &.next-arrow{
            right: 13.33%;
            transform: translate(0%,-50%);
          }
        }
      }
    }

    .slide-dots{
      display: flex;
      justify-content: center;
      @include props(mt,95);
      @include mq(md){
        @include props(mt,95,750);
      }
      li{
        padding: 0 !important;
        @include props(w,30);
        @include props(h,4);
        background: #C3BFBF;
        margin: 0 5px;

        @include mq(md){
          @include props(w,30,750);
          @include props(h,4,750);
          @include props(ml,7,750);
          @include props(mr,7,750);
        }

        &:hover{
          cursor: pointer;
        }

        &.slick-active{
          background: #666666;
        }

        button{
          text-indent: -9999px;
        }
      }
    }
	}
}

#productsTouchTop{
	.productsCateArea{
		padding-top: 0 !important;
		@include props(mb,200);

		@include mq(md){
			@include props(mb,200,750);
		}
	}
}


/* ==============================================
products記事ページ
============================================== */
#productsSingleTop{
	background-image:linear-gradient(to bottom,rgba(222, 229, 229,1)120px,rgba(240, 246, 246,.6)850px,rgba(240, 246, 246,.8) 1000px );
	transition: 3s cubic-bezier(.08,.92,.35,1);

	@include mq(minCon){
		background-image:linear-gradient(to bottom,rgba(222, 229, 229,1)7.5vw,rgba(240, 246, 246,.6)52.63vw,rgba(240, 246, 246,.8) 62.5vw );
	}

	@include mq(md){
		background-image:linear-gradient(to bottom,rgba(222, 229, 229,1)16.66vw,rgba(240, 246, 246,.6)106vw,rgba(240, 246, 246,.8) 126.6vw );

		.twoColumnOnly{
			display: none !important;
		}
	}

	.productsArea{
		position: relative;
		z-index: 1;
		background-image:none;
		@include props(pt,180);

		@include mq(minCon){
			padding-top: 11.25vw;
		}

		@include mq(md){
			@include props(pt,205,750);
		}
	
	}

	.productsSingleConcept{
		position: relative;
		z-index: 1;
		@include clearfix;
		@include props(mt,115);
		@include props(mb,115);

		@include mq(md){
			@include props(mt,130,750);
			@include props(mb,160,750);
		}

		h1{
			font-family: $YuMincho;
			@include fz_vw(50);
			font-weight: 500;
			line-height: 1.6;
			text-align: center;
			@include props(mb,80);
			@include mq(md){
				@include props(mb,120,750);
			}
			img{
				object-fit: cover;
				margin-left: auto;
				margin-right: auto;
				@include props(w,730);
				@include props(h,284);

				@include mq(md){
					width: 100%;
					max-width: 100%;
				}
			}
		}

		h2{
			font-family: $YuMincho;
			@include fz_vw(44);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.82;
			letter-spacing: 3.08px;
			text-align: center;
			@include props(mb,140);

			@include mq(md){
				@include fz_vw(50);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.6;
				letter-spacing: 3.5px;
				@include props(mb,120,750);
			}
		}

		.read{

			@include mq(md){
				@include props(pl,40,750);
				@include props(pr,40,750);
			}

			p{
				@include fz_vw(20);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 3;
				letter-spacing: 0.6px;
				text-align: center;
	
				@include mq(md){
					@include fz_vw(32);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.56;
					letter-spacing: 1.28px;
					text-align: left;
				}
	
				img {
					@include props(mt,50);
					@include props(mb,50);
			
					&.alignleft{
						display: inline;
						float: left;
						margin: 0 auto;
						@include props(mr,20);
					}
			
					&.aligncenter{
						display: block;
						margin-left: auto;
						margin-right: auto;
					}
			
					&.alignright{
						display: inline;
						float: right;
						margin: 0 auto;
						@include props(ml,20);
					}
			
					@include mq(md){
						@include props(mt,30,750);
						@include props(mb,30,750);
			
						&.alignleft{
						@include props(mr,30,750);
						}
			
						&.alignright{
						@include props(ml,30,750);
						}
					}
				}
			}
		}
	}

.childCategoryWrap{
	position: relative;
	z-index: 1;
	@include props(pb,200);

	@include mq(md){
		@include props(pb,200,750);
	}

	.childCategoryList{
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		.childCategoryListItem{
			display:flex;
			align-items: flex-end;
			width: 100%;
			position: relative;
			@include props(mb,250);

			&:nth-child(even){
				flex-direction: row-reverse;

				.txt{
					&:before{
						content: "";
						position: absolute;
						top: 0;
						left: inherit;
						right: 0;
						z-index: -1;
					}
				}

				.img{
					right: inherit;
					left: 0;
				}
			}

			@include mq(md){
				flex-wrap: wrap;
				flex-direction: column;
				align-items: inherit !important;
				@include props(pr,40,750);
				@include props(mb,160,750);
				@include props(pb,0,750);

				&:nth-child(even){
					flex-direction: column;
					@include props(pl,40,750);

					.txt{
						&:before{
							content: "";
							position: absolute;
							top: 0;
							left: inherit;
							@include props(r,-40,750);
							z-index: -1;
						}
					}

					.img{
						@include props(l,-40,750);
						right: inherit;
						@include props(ml,-40,750)
					}
				}

				.txt{
					width: 100%;
					max-height: 100%;
					min-height: auto;
					@include props(pl,40,750);
					@include props(pr,0,750);
					@include props(pt,160,750);
					@include props(pb,80,750);
					&:before{
						@include props(l,-40,750);
						@include props(w,710,750);
						height: calc(100% - 27vw)
					}

					.mainTxt{
						@include fz_vw(48);
						text-align: center;
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 2.16px;
						@include props(mb,80,750);
					}

					.descTxt{
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 1.12px;
						@include props(mb,0,750);
					}
				}
			}

			.txt{
				@include props(pl,90);
				@include props(pt,90);
				@include props(pr,66);
				@include props(pb,80);
				@include props(mh,490);
				width: 40.70%;

				&:before{
					content: "";
					background-color: $basicWhiteColor;
					@include props(w,900);
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					z-index: -1;
				}

				.mainTxt{
					font-family: $YuMincho;
					@include fz_vw(32);
					text-align: left;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 1.76px;
					@include props(mb,25);
				}

				.descTxt{
					@include fz_vw(16);
					letter-spacing: normal;
					font-family: $YuGothic;
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2.22;
					letter-spacing: 0.36px;
				}
			}

			.arrow{
				@include fz_vw(16);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 1;
				letter-spacing: 0.96px;
				display: block;
				text-align: end;
				font-family: $YuMincho;
	
				&:after{
					content: "";
					background: url(/images/common/arrow_next.svg) bottom center no-repeat;
					background-size: contain;
					@include props(w,30);
					@include props(h,10);
					display: inline-block;
					@include props(ml,10);
				}

				@include mq(md){
					@include fz_vw(30);
	
					&:after{
						background: url(/images/products/self_pleasure/arrow_next_sp.svg) bottom center no-repeat;
						background-size: contain;
						@include props(w,53,750);
						@include props(h,11,750);
						@include props(ml,16,750);
					}
				}
			}

			.img{
				//position: relative;
				position: absolute;
				top: inherit;
				width: 59.297%;
				@include props(b,-50);
				right: 0;
				box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

				img{
					margin: 0;
					object-fit: cover;
					width: 100%;
					height: 100%;
				}

				@include mq(md){
					// position: absolute;
					position: relative;
					@include props(w,670,750);
					@include props(mt,0,750);
					@include props(mb,0,750);
					@include props(r,-40,750);
					// @include props(b,185,750);
					bottom: inherit;
				}
			}
		}
	}
}
	.childCategoryWrap{
		// position: relative;
		// z-index: 1;
		// @include props(pb,200);

		// @include mq(md){
		// 	@include props(pb,200,750);
		// }

		.childCategoryListItem{
			// align-items: flex-end;
			// @include mq(md){
			// 	align-items: inherit !important;
			// 	@include props(mb,160,750);
			// 	@include props(pb,0,750);
			// }

			// .img{
			// 	position: relative;
			// 	@include props(t,50);
			// 	bottom: inherit;
	
			// 	@include mq(md){
			// 		top: inherit;
			// 	}
			// }

			// .txt{
			// 	// min-height: 490px;
			// 	@include props(pr,66);
			// 	@include props(pb,80);

			// 	@include mq(md){
			// 		min-height: inherit;
			// 		@include props(pt,160,750);
			// 		padding-bottom: 10.66vw !important;
			// 		@include props(pr,0,750);

			// 		&:before{
			// 			height: calc(100% - 27vw)
			// 		}
			// 	}

			// 	.mainTxt{
			// 		@include fz_vw(32);
			// 		text-align: left;

			// 		@include mq(md){
			// 			@include fz_vw(48);
			// 			text-align: center;
			// 		}
			// 	}

			// 	.descTxt{
			// 		@include props(mb,0);
			// 		@include fz_vw(16);
			// 		letter-spacing: normal;

			// 		@include mq(md){
			// 			@include fz_vw(28);
			// 		}
			// 	}
			// }

			// @include mq(md){
			// 	.txt{
			// 		@include props(pb,275,750);
			// 	}

			// 	&:nth-child(even){
			// 		.img{
			// 			@include props(ml,-40,750)
			// 		}
			// 	}
			// }
		}

		.productsMainSlide{
			width: 59.297%;
			margin-left: auto;
			margin-right: auto;
			@include props(mt,350);
			@include props(mb,50);

			@include mq(md){
				@include props(mt,0,750);
				@include props(mb,80,750);
				width: 100%;

				.productsMainSlideInner{
					img{
						@include props(pl,40,750);
						@include props(pr,40,750);
					}
				}
			}

			.slide-arrow{
				position: absolute;
				z-index: 1;
				@include props(t,236);
				transform: translate(0,-50%);
				&:hover{
					cursor: pointer;
				}

				&.prev-arrow{
					left: -7.73%;

					&.slick-disabled{
						display: none !important;
					}
				}

				&.next-arrow{
					right: -7.73%;

					&.slick-disabled{
						display: none !important;
					}
				}

				@include mq(md){
					@include props(t,165,750);
					@include props(w,21.6,750);
					&.prev-arrow{
						left: 0;
					}
	
					&.next-arrow{
						right: 0;
					}
				}
			}

			.txtBox{
				font-family: $YuGothic;
				background: $basicWhiteColor;
				margin-top: 5.952%;
				padding: 4.166% 5.35%;

				p{
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					text-align: left;
				}

				@include mq(md){
					@include props(mt,40,750);
					@include props(p,40,750);

					p{
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 1.12px;
						text-align: left;
					}
				}
			}
		}

		.productsSubSlide{
			padding: 1.46%;
			background: $basicWhiteColor;

			.slick-slide{
				img{
					border: 4px solid #FFF;
				}
				@include mq(md){
					@include props(ml,9,750);
					@include props(mr,9,750);
				}
			}

			.slick-slide.slick-current{
				img{
					border: 4px solid #98CC98;
				}
			}

			@include mq(md){
				@include props(pt,40,750);
				@include props(pb,40,750);
				@include props(pr,40,750);
				@include props(pl,40,750);
				position: relative;
				
				&:before{
					content: "";
					width: 100vw;
					position: absolute;
					top: 0;
					left: 50%;
					transform: translate(-50%,0);
					height: 100%;
					background: $basicWhiteColor;
					z-index: -1;
				}

				.slide-arrow{
					position: absolute;
					top: 50%;
					transform: translate(0,-50%);
					@include props(w,21.4,750);

					&.prev-arrow{
						@include props(l,0,750);

						&.slick-disabled{
							display: none !important;
						}
					}

					&.next-arrow{
						@include props(r,0,750);

						&.slick-disabled{
							display: none !important;
						}
					}
				}
			}
		}
	}

	.productsSingleLinks{
		position: relative;
		z-index: 1;
		@include props(pb,200);

		@include mq(md){
			@include props(pb,150,750);
		}

		.productsSingleLinksWrap{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			@include mq(md){
				justify-content: space-between;
			}

			li{
				width: 22.54%;
				background: $basicWhiteColor;
				text-align: center;
				margin: 0 1.6105%;

				&:nth-child(1){
					margin-left: 0;
				}
				&:nth-child(4){
					margin-right: 0;
				}

				&:nth-child(n + 5){
					margin-top: 2.92%
				}

				@include mq(md){
					@include props(w,310,750);
					@include props(mb,50,750);
					@include props(mt,0,750);
					@include props(ml,0,750);
					@include props(mr,0,750);
				}

				a{
					display: block;
					width: 100%;
					height: 100%;
					padding: 13.02% 9.74% 9.74%;
					position: relative;
					transition: opacity .3s;

					&:hover{
						opacity: .7;
					}
					
					&:after{
						content: "";
						background: url(/images/products/plus/arrow_next.svg) bottom center no-repeat;
						background-size: contain;
						width: 12.11%;
						height: 7px;
						position: absolute;
						bottom: 9.74%;;
						right: 9.74%;
					}

					p{
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.89;
						letter-spacing: normal;
						@include props(mb,25);

						&:before{
							content: "";
							width: 28.34%;
							@include props(h,70);
							display: block;
							margin-left: auto;
							margin-right: auto;
							@include props(mb,25);;
						}

						&:after{
							font-family: $YuMincho;
							@include fz_vw(14);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.6;
							letter-spacing: 0.28px;
							text-align: center;
							display: block;
						}
					}

					@include mq(md){
						@include props(pt,45,750);
						@include props(pl,20,750);
						@include props(pb,20,750);
						@include props(pr,20,750);

						&:after{
							@include props(w,53,750);
							@include props(h,10,750);
						}

						p{
							@include fz_vw(24);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.42;
							letter-spacing: normal;
							@include props(mb,30,750);

							&:before{
								@include props(w,83,750);
								@include props(h,83,750);
							}

							&:after{
								@include fz_vw(20);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 2;
								letter-spacing: 0.4px;
								@include props(mt,10,750);
							}
						}
					}
				}

				&.cart{

					a{
						&:after{
							background: url(/images/common/blank_icon.svg) bottom center no-repeat;
							background-size: contain;
							@include props(w,16);
							@include props(h,14);
							min-width: 12px;
							min-height: 10px;

							@include mq(md){
								@include props(w,23,750);
								@include props(h,21,750);
							}
						}
					}
					p{
						&:before{
							background: url(/images/common/cart_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Official Online Store";
						}
					}
				}

				&.faq{
					p{
						&:before{
							background: url(/images/common/faq_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "FAQ";
						}
					}
				}
				&.review{
					p{
						&:before{
							background: url(/images/common/review_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Review";
						}
					}
				}
				&.download{
					a{
						&:after{
							background: url(/images/common/blank_icon.svg) bottom center no-repeat;
							background-size: contain;
							@include props(w,16);
							@include props(h,14);
							min-width: 12px;
							min-height: 10px;

							@include mq(md){
								@include props(w,23,750);
								@include props(h,21,750);
							}
						}
					}
					p{
						&:before{
							background: url(/images/common/download_icon.svg) center center no-repeat;
							background-size: contain;
						}

						&:after{
							content: "Download Manual";
						}
					}
				}

				&:not(.cart):not(.download){
					a{

						&:hover{
							&:after{
								animation: 1s arrow linear forwards;
							}
						}
					}
				}
			}
		}
	}

	.productsSingleMovie{
		position: relative;
		z-index: 1;
		text-align: center;
		@include props(pb,200);

		.secTit{
			max-width: 1406px;
			padding-left: 20px;
			padding-right: 20px;
			margin: 0 auto;
		}

		.videoBox{
			@include props(w,900);
			height: auto;
			position: relative;
			margin: 0 auto;
			
			&:before{
				display: block;
				content: "";
				width: 100%;
				height: 100%;
				padding-top: 56.25%;
			}
			.video-js{
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				object-fit: cover;
				box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

				.vjs-big-play-button{
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					opacity: 0;
				}

				.vjs-poster{
					background-color: inherit;
				}
			}
		}

		@include mq(md){
			@include props(pb,160,750);

			.secTit{
				@include props(pl,40,750);
				@include props(pr,40,750);
			}

			.videoBox{
				width: 100%;
				max-width: 100%;
			}
		}
	}

	.productsSingleHowto{
		position: relative;
		z-index: 1;
		@include props(pb,200);

		@include mq(md){
			@include props(pb,200,750);
		}

		.towColumns{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			@include props(mb,100);
			
			img{
				width: 47.9%;
			}

			@include mq(md){
				@include props(mb,40,750);
				img{
					width: 100%;
					@include props(mb,40,750);
				}
			}
		}

		.threeColumns{
			background: $basicWhiteColor;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 3.6603% 4.026%;

			@include mq(md){
				@include props(pt,40,750);
				@include props(pr,40,750);
				@include props(pl,40,750);
				@include props(pb,75,750);

				&:after{
					width: 100%;
				}
			}
			
			&:after{
				content: "";
				display: block;
				width: 30.4136%;
			}

			.contentsWrap{
				width: 30.4136%;

				@include mq(md){
					width: 100%;
					@include props(mb,40,750);
					display: flex;
					align-items: center;

					&:last-of-type{
						@include props(mb,0,750);
					}

					img{
						@include props(w,206,750);
						@include props(mb,0,750);
					}
				}

				img{
					margin-bottom: 10.47%;
				}

				p{
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.32px;
					text-align: left;

					@include mq(md){
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 1.12px;
						text-align: left;
						@include props(pl,40,750);
					}
				}
			}
		}
	}

	.productsSingleLineup{
		position: relative;
		z-index: 1;
		@include props(pb,147);

		@include mq(md){
			@include props(pb,200,750);
		}

		.productsLineupListWrap{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: flex-start;

			&.center{
				justify-content: center;

				.productsLineupList{
					@include props(mr,50);

					&:last-of-type{
						margin-right: 0;
					}
				}

				&:after{
					display: none;
				}
			}
			&.twoColumn{
				justify-content: center;

				.productsLineupList{
					@include props(mr,25);
					@include props(ml,25);
				}

				&:after{
					display: none;
				}
			}

			@include mq(md){
				&.center{
					justify-content: space-between;

					.productsLineupList{
						margin-right: 0;
					}
				}
				&.twoColumn{
					justify-content: space-between;

					.productsLineupList{
						margin-right: 0;
						margin-left: 0;
					}
				}
				&:after{
					width: 100%;
				}
			}

			&:after{
				content: "";
				display: block;
				width: 30.89%;
			}
			
			.productsLineupList{
				width: 30.89%;
				background: $basicWhiteColor;
				padding: 2.928% 3.367%;
				@include props(mb,100);
				display: flex;
				flex-direction: column;
				;

				@include mq(md){
					width: 100%;
					@include props(pt,40,750);
					@include props(pl,40,750);
					@include props(pr,40,750);
					@include props(pb,60,750);
					@include props(mb,80,750);

					&:last-child{
						@include props(mb,0,750);
					}
				}

				.imgSlide{
					position: relative;

					img{
						width: 100%;
					}
					.slide-arrow{
						width: 3.636%;
						position: absolute;
						top: 50%;

						&.prev-arrow{
							left: -8%;
						}
						&.next-arrow{
							right: -8%;
						}

						@include mq(md){
							@include props(w,21.4,750);

							&.prev-arrow{
								@include props(l,-20,750);
								transform: translate(-50%,0);
							}

							&.next-arrow{
								@include props(r,-20,750);
								transform: translate(50%,0);
							}
						}
					}
				}

				.txt{
					padding-top: 12.12%;
					position: relative;
					@include props(pb,110);
					flex-grow: 1;

					@include mq(md){
						@include props(pt,80,750);
						@include props(pb,180,750);
					}

					.productsName{
						font-family: $YuMincho;
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.43;
						letter-spacing: 1.12px;
						text-align: center;
						margin-bottom: 6.06%;

						@include mq(md){
							@include fz_vw(42);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.43;
							letter-spacing: 1.68px;
							@include props(mb,30,750);
						}
					}

					.productsSubName{
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.22;
						letter-spacing: 0.36px;
						text-align: center;
						display: block;

						@include mq(md){
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.14;
							letter-spacing: 0.56px;
						}
					}

					.productsDesc{
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: left;
						margin: 12.12% auto;

						@include mq(md){
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 1.12px;
							@include props(mt,80,750);
							@include props(mb,60,750);
						}
					}

					.productsPrice{
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.25;
						letter-spacing: normal;
						text-align: left;
						@include props(mb,15);
						margin-top: 12.12%;

						@include mq(md){
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 1.29;
							letter-spacing: normal;
							@include props(mt,60,750);
							@include props(mb,60,750);
						}
					}

					.toggleBtn{
						border-bottom: 1px solid #E5E5E5;
						font-family: $YuMincho;
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2.22;
						letter-spacing: 1.08px;
						text-align: right;

						@include mq(md){
							@include fz_vw(28);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.14;
							letter-spacing: 1.68px;
						}

						&:hover{
							cursor: pointer;
						}

						&.open{
							&:after{
								content: "";
								display:inline-block;
								width: .7em;
								height: .7em;
								border-bottom: 1px solid #4d4d4d;
								border-right: 1px solid #4d4d4d;
								transform: translate(0,-25%)rotate(45deg);
								margin-left: .8em;
								transition: all .3s ease-out;
							}
						}

						&:after{
							content: "";
							display:inline-block;
							width: .7em;
							height: .7em;
							border-bottom: 1px solid #4d4d4d;
							border-right: 1px solid #4d4d4d;
							transform: translate(0,25%)rotate(225deg);
							margin-left: .8em;
							transition: all .3s ease-out;
						}
					}

					.slideContents{
						display: none;
						border-bottom: 1px solid #e5e5e5;
						padding: 7.27% 0;

						@include mq(md){
							@include props(pt,40,750);
							@include props(pb,50,750);
						}

						dl{
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
						}

						dt{
							font-family: $YuGothic;
							width: 50%;
							padding-right: 3.03%;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.32px;
							text-align: left;
							word-break: break-all;
							margin-bottom: 6.06%;
							&:last-of-type{
								margin-bottom: 0;
							}

							@include mq(md){
								@include fz_vw(28);
								@include props(mb,40,750);
							}
						}

						dd{
							font-family: $YuGothic;
							width: 50%;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.32px;
							text-align: left;
							word-break: break-all;
							margin-bottom: 6.06%;
							&:last-of-type{
								margin-bottom: 0;
							}

							@include mq(md){
								@include fz_vw(28);
								@include props(mb,40,750);
							}
						}
					}

					.onlineLinkBtn{
						display: flex;
						justify-content: center;
						align-items: center;
						background: #4d4d4d;
						font-family: $YuMincho;
						color: $basicWhiteColor;
						@include fz_vw(18);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						letter-spacing: 0.36px;
						border-radius: 3px;
						padding: 7.78% 10px;
						margin-top: 12.12%;
						width: 100%;
						position: absolute;
						bottom: 0;

						&:before{
							content: "";
							display: block;
							background: url(/images/common/online_btn_cart.svg) center center no-repeat;
							background-size: contain;
							width: 1.4em;
							height: 1.1em;
							margin-right: 0.6em;
						}

						&:after{
							content: "";
							display: block;
							background: url(/images/common/online_btn_blank.svg) center center no-repeat;
							background-size: contain;
							width: 0.9em;
							height: 0.8em;
							margin-left: 0.6em;
						}

						@include mq(md){
							@include fz_vw(32);
							@include props(mt,60,750);
						}
					}
				}
			}
		}
	}

	.productsSingleSpec{
		position: relative;
		z-index: 1;
		@include props(pb,200);

		@include mq(md){
			@include props(pb,200,750);

			.openTxt{
				text-align: center;

				#openBtn{
					font-family: $YuMincho;
					@include fz_vw(28);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.56px;

					&:hover{
						cursor: pointer;
					}

					&:after{
						content: "";
						width: .8em;
						height: .8em;
						border-bottom: 1px solid #4D4D4D;
						border-right: 1px solid #4D4D4D;
						display: inline-block;
						transform: rotate(45deg);
						margin-left: 0.5em;
						transition: all .3s;
					}

					&.isOpen{
						&:after{
							content: "";
							width: .8em;
							height: .8em;
							border-bottom: 1px solid #4D4D4D;
							border-right: 1px solid #4D4D4D;
							display: inline-block;
							transform: translate(0,25%)rotate(-135deg);
							margin-left: 0.5em;
						}
					}
				}
			}
		}

		.productsSingleSpecDesc{
			background: $basicWhiteColor;
			padding: 3.66% 14.27%;

			@include mq(md){
				@include props(pt,25,750);
				@include props(pb,25,750);
				@include props(pr,0,750);
				@include props(pl,0,750);
			}

			.mainDateList{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				@include mq(md){
					@include props(mt,30,750);
					@include props(pt,55,750);
					@include props(pb,55,750);
					@include props(pr,40,750);
					@include props(pl,40,750);
					position: relative;
					&:before{
						content: "";
						width: 100%;
						height: 1px;
						background: #E5E5E5;
						position: absolute;
						top: 0;
						left: 0;
					}
				}
				
				dt{
					width: 13.33%;
					text-align: end;
					word-break: break-all;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.32px;
					@include props(mb,20);

					@include mq(md){
						width: 27%;
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						letter-spacing: 0.56px;
						@include props(mb,40,750);
					}

					&:last-of-type{
						@include props(mb,0);
					}
				}

				dd{
					width: 85.2%;
					text-align: left;
					word-break: break-all;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.32px;
					@include props(mb,20);

					@include mq(md){
						width: 70%;
						@include fz_vw(28);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1.6;
						letter-spacing: 0.56px;
						@include props(mb,40,750)
					}

					p{
						&:not(:last-of-type){
							margin-bottom: 1em;
						}
					}

					.subDate{
						display: flex;
						justify-content: left;
						flex-wrap: wrap;

						dt{
							width:100%;
							text-align: left;
							@include props(mb,10);
							@include mq(md){
								width: 100%;
								@include fz_vw(28);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.6;
								letter-spacing: 0.56px;
								@include props(mb,20,750)
							}
						}

						dd{
							width: 100%;
							@include props(mb,20);
							@include mq(md){
								width: 100%;
								@include fz_vw(28);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.6;
								letter-spacing: 1px;
								@include props(mb,40,750)
							}
						}


						&:last-of-type{
							dd{
								@include props(mb,0,750)
							}
						}
					}

					&:last-of-type{
						@include props(mb,0);
					}
				}
			}
		}
	}

	.productsSingleInformation{
		position: relative;
		z-index: 1;
		@include props(pb,200);

		@include mq(md){
			@include props(pb,160,750);
		}

		.relatedInformationList{

			.relatedInformationListItem{
				display: flex;
				justify-content: center;
				align-items: flex-start;
				flex-wrap: wrap;
				@include props(mb,150);

				@include mq(md){
					position: relative;
					display: block;
					@include props(mb,160,750);
					&:last-child{
						@include props(mb,0,750);
					}
				}

				.img{
					width: 54.61%;
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
					img{
						width: 100%;
					}

					@include mq(md){
						width: 100%;
						@include props(l,-40,750);
						@include props(w,710,750);
					}
				}

				.txt{
					width: 45.38%;
					@include props(mt,45);
					@include mq(md){
						width: 100%;
						@include props(mt,0,750);
					}
					
					a{
						display: block;
						padding: 12.9% 12.9% 10.54% 10.17%;
						position: relative;

						@include mq(md){
							@include props(pt,60,750);
							@include props(pr,0,750);
							@include props(pb,80,750);
							@include props(pl,40,750);
						}

						&:before{
							content: "";
							position: absolute;
							top: 0;
							right: 0;
							@include props(w,830);
							height: 100%;
							background: $basicWhiteColor;
							z-index: -1;

							@include mq(md){
								@include props(w,710,750);
								@include props(t,-80,750);
								@include props(r,-40,750);
								@include props(w,710,750);
								height:calc(100% + 10.66vw)
							}
						}

						.mainTxt{
							@include fz_vw(24);
							font-weight: bold;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							@include props(mb,40);

							@include mq(md){
								@include fz_vw(36);
								@include props(mb,40,750);
							}
						}

						.descTxt{
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: normal;
							text-align: left;
							@include props(mb,60);

							@include mq(md){
								@include fz_vw(28);
								@include props(mb,80,750);
							}
						}

						.arrow{
							font-family: $YuMincho;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.25;
							letter-spacing: 0.32px;
							text-align: right;
							display: flex;
							justify-content: end;
							align-items: center;

							&:after{
								content: "";
								background: url(/images/common/arrow_next.svg) bottom center no-repeat;
								background-size: cover;
								display: inline-block;
								width: 2.5em;
								height: 0.65em;
								margin-left: .5em;
							}

							@include mq(md){
								@include fz_vw(28);

								&:after{
									width: 3em;
								}
							}
						}
						.blank{
							font-family: $YuMincho;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.25;
							letter-spacing: 0.32px;
							text-align: right;
							display: flex;
							justify-content: end;
							align-items: center;
							&:after{
								content: "";
								background: url(/images/common/blank_icon.svg) center center no-repeat;
								background-size: contain;
								display: inline-block;
								width: .8em;
								height: .8em;
								margin-left: .5em;
							}
							@include mq(md){
								@include fz_vw(28);
							}
						}
					}
				}

				&:nth-child(even){
					flex-direction: row-reverse;

					.img{
						@include mq(md){
							width: 100%;
							@include props(w,710,750);
							left: 0;
						}
					}

					.txt{
						
						a{
							display: block;
							padding: 12.9% 10.17% 10.54% 12.9%;
							position: relative;
							&:before{
								content: "";
								position: absolute;
								top: 0;
								right: inherit;
								left: 0;
								@include props(w,830);
								height: 100%;
								background: $basicWhiteColor;
								z-index: -1;
							}

							@include mq(md){
								@include props(pt,80,750);
								@include props(pl,0,750);
								@include props(pb,80,750);
								@include props(pr,40,750);

								&:before{
									@include props(w,710,750);
									@include props(t,-80,750);
									@include props(l,-40,750);
									@include props(w,710,750);
									height:calc(100% + 10.66vw)
								}
							}
						}
					}
				}
			}
		}
	}
}