@charset "utf-8";
#anniversaryTop{

	.secTit{
		font-family: $YuMincho;
		@include fz_vw(48);
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.15;
		letter-spacing: 1.92px;
		text-align: center;
		color: #666;
		@include props(mb,70);

		@include mq(md){
			@include fz_vw(54);
			font-weight: 500;
			line-height: 1.78;
			letter-spacing: 0.04em;
			@include props(mb,80,750);
		}
	}

	.gradation{
		background: url(/images/10th_anniversary/gradation_bg.png) center center repeat;
		background-size: cover;
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0;
		left: 0;
		opacity: .8;
	}

	.flower{
		transition: opacity 2s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s cubic-bezier(0.215, 0.61, 0.355, 1);
		transform: scale(0.8)translate3d(0,0,0);
		opacity: 0;
		position: absolute;
		mix-blend-mode: multiply !important;
		svg{
			width: 100%;
			height: 100%;
		}

		&.large{
			@include props(w,155);
			@include props(w,151);

			@include mq(md){
				@include props(w,125,750);
				@include props(w,122,750);
			}
		}

		&.middle{
			@include props(w,82);
			@include props(w,80);

			@include mq(md){
				@include props(w,82,750);
				@include props(w,80,750);
			}
		}

		&.small{
			@include props(w,61);
			@include props(w,60);

			@include mq(md){
				@include props(w,61,750);
				@include props(w,60,750);
			}
		}

		&:not(.fvFlower).flowerIn{
			transform: scale(1)translate3d(0,0,0);
			opacity: 1;
		}
	}

	.itemAnime{
		position: absolute;
		opacity: 0;
	}

	.anniversaryMain{
		.mainviewTit{
			position: relative;
			height: 100vh;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			justify-content: center;
			flex-direction: column;
			max-width: 1760px;
			padding: 0 20px;
			margin: auto;
		  
			@include mq(md) {
				@include props(pr, 40, 750);
				@include props(pl, 40, 750);
			}

			.mianLogo{
				width: 100%;
				text-align: center;
				@include props(mt,35);

				@include mq(md) {
					@include props(mt,80,750);
					height: 26.98%;
				}

				img{
					margin: 0 auto;
					@include props(w,596);

					@include mq(md) {
						@include props(w,667,750);
						height: 100%;
					}
				}
			}

			.flower1{
				left: 11.918%;
				top: 41%;

				@include mq(md){
					left: 55.46%;
					top: 25.07%;
				}
			}
			.flower2{
				left: 6.104%;
				top: 27.87%;

				@include mq(md){
					left: 51.06%;
					top: 17.11%;
				}
			}
			.flower3{
				left: 23.837%;
				top: 37.59%;

				@include mq(md){
					left: inherit;
					right: 10.66%;
					top: 80.65%;
				}
			}
			.flower4{
				left: 8.3139%;
				top: 59.62%;
				@include mq(md){
					left: 52.53%;
					top: 73.73%;
				}
			}
			.flower5{
				left: 28.546%;
				top: 72.22%;

				@include mq(md){
					left: 83.86%;
					top: 53.85%;
				}
			}
			.flower6{
				right: 13.13%;
				top: 20.64%;

				@include mq(md){
					right: 67.06%;
					top: 83.11%;
				}
			}
			.flower7{
				right: 10.29%;
				top: 42.68%;

				@include mq(md){
					right: inherit;
					left: 19.6%;
					top: 18.52%;
				}
			}
			.flower8{
				right: 5.98%;
				top: 62.59%;
			}
			.flower9{
				right: 18.66%;
				top: 75.46%;
			}

			.item1{
				left: 25.58%;
				top: 20.64%;
				@include props(w,189);
				@include props(h,189);

				@include mq(md){
					@include props(w,153,750);
					@include props(h,153,750);
					left: 29.2%;
					top: 14.3%;
				}
			}
			.item2{
				left: 15.87%;
				top: 27.77%;
				@include props(w,76);
				@include props(h,76);

				@include mq(md){
					@include props(w,76,750);
					@include props(h,76,750);
					left: 28.4%;
					top: 30.73%;
				}
			}
			.item3{
				left: 3.023%;
				top: 40.46%;
				@include props(w,108);
				@include props(h,185);
				@include mq(md){
					@include props(w,108,750);
					@include props(h,185,750);
					left: 8%;
					top: 26.93%;
				}
			}
			.item4{
				left: 20.93%;
				top: 52.87%;
				@include props(w,104);
				@include props(h,200);

				@include mq(md){
					@include props(w,104,750);
					@include props(h,200,750);
					left: 32.93%;
					top: 73.73%;
				}
			}
			.item5{
				left: 13.895%;
				top: 67.87%;
				@include props(w,87);
				@include props(h,117);
				@include mq(md){
					@include props(w,87,750);
					@include props(h,117,750);
					left: 10.66%;
					top: 71.42%;
				}
			}
			.item6{
				left: 20.34%;
				top: 79.53%;
				@include props(w,90);
				@include props(h,101);
			}
			.item7{
				right: 26.51%;
				top: 26.29%;
				@include props(w,63);
				@include props(h,122);

				@include mq(md){
					@include props(w,63,750);
					@include props(h,122,750);
					right: 18.99%;
					top: 15.99%;
				}
			}
			.item8{
				right: 2.79%;
				top: 28.33%;
				@include props(w,135);
				@include props(h,135);
			}
			.item9{
				right: 20.23%;
				top: 44.16%;
				@include props(w,63);
				@include props(h,122);

				@include mq(md){
					@include props(w,89,750);
					@include props(h,121,750);
					right: 10.66%;
					top: 30.20%;

				}
			}
			.item10{
				right: 2.79%;
				top: 52.5%;
				@include props(w,72);
				@include props(h,72);
			}
			.item11{
				right: 14.94%;
				top: 58.51%;
				@include props(w,68);
				@include props(h,78);
				@include mq(md){
					@include props(w,68,750);
					@include props(h,78,750);
					right: 10.26%;
					top: 71.13%;;
				}
			}
			.item12{
				right: 25.69%;
				top: 66.20%;
				@include props(w,91);
				@include props(h,114);

				@include mq(md){
					@include props(w,91,750);
					@include props(h,114,750);
					right: 24.13%;
					top: 79.98%;
				}
			}
			.item13{
				right: 5.98%;
				top: 67.87%;
				@include props(w,245);
				@include props(h,245);
			}
		}

		.anniversaryDesc{
			position: relative;
			.flower10{
				@include props(t,96);
				@include props(l,143);
			}
			.flower11{
				@include props(t,293);
				@include props(l,280);
			}
			.flower12{
				@include props(b,111);
				@include props(r,114);
			}
			.flower13{
				@include props(b,-80);
				@include props(r,176);
			}
			.read{
				font-family: $YuGothic;
				@include fz_vw(20);
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				line-height: 3;
				letter-spacing: 0.6px;
				text-align: center;
				@include props(mb,200);

				@include mq(md){
					@include fz_vw(32);
					line-height: 2.56;
					letter-spacing: 0.96px;
					@include props(mb,200,750);
				}
			}

			.pageLink{
				display: flex;
				justify-content: center;
				align-items: center;

				@include mq(md){
					justify-content: space-between
				}

				li{
					width: 15.739%;
					background: $basicWhiteColor;
					box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);

					@include mq(md){
						width: calc(100% / 3 - 1.06vw);
					}

					&:nth-child(2){
						@include props(ml,40);
						@include props(mr,40);

						@include mq(md){
							@include props(ml,0,750);
							@include props(mr,0,750);
						}
					}

					a{
						display: block;
						width: 100%;
						height: 100%;
						@include props(pt,20);
						@include props(pb,20);
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: 0.32px;
						text-align: center;
						color: #4d4d4d;

						@include mq(md){
							@include fz_vw(26);
							@include props(pt,30,750);
							@include props(pb,30,750);
							line-height: 1.23;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}
		}
	}

	.everyIroha{
		@include props(pt,200);
		@include mq(md){
			@include props(pt,200,750);
		}

		.messageDesc{
			font-family: $YuGothic;
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			text-align: center;
			@include props(mb,150);

			@include mq(md){
				@include fz_vw(32);
				line-height: 1.88;
				letter-spacing: 0;
			}
		}

		.slideContainer{
			.mainSlideContainer{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				max-width: 1406px;
				padding: 0 20px;
				margin: auto;
				@include props(mb,150);

				@include mq(minCon){
					padding: 0 40px;
				}
			  
				@include mq(md) {
					@include props(pr, 0, 750);
					@include props(pl, 0, 750);
					@include props(mb,80,750);
					@include props(pb,80,750);
					position: relative;

					&:before{
						content: "";
						width: 100%;
						height: 75.76%;
						background: $basicWhiteColor;
						position: absolute;
						bottom: 0;
						left: 0;
						z-index: 0;
					}
				}

				.gallery{
					width: 42.16%;
					position: relative;

					@include mq(md){
						width: 100%;
						@include props(mb,80,750);
						@include props(pr, 40, 750);
						@include props(pl, 40, 750);
					}

					.slick-arrow{
						position: absolute;
						z-index: 1;
						@include props(t,275);
						@include props(w,22);
						@include props(h,41);

						@include mq(md){
							@include props(t,165,750);
							@include props(w,22,750);
							@include props(h,41,750);
						}

						&.prev-arrow{
							@include props(l,-60);

							@include mq(minCon){
								left: -10px;
								transform: translate(-100%,0);
							}

							@include mq(md){
								@include props(l,110,750);
								transform: none;
							}
						}
						&.next-arrow{
							@include props(r,-36);
							@include mq(minCon){
								right: -10px;
								transform: translate(100%,0)
							}

							@include mq(md){
								@include props(r,110,750);
								transform: none;
							}
						}
					}

					.galleryList{
						@include props(pr,27);
						overflow: hidden;
						@include mq(md){
							@include props(pr, 40, 750);
							@include props(pl, 40, 750);
							@include props(pb, 0, 750);
							@include props(pt, 0, 750);
						}
						.img{
							box-shadow: 10px 10px 20px 0px rgba(0,0,0,0.1);
							@include mq(md){
								@include props(w,376,750);
								margin: 0 auto;
							}
							

							@include mq(desk){
								box-shadow: 0.55vw 0.55vw 1.25vw 0px rgba(0,0,0,0.1);
							}

							@include mq(tab){
								box-shadow: 0.58vw 0.58vw 1.36vw 0px rgba(0,0,0,0.1);
							}
						}

						.mainTit{
							@include props(mt,20);
							@include mq(md){
								display: flex;
								align-items: flex-start;
								@include props(mt,40,750);
								@include props(mb,74,750);
							}
							.name{
								font-family: $YuGothic;
								@include fz_vw(18);
								font-weight: bold;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.78;
								letter-spacing: 0.54px;
								text-align: left;
	
								@include mq(md){
									@include fz_vw(32);
									line-height: 1;
									@include props(mr,40,750);
								}
							}
	
							.career{
								@include props(mt,10);
								font-family: $YuGothic;
								@include fz_vw(18);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.78;
								letter-spacing: 0.54px;
								text-align: left;
	
								@include mq(md){
									@include fz_vw(28);
									line-height: 1.36;
									@include props(mt,0,750);
									flex: 1;
								}
							}
						}

						.message{
							@include props(mt,20);
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2;
							letter-spacing: 0.16px;
							text-align: left;

							@include mq(md){
								@include fz_vw(28);
								line-height: 1.71;
								letter-spacing: 1.12px;
							}
						}
					}
				}

				.thumbnail{
					width: 50%;
					@include mq(md){
						width: 100%;
					}
				
					@include mq(pc){
						.slick-list{
							width: 100% !important;
						}
					
						.slick-track{
							transform: unset !important;
							width: 100% !important;
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;
					
							&:before{
								content: none !important;
							}
	
							&:after{
								width: 26.3543%;
								height: 0;
							}
						}

						.slick-slide{
							width: 26.3543% !important;
							@include props(mb,30);

							.thumbnailList{
								cursor: pointer;
								outline: none;
								width:100%!important;
							}
						}
					}

					.slick-slide{

						.thumbnailList{
							@include mq(md){
								@include props(pl,20,750);
								@include props(pr,20,750);
							}
					
							img{
								border: 4px solid rgba(189,159,219,0);

								@include mq(md){
									border: 0.534vw solid rgba(189,159,219,0);
								}
							}

							.name{
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: bold;
								font-stretch: normal;
								font-style: normal;
								line-height: 2;
								letter-spacing: 0.48px;
								text-align: left;

								@include mq(md){
									@include fz_vw(24);
									line-height: 1.33;
									letter-spacing: 0.72px;
								}
							}

							.career{
								font-family: $YuGothic;
								@include fz_vw(14);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.64;
								letter-spacing: 0.42px;
								text-align: left;

								@include mq(md){
									@include fz_vw(20);
									line-height: 1.15;
									letter-spacing: 0.6px;
									@include props(mt,10,750);
								}
							}
						}


						&.slick-current{
							.thumbnailList{
								img{
									border: 4px solid rgba(189,159,219,1);
								}
							}
						}
					}

				}
			}

			.subSlideContainer{
				max-width: 1406px;
				padding: 0 10px;
				margin: auto;

				@include mq(minCon){
					padding: 0 30px;
				}
			  
				@include mq(md) {
				  @include props(pr, 40, 750);
				  @include props(pl, 40, 750);
				}
				.subSlide{
					position: relative;
					position: relative;
					@include mq(md){
						@include props(pl,16,750);
						@include props(pr,16,750);
					}

					.slick-arrow{
						position: absolute;
						top: 50%;
						@include props(w,22);
						@include props(h,41);

						@include mq(md){
							@include props(w,22,750);
							@include props(h,41,750);
						}

						&.prev-arrow{
							@include props(l,-60);
							transform: translate(0,-50%);

							@include mq(minCon){
								left: -10px;
								transform: translate(-100%,-50%);
							}
							@include mq(md){
								left: 0px;
								transform: translate(0%,-50%);
							}
						}
						&.next-arrow{
							@include props(r,-60);
							transform: translate(0,-50%);
							@include mq(minCon){
								right: -10px;
								transform: translate(100%,-50%)
							}
							@include mq(md){
								right: 0px;
								transform: translate(0%,-50%);
							}
						}
					}

					.slick-track {
						display: flex;
					}
					
					.slick-slide {
						height: auto !important;

						& > div{
							height: 100%;
						}
					}

					.subSlideList{
						padding: 0 10px;
						height: 100%;

						@include mq(md){
							@include props(pl,24,750);
							@include props(pr,24,750);
						}

						.innerList{
							background: $basicWhiteColor;
							@include props(pr,10);
							@include props(pl,20);
							@include props(pt,20);
							@include props(pb,20);
							height: 100%;

							@include mq(md){
								background:inherit;
								@include props(p,0,750);
							}

							.nameBox{
								display: flex;
								align-items: center;
								@include props(mb,20);
								@include mq(md){
									@include props(mb,30,750);
								}

								img{
									width: 41.28%;
								}

								.name{
									@include props(ml,10);
									font-family: $YuGothic;
									@include fz_vw(18);
									font-weight: bold;
									font-stretch: normal;
									font-style: normal;
									line-height: 1.78;
									letter-spacing: normal;

									@include mq(md){
										@include props(ml,10,750);
										@include fz_vw(24);
										line-height: 1.33;
									}
								}
							}

							.message{
								font-family: $YuGothic;
								@include fz_vw(16);
								font-weight: 500;
								font-stretch: normal;
								font-style: normal;
								line-height: 1.63;
								letter-spacing: 0.16px;

								@include mq(md){
									@include fz_vw(28);
									line-height: 1.71;
								}
							}
						}
					}
				}
			}
		}
	}

	.AnniversaryProject{
		@include props(pt,300);

		@include mq(md){
			@include props(pt,200,750);
		}

		.secTit{
			@include props(mb,200);

			@include mq(md){
				@include props(mb,200,750);
				@include fz_vw(70);
			}
		}

		ul{
			li{
				position: relative;
				z-index: 1;
				@include props(pt,60);

				@include mq(md){
					@include props(pt,420,750);
					@include props(pb,80,750);
				}

				&:not(:last-child){
					@include props(mb,250);

					@include mq(md){
						@include props(mb,160,750);
					}
				}
				

				.mainTxt{
					font-family: $YuMincho;
					@include fz_vw(34);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.6;
					text-align: left;
					@include props(mb,60);
					@include props(pl,60);
					width: 58.565%;

					@include mq(md){
						@include fz_vw(48);
						width: 100%;
						@include props(mb,60,750);
						@include props(mt,60,750);
					}
				}

				.flex{
					display: flex;
					align-items: flex-start;
					flex-wrap: wrap;

					.innerTxt{
						padding-right: 6.5885%;
						padding-left: 4.39%;
						width: 31.918%;
						text-align: right;

						@include mq(md){
							width: 100%;
						}

						.descTxt{
							font-family: $YuGothic;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							line-height: 2.3;
							letter-spacing: normal;
							text-align: left;
							@include props(mb,60);

							@include mq(md){
								@include fz_vw(28);
								@include props(mb,80,750);
								line-height: 1.7;
								letter-spacing: 1.12px;
							}
						}

						.arrow{
							font-family: $YuMincho;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: 0.96px;

							@include mq(md){
								@include fz_vw(28);
							}
				
							&::after{
								content: "";
								display: inline-block;
								background-image: url(/images/common/arrow_next.svg);
								background-position: center;
								background-repeat: no-repeat;
								background-size: contain;
								backface-visibility: hidden;
								margin-left: .5em;
								width: 1.875em;
								height: .6em;
							}
						}

						.external{
							font-family: $YuMincho;
							@include fz_vw(16);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: 0.96px;
							line-height: 1.5;

							@include mq(md){
								@include fz_vw(28);
							}
						}
					}

					.img{
						width: 68.08%;
						box-shadow: 10px 10px 30px 0px rgba(0,0,0,0.1);
						position: relative;
						padding-top: 40.263%;
						

						@include mq(md){
							position: absolute;
							top: 0;
							@include props(r,-40,750);
							@include props(w,710,750);
							padding-top: 62.686%;
						}

						img{
							width: 100%;
							height: 100%;
							object-fit: cover;
							position: absolute;
							top: 0;
						}
					}
				}

				&:nth-child(2){
					.img{
						padding-top: 38.799%;
						@include mq(md){
							padding-top: 60.447%;
						}
					}
				}
				&:nth-child(3){
					.img{
						padding-top: 35.724%;
						@include mq(md){
							padding-top: 55.671%;
						}
					}
				}

				&:nth-child(odd){

					.mainTxt{
						&:before{
							content: "";
							width: 58.565%;
							max-height: 600px;
							height: 90%;
							background: $basicWhiteColor;
							position: absolute;
							top: 0;
							left: 0;
							z-index: -1;
						}
	
						@include mq(md){
							&:before{
								content: "";
								@include props(w,710,750);
								height: 71.38%;
								max-height: inherit;
								top: inherit;
								bottom: 0;
								@include props(l,-40,750);
							}
						}
					}


					.mainTxt{
						@include mq(md){
							@include props(pl,0,750);
						}
					}

					.flex{
						.innerTxt{
							@include mq(md){
								@include props(pr,40,750);
								padding-left: 0;
							}
						}
					}
				}
				&:nth-child(even){

					.mainTxt{
						&:before{
							content: "";
							width: 58.565%;
							max-height: 600px;
							height: 90%;
							background: $basicWhiteColor;
							position: absolute;
							top: 0;
							right: 0;
							z-index: -1;
						}
	
						@include mq(md){
							&:before{
								content: "";
								@include props(w,710,750);
								height: 71.38%;
								max-height: inherit;
								top: inherit;
								bottom: 0;
								@include props(r,-40,750);
							}
						}
					}

					.flex{
						flex-direction: row-reverse;

						.innerTxt{
							@include mq(md){
								@include props(pl,40,750);
								@include props(pr,0,750);
							}
						}
					}

					.mainTxt{
						margin-left: auto;
						margin-right: 0;
						@include mq(md){
							@include props(pl,40,750);
						}
					}

					.img{
						@include mq(md){
							@include props(l,-40,750);
							right: inherit;
						}
					}
				}

				.flower14{
					@include props(r,130);
					@include props(t,-170);
				}
				.flower15{
					@include props(r,228);
					@include props(t,20);
				}
				.flower16{
					@include props(l,90);
					@include props(t,-290);
				}
				.flower17{
					@include props(l,175);
					@include props(t,-170);
				}
				.flower18{
					@include props(r,175);
					@include props(b,-120);
				}
			}
		}
	}

	.brandHistory{
		@include props(pt,300);
		@include mq(md){
			@include props(pt,200,750);
		}

		.secTit{

			@include mq(md){
				@include props(mb,80,750);
				@include fz_vw(70);
			}
		}

		.messageDesc{
			font-family: $YuGothic;
			@include fz_vw(18);
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 2;
			letter-spacing: normal;
			text-align: center;
			@include props(mb,150);

			@include mq(md){
				@include fz_vw(28);
				line-height: 1.88;
				@include props(mb,80,750);
			}
		}

		.historyGraph{
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			@include props(mb,80);

			@include mq(md){
				@include props(mb,180,750);
				position: relative;
			}

			.indexWrapper{
				transition: all 1s;
				width: 23.27%;
				z-index: 2;
				position: sticky;
				top: 0;
				mix-blend-mode: multiply;
				transform: translate3d(0px,0px,0px);

				&:before{
					content: "";
					background: url(/images/10th_anniversary/side_gradation.png) center left no-repeat;
					background-size: cover;
					@include props(w,55);
					height: 100%;
					position: absolute;
					z-index: -1;
					left: 0;
				}


				@include mq(md){
					width: 100%;
					@include props(t,80,750);
					position: sticky;
					mix-blend-mode: inherit;
					transform: translate(0 ,0) !important;

					&:before{
						background: url(/images/10th_anniversary/side_gradation_sp.png) center left no-repeat;
						background-size: cover;
						@include props(w,750,750);
						@include props(h,225,750);
						position: absolute;
						z-index: 1;
						@include props(l,-40,750);
					}
				}

				.index{
					display: flex;
					flex-direction: column;
					@include props(pl,30);
					@include props(pb,60);
					@include props(pt,60);
					position: relative;
					z-index: 1;

					@include mq(md){
						@include props(pl,0,750);
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: flex-start;
						@include props(pt,30,750);
						@include props(pb,30,750);

					}
					
					li{
						@include props(pt,20);
						@include props(pb,20);
						a{
							font-family: $YuMincho;
							@include fz_vw(20);
							font-weight: 500;
							font-stretch: normal;
							font-style: normal;
							letter-spacing: 1.1px;
							color: #ADADAD;
							transition: all .3s;

							&.current{
								@include fz_vw(40);
								color: #808080;
							}
						}

						@include mq(md){
							@include props(pt,0,750);
							@include props(pb,0,750);
							@include props(pr,10,750);
							@include props(pl,10,750);
							width: calc(100% / 5);

							a{
								@include fz_vw(32);
								line-height: 1.72;
								display: block;
								text-align: center;

								&.current{
									@include fz_vw(32);
								}
							}
						}
					}
				}
			}

			.historyList{
				width: 76.73%;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				@include props(mb,80);

				@include mq(md){
					width: 100%;
					display: block;
					@include props(mt,80,750);
					@include props(mb,0,750);
				}
				
				dt{
					width: 26.71%;
					@include props(pb,120);

					@include mq(md){
						width: 100%;
						@include props(pb,30,750);
						@include props(pt,10,750);
					}

					.ad{
						font-family: $YuMincho;
						@include fz_vw(90);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 1;
						text-align: right;
						@include props(mb,20);
						color: #666;

						@include mq(md){
							@include fz_vw(90);
							text-align: left;
							@include props(mb,30,750);
						}
					}

					.adDesc{
						font-family: $YuGothic;
						@include fz_vw(16);
						font-weight: 500;
						font-stretch: normal;
						font-style: normal;
						line-height: 2;
						letter-spacing: normal;
						text-align: left;

						@include mq(md){
							@include fz_vw(28);
						}
					}
				}

				dd{
					width: 69.274%;
					@include props(pb,120);
					@include props(pt,25);
					border-left: 1px solid #ADADAD;

					@include mq(md){
						width: 100%;
						@include props(pb,80,750);
						@include props(pt,60,750);
					}

					ol{
						li{
							padding-left: 16.38%;
							position: relative;

							&.dot{
								padding-left: 5.69%;

								@include mq(md){
									@include props(pl,40,750);
								}
							}

							@include mq(md){
								@include props(pl,100,750);
								&:not(:last-child){
									@include props(mb,100,750);
								}
							}

							&:not(:last-child){
								@include props(mb,80);
							}

							.notModal{
								display: flex;
								align-items: center;
								color: #4d4d4d;

								.desc{
									@include mq(md){
										flex: 1;
									}
								}
							}

							.imgWrap{
								margin-right: 2.55%;

								.img{
									position: relative;
								}

								img{
									width: 100%;
									position: absolute;
									top: 0;
									height: 100%;
									background-size: contain;
								}

								@include mq(md){
									@include props(mr,20,750);
								}
							}

							a{
								display: flex;
								align-items: center;
								color: #4d4d4d;
								@include mq(md){
									flex-wrap: wrap;
								}

								.modelArrow{
									@include props(w,45);
									@include props(h,45);
									border-radius: 50%;
									background: $basicWhiteColor;
									@include props(ml,25);
									position: relative;
									&:before{
										content: "";
										background: url(/images/10th_anniversary/arrow.svg) bottom right no-repeat;
										width: 90%;
										@include props(h,11);
										position: absolute;
										bottom: 45%;
										left: -30%;
									}

									@include mq(md){
										@include props(w,45,750);
										@include props(h,45,750);
										@include props(ml,32,750);

										&:before{
											@include props(w,51,750);
											@include props(h,10,750);
											left: -50%;
										}
									}
								}

								.img{
									position: relative;
									overflow: hidden;
									backface-visibility: hidden;
									z-index: 1;

									img{
										transition: transform 2s ease-out;
										-webkit-backface-visibility: hidden;
										backface-visibility: hidden;
									}
								}

								&:hover{
									.img{
										img{
											transform: scale(1.02) rotate(0.001deg);
											backface-visibility: hidden;
										}

									}
									.modelArrow{
										&:before{
											animation: 1s arrow linear forwards;
										}
									}
									
								}
							}

							// 花アイコンスタイル
							.irohaHana{
								width: 4.95%;

								@include mq(md){
									@include props(w,50,750);
								}

								.img{
									position: relative;
									padding-top: 100%;

									img{
										position: absolute;
										top: 0;
										width: 100%;
										height: 100%;
										background-size: contain;
									}
								}
							}

							// 吹き出し
							.balloon{
								position: relative;
								&:after{
									content: "";
									position: absolute;
									@include props(w,125);
									@include props(h,85);
									top: 0;
									right: 0;
									transform: translate(100%,-100%);

									@include mq(md){
										@include props(w,221,750);
										@include props(h,136,750);
									}
								}
							}

							.balloon1{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon01@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon2{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon02@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon3{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon03@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon4{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon04@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon5{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon05@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon6{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon06@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon7{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon07@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon8{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon08@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon9{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon09@2x.png) center center no-repeat;
									background-size: contain;
								}
							}
							.balloon10{
								&:after{
									background: url(/images/10th_anniversary/balloon/balloon10@2x.png) center center no-repeat;
									background-size: contain;
								}
							}

							&.largeCate{
								.desc{
									font-family: $YuGothic;
									@include fz_vw(18);
									font-weight: 500;
									font-stretch: normal;
									font-style: normal;
									line-height: 1.78;
									letter-spacing: normal;
									text-align: left;

									@include mq(md){
										@include fz_vw(28);
									}
								}
							}

							&.middleCate{
								.desc{
									font-family: $YuGothic;
									@include fz_vw(14);
									font-weight: 500;
									font-stretch: normal;
									font-style: normal;
									line-height: 2;
									letter-spacing: normal;
									text-align: left;

									@include mq(md){
										@include fz_vw(28);
									}
								}
							}

							&:not(.dot):before{
								content: "";
								font-family: $YuMincho;
								line-height: 1.66;
								height: 1.66em;
								@include fz_vw(30);
								position: absolute;
								@include props(l,56);
								top: 0;
								bottom: 0;
								margin: auto 0;

								@include mq(md){
									@include fz_vw(44);
									@include props(l,20,750);
								}
							}

							&.jan:after,
							&.feb:after,
							&.mar::after,
							&.apr:after,
							&.may:after,
							&.jun:after,
							&.jul:after,
							&.aug:after,
							&.sep:after,
							&.oct::after,
							&.nov::after,
							&.dec::after{
								content: "";
								position: absolute;
								top: 0;
								bottom: 0;
								left: 0;
								@include props(w,6);
								@include props(h,6);
								background: #666;;
								border-radius: 50%;
								margin: auto 0;
								transform: translate(-50%,0);

								@include mq(md){
									@include props(w,10,750);
									@include props(h,10,750);
								}
							}

							&.dot{
								&:before{
									content: "";
									position: absolute;
									top: 0;
									bottom: 0;
									left: 0;
									@include props(w,12);
									@include props(h,12);
									background: #666;;
									border-radius: 50%;
									margin: auto 0;
									transform: translate(-50%,0);

									@include mq(md){
										@include props(w,18,750);
										@include props(h,18,750);
									}
								}
							}

							&.jan{
								&:before{
									content: "01";
								}
							}

							&.feb{
								&:before{
									content: "02";
								}
							}

							&.mar{
								&:before{
									content: "03";
								}
							}
							&.apr{
								&:before{
									content: "04";
								}
							}

							&.may{
								&:before{
									content: "05";
								}
							}

							&.jun{
								&:before{
									content: "06";
								}
							}
							&.jul{
								&:before{
									content: "07";
								}
							}

							&.aug{
								&:before{
									content: "08";
								}
							}

							&.sep{
								&:before{
									content: "09";
								}
							}
							&.oct{
								&:before{
									content: "10";
								}
							}

							&.nov{
								&:before{
									content: "11";
								}
							}

							&.dec{
								&:before{
									content: "12";
								}
							}
						}
					}

					&.sec2013{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 30.36%;

									@include mq(tab){
										width: 29%;
									}

									@include mq(md){
										@include props(w,266,750);

										&:after{
											right: -25%;
											top: -15%;
											transform: translate(0,-100%)
										}
									}

									.img{
										padding-top: 44.565%;
									}
								}

								.desc{
									@include mq(md){
										@include props(w,280,750);
									}
								}

								.modelArrow{
									@include mq(md){
										position: absolute;
										bottom: 0;
										right: 0;
										transform: translate(0,100%);
									}
								}

								@include props(mt,-20);

								@include mq(md){
									@include props(mt,100,750)
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 23.59%;
									@include mq(md){
										@include props(w,164,750);
									}

									.img{
										padding-top: 36.36%;
									}
								}
							}
						}
					}

					&.sec2014{
						li{
							&:nth-child(2){
								.imgWrap{
									width: 16.5%;
									&:after{
										top: 30%;
									}

									@include mq(md){
										@include props(w,128,750);
										&:after{
											top: 15%;
										}
									}

									.img{
										padding-top: 133%;
									}
								}

								@include mq(md){
									@include props(mt,200,750);
								}
							}
							&:nth-child(3){
								.imgWrap{
									width: 10.23%;

									@include mq(md){
										@include props(w,94,750);
									}

									.img{
										padding-top: 100%;
									}
								}
							}
						}
					}
					&.sec2015{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 13.36%;

									@include mq(md){
										@include props(w,107,750);
									}

									.img{
										padding-top: 65.43%;
									}
								}
							}
							&:nth-child(2){
								.desc{
									@include mq(tab){
										letter-spacing: -0.08em;
									}
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(3){
								.imgWrap{
									width: 37.62%;

									.img{
										padding-top: 27.19%;
									}

									@include mq(tab){
										width: 33.62%;
									}

									@include mq(md){
										@include props(w,226,750);

										.img{
											padding-top: 68.99%;
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,320,750);
									}
								}
								.modelArrow{
									@include mq(md){
										position: absolute;
										bottom: 0;
										right: 0;
										transform: translate(0,100%);
									}
								}
							}
						}
					}
					&.sec2016{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 5.44%;

									&:after{
										top: 10%;
									}

									@include mq(md){
										@include props(w,56,750);
									}

									.img{
										padding-top: 296.96%;
									}
								}

								@include props(mt,-20);

								@include mq(md){
									@include props(mt,80,750);
								}
							}
						}
					}
					&.sec2017{
						li{
							&:nth-child(1){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 16.83%;

									&:after{
										top: 30%;
									}

									@include mq(md){
										@include props(w,132,750);
									}

									.img{
										padding-top: 129.41%;
									}
								}
								.modelArrow{
									@include mq(md){
										position: absolute;
										@include props(b,-20,750);
										right: 0;
									}
								}

								@include mq(md){
									@include props(mt,120,750);
								}
							}
							&:nth-child(3){
								.desc{
									@include mq(md){
										@include props(w,408,750);
										letter-spacing: -0.03em;
									}
								}
							}
							&:nth-child(4){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(5){
								.imgWrap{
									width: 14.85%;

									&:after{
										top: 30%;
									}

									.img{
										padding-top: 80%;
									}

									@include mq(md){
										@include props(w,91,750);
									}
								}
							}
						}
					}
					&.sec2018{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 9.57%;

									.img{
										padding-top: 91.37%;
									}

									@include mq(md){
										@include props(w,76,750);
									}
								}
								.desc{
									@include mq(md){
										@include props(w,330,750);
									}
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 33.0%;

									&:after{
										top: 20%;
										right: -20%;

										@include mq(md){
											top: -5%;
											right: 0;
										}
									}

									@include mq(md){
										@include props(w,270,750);
									}

									.img{
										padding-top: 70%;
									}
								}

								.desc{
									@include mq(md){
										@include props(w,275,750);
									}
								}

								.modelArrow{
									@include mq(md){
										position: absolute;
										@include props(b,-40,750);
										right: 0;
									}
								}

								@include mq(md){
									@include props(mt,200,750);
								}
							}
							&:nth-child(3){
								.imgWrap{
									width: 8.91%;

									&:after{
										top: 30%;

										@include mq(md){
											top: 0
										}
									}

									@include mq(md){
										@include props(w,54,750);
									}

									.img{
										padding-top: 133.33%;
									}
								}
							}
							&:nth-child(4){
								.imgWrap{
									width: 11.71%;

									@include mq(md){
										@include props(w,71,750);
									}

									.img{
										padding-top: 76.05%;
									}
								}
							}
						}
					}
					&.sec2019{
						@include props(pb,160);
						@include mq(md){
							@include props(pb,80,750);
						}
						li{
							&:nth-child(1){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 33.33%;

									&:after{
										top: 30%;

										@include mq(md){
											top: 0;
										}
									}

									.img{
										padding-top: 63.86%;
									}

									@include mq(md){
										@include props(w,212,750);

										.img{
											padding-top: 79.26%;
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,333,750);
										letter-spacing: -0.08em;
										
									}
								}

								.modelArrow{
									@include mq(md){
										position: absolute;
										@include props(b,-20,750);
										right: 0;
									}
								}

								@include mq(md){
									@include props(mt,200,750);
								}
							}
							&:nth-child(3){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(4){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(5){
								.imgWrap{
									width: 23.59%;

									@include mq(md){
										@include props(w,158,750);
									}


									.img{
										padding-top: 36.36%;
									}
								}
							}
							&:nth-child(6){
								.imgWrap{
									width: 6.93%;

									@include mq(md){
										@include props(w,51,750);
									}


									.img{
										padding-top: 142.85%;
									}
								}

								.desc{
									@include mq(md){
										@include props(w,408,750);
										letter-spacing: -0.02em;
									}
								}
							}
							&:nth-child(7){
								.imgWrap{
									width: 5.28%;

									@include mq(md){
										@include props(w,39,750);
									}


									.img{
										padding-top: 187.5%;
									}
								}
							}
							&:nth-child(8){
								.desc{
									@include mq(tab){
										letter-spacing: -0.05em;
									}
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(9){
								.imgWrap{
									width: 15.67%;
									&:after{
										top: 20%;
									}

									@include mq(md){
										@include props(w,120,750);
									}


									.img{
										padding-top: 133.68%;
									}
								}

								.desc{
									@include mq(md){
										@include props(w,337,750);
									}
								}

								@include mq(md){
									@include props(mt,200,750);
								}
							}
							&:nth-child(10){
								.imgWrap{
									width: 33%;

									@include mq(md){
										@include props(w,267,750);
									}


									.img{
										padding-top: 70%;
									}
								}

								.desc{
									@include mq(md){
										@include props(w,280,750);
										letter-spacing: -.05em;
									}
								}

								.modelArrow{
									@include mq(md){
										margin-left: auto;
									}
								}

								@include mq(md){
									@include props(mt,200,750);
								}
							}
						}
					}

					&.sec2020{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 6.6%;

									@include mq(md){
										@include props(w,55,750);
									}


									.img{
										padding-top: 310%;
									}
								}

								@include props(mt,-40);

								@include mq(md){
									@include props(mt,140,750);
								}
							}
							&:nth-child(2){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(3){
								.imgWrap{
									width: 23.59%;

									@include mq(md){
										@include props(w,157,750);
									}


									.img{
										padding-top: 36.36%;
									}
								}
							}
							&:nth-child(4){
								.imgWrap{
									width: 4.62%;
									.img{
										padding-top: 214.28%;
									}

									@include mq(md){
										@include props(w,35,750);
									}
								}
								.desc{
									@include mq(md){
										@include props(w,339,750);
									}
								}
							}
							&:nth-child(5){
								.imgWrap{
									width: 4.62%;

									@include mq(md){
										@include props(w,40,750);
									}


									.img{
										padding-top: 193.1%;
									}
								}
								
							}
						}
					}

					&.sec2021{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 8.08%;
									.img{
										padding-top: 100%;
									}

									@include mq(md){
										@include props(w,57,750);
									}
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 34.48%;
									&:after{
										right: 20%;
									}

									.img{
										padding-top: 30.14%;
									}

									@include mq(md){
										@include props(w,166,750);

										.img{
											padding-top: 97.03%;
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,381,750);
									}
								}

								.modelArrow{
									@include mq(md){
										position: absolute;
										@include props(b,-20,750);
										right: 0;
									}
								}

								@include props(mt,100);

								@include mq(md){
									@include props(mt,200,750);
								}
							}
							&:nth-child(3){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(4){
								.imgWrap{
									width: 4.62%;

									.img{
										padding-top: 185.18%;
									}

									@include mq(md){
										@include props(w,40,750);
									}
								}
								.desc{
									@include mq(md){
										@include props(w,312,750);
									}
								}
							}
							&:nth-child(5){
								.imgWrap{
									width: 34.48%;
									.img{
										padding-top: 66.66%;
									}
									@include mq(md){
										@include props(w,266,750);
									}
								}

								.desc{
									@include mq(md){
										@include props(w,280,750);
									}
								}

								.modelArrow{
									@include mq(md){
										margin-left: auto;
									}
								}
							}
							&:nth-child(6){
								.imgWrap{
									width: 4.62%;

									.img{
										padding-top: 185.18%;
									}

									@include mq(md){
										@include props(w,40,750);
									}
								}
								.desc{
									@include mq(md){
										@include props(w,339,750);
									}
								}
							}
						}
					}

					&.sec2022{
						li{
							&:nth-child(1){
								.imgWrap{
									width: 10.06%;
									.img{
										padding-top: 81.96%;
									}

									@include mq(md){
										@include props(w,88,750);
									}
								}
							}
							&:nth-child(2){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(3){
								.imgWrap{
									&:after{
										right: -400%;
										top: -10%;

										@include mq(md){
											@include props(t,-20,750);
											right: inherit;
											@include props(l,310,750);
											transform: translate(0,-100%);
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,408,750)
									}
								}
							}
							&:nth-child(4){
								.imgWrap{
									width: 33%;

									.img{
										padding-top: 70%;
									}

									&:after{
										top: 10%;
										right: -10%;

										@include mq(md){
											top: -10%;
											right: 0;
										}
									}

									@include mq(md){
										@include props(w,270,750);
									}
								}

								.desc{
									@include mq(md){
										flex: 1
									}
								}

								@include props(mt,100);

								@include mq(md){
									@include props(mt,200,750);

									.modelArrow{
										position: absolute;
										right: 0;
										@include props(b,-45,750);
									}
								}
							}
							&:nth-child(5){
								.desc{
									@include mq(md){
										@include props(w,408,750);
									}
								}
							}
							&:nth-child(6){
								.imgWrap{
									width: 10.23%;
									.img{
										padding-top: 87.09%;
									}


									@include mq(md){
										@include props(w,88,750);
									}
								}
							}
							&:nth-child(7){
								.imgWrap{
									width: 9.9%;
									.img{
										padding-top: 101.66%;
									}

									@include mq(md){
										@include props(w,164,750);
									}
								}
							}
							&:nth-child(8){
								.imgWrap{
									width: 39.76%;
									.img{
										padding-top: 58.09%;
									}

									@include mq(md){
										@include props(w,236,750);
									}
								}

								.modelArrow{
									@include mq(md){
										margin-left: auto;
									}
								}
							}
						}
					}

					&.sec2023{
						li{
							&:nth-child(1){
								@include props(mt,-40);
								.imgWrap{
									width: 17.65%;
									.img{
										padding-top: 124.29%;
									}

									@include mq(md){
										@include props(w,132,750);
									}
								}

								.desc{
									@include mq(md){
										@include props(w,285,750);
									}
								}

								@include mq(md){
									@include props(mt,0,750);
								}
							}
							&:nth-child(2){
								.imgWrap{
									width: 28.8%;
									.img{
										padding-top: 59.5%;
									}

									@include mq(tab){
										width: 26.8%;
									}

									@include mq(md){
										@include props(w,266,750);
									}

									&:after{
										top: 10%;
										right: -10%;

										@include mq(md){
											top: -10%;
											right: 0;
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,280,750);
									}
								}

								@include mq(md){
									@include props(mt,200,750);

									.modelArrow{
										margin-left: auto;
									}
								}
							}
							&:nth-child(3){
								.imgWrap{
									width: 33%;

									.img{
										padding-top: 49.5%;
									}

									@include mq(md){
										@include props(w,266,750);
									}

									&:after{
										top: 10%;
										right: -10%;

										@include mq(md){
											top: 0;
											right: 50%;
										}
									}
								}

								.desc{
									@include mq(md){
										@include props(w,280,750);
									}
								}

								@include mq(md){
									@include props(mt,200,750);

									.modelArrow{
										margin-left: auto;
									}
								}
							}
						}
					}
				}
			}
		}

		.historyFinish{
			margin: 0 auto;
			@include props(w,573);
			position: relative;

			img{
				margin: 0 auto;
			}

			@include mq(md){
				width: 100%;
				max-width: 100%
			}

			.flower19{
				@include props(l,-187);
				@include props(b,-50);
				transition: opacity 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1);
				@include mq(md){
					@include props(l,165,750);
					@include props(b,-205,750);
				}
			}

			.flower20{
				@include props(l,6);
				@include props(b,-100);
				transition: opacity 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1);
				@include mq(md){
					@include props(l,76,750);
					@include props(b,-82,750);
				}
			}
			.flower21{
				@include props(l,-65);
				@include props(t,-35);
				transition: opacity 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1);
				@include mq(md){
					@include props(l,350,750);
					@include props(t,267,750);
				}
			}

			.flower22{
				@include props(r,-61);
				@include props(t,-119);
				transition: opacity 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 3s cubic-bezier(0.215, 0.61, 0.355, 1);

				@include mq(md){
					@include props(r,141,750);
					@include props(t,-161,750);
				}
			}

			.flower23{
				@include props(r,-135);
				@include props(t,50);
				transition: opacity 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 1s cubic-bezier(0.215, 0.61, 0.355, 1);
				@include mq(md){
					@include props(r,30,750);
					@include props(t,-60,750);
				}
			}
			.flower24{
				@include props(r,-61);
				@include props(t,154);
				transition: opacity 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1),transform 2s 2s cubic-bezier(0.215, 0.61, 0.355, 1);
				@include mq(md){
					@include props(r,310,750);
					@include props(t,-107,750);
				}
			}
		}
	}


	.detailModalContent {
		width: 100%;
		height: 100%;
		margin: 0;
		min-height: 100%;
		background: $basicWhiteColor;
		position: fixed;
		display: none;
		z-index: 100000;
		display: none;
	  
		.close {
			position: absolute;
			@include props(t,70);
			@include props(r,100);
			z-index: 100;
			opacity: 1;
			@include mq(md) {
			  @include props(t,40,750);
			  @include props(r,40,750);
			}
	  
			a{
			  display: block;
			  text-indent: -9999px;
			  @include props(w,60);
			  @include props(h,60);
			  background: url(/images/common/modal_close_btn.svg) no-repeat;
			  background-size: contain;
	  
			  @include mq(md){
				@include props(w,48,750);
				@include props(h,48,750);
				background-size: contain;
			  }
			}
		  }
	  
		  .modalInner{
			overflow-y: auto;
			position: relative;
			margin: 0 auto;
			text-align: center;
			@include props(pt,150);
			@include props(pb,150);
			height: 100vh;
			background: $basicWhiteColor;
	  
			@include mq(md){
				@include props(pt,230,750);
				@include props(pb,230,750);
			}
	
			.minContainer{
				max-width: 802px;
				padding: 0 20px;
				margin: auto;
		
				@include mq(md){
					@include props(pl,40,750);
					@include props(pr,40,750);
				}
		
				.modalTit{
					font-family: $YuGothic;
					@include fz_vw(20);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: 0.6px;
					text-align: center;
					@include props(mb,50);
					@include mq(md){
						@include fz_vw(32);
						@include props(mb,80,750);
						line-height: 1.5;
						letter-spacing: 1.6px;
					}
				}

				img{
					@include props(mb,50);
					margin-left: auto;
					margin-right: auto;

					@include mq(md){
						@include props(mb,80,750);
						width: 100%;
					}
				}

				.modalDesc{
					font-family: $YuGothic;
					@include fz_vw(18);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					text-align: left;
					@include props(mb,80);

					@include mq(md){
						@include fz_vw(28);
						line-height: 1.71;
						@include props(mb,80,750);
					}
				}

				.modalDescStamp{
					font-family: $YuGothic;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					line-height: 2;
					letter-spacing: normal;
					text-align: left;
					@include props(mt,40);
					text-align: right;

					@include mq(md){
						text-align: left;
						@include fz_vw(28);
						line-height: 1.71;
						@include props(mt,40,750);
					}
				}

				.linkWrap{
					text-align: right;

					&:not(:last-of-type){
						@include props(mb,40);
					}

					@include mq(md){
						&:not(:last-of-type){
							@include props(mb,40,750);
						}
					}
				}

				.external{
					font-family: $YuMincho;
					@include fz_vw(16);
					font-weight: 500;
					font-stretch: normal;
					font-style: normal;
					letter-spacing: 0.16px;
					color: #4d4d4d;

					@include mq(md){
						@include fz_vw(28);
					}
				}
			}
		}
	}
	
}